import accessDenied from './access-denied.json';
import createNewPassword from './create-new-password.json';
import impersonationBanner from './impersonation-banner.json';
import impersonationForm from './impersonation-form.json';
import recoveryPasswordForm from './recovery-password-form.json';
import resetPasswordRequiredForm from './reset-password-required.json';
import signIn from './sign-in.json';
import signupForm from './signup-form.json';
import validatePasswordRecovery from './validate-password-recovery.json';

export default {
  accessDenied,
  createNewPassword,
  impersonationBanner,
  impersonationForm,
  recoveryPasswordForm,
  resetPasswordRequiredForm,
  signIn,
  signupForm,
  validatePasswordRecovery
};
