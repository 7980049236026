import React, { useContext } from 'react';
import { Box, Container, Typography, Button } from '@material-ui/core';
import HeaderWithReturn from '../../app/components/header-with-return';
import DriverSearch from '../../app/components/driver-search';
import { DistributeProcessContext } from './distribute-process-context';
import DriverDetailsDialog from './driver-details-dialog';

export default function IdentifyDriverScreen() {
  const {
    driver,
    setDriver,
    driverList,
    selectedDriver,
    setSelectedDriver,
    setToInitialState,
    openDriverDetailDialog,
    setOpenDriverDetailDialog
  } = useContext(DistributeProcessContext);

  return (
    <Box>
      <HeaderWithReturn title="Distribuir" onReturn={setToInitialState} />
      <Box
        flexFlow="column"
        overflow="hidden"
        data-testid="driver-identification"
      >
        <Container maxWidth="xs" disableGutters>
          <Box
            pt={8}
            display="flex"
            flexDirection="column"
            height="100vh"
            overflow="hidden"
          >
            <Box display="flex" paddingLeft={3} mt={3}>
              <Typography variant="h5">Identifique o entregador</Typography>
            </Box>
            <Box flex="1" overflowY="auto" width="100%" height={1} mt={3}>
              <DriverSearch
                selectDriver={selectDriver => setDriver(selectDriver)}
                selectedDriver={driver}
                onClickAvatar={selectAvatar => {
                  setOpenDriverDetailDialog(true);
                  setSelectedDriver(selectAvatar);
                }}
                selectedAvatar={selectedDriver}
                driverList={driverList}
              />
            </Box>
            <Box alignItems="center" p={3} mt={1}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                Continuar
              </Button>
            </Box>
            {openDriverDetailDialog && (
              <DriverDetailsDialog
                driver={selectedDriver}
                handleClose={() => setOpenDriverDetailDialog(false)}
              />
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
