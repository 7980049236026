import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default function ExpandIcon({ expand }) {
  return (
    <Box style={{ color: colors.blue[500] }}>
      {expand ? <ExpandLess /> : <ExpandMore />}
    </Box>
  );
}

ExpandIcon.propTypes = {
  expand: PropTypes.bool.isRequired
};
