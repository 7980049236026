import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Container, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { colors } from '@loggi/mar';
import HeaderWithReturn from '../../app/components/header-with-return';
import { ReactComponent as EmptySearch } from '../../assets/images/empty-search.svg';
import {
  HISTORY_ACTIONS,
  ROUTES,
  OPERATIONAL_PROCESS,
  ACTIVITY
} from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { OrganizeProcessContext } from './organize-process-context';
import UnitReader from './unit-reader';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { UNIT_TYPE } from '../../app/enums';

import { SortingContextSelect } from './sorting-context-select';

export default function InitialIdentification() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { sortingContext, setUnitType, setUnitInfo } = useContext(
    OrganizeProcessContext
  );
  const { trackStart } = useContext(ActivityTrackingContext);

  const [loading, setLoading] = useState(false);
  const [openContextSelector, setOpenContextSelector] = useState(false);
  const bgColor = colors.root[0];

  const goBack = () => history.push({ pathname: ROUTES.HOME });

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleError = message => {
    showSnackbarAlert(message, 'error');
    playErrorBeep();
  };

  const redirectToHome = () => history.replace(ROUTES.HOME);

  useEffect(() => {
    // prevent page from being accessed by editing the URL or non-leve users
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      redirectToHome();
    }
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleRead = ({ type, info }) => {
    setUnitType(type);
    setUnitInfo(info);
    playSuccessBeep();
    if (type === UNIT_TYPE.UNIT_TYPE_UNIT_LOAD) {
      history.push({ pathname: ROUTES.ORGANIZE.ORGANIZE_UNIT_LOAD });
    } else {
      history.push({ pathname: ROUTES.ORGANIZE.ORGANIZE_PACKAGE });
    }
  };

  return (
    <Box bgcolor={bgColor}>
      <HeaderWithReturn
        onReturn={goBack}
        title="Organizar"
        dataTestId="cancel-button-header"
        bgcolor={bgColor}
      >
        <SortingContextSelect
          openSelectContext={openContextSelector}
          setOpenSelectContext={setOpenContextSelector}
        />
      </HeaderWithReturn>

      <Container maxWidth="xs">
        <Box
          pt={8}
          display="flex"
          flexDirection="column"
          overflow="hidden"
          height="100vh"
        >
          <UnitReader
            onSuccessCallback={handleRead}
            onErrorCallback={handleError}
            setLoading={setLoading}
            disable={openContextSelector || !sortingContext}
          />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {loading && (
              <>
                <Box mb={3}>
                  <Skeleton variant="rect" height={50} width={70} />
                </Box>
                <Box>
                  <Skeleton variant="text" width={200} />
                </Box>
              </>
            )}
            {!loading && (
              <>
                <EmptySearch />
                <Typography variant="subtitle2" align="center">
                  <Box fontWeight="fontWeightMedium" mt={3}>
                    Bipe um pacote, saca ou local de armazenamento.
                  </Box>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
