import * as Sentry from '@sentry/browser';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  IconButton
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import { colors } from '@loggi/mar';
import { playSuccessBeep, playErrorBeep } from '../../sounds';
import {
  MIN_ATTEMPTS_TO_CONTINUE_SUSPICIOUS_CARA_CRACHA,
  SWITCHES
} from '../../constants';
import PhotoTemplate from '../../app/components/photo-template';
import { useFeature } from '../../app/hooks/use-feature';
import {
  deliverBagRecord as deliverBagRecordRest,
  pickupPhotoComparison,
  postProcessPhotoComparison
} from '../../api-rest';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import handleRestAPIError from '../../app/utils/rest-api-request';

export const NOTIFICATIONS = {
  UNABLE_TO_LOAD_PHOTO: 'Não foi possível carregar a foto. Tente novamente',
  RECORD_SUCCESS: 'Saca registrada com sucesso!',
  ERROR_NO_SIGNED_URL: 'Não foi possível obter URL assinada',
  ERROR_NO_SIGNED_URL_RESPONSE: 'SignedURL sem resposta',
  ERROR_S3: 'Não foi possível comunicar com S3',
  ERROR_NO_S3_RESPONSE: 'S3 sem resposta',
  LOW_SIMILARITY_TAKE_ANOTHER_PHOTO:
    'Similaridade baixa! Isto geralmente acontece quando a pessoa está de máscara ou quando a foto está escura. Por favor tire outra foto.'
};

export const DangerButton = withStyles(theme => ({
  root: {
    backgroundColor: colors.red[500],
    borderColor: colors.red[500],
    color: theme.palette.common.white
  }
}))(Button);

export function SendButtons({
  anotherImage,
  sendPhoto,
  postProcess,
  attemptsCount,
  photoComparisonErrorMsg
}) {
  return (
    <>
      {postProcess && (
        <Box
          py={1}
          className="centered"
          data-testid="errorSimilarityAlert-test"
        >
          <Alert severity="error">
            {photoComparisonErrorMsg ||
              NOTIFICATIONS.LOW_SIMILARITY_TAKE_ANOTHER_PHOTO}
          </Alert>
        </Box>
      )}
      <Box
        px={2}
        my={1.5}
        display="flex"
        textAlign="center"
        justifyContent="space-between"
      >
        <Box width={1} mx={0.2}>
          <Button
            p={3}
            fullWidth
            variant="contained"
            id="anotherPhoto"
            data-testid="anotherPhoto-test"
            onClick={anotherImage}
          >
            Tirar outra
          </Button>
        </Box>
        {postProcess &&
          attemptsCount >= MIN_ATTEMPTS_TO_CONTINUE_SUSPICIOUS_CARA_CRACHA && (
            <Box width={1} mx={0.2}>
              <DangerButton
                fullWidth
                variant="contained"
                id="photoUploadWithoutVerification"
                data-testid="photoUploadWithoutVerification-test"
                onClick={sendPhoto}
              >
                Enviar sem verificação
              </DangerButton>
            </Box>
          )}
        {!postProcess && (
          <Box width={1} mx={0.2}>
            <Button
              fullWidth
              variant="contained"
              id="sendPhoto"
              data-testid="sendPhoto-test"
              color="primary"
              onClick={sendPhoto}
            >
              Enviar
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

SendButtons.propTypes = {
  anotherImage: PropTypes.func.isRequired,
  sendPhoto: PropTypes.func.isRequired,
  postProcess: PropTypes.bool.isRequired,
  attemptsCount: PropTypes.number.isRequired,
  photoComparisonErrorMsg: PropTypes.string
};

SendButtons.defaultProps = {
  photoComparisonErrorMsg: null
};

const useStylesPhotoUpload = makeStyles(thisTheme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: thisTheme.typography
    }
  },
  padding: {
    padding: 0
  }
}));

export function PhotoUpload({ defaultImageData }) {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const enableCaraCrachaInDistribute = useFeature(
    SWITCHES.enableCaraCrachaInDistribute
  );
  const classes = useStylesPhotoUpload();
  const [redirectIdentify, setRedirectIdentify] = React.useState(false);
  const [imageData, setImageData] = useState(defaultImageData);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [bag, setBag] = useState(null);
  const [itinerary, setItinerary] = useState(null);
  const [postProcess, setPostProcess] = useState(false);
  const [photoComparisonId, setPhotoComparisonId] = useState(null);
  const [attemptsCount, setAttemptsCount] = useState(0);
  const [photoComparisonErrorMsg, setPhotoComparisonErrorMsg] = useState(null);

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    const bagState = location?.state?.bag;
    const itineraryState = location?.state?.itinerary;
    if (location.state) {
      setBag(bagState);
      setItinerary(itineraryState);
    } else {
      setRedirectIdentify(true);
    }
    if (enableCaraCrachaInDistribute && !location?.state?.contextLicensePlate) {
      showSnackbar({
        variant: 'warning',
        message: 'Agora você pode fazer essa validação pelo menu Distribuir.',
        showCloseButton: true,
        enqueueSnackbar
      });
    }
  }, [enableCaraCrachaInDistribute, enqueueSnackbar, history, location]);

  function errorHandler(err) {
    setLoading(false);
    setError(err);
    playErrorBeep();
  }

  function successHandler() {
    playSuccessBeep();
    setLoading(false);

    if (location?.state?.contextLicensePlate) {
      history.push({
        pathname: '/distribute/distribute-package-reader',
        state: location?.state
      });
      return;
    }

    history.replace({
      pathname: '/identify',
      state: {
        bagStateFromPhotoUpload: bag,
        recordSuccessWithPhoto: NOTIFICATIONS.RECORD_SUCCESS
      }
    });
  }

  const callPickupPhotoComparison = async deliverBagRecordId => {
    const response = await pickupPhotoComparison(deliverBagRecordId).catch(
      () => {
        /* if get some error in this step we must hide it */
      }
    );
    if (!response) return null;

    return {
      result: response.data.result,
      photoComparisonId: response.data.photoComparisonId,
      errorMsg: response.data.errorMsg
    };
  };

  async function callPostProcess() {
    return postProcessPhotoComparison(photoComparisonId).catch(() => {
      /* if get some error in this step we must hide it */
    });
  }

  async function doUpload(signedUrl, photo) {
    /*
    Send image to s3 bucket using pre signed url
    and call mutation of transfer confirmation
    */
    const photoDecoded = Buffer.from(
      photo.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );

    const response = await fetch(signedUrl, {
      method: 'PUT',
      mode: 'cors',
      body: photoDecoded
    }).catch(err => {
      errorHandler(
        `${NOTIFICATIONS.UNABLE_TO_LOAD_PHOTO}: ${
          NOTIFICATIONS.ERROR_S3
        }: ${err}`
      );
      Sentry.captureException(err);
    });

    if (!response) {
      return false;
    }
    if (response && !response.ok) {
      errorHandler(
        `${NOTIFICATIONS.UNABLE_TO_LOAD_PHOTO}: ${
          NOTIFICATIONS.ERROR_NO_S3_RESPONSE
        }: ${response.status} ${response.statusText}`
      );
      return false;
    }
    return response;
  }

  async function uploadPhotoToAwsS3(signedUrl) {
    /*
      get signedUrl from backend to send images to s3
    */
    // 0. Initialize
    setError('');
    // 1. Request

    return doUpload(signedUrl, imageData);
  }

  const deliverBagRecord = async ({ bagSeal, itineraryId, driverId }) => {
    let signedUrl = null;
    let deliverBagRecordId = null;

    const response = await deliverBagRecordRest(
      bagSeal,
      itineraryId,
      driverId
    ).catch(err => handleRestAPIError(err, errorHandler));

    if (!response) return null;

    signedUrl = response.data.signedUrl;
    deliverBagRecordId = response.data.deliverBagRecordId;

    if (!signedUrl) {
      errorHandler(
        `${NOTIFICATIONS.UNABLE_TO_LOAD_PHOTO}: ${
          NOTIFICATIONS.ERROR_NO_SIGNED_URL
        }`
      );
      return null;
    }

    return {
      signedUrl,
      deliverBagRecordId
    };
  };

  const recordData = () => {
    setError('');

    return deliverBagRecord({
      bagSeal: bag.bagData?.seal || bag.seal,
      itineraryId:
        bag.itinerary?.pk || bag.itinerary?.allocationId || itinerary?.pk,
      driverId: bag.driver?.pk || itinerary?.driver?.pk
    });
  };

  /*
   get signed url and call mutation of transfer confirmation
  */
  async function handleSendClick() {
    setLoading(true);

    if (postProcess) {
      // this call is async since its response does not affect the flow, even when it is an error.
      callPostProcess();
      return successHandler();
    }

    const deliverBagRecordData = await recordData();

    const signedUrl = deliverBagRecordData?.signedUrl;
    const deliverBagRecordId = deliverBagRecordData?.deliverBagRecordId;

    const isPhotoUploaded = signedUrl
      ? await uploadPhotoToAwsS3(signedUrl)
      : null;

    if (!isPhotoUploaded) {
      return null;
    }

    const pickupPhotoComparisonData =
      isPhotoUploaded && deliverBagRecordId
        ? await callPickupPhotoComparison(deliverBagRecordId)
        : null;

    if (
      !pickupPhotoComparisonData?.result ||
      (pickupPhotoComparisonData?.result !== 'suspicious' &&
        !pickupPhotoComparisonData?.errorMsg)
    ) {
      // If do not get result
      //  or result is ok (represents high similarity)
      // go to identify page
      return successHandler();
    }

    if (pickupPhotoComparisonData?.errorMsg) {
      setPhotoComparisonErrorMsg(pickupPhotoComparisonData?.errorMsg);
    } else {
      setAttemptsCount(count => count + 1);
    }

    // if gets photocomparison result but it suspicious (low similarity)
    // then show feedback to users and let they choose continue or take another photo
    setPostProcess(true);
    setPhotoComparisonId(pickupPhotoComparisonData.photoComparisonId);
    setLoading(false);

    return null;
  }

  function handleTakeAnother() {
    setError('');
    setImageData(null);
    setPostProcess(false);
    setPhotoComparisonId(null);
    setPhotoComparisonErrorMsg(null);
  }

  function handleIgnorePhoto() {
    setLoading(true);
    Sentry.captureMessage('Photo Upload ignored in cara-cracha');
    successHandler();
  }

  if (redirectIdentify) {
    return (
      <Redirect
        to={{
          pathname: '/identify'
        }}
      />
    );
  }

  return (
    <Box>
      <IconButton data-testid="backButton-test">
        <Link
          replace
          to={
            location?.state?.contextLicensePlate
              ? {
                  pathname: '/distribute'
                }
              : {
                  pathname: '/identify',
                  state: {
                    bagStateFromPhotoUpload: bag || ''
                  }
                }
          }
        >
          <ArrowBack />
        </Link>
      </IconButton>
      <Container maxWidth="xs">
        <Typography variant="h4" align="center" gutterBottom>
          Foto do entregador
        </Typography>
        <Typography align="center" gutterBottom>
          Tire uma foto nítida do entregador segurando a CNH.
        </Typography>
      </Container>
      <Container className={classes.padding} maxWidth="xs">
        <Box p={1} my={1.5}>
          <Card>
            <Box my={1}>
              <PhotoTemplate
                imageData={imageData}
                onImageCapture={setImageData}
                imgAlt="Foto tirada do rosto do entregador"
              />
            </Box>
            {imageData != null && (
              <Box>
                <Typography align="center" gutterBottom data-testid="message">
                  Certifique-se que é possível reconhecer o entregador na foto e
                  aperte Enviar. Caso contrário, tire outra foto.
                </Typography>
                {loading ? (
                  <Grid container justify="center">
                    <Box p={2}>
                      <CircularProgress justify="center" />
                    </Box>
                  </Grid>
                ) : (
                  <SendButtons
                    anotherImage={handleTakeAnother}
                    sendPhoto={handleSendClick}
                    postProcess={postProcess}
                    attemptsCount={attemptsCount}
                    photoComparisonErrorMsg={photoComparisonErrorMsg}
                  />
                )}
                {!loading && error && (
                  <>
                    <Box
                      py={1}
                      className="centered"
                      data-testid="errorAlert-test"
                    >
                      <Alert severity="error">{error}</Alert>
                    </Box>
                    {error.includes(NOTIFICATIONS.UNABLE_TO_LOAD_PHOTO) && (
                      <Box
                        px={2}
                        pb={2}
                        display="flex"
                        textAlign="center"
                        justifyContent="space-around"
                      >
                        <Button
                          my={1}
                          fullWidth
                          variant="contained"
                          id="ignorePhotoUpload"
                          data-testid="ignorePhotoUpload-test"
                          color="default"
                          onClick={handleIgnorePhoto}
                        >
                          Prosseguir sem enviar foto
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

PhotoUpload.propTypes = {
  defaultImageData: PropTypes.string
};

PhotoUpload.defaultProps = {
  defaultImageData: null
};
