import React from 'react';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {
  InfoRounded,
  ErrorRounded,
  CancelRounded,
  CheckCircleRounded
} from '@material-ui/icons';
import { pxToRem } from '../utils';
import { colors, typography as typographyPrinciples } from '../principles';

const breakpoints = createBreakpoints({});

const MuiAlert = {
  root: {
    alignItems: 'start',
    backgroundColor: colors.root[0],
    borderRadius: pxToRem(10),
    display: 'flex',
    [breakpoints.up('sm')]: {
      paddingBottom: pxToRem(16),
      paddingLeft: pxToRem(24),
      paddingRight: pxToRem(24),
      paddingTop: pxToRem(16),
      fontSize: typographyPrinciples.subtitle2.fontSize
    }
  }
};

const MuiAlertProps = {
  iconMapping: {
    /**
     * Using JSX inside this library breaks applications that don't
     * transpile the node_modules/@loggi folder.
     * Using React.createElement here is the same as using JSX </>:
     * @see {https://reactjs.org/docs/react-without-jsx.html}
     */
    info: React.createElement(InfoRounded),
    warning: React.createElement(ErrorRounded),
    success: React.createElement(CheckCircleRounded),
    error: React.createElement(CancelRounded)
  }
};

export default MuiAlert;
export { MuiAlertProps };
