import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { PackageReader } from '../../app/components/barcode-readers';
import { identifyBarcode } from '../../api';
import {
  TASK_ACK_STATUS_CHOICES,
  ERRORS,
  BAG_PACKAGE_ERROR_TYPE,
  OPERATIONAL_PROCESS,
  ACTIVITY
} from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import sharedPropTypes from '../../app/shared-prop-types';
import MovePackageDialog from './move-package-dialog';
import extractDataFromGraphQLResponse from '../../app/utils/graphql-request';

/**
 * This component is responsible for handling the packages that are read on
 * the distribution procedure. After reading the package, this component selects
 * one of three functions to process it. The function is chosen based on whether
 * the package is:
 *
 * Expected -> In this case, the package should really resides in this bag and,
 * therefore, a success function is called and the next package should be biped.
 *
 * Invalid -> There is something wrong with this package. Consequently, this component
 * shows an alert according to the error type of the package and another component
 * is called, where it will be stored. In a redispatch bag, examples of an invalid package
 * include one that is not from redispatch or has its label already expired.
 *
 * Neither Expected nor Invalid -> This package 1. was not found or 2. should not be in
 * this bag. In case of the former, an alert is shown saying that the package was
 * not found. If the latter, a warning message (i.e. warningMessage) is presented
 * and another component is called, where it will be stored.
 *
 * @param expectedPackages {Object[]}
 * @param invalidPackages {Object[]}
 * @param checkedInvalidPackages {Object[]} The invalidPackages already beeped
 * @param before {function} Called right before reading the package barcode
 * @param success {function} Called when a package is expected and valid
 * @param fail {function} Called when some request failure happens
 * @param warningMessage {string} Message to show when unexpected package is detected
 * @param source {string} PackageReader param
 * @param placeholder {string} PackageReader param
 * @param disable {string} PackageReader param
 * @param notes {string} PackageReader param
 * @param sortingContext {string} current Sorting Context (if available)
 * @param onMovePackage {function} Called after moving a package successfully
 * @returns {JSX.Element}
 */

export default function StrictPackageIdentification({
  expectedPackages,
  invalidPackages,
  checkedInvalidPackages,
  before,
  success,
  fail,
  warningMessage,
  source,
  placeholder,
  disable,
  notes,
  sortingContext,
  onMovePackage
}) {
  const [loading, setLoading] = React.useState(false);
  const [movePackageOpen, setMovePackageOpen] = React.useState(false);
  const [currentReadingPackage, setCurrentReadingPackage] = React.useState(
    null
  );
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const { enqueueSnackbar } = useSnackbar();

  const extractPackage = payload => {
    let packageObject = null;

    if (
      payload?.data?.getLabelInfo?.packages &&
      payload?.data?.getLabelInfo?.packages.length
    ) {
      [packageObject] = payload.data.getLabelInfo.packages;

      packageObject.intDeliveriesAttempts = (
        packageObject.deliveryStatusHistory || []
      )
        .filter(
          status =>
            status.codeDisplay !== TASK_ACK_STATUS_CHOICES.IMPAIRED_DELIVERY
        )
        .reduce((l, r) => Math.max(l, parseInt(r.attempt, 10)), 0);
    }

    return packageObject;
  };

  const fetchPackage = async barcode => {
    const identifyResponse = await identifyBarcode(barcode);
    const identifyPayload = await extractDataFromGraphQLResponse(
      identifyResponse
    );
    const packageObject = extractPackage({ data: identifyPayload });

    if (!packageObject) {
      fail(ERRORS.PACKAGE_NOT_FOUND);
    }

    return packageObject;
  };

  const showErrorSnackbar = message => {
    showSnackbar({
      variant: 'error',
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const startMovePackage = (barcode, packagePayload, errorMessage) => {
    setCurrentReadingPackage({ ...packagePayload, barcode });
    setMovePackageOpen(true);
    showErrorSnackbar(errorMessage);
  };

  const findPackage = (barcode, packages) =>
    packages.find(p => p.identifier === barcode);

  const processExpectedPackage = pkg => {
    setLoading(false);
    success(pkg);
  };

  const checkInvalidPackageAlreadyBeeped = barcode =>
    checkedInvalidPackages.some(p => p.identifier === barcode);

  const processInvalidPackage = (barcode, pkg) => {
    const errorMessage =
      BAG_PACKAGE_ERROR_TYPE[pkg.errorType] ||
      BAG_PACKAGE_ERROR_TYPE.BAG_PACKAGE_ERROR_TYPE_INVALID;

    if (checkInvalidPackageAlreadyBeeped(barcode)) {
      setLoading(false);
      fail('Opa, pacote já bipado!');
      return;
    }

    setLoading(false);
    startMovePackage(barcode, pkg, errorMessage);
  };

  const processPackagesNotFromLists = async barcode => {
    const packagePayload = await fetchPackage(barcode).catch(err => {
      fail(err.message);
    });
    setLoading(false);
    if (packagePayload) {
      startMovePackage(barcode, packagePayload, warningMessage);
    }
  };

  const handlePackageRead = async barcode => {
    before();
    setLoading(true);

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_PACKAGE_BEEP
    );

    const expectedPackage = findPackage(barcode, expectedPackages);

    if (expectedPackage) {
      processExpectedPackage(expectedPackage);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_PACKAGE_BEEP
      );
      return;
    }

    const invalidPackage = findPackage(barcode, invalidPackages);

    if (invalidPackage) {
      processInvalidPackage(barcode, invalidPackage);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_PACKAGE_BEEP
      );
      return;
    }

    await processPackagesNotFromLists(barcode);

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_PACKAGE_BEEP
    );
  };

  const handleMovePackage = () => {
    setMovePackageOpen(false);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_STORE_BEEP);
    onMovePackage(currentReadingPackage.identifier);
  };

  const handleCloseWithoutPackage = () => {
    setMovePackageOpen(false);
  };

  return (
    <>
      {!movePackageOpen && (
        <PackageReader
          onRead={handlePackageRead}
          loading={loading}
          source={source}
          placeholder={placeholder}
          disable={disable}
          notes={notes}
        />
      )}
      {movePackageOpen && currentReadingPackage && (
        <MovePackageDialog
          handlePackage={handleMovePackage}
          closeWithoutPackage={handleCloseWithoutPackage}
          currentPackage={currentReadingPackage}
          sortingContextLicensePlate={sortingContext}
        />
      )}
    </>
  );
}

StrictPackageIdentification.defaultProps = {
  expectedPackages: [],
  invalidPackages: [],
  checkedInvalidPackages: [],
  warningMessage: ERRORS.UNEXPECTED_PACKAGE,
  source: 'xd_app_strict_package_identification',
  placeholder: 'Leia a etiqueta do pacote',
  disable: false,
  sortingContext: null,
  onMovePackage: () => {}
};

StrictPackageIdentification.propTypes = {
  expectedPackages: sharedPropTypes.packages,
  invalidPackages: sharedPropTypes.packages,
  checkedInvalidPackages: sharedPropTypes.packages,
  before: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  fail: PropTypes.func.isRequired,
  warningMessage: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.string,
  disable: PropTypes.bool,
  notes: PropTypes.string.isRequired,
  sortingContext: PropTypes.string,
  onMovePackage: PropTypes.func
};
