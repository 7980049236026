import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ItineraryInfo = ({
  status,
  timePassedInStatus,
  itineraryPk,
  allocationId,
  warRoomUrl
}) => {
  return (
    <>
      <Typography variant="subtitle2">
        Oferta {status}, há {timePassedInStatus}
      </Typography>
      {allocationId && (
        <Typography variant="subtitle2">Alocação {allocationId}</Typography>
      )}
      {itineraryPk && (
        <Typography variant="subtitle2">Itinerário {itineraryPk}</Typography>
      )}
      {warRoomUrl && (
        <Box paddingTop={2}>
          <a href={warRoomUrl} target="_blank" rel="noopener noreferrer">
            Link para o war-room
          </a>
        </Box>
      )}
    </>
  );
};

ItineraryInfo.defaultProps = {
  itineraryPk: '',
  allocationId: '',
  warRoomUrl: ''
};

ItineraryInfo.propTypes = {
  status: PropTypes.string.isRequired,
  timePassedInStatus: PropTypes.string.isRequired,
  itineraryPk: PropTypes.string,
  allocationId: PropTypes.string,
  warRoomUrl: PropTypes.string
};

export default ItineraryInfo;
