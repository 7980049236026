import React from 'react';

import { SendRounded } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import { TESTID } from './constants';

export default function ImageView({
  titleComponent,
  image,
  isLoading,
  handleSend,
  removeImage,
  imageHeight,
  titleAlt
}) {
  const loadSendButton = () => {
    if (handleSend) {
      return (
        <IconButton
          data-testid={TESTID.IMAGE_VIEW_SEND_BUTTON}
          onClick={handleSend}
        >
          <SendRounded color="primary" fontSize="large" />
        </IconButton>
      );
    }
    return null;
  };
  const titleWithDataTestId = React.cloneElement(titleComponent, {
    'data-testid': TESTID.DAMAGE_PHOTO_TITLE
  });
  return (
    <Box>
      {titleWithDataTestId}
      <Box
        data-testid={TESTID.CONTAINER_IMAGE_VIEW}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        mb={2}
      >
        <Box position="absolute" zIndex="1" center="0" bottom="10px">
          {isLoading ? (
            <CircularProgress justify="center" />
          ) : (
            <>
              <IconButton
                data-testid={TESTID.IMAGE_VIEW_REMOVE_BUTTON}
                onClick={removeImage}
              >
                <DeleteIcon color="primary" fontSize="large" />
              </IconButton>
              {loadSendButton()}
            </>
          )}
        </Box>

        <img
          style={{
            width: '100%',
            height: imageHeight,
            objectFit: 'cover'
          }}
          data-testid={TESTID.MATCH_IMAGE}
          src={image}
          alt={titleAlt}
        />
      </Box>
    </Box>
  );
}

ImageView.propTypes = {
  image: PropTypes.string.isRequired,
  titleComponent: PropTypes.element.isRequired,
  isLoading: PropTypes.bool,
  handleSend: PropTypes.func,
  removeImage: PropTypes.func,
  imageHeight: PropTypes.string,
  titleAlt: PropTypes.string
};

ImageView.defaultProps = {
  isLoading: false,
  handleSend: null,
  removeImage: null,
  imageHeight: '70vh',
  titleAlt: ''
};
