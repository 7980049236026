import * as Sentry from '@sentry/browser';
import { ENDPOINTS, ZENDESK_URL } from './constants';
import loggiWebApi from './loggi-web-api';

const generateZendeskJwtToken = () => {
  const { url } = ENDPOINTS.JWT_TOKEN;
  const payload = { tags: ['loggione'] };

  return loggiWebApi
    .url(url)
    .accept('application/json')
    .content('application/json')
    .post(payload)
    .json(({ token }) => ({ token, url: `${ZENDESK_URL}${token}` }))
    .catch(error => {
      Sentry.captureException(error, {
        contexts: {
          generateZendeskJwtToken: payload
        }
      });
      return { token: null };
    });
};

export default generateZendeskJwtToken;
