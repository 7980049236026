import 'typeface-montserrat';
import { typography } from '../../principles';
import { pxToRem } from '../../utils';

const driversTypography = {
  ...typography,
  fontFamily: 'Montserrat, sans-serif',
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  pxToRem,
  h1: {
    fontSize: pxToRem(97),
    letterSpacing: pxToRem(-1.5),
    lineHeight: pxToRem(120)
  },
  h2: {
    fontSize: pxToRem(61),
    letterSpacing: pxToRem(-0.5),
    lineHeight: pxToRem(80)
  },
  h3: {
    fontSize: pxToRem(48),
    letterSpacing: 0,
    lineHeight: pxToRem(64)
  },
  h4: {
    fontSize: pxToRem(34),
    letterSpacing: pxToRem(0.25),
    lineHeight: pxToRem(40)
  },
  h5: {
    fontSize: pxToRem(24),
    letterSpacing: 0,
    lineHeight: pxToRem(32)
  },
  h6: {
    fontSize: pxToRem(20),
    letterSpacing: pxToRem(0.15),
    lineHeight: pxToRem(24)
  },
  subtitle1: {
    fontSize: pxToRem(16),
    letterSpacing: pxToRem(0.15),
    lineHeight: pxToRem(20),
    fontWeight: 400
  },
  subtitle2: {
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(0.1),
    lineHeight: pxToRem(16),
    fontWeight: 400
  },
  body1: {
    fontSize: pxToRem(16),
    letterSpacing: pxToRem(0.5),
    lineHeight: pxToRem(20),
    fontWeight: 400
  },
  body2: {
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(0.25),
    lineHeight: pxToRem(16),
    fontWeight: 400
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(12),
    letterSpacing: pxToRem(0.4),
    fontWeight: 400
  }
};

export default driversTypography;
