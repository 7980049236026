import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { useFeature } from '../../app/hooks/use-feature';
import {
  DECLARE_DAMAGE_STATUS_CLOSED,
  DECLARE_DAMAGE_STATUS_SOLVED,
  TESTID,
  TEXT
} from './constants';
import { SWITCHES } from '../../constants';

export default function JiraAlreadyExists({
  goToPackageDamageDeclaration,
  goBackToIdentify,
  reFetchJiraData,
  jiraTicket
}) {
  const enableCreationDuplicatedTicketForDamagedPackage = useFeature(
    SWITCHES.enableCreationDuplicatedTicketForDamagedPackage
  );

  return (
    <>
      {jiraTicket && (
        <Card>
          <CardContent>
            <Box py={2} display="flex" flexDirection="column">
              <Typography>{TEXT.JIRA_ALREADY_EXISTS}</Typography>
              <Typography variant="caption">
                {TEXT.JIRA}
                <a
                  href={`${TEXT.JIRA_BROWSER_URL}${jiraTicket.key}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {jiraTicket.key}
                </a>
              </Typography>
              <Typography variant="caption">
                {`${TEXT.STATUS}${jiraTicket.status}`}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}

      <Box mt={3} display="flex">
        {!jiraTicket ? (
          <Box mr={1} width="100%">
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={reFetchJiraData}
            >
              {TEXT.TRY_AGAIN}
            </Button>
          </Box>
        ) : (
          <>
            <Box mr={1} width="100%">
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={goBackToIdentify}
              >
                {TEXT.BACK}
              </Button>
            </Box>
            {(enableCreationDuplicatedTicketForDamagedPackage &&
              DECLARE_DAMAGE_STATUS_SOLVED.includes(jiraTicket.status)) ||
            DECLARE_DAMAGE_STATUS_CLOSED.includes(jiraTicket.status) ? (
              <Box ml={1} width="100%">
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  data-testid={TESTID.DECLARE_DAMAGE_NEXT_FORM}
                  onClick={goToPackageDamageDeclaration}
                >
                  {TEXT.FORWARD}
                </Button>
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </>
  );
}

JiraAlreadyExists.propTypes = {
  goToPackageDamageDeclaration: PropTypes.func.isRequired,
  goBackToIdentify: PropTypes.func.isRequired,
  reFetchJiraData: PropTypes.func.isRequired,
  jiraTicket: PropTypes.shape({
    key: PropTypes.string,
    status: PropTypes.string
  })
};

JiraAlreadyExists.defaultProps = {
  jiraTicket: {
    key: '',
    status: ''
  }
};
