import React from 'react';
import {
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ArrowForwardIos } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';

const RedispatchAgencyDialog = ({ title, itemList, onSelect }) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open
      data-testid="redispatch-agency-dialog"
    >
      <Box>
        <Box px={4} pt={4} pb={1}>
          <Typography variant="button" component="span">
            <Box fontWeight="fontWeightBold" color={colors.smoke[900]}>
              {title}
            </Box>
          </Typography>
        </Box>
        <List>
          {itemList.map((item, index) => (
            <Box px={1.5} key={item.key}>
              <ListItem
                button
                divider={index < itemList.length - 1}
                onClick={() => onSelect(item)}
                data-testid={`redispatch-agency-item-${index}`}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>
                  <Box px={2} pt={1}>
                    <Typography variant="body2">{item.title}</Typography>
                  </Box>
                  <Box px={2} pb={1} color={colors.smoke[700]}>
                    <Typography variant="body2">{item.subtitle}</Typography>
                  </Box>
                </ListItemText>
                <ArrowForwardIos color="primary" />
              </ListItem>
            </Box>
          ))}
        </List>
      </Box>
    </Dialog>
  );
};

const itemPropType = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  key: PropTypes.string
});

RedispatchAgencyDialog.propTypes = {
  title: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(itemPropType).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default RedispatchAgencyDialog;
