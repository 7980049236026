import { colors } from '../principles';

const MuiMobileStepper = {
  root: {
    background: 'transparent'
  },
  dotActive: {
    backgroundColor: colors.bassis[700]
  }
};

export default MuiMobileStepper;
