import { startTransfer } from '../api-rest';
import handleRestAPIError from '../app/utils/rest-api-request';
import { RESPONSE_STATUS } from '../constants';

/**
 * This service will call for StartTransferResource on Loggi Web.
 */
const startTransference = async ({
  transferId,
  vehicleLicensePlate,
  errorHandler
}) => {
  const response = await startTransfer(transferId, vehicleLicensePlate).catch(
    err => handleRestAPIError(err, errorHandler)
  );

  if (!response) return null;

  return {
    transfer: response.data.transfer,
    success: response.data.status === RESPONSE_STATUS.OK,
    errors: [{ message: response.data.error_msg }]
  };
};

export default startTransference;
