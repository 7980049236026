import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getInfoFromPackageIdentifier } from '../../api-rest';
import { BarcodeReader } from '../../app/components/barcode-readers';
import handleRestAPIError from '../../app/utils/rest-api-request';
import DisambiguatePackageDialog from '../../app/components/disambiguate-package-dialog';
import { UNIT_TYPE } from '../../app/enums';
import { GeolocationContext } from '../../geo';
import {
  HTTP_STATUS_CODES,
  OPERATIONAL_PROCESS,
  ACTIVITY
} from '../../constants';
import { extractPkgInfo, getUnit } from './unit-handlers';
import SharedPropTypes from '../../app/shared-prop-types';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { OrganizeProcessContext } from './organize-process-context';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { playActionRequiredBeep } from '../../sounds';
import showSnackbar from '../../app/components/snackbar/snackbar-container';

export const MESSAGES = {
  IMPOSSIBLE_TO_ORGANIZE_LABEL:
    'Eita, não é possível organizar esse pacote. Fale com o suporte.',
  NOT_FOUND_IDENTIFIER:
    'Eita, você bipou um código de barras que não existe. ' +
    'Verifique se o código está correto e tente de novo.',
  LABEL_ALREADY_BEEPED: 'Opa, essa unidade já está na posição!'
};

/**
 * Wrapper to a BarcodeReader that fetches Unit Info
 *
 * @callback onSuccessCallback called when Prepare Info is successfully fetched
 * @callback onErrorCallback called when and error happens while fetching Receive Info
 *
 * @param {boolean} loading - Loading state
 * @param {function} setLoading - Function to change the loading state
 * @param {string} [organizeUnitType] - Type of to organize unit been processed, if already initiated
 * @param {object} [organizeUnitInfo] - Info of to organize unit been processed, if already initiated
 * @param {string} [placeholder] - Placeholder to be show on the input
 * @param {boolean} [disable] - Disables the input
 * @param {function} onSuccessCallback -
 * @param {function} onErrorCallback -
 *
 */
export default function UnitReader({
  onSuccessCallback,
  onErrorCallback,
  loading,
  setLoading,
  organizeUnitType,
  organizeUnitInfo,
  placeholder,
  disable
}) {
  const { sortingContext } = useContext(OrganizeProcessContext);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const [geo] = React.useContext(GeolocationContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { enqueueSnackbar } = useSnackbar();
  const [packagesToDisambiguate, setPackagesToDisambiguate] = useState([]);

  const distributionCenterId = selectedDistributionCenter?.distributionCenterId;

  const showSnackbarAlert = (message, variant = 'success') =>
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });

  const isDuplicatedBeep = barcode => {
    if (organizeUnitType === UNIT_TYPE.UNIT_TYPE_UNIT_LOAD) {
      return organizeUnitInfo?.childrenUnitLoad?.some(
        childUnitLoad => childUnitLoad.childUnitLoad.identifier === barcode
      );
    }
    return false;
  };

  const getPackageIdentifierInfo = async identifier => {
    const response = await getInfoFromPackageIdentifier({
      packageIdentifier: identifier
    });
    return response.data;
  };

  const handlePackageUnit = async pkg => {
    if (isDuplicatedBeep(pkg.identifier)) {
      onErrorCallback(MESSAGES.LABEL_ALREADY_BEEPED, 'warning');
      return;
    }
    const extra = {
      pkg,
      geo,
      distributionCenterId
    };
    const unit = await getUnit({
      type: UNIT_TYPE.UNIT_TYPE_PACKAGE,
      sortingContext,
      organizeUnitType,
      organizeUnitInfo,
      extra
    });

    onSuccessCallback(unit);
  };

  const handleMultiplePackages = pkgIdentifierInfo => {
    const packages = pkgIdentifierInfo.map(pkg => extractPkgInfo(pkg));
    setPackagesToDisambiguate(packages);
  };

  const handlePackageInfo = async pkgIdentifierInfo => {
    if (pkgIdentifierInfo.info.length > 1) {
      handleMultiplePackages(pkgIdentifierInfo.info);
    } else {
      const pkg = extractPkgInfo(pkgIdentifierInfo.info[0]);
      await handlePackageUnit(pkg);
    }
  };

  const handleReadError = async err => {
    const isCustomError = Object.values(MESSAGES).some(
      errorMsg => errorMsg === err?.message
    );

    if (isCustomError) {
      onErrorCallback(err.message);
      return;
    }

    handleRestAPIError(err, errorMsg => onErrorCallback(errorMsg));
  };

  const fetchUnit = async identifier => {
    const extra = { identifier };
    return getUnit({
      type: UNIT_TYPE.UNIT_TYPE_UNIT_LOAD,
      sortingContext,
      organizeUnitType,
      organizeUnitInfo,
      extra
    });
  };

  const handleUnitSuccess = unit => {
    if (unit.warningMsg) {
      showSnackbarAlert(unit.warningMsg, 'warning');
      playActionRequiredBeep();
    }
    onSuccessCallback(unit);
  };

  const handleError = async (err, identifier) => {
    if (err?.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
      try {
        const pkgIdentifierInfo = await getPackageIdentifierInfo(identifier);
        await handlePackageInfo(pkgIdentifierInfo);
      } catch (innerErr) {
        if (innerErr?.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
          handleReadError(Error(MESSAGES.NOT_FOUND_IDENTIFIER));
        } else {
          await handleReadError(innerErr);
        }
      }
    } else {
      await handleReadError(err);
    }
  };

  const handleRead = async identifier => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.ORGANIZE_UNIT_BEEP);

    setLoading(true);

    try {
      const unit = await fetchUnit(identifier);
      handleUnitSuccess(unit);
    } catch (err) {
      await handleError(err, identifier);
    } finally {
      setLoading(false);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.ORGANIZE_UNIT_BEEP);
    }
  };

  const handleDialogCancel = () => {
    setPackagesToDisambiguate([]);
    onErrorCallback(MESSAGES.IMPOSSIBLE_TO_ORGANIZE_LABEL);
  };

  const handleSelectPackage = async selectedPackage => {
    setLoading(true);
    setPackagesToDisambiguate([]);

    await handlePackageUnit(selectedPackage).catch(err => handleReadError(err));
    setLoading(false);
  };

  return (
    <Box>
      <Box my={2.5}>
        <BarcodeReader
          onRead={handleRead}
          loading={loading}
          placeholder={placeholder}
          disable={disable}
        />
      </Box>
      {packagesToDisambiguate.length > 0 && (
        <DisambiguatePackageDialog
          open
          onCancel={handleDialogCancel}
          onSelectPackage={handleSelectPackage}
          packages={packagesToDisambiguate}
        />
      )}
    </Box>
  );
}

UnitReader.defaultProps = {
  loading: false,
  organizeUnitType: UNIT_TYPE.UNIT_TYPE_INVALID,
  organizeUnitInfo: null,
  placeholder: 'Bipe o código da unidade',
  disable: false
};

UnitReader.propTypes = {
  onSuccessCallback: PropTypes.func.isRequired,
  onErrorCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  organizeUnitType: PropTypes.oneOf(Object.values(UNIT_TYPE)),
  organizeUnitInfo: PropTypes.shape({
    pkg: SharedPropTypes.package,
    decision: SharedPropTypes.decision,
    unitLoad: SharedPropTypes.unitLoad,
    childrenUnitLoad: SharedPropTypes.childrenUnitLoad
  }),
  placeholder: PropTypes.string,
  disable: PropTypes.bool
};
