import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
/**
 * This adds a gradient box to the top of the page.
 * You can place the tag you need inside this box to use the gradient style top box.
 *
 * tip: you can see the actions-container.story file for an example.
 *
 * @children tag to be render inside the box
 */
const ActionContainerTop = ({ children }) => {
  return (
    <Box
      data-testid="gradient-top-box"
      position="fixed"
      top="0"
      width="100%"
      style={{
        background:
          'linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #FFFFFF 25%)'
      }}
    >
      <Box pb={3}>{children}</Box>
    </Box>
  );
};

ActionContainerTop.propTypes = {
  children: PropTypes.element.isRequired
};

export default ActionContainerTop;
