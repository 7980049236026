import { colors } from '../../principles';

export default {
  ...colors,
  brand: {
    lighter: '#E6F8FF',
    light: '#C0EEFF',
    pure: '#00BAFF'
  },
  primary: {
    transparent: 'rgba(0, 106, 255, 0.8)',
    light: '#E5F0FF',
    pure: '#006AFF',
    dark: '#004BB4',
    darker: '#003B99'
  },
  secondary: {
    light: '#EBE8FD',
    pure: '#4C2DEB',
    dark: '#1C0B75'
  },
  status: {
    positive: {
      pure: '#4FC2A0',
      light: '#D7F9EF'
    },
    attention: {
      pure: '#F8BD57',
      light: '#FAEACC'
    },
    negative: {
      pure: '#EB6D6D',
      light: '#FFE5E5'
    }
  },
  neutrals: {
    shapes: {
      transparent: 'rgba(51, 51, 51, 0.8)',
      lighter: '#F2F2F2',
      light: '#E6E6E6',
      dark: '#333333'
    },
    typeface: {
      primary: '#1A1A1A',
      secondary: '#666666'
    },
    white: {
      pure: '#FFFFFF',
      transparent: 'rgba(255, 255, 255, 0.8)'
    }
  }
};
