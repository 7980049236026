const EMAIL_INPUT_BUTTON_ID = 'email-input';
const FORGOT_PASSWORD_LINK_ID = 'forgot-password-link-id';
const LOADING_ID = 'loading-id';
const PASSWORD_RECOVERY_BUTTON_ID = 'submit-btn';
const PASSWORD_RECOVERY_ERRORS = [
  'UserNotFoundException',
  'LimitExceededException',
  'NetworkError',
  'NotAuthorizedException'
];

export {
  EMAIL_INPUT_BUTTON_ID,
  FORGOT_PASSWORD_LINK_ID,
  LOADING_ID,
  PASSWORD_RECOVERY_BUTTON_ID,
  PASSWORD_RECOVERY_ERRORS
};
