import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

/**
 * This hook uses the useLocation hook provided by react-router-dom to get the
 * queryParams present on the URL, and parse them using the qs package.
 * Returning then an useful js object, encapsulating the dirty work of
 * parsing the location search string.
 *
 * @example
 *
 *    url: /path?page=1&pageSize=10
 *    useQueryParams() produces => { page: 1, pageSize: 10 }
 *
 * @returns {{}}
 */
const useQueryParams = () => {
  const { search } = useLocation();

  return parse(search, { ignoreQueryPrefix: true });
};

export default useQueryParams;
