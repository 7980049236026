import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import { BUTTON_BORDER_RADIUS } from '@loggi/mar/src/constants';
import { Box, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

export default function SelectableButton({
  dataTestId,
  label,
  onClick,
  isSelected,
  noWrapLabel
}) {
  const blueColor = colors.blue[500];

  return (
    <ButtonBase onClick={onClick} disableRipple>
      <Box
        borderRadius={pxToRem(BUTTON_BORDER_RADIUS)}
        padding={`${pxToRem(12)} ${pxToRem(30)}`}
        border={
          isSelected
            ? `${pxToRem(1)} solid ${blueColor}`
            : `${pxToRem(1)} solid ${colors.smoke[300]}`
        }
        color={isSelected ? blueColor : colors.smoke[1000]}
        bgcolor={isSelected ? fade(blueColor, 0.1) : null}
        data-testid={dataTestId}
        data-isselected={isSelected}
      >
        <Typography variant="body2" noWrap={noWrapLabel}>
          {label}
        </Typography>
      </Box>
    </ButtonBase>
  );
}

SelectableButton.defaultProps = {
  onClick: () => {},
  isSelected: false,
  noWrapLabel: false
};

SelectableButton.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  noWrapLabel: PropTypes.bool
};
