import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { pxToRem } from '../utils';

const breakpoints = createBreakpoints({});

const MuiContainer = {
  root: {
    paddingLeft: pxToRem(28),
    paddingRight: pxToRem(28),
    [breakpoints.up('sm')]: {
      paddingLeft: pxToRem(44),
      paddingRight: pxToRem(44)
    }
  }
};

export default MuiContainer;
