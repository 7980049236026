import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './index.css';
import * as Sentry from '@sentry/browser';
import {
  AmplifyAuthProvider,
  AmplifyConfigure
} from '@loggi/authentication-lib';
import { SnackbarProvider } from 'notistack';
import theme, { colors } from '@loggi/mar';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { App } from './app/App';
import './configuration/i18n-init';
import './firebase/firebase-init';
import awsmobile from './aws-config';
import * as serviceWorkerOperations from './service-worker-operations';
import { FeatureSwitchProvider } from './firebase/feature-switch-provider';

AmplifyConfigure(awsmobile);

const extendedTheme = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    header: {
      gradient: colors.gradients.moonlight
    }
  }
});

const ENV = process.env.REACT_APP_ENV;

if (ENV === 'production' || ENV === 'staging' || ENV === 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: ENV,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error?.response?.status === 403) {
        return null;
      }

      return event;
    }
  });
}

if (ENV === 'production') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <ThemeProvider theme={extendedTheme}>
    <FeatureSwitchProvider>
      <AmplifyAuthProvider>
        <Router>
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'top'
            }}
            autoHideDuration={6000}
            maxSnack={3}
          >
            <App />
          </SnackbarProvider>
        </Router>
      </AmplifyAuthProvider>
    </FeatureSwitchProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorkerOperations.register();
