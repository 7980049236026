import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from '../../../routes/constants';
import RecoveryPasswordForm from '../recovery-password-form';

const ForgotPasswordRoutes = () => (
  <Switch>
    <Route
      exact
      path={FORGOT_PASSWORD_ROUTE}
      component={RecoveryPasswordForm}
    />
    <Redirect to={FORGOT_PASSWORD_ROUTE} />
  </Switch>
);

export default ForgotPasswordRoutes;
