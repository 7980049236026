export const removePhoneInputMask = (phoneNumber = '') =>
  phoneNumber.replace(/\D/g, '');

export const isMobilePhoneNumber = phoneNumber => {
  const rawPhoneNumber = removePhoneInputMask(phoneNumber);
  return rawPhoneNumber[2] === '9';
};

export const formatPhoneMask = phoneNumber => {
  const rawPhoneNumber = removePhoneInputMask(phoneNumber);
  return rawPhoneNumber
    .replace(/(\d{11})(\d)/, '$1')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3');
};
