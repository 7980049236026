import * as Sentry from '@sentry/browser';

const errorHandler = (error, data = null) => {
  Sentry.addBreadcrumb({
    message: `${error.name}`,
    level: 'error',
    data,
    type: 'error'
  });
  Sentry.captureException(error);
};

export default errorHandler;
