import { colors } from '../principles';
import { pxToRem } from '../utils';

const MuiTableCell = {
  root: {
    height: pxToRem(64),
    paddingLeft: pxToRem(28),
    paddingRight: pxToRem(28),
    borderBottomColor: colors.smoke['100']
  },
  head: {
    backgroundColor: colors.smoke['50'],
    borderBottom: 0,
    color: colors.smoke['700'],
    height: pxToRem(48),
    paddingBottom: pxToRem(12),
    paddingTop: pxToRem(12),
    '&:first-child': {
      borderRadius: '8px 0 0 8px'
    },
    '&:last-child': {
      borderRadius: '0 8px 8px 0'
    }
  }
};

export default MuiTableCell;
