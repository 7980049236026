/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  ForgotPassword,
  Signin,
  Signup,
  ValidateRecoveryPassword
} from '../screens';
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
  VALIDATE_PASSWORD_RECOVERY
} from './constants';

const AuthenticationRoutes = ({ target, signUpRedirect }) => {
  const { pathname, search } = useLocation();
  const urlRedirect = `${pathname}${search}`;

  return (
    <Switch>
      <Route
        exact
        path={LOGIN_ROUTE}
        render={props => (
          <Signin target={target} signUpRedirect={signUpRedirect} {...props} />
        )}
      />
      <Route
        path={VALIDATE_PASSWORD_RECOVERY}
        component={ValidateRecoveryPassword}
      />
      <Route path={SIGN_UP_ROUTE} component={Signup} />
      <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
      <Redirect to={{ pathname: LOGIN_ROUTE, state: { from: urlRedirect } }} />
    </Switch>
  );
};

AuthenticationRoutes.propTypes = {
  target: PropTypes.string,
  signUpRedirect: PropTypes.string
};

AuthenticationRoutes.defaultProps = {
  target: '',
  signUpRedirect: undefined
};

export default AuthenticationRoutes;
