import { CoverageParamsTranslations } from '../../one/coverage-params';
import { errorBoundaryTranslations } from '../../one/error-boundaries';
import { FormikFieldsTranslations } from '../../one/formik-fields';
import onePtBr from './one.translations.pt-br.json';
import daySummary from './day-summary.json';

export default {
  one: {
    ...errorBoundaryTranslations['pt-BR'],
    ...FormikFieldsTranslations['pt-BR'],
    ...CoverageParamsTranslations['pt-BR'],
    ...onePtBr,
    ...daySummary
  }
};
