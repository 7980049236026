const ACCESS_DENIED = '/acesso-negado';
const COMPANIES_ROUTE = '/empresas';
const FORGOT_PASSWORD_ROUTE = '/recuperar-senha';
const LOGIN_ROUTE = '/login';
const SIGN_UP_ROUTE = '/cadastro';
const VALIDATE_PASSWORD_RECOVERY = '/confirmar-recuperacao';

export {
  ACCESS_DENIED,
  COMPANIES_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
  VALIDATE_PASSWORD_RECOVERY
};
