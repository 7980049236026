import SuccessBeep from './assets/sounds/beep_success.wav';
import ErrorBeep from './assets/sounds/beep_error.wav';
import ActionRequiredBeep from './assets/sounds/beep_action_required.wav';
import CameraClick from './assets/sounds/camera_click.wav';

export function playSuccessBeep() {
  const audio = new Audio(SuccessBeep);
  audio.play();
}

export function playErrorBeep() {
  const audio = new Audio(ErrorBeep);
  audio.play();
}

export function playActionRequiredBeep() {
  const audio = new Audio(ActionRequiredBeep);
  audio.play();
}

export function playActionCameraClick() {
  const audio = new Audio(CameraClick);
  audio.play();
}
