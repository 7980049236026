import { colors } from '../principles';

const MuiInputBase = {
  root: {
    '&$disabled': {
      backgroundColor: colors.smoke[50]
    }
  }
};

export default MuiInputBase;
