import {
  editPackageStatus,
  physicalEvidenceStatuses,
  createPackageDirectionChange
} from '../api-rest';
import handleRestAPIError from '../app/utils/rest-api-request';
import { RESPONSE_STATUS } from '../constants';

export const listPackageStatuses = async ({ errorHandler }) => {
  const response = await physicalEvidenceStatuses().catch(err =>
    handleRestAPIError(err, errorHandler)
  );

  if (!response) return null;

  // Rename the object keys to be consistent with the graphQL response.
  const parsedStatusCodeDisplay = response.data.statusCodeDisplay.map(item => {
    return { value: item.statusCode, display: item.display };
  });

  return parsedStatusCodeDisplay;
};

export const editStatus = async ({
  checkId,
  newStatusCode,
  notes,
  errorHandler
}) => {
  const response = await editPackageStatus(checkId, newStatusCode, notes).catch(
    err => handleRestAPIError(err, errorHandler)
  );

  if (!response) return null;

  return {
    success: response.data.status === RESPONSE_STATUS.OK,
    errors: [{ message: response.data.error_msg }]
  };
};

export const editDirection = async ({
  packageId,
  directionValue,
  reasonValue,
  notes,
  errorHandler
}) => {
  const response = await createPackageDirectionChange(
    packageId,
    directionValue,
    reasonValue,
    notes
  ).catch(err => handleRestAPIError(err, errorHandler));

  if (!response) return null;

  return {
    success: response.status === 200,
    errors: [{ message: response.data.status.message }]
  };
};
