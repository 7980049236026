import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useCoverageParamsContext } from '../coverage-params';
import { useFeature } from '../remote-config';
import {
  MOMENT_SHIFT_END_WITH_GAP,
  MOMENT_SHIFT_START,
  MIN_COLLECT_TIME_WINDOW_HOUR_DEFAULT,
  MIN_COLLECT_TIME_WINDOW_MINUTES_DEFAULT,
  TIME_DIFF_BETWEEN_SCHEDULINGS_IN_MINUTES
} from './constants';
import BaseTimeField from './base-time-field.component';

/**
 * <StartTimeField /> - This component is only a wrapper to apply a custom
 * validation logic to the <BaseTimeField />
 * @returns {*}
 * @constructor
 */
const EndTimeField = ({ dateFieldName, fieldName, startTimeFieldName }) => {
  const { t } = useTranslation('one');
  const { values, errors } = useFormikContext();
  const coverageParamsContext = useCoverageParamsContext();
  const shouldUseCoverageParams = useFeature('should_use_coverage_params');

  const startTime = values[startTimeFieldName];
  const invalidDate = Boolean(errors[dateFieldName]);

  let momentShiftStart = MOMENT_SHIFT_START;
  let momentShiftEndWithGap = MOMENT_SHIFT_END_WITH_GAP;
  const totalMinCollectTimeWindowMinutes = TIME_DIFF_BETWEEN_SCHEDULINGS_IN_MINUTES;
  let minCollectTimeWindowHours = MIN_COLLECT_TIME_WINDOW_HOUR_DEFAULT;
  let minCollectTimeWindowMinutes = MIN_COLLECT_TIME_WINDOW_MINUTES_DEFAULT;

  if (shouldUseCoverageParams) {
    momentShiftStart =
      coverageParamsContext?.momentShiftStart || MOMENT_SHIFT_START;
    momentShiftEndWithGap =
      coverageParamsContext?.momentShiftEndWithGap || MOMENT_SHIFT_END_WITH_GAP;
    minCollectTimeWindowHours =
      coverageParamsContext?.minCollectTimeWindowHours;
    minCollectTimeWindowMinutes =
      coverageParamsContext?.minCollectTimeWindowMinutes;
  }

  const validate = useCallback(
    endTime => {
      if (invalidDate) {
        return undefined;
      }
      if (!endTime) {
        return t('baseTimeField.errorMessages.requiredEndTime');
      }
      if (!endTime.isValid()) {
        return t('baseTimeField.errorMessages.invalidTime');
      }
      if (
        endTime.isBefore(momentShiftStart, 'hour') ||
        endTime.isAfter(momentShiftEndWithGap, 'minute')
      ) {
        return t('baseTimeField.errorMessages.timeRangeOutOfService', {
          endTime: momentShiftEndWithGap.format('HH:mm'),
          startTime: momentShiftStart.format('HH:mm')
        });
      }
      if (
        startTime &&
        endTime.diff(startTime, 'minutes') < totalMinCollectTimeWindowMinutes
      ) {
        if (minCollectTimeWindowHours === 0) {
          return t('baseTimeField.errorMessages.timeRangeInMinutes', {
            count: minCollectTimeWindowMinutes
          });
        }

        if (minCollectTimeWindowMinutes === 0) {
          return t('baseTimeField.errorMessages.timeRangeInHour', {
            count: minCollectTimeWindowHours
          });
        }

        return t('baseTimeField.errorMessages.smallTimeRange', {
          count: minCollectTimeWindowHours,
          minutes: minCollectTimeWindowMinutes
        });
      }
      return undefined;
    },
    [
      invalidDate,
      momentShiftEndWithGap,
      momentShiftStart,
      startTime,
      t,
      minCollectTimeWindowHours,
      minCollectTimeWindowMinutes,
      totalMinCollectTimeWindowMinutes
    ]
  );

  return (
    <BaseTimeField
      disabled={invalidDate}
      fieldName={fieldName}
      label={t('baseTimeField.endTimeLabel')}
      validate={validate}
    />
  );
};

EndTimeField.propTypes = {
  startTimeFieldName: PropTypes.string,
  dateFieldName: PropTypes.string,
  fieldName: PropTypes.string.isRequired
};

EndTimeField.defaultProps = {
  startTimeFieldName: undefined,
  dateFieldName: undefined
};

export default EndTimeField;
