import { PopupButton } from '@typeform/embed-react';
import decamelizeKeys from 'decamelize-keys';
import PropTypes from 'prop-types';
import React from 'react';

export default function SortSurvey({
  routingCode,
  closeSurvey,
  submitSurvey,
  email
}) {
  const formId = `${process.env.REACT_APP_SORT_SURVEY_FORM_ID}`;
  const hiddenFields = decamelizeKeys({
    routingCode,
    email
  });

  return (
    <PopupButton
      id={formId}
      hidden={hiddenFields}
      open="load"
      autoClose
      hideFooter
      hideHeaders
      style={{ display: 'none' }}
      data-testid="sort-survey-form"
      onClose={closeSurvey}
      onSubmit={submitSurvey}
    />
  );
}

SortSurvey.propTypes = {
  routingCode: PropTypes.string.isRequired,
  closeSurvey: PropTypes.func.isRequired,
  submitSurvey: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired
};
