import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NameField = ({ fieldName, shouldRenderRequiredAsterisk }) => {
  const { t } = useTranslation('one');
  const [field, meta] = useField({
    name: fieldName,
    validate: newValue => {
      const minLengthName = 3;
      let errorMessage = '';
      if (!newValue) {
        errorMessage = t('nameField.errorMessages.requiredField');
      } else if (newValue?.length < minLengthName) {
        errorMessage = t('nameField.errorMessages.minLengthName');
      }
      return errorMessage;
    }
  });

  const { name, onChange, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field` }}
      InputLabelProps={{ required: shouldRenderRequiredAsterisk }}
      label={t('nameField.label')}
      placeholder={t('nameField.placeholder')}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required
      value={value}
      variant="outlined"
    />
  );
};

NameField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  shouldRenderRequiredAsterisk: PropTypes.bool
};

NameField.defaultProps = {
  shouldRenderRequiredAsterisk: true
};

export default NameField;
