import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Dialog,
  List,
  Divider,
  Container,
  Checkbox,
  ListItem
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import HeaderWithReturn from '../../app/components/header-with-return';
import pluralize from '../../app/utils/pluralize';
import Confirmation from '../../app/components/confirmation';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import SharedPropTypes from '../../app/shared-prop-types';

const useStyles = makeStyles({
  dialog: {
    padding: 0
  }
});

const EditPackagesDialog = ({
  open,
  pkgsToPrepareInfo,
  onRemovePackages,
  onReturn
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [checkedPackages, setCheckedPackages] = React.useState([]);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const isPackageChecked = selectedPackage =>
    checkedPackages.some(pkg => pkg.identifier === selectedPackage.identifier);

  const allPackagesChecked =
    checkedPackages.length === pkgsToPrepareInfo.length;

  const handleSelectedPackage = selectedPackage => {
    setIsCheckedSelectAll(false);

    if (isPackageChecked(selectedPackage)) {
      setCheckedPackages(
        checkedPackages.filter(
          pkg => pkg.identifier !== selectedPackage.identifier
        )
      );
      return;
    }

    setCheckedPackages([...checkedPackages, selectedPackage]);
  };

  const handleSelectAllChange = () => {
    setCheckedPackages([]);

    if (isCheckedSelectAll) {
      setIsCheckedSelectAll(false);
      return;
    }

    setCheckedPackages(pkgsToPrepareInfo.map(info => info.pkg));
    setIsCheckedSelectAll(true);
  };

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const getTitleText = () => {
    if (checkedPackages.length > 1) {
      return `Você quer remover <b>${
        checkedPackages.length
      } pacotes selecionados</b> da sua lista?`;
    }

    return `Você quer remover o pacote de <b>${
      checkedPackages[0]?.recipient?.name
    } - ${checkedPackages[0]?.identifier}</b> da sua lista?`;
  };

  const handleCancelRemove = () => {
    setOpenConfirmation(false);
  };

  const handleRemoveFromList = () => {
    setOpenConfirmation(true);
  };

  const handleConfirmRemove = () => {
    onRemovePackages(checkedPackages.map(pkg => pkg.identifier));
    setCheckedPackages([]);
    setOpenConfirmation(false);
    showSnackbarAlert('Boa! Pacotes removidos da lista.');
    onReturn();
  };

  useEffect(() => {
    if (allPackagesChecked) setIsCheckedSelectAll(true);
  }, [allPackagesChecked]);

  return (
    <Dialog
      open={open}
      fullScreen
      data-testid="edit-packages-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
      className={classes.dialog}
    >
      <HeaderWithReturn onReturn={onReturn} />
      <Box overflow="hidden" clone>
        <Container maxWidth="xs" disableGutters>
          <Box
            pt={8}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100vh"
          >
            <Box px={{ xs: '1.75rem', sm: '2.75rem' }}>
              <Box display="flex" alignItems="center" my={2}>
                <Box mr={2}>
                  <Checkbox
                    edge="start"
                    inputProps={{ 'data-testid': 'select-all-checkbox' }}
                    checked={isCheckedSelectAll}
                    color="primary"
                    onChange={handleSelectAllChange}
                  />
                </Box>
                <Box>
                  <Typography variant="body1">
                    <Box component="span" fontWeight="fontWeightMedium">
                      {allPackagesChecked
                        ? 'Todos selecionados'
                        : `${checkedPackages.length} ${pluralize({
                            singular: 'selecionado',
                            count: checkedPackages.length
                          })}`}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box flex={1} overflow="auto">
              <PackageList
                pkgsToPrepareInfo={pkgsToPrepareInfo}
                onSelectPackage={handleSelectedPackage}
                isPackageChecked={isPackageChecked}
              />
            </Box>
            <Box
              mt={1.5}
              mb={{ xs: 3, sm: 2.5 }}
              px={{ xs: '1.75rem', sm: '2.75rem' }}
            >
              <Button
                onClick={handleRemoveFromList}
                data-testid="remove-packages"
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={!checkedPackages.length}
              >
                Remover da lista
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      {openConfirmation && (
        <Confirmation
          open
          subtitleText={getTitleText()}
          onConfirm={handleConfirmRemove}
          confirmText="Confirmar"
          onCancel={handleCancelRemove}
          cancelText="Manter na lista"
        />
      )}
    </Dialog>
  );
};

EditPackagesDialog.defaultProps = {
  open: true
};

EditPackagesDialog.propTypes = {
  open: PropTypes.bool,
  pkgsToPrepareInfo: SharedPropTypes.pkgsToPrepareInfo.isRequired,
  onRemovePackages: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired
};

const PackageList = ({
  pkgsToPrepareInfo,
  onSelectPackage,
  isPackageChecked
}) => {
  return (
    <List>
      {pkgsToPrepareInfo.map(info => (
        <Box key={`box-${info.pkg.identifier}`}>
          <Divider />
          <PackageItem
            pkg={info.pkg}
            key={`package-${info.pkg.identifier}`}
            onSelectPackage={() => onSelectPackage(info.pkg)}
            isChecked={isPackageChecked(info.pkg)}
          />
        </Box>
      ))}
    </List>
  );
};

PackageList.propTypes = {
  pkgsToPrepareInfo: SharedPropTypes.pkgsToPrepareInfo.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  isPackageChecked: PropTypes.func.isRequired
};

const usePackageItemStyles = makeStyles({
  listItem: {
    '&.Mui-selected': {
      backgroundColor: `${colors.blue[500]}14`,
      '&:hover': {
        backgroundColor: `${colors.blue[500]}14`
      }
    }
  }
});

const PackageItem = ({ pkg, onSelectPackage, isChecked }) => {
  const classes = usePackageItemStyles();

  return (
    <ListItem
      button
      onClick={onSelectPackage}
      data-testid={`package-${pkg.identifier}`}
      disableGutters
      selected={isChecked}
      className={classes.listItem}
    >
      <Box
        display="flex"
        width="100%"
        my={1}
        px={{ xs: '1.75rem', sm: '2.75rem' }}
      >
        <Box mr={2}>
          <Checkbox edge="start" checked={isChecked} color="primary" />
        </Box>
        <Box flex={1} minWidth={0} style={{ overflowWrap: 'break-word' }}>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography>
                <Box component="subtitle2" fontWeight="fontWeightMedium">
                  {pkg.identifier}
                </Box>
              </Typography>
            </Box>
            <Box py={1} color={colors.smoke[700]}>
              <Typography variant="subtitle2">
                {`Pacote de ${pkg.company?.sharedName || pkg.company?.name}`}
              </Typography>
              <Typography variant="subtitle2">
                {` para ${pkg.recipient.name}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

PackageItem.propTypes = {
  pkg: SharedPropTypes.package.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired
};

export default EditPackagesDialog;
