import * as Sentry from '@sentry/browser';
import { logLogicalMismatches } from '../../api-rest';

const mountRequestPayload = (packages, userId, operationalProcess) => {
  return packages.map(pack => ({
    package_id: pack.pk,
    package_barcode: pack.identifier,
    operator_user_id: userId,
    operational_process: operationalProcess
  }));
};

const logLogicalMismatchesService = async ({
  lat,
  lng,
  sortingContextLicensePlate,
  userId,
  unitLoadLicensePlate,
  operationalProcess,
  packages
}) => {
  const packagesInLogicalMismatch = mountRequestPayload(
    packages,
    userId,
    operationalProcess
  );

  await logLogicalMismatches(
    lat,
    lng,
    sortingContextLicensePlate,
    unitLoadLicensePlate,
    packagesInLogicalMismatch
  ).catch(Sentry.captureException);
};

export default logLogicalMismatchesService;
