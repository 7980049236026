import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import PhotoTemplate from '../../../app/components/photo-template';
import { TESTID } from './constants';

export default function DamagePhotoUpload({
  onConfirmSend,
  titleComponent,
  titleAlt,
  cameraHeight
}) {
  return (
    <Box>
      <Box data-testid={TESTID.DAMAGE_PHOTO_TITLE} clone>
        {titleComponent}
      </Box>
      <Box pb={1} data-testid={TESTID.DAMAGE_PHOTO_CONTAINER}>
        <Box my={1}>
          <PhotoTemplate
            onImageCapture={onConfirmSend}
            cameraHeight={cameraHeight}
            imgAlt={titleAlt}
            withBorderRadius={false}
          />
        </Box>
      </Box>
    </Box>
  );
}

DamagePhotoUpload.propTypes = {
  onConfirmSend: PropTypes.func.isRequired,
  titleComponent: PropTypes.element.isRequired,
  titleAlt: PropTypes.string,
  cameraHeight: PropTypes.string
};

DamagePhotoUpload.defaultProps = {
  titleAlt: '',
  cameraHeight: '25rem'
};
