import React, { createRef, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { configure, ScanSettings, Barcode, BarcodePicker } from 'scandit-sdk';

const useStyles = makeStyles(() => ({
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}));

const videoElementId = 'videoScanner';
const timeBetweenScansMillis = 1500;

export function handleBarcodeReading(onDetected, result, err) {
  if (result) {
    onDetected(result.text);
  }

  // ignoring NotFoundException because it happens while
  // the user is not scanning a barcode
  if (err && !(err instanceof NotFoundException)) {
    throw Error(err);
  }
}

export function ZebraCameraScanner({ onDetected }) {
  const classes = useStyles();

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader(
      null,
      timeBetweenScansMillis
    );

    codeReader.decodeFromVideoDevice(null, videoElementId, (result, err) => {
      handleBarcodeReading(onDetected, result, err);
    });

    return () => {
      codeReader.reset();
    };

    // We are disabling it, because we're not passing onDetected as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // We are disabling this rule because we don't need track options
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video id={videoElementId} className={classes.video} />
  );
}

ZebraCameraScanner.propTypes = {
  onDetected: PropTypes.func.isRequired
};

export function ScanditCameraScanner({ onDetected }) {
  const classes = useStyles();
  const videoRef = createRef();
  const scanditBarcodePicker = useRef(null);

  useEffect(() => {
    configure(process.env.REACT_APP_SCANDIT_LICENSE_KEY, {
      engineLocation: process.env.REACT_APP_SCANDIT_ENGINE_LOCATION
    }).then(() => {
      BarcodePicker.create(videoRef.current, {
        scanSettings: new ScanSettings({
          enabledSymbologies: [
            Barcode.Symbology.EAN8,
            Barcode.Symbology.EAN13,
            Barcode.Symbology.UPCA,
            Barcode.Symbology.UPCE,
            Barcode.Symbology.CODE128,
            Barcode.Symbology.CODE39,
            Barcode.Symbology.CODE93,
            Barcode.Symbology.INTERLEAVED_2_OF_5,
            Barcode.Symbology.QR
          ],
          // When the same code is scanned within 1500ms
          // it is filtered out as a duplicate
          codeDuplicateFilter: 1500
        }),
        videoFit: BarcodePicker.ObjectFit.COVER,
        playSoundOnScan: true
      }).then(barcodePicker => {
        scanditBarcodePicker.current = barcodePicker;

        barcodePicker.on('scan', scanResult => {
          const { barcodes } = scanResult;
          // get the first barcode, as it can return multiple
          onDetected(barcodes[0].data);
        });
      });
    });

    return () => {
      if (scanditBarcodePicker.current != null) {
        scanditBarcodePicker.current.destroy();
        scanditBarcodePicker.current = null;
      }
    };
    // We are disabling it, because we're not passing onDetected as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={videoRef} className={classes.video} />;
}

ScanditCameraScanner.propTypes = {
  onDetected: PropTypes.func.isRequired
};
