import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { colors } from '@loggi/mar';
import parseDateWithTimezone from '../../app/utils/parse-date-with-timezone';
import {
  STRING_NULL,
  STRING_UNDEFINED,
  NO_PROCESSING_DAEADLINE
} from '../../constants';

export default function ProcessDeadlineClusterTitle({ cluster }) {
  let late = false;
  let title = NO_PROCESSING_DAEADLINE;

  const hasProcessDeadline =
    cluster && cluster !== STRING_NULL && cluster !== STRING_UNDEFINED;

  if (hasProcessDeadline) {
    const parsedDate = parseDateWithTimezone({ date: cluster });
    late = moment(parsedDate).isBefore(moment(), 'day');
    title = moment(parsedDate).format('DD [de] MMMM');
  }

  return (
    <Box display="flex">
      <Box fontWeight="fontWeightBold" mr={1.5}>
        {title}
      </Box>
      {late && (
        <ErrorIcon data-testid="late-unit" style={{ color: colors.red[500] }} />
      )}
    </Box>
  );
}

ProcessDeadlineClusterTitle.propTypes = {
  cluster: PropTypes.string.isRequired
};
