export const TEXT = {
  DAMAGED_PACKAGE_NOT_VALID:
    'Este pacote não pode ser declarado como avariado.',
  DAMAGED_BTN: 'Está danificado',
  ANALYSIS: 'Análise:',
  TICKET: 'Ticket: ',
  STATUS: 'Status: ',
  JIRA_BROWSER_URL: 'https://loggidev.atlassian.net/browse/',
  CLOSE: 'Fechar',
  CONTINUE: 'Continuar',
  NEW_REQUEST: 'Nova solicitação',
  DETAIL_ANALYSIS: 'Detalhe de análise'
};

export const JIRA_TITLE_BY_STATUS = {
  Resolvido: 'Em breve seu status será alterado',
  'Solicitação Reprovada':
    'A análise do pacote danificado foi concluída e teve sua solicitação negada.',
  'Não consertaremos':
    'A análise do pacote danificado foi concluída e teve sua solicitação negada.',
  'Informação Não Recebida':
    'A análise do pacote danificado foi concluída e teve sua solicitação negada.',
  'Análise CS': 'Solicitação em análise',
  Triagem: 'Solicitação em análise',
  'Necessita Informações':
    'Precisamos de mais informações para concluir a análise do pacote danificado. Consulte o ticket no Jira para continuar o processo.'
};

export const CLOSE_BTN_PROP = {
  Resolvido: { text: 'Fechar', variant: 'outlined' },
  'Solicitação Reprovada': { text: 'Fechar', variant: 'outlined' },
  'Não consertaremos': { text: 'Fechar', variant: 'outlined' },
  'Informação Não Recebida': { text: 'Fechar', variant: 'outlined' },
  'Análise CS': { text: 'Entendi', variant: 'contained' },
  Triagem: { text: 'Entendi', variant: 'contained' },
  'Necessita Informações': { text: 'Entendi', variant: 'contained' }
};

export const DAMAGED_PACKAGE_ERROR = {
  REASON_DAMAGED_PACKAGE_DAMAGED:
    'Opa! Pacote com status de "Avaria". Procure seu supervisor e veja como seguir.',
  REASON_DAMAGED_PACKAGE_LOST:
    'Opa! Pacote com status de "Extravio". Procure seu supervisor e veja como seguir.',
  REASON_DAMAGED_PACKAGE_STOLEN:
    'Opa! Pacote com status de "Roubo/Furto". Procure seu supervisor e veja como seguir.',
  REASON_DAMAGED_PACKAGE_DATE:
    'Opa! Pacote com última movimentação a mais de 2 meses. Procure seu supervisor e veja como seguir.',
  REASON_DAMAGED_PACKAGE_PENDING:
    'Opa! Este pacote terá seu status alterado em breve.'
};

export const JIRA_STATUS_IN_PROGRESS = ['Análise CS', 'Triagem'];

export const JIRA_SUB_TITLE_IN_PROGRESS =
  'As informações do pacote danificado estão sendo avaliadas. Aguarde a conclusão da análise.';

export const DAMAGED_PACKAGE_REASON_JIRA = 'REASON_DAMAGED_PACKAGE_AUDIT';

export const TESTID = {
  DAMAGED_PACKAGE_MENU: 'identify-edit-menu',
  CLOSE_BUTTON: 'close-button',
  NEW_REQUEST_BUTTON: 'new-request-button'
};
