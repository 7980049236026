import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import pluralize from '../../app/utils/pluralize';

const useStyles = makeStyles({
  expandedButtons: {
    marginBottom: pxToRem(15)
  },
  dialog: {
    padding: 0
  },
  buttonText: {
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  }
});

export default function RemainingWarningDialog({
  packageCount,
  handleClose,
  proceed
}) {
  const classes = useStyles();
  const packageText = pluralize({ singular: 'pacote', count: packageCount });
  const wasText = pluralize({
    singular: 'estava',
    count: packageCount,
    pluralChar: 'm'
  });

  return (
    <Dialog
      data-testid="remaining-warning"
      open
      fullScreen
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
    >
      <DialogTitle className={classes.dialog}>
        <Container maxWidth="xs">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingTop={2.5}
          >
            <IconButton
              edge="start"
              data-testid="close-remaining-warning"
              onClick={handleClose}
            >
              <ArrowBack color="primary" />
            </IconButton>
          </Box>
        </Container>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Container maxWidth="xs">
          <Box paddingTop={1.5}>
            <Typography variant="h5" align="left">
              Opa, você não bipou todos os pacotes.
            </Typography>
            <Typography
              data-testid="remaining-dialog-package-count"
              variant="h3"
              align="left"
            >
              {packageCount}
            </Typography>
            <Typography variant="body1">
              <Box>{`${packageText} faltando`}</Box>
            </Typography>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <Container maxWidth="xs">
          <Box>
            <Button
              data-testid="proceed-with-remaining-packages"
              size="large"
              variant="contained"
              color="primary"
              onClick={proceed}
              fullWidth
              className={classes.expandedButtons}
            >
              <Box className={classes.buttonText}>
                {`${packageText} não ${wasText} na saca`}
              </Box>
            </Button>
            <Button
              data-testid="not-proceed-with-remaining-packages"
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleClose}
              fullWidth
              className={classes.expandedButtons}
            >
              Voltar
            </Button>
          </Box>
        </Container>
      </DialogActions>
    </Dialog>
  );
}

RemainingWarningDialog.propTypes = {
  packageCount: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired
};
