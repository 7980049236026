import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function SkeletonList() {
  return (
    <Box marginTop={3}>
      <Skeleton
        component={Box}
        height={40}
        width="50%"
        ml={3}
        pb={5}
        paddingX={3}
      />

      <Skeleton component={Box} width="60%" ml={3} paddingX={3} />

      <Skeleton component={Box} width="80%" ml={3} paddingX={3} />
      <Box marginTop={3}>
        <Divider component={Box} />
      </Box>
    </Box>
  );
}
