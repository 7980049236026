/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  pendingIndicator: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-12px'
  }
}));

/**
 * https://github.com/mui-org/material-ui/issues/21593
 *
 * When the material-ui LoadingButton in available, we can change this
 * component to use it.
 *
 * Important points to consider:
 *  1 - "onClick" should be an async function or promise.
 *  2 - This is a wrapper to a common button, so you can use any normal button props.
 *  3 - Pass your custom condition to disable the button on "isDisabled".
 */
export default function AsyncButton({
  children,
  onClick,
  isDisabled,
  ...rest
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const handleAsyncClickHandler = async () => {
    if (loading) return;
    setLoading(true);
    await onClick().catch(Sentry.captureException);
    setLoading(false);
  };

  return (
    <Button
      {...rest}
      onClick={handleAsyncClickHandler}
      disabled={loading || isDisabled}
    >
      {children}
      {loading && (
        <CircularProgress size={24} className={classes.pendingIndicator} />
      )}
    </Button>
  );
}

AsyncButton.defaultProps = {
  onClick: () => {},
  isDisabled: false
};

AsyncButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool
};
