import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography
} from '@material-ui/core';
import AppReloadImage from '../../assets/images/app-reload.svg';
import usePWAUpdate from '../hooks/use-pwa-update';

const UpdateAvailableDialog = () => {
  const [open, setOpen] = React.useState(false);

  const { theresAnUpdateAvailable, updateApp } = usePWAUpdate();

  useEffect(() => {
    if (theresAnUpdateAvailable) {
      setOpen(true);
    }
  }, [theresAnUpdateAvailable]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="xs" onClose={handleClose}>
      <DialogContent>
        <Box
          data-testid="update-available-dialog"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            align="center"
            variant="body1"
            style={{ fontWeight: '600' }}
            gutterBottom
          >
            Opa, tem uma nova versão do aplicativo disponível!
          </Typography>
          <Box py={4}>
            <img src={AppReloadImage} alt="Loggi" />
          </Box>
          <Button
            data-testid="update-app-button"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => updateApp()}
          >
            Atualizar agora
          </Button>
          <Button
            data-testid="close-update-dialog-button"
            color="primary"
            fullWidth
            onClick={handleClose}
          >
            Atualizar mais tarde
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAvailableDialog;
