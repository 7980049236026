import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { Box, Container, Typography, Button } from '@material-ui/core';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import {
  ROUTES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  BASE_TRIP_TRANSFER_ERROR_MSGS
} from '../../constants';
import { playErrorBeep } from '../../sounds';
import HeaderWithReturn from '../../app/components/header-with-return';

import DestinationSearch from '../../app/components/destination-search';
import handleRestAPIErrorWithMessage from '../../app/utils/handle-rest-api-error-with-message';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { DistributeProcessContext } from './distribute-process-context';
import { distributionUnitLoad } from '../../api-rest';

const IdentifyDestinationScreen = () => {
  const {
    destinationList,
    vehicleLicensePlate,
    totalCargosBeeped,
    setDestinationList,
    setDistributionUnitLoadId,
    setLastCargo,
    setTotalCargosBeeped
  } = useContext(DistributeProcessContext);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const history = useHistory();

  const goInitialIdentificationPage = () => {
    setLastCargo(null);
    setTotalCargosBeeped(0);
    history.push({
      pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
    });
  };
  const goBack = () => {
    if (totalCargosBeeped > 0) {
      history.push({
        pathname: ROUTES.DISTRIBUTE.CANCEL_DISTRIBUTION
      });
    } else {
      goInitialIdentificationPage();
    }
  };

  const [someSelected, setSomeSelected] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const baseErrorHandler = message => {
    playErrorBeep();
    setErrorMessage(message);
  };

  const handleSelectDestination = selectedDestination => {
    setDestinationList(
      destinationList.map(destination =>
        destination === selectedDestination
          ? { ...destination, isSelected: !destination.isSelected }
          : destination
      )
    );
  };

  const goAddCargoPage = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTION_UNIT_LOAD
    );

    const errorMessages = [];

    const response = await distributionUnitLoad({
      vehicleLicensePlate,
      originRoutingCode: selectedDistributionCenter.routingCode,
      destinations: destinationList
        .filter(destination => destination.isSelected)
        .map(destination => destination.destinationRoutingCode)
    }).catch(error => {
      handleRestAPIErrorWithMessage(
        {
          ...BASE_TRIP_TRANSFER_ERROR_MSGS
        },
        error,
        message => {
          errorMessages.push(message);
        }
      );
    });

    if (errorMessages.length) {
      baseErrorHandler(errorMessages.join('\n'));
    } else {
      setDistributionUnitLoadId(response.data.distributionUnitLoadId);
      history.push({
        pathname: ROUTES.DISTRIBUTE.ADD_CARGO
      });
    }

    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTION_UNIT_LOAD);
  };

  useEffect(() => {
    setSomeSelected(
      destinationList.some(destination => destination.isSelected)
    );
  }, [destinationList]);

  return (
    <Box>
      <HeaderWithReturn title="Distribuir" onReturn={goBack} />
      <Box
        flexflow="column"
        overflow="hidden"
        data-testid="destination-identification"
      >
        <Container maxWidth="xs" disableGutters>
          <Box
            pt={8}
            display="flex"
            flexDirection="column"
            height="100vh"
            overflow="hidden"
          >
            <Box display="flex" paddingLeft={3} mt={3}>
              <Typography variant="h5">Identifique os destinos</Typography>
            </Box>
            <Box flex="1" overflowy="auto" width="100%" height={1} mt={3}>
              <DestinationSearch
                selectDestinations={selectedDestination =>
                  handleSelectDestination(selectedDestination)
                }
                destinationList={destinationList}
              />
            </Box>
            <Box alignItems="center" p={3} mt={1}>
              <Button
                disabled={!someSelected}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                data-testid="continue-button"
                onClick={goAddCargoPage}
              >
                Continuar
              </Button>
            </Box>
          </Box>
          {errorMessage && (
            <Box mt={1.5}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default IdentifyDestinationScreen;
