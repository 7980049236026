/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Hidden,
  Link,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useRemoteConfig } from '../remote-config';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateFooter,
  OneTemplateSummary
} from '../template';
import firstPickupStatusImage from '../shared/images/sign-up/first-pickup-status.svg';
import secondPickupStatusImage from '../shared/images/sign-up/second-pickup-status.svg';
import thirdPickupStatusImage from '../shared/images/sign-up/third-pickup-status.svg';
import mobileIllustration from '../shared/images/sign-up/illustration-signup-mobile.svg';
import checkMark from '../shared/images/sign-up/check-mark.svg';
import useCompanySignupStyles from './styles';

const SignupCompanyTemplate = ({
  children,
  currentStep,
  formTitles,
  formSubtitles,
  enableSummary,
  outOfCoverage,
  finishedSignUp
}) => {
  const { t } = useTranslation('one');
  const { pathname } = useLocation();
  const signIn = pathname === '/login';
  const accountSignUp = pathname === '/cadastro';
  const companySignupStyles = useCompanySignupStyles({ accountSignUp });
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const mediumDevice = useMediaQuery(theme => theme.breakpoints.only('md'));
  const extraSmallDevice = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const { value: enableCompanySignupEnrichment } = useRemoteConfig(
    'enable_company_signup_enrichment'
  );
  const companySignupEnrichmentEnabled =
    enableCompanySignupEnrichment === 'true';
  const signupBackgroundStyle = isMobile
    ? companySignupStyles.backgroundMobile
    : companySignupStyles.background;
  const sidebarTitles = t('signupCompanyTemplate.sidebar.title', {
    returnObjects: true
  });
  const paragraphs = t('signupCompanyTemplate.paragraphs', {
    returnObjects: true
  });
  const subtitles = t('signupCompanyTemplate.subtitle', {
    returnObjects: true
  });
  const sidebarParagraphsVariant = mediumDevice ? 'body2' : 'body1';
  const requiredStepsAmount = 3;
  const renderStepCounter =
    companySignupEnrichmentEnabled && Boolean(currentStep);

  const MobileDescription = () => (
    <Hidden mdUp>
      <Box mt={8}>
        <Typography variant="h3">
          <strong>
            {t('signupCompanyTemplate.mobileDescription.headerContent.title')}
          </strong>
        </Typography>
        {accountSignUp && (
          <Box m={2} display="flex" justifyContent="center">
            <img src={mobileIllustration} />
          </Box>
        )}
        {subtitles.map(subtitle => (
          <Typography key={subtitle} variant="subtitle1">
            <strong>{subtitle}</strong>
          </Typography>
        ))}
        <Box mt={3}>
          {paragraphs.slice(0, 2).map(paragraph => (
            <Typography
              key={paragraph}
              variant={extraSmallDevice ? 'body3' : 'body1'}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
        <Box my={3}>
          {paragraphs.slice(2, 7).map(paragraph => (
            <Typography
              key={paragraph}
              variant={extraSmallDevice ? 'body3' : 'body1'}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
        <Box>
          <Typography variant={extraSmallDevice ? 'body3' : 'body1'}>
            {paragraphs[7]}
          </Typography>
        </Box>
        <Box>
          <Typography variant={extraSmallDevice ? 'body3' : 'body1'}>
            {paragraphs[8]}
          </Typography>
        </Box>
        {signIn && (
          <Box m={2} display="flex" justifyContent="center">
            <img src={mobileIllustration} />
          </Box>
        )}
        {accountSignUp && (
          <Box my={5}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              data-testid="signUpButton"
              onClick={() => {
                window.location = `${pathname}#cadastrar`;
              }}
              component={Link}
              style={{ textDecoration: 'none', outline: 'none' }}
            >
              {t('signupCompanyTemplate.mobileDescription.signUpButton')}
            </Button>
          </Box>
        )}
      </Box>
    </Hidden>
  );

  return (
    <Box width="100%" height="auto" className={signupBackgroundStyle}>
      <OneTemplate enableFooter={false} enableSummary={enableSummary} maxWidth>
        <OneTemplateSummary color="transparent" />
        <OneTemplateContent disablePaper>
          <Box
            className={companySignupStyles.sidebar}
            pl={!isMobile && !mediumDevice && 10}
            pr={!isMobile && !mediumDevice && 10}
          >
            <Hidden smDown>
              <Box className={companySignupStyles.descriptionSection}>
                <Box className={companySignupStyles.textHeader}>
                  {sidebarTitles.map(title => (
                    <Typography
                      key={title}
                      variant={mediumDevice ? 'h4' : 'h3'}
                    >
                      <strong>{title}</strong>
                    </Typography>
                  ))}
                  <Box my={3}>
                    {subtitles.map(subtitle => (
                      <Typography
                        key={subtitle}
                        variant={mediumDevice ? 'subtitle2' : 'subtitle1'}
                      >
                        <strong>{subtitle}</strong>
                      </Typography>
                    ))}
                  </Box>
                  <Box>
                    {paragraphs.slice(0, 2).map(paragraph => (
                      <Typography
                        key={paragraph}
                        variant={sidebarParagraphsVariant}
                      >
                        {paragraph}
                      </Typography>
                    ))}
                  </Box>
                  <Box my={3}>
                    {paragraphs.slice(2, 7).map(paragraph => (
                      <Typography
                        key={paragraph}
                        variant={sidebarParagraphsVariant}
                      >
                        {paragraph}
                      </Typography>
                    ))}
                  </Box>
                  <Box>
                    {paragraphs.slice(-2).map(paragraph => (
                      <Typography
                        key={paragraph}
                        variant={sidebarParagraphsVariant}
                      >
                        {paragraph}
                      </Typography>
                    ))}
                  </Box>
                  <Box
                    mt={7}
                    className={companySignupStyles.pickupStatusSection}
                  >
                    <Box mb={-5}>
                      <img
                        className={companySignupStyles.pickupStatusImage}
                        src={firstPickupStatusImage}
                      />
                    </Box>
                    <Box mb={-5} ml={-7}>
                      <img
                        className={companySignupStyles.pickupStatusImage}
                        src={secondPickupStatusImage}
                      />
                    </Box>
                    <img
                      className={companySignupStyles.pickupStatusImage}
                      src={thirdPickupStatusImage}
                    />
                  </Box>
                </Box>
              </Box>
            </Hidden>
            <Box
              className={
                accountSignUp
                  ? companySignupStyles.formAccountSignup
                  : companySignupStyles.formSignup
              }
              m={extraSmallDevice && 2}
              pt={isMobile && 0}
              pl={!isMobile && 10}
            >
              <Box>
                {accountSignUp && <MobileDescription />}
                {renderStepCounter && (
                  <Box
                    className={companySignupStyles.stepCounter}
                    color={colors.smoke[700]}
                    fontWeight="bold"
                    p={1.5}
                    my={3}
                  >
                    <Typography variant="inherit">
                      {`${currentStep} de ${requiredStepsAmount}`}
                    </Typography>
                  </Box>
                )}
                {finishedSignUp && (
                  <Box
                    mb={3}
                    pt={18}
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <img src={checkMark} />
                  </Box>
                )}
                <Box id={accountSignUp ? 'cadastrar' : 'entrar'} mt={2} mb={3}>
                  {formTitles.map(title => (
                    <Typography
                      key={title}
                      variant={mediumDevice ? 'h4' : 'h3'}
                    >
                      <strong>{title}</strong>
                    </Typography>
                  ))}
                  <Box mt={1}>
                    {formSubtitles.map((subtitle, index) => (
                      <Box mb={index === 0 && outOfCoverage ? 3 : 0}>
                        <Typography
                          mt={10}
                          variant={
                            index === 0 && outOfCoverage ? 'h5' : 'subtitle1'
                          }
                          key={subtitle}
                        >
                          {subtitle}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {children}
                {signIn && <MobileDescription />}
                <Box mt={5} mb={7}>
                  <OneTemplateFooter />
                </Box>
              </Box>
            </Box>
          </Box>
        </OneTemplateContent>
      </OneTemplate>
    </Box>
  );
};

SignupCompanyTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number,
  formTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  formSubtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  enableSummary: PropTypes.bool,
  outOfCoverage: PropTypes.bool,
  finishedSignUp: PropTypes.bool
};

SignupCompanyTemplate.defaultProps = {
  currentStep: 0,
  enableSummary: true,
  outOfCoverage: false,
  finishedSignUp: false
};

export default SignupCompanyTemplate;
