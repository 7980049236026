import React from 'react';
import { colors } from '@loggi/mar';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import HeaderWithClose from '../../app/components/header-with-close';
import { ReactComponent as IconReceiving } from '../../assets/images/icon_receiving.svg';
import { ROUTES } from '../../constants';
import pluralize from '../../app/utils/pluralize';

export default function ConfirmReceivement() {
  const location = useLocation();
  const history = useHistory();
  const { state } = location;

  const navigateTo = pathname => {
    history.replace(pathname);
  };

  const goToHome = () => {
    navigateTo(ROUTES.HOME);
  };

  if (!state) {
    goToHome();
    return null;
  }

  const finishConfirmation = () => {
    goToHome();
  };

  const newReceivement = () => {
    navigateTo(ROUTES.RECEIVE.CONTEXT_READER);
  };

  const handleMovePackagesBulk = () => {
    history.push({
      pathname: ROUTES.RECEIVE.MOVE_PACKAGES_BULK
    });
  };

  const { totalPackagesReceived, senderName, receivedIn } = state;

  return (
    <Box height="100vh" bgcolor={colors.root[0]}>
      <Container maxWidth="xs" style={{ height: '100%' }}>
        <Box pt={1.5}>
          <HeaderWithClose
            handleClose={finishConfirmation}
            testId="confirm-receivement-close"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
        >
          <Box width="100%" mt={3} textAlign="center">
            <Typography variant="h6">Você recebeu</Typography>
            <Typography variant="h6">
              <Box fontWeight="700">
                {`${pluralize({
                  singular: `${totalPackagesReceived} unidade`,
                  count: totalPackagesReceived
                })}`}
              </Box>
            </Typography>
            {senderName && (
              <Typography variant="body1">
                <Box mt={3}>de {senderName}</Box>
              </Typography>
            )}
          </Box>
          <Box mt={6} width="100%" textAlign="center">
            <IconReceiving style={{ width: 150, height: 95 }} />
            <Typography variant="subtitle1">
              <Box mt={4}>realizada em {receivedIn}</Box>
            </Typography>
          </Box>
          <ActionContainerBottom enableSmokeBackground>
            <Container maxWidth="xs">
              <Box display="flex" pb={3.125}>
                <Box flex={1} mr={1}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={newReceivement}
                    data-testid="close"
                  >
                    Novo recebimento
                  </Button>
                </Box>
                <Box flex={1} ml={1}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleMovePackagesBulk}
                    data-testid="move-packages"
                  >
                    Mover
                  </Button>
                </Box>
              </Box>
            </Container>
          </ActionContainerBottom>
        </Box>
      </Container>
    </Box>
  );
}
