import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { pxToRem } from '@loggi/mar/src/utils';
import { colors } from '@loggi/mar';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import HeaderWithCancel from '../../app/components/header-with-cancel';
import MovePackageDialog from './move-package-dialog';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import pluralize from '../../app/utils/pluralize';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import SharedPropTypes from '../../app/shared-prop-types';
import StrictPackageIdentification from './strict-package-identification';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    height: '100%'
  },
  expandedButton: {
    flexGrow: 1,
    marginBottom: pxToRem(15)
  },
  dialog: {
    padding: 0
  }
});

export default function SortPackageDialog({
  checkedPackages,
  handleRemovePackages,
  handleClose,
  goBack,
  shouldSortAllPackages
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [movePackageOpen, setMovePackageOpen] = React.useState(false);
  const [currentReadingPackage, setCurrentReadingPackage] = React.useState(
    null
  );
  const [countSortedPackages, setCountSortedPackages] = React.useState(0);

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const startPackageMove = packageObject => {
    playSuccessBeep();
    setCurrentReadingPackage(packageObject);
    setMovePackageOpen(true);
  };

  const clearForNewReading = () => {
    setErrorMessage('');
    setCurrentReadingPackage(null);
  };

  const handleMovedPackage = movedPackage => {
    const newPackageList = checkedPackages.filter(
      p => p.identifier !== movedPackage.identifier
    );
    handleRemovePackages(newPackageList);
    setCountSortedPackages(countSortedPackages + 1);
    setMovePackageOpen(false);
  };

  const pluralizeText = text => {
    return pluralize({
      singular: text,
      count: countSortedPackages
    });
  };

  /**
   * shouldSortAllPackages === true: It's the use cases for when the operator cancels
   * the distribute flow. No packages should remain in his/her custody transfer.
   *
   * shouldSortAllPackages === false: the operator only have to sort the refused packages.
   */

  const finish = () => {
    if (shouldSortAllPackages) {
      if (checkedPackages.length) {
        showSnackbar({
          variant: 'warning',
          message: 'Você ainda tem pacotes para armazenar',
          showCloseButton: true,
          enqueueSnackbar
        });
      } else {
        goBack();
      }
    } else {
      if (countSortedPackages > 0) {
        const snackMessage = `Boa! ${pluralizeText('Pacote')} ${pluralizeText(
          'armazenado'
        )}`;
        showSnackbar({
          variant: 'success',
          message: snackMessage,
          showCloseButton: true,
          enqueueSnackbar
        });
      }

      handleClose();
    }
  };

  return (
    <Dialog
      open
      fullScreen
      data-testid="sort-package-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
    >
      <DialogTitle className={classes.dialog}>
        <Container maxWidth="xs">
          <Box paddingTop={2.5}>
            <HeaderWithCancel
              testId="sort-package-dialog-cancel-button"
              handleClose={handleClose}
            />
          </Box>
        </Container>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Container maxWidth="xs">
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Armazenar</Box>
            </Typography>
          </Box>
          <Box paddingTop={1.5}>
            <Typography variant="h5">
              Bipe só os pacotes que você vai armazenar.
            </Typography>
            <Box paddingTop={2.5}>
              {!movePackageOpen && (
                <StrictPackageIdentification
                  expectedPackages={checkedPackages}
                  before={clearForNewReading}
                  success={startPackageMove}
                  fail={baseErrorHandler}
                  source="xd_app_sort_package"
                  warningMessage="Pacote não pertence a essa saca, bipe um local para armazená-lo"
                  notes="Bipado na recusa/cancelamento do Distribuir"
                />
              )}
              {errorMessage && (
                <Box mt={1.5}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <Container maxWidth="xs">
          <Button
            data-testid="finish-reading-sort-package-dialog-btn"
            onClick={finish}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            className={classes.expandedButton}
          >
            Finalizar
          </Button>

          {movePackageOpen && currentReadingPackage && (
            <MovePackageDialog
              handlePackage={handleMovedPackage}
              closeWithoutPackage={() => {
                setMovePackageOpen(false);
              }}
              currentPackage={currentReadingPackage}
            />
          )}
        </Container>
      </DialogActions>
    </Dialog>
  );
}

SortPackageDialog.propTypes = {
  checkedPackages: SharedPropTypes.packages.isRequired,
  handleRemovePackages: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  shouldSortAllPackages: PropTypes.bool.isRequired,
  goBack: PropTypes.func
};

SortPackageDialog.defaultProps = {
  goBack: () => {}
};
