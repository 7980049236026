import { BUTTON_BORDER_RADIUS } from '../constants';
import { colors, typography } from '../principles';
import { pxToRem } from '../utils';

const contained = {
  containedSizeSmall: {
    padding: `${pxToRem(10)} ${pxToRem(20)}`
  },
  // Default (medium)
  contained: {
    padding: `${pxToRem(14)} ${pxToRem(20)}`
  },
  containedPrimary: {
    background: colors.gradients.sanches,
    '&.Mui-disabled': {
      backgroundImage: 'none'
    }
  },
  containedSizeLarge: {
    lineHeight: pxToRem(24),
    padding: `${pxToRem(16)} ${pxToRem(24)}`
  }
};

const outlined = {
  outlinedSizeSmall: {
    padding: `${pxToRem(9)} ${pxToRem(20)}`
  },
  // Default (medium)
  outlined: {
    borderColor: colors.root[0],
    padding: `${pxToRem(13)} ${pxToRem(20)}`,
    color: colors.root[0]
  },
  outlinedPrimary: {
    backgroundColor: colors.root[0]
  },
  outlinedSizeLarge: {
    lineHeight: pxToRem(22),
    padding: `${pxToRem(16)} ${pxToRem(24)}`
  }
};

// Here, "text" is a button variation, not a selector to some text element
const text = {
  textSizeSmall: {
    padding: `${pxToRem(10)} ${pxToRem(20)}`
  },
  // Default (medium)
  text: {
    padding: `${pxToRem(14)} ${pxToRem(20)}`
  },
  textSizeLarge: {
    lineHeight: pxToRem(24),
    padding: `${pxToRem(16)} ${pxToRem(24)}`
  }
};

const MuiButton = {
  root: {
    borderRadius: pxToRem(BUTTON_BORDER_RADIUS),
    fontSize: typography.button.fontSize,
    lineHeight: pxToRem(20),
    minWidth: pxToRem(64),
    whiteSpace: 'nowrap'
  },

  // Apply these styles to the Large size on all variations
  sizeLarge: {
    fontSize: typography.body1.fontSize,
    minWidth: pxToRem(110)
  },
  ...contained,
  ...outlined,
  ...text
};

export default MuiButton;
