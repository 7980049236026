import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent } from '@material-ui/core';
import DamagePhotoUpload from './damage-photo-upload';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { TESTID, TEXT } from './constants';

export default function PhotoCapture({
  onConfirmSend,
  imagesData,
  removeImage,
  goToPackageDamageDeclaration,
  handleSave,
  isAbleToSubmit
}) {
  const showCamera = imagesData.length < 3;

  return (
    <>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <DamagePhotoUpload
              onConfirmSend={onConfirmSend}
              shouldShowCamera={showCamera}
            />
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="center"
            >
              {imagesData.map(imageData => (
                <Box
                  key={imageData.key}
                  width="33%"
                  m={1}
                  mr={2}
                  position="relative"
                >
                  <img
                    style={{ width: '100%' }}
                    data-testid="img-photo-testid"
                    src={imageData.src}
                    alt="Foto tirada do pacote avariado"
                  />
                  <CloseIcon
                    style={{
                      position: 'absolute',
                      left: 75,
                      bottom: -5,
                      backgroundColor: 'black',
                      borderRadius: '100%'
                    }}
                    data-testid={TESTID.DECLARE_DAMAGE_REMOVE_PHOTO}
                    onClick={() => removeImage(imageData.key)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box my={3} display="flex">
        <Box mr={1} width="100%">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={goToPackageDamageDeclaration}
            data-testid={TESTID.DECLARE_DAMAGE_BACK}
          >
            {TEXT.BACK}
          </Button>
        </Box>
        <Box ml={1} width="100%">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!isAbleToSubmit}
            data-testid={TESTID.DECLARE_DAMAGE_SEND}
          >
            {TEXT.SEND}
          </Button>
        </Box>
      </Box>
    </>
  );
}

PhotoCapture.propTypes = {
  imagesData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      src: PropTypes.string
    })
  ).isRequired,
  removeImage: PropTypes.func.isRequired,
  onConfirmSend: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  goToPackageDamageDeclaration: PropTypes.func.isRequired,
  isAbleToSubmit: PropTypes.bool
};

PhotoCapture.defaultProps = {
  isAbleToSubmit: false
};
