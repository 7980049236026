// Hub
const AUTH_EVENT = 'auth';
const CUSTOM_OAUTH_STATE = 'customOAuthState';
const SIGN_IN_EVENT = 'signIn';
const SIGN_IN_FAILURE = 'signIn_failure';
const SIGN_UP_FAILURE = 'signUp_failure';
const SIGN_OUT_EVENT = 'signOut';

// Reducer events
const START_FETCH_AUTH_USER = 'START_FETCH_AUTH_USER';
const SUCCESS_FETCH_AUTH_USER = 'SUCCESS_FETCH_AUTH_USER';
const FAILURE_FETCH_AUTH_USER = 'FAILURE_FETCH_AUTH_USER';
const RESET_AUTH_USER = 'RESET_AUTH_USER';
const START_SIGN_IN = 'START_SIGN_IN';
const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN';
const START_SIGN_UP = 'START_SIGN_UP';
const SUCCESS_SIGN_UP = 'SUCCESS_SIGN_UP';
const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';
const SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD =
  'SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD';
const START_FORGOT_PASSWORD = 'START_FORGOT_PASSWORD';
const FAILURE_FORGOT_PASSWORD = 'FAILURE_FORGOT_PASSWORD';
const SUCCESS_FORGOT_PASSWORD = 'SUCCESS_FORGOT_PASSWORD';
const START_SEND_PASSWORD_RECOVERY = 'START_SEND_PASSWORD_RECOVERY';
const FAILURE_SEND_PASSWORD_RECOVERY = 'FAILURE_SEND_PASSWORD_RECOVERY';
const SUCCESS_SEND_PASSWORD_RECOVERY = 'SUCCESS_SEND_PASSWORD_RECOVERY';
const START_COMPLETE_NEW_PASSWORD = 'START_COMPLETE_NEW_PASSWORD';
const SUCCESS_COMPLETE_NEW_PASSWORD = 'SUCCESS_COMPLETE_NEW_PASSWORD';

export {
  AUTH_EVENT,
  CUSTOM_OAUTH_STATE,
  FAILURE_FETCH_AUTH_USER,
  FAILURE_FORGOT_PASSWORD,
  FAILURE_SEND_PASSWORD_RECOVERY,
  RESET_AUTH_ERROR,
  RESET_AUTH_USER,
  SIGN_IN_EVENT,
  SIGN_IN_FAILURE,
  SIGN_UP_FAILURE,
  SIGN_OUT_EVENT,
  START_COMPLETE_NEW_PASSWORD,
  START_FETCH_AUTH_USER,
  START_FORGOT_PASSWORD,
  START_SEND_PASSWORD_RECOVERY,
  START_SIGN_IN,
  START_SIGN_UP,
  SUCCESS_COMPLETE_NEW_PASSWORD,
  SUCCESS_FETCH_AUTH_USER,
  SUCCESS_FORGOT_PASSWORD,
  SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD,
  SUCCESS_SIGN_IN,
  SUCCESS_SIGN_UP,
  SUCCESS_SEND_PASSWORD_RECOVERY
};
