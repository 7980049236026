import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

const useDialogAlertComponentStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      height: '96%',
      maxHeight: '100%'
    }
  }
}));

const DialogAlertComponent = ({
  title,
  open,
  children,
  onContinue,
  onCancel
}) => {
  const { paper } = useDialogAlertComponentStyles();

  return (
    <Dialog open={open} classes={{ paper }} data-testid="dialog-alert">
      <DialogTitle>
        <Box pt={4} style={{ overflowWrap: 'break-word' }}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={5} />
        {children}
      </DialogContent>
      <Box mb={1.5} clone>
        <DialogActions disableSpacing>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={onCancel}
            size="large"
            data-testid="cancel"
          >
            Cancelar
          </Button>
          <Box mt={2} />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onContinue}
            data-testid="continue"
          >
            Continuar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DialogAlertComponent.defaultProps = {
  open: true
};

DialogAlertComponent.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  children: PropTypes.element.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default DialogAlertComponent;
