import { Box, Container, Typography } from '@material-ui/core';
import { pxToRem } from '@loggi/mar/src/utils';
import Avatar from '@material-ui/core/Avatar';
import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import { BarcodeReader } from '../../app/components/barcode-readers';
import Confirmation from '../../app/components/confirmation';
import AlertIcon from '../../assets/images/alert-icon-2.svg';
import {
  HISTORY_ACTIONS,
  UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX,
  ROUTES,
  ACTIVITY,
  OPERATIONAL_PROCESS
} from '../../constants';
import { movePackagesBulk } from '../../api-rest';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { ReceivingProcessContext } from './receiving-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import handleRestAPIError from '../../app/utils/rest-api-request';

export const MESSAGES = {
  SUCCESS: 'Boa! Unidades armazenadas.'
};

export default function MovePackagesBulk() {
  const { sortingContext, returnList } = useContext(ReceivingProcessContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const defaultReceivingUnitLoad = `${
    selectedDistributionCenter.routingCode
  } ${UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX}`;

  const finish = () => {
    history.push({
      pathname: ROUTES.RECEIVE.CONTEXT_READER
    });
  };

  const handleCancel = () => setOpenConfirmCancel(true);

  const handleConfirmCancel = () => finish();

  const handleRejectCancel = () => setOpenConfirmCancel(false);

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      history.replace(ROUTES.RECEIVE.CONTEXT_READER);
    }
  }, [history]);

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleRead = async unitLoadLicensePlate => {
    setLoading(true);
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.MOVE_PACKAGES_BULK_BEEP
    );
    try {
      await movePackagesBulk({
        packageIds: returnList.map(item => item.unitLoadId),
        destinationUnitLoadLicensePlate: unitLoadLicensePlate,
        sortingContextLpn: sortingContext,
        sideEffectParams: {
          distributionCenterId: selectedDistributionCenter?.distributionCenterId
        }
      });
      showSnackbarAlert(MESSAGES.SUCCESS);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.MOVE_PACKAGES_BULK_BEEP
      );
      finish();
    } catch (err) {
      setLoading(false);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.MOVE_PACKAGES_BULK_BEEP
      );
      handleRestAPIError(err, errorMsg => {
        showSnackbarAlert(errorMsg, 'error');
      });
    }
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.MOVE_PACKAGES_BULK);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.MOVE_PACKAGES_BULK);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth="xs">
        <Box
          pt={3}
          display="flex"
          flexDirection="column"
          height="100vh"
          overflow="hidden"
        >
          <Box>
            <HeaderWithCancel
              testId="cancel-button-header"
              handleClose={handleCancel}
              isDisabled={loading}
            />
            <Box pt={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Armazenar</Box>
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" height={1} mt={2.5}>
            <BarcodeReader
              onRead={handleRead}
              loading={loading}
              placeholder="Bipe o local de Armazenamento"
            />
          </Box>
          <Box display="flex" flexDirection="column" mb={8}>
            <Box>
              <Typography component="div" style={{ fontSize: pxToRem(86) }}>
                <Box fontWeight="fontWeightRegular">{returnList.length}</Box>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                <Box fontWeight="fontWeightRegular">
                  Unidades de {defaultReceivingUnitLoad}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      {openConfirmCancel && (
        <Confirmation
          open
          image={
            <Avatar
              src={AlertIcon}
              style={{ height: pxToRem(45), width: pxToRem(45) }}
            />
          }
          titleText="Tem certeza que quer cancelar?"
          subtitleText={`Se você cancelar os pacotes vão continuar em <b>${defaultReceivingUnitLoad}</b>.`}
          onConfirm={handleConfirmCancel}
          confirmText="Quero Cancelar"
          onCancel={handleRejectCancel}
          cancelText="Vou mover"
        />
      )}
    </>
  );
}
