import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import RedispatchPackageReader from './redispatch-package-reader';
import { HISTORY_ACTIONS } from '../../constants';
import { BAG_TYPE } from '../../app/enums';

function RedispatchPackageReaderPage({ history }) {
  const location = useLocation();

  // prevent user for opening this page when editing the URL
  if (
    history.action !== HISTORY_ACTIONS.PUSH ||
    !location.state?.bag ||
    location?.state?.bagType !== BAG_TYPE.BAG_TYPE_REDISPATCH
  ) {
    history.replace('/distribute');
    return null;
  }

  return (
    <RedispatchPackageReader
      bag={location.state.bag}
      redispatchUnitLoad={location.state.redispatchUnitLoad}
      goBack={history.goBack}
      contextLicensePlate={location.state.contextLicensePlate}
      userPk={location.state.userPk}
    />
  );
}

RedispatchPackageReaderPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    action: PropTypes.string
  }).isRequired
};

export default RedispatchPackageReaderPage;
