import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Box,
  Checkbox,
  Paper,
  Typography,
  CircularProgress,
  Button,
  Divider,
  Container,
  IconButton
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { Refresh } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { smoke } from '@loggi/mar/src/principles/colors';
import {
  movePackage,
  getUnitLoad as getUnitLoadRest,
  getUnitLoadMetrics
} from '../api-rest';
import {
  playActionRequiredBeep,
  playErrorBeep,
  playSuccessBeep
} from '../sounds';
import { StyledTableCell, StyledTableRow } from '../app/components/table';
import HeaderWithReturn from '../app/components/header-with-return';
import { PackageReader } from '../app/components/barcode-readers';
import { useDistributionCenter } from '../app/access-control/distribution-center-provider';
import pluralize from '../app/utils/pluralize';
import childrenWithoutOutliers from '../app/utils/children-without-outliers';
import handleRestAPIError from '../app/utils/rest-api-request';
import { HTTP_STATUS_CODES, RESPONSE_STATUS, ROUTES } from '../constants';

const DangerButton = withStyles(theme => ({
  root: {
    backgroundColor: colors.red[500],
    borderColor: colors.red[500],
    color: theme.palette.common.white
  }
}))(Button);

const useStyles = makeStyles(() => ({
  scanned: {
    backgroundColor: colors.green[100]
  }
}));

export const UnitLoadManagement = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState('');
  const [packageError, setPackageError] = useState('');
  const [packageWarning, setPackageWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUnitLoad, setSelectedUnitLoad] = useState();
  const [unitLoadLpn] = React.useState(
    location.state && location.state.unitLoadLpn
  );
  const [sortingContext] = React.useState(
    location.state && location.state.sortingContext
  );
  const [selectedChildrenUnitLoad, setSelectedChildrenUnitLoad] = useState([]);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const goBack = () =>
    history.push({
      pathname: ROUTES.PACKING,
      state: {
        sortingContext
      }
    });

  const errorHandler = message => {
    setPackageError(message);
    playErrorBeep();
  };

  const warningHandler = message => {
    setPackageWarning(message);
    playActionRequiredBeep();
  };

  const unexpectedErrorHandler = err => {
    Sentry.captureException(err);
    errorHandler(err.message);
  };

  const fetchUnitLoadData = React.useCallback(async () => {
    setIsLoading(true);
    const unitLoadPromises = [];

    const shouldShowUnitLoadMetrics = sortingContext?.licensePlate?.includes(
      'SEP'
    );

    const unitLoadInfoPromise = getUnitLoadRest(unitLoadLpn).catch(err => {
      setError(err.message);
      handleRestAPIError(err, errorHandler);
    });

    unitLoadPromises.push(unitLoadInfoPromise);

    if (shouldShowUnitLoadMetrics) {
      const unitLoadMetricsPromise = getUnitLoadMetrics(unitLoadLpn).catch(
        err => {
          setError(err.message);
          handleRestAPIError(err, () => {
            warningHandler(
              'Eita, erro ao tentar buscar as métricas da posição, ' +
                'as informações podem estar incompletas.'
            );
          });
        }
      );
      unitLoadPromises.push(unitLoadMetricsPromise);
    }

    const [unitLoadInfoResponse, unitLoadMetricsResponse] = await Promise.all(
      unitLoadPromises
    );

    setIsLoading(false);
    setSelectedChildrenUnitLoad([]);

    if (!unitLoadInfoResponse?.data) {
      setError('Unidade não encontrada');
      playErrorBeep();
      return;
    }

    if (unitLoadInfoResponse.status === HTTP_STATUS_CODES.OK) {
      const responseUnitLoad = unitLoadInfoResponse.data;
      responseUnitLoad.childrenUnitLoad =
        responseUnitLoad?.childrenUnitLoad || [];

      responseUnitLoad.density = unitLoadMetricsResponse?.data?.density;

      responseUnitLoad.unitLoadsOutlier =
        unitLoadMetricsResponse?.data?.unitLoadsOutlier || [];
      setSelectedUnitLoad(responseUnitLoad);
      setError('');
    }
  }, [unitLoadLpn, sortingContext]);

  React.useEffect(() => {
    (async function onInit() {
      await fetchUnitLoadData();
    })();
  }, [fetchUnitLoadData]);

  const refreshUnitLoadInfo = async () => {
    await fetchUnitLoadData(unitLoadLpn);
  };

  const handleRead = async (barcode, packCheckPromise) => {
    setIsLoading(true);

    const responsePackCheck = await packCheckPromise.catch(
      unexpectedErrorHandler
    );

    const sideEffectParams = {
      distributionCenterId: selectedDistributionCenter?.distributionCenterId
    };

    const response = await movePackage(
      selectedUnitLoad?.licensePlate,
      barcode,
      null, // sortingDecisionLpn
      sortingContext?.licensePlate,
      sideEffectParams
    ).catch(err => {
      handleRestAPIError(err, errorHandler);
    });

    setIsLoading(false);

    if (!response || !response.data || !responsePackCheck) {
      return;
    }

    if (response.data.status !== RESPONSE_STATUS.OK) {
      playErrorBeep();
      setPackageError(response.data.errorMsg);
      return;
    }

    if (!scannedBarcodes.includes(barcode)) {
      setScannedBarcodes([...scannedBarcodes, barcode]);
    }

    if (
      !selectedUnitLoad?.childrenUnitLoad?.find(p => p.licensePlate === barcode)
    ) {
      setSelectedUnitLoad({
        ...selectedUnitLoad,
        childrenUnitLoad: [
          ...selectedUnitLoad?.childrenUnitLoad,
          { recipient: {}, destination: {}, licensePlate: barcode }
        ]
      });
    }

    playSuccessBeep();
  };

  const unitLoadSelected = ul => {
    // Toggle element in selectedChildrenUnitLoad list.
    const index = selectedChildrenUnitLoad.indexOf(ul);

    // If the element is in the array, create a new array without it.
    if (index >= 0) {
      setSelectedChildrenUnitLoad(
        selectedChildrenUnitLoad
          .slice(0, index)
          .concat(selectedChildrenUnitLoad.slice(index + 1))
      );
    } else {
      setSelectedChildrenUnitLoad(selectedChildrenUnitLoad.concat([ul]));
    }
  };

  const allChildrenUnitLoadsAreSelected = () =>
    selectedChildrenUnitLoad.length ===
    selectedUnitLoad?.childrenUnitLoad?.length;

  const unitLoadHasOutliers = () => {
    return selectedUnitLoad?.unitLoadsOutlier?.length > 0;
  };

  const handleSelectedUnitLoad = () => {
    const childrenUnitLoadWithoutOutlier = childrenWithoutOutliers({
      unitLoad: selectedUnitLoad
    });
    let newSelectedChildrenUnitLoad;

    if (allChildrenUnitLoadsAreSelected()) {
      newSelectedChildrenUnitLoad = [];
    } else if (unitLoadHasOutliers()) {
      newSelectedChildrenUnitLoad = [
        ...selectedUnitLoad?.unitLoadsOutlier,
        ...childrenUnitLoadWithoutOutlier
      ];
    } else {
      newSelectedChildrenUnitLoad = [...childrenUnitLoadWithoutOutlier];
    }
    setSelectedChildrenUnitLoad(newSelectedChildrenUnitLoad);
  };

  const childrenUnitLoadContent = (row, isOutlier) => {
    return (
      <TableRow
        key={row?.licensePlate}
        data-testid={`row-${row?.licensePlate}`}
        onClick={() => unitLoadSelected(row)}
        className={
          scannedBarcodes.includes(row?.licensePlate) ? classes.scanned : ''
        }
      >
        <StyledTableCell padding="checkbox">
          <Box
            py={1}
            display="flex"
            alignItems="center"
            data-testid={`unit-load-outlier-${row?.id}`}
          >
            <Box>
              <Checkbox
                checked={selectedChildrenUnitLoad.includes(row)}
                color="default"
              />
            </Box>
            {isOutlier && (
              <Box ml={2} mt={1}>
                <ErrorRoundedIcon style={{ color: colors.yellow[500] }} />
              </Box>
            )}
          </Box>
        </StyledTableCell>
        <StyledTableCell
          data-testid={`integration-info-${row?.id}`}
          component="th"
          scope="row"
        >
          {row?.licensePlate}
        </StyledTableCell>
        <StyledTableCell
          data-testid={`recipient-${row?.id}`}
          component="th"
          scope="row"
        >
          {row?.recipient?.name}
        </StyledTableCell>
        <StyledTableCell
          data-testid={`destination-zip-${row?.id}`}
          align="right"
        >
          {row?.destination?.zipCode || row?.destination?.zip}
        </StyledTableCell>
      </TableRow>
    );
  };

  const unitLoadChildren = () => {
    return (
      <Box my={2.5}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                data-testid="allChildren-ul"
                key="allChildren-ul"
                onClick={handleSelectedUnitLoad}
              >
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    checked={allChildrenUnitLoadsAreSelected()}
                    onChange={handleSelectedUnitLoad}
                    inputProps={{ 'aria-label': 'select all packages' }}
                    color="default"
                  />
                </StyledTableCell>
                <StyledTableCell>Código</StyledTableCell>
                <StyledTableCell>Destinatário</StyledTableCell>
                <StyledTableCell align="right">CEP</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUnitLoad?.unitLoadsOutlier?.map(row =>
                childrenUnitLoadContent(row, true)
              )}
              {childrenWithoutOutliers({
                unitLoad: selectedUnitLoad
              }).map(row => childrenUnitLoadContent(row, false))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const unitLoadSummary = () => {
    const buttonText = `${pluralize({
      singular: `Limpar ${selectedChildrenUnitLoad.length} unidade`,
      count: selectedChildrenUnitLoad.length
    })}`;

    return (
      <Box>
        {isLoading ? (
          <Container maxWidth="xs" disableGutters>
            <Box
              display="flex"
              flexDirection="column"
              data-testid="skeleton-list"
            >
              <Box>
                <Box mb={-2}>
                  <Skeleton variant="text" height={80} />
                </Box>
                {[...Array(4).keys()].map(index => (
                  <Box key={index} mb={-3}>
                    <Skeleton variant="text" height={110} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Container>
        ) : (
          unitLoadChildren()
        )}

        {Boolean(selectedChildrenUnitLoad?.length) && !isLoading && (
          <Box mt={0.1} mb={0.1} display="flex" alignItems="center">
            <DangerButton
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                history.push({
                  pathname: '/unit-load/confirm-package-removal',
                  state: {
                    selectedChildrenUnitLoad,
                    unitLoadLpn,
                    sortingContext
                  }
                });
              }}
            >
              {buttonText}
            </DangerButton>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <HeaderWithReturn onReturn={goBack} />
      <Container maxWidth="xs">
        <Box
          pt={10}
          display="flex"
          flexDirection="column"
          overflow="auto"
          height="100vh"
        >
          <Typography component="div" variant="body1" gutterBottom>
            <Box fontWeight="fontWeightBold">Empacotar</Box>
          </Typography>
          {isLoading && !selectedUnitLoad && <CircularProgress />}
          {error && (
            <Alert data-testid="error" severity="error">
              {error}
            </Alert>
          )}
          {selectedUnitLoad && (
            <>
              <Box my={1.5}>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box data-testid="licensePlate">
                    <Typography variant="h5">
                      {`${selectedUnitLoad?.licensePlate}  -  ${
                        selectedUnitLoad?.name
                      }`}
                    </Typography>
                  </Box>
                  {!isLoading && (
                    <Box>
                      <IconButton
                        data-testid="refresh-icon"
                        edge="end"
                        onClick={refreshUnitLoadInfo}
                      >
                        <Refresh color="primary" />
                      </IconButton>
                    </Box>
                  )}
                </Box>

                {selectedUnitLoad?.density && (
                  <Box mt={1.5} mb={2} data-testid="density">
                    {isLoading ? (
                      <Container maxWidth="xs">
                        <Skeleton variant="text" />
                      </Container>
                    ) : (
                      <>
                        <Typography variant="subtitle2" align="center">
                          distância entre paradas:{' '}
                          <b>{selectedUnitLoad?.density.toFixed(1)}km </b>
                        </Typography>
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <Divider />
              <Box mt={2.5}>
                <PackageReader
                  disable={isLoading}
                  placeholder="Leia a unidade"
                  onRead={handleRead}
                  loading={isLoading}
                  notes={`Unidade bipado na unidade ${
                    selectedUnitLoad?.licensePlate
                  }`}
                />
                {selectedUnitLoad?.unitLoadsOutlier?.length > 0 && !isLoading && (
                  <Container maxWidth="xs" disableGutters>
                    <Box
                      mt={1.5}
                      backgroundColor={colors.red[50]}
                      data-testid="alert-unit-load-outlier"
                    >
                      <Alert severity="warning" color="warning">
                        <Box display="flex" color={smoke[800]}>
                          <Typography component="div" noWrap>
                            <Box fontWeight="fontWeightBold">
                              {`${
                                selectedUnitLoad?.unitLoadsOutlier?.length
                              } ${pluralize({
                                singular: 'pacote',
                                count:
                                  selectedUnitLoad?.unitLoadsOutlier?.length
                              })}`}
                            </Box>
                          </Typography>
                          <Box>
                            <Typography>&nbsp;com entrega longe</Typography>
                          </Box>
                        </Box>
                      </Alert>
                    </Box>
                  </Container>
                )}
                {packageError && (
                  <Box my={1.5}>
                    <Alert data-testid="error" severity="error">
                      {packageError}
                    </Alert>
                  </Box>
                )}
                {packageWarning && (
                  <Box my={1.5}>
                    <Alert data-testid="warning" severity="warning">
                      {packageWarning}
                    </Alert>
                  </Box>
                )}
                {unitLoadSummary()}
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export const ConfirmPackageRemoval = () => {
  const location = useLocation();

  const selectedChildrenUnitLoad =
    (location.state && location.state.selectedChildrenUnitLoad) || [];

  const unitLoadLpn = (location.state && location.state.unitLoadLpn) || '';

  const [sortingContext] = React.useState(
    location.state && location.state.sortingContext
  );

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const unitLoadChildren = () => {
    return (
      <Box my={4}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Unidade</StyledTableCell>
                <StyledTableCell align="center">CEP</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedChildrenUnitLoad.map(row => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.licensePlate}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.destination?.zipCode || row.destination?.zip}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const confirmRemoval = async () => {
    setLoading(true);

    const requests = selectedChildrenUnitLoad.map(ul => {
      return movePackage(
        '',
        ul.licensePlate,
        null, // sortingDecisionLpn
        sortingContext?.licensePlate
      );
    });
    await Promise.all(requests);

    setLoading(false);
    setSuccess(true);
  };

  if (success) {
    return (
      <Redirect
        to={{
          pathname: '/unit-load',
          state: { unitLoadLpn }
        }}
      />
    );
  }

  const text = `Deseja remover ${selectedChildrenUnitLoad.length} unidade${
    selectedChildrenUnitLoad.length === 1 ? '' : 's'
  }?`;

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">Unidade</Box>
      </Typography>
      <Box display="flex" alignItems="stretch" flexDirection="column" mt={2.5}>
        <Typography variant="h5" align="center">
          {text}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box mt={2.5}>
            <DangerButton
              variant="contained"
              size="large"
              fullWidth
              onClick={confirmRemoval}
            >
              Confirmar
            </DangerButton>
          </Box>
        )}
      </Box>
      {unitLoadChildren()}
    </Container>
  );
};
