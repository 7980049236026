import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { phoneRegex } from './constants';
import { formatPhoneMask, removePhoneInputMask } from './phone-field-helpers';

const PhoneField = ({
  fieldName,
  label,
  isRequired,
  shouldRenderRequiredAsterisk
}) => {
  const { t } = useTranslation('one');
  const [field, meta, { setValue }] = useField({
    name: fieldName,
    validate: newValue => {
      if (!isRequired) return null;
      const phoneWithoutMask = removePhoneInputMask(newValue);
      const isPhoneEmpty = !phoneWithoutMask;
      if (isPhoneEmpty) return t('phoneField.errorMessages.requiredField');
      const isPhoneValid = phoneWithoutMask.match(phoneRegex);
      if (!isPhoneValid) return t('phoneField.errorMessages.invalidNumber');
      return null;
    }
  });

  const { name, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  const handleChange = ({ target }) => {
    const { value: val } = target;
    const cleanInput = String(val).trim();
    const formattedPhone = formatPhoneMask(cleanInput);
    setValue(formattedPhone);
  };

  return (
    <TextField
      autoComplete="tel"
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      InputLabelProps={{ required: shouldRenderRequiredAsterisk }}
      inputProps={{ 'data-testid': `${name}-field`, inputMode: 'tel' }}
      label={label || t('phoneField.label')}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      required={isRequired}
      type="tel"
      value={value}
      variant="outlined"
    />
  );
};

PhoneField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  shouldRenderRequiredAsterisk: PropTypes.bool
};

PhoneField.defaultProps = {
  isRequired: true,
  label: '',
  shouldRenderRequiredAsterisk: true
};

export default PhoneField;
