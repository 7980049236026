import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import JsBarcode from 'jsbarcode';
import { colors } from '@loggi/mar';

/**
 * This component is responsible for rendering the given barcode.
 * Currently it renders as a SVG.
 *
 * @param {string} barcode - Barcode to be rendered
 * @param {Object} options - Options to the render the barcode
 * @returns {JSX.Element}
 */
const BarcodeRenderer = ({ barcode, options }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (barcode) {
      JsBarcode(ref.current, barcode, options);
    }
  }, [barcode, options]);

  return <svg ref={ref} data-testid="barcode-renderer-svg" />;
};

BarcodeRenderer.defaultProps = {
  options: {
    width: 2,
    height: 100,
    displayValue: true,
    font: 'Open Sans',
    format: 'CODE128',
    fontOptions: '',
    textAlign: 'center',
    textPosition: 'bottom',
    textMargin: 2,
    fontSize: 20,
    background: colors.root[0],
    lineColor: colors.root[900],
    margin: 10
  }
};

BarcodeRenderer.propTypes = {
  barcode: PropTypes.string.isRequired,
  options: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    displayValue: PropTypes.bool,
    font: PropTypes.string,
    format: PropTypes.string,
    fontOptions: PropTypes.string,
    textAlign: PropTypes.string,
    textPosition: PropTypes.string,
    textMargin: PropTypes.number,
    fontSize: PropTypes.number,
    background: PropTypes.string,
    lineColor: PropTypes.string,
    margin: PropTypes.number
  })
};

export default BarcodeRenderer;
