import {
  Box,
  Button,
  Chip,
  Hidden,
  makeStyles,
  Popover,
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { colors } from '@loggi/mar';
import { Skeleton, ToggleButton } from '@material-ui/lab';
import { dcShape, DistributionCenterList } from './distribution-center-list';
import { COGNITO_DISTRIBUTION_CENTER } from '../../constants';
import { useDistributionCenter } from '../access-control/distribution-center-provider';
import getUserType from '../access-control/access-control-service';
import DistributionCenterSearch from './distribution-center-search';

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  drawer: {
    '& .MuiDrawer-paper.MuiPaper-root': {
      borderRadius: spacing(2, 2, 0, 0)
    }
  },
  popover: {
    '& .MuiPopover-paper.MuiPaper-root': {
      marginTop: spacing(1),
      [breakpoints.only('xs')]: {
        width: 'calc(100% - 48px)'
      },
      [breakpoints.up('sm')]: {
        width: typography.pxToRem(320)
      }
    }
  },
  root: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    maxHeight: '85vh',
    width: '100%'
  },
  basesButton: {
    color: colors.blue[500],
    padding: spacing(2),
    width: '100%'
  },
  flexBody: {
    flex: '1',
    overflowY: 'auto'
  },
  flexBottom: {
    flex: '0 1 auto'
  },
  profileButton: {
    borderRadius: '50px',
    borderColor: colors.smoke[700],
    backgroundColor: 'white',
    padding: 12
  }
}));

export const CurrentDistributionCenter = ({
  currentDc,
  distributionCenterList,
  selectDC,
  showAllDcs
}) => {
  const classes = useStyles();

  const dcAddress = dc => {
    return dc.address ? `${dc.address.city} - ${dc.address.state}` : '';
  };

  const isUserLeve =
    getUserType(currentDc) === COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const shouldShowDCList = isUserLeve
    ? distributionCenterList?.length > 1
    : distributionCenterList?.length;

  return (
    <Box className={classes.root} pb={2}>
      <Box justify="center" textAlign="center">
        <Box>
          {currentDc ? (
            <Chip
              data-testid="dc-routing-code"
              label={currentDc.routingCode}
              variant="outlined"
            />
          ) : (
            <Skeleton variant="text" />
          )}
        </Box>
        <Box fontWeight="700">
          <Typography variant="h6">
            {currentDc ? (
              <Box data-testid="dc-name" fontWeight="700">
                {currentDc.distributionCenterName}
              </Box>
            ) : (
              <Skeleton variant="text" />
            )}
          </Typography>
        </Box>
        <Box>
          <Typography color="textSecondary" variant="body2">
            {currentDc ? dcAddress(currentDc) : <Skeleton variant="text" />}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.flexBody}>
        {shouldShowDCList ? (
          <DistributionCenterList
            distributionCenterList={distributionCenterList}
            selectedId={currentDc?.distributionCenterId}
            selectDC={selectDC}
          />
        ) : (
          <Box>
            <Skeleton component={Box} height={40} variant="rect" my={1} />
            <Skeleton component={Box} height={40} variant="rect" my={1} />
            <Skeleton component={Box} height={40} variant="rect" my={1} />
          </Box>
        )}
      </Box>
      <Box className={classes.flexBottom}>
        {!isUserLeve && (
          <Button
            className={classes.basesButton}
            onClick={showAllDcs}
            data-testid="search-dcs"
          >
            Ver todas as bases
          </Button>
        )}
      </Box>
    </Box>
  );
};

CurrentDistributionCenter.defaultProps = {
  selectDC: () => {},
  currentDc: null,
  showAllDcs: () => {}
};

CurrentDistributionCenter.propTypes = {
  currentDc: dcShape,
  selectDC: PropTypes.func,
  distributionCenterList: PropTypes.arrayOf(dcShape).isRequired,
  showAllDcs: PropTypes.func
};

export const DistributionCenterSelectMenu = ({
  anchorElement,
  open,
  handleClose
}) => {
  const styles = useStyles();
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const {
    state: {
      allDistributionCenters,
      recentDistributionCenters,
      selectedDistributionCenter
    },
    setSelectedDC
  } = useDistributionCenter();
  const [shouldShowAllDcs, setShouldShowAllDcs] = useState(false);

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const dcsToBeRendered = isUserLeve
    ? allDistributionCenters
    : recentDistributionCenters;

  const dcSelector = dc => {
    setSelectedDC(dc, authenticatedUser.email);
    setShouldShowAllDcs(false);
    handleClose();
  };

  const close = () => {
    setShouldShowAllDcs(false);
    handleClose();
  };

  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          data-testid="distribution-center-select"
          className={styles.drawer}
          anchor="bottom"
          open={open}
          onOpen={() => {}}
          onClose={close}
        >
          <Box display="flex" p={2}>
            {shouldShowAllDcs ? (
              <DistributionCenterSearch
                currentDc={selectedDistributionCenter}
                selectDC={dc => dcSelector(dc)}
                distributionCenterList={allDistributionCenters}
                cancel={() => setShouldShowAllDcs(false)}
              />
            ) : (
              <CurrentDistributionCenter
                currentDc={selectedDistributionCenter}
                distributionCenterList={dcsToBeRendered}
                selectDC={dc => dcSelector(dc)}
                showAllDcs={() => setShouldShowAllDcs(true)}
              />
            )}
          </Box>
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Popover
          data-testid="distribution-center-select"
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className={styles.popover}
          elevation={24}
          marginThreshold={24}
          onClose={close}
          open={open}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <Box display="flex" p={2}>
            {shouldShowAllDcs ? (
              <DistributionCenterSearch
                currentDc={selectedDistributionCenter}
                selectDC={dc => dcSelector(dc)}
                distributionCenterList={allDistributionCenters}
                cancel={() => setShouldShowAllDcs(false)}
              />
            ) : (
              <CurrentDistributionCenter
                currentDc={selectedDistributionCenter}
                distributionCenterList={dcsToBeRendered}
                selectDC={dc => dcSelector(dc)}
                showAllDcs={() => setShouldShowAllDcs(true)}
              />
            )}
          </Box>
        </Popover>
      </Hidden>
    </>
  );
};

DistributionCenterSelectMenu.defaultProps = {
  anchorElement: null
};

DistributionCenterSelectMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorElement: PropTypes.instanceOf(Element)
};

export const DistributionCenterSelectButton = () => {
  const styles = useStyles();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const menuRef = useRef(null);

  return (
    <>
      <ToggleButton
        className={styles.profileButton}
        data-testid="dc-select-menu-btn-id"
        onChange={() => setIsSelectorOpen(!isSelectorOpen)}
        ref={menuRef}
        selected={isSelectorOpen}
        size="small"
        variant="outlined"
        value="selected"
      >
        <Typography component="span" variant="body2">
          <Box fontWeight={700} lineHeight={1} color={colors.smoke[700]}>
            {selectedDistributionCenter?.routingCode || ''}
          </Box>
        </Typography>
      </ToggleButton>

      <DistributionCenterSelectMenu
        anchorElement={menuRef.current}
        handleClose={() => setIsSelectorOpen(false)}
        open={isSelectorOpen}
      />
    </>
  );
};
