import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecord';
import { PACKAGE_LABEL_BY_STATUS } from '../../constants';

function StatusColorDescription({ pkgStatus }) {
  const statusMap =
    PACKAGE_LABEL_BY_STATUS[pkgStatus] ||
    PACKAGE_LABEL_BY_STATUS.PACKAGE_STATUS_CODE_UNAVAILABLE;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" py={1}>
      <FiberManualRecordRoundedIcon
        fontSize="small"
        style={statusMap.style}
        data-testid="package-status-color"
      />
      <Box
        pl={1.5}
        style={{ overflowWrap: 'break-word' }}
        data-testid="package-status-label"
      >
        <Typography noWrap variant="subtitle2">
          {statusMap.text}
        </Typography>
      </Box>
    </Box>
  );
}

StatusColorDescription.propTypes = {
  pkgStatus: PropTypes.string
};

StatusColorDescription.defaultProps = {
  pkgStatus: PACKAGE_LABEL_BY_STATUS.PACKAGE_STATUS_CODE_UNAVAILABLE.text
};

export default StatusColorDescription;
