import React from 'react';

import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Container,
  Dialog,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import LensIcon from '@material-ui/icons/Lens';
import { SWITCHES } from '../../constants';
import SharedPropTypes from '../../app/shared-prop-types';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { useFeature } from '../../app/hooks/use-feature';

export default function DriverDetailsDialog({ handleClose, driver }) {
  const enableDriverIdentificationInDistribute = useFeature(
    SWITCHES.enableDriverIdentificationInDistribute
  );
  return (
    <Dialog
      open
      fullScreen
      data-testid="driver-details-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none',
          overflow: 'hidden',
          padding: 0
        }
      }}
    >
      <Container maxWidth="xs" disableGutters>
        <Box
          display="flex"
          flexDirection="column"
          height="100vh"
          overflow="hidden"
        >
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              paddingTop={1}
              paddingLeft={3}
            >
              <IconButton
                edge="start"
                onClick={handleClose}
                data-testid="close-driver-details-dialog"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box height={1} paddingTop={3} paddingLeft={3}>
            <Box width={pxToRem(120)} height={pxToRem(120)} clone>
              <Avatar src={driver.photoUrl?.profileHighRes} />
            </Box>
            <Box mt={1.5}>
              <Typography variant="h5">
                <Box fontWeight="fontWeightBold">{driver.fullName}</Box>
              </Typography>
              {enableDriverIdentificationInDistribute && (
                <Box mt={1.5}>
                  <Typography variant="h5">
                    De {driver?.transportType}
                  </Typography>
                </Box>
              )}
              <Box
                mt={1.5}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography variant="subtitle2">
                  CNH: {driver?.docCnh?.number}
                </Typography>
                <LensIcon
                  style={{
                    width: pxToRem(3),
                    height: pxToRem(3),
                    margin: 5
                  }}
                />
                <Typography variant="subtitle2">
                  RG: {driver?.docRg?.number}
                </Typography>
              </Box>
              <Box mt={1.5}>
                <Typography variant="subtitle2">
                  CPF: {driver?.docCpf?.number}
                </Typography>
              </Box>
            </Box>
          </Box>
          {enableDriverIdentificationInDistribute && (
            <Box alignItems="center" p={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                Continuar
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Dialog>
  );
}

DriverDetailsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  driver: SharedPropTypes.driver.isRequired
};
