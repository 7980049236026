import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Typography,
  Container,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';

import { useSnackbar } from 'notistack';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';

import { playErrorBeep, playSuccessBeep } from '../../../sounds';
import {
  getTripCargo,
  addCargoToTransferByTrip,
  cancelTrip,
  getTripStats
} from '../../../api-rest';
import { BarcodeReader } from '../../../app/components/barcode-readers';
import HeaderWithCancel from '../../../app/components/header-with-cancel';
import handleRestAPIErrorWithMessage from '../../../app/utils/handle-rest-api-error-with-message';

import {
  ADD_CARGO_ERROR_MSGS,
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  HTTP_STATUS_CODES,
  TRIP_PAGE_STATES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  SWITCHES,
  ADD_CARGO_CONFIRMATION_MESSAGES,
  InvalidCargoDestination,
  BAG_AVERAGE,
  COGNITO_DISTRIBUTION_CENTER
} from '../../../constants';

import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import { CargoDestinationsList, CargoInfo } from './cargo-info';
import { PGRProgress, round } from './pgr-progress';
import { TripProcessContext } from './trip-process-context';
import Confirmation from '../../../app/components/confirmation';
import { useFeature } from '../../../app/hooks/use-feature';
import pluralize from '../../../app/utils/pluralize';
import { formatMoneyToNumber } from '../../../app/utils/br-locale-utils';
import FloatingContainer from '../../../app/components/floating-container';
import getUserType from '../../../app/access-control/access-control-service';

export default function ScanItensTrip() {
  const { setPageState, selectedDC, trip, cargos } = useContext(
    TripProcessContext
  );

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const stepPercentage = 0.5;

  const showTransferCargoWeight = useFeature(SWITCHES.showTransferCargoWeight);

  const showCargoMerchandiseValue = useFeature(
    SWITCHES.showTransferCargoMerchandiseValue
  );

  const isUserLeve =
    getUserType(selectedDC) === COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const cancelScanConfirmationTextTitle = 'Tem certeza que quer cancelar?';
  const enableBulkScan =
    useFeature(SWITCHES.enableBulkScanDistributeTransfers) || isUserLeve;
  const enableAddCargoWithInvalidDestination = useFeature(
    SWITCHES.enableAddCargoWithInvalidDestination
  );

  const enableShowPgrValues = useFeature(SWITCHES.enableShowPgrValues);

  const [showCargoInfo, setShowCargoInfo] = useState(false);
  const [totalCargosBeeped, setTotalCargosBeeped] = useState(0);
  const [cargoCompany, setCargoCompany] = useState('');
  const [cargoDestination, setCargoDestination] = useState('');
  const [cargoFinalDestination, setCargoFinalDestination] = useState('');
  const [cargoLicensePlate, setCargoLicensePlate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [cargoTotalWeight, setCargoTotalWeight] = useState(0);
  const [cargoMerchandiseValue, setCargoMerchandiseValue] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
  const [forceOverrideToken, setForceOverrideToken] = useState('');
  const [invalidDestination, setInvalidDestination] = useState(false);
  const [showPossibleDestinations, setShowPossibleDestinations] = useState(
    false
  );
  const [
    selectedDestinationRoutingCode,
    setSelectedDestinationRoutingCode
  ] = useState('');
  const [confirmSelectedDestination, setConfirmSelectedDestination] = useState(
    false
  );
  const [confirmationText, setConfirmationText] = useState('');
  const [returnRelatedCargo, setReturnRelatedCargo] = useState(true);
  const [showBulkScanConfirmation, setShowBulkScanConfirmation] = useState(
    false
  );
  const [
    showBulkScanErrorInformation,
    setShowBulkScanErrorInformation
  ] = useState(false);
  const [relatedCargo, setRelatedCargo] = useState([]);
  const [firstConfirmationType, setFirstConfirmationType] = useState('');
  const [licensePlatesNotAdded, setLicensePlatesNotAdded] = useState([]);
  const [totalMerchandiseValue, setTotalMerchandiseValue] = useState(0);
  const [pgrPercentage, setPGRPercentage] = useState(0);
  const [
    showPGRLimitReachedConfirmation,
    setShowPGRLimitReachedConfirmation
  ] = useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const [maxMerchandiseValueAllowed] = useState(
    formatMoneyToNumber(trip.maxMerchandiseValueAllowed)
  );

  const destinationDescription = (destinationRoutingCode, destinationName) => {
    return `${destinationRoutingCode} ${destinationName}`;
  };

  const getArrivalTime = legs => {
    let actualLeg = {};
    const minDate = new Date('1970-01-01T00:00:00Z').toUTCString();

    actualLeg = legs.find(
      // eslint-disable-next-line camelcase
      leg => leg.source?.routing_code === selectedDC?.routingCode
    );

    if (!actualLeg) {
      actualLeg = legs.find(
        // eslint-disable-next-line camelcase
        leg => leg.destination?.routing_code === selectedDC?.routingCode
      );

      return new Date(actualLeg.destinationRealArrivalTime).toUTCString() !==
        minDate
        ? moment(actualLeg.destinationRealArrivalTime).format('HH:MM')
        : moment(actualLeg.destinationExpectedArrivalTime).format('HH:MM');
    }

    return new Date(actualLeg.sourceRealArrivalTime).toUTCString() !== minDate
      ? moment(actualLeg.sourceRealArrivalTime).format('HH:MM')
      : moment(actualLeg.sourceExpectedArrivalTime).format('HH:MM');
  };

  const baseErrorHandler = error => {
    setLoading(false);

    handleRestAPIErrorWithMessage(
      BASE_TRIP_TRANSFER_ERROR_MSGS,
      error,
      message => showSnackbarAlert(message, 'error')
    );
  };

  const evaluatePGRValues = (cargoValue = 0) => {
    if (maxMerchandiseValueAllowed) {
      const total = totalMerchandiseValue + cargoValue;

      setTotalMerchandiseValue(total);

      const percentage = round(
        (total / maxMerchandiseValueAllowed) * 100,
        stepPercentage
      );

      setPGRPercentage(percentage);

      if (total >= maxMerchandiseValueAllowed) {
        setShowPGRLimitReachedConfirmation(true);
        return;
      }

      if (total > maxMerchandiseValueAllowed - BAG_AVERAGE * 2) {
        showSnackbarAlert(
          'Você atingiu o limite de valor permitido para este veículo.'
        );
      }
    }
  };

  const getCargos = async () => {
    setLoading(true);
    const response = await getTripCargo({
      tripId: trip.id,
      sourceRoutingCode: selectedDC?.routingCode
    }).catch(error => baseErrorHandler(error));
    if (response?.status === HTTP_STATUS_CODES.OK && response.data?.cargo) {
      const validCargo = response.data.cargo?.filter(c => c && !c.started);
      setTotalCargosBeeped(validCargo.length);
    }
    setLoading(false);
  };

  const getStats = async () => {
    setLoading(true);
    const response = await getTripStats(trip.id).catch();

    if (!response) {
      setLoading(false);
      return;
    }

    if (response.data?.cargoStats?.merchandiseValue) {
      evaluatePGRValues(
        formatMoneyToNumber(response?.data?.cargoStats?.merchandiseValue)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (cargos?.length > 0) {
      setTotalCargosBeeped(cargos.length);
    } else {
      getCargos();
    }
    if (enableShowPgrValues) getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      openConfirmation &&
      !invalidDestination &&
      !confirmSelectedDestination
    ) {
      playErrorBeep();
      // Added to give an error beep when the confirmation modal appears to force the load in line 724
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConfirmation, invalidDestination, confirmSelectedDestination]);

  const successAddCargoHandler = response => {
    playSuccessBeep();
    setErrorMessage('');
    setTotalCargosBeeped(totalCargosBeeped + 1);
    setCargoCompany(response.data.cargo.sourceCompanyName);
    setCargoDestination(
      destinationDescription(
        response.data.cargo.destinationRoutingCode,
        response.data.cargo.destinationName
      )
    );
    setCargoFinalDestination(
      destinationDescription(
        response.data.cargo.finalDestinationRoutingCode,
        response.data.cargo.finalDestinationName
      )
    );
    setCargoLicensePlate(response.data.cargo.licensePlate);
    setCargoTotalWeight(response.data.cargo.totalGrossWeightInGrams);
    setCargoMerchandiseValue(response.data.cargo.totalValue);

    setShowCargoInfo(true);
    setCargoTotalWeight(response.data.cargo.totalGrossWeightInGrams);

    if (response.data.relatedCargo?.length) {
      setRelatedCargo(response.data.relatedCargo);
      setShowBulkScanConfirmation(true);
    }

    if (enableShowPgrValues) {
      evaluatePGRValues(formatMoneyToNumber(response.data.cargo.totalValue));
    }

    setLoading(false);
  };

  const successCancelTripHandler = () => {
    showSnackbarAlert('Pronto, tudo certo.\n O processo foi cancelado.');

    history.push({
      pathname: '/'
    });
  };

  const addCargoErrorHandler = error => {
    handleRestAPIErrorWithMessage(
      { ...BASE_TRIP_TRANSFER_ERROR_MSGS, ...ADD_CARGO_ERROR_MSGS },
      error,
      message => setErrorMessage(message)
    );
    setLoading(false);
    playErrorBeep();
    setShowCargoInfo(false);
  };

  const isDestinationSelected = selectedDestination =>
    selectedDestinationRoutingCode === selectedDestination;

  const handleSelectDestination = selectedDestination => {
    setOpenConfirmation(true);
    setSelectedDestinationRoutingCode(selectedDestination);
    setLoading(false);
  };

  const addCargoErrorValidation = (error, licensePlate) => {
    const overrideToken = error.response?.data?.overrideToken;

    if (overrideToken) {
      const responseData = error.response?.data;
      const responseType = responseData?.type;
      const text =
        ADD_CARGO_CONFIRMATION_MESSAGES[responseType] ||
        responseData?.errorMsg ||
        responseData?.error;

      setConfirmationText(text);
      setForceOverrideToken(overrideToken);
      setCargoLicensePlate(licensePlate);
      setOpenConfirmation(true);
      const isInvalidDestination = responseType === InvalidCargoDestination;
      setInvalidDestination(isInvalidDestination);
      setFirstConfirmationType(responseType);
      if (!enableAddCargoWithInvalidDestination && isInvalidDestination) {
        addCargoErrorHandler(error);
      }
      return;
    }
    addCargoErrorHandler(error);
  };

  const addCargo = async ({
    licensePlate,
    returnRC,
    addedInBulk,
    overrideToken = '',
    destinationRoutingCode = ''
  }) => {
    const uppercasedLicensePlate = licensePlate.toUpperCase();

    return addCargoToTransferByTrip({
      tripId: trip.id,
      licensePlate: uppercasedLicensePlate,
      sourceRoutingCode: selectedDC?.routingCode,
      forceOverrideToken: overrideToken,
      returnRelatedCargo: returnRC,
      addedInBulk,
      destinationRoutingCode
    });
  };

  const forceAddCargoHandler = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_TRIP_CONFIRMATION_BEEP
    );

    try {
      const response = await addCargo({
        licensePlate: cargoLicensePlate,
        returnRC: enableBulkScan && returnRelatedCargo,
        addedInBulk: false,
        overrideToken: forceOverrideToken,
        destinationRoutingCode: selectedDestinationRoutingCode
      });

      if (response && response.status === HTTP_STATUS_CODES.OK) {
        successAddCargoHandler(response);
      }
    } catch (error) {
      addCargoErrorValidation(error, cargoLicensePlate);
    }

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_TRIP_CONFIRMATION_BEEP
    );
    setLoading(false);
    setConfirmSelectedDestination(true);
    setInvalidDestination(false);
    setShowPossibleDestinations(false);
    setOpenConfirmation(false);
    setConfirmSelectedDestination(false);
  };

  const handleCancelForceAdd = () => {
    setSelectedDestinationRoutingCode('');
    setOpenConfirmation(false);
  };

  const handlePossibleDestinations = () => {
    setOpenConfirmation(false);
    setShowPossibleDestinations(true);
  };

  const goBack = () => {
    setShowPossibleDestinations(false);
  };

  const handleConfirmForceAdd = () => {
    setLoading(true);
    setOpenConfirmation(false);
    forceAddCargoHandler();
  };

  const handleRead = async licensePlate => {
    if (loading) return;

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_TRIP_UNIT_BEEP
    );

    setLoading(true);

    try {
      const response = await addCargo({
        licensePlate,
        returnRC: enableBulkScan && returnRelatedCargo,
        addedInBulk: false
      });

      if (response && response.status === HTTP_STATUS_CODES.OK) {
        successAddCargoHandler(response);
      }
    } catch (error) {
      addCargoErrorValidation(error, licensePlate);
    }

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_TRIP_UNIT_BEEP
    );
    setLoading(false);
  };

  const handleConferirLista = () => {
    setPageState(TRIP_PAGE_STATES.REVIEW_CARGO_START_TANSFER);
  };

  const handleCancel = async () => {
    setOpenCancelConfirmation(true);
  };

  const handleConfirmCancelScan = async () => {
    setOpenCancelConfirmation(false);
    history.push({
      pathname: '/'
    });
  };

  const handleContinueScan = () => {
    setOpenCancelConfirmation(false);
  };

  const handleConfirmCancelAndClear = async () => {
    setOpenCancelConfirmation(false);
    setLoading(true);
    const response = await cancelTrip({
      tripId: trip.id,
      sourceRoutingCode: selectedDC?.routingCode
    }).catch(error => baseErrorHandler(error));
    setLoading(false);
    if (response && response.status === HTTP_STATUS_CODES.OK) {
      successCancelTripHandler();
    }
  };

  const addCargoIgnoreFirstConfirmation = async (
    licensePlate,
    overrideToken = ''
  ) => {
    try {
      return await addCargo({
        licensePlate,
        returnRC: false,
        addedInBulk: true,
        overrideToken
      });
    } catch (error) {
      const receivedOverrideToken = error.response?.data?.overrideToken;

      if (
        error.response?.data?.type === firstConfirmationType &&
        receivedOverrideToken
      ) {
        addCargoIgnoreFirstConfirmation(licensePlate, receivedOverrideToken);
      }

      throw error;
    }
  };

  const successBulkHandler = cargo => {
    setRelatedCargo([]);
    setCargoCompany(cargo.sourceCompanyName);
    setCargoDestination(
      destinationDescription(
        cargo.destinationRoutingCode,
        cargo.destinationName
      )
    );
    setCargoFinalDestination(
      destinationDescription(
        cargo.finalDestinationRoutingCode,
        cargo.finalDestinationName
      )
    );
    setCargoLicensePlate(cargo.licensePlate);
    setCargoTotalWeight(cargo.totalGrossWeightInGrams);
    setCargoMerchandiseValue(cargo.totalValue);

    setCargoTotalWeight(cargo.totalGrossWeightInGrams);
  };

  const handleBulkScan = async () => {
    setShowBulkScanConfirmation(false);
    let cargo = {};
    let hasError = false;
    let cargoValues = 0;

    /* eslint-disable no-restricted-syntax */
    /* eslint-disable no-await-in-loop */
    for (const rc of relatedCargo) {
      setLoading(true);
      const { licensePlate } = rc;
      try {
        const response = await addCargoIgnoreFirstConfirmation(licensePlate);

        if (response && response.status === HTTP_STATUS_CODES.OK) {
          cargo = response.data.cargo;
          cargoValues += formatMoneyToNumber(cargo.totalValue);
          setTotalCargosBeeped(total => total + 1);
        }
      } catch (error) {
        const errorType = error.response?.data?.type;
        let bulkErrorMessage = '';
        handleRestAPIErrorWithMessage(
          {
            ...BASE_TRIP_TRANSFER_ERROR_MSGS,
            ...ADD_CARGO_ERROR_MSGS
          },
          error,
          message => {
            bulkErrorMessage = message;
          }
        );

        setLicensePlatesNotAdded(notAdded => [
          ...notAdded,
          {
            licensePlate,
            errorType,
            bulkErrorMessage
          }
        ]);

        hasError = true;
      }
      /* eslint-enable no-restricted-syntax */
      /* eslint-enable no-await-in-loop */
    }

    if (enableShowPgrValues) {
      evaluatePGRValues(cargoValues);
    }

    if (hasError) {
      setShowBulkScanErrorInformation(true);
    } else {
      successBulkHandler(cargo);
    }

    setLoading(false);
  };

  const getConfirmationErrorText = () => {
    return ReactDOMServer.renderToString(
      <ConfirmationBulkErrorsText
        relatedCargo={relatedCargo}
        licensePlatesNotAdded={licensePlatesNotAdded}
      />
    );
  };

  const handleBulkScanConfirmation = (errorHandling = false) => {
    if (errorHandling) {
      setShowBulkScanErrorInformation(false);
      setLicensePlatesNotAdded([]);
    } else {
      setShowBulkScanConfirmation(false);
    }
    setRelatedCargo([]);
    setReturnRelatedCargo(false);
  };

  return (
    <Box height="100vh">
      <Box display="flex" flexDirection="column" height={1}>
        <Box flex={1}>
          <Container maxWidth="xs" data-testid="scan-itens-trip" disableGutters>
            <Box paddingTop={2.5} px={3}>
              <HeaderWithCancel
                isDisabled={loading}
                testId="scan-itens-trip-cancel-button"
                handleClose={handleCancel}
              />
              {openCancelConfirmation && (
                <Confirmation
                  open
                  titleText={cancelScanConfirmationTextTitle}
                  onConfirm={handleConfirmCancelScan}
                  confirmText="Sim, sair e manter unidades."
                  onCancel={handleContinueScan}
                  cancelText="Não, desejo continuar."
                  extraOptionText="Sim, sair e remover unidades."
                  onExtraOption={handleConfirmCancelAndClear}
                  zIndex={2}
                />
              )}
              <Box paddingTop={1.5}>
                <Typography component="div" variant="body1" gutterBottom>
                  <Box fontWeight="fontWeightBold">Distribuir</Box>
                </Typography>
              </Box>
              <Box mt={2.5}>
                <BarcodeReader
                  onRead={handleRead}
                  loading={loading}
                  disable={showPossibleDestinations}
                  placeholder="Leia o código do pacote ou o lacre da saca"
                />
                {errorMessage && (
                  <Box mt={1.5}>
                    <Alert severity="error">{errorMessage}</Alert>
                  </Box>
                )}
                {enableAddCargoWithInvalidDestination &&
                  openConfirmation &&
                  invalidDestination && (
                    <Confirmation
                      open
                      subtitleText={confirmationText}
                      onConfirm={handlePossibleDestinations}
                      confirmText="Sim"
                      onCancel={handleCancelForceAdd}
                      cancelText="Não"
                      zIndex={2}
                    />
                  )}
                {showPossibleDestinations && !confirmSelectedDestination && (
                  <Box height="100vh" overflow="auto">
                    <Container
                      maxWidth="xs"
                      data-testid="cargo-destination-list"
                      disableGutters
                    >
                      <Box pt={15} pb={12}>
                        <Typography variant="subtitle2">
                          <Box>
                            Escolha um dos destinos disponíveis para
                            transferência.
                          </Box>
                        </Typography>
                        <Divider />
                        <List>
                          {trip.legs.map(
                            leg =>
                              leg.destination.routing_code !==
                                selectedDC?.routingCode && (
                                <CargoDestinationsList
                                  key={leg.destination.name}
                                  handleSelectDestination={
                                    handleSelectDestination
                                  }
                                  isDestinationSelected={isDestinationSelected}
                                  destinationName={leg.destination.name}
                                  destinationRoutingCode={
                                    leg.destination.routing_code
                                  }
                                />
                              )
                          )}
                        </List>
                        <Button
                          fullWidth
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={goBack}
                          disabled={loading}
                        >
                          Voltar
                        </Button>
                        {openConfirmation && !confirmSelectedDestination && (
                          <Confirmation
                            open
                            subtitleText="Deseja mover a carga para o destino selecionado?"
                            onConfirm={handleConfirmForceAdd}
                            confirmText="Sim"
                            onCancel={handleCancelForceAdd}
                            cancelText="Não"
                            zIndex={2}
                          />
                        )}
                      </Box>
                    </Container>
                  </Box>
                )}
                {openConfirmation &&
                  !invalidDestination &&
                  !confirmSelectedDestination && (
                    <Confirmation
                      open
                      subtitleText={confirmationText}
                      onConfirm={handleConfirmForceAdd}
                      confirmText="Sim"
                      onCancel={handleCancelForceAdd}
                      cancelText="Não"
                      zIndex={2}
                    />
                  )}
                {showBulkScanConfirmation && (
                  <Confirmation
                    open
                    subtitleText={`Encontramos <b>${
                      relatedCargo.length
                    } ${pluralize({
                      singular: 'unidade',
                      count: relatedCargo.length
                    })} </b> que podem ser distribuídas com esse item. Adicione todas de uma vez ou continue bipando.`}
                    onConfirm={handleBulkScan}
                    confirmText="Adicionar unidades"
                    onCancel={() => handleBulkScanConfirmation()}
                    cancelText="Continuar bipando"
                    zIndex={2}
                  />
                )}
                {showBulkScanErrorInformation && (
                  <Confirmation
                    open
                    subtitleText={getConfirmationErrorText()}
                    onConfirm={() => handleBulkScanConfirmation(true)}
                    confirmText="Fechar"
                    zIndex={2}
                  />
                )}
                {showPGRLimitReachedConfirmation && (
                  <Confirmation
                    open
                    subtitleText="Você ultrapassou o limite de valor permitido para este veículo. Remova unidades ou continue bipando."
                    onConfirm={() =>
                      setPageState(TRIP_PAGE_STATES.EDIT_LIST_CARGO)
                    }
                    confirmText="Remover unidades"
                    cancelText="Continuar a bipar"
                    onCancel={() => setShowPGRLimitReachedConfirmation(false)}
                    zIndex={2}
                  />
                )}
              </Box>
            </Box>

            <Box paddingTop={3} px={3}>
              <Typography gutterBottom component="div" variant="caption">
                Placa {trip.vehicleLicensePlate}
              </Typography>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {trip.drivers?.[0]?.name} da {trip.carrier?.name}
              </Typography>
              <Typography component="div" variant="caption">
                chegada prevista para hoje, {getArrivalTime(trip.legs)}
              </Typography>
            </Box>
          </Container>
        </Box>

        <Box marginTop={8} marginBottom={2} px={3}>
          <Container maxWidth="xs" disableGutters>
            <Typography
              variant="h1"
              display="inline"
              data-testid="total-cargos-beeped"
            >
              {totalCargosBeeped}
              <Typography variant="caption" component="span">
                {` un.`}
              </Typography>
            </Typography>
          </Container>
        </Box>
        {enableShowPgrValues && maxMerchandiseValueAllowed > 0 && (
          <Box marginBottom={2} px={3}>
            <Container maxWidth="xs" disableGutters>
              <PGRProgress percentage={pgrPercentage} />
            </Container>
          </Box>
        )}
        <Container maxWidth="xs" disableGutters>
          {totalCargosBeeped > 0 && (
            <FloatingContainer>
              {showCargoInfo && (
                <List disablePadding>
                  <CargoInfo
                    cargoLicensePlate={cargoLicensePlate}
                    cargoDestination={cargoDestination}
                    cargoFinalDestination={cargoFinalDestination}
                    showTransshipmentInfo={
                      cargoFinalDestination !== cargoDestination
                    }
                    cargoCompany={cargoCompany}
                    showDivider={false}
                    showCargoWeight={showTransferCargoWeight}
                    cargoTotalWeightInGrams={cargoTotalWeight}
                    showCargoMerchandiseValue={showCargoMerchandiseValue}
                    cargoMerchandiseValue={cargoMerchandiseValue}
                  />
                </List>
              )}
              <Box mt={1} flex="initial" px={3}>
                <Box display="flex">
                  <Box flex={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleConferirLista}
                      disabled={totalCargosBeeped <= 0 || loading}
                    >
                      Conferir lista
                    </Button>
                  </Box>
                </Box>
              </Box>
            </FloatingContainer>
          )}
        </Container>
      </Box>
    </Box>
  );
}

function ConfirmationBulkErrorsText({ relatedCargo, licensePlatesNotAdded }) {
  const groupedErrors = {};

  licensePlatesNotAdded.forEach(x => {
    if (groupedErrors[x.errorType]) {
      groupedErrors[x.errorType].push(x);
    } else {
      groupedErrors[x.errorType] = [x];
    }
  });

  return (
    <Box>
      <Typography align="left" variant="subtitle2">
        <Box fontSize={18}>
          Não foi possível adicionar{' '}
          <b>
            {licensePlatesNotAdded.length}{' '}
            {pluralize({
              singular: 'unidade',
              count: licensePlatesNotAdded.length
            })}
          </b>{' '}
          à lista.
        </Box>
      </Typography>

      <List disableGutters>
        {Object.keys(groupedErrors).map(errorType => {
          const values = groupedErrors[errorType];
          const message = values[0].bulkErrorMessage;
          return (
            <ListItem key={errorType} divider disableGutters>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2">
                    <Box fontWeight="fontWeightBold">{message}</Box>
                  </Typography>
                }
                secondary={
                  <List>
                    {values.map(item => {
                      const cargo = relatedCargo.find(
                        rc => rc.licensePlate === item.licensePlate
                      );
                      return (
                        <ListItemText
                          key={cargo.licensePlate}
                          disableTypography
                          primary={
                            <Typography variant="subtitle2">
                              {cargo.licensePlate}
                            </Typography>
                          }
                          secondary={
                            cargo.sourceCompanyName && (
                              <Box mb={2}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {`Pacote de ${cargo.sourceCompanyName}`}
                                </Typography>
                              </Box>
                            )
                          }
                        />
                      );
                    })}
                  </List>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

ConfirmationBulkErrorsText.propTypes = {
  relatedCargo: PropTypes.arrayOf(
    PropTypes.shape({
      licensePlate: PropTypes.string.isRequired
    })
  ).isRequired,
  licensePlatesNotAdded: PropTypes.arrayOf(
    PropTypes.shape({
      errorType: PropTypes.string.isRequired,
      bulkErrorMessage: PropTypes.string.isRequired,
      licensePlate: PropTypes.string.isRequired
    })
  ).isRequired
};
