import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';

/*
 * DamagedPackage context, used to store the state of the damaged package filling steps.
 */
export const DamagedPackageContext = React.createContext();

export const DamagedPackageProvider = ({ children, overrides }) => {
  const [physicalStatePackage, setPhysicalStatePackage] = useState([]);
  const [isProductDamaged, setIsProductDamaged] = useState(null);
  const [physicalStateProduct, setPhysicalStateProduct] = useState([]);
  const [packageId, setPackageId] = useState(null);
  const [imageLabel, setImageLabel] = useState('');
  const [isImageLabelIdentified, setIsImageLabelIdentified] = useState(false);
  const [imagesPackage, setImagesPackage] = useState([]);
  const [damageImageCurrent, setDamageImageCurrent] = useState(null);
  const [damageLocation, setDamageLocation] = useState(null);
  const [productLostStatusType, setProductLostStatusType] = useState(null);
  const [packagingQualityStatusType, setPackagingQualityStatusType] = useState(
    null
  );

  const {
    state: {
      selectedDistributionCenter: { distributionCenterId }
    }
  } = useDistributionCenter();

  const defaultValues = {
    distributionCenterId,
    physicalStatePackage,
    setPhysicalStatePackage,
    isProductDamaged,
    setIsProductDamaged,
    physicalStateProduct,
    setPhysicalStateProduct,
    packageId,
    setPackageId,
    imageLabel,
    setImageLabel,
    imagesPackage,
    setImagesPackage,
    isImageLabelIdentified,
    setIsImageLabelIdentified,
    damageImageCurrent,
    setDamageImageCurrent,
    damageLocation,
    setDamageLocation,
    productLostStatusType,
    setProductLostStatusType,
    packagingQualityStatusType,
    setPackagingQualityStatusType
  };

  return (
    <DamagedPackageContext.Provider value={{ ...defaultValues, ...overrides }}>
      {children}
    </DamagedPackageContext.Provider>
  );
};

DamagedPackageProvider.defaultProps = {
  overrides: null
};

DamagedPackageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  overrides: PropTypes.shape({})
};
