/**
 * On Every GraphQL request is possible that some error unrelated to the operation
 * is returned like this: { errors: [{ message: "user not allowed" }] }.
 * This is a very common procedure on this application, so you can use this handler to avoid repetition.
 */

const extractDataFromGraphQLResponse = async graphQLResponse => {
  if (!graphQLResponse) {
    return null;
  }
  const payload = await graphQLResponse.json();

  if (payload.errors) {
    // An error will be thrown to easier treatment on views
    // you can use a simple .catch to render or track this error message
    throw new Error(payload.errors.map(err => err.message).join('\n'));
  }

  return payload.data;
};

export default extractDataFromGraphQLResponse;
