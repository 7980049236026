import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import {
  dcShape,
  DistributionCenterList
} from '../app/components/distribution-center-list';
import { useDistributionCenter } from '../app/access-control/distribution-center-provider';
import Logo from '../assets/images/icon.png';
import { ReactComponent as ErrorImage } from '../assets/images/endpoint-error.svg';

export const RENDER_STATE = {
  LOADING: 'loading',
  DEFAULT: 'default',
  ERROR: 'error'
};

const SkeletonGroup = () => {
  return (
    <Box marginTop={3}>
      <Box width="30%" my={1} paddingX={3}>
        <Skeleton />
      </Box>
      <Box width="70%" my={1} paddingX={3}>
        <Skeleton />
      </Box>
      <Box marginTop={3}>
        <Divider component={Box} />
      </Box>
    </Box>
  );
};

const SearchInput = ({ searchDCs }) => {
  return (
    <Box
      data-testid="dc-search-input"
      borderRadius={8}
      display="flex"
      justifyContent="center"
      padding={3}
      paddingTop={2}
    >
      <TextField
        onChange={event => searchDCs(event.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        autoFocus
        placeholder="Busque por sigla, região ou nome da base"
        InputProps={{
          inputProps: {
            'data-testid': 'input-search'
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon size="small" color="disabled" />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

SearchInput.propTypes = {
  searchDCs: PropTypes.func.isRequired
};

export const ChooseDistributionCenterView = ({
  renderState,
  distributionCenterList,
  reloadList,
  leaveScreen,
  selectDC,
  proceed,
  selectedId
}) => {
  const [searchTerm, setSearchTerm] = React.useState();
  const [shownList, setShownList] = React.useState();

  useEffect(() => {
    if (!searchTerm) {
      setShownList(distributionCenterList);
      return;
    }
    const foundDCs = searchTerm
      ? distributionCenterList.filter(dc => {
          return (
            dc.routingCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            dc.distributionCenterName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        })
      : [];
    setShownList(foundDCs);
  }, [distributionCenterList, searchTerm]);

  return (
    <Box
      bgcolor="white"
      height="100vh"
      paddingY={3}
      data-testid="choose-dc-page"
    >
      <Box display="flex" height="100%" flexDirection="column">
        <Box paddingX={3}>
          <Box>
            <img src={Logo} alt="Loggi" height="36" />
          </Box>
          <Box marginTop={2} marginBottom={1}>
            <Typography variant="h4">
              <Box fontWeight="fontWeightBold">Pra começar</Box>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Selecione sua base:
            </Typography>
          </Box>
        </Box>
        <Box overflow="auto" height={1}>
          {renderState === RENDER_STATE.LOADING && (
            <Box data-testid="dc-loading">
              <SkeletonGroup />
              <SkeletonGroup />
              <SkeletonGroup />
            </Box>
          )}
          {renderState === RENDER_STATE.DEFAULT && (
            <Box height={1} display="flex" flexDirection="column">
              {distributionCenterList.length > 10 && (
                <SearchInput searchDCs={setSearchTerm} />
              )}
              <Box overflow="auto" flex={1}>
                <DistributionCenterList
                  selectDC={selectDC}
                  selectedId={selectedId}
                  distributionCenterList={shownList}
                />
              </Box>
              <Box paddingX={3} paddingY={1} minHeight={64}>
                {selectedId && (
                  <Button
                    data-testid="dc-confirmation"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={proceed}
                  >
                    Confirmar base
                  </Button>
                )}
              </Box>
            </Box>
          )}
          {renderState === RENDER_STATE.ERROR && (
            <Box
              height={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              paddingX={3}
            >
              <Box marginTop={6} align="center">
                <ErrorImage />
                <Typography variant="subtitle1" align="center">
                  <Box marginTop={2} fontWeight="fontWeightBold">
                    Eita, algo deu errado. Espere uns minutinhos e tente de
                    novo.
                  </Box>
                </Typography>
              </Box>
              <Box flex={1} mt={2} />
              <Button
                data-testid="try-again"
                onClick={reloadList}
                fullWidth
                variant="contained"
                color="primary"
              >
                Tentar Novamente
              </Button>
              <Box mt={2} />
              <Button
                data-testid="dc-logout"
                onClick={leaveScreen}
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Sair
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ChooseDistributionCenterView.defaultProps = {
  selectedId: null
};

ChooseDistributionCenterView.propTypes = {
  selectedId: PropTypes.number,
  renderState: PropTypes.string.isRequired,
  reloadList: PropTypes.func.isRequired,
  leaveScreen: PropTypes.func.isRequired,
  selectDC: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired,
  distributionCenterList: PropTypes.arrayOf(dcShape).isRequired
};

/**
 * This Page loads the available DCs and prompts the user
 * to select one DC.
 *
 * It is prepared for a failure in the fetch
 * showing a feedback and a way out.
 *
 * It also detects pre-selected DC and redirects users
 * to the app.
 */
export const ChooseDistributionCenter = () => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const {
    state: {
      allDistributionCenters,
      loadingDistributionCenter,
      hasError,
      selectedDistributionCenter
    },
    fetchDC,
    setSelectedDC
  } = useDistributionCenter();

  const history = useHistory();

  const [renderState, setRenderState] = useState(RENDER_STATE.LOADING);

  // When finish loading DCs select the only one or show the list
  useEffect(() => {
    if (loadingDistributionCenter) return;

    if (allDistributionCenters.length > 0) {
      setRenderState(RENDER_STATE.DEFAULT);
    }
  }, [loadingDistributionCenter, allDistributionCenters]);

  useEffect(() => {
    if (hasError) setRenderState(RENDER_STATE.ERROR);
  }, [hasError]);

  /**
   * This should be executed only once
   */
  useEffect(() => {
    if (selectedDistributionCenter) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChooseDistributionCenterView
      selectedId={selectedDistributionCenter?.distributionCenterId}
      renderState={renderState}
      distributionCenterList={allDistributionCenters}
      reloadList={() => {
        setRenderState(RENDER_STATE.LOADING);
        fetchDC({ userEmail: authenticatedUser.email });
      }}
      leaveScreen={() => {
        history.push('/logout');
      }}
      selectDC={dc => setSelectedDC(dc, authenticatedUser.email)}
      proceed={() => history.push('/')}
    />
  );
};
