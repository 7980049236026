import { colors, typography as typographyPrinciples } from '../principles';
import { pxToRem } from '../utils';

const MuiSnackbarContent = {
  root: {
    backgroundColor: colors.root[0],
    minWidth: pxToRem(320),
    maxWidth: pxToRem(450),
    fontSize: typographyPrinciples.body2.fontSize,
    borderRadius: pxToRem(20),
    padding: pxToRem(20)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: colors.smoke[900]
  }
};

export default MuiSnackbarContent;
