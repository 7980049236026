import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent, Box, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';
import variantIcon from './snackbar-constants';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    right: '0',
    left: '0',
    maxWidth: '550px'
  }
}));

const SnackbarMessage = forwardRef((props, ref) => {
  const {
    id,
    open,
    message,
    variant,
    showCloseButton,
    closeOnClickAnywhereOnScreen
  } = props;
  const { closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const Icon = variantIcon[variant];
  const onClose = closeOnClickAnywhereOnScreen ? () => closeSnackbar(id) : null;

  return (
    <Snackbar className={classes.root} ref={ref} open={open} onClose={onClose}>
      <SnackbarContent
        message={
          <Box display="flex" alignItems="center">
            <Icon />
            <Box ml={2} maxWidth="220px">
              {message}
            </Box>
          </Box>
        }
        action={[
          showCloseButton && (
            <IconButton
              aria-label="close"
              variant="outlined"
              key="close"
              color="primary"
              size="small"
              onClick={() => {
                closeSnackbar(id);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        ]}
      />
    </Snackbar>
  );
});

SnackbarMessage.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
  showCloseButton: PropTypes.bool,
  // The default behavior from Snackbar is to dissmiss/close if the user makes
  // any interaction anywhere on the screen. This boolean, when false, should disable this
  // behavior and only close the snackbar when the user press/click the CloseIcon button
  closeOnClickAnywhereOnScreen: PropTypes.bool
};

SnackbarMessage.defaultProps = {
  variant: 'error',
  showCloseButton: true,
  closeOnClickAnywhereOnScreen: true
};

export default SnackbarMessage;
