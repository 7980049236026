/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  Typography,
  Box,
  Button,
  Container,
  Divider,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Alert, Skeleton } from '@material-ui/lab';
import { yellow } from '@material-ui/core/colors';

import { colors } from '@loggi/mar';
import { playErrorBeep, playSuccessBeep } from '../sounds';
import {
  BarcodeReader,
  PackageReader
} from '../app/components/barcode-readers';
import SortingContextReaderRest from '../app/components/sorting-context-reader';
import useInterval from '../app/hooks/use-interval';
import { countFullUnits, aggregateSortingDecisions } from '../sorting-utils';
import {
  getDestinationStatusPplt,
  movePackage as movePackageRest,
  getSortingContext as getSortingContextRest,
  sortUnitLoad
} from '../api-rest';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  RESPONSE_STATUS,
  SWITCHES,
  SURVEY
} from '../constants';
import { ReactComponent as PPTLIcon } from '../assets/images/pptl-context.svg';
import { useDistributionCenter } from '../app/access-control/distribution-center-provider';
import handleRestAPIError from '../app/utils/rest-api-request';
import {
  setStorageValueWithTtl,
  getStorageValueWithTtl
} from '../app/utils/storage-value-with-ttl';
import { FeatureSwitchContext } from '../firebase/feature-switch-provider';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';
import { useFeature } from '../app/hooks/use-feature';
import Confirmation from '../app/components/confirmation';
import SortSurvey from '../app/components/sort-survey';

moment.updateLocale('pt-BR');

const WarningButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[700],
    '&:hover': {
      backgroundColor: yellow[900]
    }
  }
}))(Button);

const DecisionName = withStyles(() => ({
  root: {
    fontSize: '8rem',
    fontWeight: 'bold',
    lineHeight: '0.8'
  }
}))(Typography);

const AlternativeDecisionName = withStyles(() => ({
  root: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 'x-large',
    textAlign: 'right',
    fontFamily: 'Open Sans'
  }
}))(Typography);

const AlternativeRoutingCode = withStyles(() => ({
  root: {
    textAlign: 'center',
    fontSize: 'smaller',
    lineHeight: '0',
    paddingLeft: '50px'
  }
}))(Typography);

export function Sorting() {
  const pptlSupport = [
    'PPTL_SUPPORT_TYPE_INVALID',
    'PPTL_SUPPORT_TYPE_DISABLED',
    'PPTL_SUPPORT_TYPE_PCDATA_ENABLED'
  ];
  const location = useLocation();
  const history = useHistory();
  const [sortingContext, setSortingContext] = React.useState(
    location.state?.sortingContext || {}
  );
  const { enableRedirectByOperationType } = React.useContext(
    FeatureSwitchContext
  );
  const enableDisableFetchDecisions = useFeature(
    SWITCHES.enableDisableFetchDecisions
  );
  const enableSortSurvey = useFeature(SWITCHES.enableSortSurvey);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [surveyState, setSurveyState] = useState(SURVEY.STATES.CONFIRMATION);

  const alreadySawSurvey =
    localStorage.getItem(SURVEY.STORAGE_STATES.SUBMITTED) ||
    getStorageValueWithTtl(SURVEY.STORAGE_STATES.CLOSED);

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const shouldOpenSurvey = sortingContext?.licensePlate?.includes('SEP');

  const isPackageReaderDisabled =
    surveyState !== SURVEY.STATES.CLOSE &&
    !alreadySawSurvey &&
    enableSortSurvey &&
    shouldOpenSurvey;

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const getUpdatedSortingContext = async licensePlate => {
    if (!licensePlate) {
      return;
    }

    const response = await getSortingContextRest(licensePlate).catch(
      () => undefined
    );

    if (!response) return;

    const updatedSortingContext = response.data?.sortingContext;

    if (updatedSortingContext) {
      setSortingContext({
        ...updatedSortingContext,
        pptlSupport:
          typeof updatedSortingContext?.pptlSupport === 'number'
            ? pptlSupport[(updatedSortingContext?.pptlSupport)]
            : updatedSortingContext?.pptlSupport
      });
    }
  };

  useInterval(async () => {
    if (surveyState === SURVEY.STATES.SURVEY) {
      return;
    }

    await getUpdatedSortingContext(sortingContext?.licensePlate);
  }, 15000);

  const handleSubmitSurvey = () => {
    localStorage.setItem(SURVEY.STORAGE_STATES.SUBMITTED, 'true');
    setSurveyState(SURVEY.STATES.CLOSE);
    getUpdatedSortingContext(sortingContext?.licensePlate);
  };

  const handleCloseSurvey = () => {
    setStorageValueWithTtl(
      SURVEY.STORAGE_STATES.CLOSED,
      'true',
      SURVEY.CASH_TIME
    );
    setSurveyState(SURVEY.STATES.CLOSE);
    getUpdatedSortingContext(sortingContext?.licensePlate);
  };

  const isContextDefined = sortingContext?.licensePlate;
  const hasDecisions = sortingContext?.decisions?.length;

  // List decisions aggregated by unit load and sorted by name.
  const aggregatedDecisions = hasDecisions
    ? aggregateSortingDecisions(sortingContext.decisions)
    : [];

  // Count the number of unit loads that are almost full.
  const fullUnits = hasDecisions ? countFullUnits(aggregatedDecisions) : 0;

  const handleRead = async sorting => {
    if (
      enableRedirectByOperationType === 'true' &&
      sorting?.metadata?.operationType === 'OPERATION_TYPE_RECEIVE'
    ) {
      history.replace('/receive/context-reader');
      return;
    }

    setSortingContext({
      ...sorting,
      pptlSupport:
        typeof sorting?.pptlSupport === 'number'
          ? pptlSupport[(sorting?.pptlSupport)]
          : sorting?.pptlSupport
    });
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.SORT, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_CONTEXT);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.SORT, ACTIVITY.FULL_PROCESS);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">Separar</Box>
      </Typography>
      <Box className="centered" my={1}>
        {isContextDefined ? (
          <Box>
            <Typography variant="h6" align="center">
              {sortingContext.name}
            </Typography>
            <Box mb={2}>
              <Box mb={2} display="flex" justifyContent="center">
                <Typography>
                  Unidades cheias: <b data-testid="full-units">{fullUnits}</b>
                </Typography>
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <Link
                  to={{
                    pathname: '/packing',
                    state: {
                      sortingContext
                    }
                  }}
                >
                  {fullUnits ? (
                    <WarningButton
                      variant="contained"
                      data-testid="pack-warning"
                    >
                      Empacotar
                    </WarningButton>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      data-testid="pack"
                    >
                      Empacotar
                    </Button>
                  )}
                </Link>
              </Box>
            </Box>
            <Divider />
            <SortPackage
              sortingContext={sortingContext}
              distributionCenterId={
                selectedDistributionCenter?.distributionCenterId
              }
              isPackageReaderDisabled={isPackageReaderDisabled}
            />
            {enableSortSurvey && !alreadySawSurvey && shouldOpenSurvey && (
              <Confirmation
                open={surveyState === SURVEY.STATES.CONFIRMATION}
                titleText={SURVEY.LABELS.TITLE}
                subtitleText={SURVEY.LABELS.SUBTITLE}
                onConfirm={() => {
                  setSurveyState(SURVEY.STATES.SURVEY);
                }}
                confirmText={SURVEY.LABELS.CONFIRM}
                onCancel={handleCloseSurvey}
                cancelText={SURVEY.LABELS.CANCEL}
              />
            )}
            {surveyState === SURVEY.STATES.SURVEY && (
              <SortSurvey
                routingCode={selectedDistributionCenter?.routingCode}
                email={authenticatedUser.email}
                closeSurvey={handleCloseSurvey}
                submitSurvey={handleSubmitSurvey}
              />
            )}
          </Box>
        ) : (
          <Box>
            <SortingContextReaderRest
              onRead={handleRead}
              disableFetchDecisions={enableDisableFetchDecisions}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
}

const hasSiblingContexts = sortingContext =>
  sortingContext.siblingsSorterLicensePlate &&
  sortingContext.siblingsSorterLicensePlate.length > 0;

export function DecisionBox({ decision, fontSize, loading, recommendation }) {
  return (
    <Box>
      <DecisionName align="center" style={{ color: decision.color, fontSize }}>
        {decision.name}
      </DecisionName>
      <Box align="center" mt={3}>
        {!loading ? (
          <Typography
            variant="h4"
            style={{ color: recommendation.color, fontWeight: 'bold' }}
          >
            {recommendation.name}
          </Typography>
        ) : (
          <Skeleton height={40} />
        )}
      </Box>
      {decision?.destination && (
        <>
          {(decision.ulEc?.routingCode && (
            <Box align="center">
              <Typography variant="caption" style={{ color: '#cccccc' }}>
                (UnitLoad)
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: '40px', fontWeight: 'bold' }}
              >
                {decision.ulEc?.routingCode}
              </Typography>
            </Box>
          )) ||
            (decision?.destination?.expeditionCenter?.routingCode && (
              <Typography
                align="center"
                style={{ fontSize: '40px', fontWeight: 'bold' }}
              >
                {decision?.destination?.expeditionCenter?.routingCode}
              </Typography>
            ))}
          {(decision.ulEc?.name && (
            <Box align="center">
              <Typography variant="caption">{decision.ulEc?.name}</Typography>
            </Box>
          )) ||
            (decision?.destination?.expeditionCenter?.name && (
              <Box align="center">
                <Typography variant="caption">
                  {decision?.destination?.expeditionCenter?.name}
                </Typography>
              </Box>
            ))}
          {decision?.demandRegionLabel && (
            <Box align="center">
              <Typography variant="h6">
                {`${decision.demandRegionLabel}`}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
DecisionBox.propTypes = {
  decision: PropTypes.shape({
    name: PropTypes.string,
    ulEc: PropTypes.shape({
      routingCode: PropTypes.string,
      name: PropTypes.string
    }),
    color: PropTypes.string,
    demandRegionLabel: PropTypes.string,
    destination: PropTypes.shape({
      expeditionCenter: PropTypes.shape({
        routingCode: PropTypes.string,
        name: PropTypes.string
      })
    })
  }).isRequired,
  fontSize: PropTypes.string,
  loading: PropTypes.bool,
  recommendation: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string
  }).isRequired
};
DecisionBox.defaultProps = {
  fontSize: '5rem',
  loading: false
};

export function SortPackage({
  sortingContext,
  distributionCenterId,
  isPackageReaderDisabled
}) {
  const [error, setError] = React.useState('');
  const [packageBarcode, setPackageBarcode] = React.useState('');
  const [sortingDecision, setSortingDecision] = React.useState({});
  const [sortSuccessMessage, setSortSuccessMessage] = React.useState('');
  const [sortWarningMessage, setSortWarningMessage] = React.useState('');
  const [confirmPosition, setConfirmPosition] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [issues, setIssues] = React.useState([]);
  const [decisions, setDecisions] = React.useState([]);
  const [returnAddress, setReturnAddress] = React.useState(null);
  const [returnInfo, setReturnInfo] = React.useState(null);
  const [promisedDate, setPromisedDate] = React.useState(null);
  const [
    destinationsLicensePlate,
    setDestinationsLicensePlate
  ] = React.useState([]);
  const [delay, setDelay] = React.useState(1000);
  const [longPooling, setLongPooling] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [loadingRecommendation, setLoadingRecommendation] = React.useState(
    false
  );
  const enableSortingRecommendation = useFeature(
    SWITCHES.enableSortingRecommendation
  );
  const enablePromisedDateOnSorting = useFeature(
    SWITCHES.enablePromisedDateOnSorting
  );
  const [recommendation, setRecommendation] = React.useState({});
  const sortingContextRecommendationLicensePlate =
    sortingContext?.metadata?.sortingContextRecommendationLicensePlate;

  const contextText = sortingContext.name
    ? `${sortingContext.licensePlate}, ${sortingContext.name}`
    : `${sortingContext.licensePlate}`;

  const baseErrorHandler = message => {
    setError(message);
  };

  const clearInformations = (success = true) => {
    if (success) {
      setSortSuccessMessage('Sucesso!');
    }
    setRecommendation({});
    setPackageBarcode('');
    setSortingDecision({});
    setError('');
    setConfirmPosition(false);
    setDecisions([]);
    setDestinationsLicensePlate([]);
    setLongPooling(false);
    setSortWarningMessage('');
  };

  useInterval(
    async () => {
      const unitLoadStatus = await getDestinationStatusPplt(packageBarcode);
      const { status, intervalMs } = unitLoadStatus.data;
      switch (status) {
        case 'PPTL_STATUS_CACHE_RESPONSE_SORTED':
          setDelay(intervalMs);
          break;
        case 'PPTL_STATUS_CACHE_RESPONSE_CONFIRMED':
          clearInformations();
          break;
        default:
          clearInformations(false);
          setError('Erro PPTL, favor bipar o pacote novamente');
      }
    },
    longPooling ? delay : null
  );

  const configureDecisions = responsePayloads => {
    const configuredDecisions = [];
    const configuredDestinationLicensePlate = [];
    responsePayloads.forEach(responsePayload => {
      const sortPackageResponse = responsePayload.data;
      const configuredDecision = sortPackageResponse.decision || {
        color: '#ff0000',
        name: 'REJ'
      };
      if (sortPackageResponse.ulDestination) {
        Object.assign(configuredDecision, {
          ulEc: sortPackageResponse.ulDestination
        });
      }
      if (configuredDecision.destination) {
        const {
          licensePlate: configuredLicensePlate
        } = configuredDecision.destination;
        configuredDestinationLicensePlate.push(configuredLicensePlate);
      }
      configuredDecisions.push(configuredDecision);
    });
    return { configuredDecisions, configuredDestinationLicensePlate };
  };

  const handleRead = async readBarcode => {
    if (isLoading) {
      return;
    }

    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_BEEP);

    setPackageBarcode('');
    setRecommendation({});
    setIsLoading(true);
    setDestinationsLicensePlate([]);
    setDecisions([]);
    setSortingDecision({});
    setError('');
    setIssues([]);
    setSortSuccessMessage('');

    const sortingActions = [
      sortUnitLoad(sortingContext.licensePlate, readBarcode)
    ];
    if (hasSiblingContexts(sortingContext)) {
      sortingContext.siblingsSorterLicensePlate.forEach(sortingLicensePlate =>
        sortingActions.push(sortUnitLoad(sortingLicensePlate, readBarcode))
      );
    }

    const responses = await Promise.all(sortingActions).catch(err => {
      handleRestAPIError(err, baseErrorHandler);
      playErrorBeep();
      setIsLoading(false);
    });

    const response = responses && responses.length > 0 ? responses[0] : null;
    if (!response) {
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_BEEP);
      return;
    }

    const responsePayload = responses[0];
    setIsLoading(false);

    if (!responsePayload?.data) {
      playErrorBeep();
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_BEEP);
      return;
    }

    const sortPackageResponse = responsePayload.data;

    const {
      configuredDecisions,
      configuredDestinationLicensePlate
    } = configureDecisions(responses);

    setDecisions(configuredDecisions);
    if (configuredDestinationLicensePlate) {
      setDestinationsLicensePlate(configuredDestinationLicensePlate);
    }

    // Get the list of issues of the package
    setIssues(sortPackageResponse.issues || []);
    playSuccessBeep();
    setSortingDecision(configuredDecisions[0]);
    setPackageBarcode(readBarcode);
    setReturnAddress(sortPackageResponse?.returnAddress);
    setReturnInfo(sortPackageResponse?.returnInfo);
    setPromisedDate(sortPackageResponse?.promisedDate);

    // For multiple sorting, it's expected that all contexts should have the same configuration, so we consider the first one as reference.
    if (configuredDecisions[0].confirmationType === 'UNIT_LOAD_LICENSE_PLATE') {
      setConfirmPosition(true);
    }

    if (sortingContext.pptlSupport === 'PPTL_SUPPORT_TYPE_PCDATA_ENABLED') {
      setConfirmPosition(true);
      setLongPooling(true);
    }

    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_BEEP);
  };

  useEffect(() => {
    if (
      enableSortingRecommendation &&
      sortingContextRecommendationLicensePlate &&
      packageBarcode
    ) {
      setLoadingRecommendation(true);
      sortUnitLoad(sortingContextRecommendationLicensePlate, packageBarcode)
        .then(response => {
          setLoadingRecommendation(false);
          const decisionName = response.data.decision?.name || '';
          const decisionColor =
            response.data.decision?.color || colors.root[900];
          setRecommendation({ name: decisionName, color: decisionColor });
        })
        .catch(err => {
          handleRestAPIError(err, () => setLoadingRecommendation(false));
        });
    }
  }, [
    enableSortingRecommendation,
    packageBarcode,
    sortingContextRecommendationLicensePlate
  ]);

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_CONTEXT);
    trackStart(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_PACKAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableReturnInfoInsteadOfReturnAddress = useFeature(
    SWITCHES.enableReturnInfoInsteadOfReturnAddress
  );

  return (
    <Box>
      {!confirmPosition && (
        <Box my={1.5}>
          <PackageReader
            submitBarcode={!isLoading}
            onRead={handleRead}
            loading={isLoading}
            notes={`Pacote bipado usando o contexto de separação ${contextText}`}
            placeholder="Leia o pacote"
            disable={isPackageReaderDisabled}
          />
          {sortSuccessMessage && (
            <Box mx={1} mt={1.5}>
              <Alert severity="success">{sortSuccessMessage}</Alert>
            </Box>
          )}
          {error && (
            <Box mx={1} mt={1.5}>
              <Alert mt={0.1} severity="error">
                {error}
              </Alert>
            </Box>
          )}
        </Box>
      )}
      {confirmPosition && (
        <ConfirmSortPackage
          packageBarcode={packageBarcode}
          confirmationCallback={clearInformations}
          sortingContext={sortingContext}
          destinationsLicensePlate={destinationsLicensePlate}
          firstDecision={sortingDecision}
          distributionCenterId={distributionCenterId}
        />
      )}
      {sortingContext.pptlSupport === 'PPTL_SUPPORT_TYPE_PCDATA_ENABLED' && (
        <Grid container>
          <Grid item xs={2}>
            <PPTLIcon
              style={{
                marginLeft: 24
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography
              style={{
                color: '#00BAFF',
                fontWeight: 'normal',
                fontStyle: 'normal'
              }}
            >
              Conectado ao botão luminoso
            </Typography>
          </Grid>
        </Grid>
      )}
      {sortWarningMessage && (
        <Box mx={1} my={1.5}>
          <Alert severity="warning">{sortWarningMessage}</Alert>
        </Box>
      )}
      <Box>
        {sortingDecision.name && (
          <Box mx={1} my={1.5}>
            <Alert severity="info">{`Unidade ${packageBarcode} selecionada`}</Alert>
          </Box>
        )}
        <DecisionBox
          decision={sortingDecision}
          recommendation={recommendation}
          loading={loadingRecommendation}
        />
        {enablePromisedDateOnSorting && promisedDate && (
          <Box align="center">
            <Typography component={Box} variant="h6">
              <Box data-testid="sorting-promised-date">
                {`entregar até ${moment(promisedDate).format('DD MMM')}`}
              </Box>
            </Typography>
          </Box>
        )}
        {!enableReturnInfoInsteadOfReturnAddress && returnAddress && (
          <Box align="center">
            <Typography variant="caption">
              {returnAddress?.aliasName}
            </Typography>
          </Box>
        )}
        {enableReturnInfoInsteadOfReturnAddress && returnInfo && (
          <Box align="center">
            <Typography variant="caption">{returnInfo?.aliasName}</Typography>
          </Box>
        )}
        {issues && issues.length > 0 && (
          <Box mx={5} mt={1.5}>
            <Card>
              <CardContent>
                <Typography variant="h5">Problemas</Typography>
                <ul>
                  {issues.map(issue => (
                    <li key={issue}>
                      <Typography variant="caption">{issue}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>

      {decisions && decisions.length > 1 && (
        <Box mt={3}>
          <Divider />
          {decisions.slice(1).map(decision => {
            return (
              <Box mt={1.5} key={decision.name}>
                <Grid container style={{ lineHeight: 0.4 }}>
                  <Grid item xs={8}>
                    <Typography>Posição alternativa</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <AlternativeDecisionName
                      style={{
                        color: decision.color
                      }}
                    >
                      {decision.name}
                    </AlternativeDecisionName>
                    <br />
                    {(decision.ulEc?.routingCode && (
                      <AlternativeRoutingCode>
                        {decision.ulEc?.routingCode}
                      </AlternativeRoutingCode>
                    )) ||
                      (decision?.destination?.expeditionCenter?.routingCode && (
                        <AlternativeRoutingCode>
                          {decision?.destination?.expeditionCenter?.routingCode}
                        </AlternativeRoutingCode>
                      ))}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

SortPackage.propTypes = {
  sortingContext: PropTypes.shape({
    licensePlate: PropTypes.string.isRequired,
    name: PropTypes.string,
    pptlSupport: PropTypes.string,
    siblingsSorterLicensePlate: PropTypes.array
  }).isRequired,
  distributionCenterId: PropTypes.number.isRequired
};

export function ConfirmSortPackage({
  packageBarcode,
  confirmationCallback,
  sortingContext,
  destinationsLicensePlate,
  firstDecision,
  distributionCenterId
}) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const handleRead = async unitLoadLpn => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.SORT_CONFIRMATION_BEEP
    );

    const firstDestinationLpn = firstDecision.destination
      ? firstDecision.destination.licensePlate
      : '';
    const sortingDecisionLpn = destinationsLicensePlate.includes(unitLoadLpn)
      ? unitLoadLpn
      : firstDestinationLpn;

    const sortingContextLpn = sortingContext?.licensePlate || '';

    const sideEffectParams = {
      distributionCenterId
    };
    const moveRecordParams = {
      sortingPredicateId: firstDecision.predicate,
      sortingDecisionUnitloadId: firstDecision?.destination?.id
    };
    setLoading(true);

    const response = await movePackageRest(
      unitLoadLpn,
      packageBarcode,
      sortingDecisionLpn,
      sortingContextLpn,
      sideEffectParams,
      moveRecordParams
    ).catch(err => handleRestAPIError(err, errorMsg => setError(errorMsg)));

    setLoading(false);

    if (!response) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.SORT_CONFIRMATION_BEEP
      );
      return;
    }

    if (response.data.status !== RESPONSE_STATUS.OK || response.data.errorMsg) {
      setError(response.data.errorMsg);
      playErrorBeep();
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.SORT_CONFIRMATION_BEEP
      );
      return;
    }

    playSuccessBeep();
    await confirmationCallback(true);

    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_CONFIRMATION_BEEP);
  };

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_DESTINATION);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_DESTINATION);

      // After the unmount of the beep destination screen, we are redirected
      // to the beep package screen, that's why we trackStart it here too.
      trackStart(OPERATIONAL_PROCESS.SORT, ACTIVITY.BEEP_PACKAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Divider />
      <Box my={1.5}>
        <BarcodeReader
          onRead={handleRead}
          loading={loading}
          placeholder="Leia a posição"
        />
        <Container maxWidth="xs">
          <Box mx={5} mt={1.5}>
            {error && <Alert severity="error">{error}</Alert>}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

ConfirmSortPackage.propTypes = {
  packageBarcode: PropTypes.string.isRequired,
  confirmationCallback: PropTypes.func.isRequired,
  sortingContext: PropTypes.shape({
    licensePlate: PropTypes.string
  }),
  firstDecision: PropTypes.shape({
    destination: PropTypes.shape({
      licensePlate: PropTypes.string
    })
  }),
  destinationsLicensePlate: PropTypes.arrayOf(PropTypes.string),
  distributionCenterId: PropTypes.number.isRequired
};

ConfirmSortPackage.defaultProps = {
  sortingContext: {
    licensePlate: ''
  },
  firstDecision: {
    destination: {
      licensePlate: ''
    }
  },
  destinationsLicensePlate: []
};
