import { HTTP_STATUS_CODES, INTERNAL_SERVER_ERROR_MSG } from '../../constants';
import handleRestAPIError from './rest-api-request';

const getErrorMessage = (errorMessageDict, error) => {
  const errorType = error.response?.data?.type;
  return errorMessageDict[errorType];
};

const handleRestAPIErrorWithMessage = (
  errorMessageDict,
  error,
  messageCallback
) => {
  if (error.response?.status >= HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR) {
    messageCallback(INTERNAL_SERVER_ERROR_MSG);
  } else {
    const message = getErrorMessage(errorMessageDict, error);

    if (message) {
      messageCallback(message);
    } else {
      handleRestAPIError(error, messageCallback);
    }
  }
};

export default handleRestAPIErrorWithMessage;
