import React from 'react';
import {
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { colors } from '@loggi/mar';
import { withStyles } from '@material-ui/core/styles';

export const dcShape = PropTypes.shape({
  distributionCenterId: PropTypes.string,
  distributionCenterName: PropTypes.string,
  routingCode: PropTypes.string,
  location: PropTypes.string,
  lastMileCompanyType: PropTypes.string
});

const ListItemTextStyled = withStyles(() => ({
  primary: {
    fontWeight: 600
  }
}))(ListItemText);

/**
 * This Component renders a list of Distribution Centers
 * with the possibility to click each item and also highlighting
 * the selected one.
 */
export const DistributionCenterList = ({
  selectDC,
  selectedId,
  distributionCenterList
}) => {
  return (
    <List>
      {distributionCenterList.map(dc => (
        <ListItem
          data-testid="dc-list-item"
          onClick={() => selectDC(dc)}
          key={dc.distributionCenterId}
          button
          selected={selectedId === dc.distributionCenterId}
        >
          <ListItemAvatar>
            <Chip label={dc.routingCode} variant="outlined" />
          </ListItemAvatar>
          <ListItemTextStyled primary={dc.distributionCenterName} />
          {selectedId === dc.distributionCenterId && (
            <ListItemSecondaryAction>
              <CheckCircle style={{ color: colors.green[500] }} />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

DistributionCenterList.defaultProps = {
  selectedId: null,
  selectDC: () => {}
};

DistributionCenterList.propTypes = {
  selectedId: PropTypes.number,
  selectDC: PropTypes.func,
  distributionCenterList: PropTypes.arrayOf(dcShape).isRequired
};
