import { authenticationLocales } from '@loggi/authentication';

const validatePasswordRecovery = {
  ...authenticationLocales.ptBr.validatePasswordRecovery,
  headerContent: {
    title: 'Código enviado',
    subTitle: 'Digite o código de confirmação que enviamos para seu e-mail.'
  }
};

export default validatePasswordRecovery;
