export const STORAGE_RECENT_CONTEXTS_KEY = 'recentSortingContexts';

const getContextsNotExpired = () => {
  const contextsStr = localStorage.getItem(STORAGE_RECENT_CONTEXTS_KEY);

  if (!contextsStr) return {};

  const now = new Date();
  const contexts = JSON.parse(contextsStr);

  const contextsNotExpired = {};
  Object.keys(contexts).forEach(licensePlate => {
    const context = contexts[licensePlate];

    if (now.getTime() <= context.ttl) {
      contextsNotExpired[licensePlate] = context;
    }
  });

  return contextsNotExpired;
};

/**
 * Set all given contexts on the storage, if a context is already on
 * it, it will update its value.
 *
 * @param {Array} contexts new values to be added on the storage
 * @param {number} ttl the expiry time in Ms
 */
export const setContextsOnStorageWithTtl = (contexts, ttl) => {
  const now = new Date();
  const valuesWithTtl = getContextsNotExpired();

  contexts.forEach(value => {
    valuesWithTtl[value.licensePlate] = {
      value,
      ttl: now.getTime() + ttl
    };
  });

  localStorage.setItem(
    STORAGE_RECENT_CONTEXTS_KEY,
    JSON.stringify(valuesWithTtl)
  );
};

/**
 * Get all contexts that aren't expired
 *
 * @returns {Array} all the items that aren't expired
 */
export const getContextsOnStorageWithTtl = () => {
  const valuesNotExpired = getContextsNotExpired();

  localStorage.setItem(
    STORAGE_RECENT_CONTEXTS_KEY,
    JSON.stringify(valuesNotExpired)
  );

  return Object.values(valuesNotExpired).map(item => item.value);
};

export default {
  setContextsOnStorageWithTtl,
  getContextsOnStorageWithTtl,
  STORAGE_RECENT_CONTEXTS_KEY
};
