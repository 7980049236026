/* eslint-disable import/prefer-default-export */
import { useMemo, useContext } from 'react';
import camelCase from 'lodash/camelCase';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';
import { useDistributionCenter } from '../access-control/distribution-center-provider';

/**
 * This hook will fetch our remote config provider and
 * return a boolean indicating if this feature is on for this device.
 *
 *  @param {string} featureSwitchKey Remote config key that identifies a feature.
 *  @returns {boolean} Indicating if the feature is on or off.
 */
const useFeature = featureSwitchKey => {
  const remoteConfigDict = useContext(FeatureSwitchContext);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const distributionCenterId = selectedDistributionCenter?.distributionCenterId;

  return useMemo(() => {
    const configValue = remoteConfigDict[camelCase(featureSwitchKey)] || '';
    const normalizedValue = configValue.replace(/\s|\[|\]/g, '');

    if (!normalizedValue) return false;
    if (['all', 'true'].includes(normalizedValue.toLowerCase())) return true;

    return normalizedValue.split(',').includes(String(distributionCenterId));
  }, [featureSwitchKey, remoteConfigDict, distributionCenterId]);
};

/**
 * This hook will fetch our remote config provider and
 * return the value configured.
 *
 *  @param {string} featureSwitchKey Remote config key that identifies a feature.
 *  @returns {value} The feature value.
 */
const useFeatureValue = featureSwitchKey => {
  const remoteConfigDict = useContext(FeatureSwitchContext);

  return useMemo(() => {
    return remoteConfigDict[camelCase(featureSwitchKey)] || '';
  }, [featureSwitchKey, remoteConfigDict]);
};

export { useFeature, useFeatureValue };
