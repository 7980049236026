/* eslint-disable max-classes-per-file */

export class LoggiLabelNotFoundError extends Error {
  constructor(message, label, pkg) {
    super(message);
    this._label = label;
    this._pkg = pkg;
  }

  get label() {
    return this._label;
  }

  get pkg() {
    return this._pkg;
  }
}

export class CompanyDontAllowRedispatchError extends Error {
  constructor(message, pkg) {
    super(message);
    this._pkg = pkg;
  }

  get pkg() {
    return this._pkg;
  }
}
