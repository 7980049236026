import { authenticationLocales } from '@loggi/authentication';

const recoveryPassowordForm = {
  ...authenticationLocales.ptBr.recoveryPasswordForm,
  headerContent: {
    title: 'Recupere sua senha',
    subTitle:
      'Digite o seu e-mail abaixo, vamos enviar um código de confirmação para você.'
  }
};

export default recoveryPassowordForm;
