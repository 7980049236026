import React from 'react';
import {
  Box,
  makeStyles,
  useTheme,
  IconButton,
  InputBase
} from '@material-ui/core';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(() => ({
  inputText: {
    color: colors.smoke[700]
  }
}));

const SearchTransferBar = ({ setSearchTerm }) => {
  const classes = useStyles();
  const theme = useTheme();

  const setSearchTermDebounced = debounce(async inputValue => {
    await setSearchTerm(inputValue);
  }, 600);

  const handleChange = React.useCallback(event => {
    setSearchTermDebounced(event.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      border={1}
      borderColor={colors.smoke[600]}
      borderRadius={8}
      alignItems="center"
      pl={1}
      pr={3}
      pt={0.46}
      pb={0.46}
      mt={3}
      bgcolor={theme.palette.common.white}
      data-testid="search-transfer-bar"
    >
      <Box display="flex" pl={1} align-items="stretch" flexGrow={1}>
        <InputBase
          fullWidth
          data-testid="filter-receiver-transfer-input"
          inputProps={{
            className: classes.inputText,
            'aria-label': 'search-transfers'
          }}
          onChange={handleChange}
          color="primary"
          placeholder="Buscar base"
        />
      </Box>
      <IconButton edge="end">
        <SearchIcon color="primary" />
      </IconButton>
    </Box>
  );
};

SearchTransferBar.propTypes = {
  setSearchTerm: PropTypes.func.isRequired
};

export default SearchTransferBar;
