import { useAmplifyAuth } from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ScreenHeader } from '../../components';
import { VALIDATE_PASSWORD_RECOVERY } from '../../routes/constants';
import { errorHandler, showNotificationMessage } from '../../service';
import {
  EMAIL_INPUT_BUTTON_ID,
  LOADING_ID,
  PASSWORD_RECOVERY_BUTTON_ID,
  PASSWORD_RECOVERY_ERRORS
} from './constants';
import useForgotPasswordInStyle from './styles';

const RecoveryPasswordForm = () => {
  const { push } = useHistory();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useForgotPasswordInStyle();
  const { t } = useTranslation(
    state?.translationNamespace || 'recoveryPasswordForm'
  );
  const [loading, setLoading] = useState(false);
  const {
    forgotPassword,
    state: {
      error: stateError,
      passwordRecovery: { hasSentForgotPassword }
    }
  } = useAmplifyAuth();
  const [userEmail, setUserEmail] = useState(state?.email || '');

  const EmailRecoverySchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailInvalid'))
  });

  const initialValues = {
    email: userEmail
  };

  const _onSubmit = values => {
    setLoading(true);
    const { email } = values;
    forgotPassword(email);
    setUserEmail(email);
  };

  useEffect(() => {
    if (stateError) {
      setLoading(false);
      setUserEmail('');

      const errorCode = stateError.message?.code;

      const isKnownError = Boolean(
        PASSWORD_RECOVERY_ERRORS.includes(errorCode)
      );

      if (isKnownError) {
        showNotificationMessage(
          t(`errorsMessages.${errorCode}`),
          'error',
          enqueueSnackbar
        );
      } else {
        showNotificationMessage(
          t('errorsMessages.default'),
          'error',
          enqueueSnackbar
        );
        errorHandler(stateError);
      }
    }
  }, [stateError, enqueueSnackbar, t]);

  useEffect(() => {
    if (hasSentForgotPassword && userEmail) {
      showNotificationMessage(t('successMessage'), 'success', enqueueSnackbar);
      push(VALIDATE_PASSWORD_RECOVERY, userEmail);
    }
  }, [enqueueSnackbar, push, hasSentForgotPassword, t, userEmail]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <ScreenHeader
          title={t('headerContent.title')}
          subTitle={t('headerContent.subTitle')}
        />
      </OneTemplateSummary>
      <OneTemplateContent>
        <Formik
          initialValues={initialValues}
          validationSchema={EmailRecoverySchema}
          onSubmit={_onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched
          }) => (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Grid
                container
                spacing={2}
                direction="column"
                justify="space-around"
                alignItems="center"
              >
                <TextField
                  error={Boolean(errors.email) && touched.email}
                  helperText={touched.email ? errors.email : ''}
                  id={EMAIL_INPUT_BUTTON_ID}
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="email"
                  fullWidth
                  label={t('emailInput.label')}
                  variant="outlined"
                  value={values.email}
                />

                <Box pt={2} width="100%">
                  <Button
                    color="primary"
                    data-testid={PASSWORD_RECOVERY_BUTTON_ID}
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    {!loading && t('button.text')}
                    {loading && (
                      <CircularProgress
                        data-testid={LOADING_ID}
                        className={styles.buttonLoading}
                        size={26}
                      />
                    )}
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default RecoveryPasswordForm;
