import { authenticationLocales } from '@loggi/authentication';
import { componentsLocales } from '@loggi/components';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import xdappLocales from '../locales';

/**
 * i18next configuration
 */
i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      ...componentsLocales['pt-BR'],
      ...authenticationLocales.ptBr,
      ...xdappLocales.ptBr
    }
  },
  lng: 'pt-BR',
  fallbackLng: 'pt-BR'
});

export default i18n;
