import React, { useContext } from 'react';
import { colors } from '@loggi/mar';
import moment from 'moment';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import HeaderWithClose from '../../app/components/header-with-close';
import { ReactComponent as IconDistribution } from '../../assets/images/icone_distribuido.svg';
import { ROUTES, SWITCHES, DISTRIBUTE_TO } from '../../constants';
import pluralize from '../../app/utils/pluralize';
import { DistributeProcessContext } from './distribute-process-context';
import { useFeature } from '../../app/hooks/use-feature';

const NewDistributionScreen = () => {
  const history = useHistory();
  const distributedIn = moment(new Date()).format('DD MMM[,] HH:mm');
  const enablePartnerDistributeContextReader = useFeature(
    SWITCHES.enablePartnerDistributeContextReader
  );
  const partnerDistributionPath = enablePartnerDistributeContextReader
    ? ROUTES.DISTRIBUTE_PARTNER_CONTEXT_READER
    : ROUTES.DISTRIBUTE_PARTNER;
  const {
    distributeType,
    totalCargosBeeped,
    vehicleLicensePlate,
    clearData
  } = useContext(DistributeProcessContext);

  const goToHome = () => {
    history.push({
      pathname: ROUTES.HOME
    });
  };

  let pathName = ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION;

  if (distributeType === DISTRIBUTE_TO.PARTNER) {
    pathName = partnerDistributionPath;
  }

  const newDistribution = () => {
    clearData();
    history.push({
      pathname: pathName
    });
  };

  return (
    <Box height="100vh" bgcolor={colors.root[0]}>
      <Container maxWidth="xs" style={{ height: '100%' }}>
        <Box pt={1.5}>
          <HeaderWithClose handleClose={goToHome} testId="close-distribution" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
        >
          <Box width="100%" mt={3} textAlign="center">
            <Typography variant="h6">Você distribuiu</Typography>
            <Typography variant="h6">
              <Box fontWeight="700">
                {`${pluralize({
                  singular: `${totalCargosBeeped} unidade`,
                  count: totalCargosBeeped
                })}`}
              </Box>
            </Typography>

            <Typography variant="body1">
              <Box mt={3}>para {vehicleLicensePlate}</Box>
            </Typography>
          </Box>
          <Box mt={6} width="100%" textAlign="center">
            <IconDistribution style={{ width: 150, height: 95 }} />
            <Typography variant="subtitle1">
              <Box mt={4}>realizada em {distributedIn}</Box>
            </Typography>
          </Box>
          <ActionContainerBottom>
            <Container maxWidth="xs">
              <Box pb={3.125}>
                <Button
                  fullWidth
                  data-testid="new-distribution"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={newDistribution}
                >
                  Nova distribuição
                </Button>
              </Box>
            </Container>
          </ActionContainerBottom>
        </Box>
      </Container>
    </Box>
  );
};

export default NewDistributionScreen;
