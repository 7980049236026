import React, { useState, useContext, useEffect } from 'react';

import {
  Box,
  Container,
  List,
  Button,
  Divider,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { colors } from '@loggi/mar';
import { useSnackbar } from 'notistack';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import HeaderWithReturn from '../../../app/components/header-with-return';
import Confirmation from '../../../app/components/confirmation';
import pluralize from '../../../app/utils/pluralize';
import ActionContainerBottom from '../../../app/components/actions-container-bottom';
import { getTripCargo, removeCargo } from '../../../api-rest';

import { CargoInfoWithCheckbox } from './cargo-info';
import { TripProcessContext } from './trip-process-context';

import {
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  REMOVE_CARGO_ERROR_MSGS,
  TRIP_PAGE_STATES,
  SWITCHES,
  HTTP_STATUS_CODES
} from '../../../constants';
import { useFeature } from '../../../app/hooks/use-feature';
import handleRestAPIErrorWithMessage from '../../../app/utils/handle-rest-api-error-with-message';

export default function EditCargoList() {
  const { setPageState, cargos, setCargos, trip, selectedDC } = useContext(
    TripProcessContext
  );

  const { enqueueSnackbar } = useSnackbar();
  const [cargosToRemove, setCargosToRemove] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const showTransferCargoWeight = useFeature(SWITCHES.showTransferCargoWeight);

  const showTransferCargoMerchandiseValue = useFeature(
    SWITCHES.showTransferCargoMerchandiseValue
  );

  const getCargos = async () => {
    setLoading(true);
    const response = await getTripCargo({
      tripId: trip.id,
      sourceRoutingCode: selectedDC?.routingCode
    }).catch(error => {
      handleRestAPIErrorWithMessage(
        { BASE_TRIP_TRANSFER_ERROR_MSGS },
        error,
        message => showSnackbarAlert(message, 'error')
      );
    });

    if (response?.status === HTTP_STATUS_CODES.OK && response.data?.cargo) {
      const validCargo = response.data.cargo?.filter(c => c && !c.started);
      setCargos(validCargo);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCargos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseErrorHandler = message => {
    showSnackbarAlert(message, 'error');
    setLoading(false);
  };

  const goBack = () => {
    setPageState(TRIP_PAGE_STATES.REVIEW_CARGO_START_TANSFER);
  };

  const handleRemove = () => {
    setOpenConfirmation(true);
  };

  const handleConfirmRemove = async () => {
    setLoading(true);
    setOpenConfirmation(false);

    try {
      await removeCargo({
        tripId: trip.id,
        sourceRoutingCode: selectedDC?.routingCode,
        licensePlates: cargosToRemove
      });

      showSnackbarAlert('Pronto, tudo certo.\n Sua lista foi atualizada.');
      goBack();
      setLoading(false);
    } catch (error) {
      handleRestAPIErrorWithMessage(
        { ...BASE_TRIP_TRANSFER_ERROR_MSGS, ...REMOVE_CARGO_ERROR_MSGS },
        error,
        baseErrorHandler
      );
    }
  };

  const handleCancelRemove = () => {
    setOpenConfirmation(false);
  };

  const isCargoSelected = selectedLicensePlate =>
    cargosToRemove.some(cargo => cargo === selectedLicensePlate);

  const handleSelectCargo = (selectedLicensePlate, hasStarted) => {
    if (hasStarted) return;

    setIsCheckedSelectAll(false);

    if (isCargoSelected(selectedLicensePlate)) {
      setCargosToRemove(
        cargosToRemove.filter(cargo => cargo !== selectedLicensePlate)
      );

      return;
    }

    setCargosToRemove([...cargosToRemove, selectedLicensePlate]);
  };

  const handleSelectAllChange = () => {
    setCargosToRemove([]);

    if (isCheckedSelectAll) {
      setIsCheckedSelectAll(false);
      return;
    }

    setCargosToRemove(cargos.map(cargo => cargo.licensePlate));
    setIsCheckedSelectAll(true);
  };

  const getTitleText = () => {
    const packageText = pluralize({
      singular: 'pacote',
      count: cargosToRemove.length
    });
    const selectedText = pluralize({
      singular: 'selecionado',
      count: cargosToRemove.length
    });
    return `Você quer remover <b>${
      cargosToRemove.length
    } ${packageText} ${selectedText}</b> da sua lista?`;
  };

  const destinationDescription = (destinationRoutingCode, destinationName) => {
    return `${destinationRoutingCode} ${destinationName}`;
  };

  const SkeletonList = () => {
    return (
      <Box marginTop={3}>
        <Skeleton
          component={Box}
          height={40}
          width="50%"
          ml={3}
          pb={5}
          paddingX={3}
        />

        <Skeleton component={Box} width="60%" ml={3} paddingX={3} />

        <Skeleton component={Box} width="80%" ml={3} paddingX={3} />
        <Box marginTop={3}>
          <Divider component={Box} />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box height="100vh" overflow="auto">
        <Container maxWidth="xs" data-testid="edit-cargo-list" disableGutters>
          <Box pt={15} pb={12}>
            {!loading ? (
              <>
                <Divider />
                <List>
                  {cargos.map(item => (
                    <CargoInfoWithCheckbox
                      cargoCompany={item.sourceCompanyName}
                      cargoDestination={destinationDescription(
                        item.destinationRoutingCode,
                        item.destinationName
                      )}
                      cargoFinalDestination={destinationDescription(
                        item.finalDestinationRoutingCode,
                        item.finalDestinationName
                      )}
                      showTransshipmentInfo={
                        item.destinationRoutingCode !==
                        item.finalDestinationRoutingCode
                      }
                      cargoLicensePlate={item.licensePlate}
                      showPackageCount
                      packageCount={item.packageCount}
                      isCargoSelected={isCargoSelected}
                      handleSelectCargo={handleSelectCargo}
                      cargoTotalWeightInGrams={item.totalGrossWeightInGrams}
                      showCargoWeight={showTransferCargoWeight}
                      cargoMerchandiseValue={item.totalValue}
                      showCargoMerchandiseValue={
                        showTransferCargoMerchandiseValue
                      }
                    />
                  ))}
                </List>
              </>
            ) : (
              <Box data-testid="cargo-loading">
                {cargos.map(() => (
                  <SkeletonList />
                ))}
              </Box>
            )}
            {openConfirmation && (
              <Confirmation
                open
                subtitleText={getTitleText()}
                onConfirm={handleConfirmRemove}
                confirmText="Confirmar"
                onCancel={handleCancelRemove}
                cancelText="Manter na lista"
                zIndex={2}
              />
            )}
          </Box>
        </Container>
      </Box>

      <Container maxWidth="xs" disableGutters>
        <HeaderWithReturn
          title=""
          onReturn={goBack}
          bgcolor={colors.root[0]}
          isDisabled={loading}
        />
        <Box position="fixed" top={6} bgcolor={colors.root[0]} width={1}>
          <Box display="flex" alignItems="center" mt={8} mb={2}>
            <Box mr={1} pl={4.5}>
              <FormControlLabel
                value="select-all"
                control={
                  <Box mr={1}>
                    <Checkbox
                      edge="start"
                      inputProps={{ 'data-testid': 'select-all-checkbox' }}
                      color="primary"
                      checked={isCheckedSelectAll}
                      onChange={handleSelectAllChange}
                    />
                  </Box>
                }
                label={
                  isCheckedSelectAll
                    ? 'Todos selecionados'
                    : `${cargosToRemove.length} ${pluralize({
                        singular: 'selecionado',
                        count: cargosToRemove.length
                      })}`
                }
                labelPlacement="select-all"
              />
            </Box>
          </Box>
          <Divider />
        </Box>
      </Container>

      <ActionContainerBottom>
        <Container maxWidth="xs">
          <Box pb={3} flex="initial">
            <Box display="flex">
              <Box flex={1} ml={1}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleRemove}
                  disabled={cargosToRemove <= 0 || loading}
                >
                  Remover da lista
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </ActionContainerBottom>
    </>
  );
}
