import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import {
  Box,
  Container,
  Divider,
  Typography,
  Chip,
  Button,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as LocalShippingIcon } from '../../assets/images/local_shipping.svg';
import { ReactComponent as EmptySearch } from '../../assets/images/empty-search.svg';
import {
  ROUTES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  ADD_CARGO_ERROR_MSGS,
  InvalidCargoDestination,
  DistributionUnitLoadAlreadyStarted
} from '../../constants';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import HeaderWithReturn from '../../app/components/header-with-return';
import { BarcodeReader } from '../../app/components/barcode-readers';
import FloatingContainer from '../../app/components/floating-container';
import InvalidDestinationDialog from '../../app/components/invalid-destination-dialog';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';

import handleRestAPIErrorWithMessage from '../../app/utils/handle-rest-api-error-with-message';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { DistributeProcessContext } from './distribute-process-context';
import {
  distributionAddCargo,
  getDistributionUnitLoadCargo
} from '../../api-rest';
import pluralize from '../../app/utils/pluralize';
import SkeletonList from '../../app/components/skeleton-list';

const AddCargoScreen = () => {
  const {
    vehicleLicensePlate,
    distributionUnitLoadId,
    lastCargo,
    setLastCargo,
    totalCargosBeeped,
    setTotalCargosBeeped,
    destinationList,
    clearData
  } = useContext(DistributeProcessContext);

  const [
    openInvalidDestinationDialog,
    setOpenInvalidDestinationDialog
  ] = useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const [overrideToken, setOverrideToken] = useState('');
  const [licensePlateToForce, setLicensePlateToForce] = useState('');
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const goBack = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE.SELECT_POSSIBLE_DESTINATIONS
    });
  };

  const [errorMessage, setErrorMessage] = useState('');

  const baseErrorHandler = message => {
    playErrorBeep();
    setErrorMessage(message);
  };

  const goViewListCargoPage = async () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE.REVIEW_CARGO
    });
  };

  const updateLastCargo = async cargo => {
    setTotalCargosBeeped(totalCargosBeeped + 1);
    setLastCargo({
      destinationRoutingCode: cargo.destinationRoutingCode,
      destinationName: cargo.destinationName,
      packageCount: cargo.packageCount,
      licensePlate: cargo.licensePlate,
      sourceCompanyName: cargo.sourceCompanyName,
      transferId: cargo.transferId,
      started: cargo.started
    });
  };

  const distributionUnitLoadAlreadyStartedError = async error => {
    history.push({
      pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
    });
    clearData();
    showSnackbar({
      variant: 'warning',
      message: BASE_TRIP_TRANSFER_ERROR_MSGS[error.response.data.type],
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleRead = async licensePlate => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTION_BIPAGE_UNIT_LOAD
    );

    if (destinationList.length === 1) {
      setSelectedDestination(destinationList[0]);
    }

    const errorMessages = [];
    setErrorMessage('');
    try {
      const response = await distributionAddCargo({
        distributionUnitLoadId,
        licensePlate,
        routingCode: selectedDistributionCenter.routingCode
      });

      playSuccessBeep();

      updateLastCargo(response?.data?.cargo);
    } catch (error) {
      if (error.response?.data?.type === DistributionUnitLoadAlreadyStarted) {
        distributionUnitLoadAlreadyStartedError(error);
        return;
      }

      if (error.response?.data?.type === InvalidCargoDestination) {
        setOpenInvalidDestinationDialog(true);
        setLicensePlateToForce(licensePlate);
        setOverrideToken(error.response?.data?.overrideToken);
        return;
      }

      handleRestAPIErrorWithMessage(
        { ...BASE_TRIP_TRANSFER_ERROR_MSGS, ...ADD_CARGO_ERROR_MSGS },
        error,
        message => {
          errorMessages.push(message);
        }
      );

      if (errorMessages.length) {
        baseErrorHandler(errorMessages.join('\n'));
        return;
      }
    }

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTION_BIPAGE_UNIT_LOAD
    );
  };

  const handleForceAddCargo = async (destination = null) => {
    try {
      const response = await distributionAddCargo({
        distributionUnitLoadId,
        licensePlate: licensePlateToForce,
        routingCode: selectedDistributionCenter.routingCode,
        overrideToken,
        destinationRoutingCode: destination
          ? destination.destinationRoutingCode
          : selectedDestination.destinationRoutingCode
      });

      playSuccessBeep();

      updateLastCargo(response.data.cargo);
    } catch (error) {
      const errorMessages = [];

      handleRestAPIErrorWithMessage(
        { ...BASE_TRIP_TRANSFER_ERROR_MSGS, ...ADD_CARGO_ERROR_MSGS },
        error,
        message => {
          errorMessages.push(message);
        }
      );

      baseErrorHandler(errorMessages.join('\n'));
    }

    setOpenInvalidDestinationDialog(false);
    setLicensePlateToForce('');
    setOverrideToken('');
  };

  const getForceCargoDialogTitle = () => {
    if (destinationList.length > 1) {
      return `Opa, a unidade ${licensePlateToForce} não é para os destinos dessa distribuição.`;
    }

    return `Opa, a unidade ${licensePlateToForce} não é para o destino dessa distribuição. Tem certeza que quer adicionar na lista?`;
  };

  const getCargos = async () => {
    try {
      setLoading(true);
      const response = await getDistributionUnitLoadCargo(
        distributionUnitLoadId
      );

      const validCargo = response.data.cargos.filter(c => c && !c.started);
      setTotalCargosBeeped(validCargo.length);

      const lastValidCargo = validCargo.pop();
      setLastCargo(lastValidCargo);
      setLoading(false);
    } catch (error) {
      setTotalCargosBeeped(0);
      setLastCargo(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      justifyContent="space-between"
      height="100vh"
    >
      <HeaderWithReturn title="Distribuir" onReturn={goBack} />
      <Container maxWidth="xs">
        <Box
          pt={10}
          mb={3}
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <BarcodeReader
            onRead={handleRead}
            placeholder="Bipe o código da unidade"
          />
        </Box>
        <Chip
          style={{
            backgroundColor: colors.smoke[100],
            color: colors.root[900]
          }}
          icon={<LocalShippingIcon />}
          size="medium"
          variant="outlined"
          label={vehicleLicensePlate}
        />
      </Container>
      <Container maxWidth="xs" disableGutters>
        <Box
          data-testid="total-cargos-beeped"
          display="flex"
          flexDirection="row"
          alignItems="baseline"
          ml={3}
        >
          <Typography style={{ fontSize: pxToRem(86) }}>
            {totalCargosBeeped}
          </Typography>
          {totalCargosBeeped > 0 && (
            <Typography variant="subtitle2">un.</Typography>
          )}
        </Box>
        <FloatingContainer>
          {lastCargo ? ( // eslint-disable-line no-nested-ternary
            <>
              <InfoCargo lastCargo={lastCargo} />
              <Box mt={2} flex="initial" px={3}>
                <Box display="flex">
                  <Box flex={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={goViewListCargoPage}
                    >
                      Conferir lista
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : !loading ? (
            <Box alignItems="center" align="center" justifyContent="center">
              <EmptySearch />
              <Typography variant="subtitle2" align="center">
                <Box fontWeight="fontWeightMedium" mt={3} mb={3}>
                  Bipe o código das unidades para <br /> adicionar na lista.
                </Box>
              </Typography>
            </Box>
          ) : (
            <Box data-testid="distribute-cargo-loading">
              {[...Array(2)].map(el => (
                <SkeletonList key={el} />
              ))}
            </Box>
          )}

          {errorMessage && (
            <Box>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
        </FloatingContainer>
      </Container>
      {openInvalidDestinationDialog && (
        <InvalidDestinationDialog
          onCancel={() => {
            setOpenInvalidDestinationDialog(false);
          }}
          onConfirm={() => {
            handleForceAddCargo();
          }}
          hideConfirmButton={destinationList.length > 1}
          title={getForceCargoDialogTitle()}
        >
          <Box display="flex" flexDirection="column">
            {destinationList.length > 1 && (
              <Box mb={2}>
                <Typography
                  gutterBottom
                  component="div"
                  style={{ fontWeight: 600 }}
                  variant="subtitle2"
                  data-testid="cargo-info-license-plate"
                >
                  Selecione um destino para a unidade
                </Typography>
              </Box>
            )}
            <List>
              {destinationList.length > 1 &&
                destinationList.map(destination => {
                  return (
                    destination.isSelected && (
                      <>
                        <DestinationList
                          key={destination.destinationRoutingCode}
                          destinationItem={destination}
                          handleForceAddCargo={handleForceAddCargo}
                        />
                        <Divider light />
                      </>
                    )
                  );
                })}
            </List>
          </Box>
        </InvalidDestinationDialog>
      )}
    </Box>
  );
};

const InfoCargo = ({ lastCargo }) => {
  return (
    <>
      <Box mb={2}>
        <Typography
          gutterBottom
          component="div"
          style={{ fontWeight: 600 }}
          variant="subtitle2"
          data-testid="cargo-info-license-plate"
        >
          {lastCargo.licensePlate}
        </Typography>
      </Box>
      <Box mb={1}>
        {lastCargo.sourceCompanyName && (
          <Typography
            component="div"
            variant="subtitle2"
            color="textSecondary"
            data-testid="cargo-info-company"
          >
            Pacote de {lastCargo.sourceCompanyName}
          </Typography>
        )}
        {lastCargo.packageCount && (
          <Typography
            component="div"
            variant="subtitle2"
            color="textSecondary"
            data-testid="cargo-info-package-count"
          >
            {`com ${lastCargo.packageCount} ${pluralize({
              singular: 'pacote',
              count: lastCargo.packageCount
            })}`}
          </Typography>
        )}
      </Box>
      <Typography
        component="div"
        variant="subtitle2"
        color="textSecondary"
        data-testid="cargo-info-destination"
      >
        para {lastCargo.destinationName}
      </Typography>
    </>
  );
};

InfoCargo.propTypes = {
  lastCargo: PropTypes.shape({
    destinationRoutingCode: PropTypes.string,
    destinationName: PropTypes.string,
    packageCount: PropTypes.number,
    licensePlate: PropTypes.string,
    sourceCompanyName: PropTypes.string,
    transferId: PropTypes.number,
    started: PropTypes.bool
  }).isRequired
};

const DestinationList = props => {
  const { destinationItem, handleForceAddCargo } = props;
  return (
    <Box clone>
      <ListItem
        button
        onClick={() => handleForceAddCargo(destinationItem)}
        disableGutters
        key={destinationItem.destinationRoutingCode}
        data-testid="destination-list-item"
      >
        <ListItemText
          primary={
            <Typography
              gutterBottom
              component="div"
              style={{ fontWeight: 600 }}
              variant="subtitle2"
              data-testid="destination-item-routing-code"
            >
              {destinationItem.destinationRoutingCode}
            </Typography>
          }
          secondary={
            <Typography
              component="div"
              variant="subtitle2"
              color="textSecondary"
              data-testid="destination-item-name"
            >
              {destinationItem.destinationName}
            </Typography>
          }
        />
        <IconButton edge="end">
          <ArrowForwardIosIcon style={{ color: colors.blue[500] }} />
        </IconButton>
      </ListItem>
    </Box>
  );
};

DestinationList.propTypes = {
  destinationItem: PropTypes.shape({
    destinationRoutingCode: PropTypes.string,
    destinationName: PropTypes.string,
    isSelected: PropTypes.bool
  }).isRequired,
  handleForceAddCargo: PropTypes.func.isRequired
};

export default AddCargoScreen;
