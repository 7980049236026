import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { colors } from '@loggi/mar';
import { Alert } from '@material-ui/lab';

import {
  Box,
  Button,
  Container,
  Divider,
  List,
  Typography
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import UnitReader from './unit-reader';
import { OrganizeProcessContext } from './organize-process-context';
import DestinationUnitLoadDialog, {
  DESTINATION_UNIT_LOAD_TYPE
} from './destination-unit-load-dialog';
import {
  COGNITO_DISTRIBUTION_CENTER,
  HISTORY_ACTIONS,
  ROUTES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  SWITCHES
} from '../../constants';
import HeaderWithReturn from '../../app/components/header-with-return';
import { ReactComponent as ShelfIcon } from '../../assets/images/shelf.svg';
import PaperBox from '../../app/components/paper-box';
import { ReactComponent as EmptySearch } from '../../assets/images/empty-search.svg';
import pluralize from '../../app/utils/pluralize';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import ActionContainerTop from '../../app/components/actions-container-top';
import SharedPropTypes from '../../app/shared-prop-types';
import { UNIT_LOAD_TYPE, UNIT_TYPE } from '../../app/enums';
import {
  playActionRequiredBeep,
  playErrorBeep,
  playSuccessBeep
} from '../../sounds';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { movePackagesBulk, unitize, sortUnitLoad } from '../../api-rest';
import handleRestAPIError from '../../app/utils/rest-api-request';
import childrenWithoutOutliers from '../../app/utils/children-without-outliers';
import getUserType from '../../app/access-control/access-control-service';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { SortingContextSelect } from './sorting-context-select';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { getUnit } from './unit-handlers';
import { useFeature, useFeatureValue } from '../../app/hooks/use-feature';
import DialogAlertComponent from '../../app/components/alert-component-dialog';

export const MESSAGES = {
  ORGANIZE_UNIT_LOAD_GROUP_SUCCESS: 'Boa! Grupo criado.',
  ORGANIZE_UNIT_LOAD_MOVE_SUCCESS: 'Boa! Pacotes armazenados.',
  ORGANIZE_DENSITY_EXCEEDED:
    'A distância entre paradas está muito alta, ' +
    'retire pacotes que estão longe do grupo.'
};

export const BUTTONS = {
  GROUP_LABEL: 'Agrupar',
  MOVE_LABEL: 'Mover'
};

export default function OrganizeUnitLoad() {
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { sortingContext, unitInfo, setUnitInfo } = useContext(
    OrganizeProcessContext
  );
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const enableChangeBulkMoveToRedirectToDest = useFeature(
    SWITCHES.enableChangeBulkMoveToRedirectToDest
  );
  const enableShowOutlierDetailsInOrganize = useFeature(
    SWITCHES.enableShowOutlierDetailsInOrganize
  );
  const densityValue = JSON.parse(useFeatureValue(SWITCHES.densityValue));
  const enableDensityAnalysis = sortingContext?.licensePlate?.includes('SEP');
  const [loading, setLoading] = useState(false);
  const [openDestinationUnitLoad, setOpenDestinationUnitLoad] = useState(false);
  const [showUnitReader, setShowUnitReader] = useState(true);
  const [openDensityUnitLoadAlert, setOpenDensityUnitLoadAlert] = useState(
    false
  );
  const [destinationLicensePlate, setDestinationLicensePlate] = useState(null);
  const [destinationUlTypeState, setDestinationUlType] = useState(null);

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;
  const isButtonHidden =
    loading ||
    !unitInfo?.childrenUnitLoad?.length ||
    (!unitInfo?.unitLoad?.transhipmentRoutingCodeDestination && isUserLeve);

  useEffect(() => {
    // prevent page from being accessed by editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      history.replace(ROUTES.HOME);
    }
  }, [history]);

  useEffect(() => {
    if (unitInfo.unitLoad?.createdAt) {
      setShowUnitReader(false);
    } else {
      setShowUnitReader(true);
    }
  }, [unitInfo]);

  const showSnackbarAlert = (message, variant = 'success') =>
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });

  const addChildToUnitInfo = child =>
    setUnitInfo({
      childrenUnitLoad: [
        {
          type: UNIT_LOAD_TYPE.UNIT_LOAD_TYPE_PACKAGE,
          childUnitLoad: child.pkg
        },
        ...unitInfo.childrenUnitLoad
      ],
      unitLoad: unitInfo.unitLoad
    });

  const updateUnitLoadOfUnitInfo = unitLoad =>
    setUnitInfo({
      childrenUnitLoad: unitInfo.childrenUnitLoad,
      unitLoad: {
        name: unitLoad.name,
        licensePlate: unitLoad.license_plate,
        created: unitLoad.created
      }
    });
  const handleRead = ({ info }) => {
    addChildToUnitInfo(info);
    playSuccessBeep();
  };

  const handleError = (message, beepType = 'error') => {
    if (beepType === 'warning') {
      showSnackbarAlert(message, 'warning');
      playActionRequiredBeep();
      return;
    }
    showSnackbarAlert(message, 'error');
    playErrorBeep();
  };

  const handleUnitize = async licensePlateDestination => {
    if (
      enableDensityAnalysis &&
      densityValue[(selectedDistributionCenter?.routingCode)] &&
      unitInfo?.unitLoad?.density >=
        densityValue[(selectedDistributionCenter?.routingCode)].block
    ) {
      throw new Error(MESSAGES.ORGANIZE_DENSITY_EXCEEDED);
    }
    const response = await unitize(
      unitInfo.unitLoad.licensePlate,
      licensePlateDestination,
      sortingContext.licensePlate
    );
    return response.data;
  };

  const getSuccessMessage = licensePlateDestination =>
    licensePlateDestination
      ? MESSAGES.ORGANIZE_UNIT_LOAD_MOVE_SUCCESS
      : MESSAGES.ORGANIZE_UNIT_LOAD_GROUP_SUCCESS;

  const handleUnitizeProcess = async licensePlateDestination => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ORGANIZE_UNITIZE_BEEP
    );
    setLoading(true);
    try {
      const unitizeInfo = await handleUnitize(licensePlateDestination);
      updateUnitLoadOfUnitInfo(unitizeInfo.unitLoad);
      showSnackbarAlert(getSuccessMessage(licensePlateDestination));
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.ORGANIZE_UNITIZE_BEEP
      );
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
      history.push({ pathname: ROUTES.ORGANIZE.SUMMARY });
    } catch (err) {
      handleRestAPIError(err, errorMsg => {
        showSnackbarAlert(errorMsg, 'error');
      });
    }
    setLoading(false);
  };

  const handleMoveBulkProcess = async licensePlateDestination => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ORGANIZE_MOVE_BULK_BEEP
    );
    setLoading(true);

    const ident = enableChangeBulkMoveToRedirectToDest
      ? licensePlateDestination
      : unitInfo.unitLoad.licensePlate;

    const extra = {
      identifier: ident
    };

    try {
      const packageIds = unitInfo.childrenUnitLoad
        .filter(item => item.type === UNIT_LOAD_TYPE.UNIT_LOAD_TYPE_PACKAGE)
        .map(item => item.childUnitLoad.id);

      await movePackagesBulk({
        packageIds,
        destinationUnitLoadLicensePlate: licensePlateDestination,
        sortingContextLpn: sortingContext.licensePlate,
        sideEffectParams: {
          distributionCenterId: selectedDistributionCenter?.distributionCenterId
        }
      });

      const unit = await getUnit({
        type: UNIT_TYPE.UNIT_TYPE_UNIT_LOAD,
        extra
      });

      setUnitInfo(unit.info);
    } catch (err) {
      handleRestAPIError(err, errorMsg => {
        showSnackbarAlert(errorMsg, 'error');
      });
    }

    showSnackbarAlert(getSuccessMessage(licensePlateDestination));
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ORGANIZE_MOVE_BULK_BEEP
    );

    setOpenDestinationUnitLoad(false);
    setLoading(false);
  };

  const handleDestinationUnitLoad = async (
    licensePlateDestination = null,
    destinationUlType = DESTINATION_UNIT_LOAD_TYPE.SEAL
  ) => {
    playSuccessBeep();
    if (destinationUlType === DESTINATION_UNIT_LOAD_TYPE.SEAL) {
      return handleUnitizeProcess(licensePlateDestination);
    }

    return handleMoveBulkProcess(licensePlateDestination);
  };

  const handleReadToConfirm = (licensePlateDestination, destinationUlType) => {
    setDestinationLicensePlate(licensePlateDestination);
    setDestinationUlType(destinationUlType);
  };

  const checkDensityAlert = () => {
    const routingCode = selectedDistributionCenter?.routingCode;
    const densityAlertValue = densityValue[routingCode]?.alert;
    const unitDensity = unitInfo?.unitLoad?.density;
    return (
      enableDensityAnalysis &&
      densityAlertValue &&
      unitDensity &&
      unitDensity >= densityAlertValue
    );
  };

  const controlOpenDensityUnitLoad = (
    licensePlateDestination = null,
    destinationUlType = DESTINATION_UNIT_LOAD_TYPE.SEAL
  ) => {
    const isDensityAlert = checkDensityAlert();
    if (isDensityAlert) {
      setOpenDensityUnitLoadAlert(true);
      handleReadToConfirm(licensePlateDestination, destinationUlType);
    } else {
      setOpenDensityUnitLoadAlert(false);
      handleDestinationUnitLoad(licensePlateDestination, destinationUlType);
    }
  };

  const goBack = () =>
    history.push({ pathname: ROUTES.ORGANIZE.INITIAL_IDENTIFICATION });

  const handleAlertContinue = () => {
    setOpenDensityUnitLoadAlert(false);
    handleDestinationUnitLoad(destinationLicensePlate, destinationUlTypeState);
  };

  const handleAlertCancel = () => {
    setOpenDensityUnitLoadAlert(false);
    setOpenDestinationUnitLoad(false);
  };

  const shouldShowUnitLoadMetrics =
    sortingContext?.licensePlate?.includes('SEP') &&
    unitInfo?.unitLoadsOutlier?.length > 0;

  return (
    <Box bgcolor={colors.root[0]}>
      <Box overflow="hidden" flexDirection="column" display="flex" mt={8}>
        <Container maxWidth="xs">
          <Box>
            {!openDestinationUnitLoad && showUnitReader && (
              <UnitReader
                onSuccessCallback={handleRead}
                onErrorCallback={handleError}
                loading={loading}
                setLoading={setLoading}
                organizeUnitInfo={unitInfo}
                organizeUnitType={UNIT_TYPE.UNIT_TYPE_UNIT_LOAD}
                placeholder="Bipe unidades para adicionar"
              />
            )}
          </Box>
        </Container>
        <Container maxWidth="xs" disableGutters>
          {loading && <Loading />}
          {!loading && (
            <Box pt={4.5} display="flex" flexDirection="column">
              <PaperBox>
                <Box minHeight="75vh">
                  <Container maxWidth="xs">
                    <Box mb={3} mt={5}>
                      <ShelfIcon />
                      <Box mt={3}>
                        <Typography variant="subtitle1">
                          <Box fontWeight="fontWeightBold">
                            {unitInfo?.unitLoad?.licensePlate}
                          </Box>
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {`com ${
                            unitInfo?.childrenUnitLoad?.length
                          } ${pluralize({
                            singular: 'pacote',
                            count: unitInfo?.childrenUnitLoad?.length
                          })}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Container>
                  <Box mb={1}>
                    <Divider light />
                  </Box>
                  {unitInfo?.unitLoad?.density > 0 &&
                    unitInfo?.childrenUnitLoad?.length > 1 && (
                      <Box data-testid="unit-load-density">
                        <Container maxWidth="xs">
                          <Box mb={3} mt={3}>
                            <Typography variant="subtitle2">
                              <Box fontWeight="fontWeightMedium">
                                para ofertar
                              </Box>
                            </Typography>
                            <Typography>
                              <Box mt={0.5}>
                                Distância entre paradas:{' '}
                                <Box
                                  component="span"
                                  fontWeight="fontWeightMedium"
                                >
                                  {unitInfo?.unitLoad?.density}km
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                        </Container>
                        <Box mb={1}>
                          <Divider light />
                        </Box>
                      </Box>
                    )}
                  {shouldShowUnitLoadMetrics && (
                    <Container maxWidth="xs">
                      <Box mt={3} data-testid="alert-unit-load-outlier">
                        <Alert severity="warning">
                          <Box display="flex">
                            <Typography noWrap display="inline">
                              <Box fontWeight="fontWeightBold">
                                {`${
                                  unitInfo?.unitLoadsOutlier?.length
                                } ${pluralize({
                                  singular: 'pacote',
                                  count: unitInfo?.unitLoadsOutlier?.length
                                })} `}
                              </Box>
                            </Typography>
                            <Box>
                              <Typography variant="body1">
                                &nbsp;com entrega longe.
                              </Typography>
                            </Box>
                          </Box>
                        </Alert>
                      </Box>
                    </Container>
                  )}
                  {shouldShowUnitLoadMetrics && (
                    <Box>
                      {enableShowOutlierDetailsInOrganize ? (
                        <UnitLoadOutlierList
                          childrenUnitLoad={unitInfo?.unitLoadsOutlier}
                          id="outlier"
                          setUnitInfo={setUnitInfo}
                          sortingContext={sortingContext}
                        />
                      ) : (
                        <UnitLoadList
                          childrenUnitLoad={unitInfo?.unitLoadsOutlier}
                          id="outlier"
                        />
                      )}
                      <Box mb={1}>
                        <Divider light />
                      </Box>
                    </Box>
                  )}
                  <UnitLoadList
                    childrenUnitLoad={childrenWithoutOutliers({
                      unitInfo
                    })}
                    id="children"
                  />
                </Box>
              </PaperBox>
            </Box>
          )}
        </Container>
      </Box>
      <ActionContainerTop>
        <Container maxWidth="xs">
          <Box mt={3}>
            <HeaderWithReturn title="Organizar" onReturn={goBack} fixed={false}>
              <SortingContextSelect isDisabled />
            </HeaderWithReturn>
          </Box>
        </Container>
      </ActionContainerTop>
      <ActionContainerBottom>
        <Container maxWidth="xs">
          {!isButtonHidden && (
            <Box mb={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                data-testid="organize-unit-load-button"
                onClick={
                  isUserLeve
                    ? () => handleUnitizeProcess()
                    : () => setOpenDestinationUnitLoad(true)
                }
              >
                {isUserLeve ? BUTTONS.GROUP_LABEL : BUTTONS.MOVE_LABEL}
              </Button>
            </Box>
          )}
        </Container>
      </ActionContainerBottom>
      {openDestinationUnitLoad && (
        <DestinationUnitLoadDialog
          open
          onRead={controlOpenDensityUnitLoad}
          onCancel={() => setOpenDestinationUnitLoad(false)}
          loading={loading}
        />
      )}
      {openDensityUnitLoadAlert && (
        <DialogAlertComponent
          title="Opa, existem pacotes que estão longe do grupo. Tem certeza que quer continuar?"
          open
          onContinue={handleAlertContinue}
          onCancel={handleAlertCancel}
        >
          <Alert severity="info">
            <Box display="flex">
              <Typography display="initial">
                Para garantir uma boa rota, você precisa mover os pacotes que
                estão longe.
              </Typography>
            </Box>
          </Alert>
        </DialogAlertComponent>
      )}
    </Box>
  );
}

const UnitLoadList = ({ childrenUnitLoad, id }) => {
  return (
    <>
      {!childrenUnitLoad.length && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="35vh"
          px={2.5}
        >
          <Box>
            <EmptySearch />
          </Box>
          <Box>
            <Typography variant="subtitle2" align="center">
              <Box
                mt={2.5}
                fontWeight="fontWeightMedium"
                data-testid="organize-empty-unit-load"
              >
                Bipe pacotes para armazenar aqui.
              </Box>
            </Typography>
          </Box>
        </Box>
      )}
      {childrenUnitLoad.length > 0 && (
        <Container maxWidth="xs">
          <Box>
            <List data-testid={`packages-list-organize-unit-load-${id}`}>
              {childrenUnitLoad.map(childUnitLoad => (
                <ListItem
                  key={childUnitLoad.childUnitLoad.identifier}
                  disableGutters
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" noWrap>
                        {childUnitLoad.childUnitLoad.identifier}
                      </Typography>
                    }
                    secondary={
                      <Typography color="textSecondary" noWrap>
                        {`Pacote de ${childUnitLoad.childUnitLoad.company
                          .sharedName ||
                          childUnitLoad.childUnitLoad.company.name}`}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      )}
    </>
  );
};

UnitLoadList.defaultProps = {
  childrenUnitLoad: [],
  id: ''
};

UnitLoadList.propTypes = {
  childrenUnitLoad: SharedPropTypes.childrenUnitLoad,
  id: PropTypes.string
};

const UnitLoadOutlierList = ({
  childrenUnitLoad,
  id,
  setUnitInfo,
  sortingContext
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleClick = async ul => {
    setLoading(true);

    const sortUnitLoadPromises = [];

    const sortUnitLoadResponsePromise = sortUnitLoad(
      sortingContext?.licensePlate,
      ul?.childUnitLoad?.identifier
    );

    sortUnitLoadPromises.push(sortUnitLoadResponsePromise);

    if (sortingContext.recommendationLicensePlate) {
      const sortUnitLoadRecommendationResponsePromise = sortUnitLoad(
        sortingContext.recommendationLicensePlate,
        ul?.childUnitLoad?.identifier
      );

      sortUnitLoadPromises.push(sortUnitLoadRecommendationResponsePromise);
    }

    const [
      sortUnitLoadResponse,
      sortUnitLoadRecommendationResponse
    ] = await Promise.all(sortUnitLoadPromises);

    setUnitInfo({
      type: UNIT_TYPE.UNIT_TYPE_PACKAGE,
      decision: sortUnitLoadResponse?.data?.decision,
      pkg: ul.childUnitLoad,
      recommendationDecision: sortUnitLoadRecommendationResponse?.data?.decision
    });

    setLoading(false);
    history.push({ pathname: ROUTES.ORGANIZE.ORGANIZE_PACKAGE });
  };

  return (
    <>
      {childrenUnitLoad.length > 0 && (
        <Container maxWidth="xs">
          {loading && <Loading />}
          {!loading && (
            <Box>
              <List data-testid={`packages-list-organize-unit-load-${id}`}>
                {childrenUnitLoad.map(childUnitLoad => (
                  <ListItem
                    button
                    onClick={() => handleClick(childUnitLoad)}
                    key={childUnitLoad.childUnitLoad.identifier}
                    data-testid="outlier-details-button"
                    disableGutters
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" noWrap>
                          {childUnitLoad.childUnitLoad.identifier}
                        </Typography>
                      }
                      secondary={
                        <Typography color="textSecondary" noWrap>
                          {`Pacote de ${childUnitLoad.childUnitLoad.company
                            .sharedName ||
                            childUnitLoad.childUnitLoad.company.name}`}
                        </Typography>
                      }
                    />
                    <IconButton edge="end">
                      <ArrowForwardIosIcon
                        style={{ color: colors.blue[500] }}
                      />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Container>
      )}
    </>
  );
};

UnitLoadOutlierList.defaultProps = {
  childrenUnitLoad: [],
  id: '',
  setUnitInfo: null,
  sortingContext: {
    licensePlate: ''
  }
};

UnitLoadOutlierList.propTypes = {
  childrenUnitLoad: SharedPropTypes.childrenUnitLoad,
  id: PropTypes.string,
  setUnitInfo: PropTypes.func,
  sortingContext: PropTypes.shape({
    licensePlate: PropTypes.string,
    recommendationLicensePlate: PropTypes.string
  })
};

const Loading = () => {
  return (
    <Container maxWidth="xs">
      <Box mt={4.5} display="flex" flexDirection="column">
        <Box pb={2}>
          <Skeleton variant="rect" height={160} />
        </Box>
        <Box>
          {[...Array(4).keys()].map(index => (
            <Box key={index} mt={-2}>
              <Skeleton variant="text" height={140} />
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};
