import React from 'react';
import { List } from '@material-ui/core';
import PackageItem from './package-item';
import SharedPropTypes from '../shared-prop-types';

export default function PackageList({ packages }) {
  return (
    <List data-testid="package-list">
      {packages.map((item, i) => (
        <PackageItem
          key={item.identifier}
          packageItem={item}
          bottomDivider={packages.length - 1 === i}
        />
      ))}
    </List>
  );
}

PackageList.propTypes = {
  packages: SharedPropTypes.packages.isRequired
};
