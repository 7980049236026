import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/pt-br';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import {
  DECLARE_DAMAGE_LOCATION,
  DECLARE_DAMAGE_CAUSE,
  DECLARE_DAMAGE_PHYSICAL_STATE,
  TEXT,
  TESTID
} from './constants';

export default function PackageDamageDeclaration({
  form,
  setForm,
  goBackToIdentify,
  goToPhotoCapture
}) {
  const handleProcessLocation = e => {
    setForm.processLocation(e.target.value);
  };
  const handleDamageCause = e => {
    setForm.damageCause(e.target.value);
  };
  const handlePhysicalDamage = e => {
    setForm.physicalDamage(e.target.value);
  };

  const isAbleToAdvance =
    form.processLocation && form.damageCause && form.physicalDamage;

  return (
    <>
      <Card>
        <CardContent>
          <Box py={1}>
            <FormControl fullWidth>
              <InputLabel>{TEXT.DECLARATION_LOCATION}</InputLabel>
              <Select
                native
                value={form.processLocation}
                onChange={handleProcessLocation}
                inputProps={{
                  'data-testid': TESTID.DECLARE_DAMAGE_PROCESS_LOCATION
                }}
              >
                {DECLARE_DAMAGE_LOCATION.map(option => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth>
              <InputLabel>{TEXT.DECLARATION_CAUSE}</InputLabel>
              <Select
                native
                value={form.damageCause}
                onChange={handleDamageCause}
                inputProps={{
                  'data-testid': TESTID.DECLARE_DAMAGE_CAUSE
                }}
              >
                {DECLARE_DAMAGE_CAUSE.map(cause => (
                  <option value={cause.value} key={cause.value}>
                    {cause.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box py={1}>
            <FormControl fullWidth>
              <InputLabel>{TEXT.DECLARATION_PHYSICAL_STATUS}</InputLabel>
              <Select
                native
                value={form.physicalDamage}
                onChange={handlePhysicalDamage}
                inputProps={{
                  'data-testid': TESTID.DECLARE_DAMAGE_PHYSICAL_DAMAGE
                }}
              >
                {DECLARE_DAMAGE_PHYSICAL_STATE.map(state => (
                  <option value={state.value} key={state.value}>
                    {state.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box pt={4}>
            <TextField
              fullWidth
              inputProps={{
                'data-testid': TESTID.DECLARE_DAMAGE_TEXT
              }}
              label={TEXT.DECLARATION_OPEN_TEXT}
              multiline
              rows="3"
              variant="outlined"
              onChange={setForm.damageText}
              value={form.damageText}
            />
          </Box>
        </CardContent>
      </Card>
      <Box mt={3} display="flex">
        <Box mr={1} width="100%">
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={goBackToIdentify}
          >
            {TEXT.BACK}
          </Button>
        </Box>
        <Box ml={1} width="100%">
          <Button
            disabled={!isAbleToAdvance}
            fullWidth
            color="primary"
            variant="contained"
            data-testid={TESTID.DECLARE_DAMAGE_ADVANCE_BUTTON}
            onClick={goToPhotoCapture}
          >
            {TEXT.FORWARD}
          </Button>
        </Box>
      </Box>
    </>
  );
}

PackageDamageDeclaration.propTypes = {
  goToPhotoCapture: PropTypes.func.isRequired,
  goBackToIdentify: PropTypes.func.isRequired,
  form: PropTypes.shape({
    processLocation: PropTypes.string.isRequired,
    damageCause: PropTypes.string.isRequired,
    physicalDamage: PropTypes.string.isRequired,
    damageText: PropTypes.string.isRequired
  }).isRequired,
  setForm: PropTypes.shape({
    processLocation: PropTypes.func.isRequired,
    damageCause: PropTypes.func.isRequired,
    physicalDamage: PropTypes.func.isRequired,
    damageText: PropTypes.func.isRequired
  }).isRequired
};

PackageDamageDeclaration.defaultProps = {};
