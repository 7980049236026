import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Chip
} from '@material-ui/core';
import TwoWheelerIcon from '@material-ui/icons/TwoWheeler';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@loggi/mar/src/utils';
import { useSnackbar } from 'notistack';
import { colors } from '@loggi/mar';
import { SERVICE_TYPE, TRANSPORT_TYPES } from '../app/enums';
import { GeolocationContext } from '../geo';
import {
  playErrorBeep,
  playSuccessBeep,
  playActionRequiredBeep
} from '../sounds';
import { PackageReader } from '../app/components/barcode-readers';
import SelectableButton from '../app/components/selectable-button';
import HeaderWithReturn from '../app/components/header-with-return';
import { dispatch, updateVehicleUnitLoadApi } from './dispatch-service';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  ERROR_MESSAGE_UPDATE_VEHICLE_UNIT_LOAD,
  SWITCHES
} from '../constants';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';
import { useFeature } from '../app/hooks/use-feature';
import showSnackbar from '../app/components/snackbar/snackbar-container';

const useStyles = makeStyles({
  button: {
    fontSize: pxToRem(18),
    width: pxToRem(100)
  }
});
const VEHICLE_TYPE_ICON = { Moto: TwoWheelerIcon, Carro: DirectionsCarIcon };
export default function Dispatch({ dispatchService }) {
  const [geo] = React.useContext(GeolocationContext);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [infoMessage, setInfoMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [dynamicDispatcher, setDynamicDispatcher] = React.useState(false);
  const [bagBarcode, setBagBarcode] = React.useState('');
  const [confirmationStatus, setConfirmationStatus] = React.useState('');
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [transportType, setTransportType] = React.useState('');
  const history = useHistory();
  const [serviceType, setServiceType] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isBeep, setIsBeep] = React.useState(false);

  const classes = useStyles();

  const enableUpdateVehicleUnitLoad = useFeature(
    SWITCHES.enableUpdateVehicleUnitLoad
  );

  const enableDispatchFlow = useFeature(SWITCHES.enableDispatchFlow);

  const showSnackbarAlert = (message, variant = 'success') =>
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });

  const clearMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
    setInfoMessage('');
  };

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  useEffect(() => {
    if (enableDispatchFlow) {
      if (successMessage) {
        showSnackbarAlert(successMessage);
      }
      if (errorMessage) {
        showSnackbarAlert(errorMessage, 'error');
      }
    }
    trackStart(OPERATIONAL_PROCESS.DISPATCH, ACTIVITY.FULL_PROCESS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage, errorMessage, enableDispatchFlow]);

  const confirmationStatuses = {
    finished: {
      statusDisplay: 'Finalizada'
    },
    accepted: {
      statusDisplay: 'Aceita'
    },
    started: {
      statusDisplay: 'Iniciada'
    },
    ongoing: {
      statusDisplay: 'Em execução'
    }
  };

  const confirmationStatusDisplay = status =>
    confirmationStatuses[status] || { statusDisplay: status };

  const handleRead = async barcode => {
    setLoading(true);

    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISPATCH_BEEP);

    const isNewNuvem = serviceType === SERVICE_TYPE.PARCEIROS;
    let updateVehicleSuccess;

    if (enableUpdateVehicleUnitLoad || serviceType === SERVICE_TYPE.NUVEM) {
      updateVehicleSuccess = await updateVehicleUnitLoadApi(
        transportType === '' ? TRANSPORT_TYPES.CAR : transportType,
        barcode
      );

      if (!updateVehicleSuccess) {
        setErrorMessage(ERROR_MESSAGE_UPDATE_VEHICLE_UNIT_LOAD);
        playErrorBeep();
        setLoading(false);
      }
    }

    const dispatchPayload = await dispatchService({
      bagSeal: barcode,
      geo,
      dynamicDispatcher,
      confirmationStatus,
      errorHandler: baseErrorHandler,
      isNuvemApi: isNewNuvem
    });

    setLoading(false);

    if (!dispatchPayload) {
      setConfirmationStatus('');
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISPATCH_BEEP);
      return;
    }

    const { orderStatus, success } = dispatchPayload;

    const errorResponseWithOrderStatus = !success && orderStatus;
    const errorResponseWithoutOrderStatus = !success && !orderStatus;

    // block dispatch if the order is accepted, started or finished
    if (errorResponseWithOrderStatus) {
      playActionRequiredBeep();
      setLoading(false);
      setBagBarcode(barcode);
      setConfirmationStatus(orderStatus);
    } else if (errorResponseWithoutOrderStatus) {
      const errMessage = dispatchPayload.errors
        .map(err => err.message)
        .join('\n');
      setErrorMessage(errMessage);
      playErrorBeep();
      setLoading(false);
      setConfirmationStatus('');
    } else {
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
      trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
      setSuccessMessage(`Carga ${barcode} expedida!`);
      playSuccessBeep();
      setLoading(false);
      setConfirmationStatus('');
    }
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISPATCH_BEEP);
    trackEnd(OPERATIONAL_PROCESS.DISPATCH, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.DISPATCH, ACTIVITY.FULL_PROCESS);
  };

  const handleChange = async () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleChangeCheckbox = event => {
    const newDynamicDispatcher = event.target.checked;

    if (newDynamicDispatcher && geo.defaultCoords) {
      setInfoMessage('Não foi possível acessar a localização do dispositivo');
      return;
    }

    setDynamicDispatcher(newDynamicDispatcher);
    setInfoMessage('');
  };

  const handleClickYesButton = () => {
    handleRead(bagBarcode);
  };

  const handleClickNoButton = () => {
    setBagBarcode('');
    setConfirmationStatus('');
  };

  const goBack = () => history.push({ pathname: '/' });

  const selectableButtonGroup = (labelsMap, setState, state) => {
    const buttonTypes = Object.entries(labelsMap);

    return (
      <Box mt={1} display="flex" flexWrap="wrap">
        {buttonTypes.map(([key, label]) => (
          <Box pt={2} pr={2} key={key}>
            <SelectableButton
              dataTestId={`type-${label}-${key}`}
              label={label}
              onClick={() => {
                setInfoMessage('');
                setState(label === state ? '' : label);
              }}
              isSelected={label === state}
            >
              {label}
            </SelectableButton>
          </Box>
        ))}
      </Box>
    );
  };

  const chip = (message, IconComponent) => {
    return (
      <Chip
        label={message}
        icon={
          IconComponent ? (
            <IconComponent style={{ color: colors.smoke[900] }} />
          ) : null
        }
        style={{ backgroundColor: colors.smoke[100], color: colors.smoke[900] }}
        size="small"
      />
    );
  };

  return enableDispatchFlow ? (
    <Box data-testid="dispatch">
      <HeaderWithReturn title="Expedir" onReturn={goBack} />
      {!isBeep && (
        <Container maxWidth="xs">
          <Box
            pt={8}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100vh"
          >
            <Box mt={3}>
              <Typography mb={3} variant="body1">
                Qual tipo de serviço está fazendo?
              </Typography>
              {selectableButtonGroup(SERVICE_TYPE, setServiceType, serviceType)}
              {serviceType === SERVICE_TYPE.NUVEM && (
                <Box pt={4.5} display="flex" flexDirection="column">
                  <Typography variant="body1">
                    A oferta é para qual tipo de veículo?
                  </Typography>
                  {selectableButtonGroup(
                    TRANSPORT_TYPES,
                    setTransportType,
                    transportType
                  )}
                </Box>
              )}
            </Box>
            <Box pt={3} flex={1}>
              <FormControlLabel
                data-testid="my-location-checkbox"
                control={
                  <Checkbox
                    checked={dynamicDispatcher}
                    onChange={handleChangeCheckbox}
                    color="primary"
                  />
                }
                label="Expedir da minha localização"
              />
            </Box>
            {infoMessage && showSnackbarAlert(infoMessage, 'warning')}
            <Box mb={3}>
              <Button
                fullWidth
                data-testid="continue-button"
                color="primary"
                variant="contained"
                size="large"
                disabled={
                  !(
                    serviceType === SERVICE_TYPE.PARCEIROS ||
                    (serviceType === SERVICE_TYPE.NUVEM && transportType)
                  )
                }
                onClick={() => {
                  setIsBeep(true);
                }}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      {isBeep && (
        <Container maxWidth="xs">
          <Box
            pt={8}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100vh"
          >
            <Box pt={3}>
              <PackageReader
                onRead={handleRead}
                onChange={clearMessages}
                loading={loading}
                notes="Expedido pelo XD App"
                source="xd_app_dispatch"
                placeholder="Bipe o lacre"
              />
            </Box>
            <Box pt={1.5} display="flex" flexDirection="row">
              <Box mr={1}>{chip(serviceType, null)}</Box>
              {serviceType === SERVICE_TYPE.NUVEM && (
                <Box>
                  {chip(transportType, VEHICLE_TYPE_ICON[transportType])}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  ) : (
    <Box data-testid="dispatch">
      <HeaderWithReturn title="Expedir" onReturn={goBack} />
      <Container maxWidth="xs">
        <Box
          pt={8}
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          {enableUpdateVehicleUnitLoad && (
            <>
              <Box mt={3}>
                <Typography variant="body1">
                  A oferta é para qual tipo de veículo?
                </Typography>
              </Box>

              <Box pt={2} display="flex" flexDirection="row">
                {Object.keys(TRANSPORT_TYPES).map((type, index) => {
                  const transportValue = TRANSPORT_TYPES[type];
                  const isSelected = transportType === transportValue;
                  return (
                    <Box mr={index % 2 === 0 ? 2 : 0} key={type}>
                      <SelectableButton
                        dataTestId={`tranport-type-${transportValue}`}
                        label={transportValue}
                        onClick={() => setTransportType(transportValue)}
                        isSelected={isSelected}
                      >
                        {transportValue}
                      </SelectableButton>
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </Box>

        <Box pt={4}>
          <PackageReader
            onRead={handleRead}
            onChange={handleChange}
            loading={loading}
            disable={Boolean(confirmationStatus)}
            notes="Expedido pelo XD App"
            source="xd_app_dispatch"
            placeholder="Bipe o lacre"
          />
          <Box>
            <Dialog
              open={Boolean(confirmationStatus)}
              data-testid="confirmation_status_dialog"
            >
              <DialogTitle>
                Deseja realmente expedir <b>{bagBarcode}</b>?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Status da saca:{' '}
                  <b>
                    {
                      confirmationStatusDisplay(confirmationStatus)
                        .statusDisplay
                    }
                  </b>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.button}
                  onClick={handleClickNoButton}
                  color="secondary"
                  data-testid="dispatch_no"
                  autoFocus
                >
                  Não
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleClickYesButton}
                  color="primary"
                  data-testid="dispatch_yes"
                  autoFocus
                >
                  Sim
                </Button>
              </DialogActions>
            </Dialog>
            <Box my={2.5} alignItems="left">
              <FormControlLabel
                data-testid="my-location-checkbox"
                control={
                  <Checkbox
                    checked={dynamicDispatcher}
                    onChange={handleChangeCheckbox}
                    color="primary"
                  />
                }
                label="Expedir da minha localização"
              />
              {successMessage && (
                <Box my={1.5}>
                  <Alert data-testid="success" severity="success">
                    {successMessage}
                  </Alert>
                </Box>
              )}
              {errorMessage && (
                <Box my={1.5}>
                  <Alert data-testid="error" severity="error">
                    {errorMessage}
                  </Alert>
                </Box>
              )}
              {infoMessage && (
                <Box my={1.5}>
                  <Alert data-testid="info" severity="info">
                    {infoMessage}
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

Dispatch.defaultProps = {
  // It becomes easier to test/mock by injecting the service.
  dispatchService: dispatch
};

Dispatch.propTypes = {
  dispatchService: PropTypes.func
};
