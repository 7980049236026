import { makeStyles } from '@material-ui/core/styles';

const useValidatePasswordRecoveryStyle = makeStyles(({ typography }) => ({
  continueBtn: {
    fontSize: typography.pxToRem(14),
    marginTop: typography.pxToRem(15)
  },
  resendCodeBtn: {
    fontSize: typography.pxToRem(14),
    marginTop: typography.pxToRem(10)
  },
  validateMessage: { fontSize: typography.pxToRem(20) },
  loading: {
    color: 'white'
  }
}));

export default useValidatePasswordRecoveryStyle;
