import { pxToRem } from '../utils';
import { colors, typography as typographyPrinciples } from '../principles';

const MuiTooltip = {
  tooltip: {
    display: 'flex',
    backgroundColor: colors.smoke[900],
    fontWeight: typographyPrinciples.body2.fontWeight,
    fontSize: typographyPrinciples.body2.fontSize,
    borderRadius: pxToRem(4),
    minHeight: pxToRem(32),
    alignItems: 'center',
    '& .MuiTooltip-arrow': {
      color: colors.smoke[900]
    }
  }
};

export default MuiTooltip;
