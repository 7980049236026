import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Dialog, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import AsyncButton from '../../app/components/async-button';
import { movePackagesBulk } from '../../api-rest';
import SharedPropTypes from '../../app/shared-prop-types';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import handleRestAPIError from '../../app/utils/rest-api-request';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import IdentifyPerson from '../../app/components/identify-person';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    height: '97vh'
  }
});

export default function RedispatchReceiverConfirmation({
  packages,
  redispatchUnitLoad,
  contextLicensePlate
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const handleCancel = () => {
    history.goBack();
  };

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleCpfChange = event => {
    setCpf(event.target.value);
  };

  const shouldEnableSubmitButton = name && cpf.length === 11;

  const showClosingSnackBar = (variant, message) => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const onSuccess = () => {
    showClosingSnackBar(
      'success',
      'Pronto, tudo certo. Os pacotes foram distribuídos.'
    );
    history.goBack();
  };

  const onFailure = message => {
    showClosingSnackBar('error', message);
  };

  const handleConfirmation = async () => {
    try {
      await movePackagesBulk({
        packageIds: packages.map(p => p.pk),
        destinationUnitLoadLicensePlate: redispatchUnitLoad.license_plate,
        sortingContextLpn: contextLicensePlate,
        receiver: { name, cpf },
        sideEffectParams: {
          distributionCenterId: selectedDistributionCenter.distributionCenterId
        }
      });
      onSuccess();
    } catch (err) {
      handleRestAPIError(err, errorMsg => onFailure(errorMsg));
    }
  };

  return (
    <Dialog
      open
      fullScreen
      data-testid="redispatch-confirmation-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
    >
      <Container
        maxWidth="xs"
        overflow="hidden"
        className={classes.root}
        justify="center"
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex="1 1 auto">
            <Box paddingTop={2.5}>
              <HeaderWithCancel
                testId="redispatch-cancel-button"
                handleClose={handleCancel}
              />
            </Box>
            <Box paddingTop={1.5}>
              <Typography component="div" variant="h5">
                Peça o nome e CPF do motorista responsável pela coleta.
              </Typography>
            </Box>

            <Box mt={3.0}>
              <Box borderRadius={4}>
                <IdentifyPerson
                  name={name}
                  cpf={cpf}
                  onNameChange={handleNameChange}
                  onCpfChange={handleCpfChange}
                />
              </Box>
            </Box>
          </Box>
          <Box flex="0 1 auto">
            <Box>
              <AsyncButton
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                isDisabled={!shouldEnableSubmitButton}
                onClick={handleConfirmation}
                data-testid="redispatch-confirmation-button"
              >
                Pronto, tudo certo
              </AsyncButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
}

RedispatchReceiverConfirmation.propTypes = {
  packages: SharedPropTypes.packages.isRequired,
  redispatchUnitLoad: SharedPropTypes.unitLoad.isRequired,
  contextLicensePlate: PropTypes.string.isRequired
};
