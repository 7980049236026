import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Container } from '@material-ui/core';
import { colors } from '@loggi/mar';
import SortingContextReader from '../../app/components/sorting-context-reader';
import { ROUTES } from '../../constants';
import HeaderWithCancel from '../../app/components/header-with-cancel';

export default function PartnerContextReader() {
  const history = useHistory();

  const goBack = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE.IDENTIFICATION
    });
  };

  const handleRestContextRead = async sorting => {
    history.push({
      pathname: ROUTES.DISTRIBUTE_PARTNER,
      state: {
        sortingContextLicensePlate: sorting.licensePlate
      }
    });
  };

  return (
    <Box
      bgcolor={colors.root[0]}
      height="100vh"
      display="flex"
      flexDirection="column"
      data-testid="distribute-partner-context-reader"
    >
      <Container
        maxWidth="xs"
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Box pt={3}>
          <HeaderWithCancel
            testId="cancel-button"
            handleClose={goBack}
            isDisabled={false}
          />
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Distribuir</Box>
            </Typography>
          </Box>
          <Box mt={1.5}>
            <Typography variant="h5">
              Para começar, bipe o código da operação
            </Typography>
          </Box>
        </Box>
        <SortingContextReader
          onRead={handleRestContextRead}
          placeholder="Leia o código da operação"
          disableFetchDecisions
          aggregated={false}
        />
      </Container>
    </Box>
  );
}
