import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  List,
  Button,
  Divider,
  Paper
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useSnackbar } from 'notistack';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import SkeletonList from '../../../app/components/skeleton-list';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import HeaderWithReturn from '../../../app/components/header-with-return';
import handleRestAPIErrorWithMessage from '../../../app/utils/handle-rest-api-error-with-message';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import { DistributeProcessContext } from '../distribute-process-context';
import DistributionUnitLoadCargoInfo from './distribution-unit-load-cargo-info';
import {
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  ROUTES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  START_TRIP_TRANSFERS_ERROR_MSGS,
  DistributionUnitLoadAlreadyStarted
} from '../../../constants';
import {
  getDistributionUnitLoadCargo,
  startTransfers
} from '../../../api-rest';

export default function DistributionUnitLoadCargoReview() {
  const {
    vehicleLicensePlate,
    distributionUnitLoadId,
    cargos,
    setCargos,
    setTransfersStarted,
    totalCargosBeeped,
    setTotalCargosBeeped,
    clearData,
    setLastCargo
  } = useContext(DistributeProcessContext);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const baseErrorHandler = message => {
    showSnackbarAlert(message, 'error');
    setLoading(false);
  };

  const WHITE_GRADIENT = `linear-gradient(to top, rgba(255, 255, 255, 0) 0%,  ${
    colors.root[0]
  } 70%`;

  const getCargos = async () => {
    try {
      setLoading(true);

      const response = await getDistributionUnitLoadCargo(
        distributionUnitLoadId
      );

      if (response.data.cargos.length === 0) {
        setCargos([]);
        setLoading(false);
        setTotalCargosBeeped(0);
        setLastCargo(null);
        return;
      }

      const validCargo = response.data.cargos.filter(c => c && !c.started);
      setCargos(validCargo);
      setTotalCargosBeeped(validCargo.length);
      setLoading(false);
    } catch (error) {
      setCargos([]);
      setLoading(false);
      handleRestAPIErrorWithMessage(
        BASE_TRIP_TRANSFER_ERROR_MSGS,
        error,
        baseErrorHandler
      );
    }
  };

  const goBack = () => {
    setCargos([]);
    history.push({
      pathname: ROUTES.DISTRIBUTE.ADD_CARGO
    });
  };

  const handleStartTranfers = async () => {
    trackStart(OPERATIONAL_PROCESS.START_TRANSFER, ACTIVITY.START_TRANSFER);

    try {
      const response = await startTransfers({
        distributionUnitLoadId,
        routingCode: selectedDistributionCenter.routingCode,
        vehicleLicensePlate
      });

      setTransfersStarted(response.data.transferIds);
      history.push({
        pathname: ROUTES.DISTRIBUTE.NEW_DISTRIBUTION
      });
    } catch (error) {
      if (error.response?.data?.type === DistributionUnitLoadAlreadyStarted) {
        history.push({
          pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
        });
        clearData();
        showSnackbar({
          variant: 'warning',
          message: BASE_TRIP_TRANSFER_ERROR_MSGS[error.response.data.type],
          showCloseButton: true,
          enqueueSnackbar
        });
        return;
      }

      handleRestAPIErrorWithMessage(
        START_TRIP_TRANSFERS_ERROR_MSGS,
        error,
        baseErrorHandler
      );
    }

    trackEnd(OPERATIONAL_PROCESS.START_TRANSFER, ACTIVITY.START_TRANSFER);
  };

  const goToEdition = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE.EDIT_CARGO
    });
  };

  useEffect(() => {
    getCargos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cargos.length > 0) {
      setLastCargo(cargos[cargos.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargos]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
    >
      <HeaderWithReturn onReturn={goBack} />
      <Container disableGutters>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="baseline"
          ml={3}
          paddingTop={5}
        >
          <Typography
            style={{ fontSize: '86px' }}
            data-testid="distribution-unit-load-cargo-count"
          >
            {totalCargosBeeped}
          </Typography>
          <Typography variant="subtitle2">un.</Typography>
        </Box>
      </Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ overflowY: 'scroll' }}
      >
        <Paper
          style={{
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTop: `1px solid ${colors.smoke[100]}`
          }}
          component={Box}
          elevation={12}
          data-testid="show-cargo-info"
        >
          {!loading ? (
            <>
              <List>
                {cargos?.map(item => (
                  <>
                    <DistributionUnitLoadCargoInfo
                      distributionCargoLicensePlate={item.licensePlate}
                      distributionCargoCompany={item.sourceCompanyName}
                      distributionCargoDestination={item.destinationName}
                      packageCount={item.packageCount}
                    />
                    <Divider light />
                  </>
                ))}
              </List>
            </>
          ) : (
            <Box data-testid="distribute-cargo-loading">
              {[...Array(3)].map(el => (
                <SkeletonList key={el} />
              ))}
            </Box>
          )}
        </Paper>
      </Box>
      <Box
        padding={3}
        style={{
          background: WHITE_GRADIENT
        }}
        width="100%"
      >
        <Box display="flex">
          <Box flex={1} mr={1}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={goToEdition}
            >
              Editar
            </Button>
          </Box>
          <Box flex={1} ml={1}>
            <Button
              data-testid="distribuir-button"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              isDisabled={loading}
              onClick={handleStartTranfers}
            >
              Distribuir
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
