import { Box, makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useContentStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      borderRadius: spacing(2, 2, 0, 0),
      '& + &': {
        borderRadius: 0,
        borderTop: `1px solid ${palette.grey['100']}`,
        boxShadow: 'none'
      },
      '&:last-child': {
        flexGrow: 1
      }
    },
    [breakpoints.up('sm')]: {
      borderRadius: spacing(2),
      '& + &': {
        marginTop: spacing(3)
      }
    }
  }
}));

/**
 * @param children
 * @param disablePadding
 * @param props
 * @returns {*}
 * @constructor
 */
const OneTemplateContent = ({
  children,
  disablePadding,
  disablePaper,
  ...props
}) => {
  const style = useContentStyles();

  if (disablePaper) {
    return children;
  }

  return (
    <Paper
      className={style.paper}
      component={Box}
      elevation={6}
      minHeight={44}
      p={disablePadding ? {} : { xs: 3, sm: 5.5 }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Paper>
  );
};

OneTemplateContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  disablePadding: PropTypes.bool,
  disablePaper: PropTypes.bool
};

OneTemplateContent.defaultProps = {
  disablePadding: false,
  disablePaper: false
};

export default OneTemplateContent;
