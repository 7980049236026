import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from '../../app/shared-prop-types';
import { UNIT_TYPE } from '../../app/enums';

export const OrganizeProcessContext = React.createContext();

export const OrganizeProcessProvider = ({ children, initialState }) => {
  // The current sorting context
  const [sortingContext, setSortingContext] = useState(
    initialState.sortingContext || null
  );

  // The type of the current unit
  const [unitType, setUnitType] = useState(initialState.unitType || null);

  /**
   * The info of the current unit, as we have different types, every type
   * stores different infos
   */
  const [unitInfo, setUnitInfo] = useState(initialState.unitInfo || null);

  const setToInitialState = () => {
    setSortingContext(null);
    setUnitType(null);
    setUnitInfo(null);
  };

  return (
    <OrganizeProcessContext.Provider
      value={{
        setToInitialState,
        sortingContext,
        setSortingContext,
        unitType,
        setUnitType,
        unitInfo,
        setUnitInfo
      }}
    >
      {children}
    </OrganizeProcessContext.Provider>
  );
};

OrganizeProcessProvider.defaultProps = {
  initialState: {}
};

OrganizeProcessProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialState: PropTypes.shape({
    sortingContext: SharedPropTypes.sortingContext,
    unitInfo: PropTypes.shape({
      pkg: SharedPropTypes.package,
      decision: SharedPropTypes.decision,
      recommendationDecision: SharedPropTypes.decision,
      unitLoad: SharedPropTypes.unitLoad,
      childrenUnitLoad: SharedPropTypes.childrenUnitLoad,
      unitLoadsOutlier: SharedPropTypes.childrenUnitLoad
    }),
    unitType: PropTypes.oneOf(Object.values(UNIT_TYPE))
  })
};
