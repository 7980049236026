/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router';
import HeaderWithButton from '../../app/components/header-with-button';
import PackagePosition from '../../app/components/package-position';
import { useStyles } from '../../app/components/snackbar/snackbar-component';
import { HISTORY_ACTIONS } from '../../constants';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import StrictPackageIdentification from '../distribute/strict-package-identification';

export default function IdentifyPackageReader({ history }) {
  const location = useLocation();

  const { bag, checkedPackagesIds } = location.state;

  const handleReturn = () => {
    history.replace('/identify');
  };

  // prevent user for opening this page when editing the URL
  if (
    history.action !== HISTORY_ACTIONS.PUSH ||
    !location.state?.bag ||
    !location.state?.hasAccessToPrepareBag
  ) {
    handleReturn();
    return null;
  }

  const classes = useStyles();
  const [checkedPackages, setCheckedPackages] = React.useState(
    bag.packages.filter(p => checkedPackagesIds.includes(p.pk))
  );
  const [remainingPackages, setRemainingPackages] = React.useState(
    bag.packages.filter(p => !checkedPackagesIds.includes(p.pk))
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const lastCheckedPackagePosition =
    checkedPackages[checkedPackages.length - 1];

  const clearForNewReading = () => {
    setErrorMessage('');
  };

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const updatePackagesStates = barcode => {
    setCheckedPackages([
      ...checkedPackages,
      ...bag.packages.filter(p => p.identifier === barcode)
    ]);
    setRemainingPackages(
      remainingPackages.filter(p => p.identifier !== barcode)
    );
  };

  const handlePackageReadSuccess = async currentPackage => {
    clearForNewReading();

    if (!currentPackage) {
      baseErrorHandler('Código de barras não encontrado');
      return;
    }

    const barcode = currentPackage.identifier;
    if (!checkedPackages.some(p => p.identifier === barcode)) {
      updatePackagesStates(barcode);
      playSuccessBeep();
    }
  };

  return (
    <Box data-testid="identify-package-reader">
      <Container maxWidth="xs" className={classes.root}>
        <Box paddingTop={2.5} className={classes.flexBox}>
          <HeaderWithButton
            testId="identify-package-reader-ok-button"
            handleButtonClick={handleReturn}
          />
          <Box className={classes.flexBody}>
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Preparar embarque</Box>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <PackagePosition
                checkedPackages={checkedPackages}
                skipPackagesChecks={false}
                identifier="identify"
                lastCheckedPackagePosition={lastCheckedPackagePosition}
                totalPackages={bag.numPackages}
                showPackagePositionOnDistribute="true"
              />
            </Box>
            <Box mt={8}>
              <Typography variant="body1">LACRE {bag.seal}</Typography>
            </Box>
            <Box className="centered" mt={2.5}>
              <StrictPackageIdentification
                expectedPackages={bag.packages}
                before={clearForNewReading}
                success={handlePackageReadSuccess}
                fail={baseErrorHandler}
                source="xd_app_identify_package_reader"
                notes={`Saca ${bag.seal} bipada no Preparar embarque`}
              />
            </Box>
            {errorMessage && (
              <Box mt={1.5}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

IdentifyPackageReader.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    action: PropTypes.string
  }).isRequired
};
