import { captureException } from '@sentry/browser';
import { DEFAULT_REQUEST_TIMEOUT } from '../../api-rest';
import { ERRORS, NETWORK_ERROR_MESSAGE } from '../../constants';

const handleRestAPIError = (error, messageCallback) => {
  const { message, isAxiosError, code } = error;
  const responseData = error.response?.data;
  let displayMessage = message;

  // These are the expected error payloads, so we're not sending to sentry
  if (responseData?.errorMsg || responseData?.error) {
    displayMessage = responseData.errorMsg || responseData.error;
  } else {
    // sometimes API error can be more especific with a .detail attribute
    if (responseData?.detail) {
      displayMessage = responseData?.detail;
    }
    if (isAxiosError) {
      // If the error is a client timeout or abort, we should display a good message to the user
      // https://github.com/axios/axios/blob/16aa2ce7fa42e7c46407b78966b7521d8e588a72/test/unit/adapters/http.js
      if (code === 'ECONNABORTED' && message.includes('timeout')) {
        displayMessage = `${
          ERRORS.SOMETHING_WRONG_TRY_AGAIN
        } (Tempo de resposta excedido em ${DEFAULT_REQUEST_TIMEOUT /
          1000} segundos)`;
      }
      // If the error is a network error, show a better message to the user
      // https://github.com/axios/axios/blob/04d45f20911a02e9457db9e9d104aa156e170b11/lib/adapters/xhr.js
      if (message === 'Network Error') {
        displayMessage = NETWORK_ERROR_MESSAGE;
      }
    } else {
      // We only capture unexpected errors
      captureException(error);
    }
  }

  messageCallback(displayMessage);
};

export default handleRestAPIError;
