/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Container as MaterialContainer,
  withStyles
} from '@material-ui/core';
import { Can } from './access-control-container';
import { ROUTES } from '../../constants';
import { useDistributionCenter } from './distribution-center-provider';

const Container = withStyles({
  root: {
    height: '100vh'
  }
})(MaterialContainer);

/**
 * This component will render a loading feedback while
 * checking if this user Distribution Center is already
 * configured. Will call redirectToDCSelect if the user
 * needs to setup its Distribution Center.
 */
export function WithDCCheck({ children }) {
  const history = useHistory();
  const [isRunningChecks, setIsRunningChecks] = useState(true);

  const {
    state: { selectedDistributionCenter, loadingDistributionCenter }
  } = useDistributionCenter();

  useEffect(() => {
    // Should redirect to DC select screen when there is no DC selected
    if (!loadingDistributionCenter) {
      if (selectedDistributionCenter) {
        setIsRunningChecks(false);
      } else {
        setIsRunningChecks(false);
        history.push({ pathname: '/choose-distribution-center' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDistributionCenter]);

  if (isRunningChecks) {
    return (
      <Container maxWidth="xs">
        <Box
          data-testid="loading-dcs"
          display="flex"
          height="100vh"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
          Carregando local de trabalho
        </Box>
      </Container>
    );
  }

  return children;
}

WithDCCheck.propTypes = {
  children: PropTypes.element
};

WithDCCheck.defaultProps = {
  children: null
};

/**
 * CustomRoute is an implementation of React Route that follows our authorization rules.
 *
 * If the user is allowed to access the route resource, then the route will be successfully
 * rendered. Otherwise, we will redirect the user to the PageNotFound screen.
 */
const CustomRoute = ({ component: Component, actions, ...rest }) => {
  return (
    <>
      <WithDCCheck>
        <Can actions={actions}>
          <Route
            {...rest}
            render={props => {
              return <Component {...props} />;
            }}
          />
        </Can>
        <Can actions={actions} reverse>
          <Redirect to={ROUTES.NOT_FOUND} />
        </Can>
      </WithDCCheck>
    </>
  );
};

CustomRoute.propTypes = {
  component: PropTypes.instanceOf(Function),
  actions: PropTypes.arrayOf(PropTypes.string)
};

CustomRoute.defaultProps = {
  component: null,
  actions: []
};

export default CustomRoute;
