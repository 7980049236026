import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useLocation, Redirect } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { playErrorBeep, playSuccessBeep } from '../sounds';
import { editStatus, listPackageStatuses } from './edit-package-status-service';
import { ACTIVITY, OPERATIONAL_PROCESS } from '../constants';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';

moment.updateLocale('pt-BR');

const useStyles = makeStyles(thisTheme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: thisTheme.typography.pxToRem(1)
    }
  },
  padding: {
    padding: 0
  },
  fullWidth: {
    width: '100%'
  }
}));

export default function EditPackageStatus({
  listPackageStatusesService,
  editPackageStatusService
}) {
  const [pack, setPack] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [redirectIdentify, setRedirectIdentify] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [newStatus, setNewStatus] = React.useState({ status: '', notes: '' });
  const [packageStatusOptions, setPackageStatusOptions] = React.useState([]);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const classes = useStyles();
  const location = useLocation();
  const resetNewStatus = () => {
    setNewStatus({ status: '', notes: '' });
    setLoading(false);
  };

  const errorHandler = React.useCallback(errMessage => {
    setErrorMessage(errMessage);
    playErrorBeep();
    resetNewStatus();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    const packState = location?.state?.pack;
    if (packState) setPack(packState);
    else setRedirectIdentify(true);
  }, [location, pack]);

  React.useEffect(() => {
    const getPackageStatusOptions = async () => {
      trackStart(
        OPERATIONAL_PROCESS.EDIT_PACKAGE_STATUS,
        ACTIVITY.FULL_PROCESS
      );
      trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
      setLoading(true);

      const ListPackageStatusesPayload = await listPackageStatusesService({
        errorHandler
      });

      setLoading(false);
      if (!ListPackageStatusesPayload) return;

      setPackageStatusOptions(ListPackageStatusesPayload);
    };
    getPackageStatusOptions();
    return () => {
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
      trackEnd(OPERATIONAL_PROCESS.EDIT_PACKAGE_STATUS, ACTIVITY.FULL_PROCESS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = event => {
    setNewStatus({
      ...newStatus,
      status: event.target.value,
      statusDisplay: event.target.name
    });
  };

  const handleJustifyStatusChange = event => {
    setNewStatus({ ...newStatus, notes: event.target.value });
  };

  const handleSave = async () => {
    if (!(newStatus.status && newStatus.notes)) {
      playErrorBeep();
      setErrorMessage(
        'Preencha todos os campos para atualizar o status do pacote'
      );
      return;
    }
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.EDIT_PACKAGE_STATUS_BEEP
    );

    setLoading(true);
    setErrorMessage('');

    const editPackageStatusPayload = await editPackageStatusService({
      checkId: pack.packageCheckId,
      newStatusCode: newStatus.status,
      notes: newStatus.notes,
      errorHandler
    });
    if (!editPackageStatusPayload) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.EDIT_PACKAGE_STATUS_BEEP
      );
      return;
    }

    if (!editPackageStatusPayload.success) {
      const errMessage = (
        editPackageStatusPayload.errors.map(err => err.message) || []
      ).join('\n');
      errorHandler(errMessage);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.EDIT_PACKAGE_STATUS_BEEP
      );
      return;
    }

    playSuccessBeep();
    setRedirectIdentify(true);
    setErrorMessage('');
    setLoading(false);
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.EDIT_PACKAGE_STATUS_BEEP
    );
  };

  if (redirectIdentify) {
    return (
      <Redirect
        to={{
          pathname: '/identify'
        }}
      />
    );
  }

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Editar status do pacote
      </Typography>
      <Divider />
      <Container maxWidth="xs">
        <Box p={1}>
          <Card>
            <CardContent>
              {pack && (
                <Box pt={2}>
                  <Typography>Pacote {pack.identifier}</Typography>
                  <Typography variant="caption">
                    Status atual: {pack.statusCodeDisplay}
                  </Typography>
                </Box>
              )}
              <Box>
                <Box py={2}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel>Novo status</InputLabel>
                    <Select
                      native
                      value={newStatus.status}
                      onChange={handleStatusChange}
                      data-testid="edit-status-options"
                      inputProps={{
                        'data-testid': 'select-id'
                      }}
                    >
                      <option value=""> </option>
                      {packageStatusOptions.map(status => (
                        <option value={status.value} key={status.value}>
                          {status.display}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box pt={2}>
                  <TextField
                    className={classes.fullWidth}
                    inputProps={{ 'aria-label': 'edit-status-notes' }}
                    label="Por que o status estava errado?"
                    multiline
                    rows="3"
                    variant="outlined"
                    onChange={handleJustifyStatusChange}
                    value={newStatus.notes}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardContent>
              <Box p={0.1} my={1.5} display="flex" flexDirection="row-reverse">
                <Box mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    Salvar
                  </Button>
                </Box>
                <Box mx={1}>
                  <Button
                    variant="contained"
                    onClick={() => setRedirectIdentify(true)}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
              {loading && (
                <Box mt={1.5} display="flex" justifyContent="center">
                  <Box>
                    <CircularProgress justify="center" />
                  </Box>
                </Box>
              )}
              {errorMessage && (
                <Box my={1.5}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

EditPackageStatus.defaultProps = {
  // It becomes easier to test/mock by injecting the service.
  listPackageStatusesService: listPackageStatuses,
  editPackageStatusService: editStatus
};

EditPackageStatus.propTypes = {
  listPackageStatusesService: PropTypes.func,
  editPackageStatusService: PropTypes.func
};
