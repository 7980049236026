import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import { pxToRem } from '@loggi/mar/src/utils';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import {
  ACTIVITY,
  HISTORY_ACTIONS,
  OPERATIONAL_PROCESS,
  RECEIVE_REDISPATCH_PAGE_STATE,
  UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX
} from '../../../constants';
import { ReceivingProcessContext } from '../receiving-process-context';
import { SelectDestinationScreen, SenderCard } from '../receive';
import CauseSelection from './cause-selection';
import DecorativeHeader from '../../../app/components/decorative-header';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import { playErrorBeep, playSuccessBeep } from '../../../sounds';
import {
  getReturnCauseList,
  identifyPackagesToReceive,
  movePackage
} from '../../../api-rest';
import handleRestAPIError from '../../../app/utils/rest-api-request';
import { PackageReader } from '../../../app/components/barcode-readers';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import SortingRecommendation from '../sorting-recommendation';

const ReceiveRedispatchPackage = () => {
  const {
    packageBarcode,
    setPackageBarcode,
    setReturnCause,
    returnList,
    setReturnList,
    sortingContext,
    receivedSenderPackages,
    setReceivedSenderPackages
  } = useContext(ReceivingProcessContext);
  const [pageState, setPageState] = useState('');
  const [loading, setLoading] = useState(false);
  const [causes, setCauses] = useState([]);
  const { trackEnd } = useContext(ActivityTrackingContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const defaultReceivingUnitLoad = `${
    selectedDistributionCenter.routingCode
  } ${UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX}`;

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const baseErrorHandler = message => {
    playErrorBeep();
    showSnackbarAlert(message, 'error');
    setLoading(false);
  };

  const findReturnCauses = async barcode => {
    const response = await getReturnCauseList(barcode);

    const { items } = response.data;
    setCauses(items);
  };

  const processRedispatchPackage = async (barcode, goBackOnCancel = false) => {
    try {
      setLoading(true);
      await findReturnCauses(barcode);
      playSuccessBeep();
      setPageState(RECEIVE_REDISPATCH_PAGE_STATE.SELECT_CAUSE);
      setLoading(false);
    } catch (err) {
      handleRestAPIError(err, baseErrorHandler);
      if (goBackOnCancel) history.goBack();
    }
  };

  const addReturn = (pkgBarcode, packageStatusMessage, unitLoadLpn) => {
    setReceivedSenderPackages(receivedSenderPackages + 1);

    returnList.push({
      package: pkgBarcode,
      deliveryStatus: packageStatusMessage,
      unitLoad: unitLoadLpn,
      senderPackage: true
    });

    setReturnList(returnList);
  };

  const handleMoveCompleted = (unitLoadLpn, packageStatusMessage) => {
    addReturn(packageBarcode, packageStatusMessage, unitLoadLpn);
    setPackageBarcode('');
    setReturnCause(null);
    setPageState(RECEIVE_REDISPATCH_PAGE_STATE.BEEP_PACKAGES);
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.RECEIVE_DESTINATION_BEEP
    );
  };

  const movePackageToDefaultUL = (barcode, notes, changeToStatus) => {
    return movePackage(
      defaultReceivingUnitLoad,
      barcode,
      defaultReceivingUnitLoad,
      sortingContext,
      {
        distributionCenterId: selectedDistributionCenter?.distributionCenterId,
        notes,
        changeToStatus
      }
    );
  };

  const handleConfirmCause = async (button, notes, changeToStatus) => {
    await movePackageToDefaultUL(packageBarcode, notes, changeToStatus);
    handleMoveCompleted(defaultReceivingUnitLoad, notes);
  };

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH || !sortingContext) {
      history.replace('/receive/context-reader');
    }
  }, [selectedDistributionCenter, history, sortingContext]);

  /**
   * For the first package only
   * we execute this effect
   */
  useEffect(() => {
    (async function processPackage() {
      if (packageBarcode) await processRedispatchPackage(packageBarcode, true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Box mt={1.5} display="flex" justifyContent="center">
          <Box>
            <CircularProgress justify="center" />
          </Box>
        </Box>
      ) : (
        <>
          {pageState === RECEIVE_REDISPATCH_PAGE_STATE.BEEP_PACKAGES && (
            <RedispatchPackageBeep
              showSnackbarAlert={showSnackbarAlert}
              processRedispatchPackage={processRedispatchPackage}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {pageState === RECEIVE_REDISPATCH_PAGE_STATE.SELECT_CAUSE && (
            <Box height="100vh" clone>
              <Container maxWidth="xs">
                <Box
                  pt={3}
                  display="flex"
                  flexDirection="column"
                  height="98vh"
                  overflow="hidden"
                >
                  <Box flex="0 1 auto">
                    <Box paddingTop={pxToRem(20)}>
                      <DecorativeHeader />
                      <Box paddingTop={1.5}>
                        <Typography
                          component="div"
                          variant="body1"
                          gutterBottom
                        >
                          <Box fontWeight="fontWeightBold">Receber</Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex="1 1 auto">
                    <CauseSelection
                      barcode={packageBarcode}
                      causes={causes}
                      onButtonClick={handleConfirmCause}
                    />
                  </Box>
                </Box>
              </Container>
            </Box>
          )}
          {pageState === RECEIVE_REDISPATCH_PAGE_STATE.SELECT_DESTINATION && (
            <Box height="100vh" clone>
              <Container maxWidth="xs">
                <Box>
                  <Box paddingTop={pxToRem(20)}>
                    <DecorativeHeader />
                    <Box paddingTop={1.5}>
                      <Typography component="div" variant="body1" gutterBottom>
                        <Box fontWeight="fontWeightBold">Receber</Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" heigth="100%">
                  <SelectDestinationScreen
                    onMoveCompleted={handleMoveCompleted}
                  />
                </Box>
              </Container>
            </Box>
          )}
        </>
      )}
    </>
  );
};

const RedispatchPackageBeep = ({
  processRedispatchPackage,
  loading,
  setLoading,
  showSnackbarAlert
}) => {
  const {
    setPackageBarcode,
    returnList,
    sender,
    setReturnCause,
    setPackToReceive,
    errorMessage,
    setErrorMessage
  } = useContext(ReceivingProcessContext);
  const history = useHistory();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const finish = () => {
    history.push({
      pathname: '/receive/context-reader'
    });
  };

  const receiveSuccess = () => {
    showSnackbarAlert('Pronto, tudo certo.\nUnidades recebidas.');
    playSuccessBeep();
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVE_REDISPATCH);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    finish();
  };

  const isDuplicatedPackageBeep = barcode =>
    returnList.filter(item => item.package === barcode)[0];

  const cancel = () => {
    setPackageBarcode('');
    setReturnCause(null);
  };

  const shouldReceivePackage = async responsePackage => {
    setPackToReceive(responsePackage);
    setPackageBarcode(responsePackage.barcode);

    if (isDuplicatedPackageBeep(responsePackage.barcode)) {
      showSnackbarAlert('Opa, pacote já bipado!', 'warning');
      cancel();
      return;
    }

    await processRedispatchPackage(responsePackage.barcode);
  };

  const handleChange = () => {
    setErrorMessage('');
    setPackageBarcode('');
    setReturnCause(null);
  };

  const handleRead = async barcode => {
    if (loading) return;

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.RECEIVE_REDISPATCH_PACKAGE_BEEP
    );

    setErrorMessage('');

    setLoading(true);
    const response = await identifyPackagesToReceive(barcode).catch(err => {
      handleRestAPIError(err, errorMsg => setErrorMessage(errorMsg));
      playErrorBeep();
      setLoading(false);
    });

    if (!response) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.RECEIVE_REDISPATCH_PACKAGE_BEEP
      );
      return;
    }

    setLoading(false);

    if (response.data && response.data.missingPackages) {
      const receivedPackage = response.data.missingPackages.filter(
        pack => pack.barcode === barcode
      )[0];

      await shouldReceivePackage(receivedPackage);
    }

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.RECEIVE_REDISPATCH_PACKAGE_BEEP
    );
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVE_REDISPATCH);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs">
      <Box
        pt={3}
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow="hidden"
      >
        <Box flex="0 1 auto">
          <DecorativeHeader />
          <Box>
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Receber</Box>
              </Typography>
            </Box>
          </Box>
          {sender && (
            <SenderCard
              sender={sender}
              logoCorreiosVisible
              showSender={() => {}}
            />
          )}

          <Box className="centered" mt={2.5}>
            <PackageReader
              onRead={handleRead}
              onChange={handleChange}
              loading={loading}
              notes="Bipe na tela de recebimento"
              placeholder="Leia a etiqueta do pacote"
            />
          </Box>
          {errorMessage && (
            <Box mt={1.5}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
        </Box>
        <Box height="40vh" my={2} flex="1 1 auto" style={{ overflowY: 'auto' }}>
          <RedispatchPackageList receivedPackages={returnList} />
        </Box>
        <Box mb={1.5} flex="0 1 auto">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            data-testid="receive-transfer-button"
            onClick={receiveSuccess}
          >
            Pronto, recebidos
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

RedispatchPackageBeep.propTypes = {
  processRedispatchPackage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  showSnackbarAlert: PropTypes.func.isRequired
};

const RedispatchPackageList = ({ receivedPackages }) => {
  return (
    <List data-testid="redispatch-package-list">
      {receivedPackages.map((receivedPackage, i) => (
        <RedispatchPackageItem
          key={`${receivedPackage.package}-${receivedPackage.unitLoad}`}
          receivedPackage={receivedPackage}
          bottomDivider={receivedPackages.length - 1 === i}
        />
      ))}
    </List>
  );
};

RedispatchPackageList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  receivedPackages: PropTypes.array.isRequired
};

const RedispatchPackageItem = ({ receivedPackage, bottomDivider }) => {
  const { sortingContext } = useContext(ReceivingProcessContext);
  return (
    <Box mb={bottomDivider ? 0 : 1}>
      <Divider />
      <Box mt={1} mb={bottomDivider ? 1 : 0} paddingLeft={0} clone>
        <ListItem>
          <Box>
            {receivedPackage.deliveryStatus && (
              <Typography variant="body2">
                {receivedPackage.deliveryStatus}
              </Typography>
            )}
            <Typography variant="body2">#{receivedPackage.package}</Typography>
            <SortingRecommendation
              sortingContext={sortingContext}
              barcode={receivedPackage.package}
            />
          </Box>
        </ListItem>
      </Box>
      {bottomDivider && <Divider />}
    </Box>
  );
};

RedispatchPackageItem.defaultProps = {
  bottomDivider: false
};

RedispatchPackageItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  receivedPackage: PropTypes.object.isRequired,
  bottomDivider: PropTypes.bool
};

export default ReceiveRedispatchPackage;
