import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useFeature } from '../remote-config';
import {
  SHIFT_START,
  TIME_GAP_BEFORE_NEXT_SCHEDULING_IN_MINUTES,
  MOMENT_SHIFT_END_WITH_GAP
} from '../formik-fields/constants';
import useCoverageParamsContext from './use-coverage-params-context.';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      backgroundColor: colors.smoke[50],
      border: `1px solid ${colors.smoke[300]}`,
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    subtitleIcon: {
      color: palette.info.dark
    }
  })
);

const CoverageParamsAlert = () => {
  const { t } = useTranslation('one');
  const styles = useStyles();
  const {
    shiftStart,
    shiftEndWithGap,
    shiftInitialSlot,
    pickupWorkingWeekdays
  } = useCoverageParamsContext();

  const shouldUseCoverageParams = useFeature('should_use_coverage_params');
  const shouldDisableWeekDaysWithoutPickup = useFeature(
    'should_disable_week_days_without_pickup'
  );

  let startTime = SHIFT_START;
  let endTime = MOMENT_SHIFT_END_WITH_GAP.format('HH:mm');
  let slotTime = moment()
    .hour(0)
    .minute(TIME_GAP_BEFORE_NEXT_SCHEDULING_IN_MINUTES)
    .format('HH:mm');

  if (shouldUseCoverageParams) {
    startTime = shiftStart;
    endTime = shiftEndWithGap;
    slotTime = shiftInitialSlot;
  }

  const WORKING_WEEK_DAYS_LIST_SIZE = 5;
  const SINGLE_WORKING_WEEK_DAYS_LIST_SIZE = 1;

  const shortPtBrWeekDays = pickupWorkingWeekdays.map(weekDay => {
    return moment()
      .locale('en')
      .isoWeekday(weekDay)
      .locale('pt-br')
      .format('dddd')
      .replace('-feira', 's,')
      .toLowerCase();
  });

  const punctuatedList =
    shortPtBrWeekDays.length > SINGLE_WORKING_WEEK_DAYS_LIST_SIZE
      ? shortPtBrWeekDays.map((x, index) => {
          if (index === shortPtBrWeekDays.length - 2)
            return shortPtBrWeekDays[index].replace(',', ' e ');
          if (index === shortPtBrWeekDays.length - 1)
            return shortPtBrWeekDays[index].replace(',', '');
          return shortPtBrWeekDays[index].replace(',', ', ');
        })
      : shortPtBrWeekDays.toString().replace(',', '');

  const messagePunctuated = Array.from(punctuatedList).join('');

  const msgAlert = t('coverageParamsAlert.alertDescription', {
    startTime,
    endTime,
    slotTime
  });

  const workingWeekDaysMsgAlert =
    pickupWorkingWeekdays.length < WORKING_WEEK_DAYS_LIST_SIZE
      ? t('coverageParamsAlert.alertDescriptionForSomeWeekDays', {
          someWeekDays: messagePunctuated,
          startTime,
          endTime,
          slotTime
        })
      : t('coverageParamsAlert.alertDescriptionForAllWeekDays', {
          startTime,
          endTime,
          slotTime
        });

  return (
    <>
      <Alert
        className={styles.alertMessage}
        data-testid="coverage-params-alert"
        icon={<Error className={styles.subtitleIcon} fontSize="small" />}
        severity="error"
      >
        <AlertTitle>{t('coverageParamsAlert.alertTitle')}</AlertTitle>
        {shouldDisableWeekDaysWithoutPickup
          ? workingWeekDaysMsgAlert
          : msgAlert}
      </Alert>
    </>
  );
};

export default CoverageParamsAlert;
