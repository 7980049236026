import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { driverShape } from '../../app/components/driver-list';

export const DistributeProcessContext = React.createContext();

export const DistributeProcessProvider = ({ children, initialState }) => {
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const [distributeType, setDistributeType] = useState(
    initialState.distributeType || null
  );

  const [lastCargo, setLastCargo] = useState(null);

  const [distributionUnitLoadId, setDistributionUnitLoadId] = useState(
    initialState.distributionUnitLoadId || null
  );

  const [totalCargosBeeped, setTotalCargosBeeped] = useState(
    initialState.totalCargosBeeped || 0
  );

  const [sortingContext, setSortingContext] = useState(
    initialState.sortingContext || selectedDistributionCenter
      ? `${selectedDistributionCenter.routingCode} DIS`
      : null
  );

  const [driver, setDriver] = useState(initialState.driver || null);

  const [openDriverDetailDialog, setOpenDriverDetailDialog] = useState(
    initialState.openDriverDetailDialog || false
  );

  const [selectedDriver, setSelectedDriver] = useState(
    initialState.selectedDriver || null
  );

  const [driverList, setDriverList] = useState(initialState.driverList || []);

  const [name, setName] = useState(initialState.name || '');

  const [cpf, setCpf] = useState(initialState.cpf || '');

  const [vehicleLicensePlate, setVehicleLicensePlate] = useState(
    initialState.vehicleLicensePlate || ''
  );

  const [destinationList, setDestinationList] = useState(
    initialState.destinationList || []
  );

  const [transfersStarted, setTransfersStarted] = useState(
    initialState.destinationList || []
  );
  const [cargos, setCargos] = useState(initialState.cargos || []);

  const setToInitialState = () => {
    setDistributeType(null);
    setSortingContext(null);
    setDriver(null);
    setOpenDriverDetailDialog(false);
    setSelectedDriver(null);
    setDriverList([]);
    setName('');
    setCpf('');
    setDestinationList([]);
    setVehicleLicensePlate('');
    setLastCargo(null);
    setDistributionUnitLoadId(null);
    setTotalCargosBeeped(0);
    setTransfersStarted([]);
    setCargos([]);
  };

  const clearData = () => {
    setTotalCargosBeeped(0);
    setLastCargo(null);
    setDistributionUnitLoadId(0);
  };

  return (
    <DistributeProcessContext.Provider
      value={{
        distributeType,
        setDistributeType,
        sortingContext,
        setSortingContext,
        setToInitialState,
        driver,
        setDriver,
        openDriverDetailDialog,
        setOpenDriverDetailDialog,
        selectedDriver,
        setSelectedDriver,
        driverList,
        setDriverList,
        name,
        setName,
        cpf,
        setCpf,
        destinationList,
        setDestinationList,
        vehicleLicensePlate,
        setVehicleLicensePlate,
        lastCargo,
        setLastCargo,
        distributionUnitLoadId,
        setDistributionUnitLoadId,
        totalCargosBeeped,
        setTotalCargosBeeped,
        transfersStarted,
        setTransfersStarted,
        cargos,
        setCargos,
        clearData
      }}
    >
      {children}
    </DistributeProcessContext.Provider>
  );
};

DistributeProcessProvider.defaultProps = {
  initialState: {}
};

DistributeProcessProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialState: PropTypes.shape({
    distributeType: PropTypes.string,
    sortingContext: PropTypes.string,
    driver: PropTypes.shape({ driverShape }),
    openDriverDetailDialog: PropTypes.bool,
    selectedDriver: PropTypes.shape({ driverShape }),
    driverList: PropTypes.arrayOf(driverShape),
    name: PropTypes.string,
    cpf: PropTypes.string,
    destinationList: PropTypes.arrayOf(
      PropTypes.shape({
        destinationRoutingCode: PropTypes.string,
        destinationName: PropTypes.string,
        isSelected: PropTypes.bool
      })
    ),
    vehicleLicensePlate: PropTypes.string,
    lastCargo: PropTypes.arrayOf(
      PropTypes.shape({
        destinationRoutingCode: PropTypes.string,
        destinationName: PropTypes.string,
        packageCount: PropTypes.number,
        licensePlate: PropTypes.string,
        sourceCompanyName: PropTypes.string,
        transferId: PropTypes.number,
        started: PropTypes.bool
      })
    ),
    distributionUnitLoadId: PropTypes.number,
    totalCargosBeeped: PropTypes.number,
    transfersStarted: PropTypes.arrayOf(PropTypes.number),
    cargos: PropTypes.arrayOf(
      PropTypes.shape({
        destinationRoutingCode: PropTypes.string,
        destinationName: PropTypes.string,
        licensePlate: PropTypes.string,
        sourceCompanyName: PropTypes.string,
        transferId: PropTypes.number,
        started: PropTypes.bool,
        finalDestinationName: PropTypes.string,
        finalDestinationRoutingCode: PropTypes.string
      })
    )
  })
};
