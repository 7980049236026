import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container, Card } from '@material-ui/core';
import PhotoTemplate from '../../app/components/photo-template';
import { TEXT, TESTID } from './constants';

export default function DamagePhotoUpload({ onConfirmSend, shouldShowCamera }) {
  const [imageData, setImageData] = useState(null);

  const onConfirm = image => {
    onConfirmSend(image);
    setImageData(null);
  };

  return (
    <Box>
      <Container maxWidth="xs">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          data-testid={TESTID.DAMAGE_PHOTO_TITLE}
        >
          {TEXT.PHOTO_CAPTURE_TITLE}
        </Typography>
        <Typography
          align="center"
          gutterBottom
          data-testid={TESTID.DAMAGE_PHOTO_DESCRIPTION}
        >
          {TEXT.PHOTO_CAPTURE_DESCRIPTION}
        </Typography>
      </Container>
      {shouldShowCamera && (
        <Box pb={1} data-testid={TESTID.DAMAGE_PHOTO_CONTAINER}>
          <Card>
            <Box my={1}>
              <PhotoTemplate
                imageData={imageData}
                onImageCapture={onConfirm}
                imgAlt={TEXT.PHOTO_CAPTURE_IMG_ALT}
              />
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  );
}

DamagePhotoUpload.propTypes = {
  onConfirmSend: PropTypes.func.isRequired,
  shouldShowCamera: PropTypes.bool
};

DamagePhotoUpload.defaultProps = {
  shouldShowCamera: true
};
