export const PACKAGE_ORIGIN = {
  PACKAGE_ORIGIN_INVALID: 'PACKAGE_ORIGIN_INVALID',
  PACKAGE_ORIGIN_UNKNOWN: 'PACKAGE_ORIGIN_UNKNOWN',
  PACKAGE_ORIGIN_NOT_INITIATED: 'PACKAGE_ORIGIN_NOT_INITIATED',
  PACKAGE_ORIGIN_FLECHA: 'PACKAGE_ORIGIN_FLECHA',
  PACKAGE_ORIGIN_REDISPATCH: 'PACKAGE_ORIGIN_REDISPATCH',
  PACKAGE_ORIGIN_PICKUP: 'PACKAGE_ORIGIN_PICKUP',
  PACKAGE_ORIGIN_TRANSFER: 'PACKAGE_ORIGIN_TRANSFER',
  PACKAGE_ORIGIN_PARTNER: 'PACKAGE_ORIGIN_PARTNER'
};

export const BAG_TYPE = {
  BAG_TYPE_INVALID: 'BAG_TYPE_INVALID',
  BAG_TYPE_FLECHA: 'BAG_TYPE_FLECHA',
  BAG_TYPE_REDISPATCH: 'BAG_TYPE_REDISPATCH'
};

export const LABEL_TYPE = {
  LABEL_TYPE_INVALID: 'LABEL_TYPE_INVALID',
  LABEL_TYPE_LOGGI: 'LABEL_TYPE_LOGGI',
  LABEL_TYPE_REDISPATCH: 'LABEL_TYPE_REDISPATCH',
  LABEL_TYPE_NF: 'LABEL_TYPE_NF',
  LABEL_TYPE_CTE: 'LABEL_TYPE_CTE',
  LABEL_TYPE_DECON: 'LABEL_TYPE_DECON'
};

export const UNIT_TYPE = {
  UNIT_TYPE_INVALID: 'UNIT_TYPE_INVALID',
  UNIT_TYPE_PACKAGE: 'UNIT_TYPE_PACKAGE',
  UNIT_TYPE_BAG: 'UNIT_TYPE_BAG',
  UNIT_TYPE_UNIT_LOAD: 'UNIT_TYPE_UNIT_LOAD'
};

// This enum represents the fields for UnitLoadTypes used in SortingUnitLoad.
export const UNIT_LOAD_TYPE = {
  // Invalid unit load type
  UNIT_LOAD_TYPE_INVALID: 'UNIT_LOAD_TYPE_INVALID',
  // Generic unit load type, eg: bag, cage ...
  UNIT_LOAD_TYPE_REGULAR: 'UNIT_LOAD_TYPE_REGULAR',
  // Unit load that is a single package
  UNIT_LOAD_TYPE_PACKAGE: 'UNIT_LOAD_TYPE_PACKAGE'
};

// This enum represents the field for ConfirmationType used in SortingDecision.
export const CONFIRMATION_TYPE = {
  INVALID: 'INVALID',
  NO_CONFIRMATION: 'NO_CONFIRMATION',
  UNIT_LOAD_LICENSE_PLATE: 'UNIT_LOAD_LICENSE_PLATE',
  DONT_MOVE: 'DONT_MOVE'
};

export const PACKAGE_STATUS_CODE = {
  PACKAGE_STATUS_CODE_DELIVERED: 'PACKAGE_STATUS_CODE_DELIVERED',
  PACKAGE_STATUS_CODE_PICKUP: 'PACKAGE_STATUS_CODE_PICKUP',
  PACKAGE_STATUS_CODE_GOING_TO_DELIVER: 'PACKAGE_STATUS_CODE_GOING_TO_DELIVER',
  PACKAGE_STATUS_CODE_RECIPIENT_REFUSED:
    'PACKAGE_STATUS_CODE_RECIPIENT_REFUSED',
  PACKAGE_STATUS_CODE_RECIPIENT_UNAVAILABLE:
    'PACKAGE_STATUS_CODE_RECIPIENT_UNAVAILABLE',
  PACKAGE_STATUS_CODE_INVALID_DESTINATION:
    'PACKAGE_STATUS_CODE_INVALID_DESTINATION',
  PACKAGE_STATUS_CODE_DAMAGED: 'PACKAGE_STATUS_CODE_DAMAGED',
  PACKAGE_STATUS_CODE_LOST: 'PACKAGE_STATUS_CODE_LOST',
  PACKAGE_STATUS_CODE_STOLEN: 'PACKAGE_STATUS_CODE_STOLEN',
  PACKAGE_STATUS_CODE_AWAITING_RESOLUTION:
    'PACKAGE_STATUS_CODE_AWAITING_RESOLUTION',
  PACKAGE_STATUS_CODE_READY_TO_RESEND: 'PACKAGE_STATUS_CODE_READY_TO_RESEND',
  PACKAGE_STATUS_CODE_READY_TO_RETURN: 'PACKAGE_STATUS_CODE_READY_TO_RETURN',
  PACKAGE_STATUS_CODE_CUSTOMER_PICKUP: 'PACKAGE_STATUS_CODE_CUSTOMER_PICKUP',
  PACKAGE_STATUS_CODE_CONFIRMED: 'PACKAGE_STATUS_CODE_CONFIRMED',
  PACKAGE_STATUS_CODE_TRANSFER_COMPLETED:
    'PACKAGE_STATUS_CODE_TRANSFER_COMPLETED',
  PACKAGE_STATUS_CODE_UNAVAILABLE: 'PACKAGE_STATUS_CODE_UNAVAILABLE'
};

export const TRANSPORT_TYPES = {
  CAR: 'Carro',
  MOTO: 'Moto'
};

export const SERVICE_TYPE = {
  NUVEM: 'Nuvem',
  PARCEIROS: 'Parceiros'
};

export const VEHICLE_TYPE = {
  CAR: 'Carro',
  MOTO: 'Moto'
};
