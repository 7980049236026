import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RECEIVE_TRANSFER_PAGE_STATE } from '../../constants';

export const ReceivingProcessContext = React.createContext();

/**
 * The states marked as DEPRECATED are still being used, but if you have the
 * opportunity to refactor any of the existing receive flows, please replace
 * these DEPRECATED states with the new ones.
 *
 * | Deprecated states |      New states      |
 * | ----------------- | -------------------- |
 * | packToReceive     | unitLoadToReceive    |
 * | packageBarcode    | unitLoadLicensePlate |
 * | senderPackages    | incomingUnitLoads    |
 * | returnList        | receivedUnitLoads    |
 */
export const ReceivingProcessProvider = ({ children, initialState }) => {
  // Destination UL where the package will be moved destination
  const [destination, setDestination] = useState(null);
  // Errors for API requests
  const [errorMessage, setErrorMessage] = useState('');
  // Waiting for API requests
  const [loading, setLoading] = useState(false);
  // [DEPRECATED: use unitLoadToReceive] Current package
  const [packToReceive, setPackToReceive] = useState(
    initialState.packToReceive || null
  );
  // [DEPRECATED: use unitLoadLicensePlate] Current barcode
  const [packageBarcode, setPackageBarcode] = useState(
    initialState.packageBarcode || ''
  );
  // Sender information
  const [sender, setSender] = useState(initialState.sender || null);
  // [DEPRECATED: use incomingUnitLoads] Array of incomining packages
  const [senderPackages, setSenderPackages] = useState(
    initialState.senderPackages || []
  );
  // Used to show move destination screen
  const [shouldFinish, setShouldFinish] = useState(false);
  // Used to show move destination screen
  const [shouldMove, setShouldMove] = useState(false);
  // A toggle for sender details
  const [shouldShowSender, setShouldShowSender] = useState(false);
  // For packages with itinerary, incomplete when received less than the total
  const [incompleteReceive, setIncompleteReceive] = useState(false);
  // A string with the reason for the return (currently used for redispatch)
  const [shouldSelectCause, setShouldSelectCause] = useState(
    initialState.shouldSelectCause || false
  );
  // A string with the reason for the return (currently used for redispatch)
  const [returnCause, setReturnCause] = useState(null);
  // Indicates whether the packages are supposed to have itinerary or not
  const [packagesHaveItinerary, setPackagesHaveItinerary] = useState(
    initialState.packagesHaveItinerary || false
  );
  // The origin of the packages returned by ReceiveInfo API
  const [packagesOrigin, setPackagesOrigin] = useState(
    initialState.packagesOrigin || ''
  );
  // The origin of the packages returned by ReceiveInfo API
  const [lastPackageOrigin, setLastPackageOrigin] = useState(
    initialState.lastPackageOrigin || ''
  );
  // The current sorting context
  const [sortingContext, setSortingContext] = useState(
    initialState.sortingContext || null
  );

  const [receiveSortingContext, setReceiveSortingContext] = useState(
    initialState.receiveSortingContext || null
  );

  // Indicates the current package is from another sender
  const [anotherSenderReceive, setAnotherSenderReceive] = useState(false);
  // This is a count of valid packages that were received
  const [receivedSenderPackages, setReceivedSenderPackages] = useState(0);
  // [DEPRECATED: use receivedUnitLoads] This list contains all package that were returned
  // (even those from another sender)
  const [returnList, setReturnList] = useState([]);

  // Current unit load to receive
  const [unitLoadToReceive, setUnitLoadToReceive] = useState(
    initialState.unitLoadToReceive || null
  );
  // Current unit load license plate
  const [unitLoadLicensePlate, setUnitLoadLicensePlate] = useState(
    initialState.unitLoadLicensePlate || ''
  );
  // Array of incomining unitloads
  const [incomingUnitLoads, setIncomingUnitLoads] = useState(
    initialState.incomingUnitLoads || []
  );
  // This list contains all unitloads that were received (even those from another sender)
  const [receivedUnitLoads, setReceivedUnitLoads] = useState(
    initialState.receivedUnitLoads || []
  );
  // A string to help redirect to the correct page in the workflow
  const [pageState, setPageState] = useState(
    RECEIVE_TRANSFER_PAGE_STATE.BEEP_UNITLOADS
  );

  const [recommendationsDict, setRecommendationsDict] = useState({});

  const setToInitialState = () => {
    setDestination(null);
    setErrorMessage('');
    setLoading(false);
    setPackToReceive(null);
    setPackageBarcode('');
    setSender(null);
    setSenderPackages([]);
    setShouldFinish(false);
    setShouldMove(false);
    setShouldShowSender(false);
    setIncompleteReceive(false);
    setShouldSelectCause(false);
    setReturnCause(null);
    setPackagesHaveItinerary(false);
    setPackagesOrigin('');
    setLastPackageOrigin('');
    setSortingContext(null);
    setReceiveSortingContext(null);
    setAnotherSenderReceive(false);
    setReceivedSenderPackages(0);
    setReturnList([]);
    setUnitLoadLicensePlate('');
    setIncomingUnitLoads([]);
    setReceivedUnitLoads([]);
    setPageState(RECEIVE_TRANSFER_PAGE_STATE.BEEP_UNITLOADS);
    setRecommendationsDict({});
  };

  return (
    <ReceivingProcessContext.Provider
      value={{
        anotherSenderReceive,
        setAnotherSenderReceive,
        destination,
        setDestination,
        errorMessage,
        setErrorMessage,
        packageBarcode,
        setPackageBarcode,
        loading,
        setLoading,
        packToReceive,
        setPackToReceive,
        receivedSenderPackages,
        setReceivedSenderPackages,
        returnList,
        setReturnList,
        sender,
        setSender,
        senderPackages,
        setSenderPackages,
        shouldFinish,
        setShouldFinish,
        shouldMove,
        setShouldMove,
        shouldShowSender,
        setShouldShowSender,
        incompleteReceive,
        setIncompleteReceive,
        shouldSelectCause,
        setShouldSelectCause,
        returnCause,
        setReturnCause,
        packagesHaveItinerary,
        setPackagesHaveItinerary,
        packagesOrigin,
        setPackagesOrigin,
        lastPackageOrigin,
        setLastPackageOrigin,
        sortingContext,
        setSortingContext,
        receiveSortingContext,
        setReceiveSortingContext,
        setToInitialState,
        unitLoadToReceive,
        setUnitLoadToReceive,
        unitLoadLicensePlate,
        setUnitLoadLicensePlate,
        incomingUnitLoads,
        setIncomingUnitLoads,
        receivedUnitLoads,
        setReceivedUnitLoads,
        pageState,
        setPageState,
        recommendationsDict,
        setRecommendationsDict
      }}
    >
      {children}
    </ReceivingProcessContext.Provider>
  );
};

ReceivingProcessProvider.defaultProps = {
  initialState: {}
};

ReceivingProcessProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialState: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    packToReceive: PropTypes.object,
    packageBarcode: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    sender: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    senderPackages: PropTypes.array,
    shouldSelectCause: PropTypes.bool,
    packagesHaveItinerary: PropTypes.bool,
    packagesOrigin: PropTypes.string,
    lastPackageOrigin: PropTypes.string,
    sortingContext: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    receiveSortingContext: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    unitLoadToReceive: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    unitLoadLicensePlate: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    incomingUnitLoads: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    receivedUnitLoads: PropTypes.array
  })
};
