export const DECLARE_DAMAGE_STATUS_CLOSED = [
  'Solicitação Reprovada',
  'Não consertaremos',
  'Informação Não Recebida'
];

export const DECLARE_DAMAGE_STATUS_SOLVED = 'Resolvido';

export const DECLARE_DAMAGE_LOCATION = [
  { label: '', value: '' },
  {
    label: 'Avaria após Entrega do pacote',
    value: 'Avaria após Entrega do pacote'
  },
  {
    label: 'Avaria em Agência',
    value: 'Avaria em Agência'
  },
  {
    label: 'Avaria em Coleta',
    value: 'Avaria em Coleta'
  },
  {
    label: 'Avaria em Leve',
    value: 'Avaria em Leve'
  },
  {
    label: 'Avaria em Rota de Entrega',
    value: 'Avaria em Rota de Entrega'
  },
  {
    label: 'Avaria em Transferências',
    value: 'Avaria em Transferências'
  },
  {
    label: 'Avaria em XD',
    value: 'Avaria em XD'
  }
];

export const DECLARE_DAMAGE_CAUSE = [
  { label: '', value: '' },
  {
    label: 'Aberto durante processo de acareação',
    value: 'Aberto durante processo de acareação'
  },
  {
    label: 'Acidente - Notificado via GR',
    value: 'Acidente - Notificado via GR'
  },
  { label: 'Embalagem inadequada', value: 'Embalagem inadequada' },
  { label: 'Pacote violado', value: 'Pacote violado' },
  {
    label: 'Problemas no armazenamento/manuseio',
    value: 'Problemas no armazenamento/manuseio'
  },
  { label: 'Processamento no sorter', value: 'Processamento no sorter' },
  { label: 'Sobreposição de status', value: 'Sobreposição de status' },
  {
    label: 'Vazamento de outro pacote/saca',
    value: 'Vazamento de outro pacote/saca'
  }
];

export const DECLARE_DAMAGE_PHYSICAL_STATE = [
  { label: '', value: '' },
  { label: 'Aberto', value: 'Aberto' },
  { label: 'Amassado', value: 'Amassado' },
  { label: 'Embalagem Vazia', value: 'Embalagem Vazia' },
  { label: 'Molhado', value: 'Molhado' },
  { label: 'Produto Quebrado', value: 'Produto Quebrado' },
  { label: 'Rasgado', value: 'Rasgado' }
];

export const DECLARE_DAMAGE_FRIENDLY_ERROR_MESSAGE = [
  {
    original: 'Ticked already created to this Package ID',
    friendly: 'Já existe uma declaração de avaria para esse pacote'
  }
];

export const DECLARE_DAMAGE_STEPS = {
  PREVIEW: 'PREVIEW',
  DAMAGE_DECLARATION: 'DAMAGE_DECLARATION',
  PHOTO_CAPTURE: 'PHOTO_CAPTURE'
};

export const TESTID = {
  DAMAGE_PHOTO_TITLE: 'damage-photo-title',
  DAMAGE_PHOTO_DESCRIPTION: 'damage-photo-description',
  DAMAGE_PHOTO_CONTAINER: 'damage-photo-camera-container',
  DECLARE_DAMAGE_ADVANCE_BUTTON: 'declare-damage-advance-button',
  DECLARE_DAMAGE_NEXT_FORM: 'declare-damage-next-form',
  DECLARE_DAMAGE_PROCESS_LOCATION: 'declare-damage-process-location',
  DECLARE_DAMAGE_CAUSE: 'declare-damage-cause',
  DECLARE_DAMAGE_PHYSICAL_DAMAGE: 'declare-damage-physcal-damage',
  DECLARE_DAMAGE_TEXT: 'declare-damage-text',
  DECLARE_DAMAGE_SEND: 'declare-damage-send',
  DECLARE_DAMAGE_BACK: 'declare-damage-back',
  DECLARE_DAMAGE_ERROR: 'declare-damage-error',
  DECLARE_DAMAGE_REMOVE_PHOTO: 'declare-damage-remove-photo',
  DECLARE_DAMAGE_LOADING: 'declare-damage-loading'
};

export const TEXT = {
  TITLE: 'Está danificado',
  JIRA_CREATED_WITH_SUCCESS: 'Boa! Ticket do Jira: ',
  JIRA_ALREADY_EXISTS: 'Este pacote possui um ticket no JIRA',
  JIRA: 'JIRA: ',
  STATUS: 'STATUS: ',
  JIRA_BROWSER_URL: 'https://loggidev.atlassian.net/browse/',
  PHOTO_CAPTURE_TITLE: 'Fotos do pacote',
  PHOTO_CAPTURE_DESCRIPTION: 'Tire fotos nítidas da avaria do pacote.',
  PHOTO_CAPTURE_IMG_ALT: 'Foto do pacote avariado',
  DECLARATION_LOCATION: 'Em qual processo foi identificada?',
  DECLARATION_CAUSE: 'Qual a causa da avaria?',
  DECLARATION_PHYSICAL_STATUS: 'Qual o estado atual do pacote?',
  DECLARATION_OPEN_TEXT: 'Qual a avaria do produto?',
  FORWARD: 'Avançar',
  BACK: 'Voltar',
  TRY_AGAIN: 'Tentar Novamente',
  SEND: 'Enviar'
};
