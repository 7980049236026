import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from '@material-ui/icons';
import { colors } from '@loggi/mar';

export const driverShape = PropTypes.shape({
  driverId: PropTypes.number,
  fullName: PropTypes.string,
  photoUrl: PropTypes.string,
  docCpf: PropTypes.string
});

const useStyles = makeStyles(theme => ({
  avatarLarge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.getContrastText(colors.root[0]),
    borderStyle: 'solid',
    borderWidth: 'thin',
    backgroundColor: 'transparent',
    fontWeight: 'bold'
  }
}));

export const DriverList = ({
  driverList,
  selectDriver,
  selectedDriver,
  onClickAvatar,
  selectedAvatar
}) => {
  const classes = useStyles();

  return (
    <List>
      {driverList.map(driver => (
        <ListItem
          button
          divider
          dense
          disableGutters
          data-testid="list-drivers"
          key={driver.driverId}
        >
          <Grid item xs={2.5}>
            <Box
              my={1.5}
              pl={3}
              data-testid="driver-avatar"
              onClick={() => onClickAvatar(driver)}
              selected={selectedAvatar === driver}
            >
              <ListItemAvatar>
                <Avatar
                  className={classes.avatarLarge}
                  src={driver.photoUrl?.profileHighRes}
                >
                  {driver.fullName ? driver.fullName[0] : undefined}
                </Avatar>
              </ListItemAvatar>
            </Box>
          </Grid>
          <Box pl={1}>
            <ListItemText
              button
              key={driver.driverId}
              data-testid="drivers-list"
              primary={<Box fontWeight="fontWeightBold">{driver.fullName}</Box>}
              onClick={() => selectDriver(driver)}
              selected={selectedDriver === driver}
            />
            {selectedDriver === driver && (
              <ListItemSecondaryAction>
                <CheckCircle style={{ color: colors.green[500] }} />
              </ListItemSecondaryAction>
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

DriverList.defaultProps = {
  selectedDriver: null,
  selectedAvatar: null
};

DriverList.propTypes = {
  driverList: PropTypes.arrayOf(driverShape).isRequired,
  selectDriver: PropTypes.func.isRequired,
  selectedDriver: PropTypes.arrayOf(driverShape),
  onClickAvatar: PropTypes.func.isRequired,
  selectedAvatar: PropTypes.arrayOf(driverShape)
};
