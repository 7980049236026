import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import * as Sentry from '@sentry/browser';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}));

const videoConstraints = {
  facingMode: 'environment'
};
const videoElementId = 'videoPhoto';

export default function CameraPhoto({ videoReference, visible }) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('');
  const onUserMediaError = error => {
    const errorMsg = `camera load error: ${error} `;
    Sentry.captureException(errorMsg);
    setErrorMessage(errorMsg);
  };
  const PERMISSION_GUIDE_TITLE = `Esse erro geralmente acontece por falta de permissão do uso da câmera. Tente os seguintes passos:`;
  const PERMISSION_GUIDE = `À direita da barra de endereço do Chrome, toque nos três pontos
  → Configurações → Configurações do site → Câmera →ativar a
  câmera.`;
  return (
    <Box className={classes.video}>
      {errorMessage && (
        <Box my={1.5}>
          <Alert severity="error">
            <b>{PERMISSION_GUIDE_TITLE}</b>
            <li>{PERMISSION_GUIDE}</li>
            {errorMessage}
          </Alert>
        </Box>
      )}
      <Webcam
        data-testid="webcam-component-test"
        style={{ display: visible ? 'block' : 'none' }}
        ref={videoReference}
        forceScreenshotSourceSize
        screenshotFormat="image/jpeg"
        id={videoElementId}
        className={classes.video}
        onUserMediaError={onUserMediaError}
        audio={false}
        videoConstraints={videoConstraints}
      />
    </Box>
  );
}

CameraPhoto.propTypes = {
  videoReference: PropTypes.shape().isRequired,
  visible: PropTypes.bool.isRequired
};
