import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

/**
 * Toast renders a toast notification that will be
 * used inside notistack context
 * @componentType presentational
 * @param id
 * @param message
 * @param type
 * @returns {*}
 * @constructor
 */
const Toast = forwardRef((props, ref) => {
  const { id, message, type, onButtonClick, buttonText } = props;
  const { closeSnackbar } = useSnackbar();
  const showButtonText = !!buttonText;

  return (
    <Alert
      style={{ alignItems: 'center' }}
      action={
        <Button
          data-testid="close-toast"
          color="primary"
          onClick={() => {
            if (onButtonClick) {
              onButtonClick();
            }

            closeSnackbar(id);
          }}
        >
          {!showButtonText && <CloseIcon color="primary" fontSize="large" />}
          {showButtonText && buttonText}
        </Button>
      }
      data-testid={`toast-${type}`}
      ref={ref}
      severity={type}
      variant="outlined"
    >
      {message}
    </Alert>
  );
});

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string
};

Toast.defaultProps = {
  onButtonClick: null,
  buttonText: ''
};

export default Toast;
