import { pxToRem } from '@loggi/mar/src/utils';
import {
  Avatar,
  Box,
  CardContent,
  Divider,
  GridList,
  GridListTile,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import BasicChip from './basic-chip';

const DriverInfo = ({
  photoUrl,
  fullName,
  cnh,
  rg,
  cpf,
  needOnboardingInstructions
}) => {
  return (
    <Box>
      <Divider />
      <CardContent>
        <GridList cellHeight="auto" cols={5}>
          <GridListTile cols={2}>
            <Avatar
              style={{
                width: pxToRem(100),
                height: pxToRem(100)
              }}
              src={photoUrl}
            />
          </GridListTile>
          <GridListTile cols={3}>
            <Typography variant="caption">Entregador</Typography>
            <Typography variant="body1">{fullName}</Typography>
            {needOnboardingInstructions ? (
              <Box>
                <BasicChip message="Novo" />
              </Box>
            ) : (
              <Box mb={1} fontWeight="fontWeightRegular">
                mais de 3 rotas
              </Box>
            )}
            {cnh && <Typography variant="body2">CNH: {cnh}</Typography>}
            {rg && <Typography variant="caption">RG: {rg}</Typography>}
            {cpf && (
              <Typography style={{ display: 'block' }} variant="caption">
                CPF: {cpf}
              </Typography>
            )}
          </GridListTile>
        </GridList>
      </CardContent>
    </Box>
  );
};

DriverInfo.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  cnh: PropTypes.string,
  rg: PropTypes.string,
  cpf: PropTypes.string,
  needOnboardingInstructions: PropTypes.bool.isRequired
};

DriverInfo.defaultProps = {
  cnh: null,
  rg: null,
  cpf: null
};

export default DriverInfo;
