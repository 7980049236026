import React from 'react';

/**
 * Declarative approach to setInterval.
 * Ref: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
// eslint-disable-next-line func-names
export default function(callback, delay) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);

  return savedCallback;
}
