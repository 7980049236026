export const ERRORS = {
  MULTIPLE_SUMMARIES:
    'Template component must receive only one child of TemplateSummary type',
  NO_SUMMARY: 'Template component must receive a TemplateSummary as child',
  UNSUPPORTED_CHILD: 'An unsupported child was used in Template'
};

export const FOOTER_TEXTS = {
  TERMS_URL: 'https://www.loggi.com/termos-condicoes-clientes-loggi-envios/',
  TERMS_DESCRIPTION: 'Termos e Condições de uso',
  COPY_RIGHT_LOGGI: '© Copyright 2022 - Loggi'
};

export default {
  ERRORS
};
