import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Container } from '@material-ui/core';
import SortingContextReader from '../../app/components/sorting-context-reader';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { ACTIVITY, OPERATIONAL_PROCESS } from '../../constants';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';

export default function ContextReader() {
  const history = useHistory();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const { enableInferDistributeSortingContext } = React.useContext(
    FeatureSwitchContext
  );

  const handleRestContextRead = async sorting => {
    history.push({
      pathname: '/distribute/bag-reader',
      state: { contextLicensePlate: sorting.licensePlate }
    });
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.CARA_CRACHA);
    if (
      enableInferDistributeSortingContext === 'true' &&
      selectedDistributionCenter
    ) {
      history.push({
        pathname: '/distribute/bag-reader',
        state: {
          contextLicensePlate: `${selectedDistributionCenter.routingCode} DIS`
        }
      });
    } else {
      trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_CONTEXT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistributionCenter]);

  return (
    <Box>
      <Container maxWidth="xs">
        <Box>
          <Typography component="div" variant="body1" gutterBottom>
            <Box fontWeight="fontWeightBold">Distribuir</Box>
          </Typography>
          <Box mt={1.5}>
            <Typography variant="h5">
              Para começar, bipe o código da operação
            </Typography>
          </Box>
        </Box>
        <SortingContextReader
          onRead={handleRestContextRead}
          placeholder="Leia o código da operação"
        />
      </Container>
    </Box>
  );
}
