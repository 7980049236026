import {
  Box,
  ButtonBase,
  ListItem,
  List,
  ListSubheader,
  TextField,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import React from 'react';
import PropTypes from 'prop-types';
import { dcShape, DistributionCenterList } from './distribution-center-list';
import { ReactComponent as EmptyList } from '../../assets/images/empty-dc-list.svg';

const useStyles = makeStyles(({ spacing }) => ({
  cancelButton: {
    color: colors.blue[500],
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(2),
    width: '100%'
  },
  root: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    maxHeight: '85vh',
    width: '100%'
  },
  flexBody: {
    flex: '1',
    overflowY: 'auto'
  },
  flexBottom: {
    flex: '0 1 auto'
  },
  large: {
    height: pxToRem(100)
  },
  searchTip: {
    fontWeight: 400,
    color: colors.smoke[700]
  }
}));

const DistributionCenterSearch = ({
  distributionCenterList,
  selectDC,
  currentDc,
  cancel
}) => {
  const classes = useStyles();
  const [shownList, setShownList] = React.useState([]);

  const searchDCs = value => {
    const foundDCs = value
      ? distributionCenterList.filter(dc => {
          return (
            dc.routingCode?.toLowerCase().includes(value.toLowerCase()) ||
            dc.distributionCenterName
              ?.toLowerCase()
              .includes(value.toLowerCase())
          );
        })
      : [];
    setShownList(foundDCs);
  };

  return (
    <Box
      className={classes.root}
      pb={2}
      data-testid="distribution-center-search"
    >
      <Box height="25px" mb={1}>
        <List>
          <ListSubheader disableSticky>Veja outra base</ListSubheader>
        </List>
      </Box>

      <ListItem>
        <Box
          width="300px"
          borderRadius={8}
          display="flex"
          justifyContent="center"
          mt={1}
          pt={1}
          pb={1}
        >
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            autoFocus
            placeholder="Busque por sigla, região ou nome da base"
            InputProps={{
              inputProps: {
                'data-testid': 'input-search'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon size="small" color="disabled" />
                </InputAdornment>
              )
            }}
            onChange={event => searchDCs(event.target.value)}
          />
        </Box>
      </ListItem>
      <Box
        className={classes.searchTip}
        justifyContent="center"
        alignItems="center"
        display="flex"
        variant="body2"
      >
        Busque por sigla, região ou nome da base
      </Box>
      <Box className={classes.flexBody}>
        {shownList.length ? (
          <DistributionCenterList
            distributionCenterList={shownList}
            selectedId={currentDc?.distributionCenterId}
            selectDC={selectDC}
          />
        ) : (
          <Box align="center" pb={2} pt={4}>
            <EmptyList className={classes.large} />
          </Box>
        )}
      </Box>
      <Box className={classes.flexBotton}>
        <ButtonBase
          alignitems="center"
          className={classes.cancelButton}
          data-testid="cancel-btn"
          variant="button"
          onClick={cancel}
        >
          Cancelar
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default DistributionCenterSearch;

DistributionCenterSearch.propTypes = {
  distributionCenterList: PropTypes.arrayOf(dcShape),
  selectDC: PropTypes.func.isRequired,
  currentDc: dcShape.isRequired,
  cancel: PropTypes.func.isRequired
};

DistributionCenterSearch.defaultProps = {
  distributionCenterList: []
};
