import { declarePackageDamage, getDeclarePackageDamage } from '../../api-rest';
import handleRestAPIError from '../../app/utils/rest-api-request';

const declareDamage = async ({
  packageId,
  damageDescription,
  imagesData,
  damageCause,
  physicalDamage,
  processLocation,
  distributionCenterId,
  errorHandler
}) => {
  const response = await declarePackageDamage(
    packageId,
    damageDescription,
    imagesData,
    damageCause,
    physicalDamage,
    processLocation,
    distributionCenterId
  ).catch(err => handleRestAPIError(err, errorHandler));

  if (!response) return null;

  return {
    success: Boolean(response.data.success),
    message: response.data.message
  };
};

const getDeclareDamage = async ({ packageId, errorHandler }) => {
  const response = await getDeclarePackageDamage(packageId).catch(err =>
    handleRestAPIError(err, errorHandler)
  );

  if (!response) return null;
  return response.data;
};

export { declareDamage, getDeclareDamage };
