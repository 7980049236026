import React, { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TEXT, TESTID, DECLARATION_RESULT } from './constants';
import TextWithBoldEmphasis from '../../../app/components/text-with-bold-emphasis';

const useDialogStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      height: '96%',
      maxHeight: '100%'
    },
    width: '100vw'
  }
}));

export default function DamagedPackageDeclarationDialog({ declarationResult }) {
  const history = useHistory();
  const handleGoBack = useCallback(() => history.push('/identify'), [history]);
  const Transition = forwardRef(function Transition(props, ref) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const { paper } = useDialogStyles();

  const firstTextMessage =
    TEXT.DAMAGED_PACKAGE_REQUEST_CREATED_MESSAGE[declarationResult];

  return (
    <Dialog
      onClose={handleGoBack}
      data-testid={TESTID.DAMAGED_PACKAGE_DECLARATION_DIALOG}
      TransitionComponent={Transition}
      classes={{ paper }}
      open
      fullWidth
    >
      <DialogContent>
        <Box pt={1}>
          <TextWithBoldEmphasis
            text={firstTextMessage.TEXT}
            emphasis={firstTextMessage.EMPHASIS}
            typographyVariant="subtitle1"
          />
          <Typography variant="subtitle1">
            {TEXT.DAMAGED_PACKAGE_REQUEST_CREATED_MESSAGE_2}
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="subtitle2">
            {TEXT.DAMAGED_PACKAGE_REQUEST_CREATED_MESSAGE_3}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            data-testid={TESTID.DAMAGED_PACKAGE_DECLARATION_DIALOG_CLOSE_BUTTON}
            fullWidth
          >
            {TEXT.CLOSE}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

DamagedPackageDeclarationDialog.propTypes = {
  declarationResult: PropTypes.string
};

DamagedPackageDeclarationDialog.defaultProps = {
  declarationResult:
    DECLARATION_RESULT.DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_DAMAGED
};
