const initials = name => {
  if (!name) return undefined;

  const arr = name.split(' ');
  if (arr.length > 1) {
    return (arr[0][0] + arr[1][0]).toUpperCase();
  }

  return name[0];
};

export default initials;
