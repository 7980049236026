import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { colors } from '@loggi/mar';

/**
 * This adds a gradient box to the bottom of the page.
 * You can place the tag you need inside this box to use the gradient style bottom box.
 *
 * tip: you can see the actions-container.story file for an example.
 *
 * @children tag to be render inside the box
 */

const SMOKE_50_GRADIENT = `linear-gradient(to bottom, rgba(245,248,250, 0) 0%, ${
  colors.smoke[50]
} 25%)`;
const WHITE_GRADIENT = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,  ${
  colors.root[0]
} 25%`;

const ActionContainerBottom = ({ children, enableSmokeBackground }) => {
  return (
    <Box
      data-testid="gradient-bottom-box"
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      style={{
        background: enableSmokeBackground ? SMOKE_50_GRADIENT : WHITE_GRADIENT
      }}
    >
      <Box pt={3}>{children}</Box>
    </Box>
  );
};

ActionContainerBottom.propTypes = {
  children: PropTypes.element.isRequired,
  enableSmokeBackground: PropTypes.bool
};

ActionContainerBottom.defaultProps = {
  enableSmokeBackground: false
};

export default ActionContainerBottom;
