import React from 'react';
import SnackbarMessage from './snackbar-component';

/**
 * @param {Object} options
 * @param {boolean} options.persist - should disable the "auto hide" behavior
 * @param {boolean} options.closeOnClickAnywhereOnScreen
 *  The default behavior from snackbar is to dissmiss/close
 *  if the user makes any interaction anywhere on the screen. This boolean, when false, should disable this behavior
 *  and only close the snackbar when the user press/click the CloseIcon button
 */
const showSnackbar = ({
  message,
  variant,
  showCloseButton,
  enqueueSnackbar,
  onEnter = () => {},
  preventDuplicate = false,
  persist = false,
  closeOnClickAnywhereOnScreen = true
}) => {
  enqueueSnackbar(message, {
    content: (key, msg) => (
      <SnackbarMessage
        open
        id={key}
        message={msg}
        variant={variant}
        showCloseButton={showCloseButton}
        closeOnClickAnywhereOnScreen={closeOnClickAnywhereOnScreen}
      />
    ),
    onEnter,
    preventDuplicate,
    persist
  });
};

export default showSnackbar;
