import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button, Box, Container, Dialog, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import HeaderWithCancel from '../../app/components/header-with-cancel';

import AsyncButton from '../../app/components/async-button';
import PackageList from '../../app/components/package-list';
import showSnackbar from '../../app/components/snackbar/snackbar-container';

import SortPackageDialog from './sort-package-dialog';
import DistributePackageList from './distribute-package-list';
import SharedPropTypes from '../../app/shared-prop-types';
import pluralize from '../../app/utils/pluralize';
import handleRestAPIError from '../../app/utils/rest-api-request';
import { getOrCreateUnitLoad } from '../../api-rest';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  UnitLoadExtraInfo
} from '../../constants';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    height: '100vh'
  },
  dialog: {
    padding: 0
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  flexHeader: {
    flex: '1'
  },
  flexBody: {
    flex: '1',
    overflowY: 'auto'
  },
  flexBottom: {
    flex: '0 1 auto'
  }
});

export default function DistributeWaitDriver({
  checkedPackages,
  numPackages,
  skipPackagesChecks,
  destinationUnitLoadLpn,
  skipConferenceClicked
}) {
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [packages, setPackages] = React.useState(checkedPackages);
  const [openSortPackageDialog, setOpenSortPackageDialog] = React.useState(
    false
  );
  const [shouldSortAllPackages, setShouldSortAllPackages] = React.useState(
    false
  );

  const { showPackagePositionOnDistribute } = React.useContext(
    FeatureSwitchContext
  );

  const showClosingSnackBar = (variant, message) => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const showErrorSnackBar = message => {
    showClosingSnackBar('error', message);
  };

  const handleCancel = () => {
    setShouldSortAllPackages(true);
    setOpenSortPackageDialog(true);
    showSnackbar({
      variant: 'warning',
      message: 'Você ainda tem pacotes para armazenar',
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleCloseSortPackagesDialog = () => {
    setShouldSortAllPackages(false);
    setOpenSortPackageDialog(false);
  };

  const goBackDistributePage = () => {
    trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.WAITING_FOR_DRIVER);
    trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    history.push('/distribute');
  };

  const handleRemovePackages = updatedPackageList => {
    trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.REJECTED_PACKAGE_BEEP);
    trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.REJECTED_PACKAGE_BEEP);
    setPackages(updatedPackageList);
  };

  const fetchUnitLoadExtraInfo = async () => {
    const unitLoadResponse = await getOrCreateUnitLoad(
      destinationUnitLoadLpn,
      ''
    ).catch(err => handleRestAPIError(err, showErrorSnackBar));

    if (!unitLoadResponse) {
      return null;
    }

    const { error, extraInfo } = unitLoadResponse.data;

    if (error) {
      showClosingSnackBar('error', error);
      return null;
    }

    return extraInfo;
  };

  const handleDriverAccepted = async () => {
    const unitLoadExtraInfo = await fetchUnitLoadExtraInfo();

    if (!unitLoadExtraInfo) return;

    if (unitLoadExtraInfo === UnitLoadExtraInfo.UNIT_LOAD_EXTRA_INFO_IS_EMPTY) {
      trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.WAITING_FOR_DRIVER);
      trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.FULL_PROCESS);
      showClosingSnackBar(
        'success',
        'Pronto, tudo certo. Os pacotes foram distribuídos.'
      );
      history.goBack();
    } else {
      showClosingSnackBar('warning', 'Entregador ainda não aceitou levar');
    }
  };

  const startRejectedPackageBeeping = () => {
    trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.REJECTED_PACKAGE_BEEP);
    setOpenSortPackageDialog(true);
  };

  return (
    <Dialog
      open
      fullScreen
      data-testid="waiting-driver-response-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
    >
      <Container maxWidth="xs" className={classes.root} justify="center">
        <Box className={classes.flexBox}>
          <Box className={classes.flexHeader}>
            <Box paddingTop={2.5}>
              <HeaderWithCancel
                testId="distribute-wait-driver-cancel-button"
                handleClose={handleCancel}
              />
            </Box>
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Distribuir</Box>
              </Typography>
            </Box>
            {skipPackagesChecks ? (
              <>
                <Box display="flex" paddingTop={1.5}>
                  <Typography
                    color="secondary"
                    variant="h3"
                    data-testid="wait-driver-checked-packages-count"
                  >
                    1
                  </Typography>
                </Box>
                <Typography variant="body1">Unidade</Typography>
              </>
            ) : (
              <>
                <Box display="flex" paddingTop={1.5}>
                  <Typography
                    color="secondary"
                    variant="h3"
                    data-testid="wait-driver-checked-packages-count"
                  >
                    {packages.length}
                  </Typography>
                  <Typography variant="h3">&nbsp;de {numPackages}</Typography>
                </Box>
                <Typography variant="body1">
                  {`${pluralize({
                    singular: 'pacote',
                    count: numPackages.length
                  })}`}
                </Typography>
              </>
            )}

            <Box paddingTop={1.5}>
              {skipPackagesChecks ? (
                <Typography variant="h6">
                  Aguarde o aceite do entregador para confirmar.
                </Typography>
              ) : (
                <Typography variant="h6">
                  Aguarde a revisão e o aceite do entregador para confirmar.
                </Typography>
              )}
            </Box>
          </Box>
          {!skipPackagesChecks && (
            <Box my={2} className={classes.flexBody}>
              {showPackagePositionOnDistribute === 'true' &&
              !skipConferenceClicked ? (
                <DistributePackageList units={packages} />
              ) : (
                <PackageList packages={packages} />
              )}
            </Box>
          )}
          <Box className={classes.flexBottom}>
            <Box mb={1.5}>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary"
                data-testid="driver-refused-packages-button"
                onClick={startRejectedPackageBeeping}
              >
                Recusou levar
              </Button>
            </Box>
            <Box mb={1.5}>
              <AsyncButton
                fullWidth
                data-testid="driver-accepted-packages-button"
                size="large"
                variant="contained"
                color="primary"
                onClick={handleDriverAccepted}
              >
                Aceitou levar
              </AsyncButton>
            </Box>
          </Box>

          {openSortPackageDialog && (
            <SortPackageDialog
              checkedPackages={packages}
              handleRemovePackages={handleRemovePackages}
              handleClose={handleCloseSortPackagesDialog}
              goBack={goBackDistributePage}
              shouldSortAllPackages={shouldSortAllPackages}
            />
          )}
        </Box>
      </Container>
    </Dialog>
  );
}

DistributeWaitDriver.defaultProps = {
  skipConferenceClicked: false
};

DistributeWaitDriver.propTypes = {
  checkedPackages: SharedPropTypes.packages.isRequired,
  numPackages: PropTypes.number.isRequired,
  skipPackagesChecks: PropTypes.bool.isRequired,
  destinationUnitLoadLpn: PropTypes.string.isRequired,
  skipConferenceClicked: PropTypes.bool
};
