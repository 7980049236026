import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@loggi/mar/src/utils';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import AsyncButton from '../../../app/components/async-button';
import RadioButtonList from '../../../app/components/radio-button-list';
import { playErrorBeep } from '../../../sounds';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import { ACTIVITY, OPERATIONAL_PROCESS } from '../../../constants';
import handleRestAPIError from '../../../app/utils/rest-api-request';

const useStyles = makeStyles(() => ({
  flexBody: {
    height: '40vh',
    padding: 0
  },
  flexHidden: {
    height: pxToRem(0),
    overflow: 'hidden'
  }
}));

export default function CauseSelection({ barcode, causes, onButtonClick }) {
  const [enableButton, setEnableButton] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [notes, setNotes] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const classes = useStyles();

  const handleOnButtonClick = async button => {
    const returnCause = notes
      ? `${selectedItem.text} - ${notes}`
      : `${selectedItem.text}`;
    const changeToStatus = selectedItem.status;
    await onButtonClick(button, returnCause, changeToStatus).catch(err => {
      handleRestAPIError(err, message => {
        showSnackbar({
          variant: 'error',
          message,
          showCloseButton: true,
          enqueueSnackbar,
          onEnter: () => playErrorBeep(),
          preventDuplicate: true
        });
      });
    });
  };

  const handleOnClickItem = item => {
    if (item !== selectedItem) {
      setNotes('');
    }

    setSelectedItem(item);
  };

  const handleOnChangeNotes = event => {
    setNotes(event.target.value);
  };

  const handleOnReadBarcode = () => {
    showSnackbar({
      variant: 'error',
      message: 'Eita, você ainda não selecionou o motivo de retorno.',
      showCloseButton: true,
      enqueueSnackbar,
      onEnter: () => playErrorBeep(),
      preventDuplicate: true
    });
  };

  const itemList = useMemo(
    () =>
      causes.map((item, index) => ({
        ...item,
        key: `${index}-${item.status}`
      })),
    [causes]
  );

  useEffect(() => {
    if (selectedItem) {
      const hasNotesOrDontRequireNotes = !!notes || !selectedItem?.expectNotes;
      setEnableButton(hasNotesOrDontRequireNotes);
    }
  }, [selectedItem, notes]);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.CAUSE_SELECTION);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.CAUSE_SELECTION);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      data-testid="cause-selection-main-box"
    >
      <Box flex="0 1 auto">
        <Box>
          <Typography variant="h5">Por que o pacote retornou?</Typography>
        </Box>
        <Box mt={1.5}>
          <Typography variant="subtitle2">#{barcode}</Typography>
        </Box>
      </Box>
      <Box my={3} flex="1 1 auto" overflow="auto" className={classes.flexBody}>
        <RadioButtonList
          itemList={itemList}
          selectedItemKey={selectedItem?.key}
          expectNotes={selectedItem?.expectNotes}
          notes={notes}
          onClickItem={handleOnClickItem}
          onChangeNotes={handleOnChangeNotes}
        />
      </Box>
      <Box flex="0 1 auto">
        <Box>
          <AsyncButton
            fullWidth
            data-testid="cause-selection-confirm-btn"
            size="large"
            variant="contained"
            color="primary"
            isDisabled={!enableButton}
            onClick={handleOnButtonClick}
          >
            Continuar
          </AsyncButton>
        </Box>
      </Box>
      <Box className={classes.flexHidden} data-testid="box-hidden-text-field">
        <TextField
          onChange={handleOnReadBarcode}
          autoFocus
          inputProps={{ 'data-testid': 'cause-selection-hidden-text-field' }}
        />
      </Box>
    </Box>
  );
}

const causePropType = PropTypes.shape({
  text: PropTypes.string,
  expectNotes: PropTypes.bool,
  status: PropTypes.string
});

CauseSelection.propTypes = {
  barcode: PropTypes.string.isRequired,
  causes: PropTypes.arrayOf(causePropType).isRequired,
  onButtonClick: PropTypes.func.isRequired
};
