/* eslint-disable react/jsx-props-no-spreading */
import { Box, Container, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Children } from 'react';

import { ERRORS } from './constants';
import OneTemplateContent from './one-template-content';
import OneTemplateSummary from './one-template-summary';
import OneTemplateFooter from './one-template-footer';

/**
 * This method receives the children param from a component and filter them
 * based on the component Class provided
 * @param children
 * @param Component
 * @returns {React.ReactChild[]}
 */
const filterChildren = (children, Component) =>
  Children.toArray(children).filter(({ type }) => type === Component);

const useGlobalStyles = makeStyles({
  '@global': {
    'html, body, #root': {
      height: '100%'
    }
  }
});

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    [breakpoints.only('xs')]: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    }
  }
}));

const useStylesForFooter = makeStyles(({ breakpoints }) => ({
  container: {
    [breakpoints.only('xs')]: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: 'transparent',
      position: 'relative',
      bottom: 0,
      padding: 40,
      height: 50
    }
  }
}));

/**
 * This component filter its child nodes, using only supported ones and
 * placing them into specific regions of the template.
 * @param children - Only a single <OneTemplateSummary /> and one or
 * more <OneTemplateContent /> child nodes
 * @returns {*}
 * @constructor
 */
const OneTemplate = ({ children, enableFooter, enableSummary, maxWidth }) => {
  let summary;
  const content = filterChildren(children, OneTemplateContent);

  useGlobalStyles();
  const styles = useStyles();
  const stylesForFooter = useStylesForFooter();

  const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));

  if (enableSummary) {
    summary = filterChildren(children, OneTemplateSummary);
    if (summary.length === 0) throw new Error(ERRORS.NO_SUMMARY);
    if (summary.length > 1) throw new Error(ERRORS.MULTIPLE_SUMMARIES);
  }

  Children.forEach(children, props => {
    // eslint-disable-next-line react/prop-types
    if (props?.type) {
      if (
        // eslint-disable-next-line react/prop-types
        props.type !== OneTemplateContent &&
        // eslint-disable-next-line react/prop-types
        props.type !== OneTemplateSummary
      ) {
        throw new Error(ERRORS.UNSUPPORTED_CHILD);
      }
    }
  });

  const additionalContainerProps = {
    className: styles.container,
    disableGutters: isMobile
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {summary}
      <Box clone mt={-8} pb={{ xs: 0, sm: 5.5 }}>
        {maxWidth ? (
          <Container
            {...additionalContainerProps}
            maxWidth={['xs', 'sm', 'md', 'lg', 'xl']}
          >
            {content}
          </Container>
        ) : (
          <Container {...additionalContainerProps}>{content}</Container>
        )}
      </Box>
      {enableFooter && (
        <Box clone mt={0} pb={{ xs: 0, sm: 5.5 }}>
          <Container
            className={stylesForFooter.container}
            disableGutters={isMobile}
          >
            <OneTemplateFooter />
          </Container>
        </Box>
      )}
    </Box>
  );
};

OneTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  enableFooter: PropTypes.bool,
  enableSummary: PropTypes.bool,
  maxWidth: PropTypes.bool
};

OneTemplate.defaultProps = {
  enableFooter: true,
  enableSummary: true,
  maxWidth: false
};

export default OneTemplate;
