import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { colors } from '@loggi/mar';

export default function PaperBox({ children }) {
  return (
    <Box
      style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
      borderColor={colors.smoke[100]}
      border={1}
      borderLeft={0}
      borderRight={0}
      borderBottom={0}
      borderTop={1}
      boxShadow={10}
      data-testid="paper-box"
    >
      {children}
    </Box>
  );
}

PaperBox.propTypes = {
  children: PropTypes.element.isRequired
};
