import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import 'moment/locale/pt-br';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/browser';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { ReceivingProcessContext } from './receiving-process-context';
import { ROUTES, SWITCHES } from '../../constants';
import { useFeature } from '../../app/hooks/use-feature';

import { getSortingContext as getSortingContextRest } from '../../api-rest';

export default function ReceiveContextReader() {
  /**
   * This component should be REMOVED and the routes should go
   * directly to RECEIVE_INITIAL_IDENTIFICATION
   */
  const {
    setSortingContext,
    setToInitialState,
    setReceiveSortingContext
  } = useContext(ReceivingProcessContext);
  const history = useHistory();

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const enableNewReceiveFlowWithSibling = useFeature(
    SWITCHES.enableNewReceiveFlowWithSibling
  );

  React.useEffect(() => {
    if (enableNewReceiveFlowWithSibling)
      getSortingContextRest(`${selectedDistributionCenter.routingCode} COL`)
        .then(response => {
          setReceiveSortingContext(response.data.sortingContext);
        })
        .catch(err => {
          Sentry.captureException(err);
        });

    setToInitialState();
    setSortingContext(`${selectedDistributionCenter.routingCode} COL`);
    history.push({
      pathname: ROUTES.RECEIVE_INITIAL_IDENTIFICATION
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistributionCenter]);

  return <CircularProgress />;
}
