import { fromKgToBrazilianText } from '../../../app/utils/br-locale-utils';

/**
 * Calculates the vehicle occupation percentage
 *
 * @param {Number} currentWeight
 * @param {Number} maxWeight
 * @returns {String} Vehicle occupation percentage
 */
const calculateTotalWeightPercentage = (currentWeight, maxWeight) => {
  if (!currentWeight || !maxWeight) return '0';
  return ((currentWeight / maxWeight) * 100).toFixed(0);
};

/**
 * Formats the transfer weight in trip (beeped and already in truck) and also
 * returns the limit and total percentage in brazilian text format
 *
 * @param {Number} weightInTripG weight in trip(beeped + in truck) g
 * @param {Number} tripMaxWeightKg maximum allowed weight in kg
 * @returns string in brazilian format containing the current weight,
 * max allowed in the trip and
 * occupation percentage of the vehicle(if max exists)
 */
export const formatTransferWeightText = (weightInTripG, tripMaxWeightKg) => {
  const weightInTripKg = weightInTripG / 1000 || 0;

  const currentWeightText = fromKgToBrazilianText(weightInTripKg);

  const transferPercentage = calculateTotalWeightPercentage(
    weightInTripKg,
    tripMaxWeightKg
  );

  if (transferPercentage !== '0' && tripMaxWeightKg) {
    const limitsText = ` / ${fromKgToBrazilianText(
      parseFloat(tripMaxWeightKg)
    )} - (${transferPercentage}%)`;
    return `${currentWeightText}${limitsText}`;
  }

  return currentWeightText;
};

export default { formatTransferWeightText };
