import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import SharedPropTypes from '../../app/shared-prop-types';

export default function DistributePackageList({ units }) {
  return (
    <List data-testid="distribute-package-list">
      {units.map((item, i) => (
        <DistributePackageItem
          key={item.identifier}
          packageItem={item}
          bottomDivider={units.length - 1 === i}
        />
      ))}
    </List>
  );
}

DistributePackageList.propTypes = {
  units: SharedPropTypes.packages.isRequired
};

export function DistributePackageItem({ packageItem, bottomDivider }) {
  const companyName =
    packageItem?.company?.name || packageItem?.company?.sharedName;

  return (
    <Box
      mb={bottomDivider ? 0 : 1}
      data-testid={`distribute-package-item-${packageItem.identifier}`}
    >
      <Divider />
      <Box mt={1} mb={bottomDivider ? 1 : 0} maxWidth={pxToRem(300)}>
        <ListItem style={{ paddingLeft: '0' }}>
          <ListItemAvatar>
            <Box>
              {(packageItem.position || packageItem.position === 0) && (
                <Avatar
                  style={{
                    width: '40px',
                    height: '30px',
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                    backgroundColor: 'transparent',
                    borderColor: colors.blue[500],
                    borderRadius: 10
                  }}
                >
                  <Typography
                    data-testid={`${packageItem.identifier}-position`}
                    display="block"
                    style={{ fontWeight: 600, color: colors.root[900] }}
                  >
                    {packageItem.position}
                  </Typography>
                </Avatar>
              )}
            </Box>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography display="block" style={{ fontWeight: 'bold' }}>
                {packageItem.identifier}
              </Typography>
            }
          />
        </ListItem>
        <Box ml={7}>
          <Typography noWrap color="textSecondary">
            {companyName ? `Pacote de ${companyName}` : ''}
          </Typography>
        </Box>
      </Box>
      {bottomDivider && <Divider />}
    </Box>
  );
}

DistributePackageItem.defaultProps = {
  bottomDivider: false
};

DistributePackageItem.propTypes = {
  packageItem: SharedPropTypes.package.isRequired,
  bottomDivider: PropTypes.bool
};
