import React, { createContext, useRef } from 'react';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { logActivityTracking } from '../../api-rest';
import { useDistributionCenter } from '../access-control/distribution-center-provider';
import { useFeature } from '../hooks/use-feature';
import { OPERATIONAL_PROCESS, ACTIVITY, SWITCHES } from '../../constants';

export const ActivityTrackingContext = createContext();

/**
 * Provider for tracking the user's activity.
 */
export function ActivityTrackingProvider({ children }) {
  const activityData = useRef({});

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const disableActivityTracking = useFeature(SWITCHES.disableActivityTracking);
  const trackingBeepsLatency = useFeature(SWITCHES.trackingBeepsLatency);

  /**
   * This function sets in the state the startTime of a specific activity.
   *
   * Example:
   * {
   *  OPERATIONAL_PROCESS_DISTRIBUTE: {
   *    FULL_PROCESS: {
   *      startTime: <timeInMs>,
   *      processId: <uuid>
   *    }
   *  }
   * }
   *
   * This function is not exposed by the Provider API.
   */
  const setupActivity = (processName, activityName, startTime, processId) => {
    activityData.current = {
      ...activityData.current,
      [processName]: {
        ...activityData.current[processName],
        [activityName]: {
          startTime,
          processId
        }
      }
    };
  };

  /**
   * This function is responsible for tracking the start time of a user's activity.
   *
   * @param {string} processName - operational process that the user is currently doing.
   * @param {string} activityName - current user activity within the operational process.
   */
  const trackStart = (processName, activityName) => {
    try {
      const startTime = Date.now();
      const processId = activityName === ACTIVITY.FULL_PROCESS ? uuid.v4() : '';
      setupActivity(processName, activityName, startTime, processId);
    } catch (error) {
      // ignore the error
    }
  };

  /**
   * This function is responsible for tracking the end time of a user's activity. It also
   * calculates the time spent in milliseconds on the activity and sends all the activity
   * information to the LoggiWeb.
   *
   * @param {string} processName - operational process that the user is currently doing.
   * @param {string} activityName - current user activity within the operational process.
   */
  const trackEnd = (processName, activityName) => {
    try {
      const currentActivityData = activityData.current;
      const endTime = Date.now();

      if (disableActivityTracking) {
        return;
      }

      if (
        !currentActivityData[processName] ||
        !currentActivityData[processName][activityName] ||
        !currentActivityData[processName][ACTIVITY.FULL_PROCESS]
      ) {
        return;
      }

      if (
        !trackingBeepsLatency &&
        currentActivityData[OPERATIONAL_PROCESS.BEEP_LATENCY]
      ) {
        return;
      }

      const { startTime } = currentActivityData[processName][activityName];

      if (!startTime) {
        return;
      }

      const timeSpentInMs = endTime - startTime;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      // call the logging API asynchronously
      logActivityTracking({
        processName,
        activityName,
        timeSpentInMs,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        distributionCenterId: selectedDistributionCenter?.distributionCenterId,
        userEmail: authenticatedUser.email,
        processId:
          currentActivityData[processName][ACTIVITY.FULL_PROCESS].processId
      });

      // reset the activity starting time and process id
      setupActivity(processName, activityName, 0, '');
    } catch (error) {
      setupActivity(processName, activityName, 0, '');
    }
  };

  return (
    <ActivityTrackingContext.Provider value={{ trackStart, trackEnd }}>
      {children}
    </ActivityTrackingContext.Provider>
  );
}

ActivityTrackingProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export function TestActivityTrackingProvider({
  children,
  trackStart,
  trackEnd
}) {
  return (
    <ActivityTrackingContext.Provider value={{ trackStart, trackEnd }}>
      {children}
    </ActivityTrackingContext.Provider>
  );
}

TestActivityTrackingProvider.defaultProps = {
  trackStart: () => {},
  trackEnd: () => {}
};

TestActivityTrackingProvider.propTypes = {
  children: PropTypes.element.isRequired,
  trackStart: PropTypes.func,
  trackEnd: PropTypes.func
};
