import React, { useContext } from 'react';
import { Box } from '@material-ui/core';

import { colors } from '@loggi/mar';

import { TRIP_PAGE_STATES } from '../../../constants';

import ScanItensTrip from './scan-itens-trip';
import TripStartTransfers from './trip-start-transfers';
import Success from './success';
import EditCargoList from './edit-cargo-list';
import { TripProcessContext } from './trip-process-context';

export default function TripProcess() {
  const { pageState } = useContext(TripProcessContext);

  return (
    <Box bgcolor={colors.root[0]}>
      {pageState === TRIP_PAGE_STATES.BEEP_CARGO && <ScanItensTrip />}
      {pageState === TRIP_PAGE_STATES.REVIEW_CARGO_START_TANSFER && (
        <TripStartTransfers />
      )}
      {pageState === TRIP_PAGE_STATES.END_SUCCESS && <Success />}
      {pageState === TRIP_PAGE_STATES.EDIT_LIST_CARGO && <EditCargoList />}
    </Box>
  );
}
