import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  makeStyles,
  DialogContent,
  DialogActions,
  Slide
} from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';
import { useSnackbar } from 'notistack';

import { NETWORK_ERROR_MESSAGE } from '../../constants';
import {
  TEXT,
  JIRA_TITLE_BY_STATUS,
  TESTID,
  JIRA_SUB_TITLE_IN_PROGRESS,
  JIRA_STATUS_IN_PROGRESS,
  CLOSE_BTN_PROP
} from './constants';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { playErrorBeep } from '../../sounds';
import { getDeclareDamage } from '../declare-package-damage/declare-package-damage-service';
import { DECLARE_DAMAGE_STATUS_CLOSED } from '../declare-package-damage/constants';

const Transition = forwardRef(function Transition(props, ref) {
  /* eslint-disable react/jsx-props-no-spreading */
  return <Slide direction="up" ref={ref} {...props} />;
});

function loadAnalysis(jiraTicket) {
  return (
    jiraTicket.comments?.length && (
      <Typography variant="span">{`"${
        jiraTicket.comments[0].body
      }"`}</Typography>
    )
  );
}

function loadContent(jiraTicket) {
  if (JIRA_STATUS_IN_PROGRESS.includes(jiraTicket.status)) {
    return (
      <Box pt={4} display="flex" flexDirection="column">
        <Box mb={2}>
          <Typography variant="h7">{JIRA_SUB_TITLE_IN_PROGRESS}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box pt={4} display="flex" flexDirection="column">
      <Box mb={2}>
        <Typography
          variant="caption"
          style={{
            fontWeight: 'bold'
          }}
        >
          {TEXT.DETAIL_ANALYSIS}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h7" style={{ fontWeight: 'bold' }}>
          {TEXT.TICKET} {jiraTicket.key}
        </Typography>
      </Box>
      {loadAnalysis(jiraTicket)}
    </Box>
  );
}

const useDialogStyles = isAutoHeight =>
  makeStyles(({ breakpoints }) => ({
    paper: {
      [breakpoints.only('xs')]: {
        height: isAutoHeight ? 'auto' : '96%',
        maxHeight: '100%'
      }
    }
  }))();

const IdentifyDamagedPackageJiraMenu = ({
  open,
  setOpen,
  packageId,
  handleContinue
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jiraTicket, setJiraTicket] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const isAutoHeight =
    JIRA_STATUS_IN_PROGRESS.includes(jiraTicket?.status) || isLoading;
  const { paper } = useDialogStyles(isAutoHeight);

  const errorHandler = React.useCallback(
    errMessage => {
      showSnackbar({
        message: errMessage,
        variant: 'error',
        showCloseButton: true,
        enqueueSnackbar
      });
      playErrorBeep();
      setOpen(false);
    },
    [enqueueSnackbar, setOpen]
  );

  const fetchDeclarePackageDamage = React.useCallback(
    async pId => {
      setIsLoading(true);
      setJiraTicket(null);
      const response = await getDeclareDamage({
        packageId: pId,
        errorHandler
      });
      if (response) {
        const { issues } = response;
        if (issues?.length) {
          setJiraTicket(issues[0]);
        } else {
          setOpen(false);
          errorHandler(NETWORK_ERROR_MESSAGE);
        }
      }
      setIsLoading(false);
    },
    [errorHandler, setIsLoading, setOpen]
  );

  useEffect(() => {
    if (open) {
      fetchDeclarePackageDamage(packageId);
    }
  }, [open, fetchDeclarePackageDamage, packageId]);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      classes={{ paper }}
      data-testid={TESTID.DAMAGED_PACKAGE_MENU}
    >
      <DialogTitle>
        <Box pt={4}>
          <Typography variant="h6">
            <Box component="span">
              {JIRA_TITLE_BY_STATUS[(jiraTicket?.status)]}
            </Box>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          width="100%"
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box flexGrow={1}>
            {isLoading && (
              <Box pb={4} display="flex" justifyContent="center">
                <CircularProgress justify="center" />
              </Box>
            )}

            {jiraTicket && (
              <Box display="flex" flexDirection="column">
                {loadContent(jiraTicket)}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <Box mb={1.5} clone>
        <DialogActions>
          <Box display="flex" flexDirection="column">
            {DECLARE_DAMAGE_STATUS_CLOSED.includes(jiraTicket?.status) && (
              <Box mb={1} width="100%">
                <Button
                  onClick={handleContinue}
                  data-testid={TESTID.NEW_REQUEST_BUTTON}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  {TEXT.NEW_REQUEST}
                </Button>
              </Box>
            )}
            <Box width="100%">
              <Button
                onClick={handleClose}
                data-testid={TESTID.CLOSE_BUTTON}
                variant={
                  jiraTicket
                    ? CLOSE_BTN_PROP[jiraTicket.status].variant
                    : 'outlined'
                }
                color="primary"
                fullWidth
              >
                {jiraTicket
                  ? CLOSE_BTN_PROP[jiraTicket.status].text
                  : TEXT.CLOSE}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

IdentifyDamagedPackageJiraMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  packageId: PropTypes.number.isRequired,
  handleContinue: PropTypes.func.isRequired
};

export default IdentifyDamagedPackageJiraMenu;
