/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import * as Sentry from '@sentry/browser';

import moment from 'moment';
import 'moment/locale/pt-br';

import { pxToRem } from '@loggi/mar/src/utils';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import { identifyBarcode } from '../../api';
import {
  getUnitLoad as getUnitLoadRest,
  unitloadStartProcessingDeadlineDates
} from '../../api-rest';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { PackageReader } from '../../app/components/barcode-readers';
import { useFeature } from '../../app/hooks/use-feature';
import extractDataFromGraphQLResponse from '../../app/utils/graphql-request';
import handleRestAPIError from '../../app/utils/rest-api-request';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  SWITCHES,
  TASK_ACK_STATUS_CHOICES
} from '../../constants';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import IdentifyBag from './identify-bag';
import IdentifyPackage from './identify-package';

moment.updateLocale('pt-BR');

const useStyles = makeStyles(thisTheme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: thisTheme.typography.pxToRem(1)
    }
  },
  large: {
    width: pxToRem(100),
    height: pxToRem(100)
  },
  padding: {
    padding: 0
  },
  link: {
    color: 'inherit',
    'text-decoration': 'inherit'
  }
}));

export default function Identify({ location }) {
  const locationData = location;
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [pack, setPack] = React.useState(null);
  const [bag, setBag] = React.useState(null);
  const [
    transferConfirmationSuccess,
    setTransferConfirmationSuccess
  ] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { bagStateFromPhotoUpload, recordSuccessWithPhoto } =
    locationData.state || '';
  const [nonIntegratedPackage, setNonIntegratedPackage] = React.useState(null);

  const showProcessDeadlineDate = useFeature(SWITCHES.showProcessDeadlineDate);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const classes = useStyles();

  const errorHandler = err => {
    Sentry.captureException(err);
    playErrorBeep();
  };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.IDENTIFY, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    return () => {
      trackEnd(OPERATIONAL_PROCESS.IDENTIFY, ACTIVITY.FULL_PROCESS);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIntervalTime = timeString => {
    // This function garantees that the timedelta returns a correct value
    // considering that loggi-web always sends the date string in São Paulo timezone.
    const timeStringUTC = moment(
      `${timeString ? timeString.split('Z')[0] : timeString}-03:00`
    ).utc();
    return `${moment.utc().diff(timeStringUTC, 'minute')} min`;
  };

  const itineraryStatus = data =>
    ({
      FINISHED: {
        statusDisplay: 'Finalizada',
        timePassedMin: getIntervalTime(data.finished)
      },
      ACCEPTED: {
        statusDisplay: 'Aceita',
        timePassedMin: getIntervalTime(data.created)
      },
      ALLOCATING: {
        statusDisplay: 'Alocando',
        timePassedMin: getIntervalTime(data.created)
      },
      STARTED: {
        statusDisplay: 'Iniciada',
        timePassedMin: getIntervalTime(data.started)
      },
      CANCELLED: {
        statusDisplay: 'Cancelada',
        timePassedMin: '-'
      },
      DROPPED: {
        statusDisplay: 'Perdida',
        timePassedMin: getIntervalTime(data.dropped)
      },
      ONGOING: {
        statusDisplay: 'Em execução',
        timePassedMin: getIntervalTime(data.created)
      }
    }[(data.status || '').toUpperCase()] || {
      statusDisplay: data.status,
      timePassedMin: getIntervalTime(data.created)
    });

  const previousStateCleanup = () => {
    setPack(null);
    setBag(null);
    setErrorMessage(null);
  };

  const isNewBagStateInconsistent = (newBagState, previousBagState) => {
    return (
      previousBagState &&
      (newBagState.bagData.seal !== previousBagState.bagData.seal ||
        newBagState.driver.pk !== previousBagState.driver.pk ||
        newBagState.itinerary.pk !== previousBagState.itinerary.pk)
    );
  };

  const isBarcodeNotFound = payloadData => {
    return (
      !payloadData.getLabelInfo ||
      (!(
        payloadData.getLabelInfo.packages &&
        payloadData.getLabelInfo.packages.length
      ) &&
        !payloadData.getLabelInfo.bag &&
        !payloadData.getLabelInfo.unitLoad &&
        !payloadData.getLabelInfo.nonIntegratedPackage)
    );
  };

  const resolvePackage = async (payloadData, packageCheckPromise) => {
    const packageData = payloadData.getLabelInfo.packages[0];
    const responsePackageCheck = await packageCheckPromise;
    const payloadPackageCheck = await responsePackageCheck.json();
    packageData.packageCheckId =
      payloadPackageCheck?.data.checkPackage?.packageCheck?.pk;
    packageData.intDeliveriesAttempts = (
      packageData.deliveryStatusHistory || []
    )
      .filter(
        status =>
          status.codeDisplay !== TASK_ACK_STATUS_CHOICES.IMPAIRED_DELIVERY
      )
      .reduce((l, r) => Math.max(l, parseInt(r.attempt, 10)), 0);
    setPack(packageData);
  };

  const resolveBagState = bagData => {
    return {
      bagData: {
        numPackages: bagData.numPackages || 0,
        seal: bagData.seal,
        cutoffRoute: bagData.route
          ? `${bagData?.route?.cutoff?.pk} - ${bagData.route.index}`
          : '-',
        distributionCenter: {
          name: bagData.distributionCenter
            ? bagData.distributionCenter.name
            : '-',
          routingCode: bagData.distributionCenter
            ? bagData.distributionCenter.routingCode
            : '-'
        },
        promisedDate: bagData.promisedDate
      },
      status: bagData.statusDisplay
    };
  };

  const resolveUnitLoadAsBagState = unitLoadData => {
    return {
      bagData: {
        numPackages: unitLoadData.childrenUnitLoad?.length || 0,
        seal: unitLoadData.licensePlate,
        promisedDate: unitLoadData.startProcessingDeadlineDateTime
      },
      status: unitLoadData.statusDisplay
    };
  };

  const resolveBag = async bagData => {
    const bagState = resolveBagState(bagData);
    if (bagData.route?.inquiry.finalized?.edges[0]?.node.itinerary) {
      const itineraryData =
        bagData.route.inquiry.finalized.edges[0].node.itinerary;
      const itineraryStatusDisplay = itineraryStatus(itineraryData);

      bagState.status = itineraryStatusDisplay.statusDisplay;
      bagState.itinerary = {
        ...itineraryStatusDisplay,
        pk: itineraryData.pk,
        url: itineraryData.url
      };
      if (itineraryData.driver) {
        bagState.driver = { ...itineraryData.driver };
      }
    }
    bagState.packagesInBag = bagData.packages;

    // Warning user when is necessary reload bag data
    // It is important when bag was reallocated
    if (isNewBagStateInconsistent(bagState, bagStateFromPhotoUpload)) {
      previousStateCleanup();
      setTransferConfirmationSuccess('');
      locationData.state = null;
      setErrorMessage(
        'Os dados da saca foram alterados, por favor recomece a transferência. Isto geralmente acontece quando a saca é realocada.'
      );
      setLoading(false);
      playErrorBeep();
      return;
    }

    locationData.state = null;
    setBag({ ...bagState });
  };

  const allocationDriverAsBagDriver = allocationDriver => {
    if (!allocationDriver) {
      return null;
    }
    return {
      pk: allocationDriver.pk,
      photoUrl: {
        profileHighRes: allocationDriver.photoUrl
      },
      fullName: allocationDriver.name,
      cnh: {
        number: allocationDriver.cnh
      },
      docRg: {
        number: allocationDriver.rg
      },
      docCpf: {
        number: allocationDriver.cpf
      },
      needOnboardingInstructions: allocationDriver.needOnboardingInstructions
    };
  };

  const resolveAllocation = (allocation, unitLoadState) => {
    const allocationULState = unitLoadState;
    allocationULState.driver = allocationDriverAsBagDriver(allocation.driver);

    const itineraryStatusDisplay = {
      statusDisplay: allocation.statusDisplay,
      timePassedMin: getIntervalTime(allocation.statusLastUpdateTime)
    };

    allocationULState.status = itineraryStatusDisplay.statusDisplay;

    allocationULState.itinerary = {
      ...unitLoadState.itinerary,
      ...itineraryStatusDisplay,
      allocationId: allocation.id
    };

    return allocationULState;
  };

  const resolveItinerary = (itinerary, unitLoadState) => {
    const itineraryULState = unitLoadState;
    if (itinerary.driver) {
      itineraryULState.driver = { ...itinerary.driver };
    }

    const itineraryStatusDisplay = itineraryStatus(itinerary);

    itineraryULState.status = itineraryStatusDisplay.statusDisplay;

    itineraryULState.itinerary = {
      ...unitLoadState.itinerary,
      ...itineraryStatusDisplay,
      pk: itinerary.id
    };

    return itineraryULState;
  };

  const resolveUnitLoadAsBag = async unitLoadData => {
    let unitLoadState = resolveUnitLoadAsBagState(unitLoadData);
    const { allocation, itinerary } = unitLoadData;

    if (allocation) {
      unitLoadState = resolveAllocation(allocation, unitLoadState);
    }
    if (itinerary) {
      unitLoadState = resolveItinerary(itinerary, unitLoadState);
    }

    unitLoadState.packagesInBag = unitLoadData.childrenUnitLoad || [];
    setBag({ ...unitLoadState });
  };

  const resolveUnitLoad = async unitLoadData => {
    const responseUnitLoadRest = showProcessDeadlineDate
      ? await unitloadStartProcessingDeadlineDates(
          unitLoadData.licensePlate
        ).catch(err => {
          setErrorMessage(err.message);
          handleRestAPIError(err, errorHandler);
        })
      : await getUnitLoadRest(unitLoadData.licensePlate).catch(err => {
          setErrorMessage(err.message);
          handleRestAPIError(err, errorHandler);
        });

    if (!responseUnitLoadRest) return;

    if (!responseUnitLoadRest.data) {
      setErrorMessage('Unidade não encontrada');
      playErrorBeep();
      return;
    }

    await resolveUnitLoadAsBag({
      ...responseUnitLoadRest.data,
      seal: unitLoadData.licensePlate
    });
  };

  const handleRead = async (barcode, packageCheckPromise) => {
    // Cleanup previous state so the widget disappear from the screen.
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.IDENTIFY_BEEP);
    previousStateCleanup();
    setTransferConfirmationSuccess(recordSuccessWithPhoto);
    setLoading(true);
    setNonIntegratedPackage(null);

    const response = await identifyBarcode(
      barcode,
      selectedDistributionCenter
    ).catch(err => {
      setErrorMessage(err.message);
      Sentry.captureException(err);
    });

    const payloadData = await extractDataFromGraphQLResponse(response).catch(
      err => {
        setErrorMessage(err.message);
      }
    );

    if (!payloadData) {
      setLoading(false);
      playErrorBeep();
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.IDENTIFY_BEEP);
      return;
    }

    const barcodeNotFound = isBarcodeNotFound(payloadData);

    if (barcodeNotFound) {
      setErrorMessage('Código de barras não encontrado');
      setLoading(false);
      playErrorBeep();
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.IDENTIFY_BEEP);
      return;
    }

    if (
      payloadData.getLabelInfo.packages &&
      payloadData.getLabelInfo.packages.length > 0
    ) {
      await resolvePackage(payloadData, packageCheckPromise);
    }

    if (payloadData.getLabelInfo.bag) {
      await resolveBag(payloadData.getLabelInfo.bag);
    }

    if (payloadData.getLabelInfo.unitLoad) {
      await resolveUnitLoad(payloadData.getLabelInfo.unitLoad);
    }

    setNonIntegratedPackage(
      payloadData?.getLabelInfo?.nonIntegratedPackage || null
    );

    setLoading(false);
    playSuccessBeep();
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.IDENTIFY_BEEP);
  };

  useEffect(() => {
    if (bagStateFromPhotoUpload) {
      handleRead(bagStateFromPhotoUpload.bagData.seal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">Identificar</Box>
      </Typography>
      <Box my={2.5} mt={2.5} className="centered">
        <PackageReader
          onRead={handleRead}
          loading={loading}
          notes="Identificado no XD App"
          source="xd_app_identify"
        />
      </Box>
      <Box className={classes.padding} maxWidth="xs">
        {pack && <IdentifyPackage pack={pack} />}
        {bag && (
          <IdentifyBag
            bag={bag}
            transferConfirmationSuccess={transferConfirmationSuccess}
          />
        )}
        {nonIntegratedPackage && (
          <Box className={classes.padding} maxWidth="xs">
            <Card>
              <CardContent>
                <Typography variant="h5">
                  <center> Pacote não integrado </center>
                </Typography>
                <Typography variant="h5">
                  <center> {nonIntegratedPackage.barcode} </center>
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Typography variant="body1">
                  {nonIntegratedPackage.companyName}
                </Typography>
                <Typography variant="caption">
                  {nonIntegratedPackage.recipientName}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
        {errorMessage && (
          <Box mx={5} mt={1.5}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
      </Box>
    </Container>
  );
}

Identify.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      bagId: PropTypes.string
    })
  })
};

Identify.defaultProps = {
  location: {
    state: {
      bagId: ''
    }
  }
};
