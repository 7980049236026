import { withStyles } from '@material-ui/core/styles';
import { colors, typography } from '@loggi/mar';
import { TableCell, TableRow } from '@material-ui/core';

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.blue[500],
    color: theme.palette.common.white,
    ...typography.subtitle2
  },
  body: {
    ...typography.body1
  }
}))(TableCell);

// Custom table row with every-other color alternated for better visual cue
export const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.common.white
    }
  }
}))(TableRow);
