import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions
} from '@material-ui/core';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

/**
 * This component is responsible for showing a confirmation alert on the screen,
 * it uses a Drawer on mobile and a dialog on the desktop. If an onCancel prop
 * is passed, renders a cancel button also.
 *
 *
 * @param open {boolean} Open the component
 * @param image {element} An image to be added on top of the title/subtitle
 * @param titleText {string} The title showed on the top of the component
 * @param subtitleText {string} Text placed above the confirm button
 * @param onConfirm {function} Event called after click on the confirm button
 * @param confirmText {string} Text shown on the confirm button
 * @param [onCancel] {function} Optional Event called after click on the cancel button
 * @param [cancelText] {string} Optional Text shown on the cancel button
 * @param [onExtraOption] {function} Optional Event called after click on the extra button
 * @param [extraOptionText] {string} Optional Text shown on the extra button
 * @param [zIndex] {number} Optional zIndex override
 * @returns {JSX.Element}
 */
const Confirmation = ({
  open,
  image,
  titleText,
  subtitleText,
  onConfirm,
  confirmText,
  onCancel,
  cancelText,
  onExtraOption,
  extraOptionText,
  zIndex
}) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      zIndex={zIndex}
      data-testid="confirmation-dialog"
    >
      <DialogTitle>
        <Box
          pt={2}
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
        >
          {image && <Box mb={3}>{image}</Box>}
          {titleText && (
            <Box>
              <Typography align="center" variant="h6">
                {titleText}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
        >
          <Box mx={{ xs: 1, sm: 0 }} style={{ overflowWrap: 'break-word' }}>
            <Typography
              align="center"
              variant="subtitle2"
              dangerouslySetInnerHTML={{
                __html: subtitleText
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <Box>
        <DialogActions>
          <Box width={onExtraOption ? '100%' : 'inherit'} my={1.5}>
            <Box
              display={onExtraOption ? 'block' : 'flex'}
              flexDirection={{
                xs: 'column',
                sm: onExtraOption ? 'column' : 'row-reverse'
              }}
              width={onExtraOption ? '100%' : 'inherit'}
              my={1.5}
            >
              <Box>
                <Button
                  fullWidth
                  data-testid="confirm-button"
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={onConfirm}
                >
                  {confirmText}
                </Button>
              </Box>
              {onCancel && (
                <Box
                  pt={{ xs: 2.5, sm: onExtraOption ? 2.5 : 0 }}
                  mr={{ xs: 0, sm: onExtraOption ? 0 : 1 }}
                >
                  <Button
                    fullWidth
                    data-testid="cancel-button"
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                  >
                    {cancelText}
                  </Button>
                </Box>
              )}
              {onExtraOption && (
                <Box pt={{ xs: 2.5, sm: 2.5 }} mr={{ xs: 0, sm: 0 }}>
                  <Button
                    fullWidth
                    data-testid="extra-option-button"
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={onExtraOption}
                  >
                    {extraOptionText}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

Confirmation.defaultProps = {
  image: null,
  titleText: '',
  onCancel: null,
  cancelText: '',
  onExtraOption: null,
  extraOptionText: '',
  zIndex: null
};

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  image: PropTypes.element,
  titleText: PropTypes.string,
  subtitleText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  onExtraOption: PropTypes.func,
  extraOptionText: PropTypes.string,
  zIndex: PropTypes.number
};

export default Confirmation;
