import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
import HeaderWithReturn from '../../app/components/header-with-return';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import FloatingContainer from '../../app/components/floating-container';
import { ReceivingProcessContext } from './receiving-process-context';

function ItemListReceiveCheck({ barcode, company, recomendation, hasDivider }) {
  return (
    <>
      <Box mt={3.75} mb={3.75} display="flex" flexDirection="column">
        <Typography variant="body2" gutterBottom>
          <Box mb={1} fontWeight="600" fontSize="1.125rem">
            {barcode}
          </Box>
          {company && (
            <Box
              color={colors.smoke[700]}
              mb={0.5}
              fontWeight="400"
              fontSize="1rem"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              Pacote de {company}
            </Box>
          )}
          {(recomendation.name || recomendation.routingCode) && (
            <Box
              color={colors.smoke[700]}
              mb={0.5}
              fontWeight="400"
              fontSize="1rem"
            >
              {recomendation.name} • {recomendation.routingCode}
            </Box>
          )}
        </Typography>
      </Box>
      {hasDivider && <Divider />}
    </>
  );
}

ItemListReceiveCheck.propTypes = {
  barcode: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  recomendation: PropTypes.shape({
    name: PropTypes.string,
    routingCode: PropTypes.string
  }).isRequired,
  hasDivider: PropTypes.bool.isRequired
};

export default function ReceiveCheck({ packages, finishReceivement, goBack }) {
  const { recommendationsDict } = useContext(ReceivingProcessContext);

  return (
    <Box
      height="100vh"
      overflow="auto"
      bgcolor={colors.root[0]}
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Container maxWidth="xs">
        <Box pt={1.5}>
          <HeaderWithReturn onReturn={goBack} fixed={false} />
        </Box>
        <Box mt={4} display="flex" alignItems="flex-end">
          <Typography variant="body1">
            <Box fontWeight="fontWeightRegular" fontSize="5.375rem">
              {packages.length}
            </Box>
          </Typography>
          <Typography variant="body1">
            <Box mb={3} fontWeight="fontWeightRegular">
              &nbsp;un.
            </Box>
          </Typography>
        </Box>
      </Container>
      <Box
        maxWidth="444px"
        width="100%"
        flex="1"
        mx="auto"
        px={{ sm: '1.25rem' }}
        pb={11}
        mt={2}
      >
        <FloatingContainer>
          {packages.map((p, index) => (
            <ItemListReceiveCheck
              key={p.package}
              barcode={p.package}
              company={p.company}
              recomendation={recommendationsDict[p.package] || {}}
              hasDivider={packages.length !== index + 1}
            />
          ))}
        </FloatingContainer>
      </Box>
      <Container maxWidth="xs">
        <ActionContainerBottom>
          <Container maxWidth="xs">
            <Box pb={3.125}>
              <Button
                onClick={finishReceivement}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
              >
                Pronto, recebidos
              </Button>
            </Box>
          </Container>
        </ActionContainerBottom>
      </Container>
    </Box>
  );
}

ReceiveCheck.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      package: PropTypes.string,
      company: PropTypes.string
    })
  ).isRequired,
  finishReceivement: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
};
