import React, { useContext, useEffect, useCallback } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { colors } from '@loggi/mar';
import DamagePhotoUpload from './damage-photo-upload';
import { TESTID, TEXT, NUMBER } from './constants';
import { DamagedPackageContext } from './damaged-package-context';
import {
  ACTIVITY,
  COGNITO_DISTRIBUTION_CENTER,
  OPERATIONAL_PROCESS,
  ROUTES,
  SWITCHES
} from '../../../constants';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import HeaderWithReturn from '../../../app/components/header-with-return';
import TextWithBoldEmphasis from '../../../app/components/text-with-bold-emphasis';
import { useFeature } from '../../../app/hooks/use-feature';
import ImageView from './image-view';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import getUserType from '../../../app/access-control/access-control-service';

const ThumbnailImages = ({ imagesData, imageCurrent, setImageCurrent }) => {
  const smallDefaultNumberImagesLeft =
    NUMBER.TOTAL_PREVISUALIZE_IMAGE - imagesData.length;

  const defaultThumbnailKey = i => `default-thumbs-${i}`;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      justifyContent="center"
    >
      {imagesData.map(imageData => (
        <Box
          key={imageData.key}
          width="72px"
          height="59px"
          m={1}
          mr={1}
          position="relative"
          onClick={() => {
            setImageCurrent(imageData);
          }}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              border: imageCurrent?.key === imageData.key ? 'solid' : '',
              color: colors.blue[500],
              borderRadius: 7
            }}
            data-testid={TESTID.DAMAGE_PACKAGE_MINIPREVISUALIZATION}
            src={imageData.src}
            alt="Foto tirada do pacote avariado"
          />
        </Box>
      ))}

      {[...Array(smallDefaultNumberImagesLeft)].map((item, index) => (
        <Box
          key={defaultThumbnailKey(index)}
          width="72px"
          height="59px"
          m={1}
          mr={1}
          position="relative"
          onClick={() => {
            setImageCurrent(null);
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled
            style={{
              borderRadius: 7,
              height: '100%',
              width: '100%',
              backgroundColor:
                index === 0 ? colors.blue[100] : colors.smoke[200]
            }}
          >
            <CameraAltIcon
              alt="Icone default"
              style={{
                fontSize: 25,
                color: index === 0 ? colors.blue[500] : colors.smoke[500]
              }}
            />
          </Button>
        </Box>
      ))}
    </Box>
  );
};

const imageShape = PropTypes.shape({
  key: PropTypes.string,
  src: PropTypes.string
});

ThumbnailImages.propTypes = {
  imagesData: PropTypes.arrayOf(imageShape).isRequired,
  imageCurrent: imageShape.isRequired,
  setImageCurrent: PropTypes.func.isRequired
};

export default function UploadImageDamagePackage() {
  const {
    imageLabel,
    imagesPackage: imagesData,
    setImagesPackage: setImagesData,
    damageImageCurrent: imageCurrent,
    setDamageImageCurrent: setImageCurrent
  } = useContext(DamagedPackageContext);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const history = useHistory();
  const enableDeclarePackageDamageV2ByDc = useFeature(
    SWITCHES.enableDeclarePackageDamageV2ByDc
  );
  const enableDeclarePackageDamageV2AllDcsNoLeve = useFeature(
    SWITCHES.enableDeclarePackageDamageV2AllDcsNoLeve
  );

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const isDeclareDamagedPackageEnabled =
    enableDeclarePackageDamageV2ByDc ||
    (enableDeclarePackageDamageV2AllDcsNoLeve && !isUserLeve);

  const showCamera =
    imagesData.length < NUMBER.TOTAL_PREVISUALIZE_IMAGE && !imageCurrent;
  const isAbleToSubmit = imagesData.length > 0;

  const handleGoBack = useCallback(
    () => history.push(ROUTES.DAMAGED_PACKAGE.MATCH_IMAGE),
    [history]
  );
  const handleGoIdentify = useCallback(() => history.push('/identify'), [
    history
  ]);

  const handleGoNextStep = useCallback(
    () => history.push(ROUTES.DAMAGED_PACKAGE.FORM),
    [history]
  );

  useEffect(() => {
    if (!isDeclareDamagedPackageEnabled) {
      handleGoIdentify();
    }
  }, [isDeclareDamagedPackageEnabled, handleGoIdentify]);

  useEffect(() => {
    if (!imageLabel) {
      handleGoBack();
    }
  }, [handleGoBack, imageLabel]);

  useEffect(() => {
    trackStart(
      OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2,
      ACTIVITY.UPLOAD_DAMAGE_PACKAGE_STEP
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeImage = () => {
    setImagesData(images => images.filter(image => image !== imageCurrent));
    setImageCurrent(null);
  };

  const onConfirmSend = image => {
    const imageObj = { src: image, key: new Date().getTime() };
    const newImages = [...imagesData, imageObj];
    setImagesData(newImages);

    if (newImages.length === NUMBER.TOTAL_PREVISUALIZE_IMAGE && !imageCurrent) {
      setImageCurrent(imageObj);
    }
  };

  const handleSend = async () => {
    trackEnd(
      OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2,
      ACTIVITY.UPLOAD_DAMAGE_PACKAGE_STEP
    );
    handleGoNextStep();
  };

  return (
    <Box>
      <HeaderWithReturn title={TEXT.TITLE} onReturn={handleGoBack} />
      <Container maxWidth="xs">
        <Box display="flex" flexDirection="column" pt={10}>
          {showCamera && (
            <DamagePhotoUpload
              titleComponent={
                <TextWithBoldEmphasis
                  text={TEXT.TITLE_UPLOAD_DAMAGE_IMAGE.TEXT}
                  emphasis={TEXT.TITLE_UPLOAD_DAMAGE_IMAGE.EMPHASIS}
                  typographyVariant="subtitle1"
                />
              }
              titleAlt={TEXT.ALT_UPLOAD_DAMAGE_IMAGE}
              onConfirmSend={onConfirmSend}
              cameraHeight="55vh"
            />
          )}
          {imageCurrent && (
            <ImageView
              titleComponent={
                <TextWithBoldEmphasis
                  text={TEXT.TITLE_UPLOAD_DAMAGE_IMAGE.TEXT}
                  emphasis={TEXT.TITLE_UPLOAD_DAMAGE_IMAGE.EMPHASIS}
                  typographyVariant="subtitle1"
                />
              }
              titleAlt={TEXT.ALT_PREVISUALIZE_DAMAGE_IMAGE}
              removeImage={removeImage}
              image={imageCurrent.src}
              imageHeight="55vh"
            />
          )}

          <ThumbnailImages
            imagesData={imagesData}
            imageCurrent={imageCurrent}
            setImageCurrent={setImageCurrent}
          />
        </Box>

        <Box my={3} display="flex">
          <Box width="100%">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSend}
              disabled={!isAbleToSubmit}
              data-testid={TESTID.DAMAGE_PACKAGE_SEND_BUTTON}
            >
              {TEXT.SEND_DAMAGE_IMAGE}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
