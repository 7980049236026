import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Alert } from '@material-ui/lab';
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  TextField,
  Divider,
  Container
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { getLabelInfoByBagOrUnitLoad } from '../api';
import { playErrorBeep, playSuccessBeep } from '../sounds';
import { BarcodeReader } from '../app/components/barcode-readers';
import startTransference from './start-transfer-service';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  SWITCHES,
  UNKNOWN_ERROR
} from '../constants';
import AsyncButton from '../app/components/async-button';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';
import { useFeature } from '../app/hooks/use-feature';

export const ERROR_MESSAGE = {
  BAG_OR_UNITLOAD_NOT_FOUND: 'Unidade não encontrada',
  TRANSFER_NOT_FOUND: 'Unidade não tem transferência',
  PLATE_NONSTANDARD: 'Placa fora do padrão.',
  PLATE_COUNT: 'Placa deve ter 7 dígitos',
  TRANSFER_MUST_SAVED: 'Tranferência com status incorreto'
};
const STANDARD_VEHICLE_LICENSE_PLATE = '^[A-Z]{3}[0-9][A-Z0-9][0-9]{2}$';
const STEPS = { ready: 1, done: 2 };
const TRANSFER_ALLOWED_STATUS = 'saved';

function errorHandling({ response, payload, setError }) {
  const statusMessage =
    response && response.status
      ? `${response.status} - ${response.statusText}:`
      : '';
  const msgError = `${statusMessage} ${payload.errors[0].message || ''}`;
  playErrorBeep();
  setError(msgError);
}

export const InputText = props => {
  const { id, onSubmit, label, placeholder, error, inputProps } = props;

  const [textValue, setTextValue] = useState('');
  const [loading, setLoading] = useState(false);
  const inputField = React.createRef();

  useEffect(() => {
    if (inputField.current) {
      inputField.current.focus();
    }
  }, [inputField]);

  useEffect(() => {
    if (error) {
      playErrorBeep();
    }
  }, [error]);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    await onSubmit(textValue);
    setTextValue('');
    setLoading(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <form
          onSubmit={handleSubmit}
          data-testid={`form-${id}`}
          style={{ width: '100%' }}
        >
          <TextField
            error={Boolean(error)}
            helperText={error}
            id={id}
            label={label}
            variant="outlined"
            size="medium"
            inputRef={inputField}
            type="text"
            inputProps={{ 'aria-label': id, ...inputProps }}
            placeholder={placeholder}
            value={textValue}
            onChange={event => {
              setTextValue(event.target.value);
            }}
            fullWidth
            required
          />
        </form>
        <Box display="flex" position="absolute" right={0} top={0} m={0.5}>
          {loading && <CircularProgress size={15} />}
        </Box>
      </Box>
    </Box>
  );
};

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  inputProps: PropTypes.shape({})
};

InputText.defaultProps = {
  placeholder: '',
  error: '',
  inputProps: {}
};

export const DetailItem = ({ label, field }) => (
  <Typography variant="body1" component="div" gutterBottom>
    <Box
      fontWeight="fontWeightBold"
      justify="flex-start"
      m={1}
      color={colors.blue[500]}
    >
      {label}
      <Typography variant="h5" component="div">
        <Box color={colors.smoke[900]}>
          <Typography>{field}</Typography>
        </Box>
      </Typography>
    </Box>
  </Typography>
);

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const Details = ({ loading, details, showInfo, licensePlate }) => {
  return (
    <Grid container>
      {loading && (
        <Grid container justify="center">
          <Box p={2}>
            <CircularProgress justify="center" />
          </Box>
        </Grid>
      )}

      {showInfo && (
        <Grid item p={2.5}>
          {details.map(({ label, field }, index) => (
            <DetailItem
              key={`item-${JSON.stringify(index)}`}
              label={label}
              field={field}
            />
          ))}
          <DetailItem key="item-last" label="Placa" field={licensePlate} />
        </Grid>
      )}
    </Grid>
  );
};
Details.propTypes = {
  loading: PropTypes.bool.isRequired,
  details: PropTypes.arrayOf.isRequired,
  showInfo: PropTypes.arrayOf.isRequired,
  licensePlate: PropTypes.string.isRequired
};

const DetailsBag = ({ loading, bagInfo, licensePlate }) => {
  const { seal = '', transfers = [{}] } = bagInfo;
  const details = [
    { label: 'Saca', field: seal },
    { label: 'Transferências', field: transfers[0]?.name ?? '' },
    {
      label: 'Destino',
      field: transfers[0]?.expeditionCenter?.shortDisplay ?? ''
    },
    {
      label: 'Sacas Adicionadas',
      field: transfers[0]?.numBags ?? ''
    }
  ];

  const showItems = !loading && bagInfo && bagInfo.seal;

  return (
    <Details
      loading={loading}
      details={details}
      showInfo={showItems}
      licensePlate={licensePlate}
    />
  );
};

DetailsBag.propTypes = {
  loading: PropTypes.bool.isRequired,
  licensePlate: PropTypes.string,
  bagInfo: PropTypes.shape({
    seal: PropTypes.string,
    transfers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        expeditionCenter: PropTypes.shape({
          shortDisplay: PropTypes.string
        })
      })
    )
  })
};

DetailsBag.defaultProps = {
  bagInfo: {},
  licensePlate: ''
};

const DetailsUnitLoad = ({ loading, unitLoadInfo, licensePlateVehicle }) => {
  const { licensePlate = '', transfer = {} } = unitLoadInfo;
  const details = [
    { label: 'Unidade', field: licensePlate },
    { label: 'Transferências', field: transfer?.name ?? '' },
    {
      label: 'Destino',
      field: transfer?.expeditionCenter?.shortDisplay ?? ''
    }
  ];

  const showItems = !loading && unitLoadInfo?.licensePlate;

  return (
    <Details
      loading={loading}
      details={details}
      showInfo={showItems}
      licensePlate={licensePlateVehicle}
    />
  );
};

DetailsUnitLoad.propTypes = {
  loading: PropTypes.bool.isRequired,
  licensePlateVehicle: PropTypes.string,
  unitLoadInfo: PropTypes.shape({
    licensePlate: PropTypes.string,
    transfer: PropTypes.shape({
      name: PropTypes.string,
      expeditionCenter: PropTypes.shape({
        shortDisplay: PropTypes.string
      })
    })
  })
};

DetailsUnitLoad.defaultProps = {
  unitLoadInfo: {},
  licensePlateVehicle: ''
};

export const StartTransfer = ({ startTransferService }) => {
  const [bagInfo, setBagInfo] = useState({});
  const [unitLoadInfo, setUnitLoadInfo] = useState({});
  const [error, setError] = useState('');
  const [licensePlate, setLicensePlate] = useState({ error: '', value: '' });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEPS.ready);
  const [focusBarcodeReader, setFocusBarcodeReader] = useState(true);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const enableStartTransferUnitLoad = useFeature(
    SWITCHES.enableStartTransferUnitLoad
  );

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.START_TRANSFER, ACTIVITY.FULL_PROCESS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetState = () => {
    setFocusBarcodeReader(true);
    setBagInfo({});
    setError('');
    setLicensePlate({});
    setStep(STEPS.ready);
  };

  const errorHandler = err => {
    setError(err);
    setLoading(false);
    playErrorBeep();
  };

  const unexpectedErrorHandler = err => {
    Sentry.captureException(err);
    errorHandler(`${UNKNOWN_ERROR}: ${err.message}`);
  };

  const handleBagReader = async barcode => {
    resetState();
    setLoading(true);

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.START_TRANSFER_BAG_BEEP
    );

    const response = await getLabelInfoByBagOrUnitLoad(barcode, errorHandler);

    if (!response) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.START_TRANSFER_BAG_BEEP
      );
      return;
    }

    const getInfoUnitLoad = unitLoad => {
      if (!unitLoad || !unitLoad.licensePlate) {
        errorHandler(ERROR_MESSAGE.BAG_OR_UNITLOAD_NOT_FOUND);
      } else if (!unitLoad.transfer) {
        errorHandler(ERROR_MESSAGE.TRANSFER_NOT_FOUND);
      } else if (unitLoad.transfer.status !== TRANSFER_ALLOWED_STATUS) {
        errorHandler(ERROR_MESSAGE.TRANSFER_MUST_SAVED);
      } else {
        setFocusBarcodeReader(false);
        setUnitLoadInfo(unitLoad);
        playSuccessBeep();
      }
      setLoading(false);
    };

    const getInfoBag = bag => {
      if (!bag || !bag.seal) {
        errorHandler(ERROR_MESSAGE.BAG_OR_UNITLOAD_NOT_FOUND);
      } else if (!bag.transfers.length) {
        errorHandler(ERROR_MESSAGE.TRANSFER_NOT_FOUND);
      } else if (bag.transfers[0].status !== TRANSFER_ALLOWED_STATUS) {
        errorHandler(ERROR_MESSAGE.TRANSFER_MUST_SAVED);
      } else {
        setFocusBarcodeReader(false);
        setBagInfo(bag);
        playSuccessBeep();
      }
      setLoading(false);
    };

    response.json().then(payload => {
      if (response.ok) {
        const bag = payload?.data?.getLabelInfo?.bag ?? null;
        const unitLoad = payload?.data?.getLabelInfo?.unitLoad ?? null;

        if (enableStartTransferUnitLoad && unitLoad) {
          getInfoUnitLoad(unitLoad);
        } else {
          getInfoBag(bag);
        }
      } else {
        errorHandling({ response, payload, setError });
        setLoading(false);
      }
    });

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.START_TRANSFER_BAG_BEEP
    );
    setError('');
    setLicensePlate({});
  };

  const handleLicensePlate = async vehicleLicensePlate => {
    setError('');
    setLicensePlate({ value: '', error: '' });
    const regex = new RegExp(STANDARD_VEHICLE_LICENSE_PLATE, 'i');
    if (regex.test(vehicleLicensePlate)) {
      playSuccessBeep();
      setLicensePlate({ value: vehicleLicensePlate.toUpperCase() });
    } else {
      setLicensePlate({ error: ERROR_MESSAGE.PLATE_NONSTANDARD });
    }
  };

  const getTransferId = () => {
    if (bagInfo.transfers?.length) {
      return bagInfo.transfers[0].pk;
    }
    return unitLoadInfo.transfer?.pk;
  };

  const handleInitTransfer = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.START_TRANSFER_INIT_BEEP
    );
    setLoading(true);
    const pk = getTransferId();
    const startTransferPayload = await startTransferService({
      transferId: pk,
      vehicleLicensePlate: licensePlate.value,
      errorHandler,
      unexpectedErrorHandler
    });

    setLoading(false);

    if (!startTransferPayload) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.START_TRANSFER_INIT_BEEP
      );
      return;
    }

    const { success, errors } = startTransferPayload;

    if (success) {
      setFocusBarcodeReader(true);
      playSuccessBeep();
      setStep(STEPS.done);
      trackEnd(OPERATIONAL_PROCESS.START_TRANSFER, ACTIVITY.FULL_PROCESS);
    } else {
      errorHandler(errors.map(err => err.message).join('\n'));
    }
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.START_TRANSFER_INIT_BEEP
    );
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
  };

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">Iniciar Transferência</Box>
      </Typography>
      <Box className="centered" my={2.5}>
        <Box>
          <BarcodeReader
            onRead={handleBagReader}
            disable={!focusBarcodeReader}
            placeholder="Leia o código da saca"
          />

          {(bagInfo.seal || unitLoadInfo.licensePlate) && (
            <Box mt={2.5}>
              <InputText
                id="license-plate-reader-txt"
                label="Placa"
                fullWidth
                placeholder="Digite a placa do veículo"
                onSubmit={handleLicensePlate}
                error={licensePlate.error}
                inputProps={{ maxLength: 7 }}
              />
            </Box>
          )}

          <Box py={1}>
            <Divider />
          </Box>

          {Boolean(error) && (
            <Box my={1.5}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          {step === STEPS.done && (
            <Box my={1.5}>
              <Alert severity="success">
                Transferência iniciada com sucesso.
              </Alert>
            </Box>
          )}
          {enableStartTransferUnitLoad && unitLoadInfo?.licensePlate ? (
            <DetailsUnitLoad
              unitLoadInfo={unitLoadInfo}
              loading={loading}
              licensePlateVehicle={licensePlate.value || ''}
            />
          ) : (
            <DetailsBag
              bagInfo={bagInfo}
              loading={loading}
              licensePlate={licensePlate.value || ''}
            />
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            p={1}
          >
            <AsyncButton
              variant="contained"
              onClick={handleInitTransfer}
              color="primary"
              fullWidth
              isDisabled={!licensePlate.value || step === STEPS.done}
              data-testid="start-transfer-button"
            >
              Iniciar a transferência
            </AsyncButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

StartTransfer.defaultProps = {
  startTransferService: startTransference
};

StartTransfer.propTypes = {
  startTransferService: PropTypes.func
};
