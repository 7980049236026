import addressAliasField from './address-alias-field.translations.pt-br.json';
import addressField from './address-field.translations.pt-br.json';
import baseTimeField from './base-time-field.translations.pt-br.json';
import cnpjOrCpfField from './cnpj-or-cpf-field.translations.pt-br.json';
import complementField from './complement-field.translations.pt-br.json';
import dateField from './date-field.translations.pt-br.json';
import instructionsField from './instructions-field.translations.pt-br.json';
import nameField from './name-field.translations.pt-br.json';
import noComplementField from './no-complement-field.translations.pt-br.json';
import phoneField from './phone-field.translations.pt-br.json';
import shared from './shared.translations.pt-br.json';
import weekdaysField from './weekdays-field.translations.pt-br.json';
import measurementsField from './measurements-field.translations.pt-br.json';
import tokenField from './token-field.translations.pt-br.json';

export default {
  addressAliasField,
  addressField,
  baseTimeField,
  cnpjOrCpfField,
  complementField,
  dateField,
  instructionsField,
  nameField,
  noComplementField,
  phoneField,
  shared,
  weekdaysField,
  measurementsField,
  tokenField
};
