import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  List,
  Button,
  Divider,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

import { colors } from '@loggi/mar';
import { useSnackbar } from 'notistack';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import SkeletonList from '../../app/components/skeleton-list';
import HeaderWithReturn from '../../app/components/header-with-return';
import Confirmation from '../../app/components/confirmation';
import pluralize from '../../app/utils/pluralize';
import CheckboxListItem from '../../app/components/checkbox-list-item';
import ActionContainerBottom from '../../app/components/actions-container-bottom';

import { removeDistributionUnitLoadCargo } from '../../api-rest';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';

import DistributionUnitLoadCargoInfo from './trip/distribution-unit-load-cargo-info';
import { DistributeProcessContext } from './distribute-process-context';

import {
  ROUTES,
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  REMOVE_CARGO_ERROR_MSGS,
  DistributionUnitLoadAlreadyStarted
} from '../../constants';
import handleRestAPIErrorWithMessage from '../../app/utils/handle-rest-api-error-with-message';

export default function DistributionUnitLoadEditCargoList() {
  const { cargos, setCargos, distributionUnitLoadId, clearData } = useContext(
    DistributeProcessContext
  );

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [distributionCargosToRemove, setDistributionCargosToRemove] = useState(
    []
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isCheckedSelectAllCargos, setIsCheckedSelectAllCargos] = useState(
    false
  );
  const [loading, setLoading] = useState(false);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const baseErrorHandler = message => {
    showSnackbarAlert(message, 'error');
    setLoading(false);
  };

  const goBack = () => {
    setCargos([]);
    history.push({ pathname: ROUTES.DISTRIBUTE.REVIEW_CARGO });
  };

  const handleRemove = () => {
    setOpenConfirmation(true);
  };

  const cancelRemove = () => {
    setOpenConfirmation(false);
  };

  const confirmRemove = async () => {
    setLoading(true);
    setOpenConfirmation(false);
    try {
      await removeDistributionUnitLoadCargo({
        distributionUnitLoadId,
        sourceRoutingCode: selectedDistributionCenter.routingCode,
        licensePlates: distributionCargosToRemove
      });

      showSnackbarAlert('Pronto, tudo certo.\n Sua lista foi atualizada.');
      goBack();
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.type === DistributionUnitLoadAlreadyStarted) {
        history.push({
          pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
        });
        clearData();
        showSnackbar({
          variant: 'warning',
          message: BASE_TRIP_TRANSFER_ERROR_MSGS[error.response.data.type],
          showCloseButton: true,
          enqueueSnackbar
        });
        return;
      }
      handleRestAPIErrorWithMessage(
        { ...BASE_TRIP_TRANSFER_ERROR_MSGS, ...REMOVE_CARGO_ERROR_MSGS },
        error,
        baseErrorHandler
      );
    }
  };

  const isDistributionCargoSelected = selectedLicensePlate =>
    distributionCargosToRemove.some(cargo => cargo === selectedLicensePlate);

  const handleSelectDistributionCargo = (selectedLicensePlate, hasStarted) => {
    if (hasStarted) return;

    setIsCheckedSelectAllCargos(false);

    if (isDistributionCargoSelected(selectedLicensePlate)) {
      setDistributionCargosToRemove(
        distributionCargosToRemove.filter(
          cargo => cargo !== selectedLicensePlate
        )
      );

      return;
    }

    setDistributionCargosToRemove([
      ...distributionCargosToRemove,
      selectedLicensePlate
    ]);
  };

  const handleSelectAllCargosChange = () => {
    setDistributionCargosToRemove([]);

    if (isCheckedSelectAllCargos) {
      setIsCheckedSelectAllCargos(false);
      return;
    }

    setDistributionCargosToRemove(cargos.map(cargo => cargo.licensePlate));
    setIsCheckedSelectAllCargos(true);
  };

  const getTitleText = () => {
    const packageText = pluralize({
      singular: 'pacote',
      count: distributionCargosToRemove.length
    });
    const selectedText = pluralize({
      singular: 'selecionado',
      count: distributionCargosToRemove.length
    });
    return `Você quer remover <b>${
      distributionCargosToRemove.length
    } ${packageText} ${selectedText}</b> da sua lista?`;
  };

  return (
    <>
      <Box height="100vh" overflow="auto">
        <Container
          maxWidth="xs"
          data-testid="edit-distribution-cargo-list"
          disableGutters
        >
          <Box pt={15} pb={12} bgcolor={colors.root[0]}>
            {!loading ? (
              <>
                <Divider />
                <List>
                  {cargos.map(item => (
                    <CheckboxListItem
                      key={item.licensePlate}
                      onClick={() =>
                        handleSelectDistributionCargo(item.licensePlate)
                      }
                      selected={isDistributionCargoSelected(item.licensePlate)}
                      divider
                    >
                      <DistributionUnitLoadCargoInfo
                        distributionCargoLicensePlate={item.licensePlate}
                        distributionCargoCompany={item.sourceCompanyName}
                        distributionCargoDestination={item.destinationName}
                        packageCount={item.packageCount}
                      />
                    </CheckboxListItem>
                  ))}
                </List>
              </>
            ) : (
              <Box data-testid="distribution-cargo-loading">
                <SkeletonList />
              </Box>
            )}
            {openConfirmation && (
              <Confirmation
                open
                subtitleText={getTitleText()}
                confirmText="Confirmar"
                onConfirm={confirmRemove}
                onCancel={cancelRemove}
                cancelText="Manter na lista"
                zIndex={2}
              />
            )}
          </Box>
        </Container>
      </Box>

      <Container disableGutters>
        <HeaderWithReturn
          onReturn={goBack}
          bgcolor={colors.root[0]}
          isDisabled={loading}
        />
        <Box position="fixed" top={5} bgcolor={colors.root[0]} width={1}>
          <Box display="flex" alignItems="center" mt={8} mb={2}>
            <Box mr={1} pl={4.5}>
              <FormControlLabel
                control={
                  <Box mr={1}>
                    <Checkbox
                      edge="start"
                      inputProps={{
                        'data-testid': 'select-all-cargos-checkbox'
                      }}
                      color="primary"
                      checked={isCheckedSelectAllCargos}
                      onChange={handleSelectAllCargosChange}
                    />
                  </Box>
                }
                label={
                  isCheckedSelectAllCargos
                    ? 'Todos selecionados'
                    : `${distributionCargosToRemove.length} ${pluralize({
                        singular: 'selecionado',
                        count: distributionCargosToRemove.length
                      })}`
                }
                labelPlacement="select-all"
              />
            </Box>
          </Box>
          <Divider />
        </Box>
      </Container>

      <ActionContainerBottom>
        <Container maxWidth="xs">
          <Box pb={3} flex="initial">
            <Box display="flex">
              <Box flex={1} ml={1}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleRemove}
                  disabled={distributionCargosToRemove <= 0 || loading}
                >
                  Remover da lista
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </ActionContainerBottom>
    </>
  );
}
