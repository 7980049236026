import { pxToRem } from '../utils';
import { colors, typography } from '../principles';

const MuiChip = {
  root: {
    backgroundColor: colors.smoke[50],
    borderRadius: pxToRem(100),
    color: colors.smoke[700],
    height: pxToRem(48),
    '&:hover': {
      backgroundColor: colors.smoke[100],
      color: colors.smoke[900]
    }
  },
  clickable: {
    '&:focus': {
      backgroundColor: colors.smoke[50]
    },
    '&:hover': {
      backgroundColor: colors.smoke[100]
    }
  },
  colorPrimary: {
    backgroundImage: colors.gradients.sanches,
    '&:hover': {
      backgroundImage: 'none',
      backgroundColor: colors.blue[500],
      color: colors.root[0]
    }
  },
  label: {
    fontSize: typography.button.fontSize,
    fontWeight: 600,
    lineHeight: pxToRem(21),
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(12),
    textTransform: 'none'
  },
  labelSmall: {
    fontSize: typography.caption.fontSize,
    lineHeight: pxToRem(18),
    paddingLeft: pxToRem(8),
    paddingRight: pxToRem(8)
  },
  outlined: {
    backgroundImage: 'none',
    borderColor: colors.smoke[100],
    '$clickable&:focus,  $clickable&:hover': {
      backgroundColor: colors.root[0]
    },
    '&:hover': {
      backgroundColor: colors.root[0],
      borderColor: colors.smoke[300],
      color: colors.smoke[900]
    }
  },
  outlinedPrimary: {
    backgroundImage: 'none',
    borderColor: colors.blue[500],
    color: colors.blue[500],
    '$clickable&:focus, $clickable&:hover': {
      backgroundColor: colors.root[0]
    },
    '&:hover': {
      backgroundColor: colors.root[0],
      borderColor: colors.blue[700],
      color: colors.blue[700]
    }
  },
  sizeSmall: {
    height: pxToRem(23)
  }
};

export default MuiChip;
