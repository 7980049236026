import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '@loggi/mar';
import { Box, Typography, IconButton, Container } from '@material-ui/core';
import { ReactComponent as ShortArrowLeft } from '../../assets/images/short-arrow-left.svg';

export const TESTID_BACK_ARROW = 'header-with-return-arrow';

/**
 * This component is responsible for showing a header with a title and a return
 * button. Shows a title when one is provided, and show a return button when a
 * callback function is provided.
 *
 *
 * @param [title] {string} Optional header title
 * @param [onReturn] {func} Optional callback function for when the button is clicked
 * @param [bgcolor] {string} Optional color string for the component background
 * @param [fixed] {bool} Optional configuration to make the component fixed
 * @param [isDisabled] {bool} Optional configuration to disable the return button
 * @param [children] {element} Optional children to be added on the right top
 * @returns {JSX.Element}
 */
export default function HeaderWithReturn({
  title,
  onReturn,
  bgcolor,
  fixed,
  children,
  isDisabled
}) {
  return (
    <>
      {fixed ? (
        <FixedHeader
          title={title}
          onReturn={onReturn}
          bgcolor={bgcolor}
          isDisabled={isDisabled}
        >
          {children}
        </FixedHeader>
      ) : (
        <Header title={title} onReturn={onReturn} isDisabled={isDisabled}>
          {children}
        </Header>
      )}
    </>
  );
}

HeaderWithReturn.propTypes = {
  title: PropTypes.string,
  onReturn: PropTypes.func,
  bgcolor: PropTypes.string,
  fixed: PropTypes.bool,
  children: PropTypes.element,
  isDisabled: PropTypes.bool
};

HeaderWithReturn.defaultProps = {
  title: null,
  onReturn: null,
  bgcolor: colors.smoke[50],
  fixed: true,
  children: null,
  isDisabled: false
};

const FixedHeader = ({ title, onReturn, bgcolor, isDisabled, children }) => {
  return (
    <Box
      width="100%"
      position="fixed"
      pt={3}
      top="0"
      zIndex={3}
      bgcolor={bgcolor}
    >
      <Container maxWidth="xs">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={0.5}
        >
          <HeaderContent
            title={title}
            onReturn={onReturn}
            isDisabled={isDisabled}
          >
            {children}
          </HeaderContent>
        </Box>
      </Container>
    </Box>
  );
};

FixedHeader.propTypes = {
  title: PropTypes.string,
  onReturn: PropTypes.func,
  bgcolor: PropTypes.string,
  children: PropTypes.element,
  isDisabled: PropTypes.bool
};

FixedHeader.defaultProps = {
  title: null,
  onReturn: null,
  bgcolor: colors.smoke[50],
  children: null,
  isDisabled: false
};

const Header = ({ title, onReturn, isDisabled, children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      mt={1}
      justifyContent="space-between"
    >
      <HeaderContent title={title} onReturn={onReturn} isDisabled={isDisabled}>
        {children}
      </HeaderContent>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  onReturn: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.element
};

Header.defaultProps = {
  title: null,
  onReturn: null,
  isDisabled: false,
  children: null
};

const HeaderContent = ({ title, onReturn, isDisabled, children }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        {onReturn && (
          <IconButton
            onClick={onReturn}
            edge="start"
            data-testid={TESTID_BACK_ARROW}
            disabled={isDisabled}
          >
            <ShortArrowLeft
              fill={isDisabled ? colors.smoke[300] : colors.blue[500]}
            />
          </IconButton>
        )}
        {title && (
          <Box data-testid="header-with-return-title">
            <Typography component="span" variant="body2">
              <Box fontWeight="fontWeightBold">{title}</Box>
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {children}
      </Box>
    </>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.string,
  onReturn: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.element
};

HeaderContent.defaultProps = {
  title: null,
  onReturn: null,
  isDisabled: false,
  children: null
};
