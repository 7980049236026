import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { sortUnitLoad } from '../../api-rest';
import handleRestAPIError from '../../app/utils/rest-api-request';
import { ReceivingProcessContext } from './receiving-process-context';
import { SWITCHES } from '../../constants';
import { useFeature } from '../../app/hooks/use-feature';
import { PACKAGE_ORIGIN } from '../../app/enums';

const SortingRecommendationReceive = ({
  name,
  routingCode,
  decisionSiblingName
}) => {
  return (
    <Typography variant="body2">
      <Box fontWeight="fontWeightRegular" fontSize="1.75rem">
        {decisionSiblingName || routingCode}
      </Box>
      <Box fontWeight="fontWeightRegular" fontSize="1.75rem">
        {name}
      </Box>
    </Typography>
  );
};

SortingRecommendationReceive.defaultProps = {
  name: '',
  decisionSiblingName: ''
};

SortingRecommendationReceive.propTypes = {
  name: PropTypes.string,
  routingCode: PropTypes.string.isRequired,
  decisionSiblingName: PropTypes.string
};

const decisionRoutingCode = recommendation =>
  recommendation ? recommendation.routingCode : '';
const decisionName = recommendation =>
  recommendation ? recommendation.name : '';
const decisionColor = recommendation =>
  recommendation ? recommendation.color : colors.root[900];

const getDecisionValues = decision => {
  return {
    name: decision?.name || '',
    routingCode: decision?.expeditionCenterRoutingCode || '',
    color: decision?.color || colors.root[900]
  };
};

const SortingRecommendation = ({
  siblingSorterLicensePlate,
  sortingContext,
  barcode,
  isNewReceive
}) => {
  const [loading, setLoading] = React.useState(true);
  const [expeditionCenter, setExpeditionCenter] = React.useState('');
  const {
    recommendationsDict,
    setRecommendationsDict,
    lastPackageOrigin
  } = useContext(ReceivingProcessContext);

  const enableNewReceiveFlowWithSibling = useFeature(
    SWITCHES.enableNewReceiveFlowWithSibling
  );

  const [decisionSiblingName, setDecisionSiblingName] = useState('');

  useEffect(() => {
    setLoading(true);
    const recommendation = recommendationsDict[barcode];
    if (!recommendation) {
      setDecisionSiblingName('');

      sortUnitLoad(sortingContext, barcode)
        .then(response => {
          const { decision } = response.data;
          const { name, routingCode, color } = getDecisionValues(decision);
          setRecommendationsDict(previousRecommendationDict => ({
            ...previousRecommendationDict,
            [barcode]: { name, routingCode, color }
          }));

          const expeditionCenterName = response.data.ulEc?.name;
          if (expeditionCenterName) setExpeditionCenter(expeditionCenterName);
          setLoading(false);
        })
        .catch(err => {
          handleRestAPIError(err, () => setLoading(false));
        });

      if (
        enableNewReceiveFlowWithSibling &&
        siblingSorterLicensePlate &&
        lastPackageOrigin !== PACKAGE_ORIGIN.PACKAGE_ORIGIN_FLECHA
      )
        sortUnitLoad(siblingSorterLicensePlate, barcode)
          .then(_response => {
            if (_response.data.decision.name)
              setDecisionSiblingName(_response.data.decision.name);
          })
          .catch(err => {
            handleRestAPIError(err, () => {});
          });
    } else {
      setLoading(false);
    }
  }, [
    barcode,
    recommendationsDict,
    sortingContext,
    setRecommendationsDict,
    siblingSorterLicensePlate,
    enableNewReceiveFlowWithSibling,
    lastPackageOrigin
  ]);

  const oldSortingRecommendation = () => (
    <Typography component={Box} variant="body2">
      {!loading ? (
        <Box data-testid="recommendation-text">
          <Box
            style={{
              color: decisionColor(recommendationsDict[barcode]),
              fontWeight: 'bold'
            }}
          >
            {decisionName(recommendationsDict[barcode])}
          </Box>
          <Box>{expeditionCenter}</Box>
        </Box>
      ) : (
        <Skeleton />
      )}
    </Typography>
  );

  const newSortingRecommendation = () =>
    !loading ? (
      <SortingRecommendationReceive
        sortingContext={sortingContext}
        name={decisionName(recommendationsDict[barcode])}
        routingCode={decisionRoutingCode(recommendationsDict[barcode])}
        decisionSiblingName={decisionSiblingName}
      />
    ) : (
      <Skeleton />
    );

  return isNewReceive ? newSortingRecommendation() : oldSortingRecommendation();
};

SortingRecommendation.defaultProps = {
  isNewReceive: false
};

SortingRecommendation.propTypes = {
  sortingContext: PropTypes.string.isRequired,
  barcode: PropTypes.string.isRequired,
  isNewReceive: PropTypes.bool
};

export default SortingRecommendation;
