import React, { useEffect, useState, useContext } from 'react';

import {
  Box,
  Container,
  Typography,
  List,
  Button,
  Divider,
  Grid
} from '@material-ui/core';

import { colors } from '@loggi/mar';

import { useSnackbar } from 'notistack';
import SkeletonList from '../../../app/components/skeleton-list';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import HeaderWithReturn from '../../../app/components/header-with-return';
import pluralize from '../../../app/utils/pluralize';
import ActionContainerBottom from '../../../app/components/actions-container-bottom';
import handleRestAPIErrorWithMessage from '../../../app/utils/handle-rest-api-error-with-message';
import { formatTransferWeightText } from './trip-transfer-br-locale-utils';
import {
  formatBRL,
  formatMoneyToNumber
} from '../../../app/utils/br-locale-utils';

import {
  getTripCargo,
  startTripTransfers,
  getTripStats
} from '../../../api-rest';

import { CargoInfo } from './cargo-info';
import { TripProcessContext } from './trip-process-context';

import {
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  START_TRIP_TRANSFERS_ERROR_MSGS,
  TRIP_PAGE_STATES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  SWITCHES
} from '../../../constants';

import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import AsyncButton from '../../../app/components/async-button';

import { useFeature } from '../../../app/hooks/use-feature';
import { round, PGRProgress } from './pgr-progress';

export default function TripStartTransfers() {
  const {
    setPageState,
    trip,
    selectedDC,
    setCargos,
    cargos,
    setTripStats,
    tripStats
  } = useContext(TripProcessContext);

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [hasValidCargo, setHasValidCargo] = useState(true);
  const [pgrPercentage, setPGRPercentage] = useState(0);

  const [maxMerchandiseValueAllowed] = useState(
    formatMoneyToNumber(trip.maxMerchandiseValueAllowed)
  );

  const showTransferCargoWeight = useFeature(SWITCHES.showTransferCargoWeight);

  const enableShowPgrValues = useFeature(SWITCHES.enableShowPgrValues);

  const showTransferCargoMerchandiseValue = useFeature(
    SWITCHES.showTransferCargoMerchandiseValue
  );

  const calculateDefaultPadding = () => {
    if (
      (!showTransferCargoMerchandiseValue && showTransferCargoWeight) ||
      (showTransferCargoMerchandiseValue && !showTransferCargoWeight)
    ) {
      return '320px';
    }
    if (showTransferCargoMerchandiseValue && showTransferCargoWeight) {
      return '350px';
    }
    return '300px';
  };

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const baseErrorHandler = message => {
    showSnackbarAlert(message, 'error');
    setLoading(false);
  };

  const getCargos = async () => {
    try {
      setLoading(true);
      const response = await getTripCargo({
        tripId: trip.id,
        sourceRoutingCode: selectedDC?.routingCode
      });
      setLoading(false);

      if (!response.data?.cargo) {
        setCargos([]);
        setHasValidCargo(false);
        return;
      }

      const validCargo = response.data.cargo?.filter(c => c && !c.started);
      setCargos(validCargo);
      setHasValidCargo(validCargo.length > 0);
    } catch (error) {
      setLoading(false);

      handleRestAPIErrorWithMessage(
        BASE_TRIP_TRANSFER_ERROR_MSGS,
        error,
        baseErrorHandler
      );
    }
  };

  const getStats = async () => {
    try {
      setLoading(true);
      const response = await getTripStats(trip.id);
      setLoading(false);
      const cargoStats = response?.data?.cargoStats;
      if (cargoStats) {
        setTripStats(response.data.cargoStats);

        if (maxMerchandiseValueAllowed) {
          if (cargoStats?.merchandiseValue) {
            const percentage = round(
              (formatMoneyToNumber(cargoStats.merchandiseValue) /
                maxMerchandiseValueAllowed) *
                100,
              0.5
            );

            setPGRPercentage(percentage);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setTripStats({});
    }
  };

  useEffect(() => {
    getCargos();
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransferIds = () => {
    return [...new Set(cargos.map(cargo => cargo.transferId))];
  };

  const handleDistribute = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_START_TRIP_BEEP
    );

    setLoading(true);
    const transferIds = getTransferIds();
    const errorMessages = [];
    const transferIdsNotStarted = [];

    /* eslint-disable no-restricted-syntax */
    /* eslint-disable no-await-in-loop */
    for (const transferId of transferIds) {
      try {
        await startTripTransfers({
          tripId: trip.id,
          sourceRoutingCode: selectedDC?.routingCode,
          transferIds: [transferId]
        });
      } catch (error) {
        transferIdsNotStarted.push(transferId);
        handleRestAPIErrorWithMessage(
          {
            ...BASE_TRIP_TRANSFER_ERROR_MSGS,
            ...START_TRIP_TRANSFERS_ERROR_MSGS
          },
          error,
          message => {
            errorMessages.push(message);
          }
        );
      }
    }
    /* eslint-enable no-restricted-syntax */
    /* eslint-enable no-await-in-loop */

    if (errorMessages.length) {
      baseErrorHandler(errorMessages.join('\n'));

      const cargosNotStarted = cargos.filter(cargo =>
        transferIdsNotStarted.some(id => id === cargo.transferId)
      );
      setCargos(cargosNotStarted);
      setHasValidCargo(cargosNotStarted.length > 0);
    } else {
      showSnackbarAlert(
        'Pronto, tudo certo.\n As unidades foram distribuídas.'
      );
      setPageState(TRIP_PAGE_STATES.END_SUCCESS);
    }

    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_START_TRIP_BEEP
    );
    setLoading(false);
  };

  const redirectToEdit = () => {
    setPageState(TRIP_PAGE_STATES.EDIT_LIST_CARGO);
  };

  const goBack = () => {
    setPageState(TRIP_PAGE_STATES.BEEP_CARGO);
  };

  const destinationDescription = (destinationRoutingCode, destinationName) => {
    return `${destinationRoutingCode} ${destinationName}`;
  };

  return (
    <>
      <Box height="100vh" overflow="auto">
        <Container
          maxWidth="xs"
          data-testid="trip-start-transfers"
          disableGutters
        >
          <Box
            pb={10}
            style={{ paddingTop: `calc(${calculateDefaultPadding()} - 5vh)` }}
          >
            {!loading ? (
              <>
                <Divider />
                <List>
                  {cargos?.map(item => (
                    <CargoInfo
                      key={item.licensePlate}
                      cargoCompany={item.sourceCompanyName}
                      cargoDestination={destinationDescription(
                        item.destinationRoutingCode,
                        item.destinationName
                      )}
                      cargoFinalDestination={destinationDescription(
                        item.finalDestinationRoutingCode,
                        item.finalDestinationName
                      )}
                      showTransshipmentInfo={
                        item.destinationRoutingCode !==
                        item.finalDestinationRoutingCode
                      }
                      cargoLicensePlate={item.licensePlate}
                      packageCount={item.packageCount}
                      showPackageCount
                      cargoTotalWeightInGrams={item.totalGrossWeightInGrams}
                      showCargoWeight={showTransferCargoWeight}
                      cargoMerchandiseValue={item.totalValue}
                      showCargoMerchandiseValue={
                        showTransferCargoMerchandiseValue
                      }
                    />
                  ))}
                </List>
              </>
            ) : (
              <Box data-testid="cargo-loading">
                <SkeletonList />
                <SkeletonList />
                <SkeletonList />
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Container maxWidth="xs" disableGutters>
        <HeaderWithReturn
          title=""
          onReturn={goBack}
          bgcolor={colors.root[0]}
          isDisabled={loading}
        />
        <Box position="fixed" top={15} bgcolor={colors.root[0]}>
          <Box px={3} pb={1}>
            <Box paddingTop={8}>
              <Typography>
                <Box fontWeight="fontWeightBold">Distribuir</Box>
              </Typography>
            </Box>
            <Box flex={1}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    style={{ marginBottom: -10 }}
                    color="secondary"
                    variant="h2"
                    data-testid="total-cargo"
                  >
                    {cargos?.length}
                  </Typography>
                  <Typography style={{ top: 0 }} variant="caption">
                    &nbsp;
                    {pluralize({
                      singular: 'unidade',
                      count: cargos?.length
                    })}
                  </Typography>
                </Grid>
                <Grid item sx={{ paddingTop: '10px' }}>
                  <Typography>
                    {showTransferCargoWeight && tripStats?.totalWeight && (
                      <Box>
                        Peso:{' '}
                        <Typography
                          component="span"
                          data-testid="transfer-weight"
                        >
                          {formatTransferWeightText(
                            tripStats.totalWeight,
                            tripStats.maxWeight
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                  <Typography>
                    {showTransferCargoMerchandiseValue && (
                      <Box>
                        Valor:{' '}
                        <Typography
                          component="span"
                          data-testid="transfer-merchandise-value"
                        >
                          {formatBRL(tripStats.merchandiseValue)}
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                </Grid>
                {enableShowPgrValues && Boolean(maxMerchandiseValueAllowed) && (
                  <Grid item xs={12}>
                    <Box pt={2}>
                      <PGRProgress percentage={pgrPercentage} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box mt={1.5} mb={1}>
              <Typography variant="subtitle2">
                <Box>Confira a lista com o motorista antes de embarcar</Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>

      <ActionContainerBottom>
        <Container maxWidth="xs">
          <Box mb={3} flex="initial">
            <Box display="flex">
              <Box flex={1} mr={1}>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={redirectToEdit}
                  disabled={loading || !hasValidCargo}
                >
                  Editar
                </Button>
              </Box>
              <Box flex={1} ml={1}>
                <AsyncButton
                  data-testid="distribuir-button"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleDistribute}
                  isDisabled={loading || !hasValidCargo}
                >
                  Distribuir
                </AsyncButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </ActionContainerBottom>
    </>
  );
}
