import { RESPONSE_STATUS } from '../constants';
import { dispatchBag, updateVehicleUnitLoad } from '../api-rest';
import handleRestAPIError from '../app/utils/rest-api-request';

/**
 * This service will call for Dispatch on Loggi Web. 'errorHandler' and 'unexpectedErrorHandler'
 * will be called with error messages.
 */
const dispatch = async ({
  bagSeal,
  geo,
  dynamicDispatcher,
  confirmationStatus,
  errorHandler,
  isNuvemApi = false
}) => {
  const response = await dispatchBag(
    bagSeal,
    geo,
    dynamicDispatcher,
    confirmationStatus,
    isNuvemApi
  ).catch(err => handleRestAPIError(err, errorHandler));

  if (!response) return null;

  return {
    orderStatus: response.data.order_status,
    success: response.data.status === RESPONSE_STATUS.OK,
    errors: [{ message: response.data.error_msg }]
  };
};

const updateVehicleUnitLoadApi = async (vehicleType, licensePlate) => {
  try {
    const response = await updateVehicleUnitLoad({
      vehicleType,
      licensePlate
    });

    return response?.data?.status === RESPONSE_STATUS.OK;
  } catch (error) {
    return false;
  }
};

export { dispatch, updateVehicleUnitLoadApi };
