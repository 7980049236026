import palette from '../palette';

const MuiSwitch = {
  colorPrimary: {
    '&$checked': {
      color: palette.success.dark
    },
    '&$checked + $track': {
      backgroundColor: palette.success.main
    },
    '&:not($checked)': {
      color: palette.error.dark
    },
    '&:not($checked) + $track': {
      backgroundColor: palette.error.main
    }
  }
};

export default MuiSwitch;
