const CompanyHierarchy = (() => {
  let instance;

  function CompanyHierarchySingleton() {
    if (instance) {
      return instance;
    }

    instance = this;

    /**
     * This property stores the logged company ID we have in URL
     */
    this.loggedCompanyId = undefined;

    /**
     * This property contains the list of childrens that a logged company might have
     */
    this.childrens = [];

    this.setChildren = children => {
      this.childrens = [...children];
    };

    this.getChildren = () => {
      return this.childrens;
    };

    this.setLoggedCompanyId = loggedCompanyId => {
      this.loggedCompanyId = loggedCompanyId;
    };

    this.getLoggedCompanyId = () => {
      return this.loggedCompanyId;
    };

    this.hasNoLoggedCompany = () => {
      return this.loggedCompanyId === undefined;
    };
  }

  return {
    getInstance: () => instance || new CompanyHierarchySingleton()
  };
})();

export default CompanyHierarchy;
