import { VEHICLE_LICENSE_PLATE_LENGTH } from '../../constants';

const vehicleLicensePlateIsInvalid = ({ vehicleLicensePlate }) => {
  return (
    !vehicleLicensePlate ||
    !/^[a-zA-z]{3}[0-9][A-Za-z0-9][0-9]{2}$/.test(vehicleLicensePlate) ||
    vehicleLicensePlate.length !== VEHICLE_LICENSE_PLATE_LENGTH
  );
};

export default vehicleLicensePlateIsInvalid;
