import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import { useEffect, useReducer } from 'react';

// These are some variables that should be shared across multiple calls to this
// hook, introducing a Singleton pattern here
let remoteConfigActivatePromise = null;
let configs = null;

const serializeConfigs = () => {
  const stringConfigs = {};
  Object.entries(configs).forEach(([key, value]) => {
    const stringValue = value.asString();
    let parsedValue;
    try {
      parsedValue = JSON.parse(stringValue);
    } catch {
      parsedValue = null;
    }

    stringConfigs[key] = parsedValue || stringValue;
  });
  return stringConfigs;
};

export const preloadFirebaseConfigs = () => {
  const remoteConfig = firebase.remoteConfig();
  remoteConfigActivatePromise = remoteConfig.fetchAndActivate();
  return remoteConfigActivatePromise.then(() => {
    // Uses the getAll method to cache all the current remote configs
    configs = remoteConfig.getAll();

    Sentry.setContext('Firebase', { 'Remote Configs': serializeConfigs() });
  });
};

export const getPreloadedConfig = config => configs[config];

/**
 * This is a helper to get the firebase config, in pure js functions
 * that doesn't depend on the react lifecycle
 * @param remoteConfigParam
 * @returns {*} - Returns a promise that resolve to a remote config Value instance
 * https://firebase.google.com/docs/reference/js/firebase.remoteconfig.Value
 */
export const getRemoteConfig = remoteConfigParam => {
  if (!remoteConfigActivatePromise) {
    remoteConfigActivatePromise = preloadFirebaseConfigs();
  }

  return remoteConfigActivatePromise.then(() => configs[remoteConfigParam]);
};

/**
 * Firebase reducer to be used with useReducer hook
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @returns {Object} Updated state
 */
/* eslint-disable consistent-return, default-case */
const remoteConfigReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_DONE':
      return {
        ...state,
        isLoading: false,
        value: action.payload
      };
  }
};
/* eslint-enable consistent-return, default-case */

/**
 * This is a method that initializes the useReducer state, checking if the
 * requested config is already cached, and if so the initial state will
 * start with the value, avoiding a second render
 * @param remoteConfigParam
 * @returns {{isLoading: boolean, hasError: boolean, value: string}}
 */
const initReducerState = remoteConfigParam => {
  let value = null;

  if (remoteConfigParam && configs) {
    value = configs[remoteConfigParam].asString();
  }

  return {
    isLoading: false,
    hasError: false,
    value
  };
};

/**
 * Custom Hook
 * Get Firebase Remote Config parameter value
 *
 * @param {String|Null} remoteConfigParam
 *
 * @returns {Object}
 *
 * Usage example:
 * const { value } = useRemoteConfig('test_feature_switch')
 */
export const useRemoteConfig = remoteConfigParam => {
  const [state, dispatch] = useReducer(
    remoteConfigReducer,
    remoteConfigParam,
    initReducerState
  );

  useEffect(() => {
    if (state.value === null) {
      dispatch({ type: 'FETCH_INIT' });
      getRemoteConfig(remoteConfigParam).then(value => {
        dispatch({ type: 'FETCH_DONE', payload: value.asString() });
      });
    }
  }, [remoteConfigParam, state.value]);

  return state;
};
