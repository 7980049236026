import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, Container, Button } from '@material-ui/core';
import { colors } from '@loggi/mar';
import Typography from '@material-ui/core/Typography';
import { pxToRem } from '@loggi/mar/src/utils';
import HeaderWithClose from '../../app/components/header-with-close';
import BarcodeRenderer from '../../app/components/barcode-renderer';
import useWindowDimensions from '../../app/hooks/use-window-dimensions';

const PreviewBarcodeDialog = ({ open, unitLoadLpn, onClose }) => {
  const { innerWidth, innerHeight } = useWindowDimensions();

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box padding={0} clone>
      <Dialog
        open={open}
        fullScreen
        data-testid="preview-barcode-dialog"
        PaperProps={{
          style: {
            backgroundColor: colors.root[0],
            boxShadow: 'none'
          }
        }}
      >
        <Box overflow="hidden" clone>
          <Container maxWidth="xs">
            <Box
              display="flex"
              displayPrint="none"
              flexDirection="column"
              overflow="hidden"
              height="100vh"
            >
              <Box>
                <HeaderWithClose testId="close-button" handleClose={onClose} />
              </Box>
              <Box flex={1} overflow="hidden">
                <Box
                  display="flex"
                  height="100%"
                  alignContent="center"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  style={{ transform: 'rotate(270deg)' }}
                  mr={2.5}
                >
                  <Box mb={-3}>
                    <Typography style={{ fontSize: pxToRem(80) }}>
                      <Box
                        component="span"
                        fontWeight="fontWeightBold"
                        color={colors.root[900]}
                      >
                        {unitLoadLpn.slice(-4)}
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <BarcodeRenderer
                      barcode={unitLoadLpn}
                      options={{
                        width: Math.min(innerWidth * 0.01, 5),
                        height: innerHeight * 0.13,
                        displayValue: true,
                        fontSize: 28,
                        font: 'Open Sans',
                        textMargin: 8
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mt={1.5} mb={3}>
                <Button
                  data-testid="print-barcode"
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          display="none"
          displayPrint="flex"
          flexDirection="column"
          overflow="hidden"
          alignItems="flex-start"
        >
          <Box flex={1} overflow="hidden">
            <Box
              display="flex"
              height="100%"
              alignContent="center"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Box mb={-1.5}>
                <Typography style={{ fontSize: pxToRem(40) }}>
                  <Box
                    component="span"
                    fontWeight="fontWeightBold"
                    color={colors.root[900]}
                  >
                    {unitLoadLpn.slice(-4)}
                  </Box>
                </Typography>
              </Box>
              <Box>
                <BarcodeRenderer
                  barcode={unitLoadLpn}
                  options={{
                    width: 1.5,
                    height: 60,
                    displayValue: true,
                    fontSize: 14,
                    font: 'Open Sans',
                    textMargin: 2
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

PreviewBarcodeDialog.defaultProps = {
  open: true
};

PreviewBarcodeDialog.propTypes = {
  open: PropTypes.bool,
  unitLoadLpn: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PreviewBarcodeDialog;
