import React from 'react';
import PropTypes from 'prop-types';

import { Box, Container, Dialog, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';

import { makeStyles } from '@material-ui/core/styles';
import PackageList from '../../app/components/package-list';
import SharedPropTypes from '../../app/shared-prop-types';
import HeaderWithCancel from '../../app/components/header-with-cancel';

const useStyles = makeStyles({
  dialog: {
    padding: 0
  },
  textSpacing: {
    marginBottom: pxToRem(15)
  },
  root: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    height: '100vh'
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  flexHeader: {
    flex: '0'
  },
  flexBody: {
    flex: '1',
    overflowY: 'auto'
  }
});

export default function DirtyUnitLoadDialog({
  handleClose,
  packages,
  userName
}) {
  const classes = useStyles();

  return (
    <Dialog
      data-testid="dirty-unit-load-dialog"
      open
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
      fullScreen
      className={classes.dialog}
    >
      <Container maxWidth="xs" className={classes.root} justify="center">
        <Box className={classes.flexBox}>
          <Box className={classes.flexHeader}>
            <Box paddingTop={2.5}>
              <Box>
                <HeaderWithCancel
                  testId="close-dirty-unit-load-dialog"
                  handleClose={handleClose}
                />
                <Box paddingTop={1.5}>
                  <Typography component="div" variant="body1" gutterBottom>
                    <Box fontWeight="fontWeightBold">Distribuir</Box>
                  </Typography>
                </Box>
                <Box paddingTop={1.5}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    className={classes.textSpacing}
                  >
                    Olá, {userName}. Ainda tem pacotes sob sua responsabilidade!
                  </Typography>
                  <Typography variant="subtitle1" align="left">
                    Armazene usando o &quot;Separar&quot; antes de iniciar um
                    novo processo.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box marginTop={2.5} className={classes.flexBody}>
            <PackageList packages={packages} />
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
}

DirtyUnitLoadDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  packages: SharedPropTypes.packages.isRequired,
  userName: PropTypes.string.isRequired
};
