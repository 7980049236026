import { useFormikContext } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useCoverageParamsContext } from '../coverage-params';
import BaseTimeField from './base-time-field.component';
import { useFeature } from '../remote-config';
import {
  TIME_GAP_BEFORE_NEXT_SCHEDULING_IN_MINUTES,
  MOMENT_SHIFT_END_WITH_GAP,
  MOMENT_SHIFT_START
} from './constants';

/**
 * <StartTimeField /> - This component is only a wrapper to apply a custom
 * validation logic to the <BaseTimeField />
 * @returns {*}
 * @constructor
 */
const StartTimeField = ({ endTimeFieldName, dateFieldName, fieldName }) => {
  const { t } = useTranslation('one');
  const { values, errors } = useFormikContext();
  const coverageParamsContext = useCoverageParamsContext();
  const shouldUseCoverageParams = useFeature('should_use_coverage_params');

  const date = values[dateFieldName];
  const endTime = values[endTimeFieldName];
  const invalidDate = Boolean(errors[dateFieldName]);

  let momentShiftStart = MOMENT_SHIFT_START;
  let momentShiftEndWithGap = MOMENT_SHIFT_END_WITH_GAP;

  if (shouldUseCoverageParams) {
    momentShiftStart =
      coverageParamsContext?.momentShiftStart || MOMENT_SHIFT_START;
    momentShiftEndWithGap =
      coverageParamsContext?.momentShiftEndWithGap || MOMENT_SHIFT_END_WITH_GAP;
  }

  const validate = useCallback(
    startTime => {
      if (invalidDate) {
        return undefined;
      }
      if (!startTime) {
        return t('baseTimeField.errorMessages.requiredStartTime');
      }
      if (!startTime.isValid()) {
        return t('baseTimeField.errorMessages.invalidTime');
      }
      if (
        startTime.isBefore(momentShiftStart, 'hour') ||
        startTime.isAfter(momentShiftEndWithGap, 'minute')
      ) {
        return t('baseTimeField.errorMessages.timeRangeOutOfService', {
          endTime: momentShiftEndWithGap.format('HH:mm'),
          startTime: momentShiftStart.format('HH:mm')
        });
      }

      if (endTime && startTime.isSameOrAfter(endTime, 'minute')) {
        return t('baseTimeField.errorMessages.startTimeAfterEndTime');
      }
      const initialSlotTimeMinutes =
        coverageParamsContext?.initialSlotTimeMinutes;
      const safeInitialSlotHours = coverageParamsContext?.safeInitialSlotHours;

      const now = moment();
      const enableCheckStandardTimeGap = shouldUseCoverageParams
        ? !initialSlotTimeMinutes
        : true;

      if (
        date &&
        now.isSame(date, 'day') &&
        enableCheckStandardTimeGap &&
        startTime.diff(now, 'minutes') <
          TIME_GAP_BEFORE_NEXT_SCHEDULING_IN_MINUTES
      ) {
        return t('baseTimeField.errorMessages.startTimeBeforeMinTime', {
          minutes: TIME_GAP_BEFORE_NEXT_SCHEDULING_IN_MINUTES
        });
      }
      if (date) {
        date.hours(startTime.get('hour')).minutes(startTime.get('minutes'));
      }

      if (
        shouldUseCoverageParams &&
        date &&
        date.diff(now, 'minutes') < initialSlotTimeMinutes
      ) {
        return initialSlotTimeMinutes >= 60
          ? t('baseTimeField.errorMessages.invalidSlotInterval', {
              count: safeInitialSlotHours
            })
          : t('baseTimeField.errorMessages.startTimeBeforeMinTime', {
              minutes: initialSlotTimeMinutes
            });
      }

      return undefined;
    },
    [
      coverageParamsContext,
      date,
      endTime,
      invalidDate,
      momentShiftEndWithGap,
      momentShiftStart,
      shouldUseCoverageParams,
      t
    ]
  );

  return (
    <BaseTimeField
      disabled={invalidDate}
      fieldName={fieldName}
      label={t('baseTimeField.startTimeLabel')}
      validate={validate}
    />
  );
};

StartTimeField.propTypes = {
  endTimeFieldName: PropTypes.string,
  dateFieldName: PropTypes.string,
  fieldName: PropTypes.string.isRequired
};

StartTimeField.defaultProps = {
  endTimeFieldName: undefined,
  dateFieldName: undefined
};

export default StartTimeField;
