import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TokenField = ({ fieldName, minValue, maxValue, disabled }) => {
  const { t } = useTranslation('one');
  const [field, meta] = useField({
    name: fieldName,
    validate: newValue => {
      let errorMessage = '';
      if (!newValue) {
        errorMessage = t('tokenField.errorMessages.requiredField');
      } else if (newValue?.length < minValue) {
        errorMessage = t('tokenField.errorMessages.minLengthToken');
      } else if (newValue?.length > maxValue) {
        errorMessage = t('tokenField.errorMessages.maxLengthToken');
      }
      return errorMessage;
    }
  });

  const { name, onChange, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field` }}
      InputLabelProps={{ required: true }}
      label={t('tokenField.label')}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required
      value={value}
      variant="outlined"
      disabled={disabled}
    />
  );
};

TokenField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

TokenField.defaultProps = {
  disabled: false
};

export default TokenField;
