import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, ListItem, ListItemText } from '@material-ui/core';
import pluralize from '../../../app/utils/pluralize';

export default function DistributionUnitLoadCargoInfo({
  distributionCargoLicensePlate,
  distributionCargoCompany,
  distributionCargoDestination,
  packageCount
}) {
  return (
    <Box clone padding={3}>
      <ListItem
        disableGutters
        key={distributionCargoLicensePlate}
        data-testid="distribution-cargo-list-item"
      >
        <ListItemText
          primary={
            <Typography
              gutterBottom
              component="div"
              style={{ fontWeight: 600 }}
              variant="subtitle2"
            >
              {distributionCargoLicensePlate}
            </Typography>
          }
          secondary={
            <>
              {distributionCargoCompany ? (
                <Typography
                  component="div"
                  variant="subtitle2"
                  color="textSecondary"
                  data-testid="distribution-cargo-info-company"
                >
                  Pacote de {distributionCargoCompany}
                </Typography>
              ) : (
                <Typography
                  component="div"
                  variant="subtitle2"
                  color="textSecondary"
                  data-testid="distribution-cargo-info-package-count"
                >
                  {`com ${packageCount} ${pluralize({
                    singular: 'pacote',
                    count: packageCount
                  })}`}
                </Typography>
              )}
              {
                <Typography
                  component="div"
                  variant="subtitle2"
                  color="textSecondary"
                  data-testid="distribution-cargo-info-destination"
                >
                  para {distributionCargoDestination}
                </Typography>
              }
            </>
          }
        />
      </ListItem>
    </Box>
  );
}

DistributionUnitLoadCargoInfo.defaultProps = {
  distributionCargoLicensePlate: '',
  distributionCargoCompany: '',
  distributionCargoDestination: '',
  packageCount: 0
};

DistributionUnitLoadCargoInfo.propTypes = {
  distributionCargoLicensePlate: PropTypes.string,
  distributionCargoCompany: PropTypes.string,
  distributionCargoDestination: PropTypes.string,
  packageCount: PropTypes.number
};
