import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getPreloadedConfig } from './firebase-remote-config-helpers';

const useFeature = (feature, forceCompanyId) => {
  let { companyId } = useParams();
  if (forceCompanyId) companyId = forceCompanyId;

  return useMemo(() => {
    const configValue = getPreloadedConfig(feature).asString();
    const normalizedValue = configValue.replace(/\s/g, '');

    if (!normalizedValue) return false;
    if (normalizedValue.toLowerCase() === 'all') return true;

    return normalizedValue.split(',').includes(String(companyId));
  }, [companyId, feature]);
};

export default useFeature;
