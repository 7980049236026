import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export default function CpfFormatInput({
  inputRef,
  onChange,
  className,
  placeholder,
  required,
  onFocus
}) {
  const handleOnValueChange = values => {
    onChange({
      target: {
        value: values.value
      }
    });
  };

  return (
    <NumberFormat
      getInputRef={inputRef}
      data-testid="cpf-format-input"
      onValueChange={handleOnValueChange}
      isNumericString
      format="###.###.###-##"
      className={className}
      placeholder={placeholder}
      required={required}
      onFocus={onFocus}
    />
  );
}

CpfFormatInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
};
