import { makeStyles } from '@material-ui/core/styles';

const useSignInStyle = makeStyles(({ typography }) => ({
  btn: {
    fontSize: typography.pxToRem(14),
    marginTop: typography.pxToRem(20)
  },
  federatedBtn: {
    backgroundColor: 'white',
    marginBottom: typography.pxToRem(15),
    '& svg': {
      marginRight: typography.pxToRem(16)
    }
  },
  input: { marginBottom: typography.pxToRem(15) },
  divider: {
    marginTop: typography.pxToRem(25),
    marginBottom: typography.pxToRem(5)
  },
  dividerText: {
    fontSize: typography.pxToRem(10),
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    zIndex: 2,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      borderTop: 'solid 1px #EAEEF4',
      zIndex: -1
    }
  },
  dividerLine: {
    margin: 0,
    display: 'inline-block',
    padding: `0 ${typography.pxToRem(8)}`,
    background: '#fff'
  },
  createAccountText: {
    textAlign: 'center',
    fontSize: typography.pxToRem(14),
    paddingRight: typography.pxToRem(2),
    '&:last-child': {
      fontWeight: 600
    }
  },
  loading: {
    color: 'white'
  },
  backgroundMobile: {
    backgroundColor: 'white'
  }
}));

export default useSignInStyle;
