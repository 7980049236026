import { BUTTON_BORDER_RADIUS } from '../constants';
import { pxToRem } from '../utils';

const MuiButtonGroup = {
  root: {
    borderRadius: pxToRem(BUTTON_BORDER_RADIUS)
  }
};

export default MuiButtonGroup;
