import React, { useRef } from 'react';
import {
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { PhotoCamera, CancelPresentationTwoTone } from '@material-ui/icons';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import useInterval from '../hooks/use-interval';
import { ZebraCameraScanner, ScanditCameraScanner } from './camera-scanner';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';

export function KeyboardScannerTemplate({
  onRead,
  onChange,
  loading,
  disable,
  handleCameraClick,
  placeholder
}) {
  const [packageBarcode, setPackageBarcode] = React.useState('');

  const handleSubmit = async event => {
    event.preventDefault();

    // We're not assuming Physical Evidence for Desktops for now.
    const oldBarcode = packageBarcode;
    setPackageBarcode('');
    await onRead(oldBarcode);
  };

  // Create a reference to the input and force focus.
  const inputField = React.createRef();
  useInterval(() => {
    // It's not clear, but there are cases when this can run before rendering.
    if (inputField.current && !disable) {
      inputField.current.focus();
    }
  }, 500);

  return (
    <Box alignItems="center" justifyContent="space-around" p={0}>
      <Box flex={1} />
      <form onSubmit={handleSubmit} data-testid="barcode-reader">
        <TextField
          style={{ width: '100%' }}
          data-testid="barcode-reader-text-field"
          variant="outlined"
          size="small"
          inputRef={inputField}
          type="text"
          disabled={loading || disable}
          InputProps={{
            inputProps: {
              'aria-label': 'barcode-reader'
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="open-camera"
                  onClick={handleCameraClick}
                  edge="end"
                >
                  <PhotoCamera color="primary" />
                </IconButton>
              </InputAdornment>
            )
          }}
          placeholder={placeholder}
          onChange={event => {
            setPackageBarcode(event.target.value);
            if (onChange) {
              onChange(event.target.value);
            }
          }}
          value={packageBarcode}
          required
        />
      </form>
      <Box flex={1} ml={1}>
        {loading && <CircularProgress data-testid="circular-progress" />}
      </Box>
    </Box>
  );
}

KeyboardScannerTemplate.propTypes = {
  onRead: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disable: PropTypes.bool.isRequired,
  handleCameraClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

KeyboardScannerTemplate.defaultProps = {
  placeholder: ''
};

export function CameraScannerTemplate({ onRead, handleCameraClick }) {
  const { scanditScannerUsers, isLoading } = React.useContext(
    FeatureSwitchContext
  );
  const enableScanditScanner = useRef(false);
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  /**
   * [TODO - llvieira] After tests with Scandit, remove this logic.
   *
   * This code should be outsing an useEffect so that it can run
   * before the component rendering. Otherwise, it would break the UI.
   */
  if (!isLoading) {
    if (scanditScannerUsers.includes(authenticatedUser.email)) {
      enableScanditScanner.current = true;
    }
  }

  return (
    <Box
      data-testid="camera-scanner"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      height="7rem"
      p={0.05}
    >
      <Box position="absolute" zIndex="1" right="0" bottom="70px">
        <IconButton
          data-testid="close-camera"
          onClick={handleCameraClick}
          edge="end"
        >
          <CancelPresentationTwoTone color="primary" fontSize="large" />
        </IconButton>
      </Box>

      {isLoading ? (
        <CircularProgress data-testid="loading-cameras" />
      ) : (
        [
          enableScanditScanner.current ? (
            <ScanditCameraScanner key="0" onDetected={onRead} />
          ) : (
            <ZebraCameraScanner key="1" onDetected={onRead} />
          )
        ]
      )}
    </Box>
  );
}

CameraScannerTemplate.propTypes = {
  onRead: PropTypes.func.isRequired,
  handleCameraClick: PropTypes.func.isRequired
};
