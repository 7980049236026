import { useEffect } from 'react';
import {
  updateServiceWorker,
  addControllerChangeListener
} from '../../service-worker-operations';
import useInterval from './use-interval';
import { CHECK_FOR_UPDATES_INTERVAL_IN_MS } from '../../constants';
import usePersistedState, { persistedStateKeys } from './use-persisted-state';

function usePWAUpdate({
  /**
   * Setup args that should be called as true only once in an app.
   */
  checkForUpdatesPeriodically = false,
  watchForControllerChange = false
} = {}) {
  const [theresAnUpdateAvailable, setUpdateAvailability] = usePersistedState(
    persistedStateKeys.appUpdateAvailable,
    false
  );

  useEffect(() => {
    if (watchForControllerChange) {
      addControllerChangeListener(() => {
        setUpdateAvailability(true);
      });
    }
  }, [setUpdateAvailability, watchForControllerChange]);

  useInterval(() => {
    if (checkForUpdatesPeriodically) {
      updateServiceWorker();
    }
  }, CHECK_FOR_UPDATES_INTERVAL_IN_MS);

  const markAppAsUpdated = () => setUpdateAvailability(false);

  const updateApp = () => {
    markAppAsUpdated();
    window.location.reload();
  };

  return {
    updateApp,
    theresAnUpdateAvailable
  };
}
export default usePWAUpdate;
