import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import { Avatar, Box, Button, Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@loggi/mar/src/utils';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import DistributeWaitDriver from './distribute-wait-driver';
import DriverDetailsDialog from './driver-details-dialog';
import SharedPropTypes from '../../app/shared-prop-types';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import StrictPackageIdentification from './strict-package-identification';
import RemainingWarningDialog from './remaining-warning-dialog';
import { movePackagesForCustodyTransfer } from '../../api-rest';
import AsyncButton from '../../app/components/async-button';
import handleRestAPIError from '../../app/utils/rest-api-request';
import CheckUnitLoadService from './check-unit-load-packages-service';
import DirtyUnitLoadDialog from './dirty-unit-load-dialog';
import logLogicalMismatchesService from './log-logical-mismatches-service';
import { GeolocationContext } from '../../geo';
import {
  ACTIVITY,
  DISTRIBUTE_STEP_TYPE,
  LOGICAL_MISMATCH_OPERATIONAL_PROCESSES,
  OPERATIONAL_PROCESS,
  SWITCHES
} from '../../constants';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';
import PackagePosition from '../../app/components/package-position';
import { useFeature } from '../../app/hooks/use-feature';
import BasicChip from '../../app/components/basic-chip';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';

const useStyles = makeStyles(thisTheme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    '& > *': {
      margin: thisTheme.typography.pxToRem(1)
    },
    height: '97vh',
    overflow: 'hidden',
    flexGrow: 1
  },
  avatar: {
    width: pxToRem(60),
    height: pxToRem(60)
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  flexBody: {
    flex: '1 1 auto'
  },
  flexBottom: {
    flex: '0 1 auto'
  }
}));

function SkipConferenceButton({ hasAccessToPrepareBag, handleSkipConference }) {
  return hasAccessToPrepareBag ? (
    <Box alignSelf="stretch" mb={1.5} mt={1.5}>
      <Button
        data-testid="prepare-bag-button"
        fullWidth
        variant="outlined"
        color="primary"
        onClick={handleSkipConference}
      >
        Pular conferência
      </Button>
    </Box>
  ) : null;
}

SkipConferenceButton.propTypes = {
  hasAccessToPrepareBag: PropTypes.bool.isRequired,
  handleSkipConference: PropTypes.func.isRequired
};

export default function DistributePackageReader({
  goBack,
  bag,
  driver,
  offerAcceptedAt,
  checkedPackagesIds,
  destinationUnitLoadLpn,
  contextLicensePlate,
  userPk,
  caraCrachaRecord
}) {
  const classes = useStyles();

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [geo] = React.useContext(GeolocationContext);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [checkedPackages, setCheckedPackages] = React.useState(
    bag.packages.filter(p => checkedPackagesIds.includes(p.pk))
  );
  const [remainingPackages, setRemainingPackages] = React.useState(
    bag.packages.filter(p => !checkedPackagesIds.includes(p.pk))
  );
  const [waitDriverDialogOpen, setWaitDriverDialogOpen] = React.useState(false);
  const [remainingDialogOpen, setRemainingDialogOpen] = React.useState(false);
  const [openDriverDetailDialog, setOpenDriverDetailDialog] = React.useState(
    false
  );
  const [unexpectedPackages, setUnexpectedPackages] = React.useState([]);
  const [dirtyUnitLoadDialogOpen, setDirtyUnitLoadDialogOpen] = React.useState(
    false
  );
  const [skipConference, setSkipConference] = React.useState(false);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const {
    showPackagePositionOnDistribute,
    enableSkipPackagesBeepInDistribute
  } = React.useContext(FeatureSwitchContext);

  const hasAccessToPrepareBag = useFeature(
    SWITCHES.enableDistributionIndexesOnIdentify
  );

  const skipPackagesChecks = (() => {
    return (
      bag.skipDistributeStep === DISTRIBUTE_STEP_TYPE.SKIP_PACKAGES_CHECKS ||
      enableSkipPackagesBeepInDistribute.includes(authenticatedUser.email)
    );
  })();

  const lastCheckedPackagePosition =
    checkedPackages[checkedPackages.length - 1];

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const unexpectedErrorHandler = err => {
    Sentry.captureException(err);
    baseErrorHandler(err.message);
  };

  const clearForNewReading = () => {
    setErrorMessage('');
  };

  const updatePackagesStates = barcode => {
    setCheckedPackages([
      ...checkedPackages,
      ...bag.packages.filter(p => p.identifier === barcode)
    ]);
    setRemainingPackages(
      remainingPackages.filter(p => p.identifier !== barcode)
    );
  };

  const handleUnexpectedPackages = packages => {
    setUnexpectedPackages(packages);
    setDirtyUnitLoadDialogOpen(true);
  };

  const movePackagesToUnitLoad = async packagesIdsToMove => {
    const unitLoadData = await CheckUnitLoadService(
      packagesIdsToMove,
      handleUnexpectedPackages,
      baseErrorHandler,
      unexpectedErrorHandler
    );

    if (!unitLoadData) return false;

    try {
      await movePackagesForCustodyTransfer(
        packagesIdsToMove,
        destinationUnitLoadLpn,
        bag.seal,
        selectedDistributionCenter?.distributionCenterId
      );
      return true;
    } catch (err) {
      handleRestAPIError(err, baseErrorHandler);
      return false;
    }
  };

  const handlePackageReadSuccess = async currentPackage => {
    clearForNewReading();
    const barcode = currentPackage?.identifier;

    if (!barcode) {
      baseErrorHandler('Código de barras não encontrado');
    } else if (!checkedPackages.some(p => p.identifier === barcode)) {
      updatePackagesStates(barcode);
      playSuccessBeep();
      trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_PACKAGE);
      trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_PACKAGE);
    }
  };

  const openRemainingPackages = () => {
    setRemainingDialogOpen(true);
  };

  const handleLogicalMismatches = async () => {
    const lng = geo.coords.longitude;
    const lat = geo.coords.latitude;
    await logLogicalMismatchesService({
      lat,
      lng,
      sortingContextLicensePlate: contextLicensePlate,
      userId: userPk,
      unitLoadLicensePlate: bag.seal,
      operationalProcess:
        LOGICAL_MISMATCH_OPERATIONAL_PROCESSES.OPERATIONAL_PROCESS_DISTRIBUTE,
      packages: remainingPackages
    });
  };

  const moveToWaitDriver = async packagesIdsToMove => {
    const movePackagesSucess = await movePackagesToUnitLoad(packagesIdsToMove);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_FINISH_BEEP);

    if (movePackagesSucess) {
      trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.WAITING_FOR_DRIVER);
      setWaitDriverDialogOpen(true);
    }
  };

  const proceedWithRemainingPackages = async () => {
    // If this request fails we still proceed with the rest of the process
    await handleLogicalMismatches().catch(Sentry.captureException);

    const packagesIdsToMove = checkedPackages.map(p => p.pk);
    moveToWaitDriver(packagesIdsToMove);

    setRemainingDialogOpen(false);
  };

  const finishExperimentalBagReading = async () => {
    setCheckedPackages([...bag.packages]);
    setRemainingPackages([]);

    const packagesIdsToMove = bag.packages.map(p => p.pk);
    await moveToWaitDriver(packagesIdsToMove);
  };

  const finishPackagesChecksBagReading = async () => {
    if (remainingPackages.length > 0) {
      openRemainingPackages();
    } else {
      const packagesIdsToMove = checkedPackages.map(p => p.pk);
      await moveToWaitDriver(packagesIdsToMove);
    }
  };

  const finishReading = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_FINISH_BEEP
    );
    if (skipPackagesChecks) {
      await finishExperimentalBagReading();
    } else {
      await finishPackagesChecksBagReading();
    }
  };

  const handleClose = () => {
    goBack();
  };

  const handleSkipConference = () => {
    setSkipConference(true);
    finishExperimentalBagReading();
  };

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_BAG);
    trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_PACKAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="distribute-package-reader">
      <Container maxWidth="xs" className={classes.root}>
        <Box paddingTop={2.5} className={classes.flexBox}>
          <Box className={classes.flexBody}>
            <HeaderWithCancel
              testId="distribute-package-reader-cancel-button"
              handleClose={handleClose}
            />
            <Box paddingTop={1.5}>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightBold">Distribuir</Box>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <PackagePosition
                checkedPackages={checkedPackages}
                skipPackagesChecks={skipPackagesChecks}
                identifier="distribute"
                lastCheckedPackagePosition={lastCheckedPackagePosition}
                totalPackages={bag.numPackages}
                showPackagePositionOnDistribute={
                  showPackagePositionOnDistribute
                }
              />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mt={3}>
                <Typography variant="body1">
                  <Box fontWeight="fontWeightBold">{driver.fullName}</Box>
                  {caraCrachaRecord?.needOnboardingInstructions ? (
                    <Box my={1}>
                      <BasicChip message="Novo" />
                    </Box>
                  ) : (
                    <Box my={1} fontWeight="fontWeightRegular">
                      mais de 3 rotas
                    </Box>
                  )}
                  <Box fontWeight="fontWeightRegular">
                    CPF: {driver.docCpf?.number || ''}
                  </Box>
                </Typography>
              </Box>
              <Box
                marginLeft="auto"
                onClick={() => setOpenDriverDetailDialog(true)}
                data-testid="driver-avatar"
              >
                <Avatar
                  src={driver.photoUrl.profileHighRes}
                  className={classes.avatar}
                />
              </Box>
            </Box>
            <Typography variant="body1">
              <Box display="flex" mt={1.5}>
                <Box fontWeight="fontWeightBold">oferta aceita&nbsp;</Box>
                <Box fontWeight="fontWeightRegular">
                  {offerAcceptedAt &&
                    moment(offerAcceptedAt).format('DD MMM HH:mm')}
                </Box>
              </Box>
            </Typography>
            <Box mt={2}>
              <Typography variant="body1">LACRE {bag.seal}</Typography>
            </Box>

            <Box className="centered" mt={2.5}>
              {!waitDriverDialogOpen && !skipPackagesChecks && (
                <StrictPackageIdentification
                  expectedPackages={bag.packages}
                  before={clearForNewReading}
                  success={handlePackageReadSuccess}
                  fail={baseErrorHandler}
                  source="xd_app_distribute_package"
                  sortingContext={contextLicensePlate}
                  notes={`Bipado na checagem de pacotes ao distribuir a saca ${
                    bag.seal
                  }`}
                />
              )}
            </Box>
            {errorMessage && (
              <Box mt={1.5}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
          </Box>

          <Box className={classes.flexBottom}>
            {(checkedPackages.length > 0 || skipPackagesChecks) && (
              <Box>
                <AsyncButton
                  fullWidth
                  data-testid="distribute-finish-reading-btn"
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={finishReading}
                >
                  Enviar lista
                </AsyncButton>
              </Box>
            )}
          </Box>

          <SkipConferenceButton
            hasAccessToPrepareBag={hasAccessToPrepareBag}
            handleSkipConference={handleSkipConference}
          />

          {waitDriverDialogOpen && (
            <DistributeWaitDriver
              destinationUnitLoadLpn={destinationUnitLoadLpn}
              checkedPackages={checkedPackages}
              numPackages={bag.numPackages}
              skipPackagesChecks={skipPackagesChecks}
              skipConferenceClicked={skipConference}
            />
          )}

          {remainingDialogOpen && (
            <RemainingWarningDialog
              packageCount={remainingPackages.length}
              handleClose={() => setRemainingDialogOpen(false)}
              proceed={proceedWithRemainingPackages}
            />
          )}

          {openDriverDetailDialog && (
            <DriverDetailsDialog
              driver={driver}
              handleClose={() => setOpenDriverDetailDialog(false)}
            />
          )}

          {dirtyUnitLoadDialogOpen && (
            <DirtyUnitLoadDialog
              packages={unexpectedPackages}
              handleClose={() => {
                setDirtyUnitLoadDialogOpen(false);
              }}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

DistributePackageReader.propTypes = {
  offerAcceptedAt: PropTypes.string.isRequired,
  bag: PropTypes.shape({
    seal: PropTypes.string,
    numPackages: PropTypes.number,
    packages: SharedPropTypes.packages,
    skipDistributeStep: PropTypes.string
  }).isRequired,
  driver: SharedPropTypes.driver.isRequired,
  goBack: PropTypes.func.isRequired,
  checkedPackagesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  destinationUnitLoadLpn: PropTypes.string.isRequired,
  contextLicensePlate: PropTypes.string.isRequired,
  userPk: PropTypes.number.isRequired,
  caraCrachaRecord: SharedPropTypes.caraCrachaRecord.isRequired
};
