import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Container,
  Dialog,
  Typography,
  Button
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@loggi/mar/src/utils';

import showSnackbar from '../../app/components/snackbar/snackbar-container';
import {
  CARA_CRACHA_FOR_DRIVER,
  OPERATIONAL_PROCESS,
  ACTIVITY
} from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

const useStyles = makeStyles(thisTheme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    '& > *': {
      margin: thisTheme.typography.pxToRem(1)
    },
    height: '97vh',
    overflow: 'hidden',
    flexGrow: 1
  },
  dialog: {
    padding: 0
  },
  largeAvatar: {
    width: pxToRem(160),
    height: pxToRem(160)
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  flexBody: {
    flex: '1 1 auto'
  },
  flexBottom: {
    flex: '0 1 auto'
  },
  expandedButtons: {
    marginBottom: pxToRem(15)
  }
}));

export default function CaraCrachaCheckDialog({
  handleClose,
  fetchCaraCrachaStatus,
  redirectToIdentify,
  itineraryId,
  driverId
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const caraCrachaNotFoundSnack = async () => {
    showSnackbar({
      variant: 'warning',
      message: 'Opa, ainda não atualizamos.',
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const checkDriverCaraCracha = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_CARA_CRACHA_BEEP
    );

    const caraCrachaStatus = await fetchCaraCrachaStatus(driverId, itineraryId);
    if (!caraCrachaStatus) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_CARA_CRACHA_BEEP
      );
      return;
    }
    if (caraCrachaStatus !== CARA_CRACHA_FOR_DRIVER.CREATED) {
      caraCrachaNotFoundSnack();
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_CARA_CRACHA_BEEP
      );
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      open
      fullScreen
      data-testid="cara-cracha-check-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
      className={classes.dialog}
    >
      <Container maxWidth="xs" className={classes.root}>
        <Box paddingY={2.5} className={classes.flexBox}>
          <Box className={classes.flexBox}>
            <Box className={classes.flexBody}>
              <Box paddingTop={2.5} align="center">
                <Avatar className={classes.largeAvatar} />
              </Box>
              <Box paddingTop={2.5}>
                <Typography variant="h6" align="center">
                  Pode demorar um pouco até a foto que você enviou subir ou se
                  você ainda não tirou é só começar pelo identificar.
                </Typography>
              </Box>
            </Box>
            <Box className={classes.flexBottom}>
              <Button
                data-testid="redirect-to-identify"
                size="large"
                variant="outlined"
                color="primary"
                onClick={redirectToIdentify}
                fullWidth
                className={classes.expandedButtons}
              >
                Identificar saca
              </Button>
              <Button
                data-testid="reload-cara-cracha"
                size="large"
                variant="contained"
                color="primary"
                onClick={checkDriverCaraCracha}
                fullWidth
              >
                Atualizar meu envio
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
}

CaraCrachaCheckDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  fetchCaraCrachaStatus: PropTypes.func.isRequired,
  redirectToIdentify: PropTypes.func.isRequired,
  itineraryId: PropTypes.number.isRequired,
  driverId: PropTypes.number.isRequired
};
