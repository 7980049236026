import { getOrCreateUnitLoad } from '../../api-rest';
import { getUser } from '../../api';
import extractDataFromGraphQLResponse from '../../app/utils/graphql-request';
import handleRestAPIError from '../../app/utils/rest-api-request';

const fetchUnitLoadData = async (user, errorHandler) => {
  const unitLoadResponse = await getOrCreateUnitLoad(
    `CUSTODYTRANSFER:${user.pk}`,
    `Operator ${user.pk} Custody Transfer`
  ).catch(err => handleRestAPIError(err, errorHandler));

  if (!unitLoadResponse) {
    return null;
  }

  const { error, unitLoad, packagesInfo = [] } = unitLoadResponse.data;
  if (error) {
    errorHandler(error);
    return null;
  }

  if (!unitLoad) return null;

  return {
    userName: user.fullName,
    userPk: user.pk,
    destinationUnitLoadlicensePlate: unitLoad.license_plate,
    packages: packagesInfo.map(p => ({
      pk: parseInt(p.package_id, 10),
      identifier: p.barcode
    }))
  };
};

const fetchUser = async errorHandler => {
  const response = await getUser(errorHandler);
  if (!response) return null;

  const data = await extractDataFromGraphQLResponse(response);

  return data.user;
};

const getDistributeUnitLoadData = async errorHandler => {
  const user = await fetchUser(errorHandler);
  if (!user) return null;

  return fetchUnitLoadData(user, errorHandler);
};

const CheckUnitLoadService = async (
  bagPackagesIds,
  failHandler,
  errorHandler,
  unexpectedErrorHandler
) => {
  const unitLoadData = await getDistributeUnitLoadData(errorHandler).catch(
    unexpectedErrorHandler
  );

  if (!unitLoadData) return null;

  const unexpectedItineraryPackages = unitLoadData.packages.filter(
    p => !bagPackagesIds.includes(p.pk)
  );

  if (unexpectedItineraryPackages.length) {
    failHandler(unexpectedItineraryPackages, unitLoadData.userName);
    return null;
  }

  return unitLoadData;
};

export default CheckUnitLoadService;
