import wretch from 'wretch';
import { ACCESS_DENIED, LOGIN } from './constants';
import oneAuthMiddleware from './one-auth-middleware';

// Wretch object is immutable which means that you can configure, store and reuse instances.
// This seems like a good place to put things that are common to all requests.
//
// More: https://github.com/elbywan/wretch#because-configuration-should-not-rhyme-with-repetition

const publicApi = wretch()
  .url(process.env.REACT_APP_PUBLIC_API_URL)
  .middlewares([oneAuthMiddleware])
  .resolve(response =>
    response
      .unauthorized(() => {
        window.location.assign(LOGIN);
      })
      .forbidden(() => {
        window.location.assign(ACCESS_DENIED);
      })
  );

export default publicApi;
