import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      height: '96%',
      maxHeight: '100%'
    }
  },
  alertIcon: {
    '& .MuiAlert-icon': {
      marginTop: typography.pxToRem(10)
    }
  }
}));

const EmergencyRedispatchLabelDialog = ({ open, onConfirm, onCancel }) => {
  const { paper, alertIcon } = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper }}
      data-testid="emergency-redispatch-label"
    >
      <DialogTitle>
        <Box pt={4}>
          <Typography variant="h6">
            Opa, esse embarcador não aceita entregar com Correios. Tem certeza
            que quer gerar uma etiqueta?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <Alert severity="info" className={alertIcon}>
            <Box py={1}>
              <AlertTitle variant="h6">É bom lembrar!</AlertTitle>
              <Typography variant="body2">
                Só envie esse pacote para os Correios se for um caso de
                emergência e tiver a permissão. Em caso de dúvidas, fale com o
                suporte.
              </Typography>
            </Box>
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row-reverse' }}
          my={1.5}
        >
          <Box>
            <Button
              fullWidth
              data-testid="confirm-button-emergency"
              size="large"
              variant="outlined"
              color="primary"
              onClick={onConfirm}
            >
              Etiquetar
            </Button>
          </Box>
          <Box pt={{ xs: 2.5, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
            <Button
              fullWidth
              data-testid="cancel-button-emergency"
              size="large"
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EmergencyRedispatchLabelDialog.defaultProps = {
  open: true
};

EmergencyRedispatchLabelDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default EmergencyRedispatchLabelDialog;
