import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { rules } from './access-control-rules';
import getUserType from './access-control-service';
import { useDistributionCenter } from './distribution-center-provider';

export const check = (actions, userType) => {
  if (actions.length === 0) {
    return true;
  }
  if (userType === null) {
    return false;
  }
  const permissions = rules[userType];
  if (!permissions) {
    return false;
  }

  const permissionMapped = actions.map(action => permissions.includes(action));

  return permissionMapped.some(permission => permission);
};

/**
 * Can component is responsible for handling conditional rendering based on authorization rules.
 *
 * Example of usage:
 *
 * <Can actions={[ACTIONS.SORT]}>
 *  <YourComponent />
 * </Can>
 *
 * Only users allowed to do sort operations will be able to access <YourComponent />.
 */
export function Can({ actions, children, reverse }) {
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    async function getData() {
      const userType = getUserType(selectedDistributionCenter);

      const _isAllowed = check(actions, userType);
      setIsAllowed(reverse ? !_isAllowed : _isAllowed);
    }

    getData();
  }, [actions, children, reverse, selectedDistributionCenter]);

  if (isAllowed) {
    return children;
  }
  return null;
}

Can.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool
};

Can.defaultProps = {
  reverse: false
};
