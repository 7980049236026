/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import * as Yup from 'yup';
import classNames from 'classnames';
import {
  SIGN_IN_FAILURE,
  SIGN_UP_FAILURE,
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import SignupCompanyTemplate from '@loggi/components/src/one/sign-up-company';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { GoogleSignIn, AppleSignIn, Divider } from '../signin';
import PasswordInput from '../../components/password-input/password-input.component';
import { errorHandler, showNotificationMessage } from '../../service';
import { SIGN_IN_ERRORS } from '../signin/constants';
import {
  CONTINUE_BUTTON_ID,
  EMAIL_INPUT_BUTTON_ID,
  LOADING_ID,
  NAME_INPUT_BUTTON_ID,
  SIGNUP_ERRORS,
  MARKETING_UTMS_LOCAL_STORAGE_KEY
} from './constants';
import { LOGIN_ROUTE } from '../../routes/constants';
import PASSWORD_MIN_LENGTH from '../constants';

const initialValues = {
  email: '',
  name: '',
  password: ''
};

const useSignupFormStyle = makeStyles(({ palette, spacing, typography }) => ({
  progress: {
    color: palette.common.white
  },
  backgroundMobile: {
    backgroundColor: 'white'
  },
  labels: {
    marginBottom: spacing(1)
  },
  registerLink: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  haveAccountText: {
    textAlign: 'center',
    fontSize: typography.pxToRem(14),
    paddingRight: typography.pxToRem(2)
  },
  haveAccountLink: {
    fontWeight: 600
  }
}));

const SignupForm = ({ showAppleSignIn, showGoogleSignIn }) => {
  const {
    signUp,
    state: { error: stateError }
  } = useAmplifyAuth();
  const styles = useSignupFormStyle();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['signupForm', 'signIn']);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const queryParams = useQueryParams();

  const signupValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('validations.requiredName')),
    email: Yup.string()
      .required(t('validations.requiredEmail'))
      .email(t('validations.invalidEmail')),
    password: Yup.string()
      .required(t('validations.requiredPassword'))
      .min(PASSWORD_MIN_LENGTH, t('validations.minLengthPassword'))
  });

  const signInErrorNotification = useCallback(
    signInError => {
      const { NotAuthorizedException } = SIGN_IN_ERRORS;

      if (signInError.code === NotAuthorizedException.code) {
        return NotAuthorizedException[signInError.message]
          ? t(
              `signIn:errorsMessages.NotAuthorizedException.${
                NotAuthorizedException[signInError.message]
              }`
            )
          : t('signIn:errorsMessages.NotAuthorizedException.fallbackMessage');
      }
      return t(
        `signIn:errorsMessages.${SIGN_IN_ERRORS[signInError.name].code}`
      );
    },
    [t]
  );

  const onSubmitSignUp = (formValues, { setSubmitting }) => {
    setLoading(true);
    const { email, name, password } = formValues;

    setSubmitting(false);

    signUp(email.toLowerCase(), name.trim(), password);
  };

  const getErrorFeedback = useCallback(
    error => {
      if (error.event === SIGN_UP_FAILURE) {
        const signupErrorMessage = error.message.message;
        const signUpErrorCode = error.message.code;
        return signupErrorMessage.includes(
          SIGNUP_ERRORS[signUpErrorCode]?.message
        )
          ? t('signupForm:errorsMessages.LoggiUserAlreadyExists')
          : t(
              `signupForm:errorsMessages.${
                SIGNUP_ERRORS[error.message.name].code
              }`
            );
      }

      return signInErrorNotification(error.message);
    },
    [signInErrorNotification, t]
  );

  const showDefaultErrorNotificationMessage = useCallback(
    error => {
      if (error.event === SIGN_IN_FAILURE) {
        return showNotificationMessage(
          t('signIn:errorsMessages.DefaultException'),
          'error',
          enqueueSnackbar
        );
      }

      return showNotificationMessage(
        t('signupForm:errorsMessages.DefaultException'),
        'error',
        enqueueSnackbar
      );
    },
    [enqueueSnackbar, t]
  );

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        ...utmParams
      } = queryParams;
      localStorage.setItem(
        MARKETING_UTMS_LOCAL_STORAGE_KEY,
        JSON.stringify({
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          ...utmParams
        })
      );
    }
    if (stateError && stateError !== USER_NOT_AUTHENTICATED_ERROR) {
      const isKnownError = Boolean(
        SIGNUP_ERRORS[(stateError.message?.name)] ||
          SIGN_IN_ERRORS[(stateError.message?.name)]
      );

      if (isKnownError) {
        const errorFeedback = getErrorFeedback(stateError);
        showNotificationMessage(errorFeedback, 'error', enqueueSnackbar);
      } else {
        errorHandler(stateError);
        showDefaultErrorNotificationMessage(stateError);
      }

      setLoading(false);
    }
  }, [
    stateError,
    getErrorFeedback,
    enqueueSnackbar,
    t,
    showDefaultErrorNotificationMessage,
    queryParams
  ]);

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitSignUp,
    validationSchema: signupValidationSchema
  });

  const {
    handleSubmit,
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = formik;

  return (
    <>
      <SignupCompanyTemplate
        formTitles={t('signupForm.titles', { returnObjects: true })}
        formSubtitles={t('signupForm.subtitles', { returnObjects: true })}
        enableSummary={!isMobile}
      >
        <FormikProvider value={formik}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            data-testid="sign-up-form"
          >
            <TextField
              id={NAME_INPUT_BUTTON_ID}
              margin="normal"
              name="name"
              onChange={handleChange}
              onBlur={() => setFieldTouched('name')}
              fullWidth
              label={t('inputsLabel.name')}
              variant="outlined"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              className={styles.labels}
            />
            <TextField
              id={EMAIL_INPUT_BUTTON_ID}
              margin="normal"
              onChange={handleChange}
              onBlur={() => setFieldTouched('email')}
              name="email"
              fullWidth
              label={t('inputsLabel.email')}
              variant="outlined"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              className={styles.labels}
            />
            <PasswordInput
              handleChange={handleChange}
              password={values.password}
              handleBlur={handleBlur}
              error={touched.password && errors.password}
              label={t('inputsLabel.password')}
              className={styles.labels}
            />
            <Box pt={2} width="100%">
              <Button
                color="primary"
                disabled={loading}
                data-testid={CONTINUE_BUTTON_ID}
                fullWidth
                variant="contained"
                type="submit"
              >
                {!loading && t('buttons.continue')}
                {loading && (
                  <CircularProgress
                    data-testid={LOADING_ID}
                    className={styles.progress}
                    size={26}
                  />
                )}
              </Button>
            </Box>
            {(showGoogleSignIn || showAppleSignIn) && <Divider />}
            {showGoogleSignIn && <GoogleSignIn />}
            {showAppleSignIn && <AppleSignIn />}
            <Box margin={2}>
              <Typography align="center" className={styles.haveAccountText}>
                {t('signupForm.alreadyRegistered')}
                <Link href={LOGIN_ROUTE}>
                  <styles
                    className={classNames(
                      styles.haveAccountText,
                      styles.haveAccountLink
                    )}
                  >
                    {t('signupForm.loginLink')}
                  </styles>
                </Link>
              </Typography>
            </Box>
          </form>
        </FormikProvider>
      </SignupCompanyTemplate>
    </>
  );
};

SignupForm.propTypes = {
  showAppleSignIn: PropTypes.bool,
  showGoogleSignIn: PropTypes.bool
};

SignupForm.defaultProps = {
  showAppleSignIn: true,
  showGoogleSignIn: true
};

export default SignupForm;
