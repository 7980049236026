import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  Box,
  ButtonBase,
  Divider,
  fade,
  makeStyles,
  Popover,
  Typography
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import React from 'react';
import { EMOJI } from '../../constants';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    userName: {
      color: colors.smoke[900],
      fontSize: 16
    },
    email: {
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: colors.smoke[700],
      fontSize: 12
    },
    menuItem: {
      color: colors.blue[500],
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(2),
      width: '100%',
      fontFamily: 'Open Sans',
      fontSize: 14,
      '&:hover': {
        backgroundColor: fade(palette.primary.light, 0.1)
      },
      '&.Mui-disabled': {
        color: palette.text.disabled
      },
      '&.Mui-disabled path': {
        fill: palette.text.disabled
      },
      [breakpoints.up('sm')]: {
        padding: spacing(1.5, 2)
      }
    },
    picture: {
      color: palette.grey[900],
      backgroundColor: palette.common.white,
      borderColor: palette.grey[500],
      borderStyle: 'solid',
      borderWidth: 'thin'
    },
    popover: {
      '& .MuiPopover-paper.MuiPaper-root': {
        marginTop: spacing(1),
        [breakpoints.only('xs')]: {
          width: 'calc(100% - 48px)'
        },
        [breakpoints.up('sm')]: {
          width: typography.pxToRem(320)
        }
      }
    }
  })
);

const UserInfoMenu = ({ anchorElement, open, handleClose }) => {
  const {
    state: { authenticatedUser },
    signOut
  } = useAmplifyAuth();
  const styles = useStyles();

  const avatarLetter = authenticatedUser?.name
    ? authenticatedUser?.name[0]
    : undefined;

  return (
    <Popover
      anchorEl={anchorElement}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      className={styles.popover}
      elevation={24}
      marginThreshold={24}
      onClose={handleClose}
      open={open}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Box display="flex" p={2}>
        <Box ml={avatarLetter ? 2 : 0} flex={1} style={{ minWidth: 0 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" style={{ lineHeight: '20px' }}>
              <Box
                fontWeight="fontWeightBold"
                component="strong"
                className={styles.userName}
              >
                Olá, {authenticatedUser.name}!
                <span role="img" aria-label="Waving hand">
                  &nbsp;{EMOJI.waving_hand}
                </span>
              </Box>
            </Typography>
          </Box>
          <br />
          <Typography variant="body2" className={styles.email}>
            {authenticatedUser.email} • {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <ButtonBase
        alignitems="center"
        className={styles.menuItem}
        data-testid="sign-out-btn"
        onClick={signOut}
        variant="button"
      >
        Sair
      </ButtonBase>
    </Popover>
  );
};

UserInfoMenu.defaultProps = {
  anchorElement: null
};

UserInfoMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorElement: PropTypes.instanceOf(Element)
};

export default UserInfoMenu;
