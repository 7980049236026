import {
  AWS_COGNITO_IMPERSONATION_ERRORS,
  useAmplifyAuth
} from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ScreenHeader from '../../components/screen-header.component';
import { ACCESS_DENIED } from '../../routes/constants';
import { showNotificationMessage } from '../../service';
import { BUTTON_ID, EMAIL_INPUT_ID, PROGRESS_BUTTON_ID } from './constants';

const useImpersonationFormStyle = makeStyles(({ palette }) => ({
  progressButton: {
    color: palette.common.white
  }
}));

const ImpersonationForm = () => {
  const { t } = useTranslation('impersonationForm');
  const styles = useImpersonationFormStyle();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { state, impersonationSignIn } = useAmplifyAuth();

  const { error: stateError } = state;

  const emailImpersonationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailInvalid'))
  });

  const showNotification = () =>
    showNotificationMessage(t('success'), 'success', enqueueSnackbar);

  useEffect(() => {
    if (stateError) {
      setLoading(false);
      const errorCode = stateError.message.code;
      if (
        errorCode ===
        AWS_COGNITO_IMPERSONATION_ERRORS.UserLambdaValidationException.code
      ) {
        return push(ACCESS_DENIED);
      }

      const isKnownError = AWS_COGNITO_IMPERSONATION_ERRORS[errorCode];

      const errorMessage = isKnownError
        ? t(`awsCognitoErrors.${errorCode}.message`)
        : t('awsCognitoErrors.DefaultException.message');

      return showNotificationMessage(errorMessage, 'error', enqueueSnackbar);
    }

    return undefined;
  }, [stateError, push, enqueueSnackbar, t]);

  const _initiateImpersonation = formValues => {
    const { email } = formValues;

    setLoading(true);
    impersonationSignIn(email, showNotification);
  };

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <ScreenHeader
          title={t('header.message')}
          subTitle={t('subHeader.message')}
        />
      </OneTemplateSummary>
      <OneTemplateContent>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={emailImpersonationSchema}
          onSubmit={_initiateImpersonation}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                direction="column"
                justify="space-around"
                alignItems="center"
              >
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  inputProps={{ 'data-testid': EMAIL_INPUT_ID }}
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="email"
                  fullWidth
                  label={t('emailInput.label')}
                  variant="outlined"
                  value={values.email}
                />

                <Box pt={2} width="100%">
                  <Button
                    disabled={loading}
                    color="primary"
                    data-testid={BUTTON_ID}
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    {!loading && t('button.text')}
                    {loading && (
                      <CircularProgress
                        data-testid={PROGRESS_BUTTON_ID}
                        size={26}
                        className={styles.progressButton}
                      />
                    )}
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default ImpersonationForm;
