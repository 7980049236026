import moment from 'moment';

export const mobilePhoneInputMask = '(99) 99999-9999';
export const phoneInputMask = '(99) 9999-9999';
export const phoneRegex = /^[0-9]{10,11}$/;

export const ASYNC_PENDING = 'async-pending';
export const DATE_CHRISTMAS = 25;
export const DATE_CHRISTMAS_EVE = 24;
export const DATE_NEW_YEARS = 1;
export const DATE_NEW_YEARS_EVE = 31;
export const MONTH_DECEMBER = 11;
export const MONTH_JANUARY = 0;
export const SCHEDULING_DAYS_FROM_NOW = 30;
export const SHIFT_END = '19:00';
export const SHIFT_START = '08:00';
export const TIME_DIFF_BETWEEN_SCHEDULINGS_IN_MINUTES = 90;
export const TIME_GAP_BEFORE_NEXT_SCHEDULING_IN_MINUTES = 60;
export const TIME_GAP_BEFORE_SHIFT_END_IN_MINUTES = 30;
export const MIN_COLLECT_TIME_WINDOW_HOUR_DEFAULT = 1;
export const MIN_COLLECT_TIME_WINDOW_MINUTES_DEFAULT = 30;

export const MOMENT_SHIFT_START = moment(SHIFT_START, 'HH:mm');
export const MOMENT_SHIFT_END_WITH_GAP = moment(SHIFT_END, 'HH:mm').subtract(
  TIME_GAP_BEFORE_SHIFT_END_IN_MINUTES,
  'minutes'
);

export const weekDays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
];
