import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

function getTextWithEmphasis(text, emphasis) {
  if (!text.includes(emphasis)) {
    return text;
  }
  return (
    <>
      {text.substring(0, text.indexOf(emphasis))}
      <Typography style={{ fontWeight: 'bold' }} display="inline">
        {emphasis}
      </Typography>
      {text.substring(text.indexOf(emphasis) + emphasis.length)}
    </>
  );
}

export default function TextWithBoldEmphasis(props) {
  const { text, emphasis, typographyVariant } = props;

  return (
    <Typography variant={typographyVariant}>
      {getTextWithEmphasis(text, emphasis)}
    </Typography>
  );
}

TextWithBoldEmphasis.propTypes = {
  text: PropTypes.string.isRequired,
  emphasis: PropTypes.string,
  typographyVariant: PropTypes.string
};

TextWithBoldEmphasis.defaultProps = {
  emphasis: '',
  typographyVariant: 'subtitle2'
};
