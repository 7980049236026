import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { getSortingContext } from '../../api-rest';

import { ACTIVITY, OPERATIONAL_PROCESS } from '../../constants';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { BarcodeReader } from './barcode-readers';
import handleRestAPIError from '../utils/rest-api-request';
import { ActivityTrackingContext } from '../activity-tracking/activity-tracking-provider';

export const requestSortingContext = async (
  lnp,
  errorHandler,
  disableFetchDecisions = false
) => {
  const response = await getSortingContext(lnp, disableFetchDecisions).catch(
    err => {
      handleRestAPIError(err, errorHandler);
    }
  );
  if (!response) return [response, undefined];

  return [response.data, response.status];
};

export const requestAggregatedSortingContext = async (
  lnp,
  errorHandler,
  disableFetchDecisions = false
) => {
  const [parent, parentStatus] = await requestSortingContext(
    lnp,
    errorHandler,
    disableFetchDecisions
  );
  const siblingLnp = parent?.sortingContext?.siblingsSorterLicensePlate || [];
  const siblings = await Promise.all(
    siblingLnp.map(sLnp => requestSortingContext(sLnp, errorHandler))
  );

  if (!parent) return [parent, parentStatus];

  const decisions = parent.sortingContext?.decisions || [];

  const response = [
    {
      ...parent,
      sortingContext: {
        ...{
          ...parent.sortingContext,
          decisions
        }
      }
    },
    parentStatus
  ];

  siblings.forEach(s => {
    const [sibling, siblingStatus] = s;
    if (!sibling || siblingStatus > 500 || !response[0] || response[1] > 500) {
      response[0] = '';
      response[1] = siblingStatus;
      return;
    }
    if (sibling.sortingContext.decisions) {
      response[0].sortingContext.decisions.push(
        ...sibling.sortingContext.decisions
      );
    }
    response[1] = Math.max(siblingStatus, response[1]);
  });

  return response;
};
export default function SortingContextReader({
  onRead,
  aggregated,
  placeholder,
  disable,
  disableFetchDecisions
}) {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const baseErrorHandler = message => {
    setErrorMessage(`Erro ao identificar contexto: ${message}`);
    playErrorBeep();
  };

  const unexpectedErrorHandler = err => {
    Sentry.captureException(err);
    baseErrorHandler(err);
  };

  const handleRead = async sortingBarcode => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_CONTEXT_BEEP);

    setErrorMessage('');
    setLoading(true);

    const fetchSortingContext = aggregated
      ? requestAggregatedSortingContext
      : requestSortingContext;

    const [response, responseStatus] = await fetchSortingContext(
      sortingBarcode,
      baseErrorHandler,
      disableFetchDecisions
    ).catch(err => {
      unexpectedErrorHandler(err);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_CONTEXT_BEEP);
      return [undefined, undefined];
    });

    setLoading(false);

    if (!response || responseStatus >= 500) {
      playErrorBeep();
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_CONTEXT_BEEP);
      return;
    }

    const { sortingContext } = response;

    if (!sortingContext) {
      baseErrorHandler(`Contexto "${sortingBarcode}" não encontrado`);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_CONTEXT_BEEP);
      return;
    }

    playSuccessBeep();
    await onRead(sortingContext);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.SORT_CONTEXT_BEEP);
  };

  return (
    <Box mt={1.5}>
      <BarcodeReader
        onRead={handleRead}
        loading={loading}
        placeholder={placeholder}
        disable={disable}
        disableFetchDecisions={disableFetchDecisions}
      />
      {errorMessage && (
        <Box mt={1.5}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
    </Box>
  );
}

SortingContextReader.propTypes = {
  onRead: PropTypes.func,
  aggregated: PropTypes.bool,
  placeholder: PropTypes.string,
  disable: PropTypes.bool,
  disableFetchDecisions: PropTypes.bool
};

SortingContextReader.defaultProps = {
  onRead: () => {},
  aggregated: false,
  placeholder: 'Leia o código da operação',
  disable: false,
  disableFetchDecisions: false
};
