const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENT_POOL,
  aws_cognito_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_WEB_CLIENT,
  oauth: {
    domain: process.env.REACT_APP_AWS_COGNITO_DOMAINANME,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
};

export default awsmobile;
