import MomentUtils from '@date-io/moment';
import { FormControl } from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

/**
 * <TimeField />
 * @param fieldName - used as field name for formik useField
 * @param label
 * @param validate
 * @param disabled
 * @returns {*}
 * @constructor
 */
const BaseTimeField = ({ fieldName, label, validate, disabled, required }) => {
  const [field, meta, helpers] = useField({
    name: fieldName,
    validate
  });

  const { validateField } = useFormikContext();

  /** Why does this useEffect exist?
   * This useEffect is here as an workaround to the deficiency of formik's
   * ability to validate fields dependant on others. There's an open issue
   * about this on their repo: https://github.com/jaredpalmer/formik/issues/1737.
   * With this whenever the validate method is updated, a new validate will
   * be triggered with a setTimout to give time to the validate propagate
   * within the useField hook */
  useEffect(() => {
    const timeoutId = setTimeout(() => validateField(fieldName), 50);
    return () => clearTimeout(timeoutId);
  }, [fieldName, validateField]);

  const { name, onBlur, value = null } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;
  const hasError = Boolean(error) && touched;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl variant="outlined" fullWidth>
        <KeyboardTimePicker
          ampm={false}
          disabled={disabled}
          disableToolbar
          error={hasError}
          fullWidth
          helperText={hasError && error}
          id={name}
          inputVariant="outlined"
          keyboardIcon=""
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={setValue}
          required={required}
          value={value}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

BaseTimeField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired,
  required: PropTypes.bool
};

BaseTimeField.defaultProps = {
  required: false
};

export default BaseTimeField;
