import React from 'react';

export default function useDeviceDetect() {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const userAgent =
      typeof navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|webOS|IEMobile|WPDesktop|Windows Phone/i
      )
    );
    setIsMobile(mobile);
  }, []);

  return { isMobile };
}
