import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Avatar,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import pluralize from '../../../app/utils/pluralize';
import CheckboxListItem from '../../../app/components/checkbox-list-item';
import {
  fromKgToBrazilianText,
  formatBRL
} from '../../../app/utils/br-locale-utils';

function CargoInfoItem({
  cargoLicensePlate,
  cargoCompany,
  cargoDestination,
  cargoFinalDestination,
  showTransshipmentInfo,
  packageCount,
  showPackageCount,
  cargoTotalWeightInGrams,
  showCargoMerchandiseValue,
  cargoMerchandiseValue,
  showCargoWeight
}) {
  return (
    <>
      <ListItemSecondaryAction>
        {!cargoCompany && showPackageCount && (
          <Box bgcolor="primary.main" clone>
            <Avatar>
              <Typography variant="body2" data-testid="package-count-avatar">
                <b>{packageCount}</b>
              </Typography>
            </Avatar>
          </Box>
        )}
      </ListItemSecondaryAction>

      <ListItemText
        primary={
          <Typography
            gutterBottom
            component="div"
            style={{ fontWeight: 600 }}
            variant="subtitle2"
          >
            {cargoLicensePlate}
          </Typography>
        }
        secondary={
          <>
            {cargoCompany && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                data-testid="cargo-info-company"
              >
                Pacote de {cargoCompany}
              </Typography>
            )}
            {!cargoCompany && showPackageCount && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                data-testid="cargo-info-package-count"
              >
                {`com ${packageCount} ${pluralize({
                  singular: 'pacote',
                  count: packageCount
                })}`}
              </Typography>
            )}
            {
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                data-testid="cargo-info-no-transshipment"
              >
                para {cargoDestination}
              </Typography>
            }
            {showTransshipmentInfo && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                data-testid="cargo-info-transshipment"
              >
                com transbordo para {cargoFinalDestination}
              </Typography>
            )}
            <Typography component="div" variant="caption" color="textSecondary">
              {showCargoWeight && (
                <>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    data-testid="cargo-info-weight"
                  >
                    Peso:{' '}
                    {fromKgToBrazilianText(cargoTotalWeightInGrams / 1000)}
                  </Typography>
                </>
              )}
              {showCargoMerchandiseValue && (
                <>
                  {showCargoWeight ? ' | ' : ''}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    data-testid="cargo-info-value"
                  >
                    Valor: {formatBRL(cargoMerchandiseValue)}
                  </Typography>
                </>
              )}
            </Typography>
          </>
        }
      />
    </>
  );
}

CargoInfoItem.defaultProps = {
  cargoLicensePlate: '',
  cargoCompany: '',
  cargoDestination: '',
  cargoFinalDestination: '',
  showTransshipmentInfo: false,
  packageCount: 0,
  showPackageCount: false,
  cargoTotalWeightInGrams: 0,
  showCargoWeight: false,
  showCargoMerchandiseValue: false,
  cargoMerchandiseValue: 0
};

CargoInfoItem.propTypes = {
  cargoLicensePlate: PropTypes.string,
  cargoCompany: PropTypes.string,
  cargoDestination: PropTypes.string,
  cargoFinalDestination: PropTypes.string,
  showTransshipmentInfo: PropTypes.bool,
  packageCount: PropTypes.number,
  showPackageCount: PropTypes.bool,
  cargoTotalWeightInGrams: PropTypes.number,
  showCargoWeight: PropTypes.bool,
  showCargoMerchandiseValue: PropTypes.bool,
  cargoMerchandiseValue: PropTypes.number
};

function CargoDestinationItem({ destinationName, destinationRoutingCode }) {
  return (
    <ListItemText
      primary={
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          data-testid="cargo-destination"
        >
          {destinationName} - {destinationRoutingCode}
        </Typography>
      }
    />
  );
}

CargoDestinationItem.defaultProps = {
  destinationName: '',
  destinationRoutingCode: ''
};

CargoDestinationItem.propTypes = {
  destinationName: PropTypes.string,
  destinationRoutingCode: PropTypes.string
};

export function CargoInfo({
  cargoLicensePlate,
  cargoCompany,
  cargoDestination,
  cargoFinalDestination,
  showTransshipmentInfo,
  packageCount,
  showPackageCount,
  showDivider,
  showCargoWeight,
  cargoTotalWeightInGrams,
  showCargoMerchandiseValue,
  cargoMerchandiseValue
}) {
  return (
    <Box px={3} paddingRight={3} clone>
      <ListItem
        disableGutters
        divider={showDivider}
        key={cargoLicensePlate}
        data-testid="cargo-list-item"
      >
        <CargoInfoItem
          cargoLicensePlate={cargoLicensePlate}
          cargoCompany={cargoCompany}
          cargoDestination={cargoDestination}
          cargoFinalDestination={cargoFinalDestination}
          showTransshipmentInfo={showTransshipmentInfo}
          packageCount={packageCount}
          showPackageCount={showPackageCount}
          showCargoWeight={showCargoWeight}
          cargoTotalWeightInGrams={cargoTotalWeightInGrams}
          showCargoMerchandiseValue={showCargoMerchandiseValue}
          cargoMerchandiseValue={cargoMerchandiseValue}
        />
      </ListItem>
    </Box>
  );
}

CargoInfo.defaultProps = {
  cargoLicensePlate: '',
  cargoCompany: '',
  cargoDestination: '',
  cargoFinalDestination: '',
  showTransshipmentInfo: false,
  packageCount: 0,
  showPackageCount: false,
  showDivider: true,
  cargoTotalWeightInGrams: 0,
  showCargoWeight: false,
  showCargoMerchandiseValue: false,
  cargoMerchandiseValue: 0
};

CargoInfo.propTypes = {
  cargoLicensePlate: PropTypes.string,
  cargoCompany: PropTypes.string,
  cargoDestination: PropTypes.string,
  cargoFinalDestination: PropTypes.string,
  showTransshipmentInfo: PropTypes.bool,
  packageCount: PropTypes.number,
  showPackageCount: PropTypes.bool,
  showDivider: PropTypes.bool,
  cargoTotalWeightInGrams: PropTypes.number,
  showCargoWeight: PropTypes.bool,
  showCargoMerchandiseValue: PropTypes.bool,
  cargoMerchandiseValue: PropTypes.number
};

export function CargoInfoWithCheckbox({
  cargoLicensePlate,
  cargoCompany,
  cargoDestination,
  cargoFinalDestination,
  showTransshipmentInfo,
  packageCount,
  showPackageCount,
  handleSelectCargo,
  isCargoSelected,
  showCargoWeight,
  cargoTotalWeightInGrams,
  showCargoMerchandiseValue,
  cargoMerchandiseValue
}) {
  return (
    <CheckboxListItem
      key={cargoLicensePlate}
      testIdListItem="cargo-list-item"
      onClick={() => handleSelectCargo(cargoLicensePlate)}
      selected={isCargoSelected(cargoLicensePlate)}
      divider
    >
      <CargoInfoItem
        cargoLicensePlate={cargoLicensePlate}
        cargoCompany={cargoCompany}
        cargoDestination={cargoDestination}
        cargoFinalDestination={cargoFinalDestination}
        showTransshipmentInfo={showTransshipmentInfo}
        packageCount={packageCount}
        showPackageCount={showPackageCount}
        showCargoMerchandiseValue={showCargoMerchandiseValue}
        showCargoWeight={showCargoWeight}
        cargoMerchandiseValue={cargoMerchandiseValue}
        cargoTotalWeightInGrams={cargoTotalWeightInGrams}
      />
    </CheckboxListItem>
  );
}

CargoInfoWithCheckbox.defaultProps = {
  cargoLicensePlate: '',
  cargoCompany: '',
  cargoDestination: '',
  cargoFinalDestination: '',
  showTransshipmentInfo: false,
  packageCount: 0,
  showPackageCount: false,
  cargoTotalWeightInGrams: 0,
  showCargoWeight: false,
  showCargoMerchandiseValue: false,
  cargoMerchandiseValue: 0
};

CargoInfoWithCheckbox.propTypes = {
  cargoLicensePlate: PropTypes.string,
  cargoCompany: PropTypes.string,
  cargoDestination: PropTypes.string,
  cargoFinalDestination: PropTypes.string,
  showTransshipmentInfo: PropTypes.bool,
  packageCount: PropTypes.number,
  showPackageCount: PropTypes.bool,
  handleSelectCargo: PropTypes.func.isRequired,
  isCargoSelected: PropTypes.func.isRequired,
  cargoTotalWeightInGrams: PropTypes.number,
  showCargoWeight: PropTypes.bool,
  showCargoMerchandiseValue: PropTypes.bool,
  cargoMerchandiseValue: PropTypes.number
};

export function CargoDestinationsList({
  handleSelectDestination,
  isDestinationSelected,
  destinationName,
  destinationRoutingCode
}) {
  return (
    <ListItem
      button
      key={destinationRoutingCode}
      data-testid="destination-list-item"
      onClick={() => handleSelectDestination(destinationRoutingCode)}
      selected={isDestinationSelected(destinationRoutingCode)}
      divider
    >
      <CargoDestinationItem
        destinationName={destinationName}
        destinationRoutingCode={destinationRoutingCode}
      />
    </ListItem>
  );
}

CargoDestinationsList.defaultProps = {
  destinationName: '',
  destinationRoutingCode: ''
};

CargoDestinationsList.propTypes = {
  handleSelectDestination: PropTypes.func.isRequired,
  isDestinationSelected: PropTypes.func.isRequired,
  destinationName: PropTypes.string,
  destinationRoutingCode: PropTypes.string
};
