import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';

import Logo from '../../assets/images/icon.png';

export default function HeaderWithCancel({ testId, handleClose, isDisabled }) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
      <Box>
        <img src={Logo} alt="Loggi" height="25" />
      </Box>
      <Box marginLeft="auto">
        <Button
          data-testid={testId}
          size="medium"
          variant="outlined"
          color="primary"
          onClick={handleClose}
          disabled={isDisabled}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
}

HeaderWithCancel.propTypes = {
  testId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

HeaderWithCancel.defaultProps = {
  isDisabled: false
};
