import { useAmplifyAuth } from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PasswordInput, ScreenHeader } from '../../components';
import { showNotificationMessage } from '../../service';
import PASSWORD_MIN_LENGTH from '../constants';
import { DEFINE_NEW_PASSWORD_BUTTON_ID, LOADING_ID } from './constants';

const useCreateNewPasswordStyle = makeStyles(({ palette }) => ({
  buttonLoading: {
    color: palette.common.white
  }
}));

const CreateNewPassword = () => {
  const { t } = useTranslation('createNewPassword');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const styles = useCreateNewPasswordStyle();

  const { completeNewPassword } = useAmplifyAuth();

  const onSubmitNewPassword = formValues => {
    const { password } = formValues;
    setLoading(true);
    completeNewPassword(password).then(() => {
      setLoading(false);
      showNotificationMessage(t('success.message'), 'success', enqueueSnackbar);
    });
  };

  const validatePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('passwordIsRequired.message'))
      .min(PASSWORD_MIN_LENGTH, t('passwordMinLength.message'))
  });

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <ScreenHeader
          title={t('header.message')}
          subTitle={t('subHeader.message')}
        />
      </OneTemplateSummary>
      <OneTemplateContent>
        <Formik
          initialValues={{
            password: ''
          }}
          validationSchema={validatePasswordSchema}
          onSubmit={onSubmitNewPassword}
        >
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            errors
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container justify="center" alignContent="space-between">
                <Box marginBottom={8} width="100%">
                  <PasswordInput
                    handleChange={handleChange}
                    password={values.password}
                    handleBlur={handleBlur}
                    error={touched.password && errors.password}
                    label={t('passwordInput.label')}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={loading}
                  data-testid={DEFINE_NEW_PASSWORD_BUTTON_ID}
                >
                  {!loading && t('definePasswordButton.text')}
                  {loading && (
                    <CircularProgress
                      data-testid={LOADING_ID}
                      className={styles.buttonLoading}
                      size={26}
                    />
                  )}
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default CreateNewPassword;
