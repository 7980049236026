import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { CheckCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';

export default function AvatarChip({ photo, name, hasCheckIcon }) {
  return (
    <Box
      data-testid="avatar-chip"
      display="inline-flex"
      bgcolor={colors.smoke[100]}
      borderRadius={43}
      height={33}
      position="relative"
      justifyContent="center"
      alignItems="center"
      maxWidth="100%"
    >
      <Box paddingLeft="4px">
        <Avatar style={{ height: '25px', width: '25px' }} src={photo} />
      </Box>
      <Box
        clone
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        paddingLeft={2}
        paddingRight={3}
        width={1}
      >
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {name}
        </Typography>
      </Box>
      {hasCheckIcon && (
        <Box
          data-testid="avatar-chip-check-icon"
          clone
          width={28}
          height={28}
          position="absolute"
          right={-8}
          bottom={-8}
        >
          <CheckCircle style={{ color: colors.green[500] }} />
        </Box>
      )}
    </Box>
  );
}

AvatarChip.defaultProps = {
  hasCheckIcon: true,
  photo: '',
  name: ''
};

AvatarChip.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  hasCheckIcon: PropTypes.bool
};
