import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import { TripProcessContext } from './trip-process-context';

import ActionContainerBottom from '../../../app/components/actions-container-bottom';
import pluralize from '../../../app/utils/pluralize';
import { ROUTES, OPERATIONAL_PROCESS, ACTIVITY } from '../../../constants';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';

export default function Success() {
  const { cargos, trip, selectedDC, clearData } = useContext(
    TripProcessContext
  );

  const history = useHistory();
  const [groupedCargos, setGroupedCargos] = useState([]);
  const [driverName, setDriverName] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState('');

  const { trackEnd } = useContext(ActivityTrackingContext);

  useEffect(() => {
    if (cargos.length > 0) {
      const cargoGroupByRoutingCode = {};
      cargos.forEach(cargo => {
        cargoGroupByRoutingCode[cargo.destinationRoutingCode] = {
          destinationRoutingCode: cargo.destinationRoutingCode,
          destinationName: cargo.destinationName,
          total:
            (cargoGroupByRoutingCode[cargo.destinationRoutingCode]?.total ||
              0) + 1
        };
      });

      setGroupedCargos(Object.values(cargoGroupByRoutingCode));
      setDriverName(trip.drivers?.[0]?.name);
      setCarrierName(trip.carrier?.name);
      setVehicleLicensePlate(trip.vehicleLicensePlate);
      clearData();
      trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.FULL_PROCESS);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargos]);

  const redirectToNewDistribution = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
    });
  };

  const redirectToMainMenu = () => {
    history.push({
      pathname: '/'
    });
  };

  const destinationDescription = (destinationRoutingCode, destinationName) => {
    return `${destinationRoutingCode} ${destinationName}`;
  };

  return (
    <>
      <Box pb={13} height="100vh" overflow="auto">
        <Container maxWidth="xs" data-testid="end-success" disableGutters>
          <List>
            {groupedCargos.map(item => (
              <ListItem
                data-testid="success-list-item"
                key={item.destinationRoutingCode}
                alignItems="flex-start"
                divider
              >
                <ListItemText
                  primary={
                    <Box px={1}>
                      <Typography>
                        {`Você movimentou ${item.total} ${pluralize({
                          singular: 'unidade',
                          count: cargos?.length
                        })} de ${
                          selectedDC?.distributionCenterName
                        } para ${destinationDescription(
                          item.destinationRoutingCode,
                          item.destinationName
                        )}`}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>

          <Box mt={1.5} pl={3}>
            <Typography component="div" variant="caption" color="textSecondary">
              {driverName} da {carrierName}
            </Typography>
            <Typography component="div" variant="caption">
              com veículo {vehicleLicensePlate}
            </Typography>
          </Box>
        </Container>
      </Box>
      <ActionContainerBottom>
        <Container maxWidth="xs">
          <Box pb={3}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <Button
                  fullWidth
                  size="medium"
                  variant="outlined"
                  color="primary"
                  onClick={redirectToMainMenu}
                >
                  Fechar
                </Button>
              </Box>
              <Box flex={1} ml={1}>
                <Button
                  fullWidth
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={redirectToNewDistribution}
                >
                  Nova distribuição
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </ActionContainerBottom>
    </>
  );
}
