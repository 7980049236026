import React from 'react';
import PropTypes from 'prop-types';

/*
 * Geolocation context, used in georeferenced calls.
 */
export const GeolocationContext = React.createContext();

/*
 * Geolocation provider high order component.
 */
export function GeolocationProvider({ children }) {
  // TODO: Remove default after fixing https/ssl.
  const [geo, setGeo] = React.useState({
    coords: {
      longitude: 0.0,
      latitude: 0.0
    },
    defaultCoords: true
  });

  const onReceivePosition = position => {
    setGeo(position);
  };

  React.useEffect(() => {
    const watcher = navigator.geolocation.watchPosition(
      onReceivePosition,
      () => {},
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 180000 }
    );

    return () => navigator.geolocation.clearWatch(watcher);
  }, []);

  return geo.coords ? (
    <GeolocationContext.Provider value={[geo, setGeo]}>
      {children}
    </GeolocationContext.Provider>
  ) : (
    <div className="centered">
      <p>Ative a geolocalização antes de prosseguir</p>
    </div>
  );
}

GeolocationProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const TEST_LATITUDE = -23.0;
export const TEST_LONGITUDE = -46.0;

export function TestGeolocationProvider({ coords, defaultCoords, children }) {
  const [geo, setGeo] = React.useState({ coords, defaultCoords });

  return (
    <GeolocationContext.Provider value={[geo, setGeo]}>
      {children}
    </GeolocationContext.Provider>
  );
}

TestGeolocationProvider.propTypes = {
  children: PropTypes.element.isRequired,
  coords: PropTypes.shape({
    longitude: PropTypes.number,
    latitude: PropTypes.number
  }),
  defaultCoords: PropTypes.bool
};

TestGeolocationProvider.defaultProps = {
  coords: {
    longitude: TEST_LONGITUDE,
    latitude: TEST_LATITUDE
  },
  defaultCoords: false
};
