// impersonation
const COGNITO_IU = 'cognito.iu';

// Amplify
const USER_NOT_AUTHENTICATED_ERROR = 'No current user';
const EXPIRED_REFRESH_TOKEN_ERROR_CODE = 'NotAuthorizedException';

const AWS_CUSTOM_AUTH_CHALLENGE_NAME = 'CUSTOM_CHALLENGE';
const AWS_CUSTOM_CHALLENGE_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

const AWS_COGNITO_IMPERSONATION_ERRORS = {
  UserLambdaValidationException: {
    code: 'UserLambdaValidationException'
  },
  UserNotFoundException: {
    code: 'UserNotFoundException'
  },
  NotAuthorizedException: {
    code: 'NotAuthorizedException'
  }
};

const FAILURE_FORGOT_PASSWORD = 'FAILURE_FORGOT_PASSWORD';
const FAILURE_SEND_PASSWORD_RECOVERY = 'FAILURE_SEND_PASSWORD_RECOVERY';

const ACCESS_DENIED = '/acesso-negado';
const LOGIN = '/login';

const ZENDESK_URL = process.env.REACT_APP_ZENDESK_URL;

const ENDPOINTS = {
  JWT_TOKEN: {
    url: '/one/api/jwt_client/jwt-token/'
  }
};

const SELECTED_COMPANY_LOCAL_STORAGE_KEY = 'loggione_selected_company';
const USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY =
  'loggione_user_access_companies';

export {
  COGNITO_IU,
  AWS_COGNITO_IMPERSONATION_ERRORS,
  AWS_CUSTOM_CHALLENGE_NEW_PASSWORD_REQUIRED,
  USER_NOT_AUTHENTICATED_ERROR,
  EXPIRED_REFRESH_TOKEN_ERROR_CODE,
  AWS_CUSTOM_AUTH_CHALLENGE_NAME,
  FAILURE_SEND_PASSWORD_RECOVERY,
  FAILURE_FORGOT_PASSWORD,
  ACCESS_DENIED,
  LOGIN,
  ZENDESK_URL,
  ENDPOINTS,
  SELECTED_COMPANY_LOCAL_STORAGE_KEY,
  USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY
};
