import React from 'react';
import PropTypes from 'prop-types';

import useRemoteConfig from './firebase-service';

export const FeatureSwitchContext = React.createContext();

/**
 * All keys are extracted from REMOTE_CONFIG_DEFAULT
 * so use the following convention:
 *
 *  Remote configs in snakeCase in firebase.
 *  Provider values in camelCase in the app.
 *
 *  Example:
 *   Given the remote config in firebase:
 *   enable_some_example_feature
 *
 *   Use the provider values like this:
 *   const { enableSomeExampleFeature } = React.useContext(FeatureSwitchContext);
 */
export function FeatureSwitchProvider({ children }) {
  /**
   * 'value' will contain a dictionary
   *  with the keys and values extracted from firebase
   *
   * {
   *    enableSomeExampleFeature: 'true'
   * }
   *
   * While isLoading === true, values will reflect the default config.
   * After isLoading === false they will use the value fetched from firebase.
   */
  const { value, isLoading } = useRemoteConfig();

  return (
    <FeatureSwitchContext.Provider
      value={{
        ...value,
        isLoading
      }}
    >
      {children}
    </FeatureSwitchContext.Provider>
  );
}

FeatureSwitchProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export function TestFeatureSwitchProvider({
  children,
  scanditScannerUsers,
  enableGetDistributeInfoRest,
  graphqlToRestMigrationUsers,
  showPackagePositionOnDistribute,
  isLoading,
  enableRedirectByOperationType,
  enableInferDistributeSortingContext,
  enableSelectedDistributionCenterIdHeader,
  enableUnifiedDistribute,
  enableSkipPackagesBeepInDistribute,
  enableChangeBulkMoveToRedirectToDest,
  enableShowOutlierDetailsInOrganize,
  enableChangeLicensePlateSentMovePackage,
  switches
}) {
  return (
    <FeatureSwitchContext.Provider
      value={{
        scanditScannerUsers,
        enableGetDistributeInfoRest,
        graphqlToRestMigrationUsers,
        showPackagePositionOnDistribute,
        isLoading,
        enableRedirectByOperationType,
        enableInferDistributeSortingContext,
        enableSelectedDistributionCenterIdHeader,
        enableUnifiedDistribute,
        enableSkipPackagesBeepInDistribute,
        enableChangeBulkMoveToRedirectToDest,
        enableShowOutlierDetailsInOrganize,
        enableChangeLicensePlateSentMovePackage,
        ...switches
      }}
    >
      {children}
    </FeatureSwitchContext.Provider>
  );
}

TestFeatureSwitchProvider.defaultProps = {
  scanditScannerUsers: '[]',
  enableGetDistributeInfoRest: 'false',
  graphqlToRestMigrationUsers: '[]',
  showPackagePositionOnDistribute: 'false',
  isLoading: false,
  enableRedirectByOperationType: 'false',
  enableInferDistributeSortingContext: 'false',
  enableSelectedDistributionCenterIdHeader: 'false',
  enableUnifiedDistribute: 'false',
  enableSkipPackagesBeepInDistribute: '[]',
  enableChangeBulkMoveToRedirectToDest: 'false',
  enableShowOutlierDetailsInOrganize: 'false',
  enableChangeLicensePlateSentMovePackage: 'false'
};

TestFeatureSwitchProvider.propTypes = {
  children: PropTypes.element.isRequired,
  scanditScannerUsers: PropTypes.string,
  enableGetDistributeInfoRest: PropTypes.string,
  graphqlToRestMigrationUsers: PropTypes.string,
  showPackagePositionOnDistribute: PropTypes.string,
  isLoading: PropTypes.bool,
  enableRedirectByOperationType: PropTypes.string,
  enableInferDistributeSortingContext: PropTypes.string,
  enableSelectedDistributionCenterIdHeader: PropTypes.string,
  enableUnifiedDistribute: PropTypes.string,
  enableSkipPackagesBeepInDistribute: PropTypes.string,
  enableChangeBulkMoveToRedirectToDest: PropTypes.string,
  enableShowOutlierDetailsInOrganize: PropTypes.string,
  enableChangeLicensePlateSentMovePackage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  switches: PropTypes.object.isRequired
};
