import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorBoundaryContext from './error-boundary-context';
import ErrorContent from './error-content.component';

const ContentErrorBoundary = ({ beforeCapture, children }) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={props => (
        <ErrorBoundaryContext.Provider value={props}>
          <ErrorContent />
        </ErrorBoundaryContext.Provider>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

ContentErrorBoundary.propTypes = {
  beforeCapture: PropTypes.func,
  children: PropTypes.node.isRequired
};

ContentErrorBoundary.defaultProps = {
  beforeCapture: () => {}
};

export default ContentErrorBoundary;
