import React from 'react';
import { useLocation } from 'react-router-dom';
import VehicleLicensePlateInput from './trip/vehicle-license-plate-input';
import { DISTRIBUTE_TO } from '../../constants';

function LicensePlateInputPage() {
  const location = useLocation();
  const { sortingContextLicensePlate } = location.state || {};

  return (
    <VehicleLicensePlateInput
      transferType={DISTRIBUTE_TO.PARTNER}
      sortingContextLicensePlate={sortingContextLicensePlate}
    />
  );
}

export default LicensePlateInputPage;
