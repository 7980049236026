import {
  FAILURE_FETCH_AUTH_USER,
  FAILURE_FORGOT_PASSWORD,
  FAILURE_SEND_PASSWORD_RECOVERY,
  RESET_AUTH_ERROR,
  RESET_AUTH_USER,
  SIGN_IN_FAILURE,
  SIGN_UP_FAILURE,
  START_COMPLETE_NEW_PASSWORD,
  START_FETCH_AUTH_USER,
  START_FORGOT_PASSWORD,
  START_SEND_PASSWORD_RECOVERY,
  START_SIGN_IN,
  START_SIGN_UP,
  SUCCESS_COMPLETE_NEW_PASSWORD,
  SUCCESS_FETCH_AUTH_USER,
  SUCCESS_FORGOT_PASSWORD,
  SUCCESS_SEND_PASSWORD_RECOVERY,
  SUCCESS_SIGN_IN,
  SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD,
  SUCCESS_SIGN_UP
} from './constants';

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_FETCH_AUTH_USER: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case SUCCESS_FETCH_AUTH_USER: {
      return {
        ...state,
        loading: false,
        error: null,
        authenticatedUser: payload.authenticatedUser,
        isImpersonation: payload.isImpersonation
      };
    }
    case FAILURE_FETCH_AUTH_USER: {
      return {
        ...state,
        loading: false,
        error: payload.error,
        isImpersonation: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case START_SIGN_UP: {
      return {
        ...state,
        error: null
      };
    }
    case SUCCESS_SIGN_UP: {
      return {
        ...state,
        error: null,
        newUser: payload.newUser
      };
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: {
          message: payload?.error,
          event: SIGN_UP_FAILURE
        },
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case START_SIGN_IN: {
      return {
        ...state,
        error: null
      };
    }
    case SUCCESS_SIGN_IN: {
      return {
        ...state,
        loading: false,
        error: null,
        newPasswordRequired: false
      };
    }
    case SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD: {
      return {
        ...state,
        loading: false,
        error: null,
        newPasswordRequired: true,
        authenticatedUser: payload.authenticatedUser
      };
    }
    case SIGN_IN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: {
          message: payload?.error,
          event: SIGN_IN_FAILURE
        },
        newPasswordRequired: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case START_COMPLETE_NEW_PASSWORD: {
      return {
        ...state,
        error: null,
        newPasswordRequired: true
      };
    }
    case SUCCESS_COMPLETE_NEW_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: false,
        newPasswordRequired: false,
        authenticatedUser: payload.authenticatedUser
      };
    }
    case RESET_AUTH_ERROR: {
      return {
        ...state,
        error: null,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case RESET_AUTH_USER: {
      return {
        ...state,
        error: null,
        authenticatedUser: null,
        isImpersonation: false,
        newUser: null,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case START_FORGOT_PASSWORD: {
      return {
        ...state,
        loading: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case SUCCESS_FORGOT_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: true
        }
      };
    }
    case FAILURE_FORGOT_PASSWORD: {
      return {
        ...state,
        error: {
          message: payload?.error,
          event: FAILURE_FORGOT_PASSWORD
        },
        loading: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case START_SEND_PASSWORD_RECOVERY: {
      return {
        ...state,
        error: null,
        loading: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    case SUCCESS_SEND_PASSWORD_RECOVERY: {
      return {
        ...state,
        error: null,
        passwordRecovery: {
          hasConfirmedNewPassword: true,
          hasSentForgotPassword: false
        }
      };
    }
    case FAILURE_SEND_PASSWORD_RECOVERY: {
      return {
        ...state,
        error: {
          message: payload?.error,
          event: FAILURE_SEND_PASSWORD_RECOVERY
        },
        loading: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
    default: {
      return {
        loading: true,
        error: null,
        authenticatedUser: null,
        isImpersonation: false,
        newUser: null,
        newPasswordRequired: false,
        passwordRecovery: {
          hasConfirmedNewPassword: false,
          hasSentForgotPassword: false
        }
      };
    }
  }
};
