import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { ACTIVITY, OPERATIONAL_PROCESS, SWITCHES } from '../../constants';
import {
  DECLARE_DAMAGE_FRIENDLY_ERROR_MESSAGE,
  DECLARE_DAMAGE_STEPS,
  TESTID,
  TEXT
} from './constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import PhotoCapture from './photo-capture';
import JiraAlreadyExists from './jira-already-exists';
import PackageDamageDeclaration from './package-damage-declaration';
import {
  declareDamage,
  getDeclareDamage
} from './declare-package-damage-service';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { useFeature } from '../../app/hooks/use-feature';
import convertBase64ToFile from '../../app/utils/convert-image';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';

export default function DeclarePackageDamage({
  declarePackageDamageService,
  getDeclareDamageService
}) {
  const [pack, setPack] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [jiraTicket, setJiraTicket] = React.useState(null);
  const [imagesData, setImagesData] = React.useState([]);
  const [redirectIdentify, setRedirectIdentify] = React.useState(false);
  const [damageText, setDamageText] = React.useState('');
  const [physicalDamage, setPhysicalDamage] = React.useState('');
  const [damageCause, setDamageCause] = React.useState('');
  const [processLocation, setProcessLocation] = React.useState('');
  const [currentStep, setCurrentStep] = React.useState(
    DECLARE_DAMAGE_STEPS.PREVIEW
  );
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const enableDeclarePackageDamage = useFeature(
    SWITCHES.enableDeclarePackageDamage
  );
  const enableDeclarePackageDamageByDc = useFeature(
    SWITCHES.enableDeclarePackageDamageByDc
  );

  const shouldDisableDeclareDamage = !(
    enableDeclarePackageDamage && enableDeclarePackageDamageByDc
  );

  const isAbleToAdvance = Boolean(
    physicalDamage && damageCause && processLocation
  );
  const isAbleToSubmit = Boolean(isAbleToAdvance && imagesData.length > 0);

  const goToPackageDamageDeclaration = React.useCallback(() => {
    trackEnd(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.PREVIEW_STEP);
    trackStart(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.FORM_FIRST_STEP);
    setCurrentStep(DECLARE_DAMAGE_STEPS.DAMAGE_DECLARATION);
  }, [trackEnd, trackStart, setCurrentStep]);

  const goToPhotoCapture = () => {
    trackEnd(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.FORM_FIRST_STEP);
    trackStart(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.FORM_SECOND_STEP);
    setCurrentStep(DECLARE_DAMAGE_STEPS.PHOTO_CAPTURE);
  };

  const goBackToIdentify = () => setRedirectIdentify(true);
  const handleDamageTextChange = event => setDamageText(event.target.value);
  const removeImage = key => {
    setImagesData(images => images.filter(image => image.key !== key));
  };
  const onConfirmSend = image => {
    setImagesData(images => [
      ...images,
      { src: image, key: new Date().getTime() }
    ]);
  };
  const extractTicketCode = message => {
    return message.split(' ')[2];
  };

  const form = {
    processLocation,
    damageCause,
    physicalDamage,
    damageText
  };

  const setForm = {
    processLocation: v => setProcessLocation(v),
    damageCause: v => setDamageCause(v),
    physicalDamage: v => setPhysicalDamage(v),
    damageText: handleDamageTextChange
  };

  const errorHandler = React.useCallback(
    errMessage => {
      showSnackbar({
        message: errMessage,
        variant: 'error',
        showCloseButton: true,
        enqueueSnackbar
      });
      playErrorBeep();
      setLoading(false);
    },
    [enqueueSnackbar]
  );

  const fetchDeclarePackageDamage = React.useCallback(
    async packageId => {
      setLoading(true);

      const response = await getDeclareDamageService({
        packageId,
        errorHandler
      });

      if (response) {
        const { issues } = response;
        if (issues?.length) {
          setJiraTicket(issues[0]);
        } else {
          goToPackageDamageDeclaration();
        }
      }
      setLoading(false);
    },
    [getDeclareDamageService, errorHandler, goToPackageDamageDeclaration]
  );

  React.useEffect(() => {
    const packState = location?.state?.pack;
    if (packState) {
      setPack(packState);
      fetchDeclarePackageDamage(packState.pk);
    } else goBackToIdentify();
  }, [location, fetchDeclarePackageDamage]);

  React.useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.PREVIEW_STEP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    if (loading) return;

    trackEnd(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.FORM_SECOND_STEP);

    setLoading(true);

    const files = imagesData.map((image, index) =>
      convertBase64ToFile(image.src, `avaria-${index}.jpeg`)
    );

    let damageDescription = damageText;

    if (jiraTicket) {
      damageDescription = [
        damageDescription,
        `Ticket Anterior: ${jiraTicket.key}`
      ].join('\n');
    }

    const distributionCenterId =
      selectedDistributionCenter?.distributionCenterId;

    const declarePackageDamagePayload = await declarePackageDamageService({
      packageId: pack.pk,
      imagesData: files,
      damageDescription,
      damageCause,
      physicalDamage,
      processLocation,
      distributionCenterId,
      errorHandler
    });

    if (!declarePackageDamagePayload) {
      setLoading(false);
      return;
    }

    if (!declarePackageDamagePayload.success) {
      const friendlyMessage = DECLARE_DAMAGE_FRIENDLY_ERROR_MESSAGE.find(
        err => err.original === declarePackageDamagePayload.message
      );
      errorHandler(
        friendlyMessage?.friendly ?? declarePackageDamagePayload.message
      );
      setLoading(false);
      return;
    }

    const ticket = extractTicketCode(declarePackageDamagePayload.message);

    playSuccessBeep();
    setLoading(false);
    trackEnd(OPERATIONAL_PROCESS.DECLARE_DAMAGE, ACTIVITY.FULL_PROCESS);
    goBackToIdentify();
    showSnackbar({
      message: `${TEXT.JIRA_CREATED_WITH_SUCCESS}${ticket}`,
      variant: 'success',
      showCloseButton: true,
      enqueueSnackbar,
      persist: true,
      closeOnClickAnywhereOnScreen: false
    });
  };

  if (redirectIdentify || shouldDisableDeclareDamage) {
    return (
      <Redirect
        to={{
          pathname: '/identify'
        }}
      />
    );
  }

  return (
    <Box>
      <Container maxWidth="xs">
        <Typography
          variant="h4"
          align="left"
          gutterBottom
          data-testid="title-declare-damage"
        >
          {TEXT.TITLE}
        </Typography>
        <Divider />
        <Box mt={1} p={1}>
          {loading ? (
            <Box
              mt={1.5}
              display="flex"
              justifyContent="center"
              data-testid={TESTID.DECLARE_DAMAGE_LOADING}
            >
              <CircularProgress justify="center" />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column">
              {currentStep === DECLARE_DAMAGE_STEPS.PREVIEW && (
                <JiraAlreadyExists
                  jiraTicket={jiraTicket}
                  goBackToIdentify={goBackToIdentify}
                  reFetchJiraData={() => fetchDeclarePackageDamage(pack.pk)}
                  goToPackageDamageDeclaration={goToPackageDamageDeclaration}
                />
              )}
              {currentStep === DECLARE_DAMAGE_STEPS.DAMAGE_DECLARATION && (
                <PackageDamageDeclaration
                  form={form}
                  setForm={setForm}
                  goBackToIdentify={goBackToIdentify}
                  goToPhotoCapture={goToPhotoCapture}
                />
              )}
              {currentStep === DECLARE_DAMAGE_STEPS.PHOTO_CAPTURE && (
                <PhotoCapture
                  imagesData={imagesData}
                  handleSave={handleSave}
                  isAbleToSubmit={isAbleToSubmit}
                  onConfirmSend={onConfirmSend}
                  removeImage={removeImage}
                  goToPackageDamageDeclaration={() =>
                    setCurrentStep(DECLARE_DAMAGE_STEPS.DAMAGE_DECLARATION)
                  }
                />
              )}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

DeclarePackageDamage.defaultProps = {
  // It becomes easier to test/mock by injecting the service.
  declarePackageDamageService: declareDamage,
  getDeclareDamageService: getDeclareDamage
};

DeclarePackageDamage.propTypes = {
  declarePackageDamageService: PropTypes.func,
  getDeclareDamageService: PropTypes.func
};
