/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { colors, pxToRem } from '@loggi/mar';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getDistributeInfo, postCancelAllocation } from '../../api-rest';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import Confirmation from '../../app/components/confirmation';
import DriverInfo from '../../app/components/driver-info';
import ExpandIcon from '../../app/components/expand-icon';
import ItineraryInfo from '../../app/components/itinerary-info';
import MainInfoBag from '../../app/components/main-info-bag';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { useFeature } from '../../app/hooks/use-feature';
import SharedPropTypes from '../../app/shared-prop-types';
import parseDateWithTimezone from '../../app/utils/parse-date-with-timezone';
import pluralize from '../../app/utils/pluralize';
import handleRestAPIError from '../../app/utils/rest-api-request';
import Icon from '../../assets/images/alert-icon-2.svg';
import {
  ACTIVITY,
  CANCEL_PICKUP_REASON,
  OPERATIONAL_PROCESS,
  SWITCHES
} from '../../constants';
import { playErrorBeep } from '../../sounds';
import CheckUnitLoadService from '../distribute/check-unit-load-packages-service';
import DirtyUnitLoadDialog from '../distribute/dirty-unit-load-dialog';
import ProcessDeadlineClusterTitle from './process-deadline-cluster-title';

moment.updateLocale('pt-BR');

function WrapperActionButtons({
  hasAccessToPrepareBag,
  recordStatus,
  statusDisplay,
  navigateToPrepareBag,
  cancelAllocation,
  errorMessage,
  dirtyUnitLoadDialogOpen,
  userName,
  unexpectedPackages,
  setDirtyUnitLoadDialogOpen,
  confirmTransferHandler,
  transferConfirmationSuccess,
  goToDistribute,
  warRoomUrl
}) {
  return (
    (hasAccessToPrepareBag || recordStatus.includes(statusDisplay)) && (
      <Box
        data-testid="wrapper-button"
        p={1.5}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        style={{
          paddingTop: 0
        }}
      >
        {recordStatus.includes(statusDisplay) && (
          <>
            <Box alignSelf="stretch" mb={3}>
              <Button
                mb={1.5}
                data-testid="confirm-transfer-button"
                fullWidth
                variant="outlined"
                color="primary"
                size="small"
                onClick={confirmTransferHandler}
              >
                Conferir identidade
              </Button>
            </Box>
            {transferConfirmationSuccess && (
              <Box alignSelf="stretch" mt={1.5}>
                <Button
                  data-testid="go-to-distribute-button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={goToDistribute}
                >
                  Distribuir pacotes
                </Button>
              </Box>
            )}
            {transferConfirmationSuccess && (
              <Box alignSelf="stretch" mt={1.5}>
                <Alert severity="success">{transferConfirmationSuccess}</Alert>
              </Box>
            )}
          </>
        )}

        {(hasAccessToPrepareBag || !warRoomUrl) && (
          <>
            <Divider style={{ width: '100%' }} />
            <List style={{ width: '100%' }} disablePadding>
              {hasAccessToPrepareBag && (
                <ListItem
                  button
                  divider
                  disableGutters
                  dense
                  onClick={navigateToPrepareBag}
                  data-testid="prepare-bag-button"
                >
                  <Box py={2} pl={3}>
                    <ListItemText primary="Preparar embarque" />
                    <ListItemSecondaryAction>
                      <ArrowForwardIos fontSize="small" color="primary" />
                    </ListItemSecondaryAction>
                  </Box>
                </ListItem>
              )}
              {!warRoomUrl && (
                <ListItem
                  button
                  divider
                  disableGutters
                  dense
                  onClick={cancelAllocation}
                >
                  <Box py={2} pl={3}>
                    <ListItemText primary="Cancelar oferta" />
                    <ListItemSecondaryAction>
                      <ArrowForwardIos fontSize="small" color="primary" />
                    </ListItemSecondaryAction>
                  </Box>
                </ListItem>
              )}
            </List>
          </>
        )}
        {(errorMessage || dirtyUnitLoadDialogOpen) && (
          <Box alignSelf="stretch" mt={1.5}>
            {errorMessage && (
              <Box mt={1.5}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
            {dirtyUnitLoadDialogOpen && (
              <DirtyUnitLoadDialog
                userName={userName}
                packages={unexpectedPackages}
                handleClose={() => {
                  setDirtyUnitLoadDialogOpen(false);
                }}
              />
            )}
          </Box>
        )}
      </Box>
    )
  );
}

WrapperActionButtons.propTypes = {
  hasAccessToPrepareBag: PropTypes.bool.isRequired,
  recordStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  statusDisplay: PropTypes.string.isRequired,
  navigateToPrepareBag: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  dirtyUnitLoadDialogOpen: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  unexpectedPackages: SharedPropTypes.packages.isRequired,
  setDirtyUnitLoadDialogOpen: PropTypes.bool.isRequired,
  confirmTransferHandler: PropTypes.func.isRequired,
  transferConfirmationSuccess: PropTypes.bool.isRequired,
  goToDistribute: PropTypes.func.isRequired,
  cancelAllocation: PropTypes.func.isRequired,
  warRoomUrl: PropTypes.string.isRequired
};

export default function IdentifyBag({ bag, transferConfirmationSuccess }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { trackStart } = useContext(ActivityTrackingContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [showPackages, setShowPackages] = React.useState(false);
  const [packageClusters, setpackageClusters] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [unexpectedPackages, setUnexpectedPackages] = React.useState([]);
  const [dirtyUnitLoadDialogOpen, setDirtyUnitLoadDialogOpen] = React.useState(
    false
  );

  const showProcessDeadlineDate = useFeature(SWITCHES.showProcessDeadlineDate);

  const hasAccessToPrepareBag = useFeature(
    SWITCHES.enableDistributionIndexesOnIdentify
  );

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const recordStatus = [
    'Iniciada',
    'Aceita',
    'Aguardando retirada',
    'Aguardando aceite',
    'Agendada'
  ];

  const goToDistribute = () => {
    history.push('/distribute');
  };

  const handleShowPackages = () => {
    setShowPackages(!showPackages);
  };

  useEffect(() => {
    const newPackageClusters = {};

    bag.packagesInBag.forEach(pack => {
      const cluster = pack.startProcessingDeadlineDate;
      if (newPackageClusters[cluster]) {
        newPackageClusters[cluster].push(pack);
      } else {
        newPackageClusters[cluster] = [pack];
      }
    });

    setpackageClusters(newPackageClusters);
  }, [bag]);

  const confirmTransferHandler = () => {
    trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.CARA_CRACHA);
    history.push({
      pathname: '/distribute/photo-upload',
      state: {
        bag
      }
    });
  };

  const extractBagInfo = data => {
    const seal = data?.bagSeal;
    const packages = data?.validPackages || [];
    const invalidPackages = data?.invalidPackages || [];
    const numPackages = packages.length + invalidPackages.length;

    return {
      seal,
      packages,
      invalidPackages,
      numPackages
    };
  };

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const mountBag = async () => {
    const distributeInfoRequest = { seal: bag.bagData.seal };
    const response = await getDistributeInfo(distributeInfoRequest).catch(err =>
      handleRestAPIError(err, baseErrorHandler)
    );
    return response ? extractBagInfo(response.data) : null;
  };

  const handleUnexpectedPackages = (packages, _userName) => {
    setUserName(_userName);
    setUnexpectedPackages(packages);
    setDirtyUnitLoadDialogOpen(true);
  };

  const unexpectedErrorHandler = err => {
    Sentry.captureException(err);
    baseErrorHandler(err.message);
  };

  const getCheckedPackagesIds = async () => {
    const bagPackagesIds = (bag.packagesInBag || []).map(p => p.pk);
    const operatorUnitLoadData = await CheckUnitLoadService(
      bagPackagesIds,
      handleUnexpectedPackages,
      baseErrorHandler,
      unexpectedErrorHandler
    );

    if (!operatorUnitLoadData) return [];

    return operatorUnitLoadData.packages
      .map(p => p.pk)
      .filter(pk => bagPackagesIds.includes(pk));
  };

  const prepareBag = async () => {
    const mountedBag = await mountBag();

    if (!mountedBag) {
      return null;
    }

    const checkedPackagesIds = await getCheckedPackagesIds();

    return {
      bag: mountedBag,
      checkedPackagesIds,
      hasAccessToPrepareBag
    };
  };

  const toggleModal = () => {
    setOpenModal(stateModal => !stateModal);
  };

  const cancelAllocation = async () => {
    const loggiUserId = JSON.parse(authenticatedUser['custom:access'])
      .loggi_user_id;

    const response = await postCancelAllocation(
      bag.driver?.pk || null,
      bag.itinerary.allocationId,
      CANCEL_PICKUP_REASON.BY_OPERATOR,
      loggiUserId
    ).catch(err => handleRestAPIError(err, baseErrorHandler));

    toggleModal();

    if (!response) {
      return;
    }

    showSnackbar({
      message: 'Tudo certo! A oferta foi cancelada.',
      variant: 'success',
      showCloseButton: true,
      enqueueSnackbar
    });

    history.push('/identify');
  };

  const navigateToPrepareBag = async () => {
    const state = await prepareBag();
    if (state) {
      history.push({
        pathname: '/identify/identify-package-reader',
        state
      });
    }
  };

  return (
    <Box p={1}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            <Box fontWeight="fontWeightBold">{bag.status}</Box>
          </Typography>
          {!showProcessDeadlineDate && (
            <Typography variant="h6">
              Entregar até{' '}
              {moment(
                parseDateWithTimezone({
                  date: bag.bagData.promisedDate
                })
              ).format('DD MMM')}
            </Typography>
          )}
        </CardContent>
        <CardContent>
          <MainInfoBag
            seal={bag.bagData.seal}
            numPackages={bag.bagData.numPackages}
            cutoffRoute={bag.bagData.cutoffRoute}
            routingCode={bag.bagData.distributionCenter?.routingCode}
            dcName={bag.bagData.distributionCenter?.name}
          />
        </CardContent>
        {bag.itinerary && (
          <Box>
            <Divider />
            <Box paddingX={2} paddingY={3}>
              <ItineraryInfo
                status={bag.itinerary.statusDisplay}
                timePassedInStatus={bag.itinerary.timePassedMin}
                itineraryPk={bag.itinerary.pk}
                allocationId={bag.itinerary.allocationId}
                warRoomUrl={bag.itinerary.url}
              />
            </Box>
          </Box>
        )}
        {bag.driver && (
          <DriverInfo
            photoUrl={bag.driver.photoUrl.profileHighRes}
            fullName={bag.driver.fullName}
            cnh={bag.driver.cnh ? bag.driver.cnh.number : ''}
            rg={bag.driver.docRg ? bag.driver.docRg.number : ''}
            cpf={bag.driver.docCpf ? bag.driver.docCpf.number : ''}
            needOnboardingInstructions={bag.driver.needOnboardingInstructions}
          />
        )}
        {bag.itinerary && (
          <Box>
            <WrapperActionButtons
              hasAccessToPrepareBag={hasAccessToPrepareBag}
              recordStatus={recordStatus}
              statusDisplay={bag.itinerary.statusDisplay}
              navigateToPrepareBag={navigateToPrepareBag}
              errorMessage={errorMessage}
              dirtyUnitLoadDialogOpen={dirtyUnitLoadDialogOpen}
              userName={userName}
              unexpectedPackages={unexpectedPackages}
              setDirtyUnitLoadDialogOpen={setDirtyUnitLoadDialogOpen}
              confirmTransferHandler={confirmTransferHandler}
              transferConfirmationSuccess={transferConfirmationSuccess}
              goToDistribute={goToDistribute}
              cancelAllocation={toggleModal}
              warRoomUrl={bag.itinerary.url}
            />
          </Box>
        )}
      </Card>
      {showProcessDeadlineDate && bag.packagesInBag[0] ? (
        <Box>
          <Typography>
            <Box fontWeight="fontWeightBold" my={2}>
              Prazo de expedição
            </Box>
          </Typography>
          {Object.keys(packageClusters)
            .sort((a, b) => a.localeCompare(b))
            .map(cluster => (
              <Box key={cluster}>
                <PackagesInBagCluster
                  cluster={cluster}
                  packagesInCluster={packageClusters[cluster]}
                />
              </Box>
            ))}
        </Box>
      ) : (
        <Box>
          {bag.packagesInBag[0] && (
            <Box my={1.5}>
              <Card>
                <Box mx={5} mt={1.5}>
                  <ListItem button onClick={handleShowPackages}>
                    <ListItemText primary="Pacotes na saca" />
                    <ExpandIcon expand={showPackages} />
                  </ListItem>
                  <Collapse in={showPackages} timeout="auto" unmountOnExit>
                    {bag.packagesInBag.map(packageInBag => (
                      <ListItem key={packageInBag.trackingKey}>
                        <ListItemText
                          primary={packageInBag.trackingKey}
                          secondary={`${packageInBag.recipient?.name} - ${
                            packageInBag.destination?.addressFormatted
                          } - ${packageInBag.company?.sharedName}`}
                        />
                      </ListItem>
                    ))}
                  </Collapse>
                </Box>
              </Card>
            </Box>
          )}
        </Box>
      )}
      <Confirmation
        image={
          <Avatar
            src={Icon}
            style={{ height: pxToRem(45), width: pxToRem(45) }}
          />
        }
        open={openModal}
        titleText="Cancelar oferta?"
        subtitleText="Esta oferta não vai mais ficar disponível para os entregadores."
        onConfirm={cancelAllocation}
        confirmText="Quero cancelar"
        onCancel={toggleModal}
        cancelText="Voltar"
      />
    </Box>
  );
}

IdentifyBag.propTypes = {
  bag: SharedPropTypes.bag.isRequired,
  transferConfirmationSuccess: PropTypes.bool.isRequired
};

export function PackagesInBagCluster({ cluster, packagesInCluster }) {
  const [showPackages, setShowPackages] = React.useState(false);

  const handleShowPackages = () => {
    setShowPackages(!showPackages);
  };

  return (
    <Box my={1.5}>
      <Card>
        <Box mx={1} my={1}>
          <ListItem button onClick={handleShowPackages}>
            <ListItemText
              primary={<ProcessDeadlineClusterTitle cluster={cluster} />}
              secondary={`${packagesInCluster.length} ${pluralize({
                singular: 'pacote',
                count: packagesInCluster.length
              })}`}
            />
            <Box style={{ color: colors.blue[500] }}>
              <ExpandIcon expand={showPackages} />
            </Box>
          </ListItem>
          <Collapse in={showPackages} timeout="auto" unmountOnExit>
            {packagesInCluster.map(packageInBag => (
              <Box>
                <Divider />
                <ListItem key={packageInBag.trackingKey}>
                  <ListItemText
                    primary={
                      <Typography variant="caption">
                        {packageInBag.currentPromisedDate && (
                          <Box fontWeight="fontWeightBold">
                            Entregar até{' '}
                            {moment(
                              parseDateWithTimezone({
                                date: packageInBag.currentPromisedDate
                              })
                            ).format('DD [de] MMM')}
                          </Box>
                        )}
                      </Typography>
                    }
                    secondary={
                      <Box
                        data-testid={`packageInfo${packageInBag.trackingKey}`}
                      >
                        <Typography>{packageInBag.trackingKey}</Typography>
                        {packageInBag.recipient?.name} -
                        {packageInBag.destination?.addressFormatted ||
                          packageInBag.destination?.address_formatted}{' '}
                        -{packageInBag.company?.sharedName}
                      </Box>
                    }
                  />
                </ListItem>
              </Box>
            ))}
          </Collapse>
        </Box>
      </Card>
    </Box>
  );
}

PackagesInBagCluster.propTypes = {
  cluster: PropTypes.string.isRequired,
  packagesInCluster: SharedPropTypes.packages.isRequired
};
