import MomentUtils from '@date-io/moment';
import { FormControl } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { useField } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCoverageParamsContext } from '../coverage-params';
import { useRemoteConfig, useFeature } from '../remote-config';
import {
  DATE_CHRISTMAS,
  DATE_CHRISTMAS_EVE,
  DATE_NEW_YEARS,
  DATE_NEW_YEARS_EVE,
  MONTH_DECEMBER,
  MONTH_JANUARY,
  SCHEDULING_DAYS_FROM_NOW,
  weekDays
} from './constants';

export class InvalidSchedulingDisableError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `InvalidSchedulingDisableError`;
  }
}

const isEndOfTheYearHoliday = date => {
  if (date.month() === MONTH_JANUARY) {
    return date.date() === DATE_NEW_YEARS;
  }
  if (date.month() === MONTH_DECEMBER) {
    return (
      [DATE_CHRISTMAS, DATE_CHRISTMAS_EVE, DATE_NEW_YEARS_EVE].indexOf(
        date.date()
      ) >= 0
    );
  }

  return false;
};

const isWeekendDate = date => date.day() === 0 || date.day() === 6;

/**
 * Receives a Moment date and an object with dates that should be disabled,
 * returning true if the date should be disabled and false if not.
 * @param date - Moment date
 * @param disabledDates - { '1': '2,3', '4': '1' } | January (days 2 and 3), April (day 1)
 * @returns {*|boolean}
 */
const isRemoteConfigDisabled = (date, disabledDates) => {
  const day = String(date.date());

  // moment's month is zero-indexed, so we add 1 to match
  // the human-readable data stored in remote config.
  const days = disabledDates[date.month() + 1];
  return days ? days.split(',').includes(day) : false;
};

const isWorkingWeekDay = (
  date,
  pickupWorkingWeekdays,
  enableFSforWorkingWeekDay
) => {
  return enableFSforWorkingWeekDay
    ? pickupWorkingWeekdays.includes(weekDays[date.day()])
    : true;
};

const DateField = ({ fieldName }) => {
  const { t } = useTranslation('one');
  const minDate = useMemo(() => moment(), []);
  const maxDate = useMemo(
    () => moment(minDate).add(SCHEDULING_DAYS_FROM_NOW, 'days'),
    [minDate]
  );
  const { value: disabledDates } = useRemoteConfig('disable_scheduling_dates');

  const parsedDisabledDates = useMemo(() => {
    const trimmedDates = disabledDates.replace(/\s/gi, '');
    let dates = {};
    try {
      dates = JSON.parse(trimmedDates);
    } catch (exceptionError) {
      Sentry.captureException(
        new InvalidSchedulingDisableError(exceptionError.message)
      );
    }
    return dates;
  }, [disabledDates]);

  const { pickupWorkingWeekdays } = useCoverageParamsContext();
  const shouldDisableWeekDaysWithoutPickup = useFeature(
    'should_disable_week_days_without_pickup'
  );
  const shouldDisableIsWeekendDateFunction = useFeature(
    'should_disable_is_weekend_date_function'
  );

  const isDisabledDate = useCallback(
    date =>
      isEndOfTheYearHoliday(date) ||
      (!shouldDisableIsWeekendDateFunction && isWeekendDate(date)) ||
      isRemoteConfigDisabled(date, parsedDisabledDates) ||
      !isWorkingWeekDay(
        date,
        pickupWorkingWeekdays,
        shouldDisableWeekDaysWithoutPickup
      ),
    [
      parsedDisabledDates,
      pickupWorkingWeekdays,
      shouldDisableWeekDaysWithoutPickup,
      shouldDisableIsWeekendDateFunction
    ]
  );

  const validate = useCallback(
    date => {
      if (!date) return t('dateField.errorMessages.requiredDate');

      const momentDate = moment(date);

      if (
        !momentDate.isBetween(minDate, maxDate, 'day', '[]') ||
        isDisabledDate(momentDate)
      ) {
        return t('dateField.errorMessages.invalidDate');
      }

      return null;
    },
    [isDisabledDate, maxDate, minDate, t]
  );

  const [field, meta, helpers] = useField({
    name: fieldName,
    validate
  });

  const { onBlur, value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;
  const hasError = Boolean(error) && touched;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl error={hasError} fullWidth variant="outlined">
        <KeyboardDatePicker
          autoOk
          data-testid="date-select"
          disableToolbar
          error={hasError}
          format="DD/MM/YYYY"
          helperText={hasError && error}
          id={fieldName}
          inputVariant="outlined"
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          label={t('dateField.label')}
          maxDate={maxDate}
          minDate={minDate}
          name={fieldName}
          onAccept={v => setValue(v)}
          onBlur={onBlur}
          onChange={v => setValue(v)}
          required
          shouldDisableDate={isDisabledDate}
          value={value || null}
          variant="inline"
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default DateField;
