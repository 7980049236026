import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { colors } from '@loggi/mar';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { BarcodeReader } from '../../app/components/barcode-readers';
import SharedPropTypes from '../../app/shared-prop-types';
import { movePackage as movePackageRest } from '../../api-rest';
import handleRestAPIError from '../../app/utils/rest-api-request';
import {
  RESPONSE_STATUS,
  OPERATIONAL_PROCESS,
  ACTIVITY
} from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';

const useStyles = makeStyles({
  dialog: {
    padding: 0
  }
});

export default function MovePackageDialog({
  currentPackage,
  handlePackage,
  closeWithoutPackage,
  sortingContextLicensePlate
}) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const handleUnitLoadRead = async unitLoadLicensePlate => {
    setErrorMessage('');
    setIsLoading(true);

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_STORE_BEEP
    );

    const sideEffectParams = {
      distributionCenterId: selectedDistributionCenter?.distributionCenterId
    };

    const response = await movePackageRest(
      unitLoadLicensePlate,
      currentPackage.identifier,
      null, // sortingDecisionLpn
      sortingContextLicensePlate, // sortingContextLpn
      sideEffectParams
    ).catch(err => {
      handleRestAPIError(err, baseErrorHandler);
    });

    setIsLoading(false);

    if (!response) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_STORE_BEEP
      );
      return;
    }

    if (response.data.status !== RESPONSE_STATUS.OK) {
      baseErrorHandler(response.data.errorMsg);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_STORE_BEEP
      );
      return;
    }

    playSuccessBeep();
    handlePackage(currentPackage);
  };

  const companyName =
    currentPackage.company?.name || currentPackage.company?.sharedName;

  return (
    <Dialog
      open
      fullScreen
      data-testid="move-package-dialog"
      onClose={closeWithoutPackage}
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
    >
      <DialogTitle className={classes.dialog}>
        <Container maxWidth="xs">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingTop={2.5}
          >
            <IconButton
              edge="start"
              data-testid="close-move-package-dialog"
              onClick={closeWithoutPackage}
            >
              <ArrowBack color="primary" />
            </IconButton>
          </Box>
        </Container>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Container maxWidth="xs">
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Armazenar</Box>
            </Typography>
          </Box>
          <Box paddingTop={1.5}>
            <Typography variant="h6">
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {companyName}
              </Box>
              <Box>#{currentPackage.identifier}</Box>
            </Typography>
          </Box>
          <Box paddingTop={2.5}>
            <BarcodeReader
              placeholder="Leia o código do local"
              onRead={handleUnitLoadRead}
              loading={isLoading}
              data-testid=""
            />
          </Box>
          {errorMessage && (
            <Box mt={1.5}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
}

MovePackageDialog.defaultProps = {
  sortingContextLicensePlate: null
};

MovePackageDialog.propTypes = {
  currentPackage: SharedPropTypes.package.isRequired,
  handlePackage: PropTypes.func.isRequired,
  closeWithoutPackage: PropTypes.func.isRequired,
  sortingContextLicensePlate: PropTypes.string
};
