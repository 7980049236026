import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { pxToRem } from '@loggi/mar/src/utils';
import { colors } from '@loggi/mar';

import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  expandedButton: {
    flexGrow: 1,
    marginBottom: pxToRem(15)
  },
  dialog: {
    padding: 0
  }
});

export default function NoDriverDialog({ open, handleClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
    >
      <DialogTitle className={classes.dialog}>
        <Container maxWidth="xs">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingTop={2.5}
          >
            <IconButton
              data-testid="no-driver-close"
              edge="start"
              onClick={handleClose}
            >
              <Close color="primary" />
            </IconButton>
          </Box>
        </Container>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Container maxWidth="xs">
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Distribuir</Box>
            </Typography>
          </Box>
          <Box paddingTop={1.5}>
            <Typography variant="h5" align="left">
              Eita! esse lacre ainda não tem um entregador.
            </Typography>
            <Typography variant="h5" align="left">
              Faça o envio de uma oferta para poder distribuir.
            </Typography>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <Container maxWidth="xs">
          <Box display="flex" flex={1}>
            <Button
              className={classes.expandedButton}
              size="large"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Beleza
            </Button>
          </Box>
        </Container>
      </DialogActions>
    </Dialog>
  );
}

NoDriverDialog.defaultProps = {
  open: false
};

NoDriverDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};
