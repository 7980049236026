import { colors } from '../principles';

const MuiFormHelperText = {
  root: {
    '&$error': {
      color: colors.red[500]
    }
  }
};

export default MuiFormHelperText;
