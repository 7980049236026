/**
 * The current date library has no support to override the current system's time zone,
 * this can be dangerous since it could show +1 day date in some devices with a
 * timezone different from America/Sao_Paulo.
 * This function can be removed when you are able to override the device timezone.
 *
 * @param {string} date - expecting an isodate formatted string
 * @returns {Date}
 */
const parseDateWithTimezone = ({ date }) => {
  return date
    ? new Date(
        new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          timeZone: 'America/Sao_Paulo'
        })
      )
    : '';
};

export default parseDateWithTimezone;
