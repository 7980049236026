import { FormHelperText, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useField } from 'formik';
import InputMask from 'react-input-mask';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { ASYNC_PENDING } from './constants';

const PostalCodeField = ({
  fieldName,
  label,
  validate,
  isCompanySignup,
  onDataChange
}) => {
  const { t } = useTranslation('one');
  const [asyncError, setAsyncError] = useState(undefined);
  const prevStateRef = useRef({});
  const prevState = prevStateRef.current;

  const MASK_POSTAL_CODE = '99999-999';

  const getNormalizedPostalCode = postalCode => postalCode?.replace(/\D/g, '');

  const runAsyncValidation = useCallback(
    async postalCode => {
      let result;
      let message;

      if (postalCode === getNormalizedPostalCode(prevState.value)) {
        return asyncError;
      }

      try {
        result = await validate(postalCode, '', false, isCompanySignup);
        message = result;
      } catch (e) {
        Sentry.captureException(e, {
          contexts: {
            runAsyncValidation: { postalCode }
          }
        });
        message = t('addressField.errorMessages.asyncValidation');
      }

      setAsyncError(message);

      return message;
    },
    [prevState.value, asyncError, t, validate, isCompanySignup]
  );

  const [field, meta] = useField({
    name: fieldName,
    validate: async newValue => {
      if (!newValue) return t('addressField.errorMessages.requiredField');
      const normalizedPostalCode = getNormalizedPostalCode(newValue);

      if (typeof validate !== 'function') return undefined;
      return runAsyncValidation(normalizedPostalCode);
    }
  });

  const { name, onBlur, onChange, value = '' } = field;
  const { error, touched } = meta;

  const isAsyncPending = error === ASYNC_PENDING;
  const hasError = Boolean(error) && touched && !isAsyncPending;

  useEffect(() => {
    prevStateRef.current = { value };
  }, [value]);

  return (
    <InputMask
      mask={MASK_POSTAL_CODE}
      maskChar={null}
      onBlur={onBlur}
      onChange={e => {
        e.preventDefault();
        onChange(e);
        onDataChange(e);
      }}
      value={value}
    >
      {(
        // we need the defaultProps to be able to test the Textfield inside the Input Mask
        defaultProps = {
          onChange: null,
          onBlur: null,
          validate: null
        }
      ) => (
        <FormControl error={hasError} fullWidth variant="outlined">
          <TextField
            error={hasError}
            label={label || t('companyPickupForm.inputLabels.postalCode')}
            name={name}
            inputProps={{ 'data-testid': 'postal-code-field' }}
            onBlur={defaultProps.onBlur}
            onChange={defaultProps.onChange}
            value={value}
            required
            fullWidth
            variant="outlined"
          />
          {!isAsyncPending && hasError && (
            <FormHelperText>
              <ErrorMessage name={name} />
            </FormHelperText>
          )}
        </FormControl>
      )}
    </InputMask>
  );
};

PostalCodeField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.func,
  isCompanySignup: PropTypes.bool,
  onDataChange: PropTypes.func.isRequired
};

PostalCodeField.defaultProps = {
  label: '',
  validate: null,
  isCompanySignup: false
};

export default PostalCodeField;
