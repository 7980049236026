import {
  Box,
  Button,
  Container,
  Drawer,
  Fade,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorIcon } from '../shared/images';
import useErrorBoundaryContext from './use-error-boundary-context';

const useAccessDeniedStyle = makeStyles(({ palette }) => ({
  image: {
    fill: palette.primary.contrastText
  },
  root: {
    backgroundColor: palette.grey['900']
  }
}));

const ErrorContent = ({ darkMode }) => {
  const { error } = useErrorBoundaryContext();
  const styles = useAccessDeniedStyle();
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation('one');

  const resetSession = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  return (
    <>
      <Fade in timeout={500}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height={1}
          justifyContent="center"
          maxWidth={600}
          mx="auto"
          textAlign="center"
        >
          <Box mt="auto" mb={2}>
            <ErrorIcon className={styles.image} />
          </Box>
          <Typography color="inherit" component="h1" variant="h6" gutterBottom>
            <Box fontWeight="fontWeightMedium" component="strong">
              {t('errorBoundaries.title')}
            </Box>
          </Typography>
          <Typography color="inherit">
            {t('errorBoundaries.description')}
          </Typography>
          <Box width={{ xs: '100%', sm: 180 }} mx="auto" mt={3} mb={2}>
            <Button
              color={darkMode ? 'secondary' : 'primary'}
              data-testid="reset-button"
              fullWidth
              onClick={resetSession}
              size="small"
              variant="outlined"
            >
              {t('errorBoundaries.resetSession')}
            </Button>
          </Box>
          <Box width={{ xs: '100%', sm: 240 }} mx="auto" mt="auto">
            <Button
              color={darkMode ? 'secondary' : 'primary'}
              fullWidth
              onClick={() => setShowError(state => !state)}
              size="small"
              variant="text"
            >
              {t('errorBoundaries.showErrorDetails')}
            </Button>
          </Box>
        </Box>
      </Fade>
      <Drawer
        open={showError}
        anchor="bottom"
        onClose={() => setShowError(false)}
        keepMounted={false}
      >
        <Box
          component="pre"
          m={0}
          py={3}
          overflow="auto"
          whiteSpace="pre-wrap"
          bgcolor="grey.900"
          color="common.white"
        >
          <Box position="relative" clone>
            <Container>
              <IconButton
                color="inherit"
                data-testid="close-icon"
                edge="end"
                onClick={() => setShowError(false)}
                style={{ position: 'absolute', right: 24, top: -12 }}
              >
                <CloseIcon />
              </IconButton>
              {error.message}
            </Container>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

ErrorContent.propTypes = { darkMode: PropTypes.bool };
ErrorContent.defaultProps = { darkMode: false };

export default ErrorContent;
