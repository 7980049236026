import PropTypes, { shape, string, number, arrayOf, bool } from 'prop-types';
import { UNIT_LOAD_TYPE } from './enums';

const pkg = shape({
  id: number,
  identifier: string.isRequired,
  intDeliveriesAttempts: number,
  loggiKey: string,
  trackingKey: string,
  currentPromisedDate: string,
  startProcessingDeadlineDate: string,
  recipient: shape({
    name: string
  }),
  company: shape({
    id: string,
    name: string,
    sharedName: string
  }),
  errorType: string,
  postingCard: shape({
    name: string,
    number: string
  }),
  status: string,
  item: number,
  totalItems: number
});

const packages = arrayOf(pkg);

const driver = shape({
  fullName: string,
  docCpf: shape({ number: string }),
  photoUrl: shape({ profileHighRes: string }),
  cnh: shape({ number: string }),
  docRg: shape({ number: string })
});

const unitLoad = shape({
  name: string.isRequired,
  license_plate: string,
  licensePlate: string,
  created: string,
  transhipmentRoutingCodeDestination: string
});

const label = shape({
  base64Pdf: string,
  type: string.isRequired,
  missingText: string,
  packageCheckNotes: string.isRequired,
  packageCheckIdentifier: string
});

const pkgToPrepareInfo = shape({
  mainLabel: label.isRequired,
  pkg: pkg.isRequired
});

const pkgsToPrepareInfo = arrayOf(pkgToPrepareInfo);

const decision = shape({
  name: string.isRequired,
  confirmationType: string.isRequired,
  demandRegionLabel: string.isRequired,
  destination: shape({
    id: string,
    licensePlate: string,
    name: string,
    expeditionCenter: shape({
      routingCode: string,
      name: string
    }),
    metadata: shape({
      sideEffect: string
    })
  }).isRequired
});

const childUnitLoad = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(UNIT_LOAD_TYPE)),
  childUnitLoad: pkg
});

const childrenUnitLoad = arrayOf(childUnitLoad);

const sortingContext = PropTypes.shape({
  name: string,
  licensePlate: string.isRequired,
  recommendationLicensePlate: string
});

const itinerary = PropTypes.shape({
  statusDisplay: string,
  timePassedMin: number,
  pk: number,
  url: string,
  cutoffRoute: string,
  distributionCenter: shape({
    routingCode: string,
    name: string
  })
});

const bag = PropTypes.shape({
  status: string,
  packagesInBag: packages,
  bagData: shape({
    seal: string,
    numPackages: number,
    cutoffRoute: string,
    distributionCenter: shape({
      routingCode: string,
      name: string
    })
  }),
  driver,
  itinerary
});

const caraCrachaRecord = PropTypes.shape({
  status: string,
  needOnboardingInstructions: bool
});

export default {
  package: pkg,
  packages,
  driver,
  unitLoad,
  label,
  pkgToPrepareInfo,
  pkgsToPrepareInfo,
  childUnitLoad,
  decision,
  childrenUnitLoad,
  sortingContext,
  bag,
  caraCrachaRecord
};
