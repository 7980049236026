import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { movePackage } from '../../api-rest';
import { BarcodeReader } from '../../app/components/barcode-readers';
import handleRestAPIError from '../../app/utils/rest-api-request';
import { OrganizeProcessContext } from './organize-process-context';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { OPERATIONAL_PROCESS, ACTIVITY, SWITCHES } from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { playSuccessBeep } from '../../sounds';
import { useFeature } from '../../app/hooks/use-feature';

export const MESSAGES = {
  SUCCESS: 'Boa! Pacote armazenado.'
};

/**
 * Wrapper to a BarcodeReader that stores the package
 *
 * @callback onSuccessCallback called when Prepare Info is successfully fetched
 * @callback onErrorCallback called when and error happens while fetching Receive Info
 *
 * @param {boolean} loading - Loading state
 * @param {function} setLoading - Function to change the loading state
 * @param {function} onSuccessCallback -
 * @param {function} onErrorCallback -
 *
 */
export default function StorageReader({
  onSuccessCallback,
  onErrorCallback,
  loading,
  setLoading
}) {
  const { sortingContext, unitInfo } = useContext(OrganizeProcessContext);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const enableChangeLicensePlateSentMovePackage = useFeature(
    SWITCHES.enableChangeLicensePlateSentMovePackage
  );

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const sideEffectParams = {
    distributionCenterId: selectedDistributionCenter?.distributionCenterId
  };

  const handleRead = destinationLicensePlate => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ORGANIZE_STORAGE_BEEP
    );

    const sortingPredicateId = unitInfo?.decision?.predicate;
    const sortingDecisionUnitloadId = unitInfo?.decision?.destination?.id;
    const sortingDecisionUnitloadLicensePlate = enableChangeLicensePlateSentMovePackage
      ? unitInfo?.decision?.destination?.licensePlate
      : destinationLicensePlate;

    const moveRecordParams = {};

    if (sortingPredicateId && sortingDecisionUnitloadId) {
      moveRecordParams.sortingPredicateId = sortingPredicateId;
      moveRecordParams.sortingDecisionUnitloadId = sortingDecisionUnitloadId;
    }

    setLoading(true);

    if (enableChangeLicensePlateSentMovePackage) {
      movePackage(
        destinationLicensePlate,
        unitInfo?.pkg?.identifier,
        sortingDecisionUnitloadLicensePlate,
        sortingContext.licensePlate,
        sideEffectParams,
        moveRecordParams
      )
        .then(response =>
          response?.data?.errorMsg || response?.data?.error
            ? onErrorCallback(response.data.errorMsg || response.data.error)
            : onSuccessCallback(MESSAGES.SUCCESS)
        )
        .catch(error => handleRestAPIError(error, onErrorCallback))
        .finally(() => setLoading(false));

      playSuccessBeep();
    } else {
      movePackage(
        destinationLicensePlate,
        unitInfo?.pkg?.identifier,
        sortingDecisionUnitloadLicensePlate,
        sortingContext.licensePlate,
        sideEffectParams,
        moveRecordParams
      )
        .then(() => onSuccessCallback(MESSAGES.SUCCESS))
        .catch(error => handleRestAPIError(error, onErrorCallback))
        .finally(() => setLoading(false));

      playSuccessBeep();
    }

    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.ORGANIZE_STORAGE_BEEP);
  };

  return (
    <Box>
      <Box my={2.5}>
        <BarcodeReader
          onRead={handleRead}
          loading={loading}
          placeholder="Bipe o local de armazenamento"
        />
      </Box>
    </Box>
  );
}

StorageReader.defaultProps = {
  loading: false
};

StorageReader.propTypes = {
  onSuccessCallback: PropTypes.func.isRequired,
  onErrorCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired
};
