import { COGNITO_DISTRIBUTION_CENTER } from '../../constants';

const getUserType = selectedDC => {
  if (!selectedDC) {
    return null;
  }

  const { lastMileCompanyType } = selectedDC;
  if (lastMileCompanyType) {
    return lastMileCompanyType ===
      COGNITO_DISTRIBUTION_CENTER.LAST_MILE_COMPANY_TYPE_LEVE
      ? COGNITO_DISTRIBUTION_CENTER.LEVE_USER
      : COGNITO_DISTRIBUTION_CENTER.AGENCY_USER;
  }

  return null;
};

export default getUserType;
