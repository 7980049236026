import { useEffect, useReducer } from 'react';
import camelCase from 'lodash/camelCase';
import firebase from 'firebase/app';
import { REMOTE_CONFIG_DEFAULT } from '../constants';

const remoteConfigDict = {};

Object.keys(REMOTE_CONFIG_DEFAULT).forEach(remoteConfigKey => {
  remoteConfigDict[camelCase(remoteConfigKey)] =
    REMOTE_CONFIG_DEFAULT[remoteConfigKey];
});

export const initialState = {
  isLoading: true,
  hasError: false,
  value: remoteConfigDict
};

/**
 * Firebase reducer to be used with useReducer hook
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @returns {Object} Updated state
 */
/* eslint-disable consistent-return, default-case */
const remoteConfigReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_DONE':
      return {
        ...state,
        isLoading: false,
        value: action.payload
      };
  }
};
/* eslint-enable consistent-return, default-case */

/**
 * Custom Hook
 * Get Firebase Remote Config parameter value
 *
 * @returns {Object}
 *
 * Usage example:
 *   const { value, isLoading } = useRemoteConfig();
 */
const useRemoteConfig = () => {
  const [state, dispatch] = useReducer(remoteConfigReducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      const { remoteConfig } = firebase;
      const remoteValues = await remoteConfig()
        .fetchAndActivate()
        .then(() => {
          // 'asString' is needed because Firebase outputs a structured object.
          // Using 'asString' here normalizes the output.
          // We are mapping all keys and returning a dict
          const remoteValuesDict = {};

          Object.keys(REMOTE_CONFIG_DEFAULT).forEach(remoteConfigKey => {
            remoteValuesDict[camelCase(remoteConfigKey)] = remoteConfig()
              .getValue(remoteConfigKey)
              .asString();
          });

          return remoteValuesDict;
        });

      dispatch({ type: 'FETCH_DONE', payload: remoteValues });
    };

    fetchData();
  }, []);

  return { ...state };
};

export default useRemoteConfig;
