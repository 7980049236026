import { colors } from '../principles';
import { pxToRem } from '../utils';

const MuiFab = {
  root: {
    backgroundColor: colors.root[0],
    border: `solid ${pxToRem(1)} ${colors.blue[500]}`,
    color: colors.blue[500],
    '&:hover': {
      backgroundColor: colors.blue[50],
      '@media (hover: none)': {
        backgroundColor: colors.blue[50]
      }
    },
    '&.Mui-disabled': {
      border: 'none'
    }
  },
  primary: {
    background: colors.gradients.sanches,
    '&.Mui-disabled': {
      backgroundImage: 'none'
    },
    border: 'none'
  },
  secondary: {
    border: 'none'
  }
};

export default MuiFab;
