import React from 'react';
import { Box, Container, Paper } from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';

export default function FloatingContainer({ children }) {
  return (
    <Paper
      style={{
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTop: `1px solid ${colors.smoke[100]}`
      }}
      component={Box}
      elevation={12}
      padding={3}
      data-testid="show-cargo-info"
      height="100%"
    >
      <Container maxWidth="xs" disableGutters>
        {children}
      </Container>
    </Paper>
  );
}

FloatingContainer.propTypes = {
  children: PropTypes.element.isRequired
};
