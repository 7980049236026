import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';
import signupBackgroundImage from '../shared/images/sign-up/background-image.svg';

const useCompanySignupStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    background: {
      backgroundImage: `url(${signupBackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left',
      backgroundColor: 'white',
      backgroundSize: 'cover',
      [breakpoints.only('md')]: {
        backgroundPosition: '42% 100%'
      }
    },
    backgroundMobile: {
      backgroundColor: 'white'
    },
    descriptionSection: {
      fontWeight: 600,
      [breakpoints.up('md')]: {
        flexGrow: 2
      },
      [breakpoints.only('md')]: {
        width: '458px'
      },
      [breakpoints.only('lg')]: {
        width: '620px'
      }
    },
    formSignup: {
      marginTop: spacing(-17),
      [breakpoints.only('xs')]: {
        marginTop: spacing(-6),
        width: '415px'
      },
      [breakpoints.only('sm')]: {
        marginTop: spacing(-18),
        width: '473px'
      },
      [breakpoints.up('md')]: {
        width: '500px',
        flexGrow: 1
      }
    },
    formAccountSignup: {
      marginTop: spacing(-17),
      [breakpoints.only('xs')]: {
        marginTop: spacing(10),
        width: '415px'
      },
      [breakpoints.only('sm')]: {
        marginTop: spacing(10),
        width: '473px'
      },
      [breakpoints.up('md')]: {
        width: '500px',
        flexGrow: 1
      }
    },
    pickupStatusImage: {
      [breakpoints.down('lg')]: {
        width: '100%',
        marginBottom: spacing(2)
      },
      [breakpoints.up('lg')]: {
        width: 'auto'
      },
      height: 'auto'
    },
    pickupStatusSection: {
      marginLeft: spacing(5)
    },
    sidebar: {
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.up('md')]: {
        justifyContent: 'space-between'
      }
    },
    stepCounter: {
      backgroundColor: colors.smoke[100],
      borderRadius: '100px',
      width: '65px'
    },
    textHeader: {
      color: palette.common.white,
      marginTop: spacing(-15),
      marginBottom: spacing(15)
    }
  })
);

export default useCompanySignupStyles;
