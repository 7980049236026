import * as overrides from '../../overrides';
import MuiButtonProps from '../../overrides/mui-button';
import MuiMobileStepperProps from '../../overrides/mui-mobile-stepper';
import palette from '../../palette';
import { pxToRem } from '../../utils';
import { baseThemeProps } from '../../theme';
import colors from './colors';
import typography from './typography';

export default {
  ...baseThemeProps,
  palette: {
    ...palette,
    primary: {
      main: colors.primary.pure
    }
  },
  overrides: {
    ...overrides,
    MuiButton: {
      ...MuiButtonProps,
      containedPrimary: {
        ...MuiButtonProps.containedPrimary,
        background: colors.primary.pure
      },
      root: {
        ...MuiButtonProps.root,
        borderRadius: pxToRem(8)
      }
    },
    MuiOutlinedInput: {},
    MuiMobileStepper: {
      ...MuiMobileStepperProps,
      dotActive: {
        backgroundColor: colors.primary.pure,
        width: pxToRem(16),
        borderRadius: 100
      }
    },
    MuiFormControlLabel: {
      root: {
        color: colors.neutrals.typeface.primary,
        backgroundColor: colors.neutrals.shapes.lighter,
        fontWeight: 500,
        padding: 10,
        borderRadius: 8,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0
      }
    }
  },
  typography,
  colors
};
