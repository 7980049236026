import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import Divider from '@material-ui/core/Divider';
import {
  Button,
  ListItemIcon,
  Container,
  TablePagination,
  withStyles
} from '@material-ui/core';

import { colors } from '@loggi/mar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos } from '@material-ui/icons';
import { playSuccessBeep, playErrorBeep } from '../sounds';
import { DetailItem } from './start-transfer';
import { PackageReader } from '../app/components/barcode-readers';
import { OPERATIONAL_PROCESS, ACTIVITY } from '../constants';
import SearchTransferBar from '../app/components/search-transfer';
import { addBagToTransfer, searchTransfers } from '../api-rest';
import handleRestAPIError from '../app/utils/rest-api-request';
import { useDistributionCenter } from '../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';

export const NOTIFICATIONS = {
  BAG_READ: 'Unidade já foi lida.',
  SUCCESS: 'Unidade adicionada com sucesso.',
  UNABLE_TO_LOAD_DATA: 'Não foi possível carregar os dados. Tente novamente.',
  EMPTY_VALID_TRANSFERS:
    'Essa base não existe ou ainda não tem transferências. Tente de novo.'
};

const screens = {
  ce_list: 1,
  transfer_list: 2,
  bag_reader: 3,
  summary: 4
};
const title = 'Adicionar Unidades à Transferência';

const subtitles = {
  [screens.ce_list]: 'Escolha a base',
  [screens.transfer_list]: 'Escolha a Transferencia',
  [screens.bag_reader]: 'Bipar Unidades',
  [screens.summary]: 'Resumo'
};

const useListIconStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const useBlueIconStyle = makeStyles(theme => ({
  root: {
    color: colors.blue[500],
    ...theme.typography.body2
  }
}));

/**
 * The main Functional Component
 * for add bag to Transfer
 */

export const AddBagToTransfer = ({ location }) => {
  const { trackEnd } = useContext(ActivityTrackingContext);
  const [
    transfersByExpeditionCenter,
    setTransfersByExpeditionCenter
  ] = useState([]);
  const [currentExpeditionCenter, setCurrentExpeditionCenter] = useState([]);
  const [screen, setScreen] = useState(screens.ce_list);
  const [currentTransfer, setCurrentTransfer] = useState({});
  const [readBags, setReadBags] = useState([]);

  const { showButtonStartTransfer } = location.state || false;

  function resetState() {
    setCurrentExpeditionCenter([]);
    setCurrentTransfer({});
    setReadBags([]);
    setScreen(screens.ce_list);
    trackEnd(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
  }

  const components = {
    [screens.ce_list]: (
      <ExpeditionCenterList
        setScreen={setScreen}
        setCurrentExpeditionCenter={setCurrentExpeditionCenter}
        setTransfersByExpeditionCenter={setTransfersByExpeditionCenter}
      />
    ),
    [screens.transfer_list]: (
      <TransferList
        setScreen={setScreen}
        transfersList={transfersByExpeditionCenter}
        currentExpeditionCenter={currentExpeditionCenter}
        setCurrentTransfer={setCurrentTransfer}
      />
    ),
    [screens.bag_reader]: (
      <AddBag
        setScreen={setScreen}
        currentTransfer={currentTransfer}
        setCurrentTransfer={setCurrentTransfer}
        readBags={readBags}
        setReadBags={setReadBags}
      />
    ),
    [screens.summary]: (
      <TransferSummary
        currentTransfer={currentTransfer}
        setCurrentTransfer={setCurrentTransfer}
        setScreen={setScreen}
        readBags={readBags}
        resetState={resetState}
        showButtonStartTransfer={showButtonStartTransfer}
      />
    )
  };

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Typography>
      <Box mt={2.5}>
        <Typography variant="subtitle2">{subtitles[screen]}</Typography>
        <Box mt={1.5}>{components[screen]}</Box>
      </Box>
    </Container>
  );
};

AddBagToTransfer.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      showButtonStartTransfer: PropTypes.bool
    })
  })
};

AddBagToTransfer.defaultProps = {
  location: {
    state: {
      showButtonStartTransfer: false
    }
  }
};

/**
 * Expedition Center List
 */

export const ExpeditionCenterList = ({
  setScreen,
  setCurrentExpeditionCenter,
  setTransfersByExpeditionCenter
}) => {
  const [transfersList, setTransfersList] = useState([]);
  const [expeditionCentersList, setExpeditionCentersList] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    itemsCount: 0,
    nPages: 0
  });
  const [currentPage, setCurrentPage] = useState(0);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { trackStart } = useContext(ActivityTrackingContext);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.DC_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listIcon = useListIconStyles();
  const blueIcon = useBlueIconStyle();

  const StyledTablePagination = withStyles({
    actions: {
      color: colors.blue[500]
    }
  })(TablePagination);

  function errorHandler(err) {
    setLoading(false);
    setError(err);
    playErrorBeep();
  }

  useEffect(() => {
    (async function getTransfersList() {
      setError('');
      setLoading(true);
      setTransfersList({});

      setLoading(true);

      const response = await searchTransfers({
        statuses: 'saved',
        searchTerm,
        selectedDistributionCenterId:
          selectedDistributionCenter?.distributionCenterId,
        itemsPerPage: 10,
        page: currentPage + 1
      }).catch(err => {
        handleRestAPIError(err, errorHandler);
      });

      if (!response) return;

      if (response) {
        const {
          transfers = [],
          pagination: paginationFromResponse,
          expeditionCenters = []
        } = response?.data?.searchTransfers;

        if (transfers.length) {
          setExpeditionCentersList(expeditionCenters);
          setTransfersList(transfers);
          setPagination(paginationFromResponse);
        } else {
          setError(NOTIFICATIONS.EMPTY_VALID_TRANSFERS);
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, setPagination, currentPage]);

  function handleClicked(expeditionCenter) {
    playSuccessBeep();
    const result = transfersList.filter(
      item => item.expeditionCenter.pk === expeditionCenter.pk
    );

    setTransfersByExpeditionCenter(result);
    setCurrentExpeditionCenter(expeditionCenter);
    setScreen(screens.transfer_list);
  }

  return (
    <Box>
      <SearchTransferBar setSearchTerm={term => setSearchTerm(term)} />
      {!loading && error && (
        <Box className="centered" mt={1.5}>
          <Alert severity="warning">{error}</Alert>
        </Box>
      )}

      {!loading && Boolean(expeditionCentersList.length) && (
        <Grid item xs={12}>
          <List>
            {!error &&
              expeditionCentersList.map(expeditionCenter => (
                <ListItem
                  button
                  divider
                  dense
                  disableGutters
                  data-testid={`list-item-${expeditionCenter.pk}`}
                  key={expeditionCenter.routingCode}
                  onClick={() => handleClicked(expeditionCenter)}
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <ListItemText
                        key={expeditionCenter.routingCode}
                        primary={expeditionCenter.routingCode}
                        secondary={expeditionCenter.shortDisplay}
                      />
                    </Grid>
                    <Grid item xs={1} classes={listIcon}>
                      <ListItemIcon>
                        <ArrowForwardIos fontSize="small" classes={blueIcon} />
                      </ListItemIcon>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
          </List>
        </Grid>
      )}

      {!error && !loading && (
        <StyledTablePagination
          component="div"
          data-testid="pagination"
          page={currentPage}
          onChangePage={(event, page) => setCurrentPage(page)}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
          count={pagination.itemsCount}
          labelDisplayedRows={({ page }) => {
            return `${page + 1} de ${pagination.nPages}`;
          }}
        />
      )}
      {loading && (
        <>
          {[...Array(9)].map(() => (
            <Skeleton component={Box} height={60} variant="rect" my={1} />
          ))}
        </>
      )}
    </Box>
  );
};

ExpeditionCenterList.propTypes = {
  setScreen: PropTypes.func.isRequired,
  setCurrentExpeditionCenter: PropTypes.func.isRequired,
  setTransfersByExpeditionCenter: PropTypes.func.isRequired
};

/**
 * Transfers List
 */
export const TransferList = props => {
  const [error, setError] = useState('');
  const { setScreen, transfersList, setCurrentTransfer } = props;

  const listIcon = useListIconStyles();
  const blueIcon = useBlueIconStyle();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.DC_LIST);
    trackStart(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.TRANSFER_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError('');
    if (!transfersList || !transfersList.length) {
      setError('Nenhuma transferência encontrada');
    }
  }, [transfersList]);

  function handleClicked(transfer) {
    playSuccessBeep();
    setCurrentTransfer(transfer);
    setScreen(screens.bag_reader);
  }

  return (
    <Box>
      {error && (
        <Box className="centered">
          <Alert severity="warning">{error}</Alert>
        </Box>
      )}

      {transfersList.length && (
        <Grid item xs={12}>
          <List>
            {transfersList.map(transfer => (
              <ListItem
                button
                divider
                disableGutters
                dense
                key={transfer.pk}
                onClick={() => handleClicked(transfer)}
              >
                <Grid container>
                  <Grid item xs={11}>
                    <ListItemText
                      key={transfer.pk}
                      primary={transfer.name}
                      secondary={moment(transfer.updated).format('LL')}
                    />
                  </Grid>
                  <Grid item xs={1} classes={listIcon}>
                    <ListItemIcon>
                      <ArrowForwardIos fontSize="small" classes={blueIcon} />
                    </ListItemIcon>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Box>
  );
};

TransferList.propTypes = {
  setScreen: PropTypes.func.isRequired,
  transfersList: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number,
      name: PropTypes.string,
      updated: PropTypes.string
    })
  ).isRequired,
  setCurrentTransfer: PropTypes.func.isRequired
};

const AddBag = props => {
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { setScreen, currentTransfer, readBags, setReadBags } = props;
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.TRANSFER_LIST);
    trackStart(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.BEEP_BAG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddBagToTransfer = async bagSeal => {
    setError('');
    setSuccessMessage('');

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ADD_BAG_TO_TRANSFER_BEEP
    );

    if (readBags.includes(bagSeal)) {
      playErrorBeep();
      setError(NOTIFICATIONS.BAG_READ);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.ADD_BAG_TO_TRANSFER_BEEP
      );
      return;
    }

    const response = await addBagToTransfer({
      bagSeal,
      transferId: currentTransfer.pk
    }).catch(err => {
      handleRestAPIError(err, setError);
      playErrorBeep();
    });

    if (!response) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.ADD_BAG_TO_TRANSFER_BEEP
      );
      return;
    }

    playSuccessBeep();
    setReadBags(prevBags => [...prevBags, bagSeal]);
    setSuccessMessage(NOTIFICATIONS.SUCCESS);
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ADD_BAG_TO_TRANSFER_BEEP
    );
    trackEnd(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.BEEP_BAG);
    trackStart(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.BEEP_BAG);
  };

  function handleFinishClicked() {
    setScreen(screens.summary);
    trackEnd(OPERATIONAL_PROCESS.ADD_BAG_TO_TRANSFER, ACTIVITY.BEEP_BAG);
  }

  return (
    <Box>
      <Box className="centered">
        <PackageReader
          onRead={handleAddBagToTransfer}
          notes="Transferência de Unidade pelo XD App"
          source="xd_app_transfer"
        />
        {error && (
          <Box className="centered" mt={1.5}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {successMessage && (
          <Box className="centered" mt={1.5}>
            <Alert severity="success">{successMessage}</Alert>
          </Box>
        )}
      </Box>
      <Box py={1}>
        <Divider />
      </Box>
      <Grid container>
        <Divider />
        <Grid item p={1}>
          <DetailItem
            key="item-transfer"
            label="Transferência"
            field={currentTransfer.name}
          />
          <DetailItem
            key="item-bags"
            label="Unidades Lidas"
            field={readBags.length}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        p={1}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinishClicked}
          disabled={!readBags.length}
          fullWidth
        >
          Finalizar Carregamento
        </Button>
      </Box>
    </Box>
  );
};
AddBag.propTypes = {
  setScreen: PropTypes.func.isRequired,
  currentTransfer: PropTypes.shape({
    pk: PropTypes.number,
    name: PropTypes.string,
    updated: PropTypes.string
  }),
  readBags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setReadBags: PropTypes.func.isRequired
};

AddBag.defaultProps = {
  currentTransfer: {
    pk: '',
    name: '',
    updated: '',
    readBags: 0
  }
};

/**
 * Bags at Transfer Summary
 */
export const TransferSummary = props => {
  const history = useHistory();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const {
    currentTransfer,
    setScreen,
    readBags,
    resetState,
    setCurrentTransfer,
    showButtonStartTransfer
  } = props;
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const getTransferSummary = async () => {
    setError('');
    setLoading(true);

    const errorHandler = err => {
      setLoading(false);
      setError(err);
      playErrorBeep();
    };

    const response = await searchTransfers({
      ids: currentTransfer.pk
    }).catch(err => {
      handleRestAPIError(err, errorHandler);
    });

    if (response) {
      const transfers = response?.data?.searchTransfers?.transfers ?? [];

      if (!transfers.length) {
        setError(NOTIFICATIONS.UNABLE_TO_LOAD_DATA);
      } else {
        setCurrentTransfer(transfers[0]);
        playSuccessBeep();
      }
      setLoading(false);
    } else {
      playErrorBeep();
      setError(NOTIFICATIONS.UNABLE_TO_LOAD_DATA);
      setLoading(false);
    }
  };

  useEffect(() => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ADD_BAG_TO_TRANSFER_SUMARY_BEEP
    );
    getTransferSummary();
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.ADD_BAG_TO_TRANSFER_SUMARY_BEEP
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToStartTransfer = () => {
    history.push({ pathname: '/start-transfer' });
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Box py={1} className="centered">
          <Divider />
        </Box>
      </Grid>

      {loading && (
        <Grid container justify="center">
          <Box p={2}>
            <CircularProgress justify="center" />
          </Box>
        </Grid>
      )}

      {!loading && error && (
        <Box className="centered" mt={1.5}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {!loading && !error && (
        <>
          <Grid item p={1}>
            <DetailItem
              key="item-transfer"
              label="Transferência"
              field={currentTransfer.name} // add transfer.name
            />
            <DetailItem
              key="item-bags"
              label="Últimas Unidades adicionadas"
              field={readBags.length || 0} // Add Read bags
            />
            <DetailItem
              key="total-item-bags"
              label="Total de Unidades na Transferência"
              field={currentTransfer.numBags} // Add total bags
            />
          </Grid>
          <Box mt={2.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              mt={1.5}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setScreen(screens.bag_reader)}
                fullWidth
              >
                Adicionar mais Unidades
              </Button>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              mt={1.5}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={resetState}
                fullWidth
              >
                Nova transferência
              </Button>
            </Box>
            {showButtonStartTransfer && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                mt={1.5}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="go-to-start-transfer"
                  onClick={goToStartTransfer}
                  fullWidth
                >
                  Iniciar Transferência
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
TransferSummary.propTypes = {
  setScreen: PropTypes.func.isRequired,
  currentTransfer: PropTypes.shape({
    pk: PropTypes.number,
    name: PropTypes.string,
    updated: PropTypes.string,
    numBags: PropTypes.number
  }).isRequired,
  setCurrentTransfer: PropTypes.func.isRequired,
  readBags: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetState: PropTypes.func.isRequired,
  showButtonStartTransfer: PropTypes.bool
};

TransferSummary.defaultProps = {
  showButtonStartTransfer: false
};

export default AddBagToTransfer;
