import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import HeaderWithReturn from '../../../app/components/header-with-return';
import { TEXT, NUMBER } from './constants';
import { useFeature } from '../../../app/hooks/use-feature';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  SWITCHES,
  ROUTES,
  COGNITO_DISTRIBUTION_CENTER
} from '../../../constants';
import DamagePhotoUpload from './damage-photo-upload';
import { matchImage } from './damaged-package-service';
import convertBase64ToFile from '../../../app/utils/convert-image';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import TextWithBoldEmphasis from '../../../app/components/text-with-bold-emphasis';
import { playErrorBeep } from '../../../sounds';
import ImageView from './image-view';
import { DamagedPackageContext } from './damaged-package-context';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import getUserType from '../../../app/access-control/access-control-service';

export default function MatchImageDamagedPackage({ matchImageService }) {
  const {
    packageId,
    setPackageId,
    imageLabel: image,
    setImageLabel: setImage,
    setIsImageLabelIdentified
  } = useContext(DamagedPackageContext);

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const [isLoading, setIsLoading] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(
    NUMBER.TOTAL_RETRY_MATCH_IMAGE
  );
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const enableDeclarePackageDamageV2ByDc = useFeature(
    SWITCHES.enableDeclarePackageDamageV2ByDc
  );
  const enableDeclarePackageDamageV2AllDcsNoLeve = useFeature(
    SWITCHES.enableDeclarePackageDamageV2AllDcsNoLeve
  );

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const isDeclareDamagedPackageEnabled =
    enableDeclarePackageDamageV2ByDc ||
    (enableDeclarePackageDamageV2AllDcsNoLeve && !isUserLeve);

  const handleGoBack = useCallback(() => history.push('/identify'), [history]);

  const handleGoNextStep = useCallback(
    isImageValid => {
      setIsImageLabelIdentified(isImageValid);
      history.push(ROUTES.DAMAGED_PACKAGE.PREVISUALIZE_IMAGE);
    },
    [setIsImageLabelIdentified, history]
  );

  const errorHandler = useCallback(
    errMessage => {
      showSnackbar({
        message: errMessage,
        variant: 'error',
        showCloseButton: true,
        enqueueSnackbar
      });
      playErrorBeep();
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (!isDeclareDamagedPackageEnabled) {
      handleGoBack();
    }
  }, [isDeclareDamagedPackageEnabled, handleGoBack]);

  useEffect(() => {
    const packState = location?.state?.pack;
    if (packState) {
      setPackageId(packState.pk);
    } else if (!packageId) handleGoBack();
  }, [location, setPackageId, packageId, handleGoBack]);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2, ACTIVITY.FULL_PROCESS);
    trackStart(
      OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2,
      ACTIVITY.MATCH_IMAGE_STEP
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmSend = imageData => {
    setImage(imageData);
  };

  const removeImage = useCallback(() => setImage(''), [setImage]);

  const handleRetry = useCallback(() => {
    const attempts = remainingAttempts - 1;
    setRemainingAttempts(attempts);
    if (attempts) {
      errorHandler(TEXT.ERROR_MATCH_IMAGE);
      removeImage();
    } else {
      errorHandler(TEXT.ERROR_MATCH_IMAGE_JIRA);
      handleGoNextStep(false);
    }
  }, [errorHandler, handleGoNextStep, remainingAttempts, removeImage]);

  const handleSend = async () => {
    setIsLoading(true);
    const file = convertBase64ToFile(image, `label.jpeg`);

    const response = await matchImageService({
      packageId,
      image: file,
      errorHandler
    });

    if (response && response.match) {
      trackEnd(
        OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2,
        ACTIVITY.MATCH_IMAGE_STEP
      );
      handleGoNextStep(true);
    } else if (response && !response.match) {
      handleRetry();
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <HeaderWithReturn title={TEXT.TITLE} onReturn={handleGoBack} />
      <Container maxWidth="xs">
        <Box pt={10}>
          {image ? (
            <ImageView
              titleComponent={
                <TextWithBoldEmphasis
                  text={TEXT.TITLE_CONFIRM_MATCH_IMAGE}
                  typographyVariant="subtitle1"
                />
              }
              titleAlt={TEXT.ALT_MATCH_IMAGE}
              removeImage={removeImage}
              image={image}
              isLoading={isLoading}
              handleSend={handleSend}
            />
          ) : (
            <DamagePhotoUpload
              titleComponent={
                <TextWithBoldEmphasis
                  text={TEXT.TITLE_MATCH_IMAGE.TEXT}
                  emphasis={TEXT.TITLE_MATCH_IMAGE.EMPHASIS}
                  typographyVariant="subtitle1"
                />
              }
              titleAlt={TEXT.ALT_MATCH_IMAGE}
              onConfirmSend={onConfirmSend}
              cameraHeight="70vh"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

MatchImageDamagedPackage.defaultProps = {
  // It becomes easier to test/mock by injecting the service.
  matchImageService: matchImage
};

MatchImageDamagedPackage.propTypes = {
  matchImageService: PropTypes.func
};
