/**
 * Convert Image (Base64) to File object
 * @param {*} base64 image in base64 format
 * @param {string} filename
 */
const convertBase64ToFile = (base64, filename) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bStr = Buffer.from(
    base64.replace(/^data:image\/\w+;base64,/, ''),
    'base64'
  );
  return new File([bStr], filename, { type: mime });
};

export default convertBase64ToFile;
