import React from 'react';
import PropTypes from 'prop-types';

export const ScannerContext = React.createContext();

/*
 * Scanner provider high order component.
 */
export function ScannerProvider({ children }) {
  const [screenScanner, setScreenScanner] = React.useState(false);

  return (
    <ScannerContext.Provider value={[screenScanner, setScreenScanner]}>
      {children}
    </ScannerContext.Provider>
  );
}

ScannerProvider.propTypes = {
  children: PropTypes.element.isRequired
};
