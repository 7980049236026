import React from 'react';

import { Box } from '@material-ui/core';

import Logo from '../../assets/images/icon.png';

export default function DecorativeHeader() {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
      <Box>
        <img src={Logo} alt="Loggi" height="25" />
      </Box>
    </Box>
  );
}
