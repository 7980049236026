import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';

import { Box, Typography, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { useAmplifyAuth } from '@loggi/authentication-lib';
import handleRestAPIError from '../../app/utils/rest-api-request';
import { caraCrachaForDriver, getDistributeInfo } from '../../api-rest';
import { identifyBarcode } from '../../api';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';

import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { PackageReader } from '../../app/components/barcode-readers';
import HeaderWithCancel from '../../app/components/header-with-cancel';

import NoDriverDialog from './no-driver-dialog';
import DirtyUnitLoadDialog from './dirty-unit-load-dialog';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import {
  ACTIVITY,
  CARA_CRACHA_FOR_DRIVER,
  OPERATIONAL_PROCESS,
  SWITCHES
} from '../../constants';
import CaraCrachaCheckDialog from './cara-cracha-check-dialog';
import CheckUnitLoadService from './check-unit-load-packages-service';
import extractDataFromGraphQLResponse from '../../app/utils/graphql-request';
import { BAG_TYPE } from '../../app/enums';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { useFeature } from '../../app/hooks/use-feature';

export default function BagReader({ contextLicensePlate, goBack, goForward }) {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const {
    enableGetDistributeInfoRest,
    enableSelectedDistributionCenterIdHeader,
    graphqlToRestMigrationUsers
  } = React.useContext(FeatureSwitchContext);
  const enableCaraCrachaInDistribute = useFeature(
    SWITCHES.enableCaraCrachaInDistribute
  );
  const enableDispatchFlowValidFromDriver = useFeature(
    SWITCHES.enableDispatchFlowValidFromDriver
  );
  const skipCaraCrachaByDcId = useFeature(SWITCHES.skipCaraCrachaByDcId);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [lastBarcode, setLastBarcode] = React.useState(null);
  const [lastItinerary, setLastItinerary] = React.useState(null);
  const [unexpectedPackages, setUnexpectedPackages] = React.useState([]);
  const [dirtyUnitLoadDialogOpen, setDirtyUnitLoadDialogOpen] = React.useState(
    false
  );
  const [noDriverDialogOpen, setNoDriverDialogOpen] = React.useState(false);
  const [
    caraCrachaCheckDialogOpen,
    setCaraCrachaCheckDialogOpen
  ] = React.useState(false);
  const [userName, setUserName] = React.useState('');
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const unexpectedErrorHandler = err => {
    Sentry.captureException(err);
    baseErrorHandler(err.message);
  };

  const showErrorSnackbar = message => {
    showSnackbar({
      variant: 'error',
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const isBagTypeFlecha = type => {
    return type === BAG_TYPE.BAG_TYPE_FLECHA;
  };

  const isBagTypeRedispatch = type => {
    return type === BAG_TYPE.BAG_TYPE_REDISPATCH;
  };

  const getBagType = (itinerary, redispatchUnitLoad) => {
    if (!itinerary && redispatchUnitLoad) {
      return BAG_TYPE.BAG_TYPE_REDISPATCH;
    }

    return BAG_TYPE.BAG_TYPE_FLECHA;
  };

  const getBagTypeFromDriver = (driver, itinerary, redispatchUnitLoad) => {
    const isRedispatch = !driver && !itinerary && redispatchUnitLoad;

    if (isRedispatch) {
      return BAG_TYPE.BAG_TYPE_REDISPATCH;
    }

    return BAG_TYPE.BAG_TYPE_FLECHA;
  };

  const extractBagInfoFromGraphQL = data => {
    const bag = data?.getLabelInfo?.bag;
    const seal = bag?.seal;
    const packages = bag?.packages || [];
    const numPackages = bag?.numPackages;

    const itinerary = bag?.route?.inquiry.finalized?.edges[0]?.node?.itinerary;
    const accepted = itinerary?.accepted;
    const driver = itinerary?.driver;
    const itineraryId = itinerary?.pk;

    return { seal, packages, numPackages, itineraryId, accepted, driver };
  };

  const getDriverInfo = (bagType, driver) => {
    return isBagTypeRedispatch(bagType) || !driver?.pk
      ? undefined
      : {
          pk: driver?.pk,
          photoUrl: {
            profileHighRes: driver?.photoUrl
          },
          fullName: driver?.name,
          cnh: {
            number: driver?.cnh
          },
          docRg: {
            number: driver?.rg
          },
          docCpf: {
            number: driver?.cpf
          }
        };
  };

  const extractBagInfoFromRest = data => {
    const seal = data?.bagSeal;
    const packages = data?.validPackages || [];
    const invalidPackages = data?.invalidPackages || [];
    const numPackages = packages.length + invalidPackages.length;

    const accepted = data?.itinerary?.accepted;
    // eslint-disable-next-line camelcase
    const itineraryId = data?.itinerary?.itinerary_id;
    const redispatchUnitLoad = data?.unitLoad;
    const type = enableDispatchFlowValidFromDriver
      ? getBagTypeFromDriver(data.driver, itineraryId, redispatchUnitLoad)
      : getBagType(itineraryId, redispatchUnitLoad);
    const driver = getDriverInfo(type, data?.driver);
    const skipDistributeStep = data?.skipDistributeStep;

    return {
      seal,
      packages,
      invalidPackages,
      numPackages,
      itineraryId,
      accepted,
      driver,
      redispatchUnitLoad,
      skipDistributeStep
    };
  };

  const extractBagInfo = data => {
    const info =
      enableGetDistributeInfoRest === 'true' ||
      graphqlToRestMigrationUsers.includes(authenticatedUser.email)
        ? extractBagInfoFromRest(data)
        : extractBagInfoFromGraphQL(data);

    const seal = info?.seal;
    const packages = info?.packages;
    const invalidPackages = info?.invalidPackages;
    const numPackages = info?.numPackages;
    const itineraryId = info?.itineraryId;
    const accepted = info?.accepted;
    const driver = info?.driver;
    const redispatchUnitLoad = info?.redispatchUnitLoad;
    const skipDistributeStep = info?.skipDistributeStep;

    return {
      bag: {
        seal,
        packages,
        invalidPackages,
        numPackages,
        skipDistributeStep
      },
      type: enableDispatchFlowValidFromDriver
        ? getBagTypeFromDriver(driver, itineraryId, redispatchUnitLoad)
        : getBagType(itineraryId, redispatchUnitLoad),
      itinerary: { pk: itineraryId, accepted, driver },
      redispatchUnitLoad
    };
  };

  const getBagInfoResponse = async barcode => {
    if (
      enableGetDistributeInfoRest === 'true' ||
      graphqlToRestMigrationUsers.includes(authenticatedUser.email)
    ) {
      const distributeInfoRequest = {
        seal: barcode
      };

      if (enableSelectedDistributionCenterIdHeader === 'true') {
        distributeInfoRequest.selectedDistributionCenterId =
          selectedDistributionCenter?.distributionCenterId;
      }

      const response = await getDistributeInfo(distributeInfoRequest).catch(
        err => {
          if (err.response && err.response.status === 404) {
            setNoDriverDialogOpen(true);
          } else {
            handleRestAPIError(err, baseErrorHandler);
          }
        }
      );
      return response?.data;
    }

    const identifyResponse = await identifyBarcode(barcode).catch(err =>
      handleRestAPIError(err, baseErrorHandler)
    );
    const data = await extractDataFromGraphQLResponse(identifyResponse).catch(
      err => baseErrorHandler(err.message)
    );

    return data;
  };

  const fetchBagInfo = async barcode => {
    const payloadBagInfo = await getBagInfoResponse(barcode);
    if (!payloadBagInfo) return null;

    return extractBagInfo(payloadBagInfo);
  };

  const checkBagInfo = async barcode => {
    const bagInfo = await fetchBagInfo(barcode);

    if (!bagInfo) return null;

    if (!bagInfo.bag.seal) {
      baseErrorHandler('Código de barras não encontrado');
      return null;
    }

    if (isBagTypeFlecha(bagInfo.type) && !bagInfo?.itinerary?.driver) {
      setNoDriverDialogOpen(true);
      return null;
    }

    return bagInfo;
  };

  const fetchCaraCrachaForDriver = async (
    driverId,
    itineraryId,
    licensePlate
  ) => {
    const caraCrachaResponse = await caraCrachaForDriver(
      driverId,
      itineraryId,
      licensePlate
    ).catch(err => handleRestAPIError(err, showErrorSnackbar));

    if (!caraCrachaResponse) return null;

    return {
      status: caraCrachaResponse.data.status,
      needOnboardingInstructions:
        caraCrachaResponse.data.needOnboardingInstructions
    };
  };

  const checkCaraCrachaRecord = async (driverPk, itineraryPk, licensePlate) => {
    if (skipCaraCrachaByDcId || itineraryPk == null) {
      return {
        status: CARA_CRACHA_FOR_DRIVER.CREATED
      };
    }

    const dataDriver = await fetchCaraCrachaForDriver(
      driverPk,
      itineraryPk,
      licensePlate
    );
    if (!dataDriver) return null;

    if (dataDriver.status !== CARA_CRACHA_FOR_DRIVER.CREATED) {
      if (!enableCaraCrachaInDistribute) {
        setCaraCrachaCheckDialogOpen(true);
        return null;
      }
    }
    return dataDriver;
  };

  const redirectToIdentify = async () => {
    goForward({
      pathname: '/identify'
    });
  };

  const redirectToRedispatchDistribute = state => {
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_BAG_BEEP);
    goForward({
      pathname: '/distribute/redispatch-package-reader',
      state
    });
  };

  const redirectToCaraCracha = state => {
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_BAG_BEEP);
    goForward({
      pathname: '/distribute/photo-upload',
      state
    });
  };

  const redirectToDistribute = state => {
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_BAG_BEEP);
    goForward({
      pathname: '/distribute/distribute-package-reader',
      state
    });
  };

  const handleUnexpectedPackages = (packages, _userName) => {
    setUserName(_userName);
    setUnexpectedPackages(packages);
    setDirtyUnitLoadDialogOpen(true);
  };

  const mountPackageReaderState = async barcode => {
    // Checks if bag exists and prepare payload
    const bagInfo = await checkBagInfo(barcode);
    let caraCrachaRecord;
    if (!bagInfo) return null;

    // Checks if the operator's UnitLoadData is empty or show warning
    const bagPackagesIds = (bagInfo.bag.packages || []).map(p => p.pk);
    const operatorUnitLoadData = await CheckUnitLoadService(
      bagPackagesIds,
      handleUnexpectedPackages,
      baseErrorHandler,
      unexpectedErrorHandler
    );
    if (!operatorUnitLoadData) return null;

    // Checks if CaraCracha record is available or show dialog
    if (isBagTypeFlecha(bagInfo.type)) {
      setLastItinerary(bagInfo.itinerary);
      caraCrachaRecord = await checkCaraCrachaRecord(
        bagInfo.itinerary.driver.pk,
        bagInfo.itinerary.pk,
        bagInfo.bag?.seal
      );
      if (!caraCrachaRecord) return null;
    }

    const checkedPackagesIds = operatorUnitLoadData.packages
      .map(p => p.pk)
      .filter(pk => bagPackagesIds.includes(pk));

    return {
      bag: bagInfo.bag,
      itinerary: bagInfo.itinerary,
      contextLicensePlate,
      checkedPackagesIds,
      destinationUnitLoadLpn:
        operatorUnitLoadData.destinationUnitLoadlicensePlate,
      userPk: operatorUnitLoadData.userPk,
      bagType: bagInfo.type,
      redispatchUnitLoad: bagInfo.redispatchUnitLoad,
      caraCrachaRecord
    };
  };

  const handleBagRead = async barcode => {
    setLastBarcode(barcode);
    setErrorMessage('');
    setLastItinerary(null);
    setLoading(true);

    if (loading) return;

    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_BAG_BEEP);

    const packageReaderState = await mountPackageReaderState(barcode);

    if (!packageReaderState) {
      playErrorBeep();
      setLoading(false);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.DISTRIBUTE_BAG_BEEP);
      return;
    }

    playSuccessBeep();
    setLoading(false);

    if (isBagTypeFlecha(packageReaderState.bagType)) {
      if (
        enableCaraCrachaInDistribute &&
        packageReaderState.caraCrachaRecord.status !==
          CARA_CRACHA_FOR_DRIVER.CREATED
      ) {
        redirectToCaraCracha(packageReaderState);
        return;
      }
      redirectToDistribute(packageReaderState);
      return;
    }

    redirectToRedispatchDistribute(packageReaderState);
  };

  const rerunForLastBarcode = async () => {
    await handleBagRead(lastBarcode);
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_CARA_CRACHA_BEEP
    );
  };

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_CONTEXT);
    trackStart(OPERATIONAL_PROCESS.DISTRIBUTE, ACTIVITY.BEEP_BAG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Container maxWidth="xs">
        <Box paddingTop={2.5}>
          <HeaderWithCancel
            testId="distribute-package-reader-cancel-button"
            handleClose={goBack}
          />
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Distribuir</Box>
            </Typography>
          </Box>
          <Box mt={1.5}>
            <Typography component="div" variant="h5">
              Bipe o lacre e comece a distribuir
            </Typography>
          </Box>
          <Box my={2.5} className="centered">
            <PackageReader
              onRead={handleBagRead}
              loading={loading}
              notes={`Saca identificada no XD App usando o contexto: ${contextLicensePlate} no Distribuir`}
              source="xd_app_distribute"
              placeholder="Leia um lacre de saca"
            />
          </Box>

          {errorMessage && (
            <Box mt={1.5} data-testid="alert-error">
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}

          {noDriverDialogOpen && (
            <NoDriverDialog
              open
              handleClose={() => {
                setNoDriverDialogOpen(false);
              }}
            />
          )}

          {dirtyUnitLoadDialogOpen && (
            <DirtyUnitLoadDialog
              userName={userName}
              packages={unexpectedPackages}
              handleClose={() => {
                setDirtyUnitLoadDialogOpen(false);
              }}
            />
          )}

          {caraCrachaCheckDialogOpen && (
            <CaraCrachaCheckDialog
              fetchCaraCrachaStatus={fetchCaraCrachaForDriver}
              itineraryId={lastItinerary.pk}
              driverId={lastItinerary.driver.pk}
              redirectToIdentify={redirectToIdentify}
              handleClose={() => {
                setCaraCrachaCheckDialogOpen(false);
                rerunForLastBarcode();
              }}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

BagReader.propTypes = {
  contextLicensePlate: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired
};
