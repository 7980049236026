import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Container,
  ListItem,
  ListItemText,
  Divider,
  List,
  Button
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import {
  HISTORY_ACTIONS,
  ROUTES,
  ACTIVITY,
  OPERATIONAL_PROCESS
} from '../../constants';
import DecorativeHeader from '../../app/components/decorative-header';
import pluralize from '../../app/utils/pluralize';
import { ReactComponent as IconReceiving } from '../../assets/images/icon_receiving.svg';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import { ReceivingProcessContext } from './receiving-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import SortingRecommendation from './sorting-recommendation';

const ReceivedPackages = () => {
  const { returnList, sortingContext } = useContext(ReceivingProcessContext);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const history = useHistory();

  const handleClose = () => {
    history.push({
      pathname: ROUTES.RECEIVE.CONTEXT_READER
    });
  };

  const handleMovePackagesBulk = () => {
    history.push({
      pathname: ROUTES.RECEIVE.MOVE_PACKAGES_BULK
    });
  };

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      history.replace(ROUTES.RECEIVE.CONTEXT_READER);
    }
  }, [history]);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVED_PACKAGES);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.RECEIVED_PACKAGES);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Container maxWidth="xs" data-testid="received-packages">
        <Box paddingTop={2.5}>
          <DecorativeHeader />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
        >
          <Box>
            <Typography variant="subtitle1">
              <Box>Você recebeu</Box>
              <Box
                textAlign="center"
                fontWeight="fontWeightBold"
                data-testid="packages-counter"
              >
                {`${returnList.length} ${pluralize({
                  singular: 'unidade',
                  count: returnList.length
                })}`}
              </Box>
            </Typography>
          </Box>
          <Box mt={4}>
            <IconReceiving style={{ width: 100 }} />
          </Box>
        </Box>
      </Container>
      <Container disableGutters maxWidth="xs">
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            pt={5}
            pb={8}
            style={{
              overflowY: 'auto'
            }}
          >
            <Divider />
            <List>
              {returnList.map(item => (
                <PackageItem
                  barcode={item.package}
                  sortingContext={sortingContext}
                  key={item.package}
                />
              ))}
            </List>
          </Box>
        </Box>
        <ActionContainerBottom enableSmokeBackground>
          <Box px={3} pb={1.5} maxWidth={pxToRem(444)}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={handleClose}
                  data-testid="close"
                >
                  Fechar
                </Button>
              </Box>
              <Box flex={1} ml={1}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleMovePackagesBulk}
                  data-testid="move-packages"
                >
                  Mover
                </Button>
              </Box>
            </Box>
          </Box>
        </ActionContainerBottom>
      </Container>
    </Box>
  );
};

const PackageItem = ({ barcode, sortingContext }) => {
  return (
    <ListItem divider data-testid={`pkg-${barcode}`}>
      <ListItemText
        primary={
          <Box pb={1} ml={1}>
            <Typography variant="body1">
              <Box component="span">#{barcode}</Box>
            </Typography>
          </Box>
        }
        secondary={
          <Box ml={1}>
            <Box color={colors.smoke[900]}>
              <SortingRecommendation
                sortingContext={sortingContext}
                barcode={barcode}
              />
            </Box>
          </Box>
        }
      />
    </ListItem>
  );
};

PackageItem.propTypes = {
  barcode: PropTypes.string.isRequired,
  sortingContext: PropTypes.string.isRequired
};

export default ReceivedPackages;
