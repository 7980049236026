const CONTINUE_BUTTON_ID = 'ctn-btn';

const RESEND_CODE_BUTTON_ID = 'resend-code-btn';

const VERIFICATION_CODE_INPUT_ID = 'verification-code-input';

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_REGEX_FOR_LOWERCASE_LETTER = '(.*[a-z].*)';
const PASSWORD_REGEX_FOR_UPPERCASE_LETTER = '(.*[A-Z].*)';
const PASSWORD_REGEX_FOR_NUMBER = '(.*\\d.*)';
const PASSWORD_REGEX_FOR_SPECIAL_CHARACTER = '[!@#$%^&*+=(),.?":{}|<>_]';

const LOADING_ID = 'loading-id';

const PASSWORD_RECOVERY_ERRORS = {
  DefaultException: {
    code: 'DefaultException',
    name: 'DefaultException',
    message: 'Não foi possível alterar sua senha.'
  },
  CodeMismatchException: {
    name: 'CodeMismatchException',
    code: 'CodeMismatchException',
    message: 'Ué, seu código estava errado. Digite outra vez.'
  },
  LimitExceededException: {
    name: 'LimitExceededException',
    code: 'LimitExceededException',
    message:
      'Opa, você tentou inserir um código errado muitas vezes. Tente outra vez daqui a pouco.'
  },
  ExpiredCodeException: {
    name: 'ExpiredCodeException',
    code: 'ExpiredCodeException',
    message:
      'Opa! Seu código está errado ou expirou. Clique em “enviar um novo código” e tente outra vez.'
  }
};

export {
  LOADING_ID,
  PASSWORD_RECOVERY_ERRORS,
  VERIFICATION_CODE_INPUT_ID,
  RESEND_CODE_BUTTON_ID,
  CONTINUE_BUTTON_ID,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX_FOR_LOWERCASE_LETTER,
  PASSWORD_REGEX_FOR_UPPERCASE_LETTER,
  PASSWORD_REGEX_FOR_NUMBER,
  PASSWORD_REGEX_FOR_SPECIAL_CHARACTER
};
