import * as Sentry from '@sentry/browser';
import {
  getUnitLoad,
  movePackage,
  sortUnitLoad,
  getUnitLoadMetrics
} from '../../api-rest';
import { UNIT_LOAD_TYPE, UNIT_TYPE } from '../../app/enums';
import { APP_NAME, RESPONSE_STATUS } from '../../constants';
import { packageCheck } from '../../api';
import handleRestAPIError from '../../app/utils/rest-api-request';
import IdentifierIsNotUnit from './exceptions';

export const MESSAGES = {
  PACKAGE_CHECK: 'Bipe na tela de organizar pacotes',
  NOT_FOUND_IDENTIFIER:
    'Eita, você bipou um código de barras que não existe. ' +
    'Verifique se o código está correto e tente de novo.',
  ERROR_GET_METRICS:
    'Eita, erro ao tentar buscar as métricas da posição, as informações podem estar incompletas.'
};

export const extractPkgInfo = packageInfo => ({
  id: packageInfo?.package?.id,
  identifier: packageInfo?.pkgInfo?.barcode,
  loggiKey: packageInfo?.package?.loggiKey,
  // eslint-disable-next-line camelcase
  trackingKey: packageInfo?.package?.tracking_key,
  recipient: { name: packageInfo?.recipient?.name },
  company: {
    id: packageInfo?.company?.id,
    name: packageInfo?.company?.name,
    sharedName: packageInfo?.company?.sharedName
  },
  item: packageInfo.package?.item,
  totalItems: packageInfo.package?.totalItems,
  status: packageInfo?.pkgStatus
});

export const extractChildrenUnitLoad = childrenUnitLoad => {
  const childrenUnitLoadResponse = [];

  if (childrenUnitLoad) {
    childrenUnitLoad.forEach(child => {
      if (child.type === UNIT_LOAD_TYPE.UNIT_LOAD_TYPE_PACKAGE) {
        const pkgUnit = {
          package: { id: child.id },
          pkgInfo: {
            barcode: child.licensePlate
          },
          recipient: child?.recipient,
          company: child?.company
        };
        const pkg = extractPkgInfo(pkgUnit);

        const childUnitLoad = {
          type: UNIT_LOAD_TYPE.UNIT_LOAD_TYPE_PACKAGE,
          childUnitLoad: pkg
        };
        childrenUnitLoadResponse.push(childUnitLoad);
      }
    });
  }

  return childrenUnitLoadResponse;
};

/**
 * This function is responsible for returning a unit, with
 * the given info.
 *
 *
 * @param {string} type - The UNIT_TYPE enum
 * @param {Object} [pkg] - The pkg, when the unit is UNIT_TYPE_PACKAGE
 * @param {Object} [decision] - The decision, when the unit is UNIT_TYPE_PACKAGE
 * @param {Object} [recommendationDecision] - The decision of the recommendation context,
 * when the unit is UNIT_TYPE_PACKAGE
 * @param {Object} [unitLoad] - The unitLoad, when the unit is UNIT_TYPE_UNIT_LOAD
 * @param {Object} [childrenUnitLoad] - The unitLoads stored inside the unitLoad,
 * when the unit is UNIT_TYPE_UNIT_LOAD
 * @param {Object} [unitLoadsOutlier] - The unit loads that are outliers (very distant
 * from the major group of packages in a geographic manner)
 * @param {string} [warningMsg] - Some warning to be show to the user
 * @returns {Object}
 */
const buildUnit = ({
  type,
  pkg,
  decision,
  unitLoad,
  childrenUnitLoad,
  recommendationDecision,
  unitLoadsOutlier,
  warningMsg
}) => {
  return {
    type,
    info: {
      pkg,
      decision,
      recommendationDecision,
      unitLoad,
      childrenUnitLoad,
      unitLoadsOutlier
    },
    warningMsg
  };
};

const getSortUnitLoadInfo = async ({ sortingContextLpn, pkg }) => {
  const response = await sortUnitLoad(sortingContextLpn, pkg.identifier);
  return response.data;
};

const getUnitLoadInfo = async identifier => {
  const response = await getUnitLoad(identifier);
  if (response.data.status === RESPONSE_STATUS.ERROR) {
    throw new IdentifierIsNotUnit(MESSAGES.NOT_FOUND_IDENTIFIER, identifier);
  }
  return response.data;
};

const getUnitLoadMetricsInfo = async identifier => {
  const response = await getUnitLoadMetrics(identifier).catch(err =>
    Sentry.captureException(err)
  );

  return response?.data;
};

const executeMovePackage = async ({
  sortingContext,
  distributionCenterId,
  pkg,
  organizeUnitInfo
}) => {
  await movePackage(
    organizeUnitInfo?.unitLoad?.licensePlate,
    pkg.identifier,
    organizeUnitInfo?.unitLoad?.licensePlate,
    sortingContext.licensePlate,
    {
      distributionCenterId
    }
  );
};

const packageHandler = async ({ sortingContext, pkg, geo }) => {
  const sortUnitLoadPromises = [];

  const sortUnitLoadResponsePromise = getSortUnitLoadInfo({
    sortingContextLpn: sortingContext.licensePlate,
    pkg
  });
  sortUnitLoadPromises.push(sortUnitLoadResponsePromise);

  if (sortingContext.recommendationLicensePlate) {
    const sortUnitLoadRecommendationResponsePromise = getSortUnitLoadInfo({
      sortingContextLpn: sortingContext.recommendationLicensePlate,
      pkg
    });

    sortUnitLoadPromises.push(sortUnitLoadRecommendationResponsePromise);
  }

  const [
    sortUnitLoadResponse,
    sortUnitLoadRecommendationResponse
  ] = await Promise.all(sortUnitLoadPromises);

  packageCheck(geo, pkg.identifier, MESSAGES.PACKAGE_CHECK, APP_NAME).catch(
    err => handleRestAPIError(err, () => {})
  );

  return buildUnit({
    type: UNIT_TYPE.UNIT_TYPE_PACKAGE,
    pkg,
    decision: sortUnitLoadResponse.decision,
    recommendationDecision: sortUnitLoadRecommendationResponse?.decision
  });
};

const packageOnUnitLoadHandler = async ({
  sortingContext,
  pkg,
  distributionCenterId,
  organizeUnitInfo
}) => {
  await executeMovePackage({
    sortingContext,
    distributionCenterId,
    pkg,
    organizeUnitInfo
  });

  return buildUnit({
    type: UNIT_TYPE.UNIT_TYPE_PACKAGE,
    pkg
  });
};

const unitLoadHandler = async ({ identifier }) => {
  let warningMsg = '';
  const unitLoadPromises = [];

  const unitLoadInfoPromise = await getUnitLoadInfo(identifier);
  unitLoadPromises.push(unitLoadInfoPromise);

  const unitLoadMetricsPromise = getUnitLoadMetricsInfo(identifier);
  unitLoadPromises.push(unitLoadMetricsPromise);

  const [unitLoadInfoResponse, unitLoadMetricsResponse] = await Promise.all(
    unitLoadPromises
  );

  if (!unitLoadMetricsResponse?.status) {
    warningMsg = MESSAGES.ERROR_GET_METRICS;
  }

  const childrenUnitLoad = extractChildrenUnitLoad(
    unitLoadInfoResponse?.childrenUnitLoad
  );

  const unitLoadsOutlier = extractChildrenUnitLoad(
    unitLoadMetricsResponse?.unitLoadsOutlier
  );

  const density = unitLoadMetricsResponse?.density;

  const unitLoad = {
    name: unitLoadInfoResponse?.name,
    licensePlate: unitLoadInfoResponse?.licensePlate,
    density,
    transhipmentRoutingCodeDestination:
      unitLoadInfoResponse?.transhipmentRoutingCodeDestination,
    createdAt: unitLoadInfoResponse?.createdAtTime
  };

  return buildUnit({
    type: UNIT_TYPE.UNIT_TYPE_UNIT_LOAD,
    unitLoad,
    childrenUnitLoad,
    unitLoadsOutlier,
    warningMsg
  });
};

/**
 * This function is responsible for returning the unitInfo of the given unit type.
 * For every unit addition, a new handler must be implemented,
 * the default being the UNIT_TYPE_PACKAGE.
 *
 *
 * @param {string} type - The UNIT_TYPE enum
 * @param {object} sortingContext - The current sorting context
 * @param {string} [organizeUnitType] - Type of the organize unit been processed, if already initiated
 * @param {object} [organizeUnitInfo] - Info of the organize unit been processed, if already initiated
 * @param {object} [extra] - Options relative to specific units
 * @returns {Label}
 */
export const getUnit = async ({
  type,
  sortingContext,
  organizeUnitType,
  organizeUnitInfo,
  extra: { pkg, geo, distributionCenterId, identifier }
}) => {
  switch (type) {
    case UNIT_TYPE.UNIT_TYPE_PACKAGE:
      if (organizeUnitType === UNIT_TYPE.UNIT_TYPE_UNIT_LOAD) {
        return packageOnUnitLoadHandler({
          sortingContext,
          pkg,
          distributionCenterId,
          organizeUnitInfo
        });
      }

      return packageHandler({ sortingContext, pkg, geo });
    case UNIT_TYPE.UNIT_TYPE_UNIT_LOAD:
      return unitLoadHandler({
        identifier
      });
    default:
      return packageHandler({ sortingContext, pkg, geo });
  }
};
