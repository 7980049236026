/**
 * Singleton class to handle Company endpoint data
 * in Loggi Envios.
 */
const Company = (() => {
  let instance;

  function CompanySingleton() {
    if (instance) {
      return instance;
    }

    instance = this;

    /**
     * CompanyData initializes empty
     * This attribute will be accountable for receiving
     * all data from Company endpoint
     */
    this.companyData = {};

    /**
     * Returns data from Company endpoint
     * @returns companyData object
     */
    this.getCompanyData = () => this.companyData;

    /**
     * Returns status from companyData object
     * @returns status attribute
     */
    this.getCompanyStatus = () => this.companyData?.status;

    /**
     * Returns integrator object from companyData object
     * @returns integrator object attribute
     */
    this.getCompanyIntegrators = () => this.companyData?.integrators ?? {};

    /**
     * Sets data for companyData object
     */
    this.setCompanyData = companyData => {
      this.companyData = companyData;
    };

    /**
     * Check if companyData object is empty
     * @returns boolean
     */
    this.isCompanyDataEmpty = () => {
      return Object.keys(this.companyData).length === 0 ?? false;
    };
  }

  return {
    getInstance: () => instance || new CompanySingleton()
  };
})();

export default Company;
