import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';

import { Box, Typography, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { changeLabel } from '../api';
import { playErrorBeep, playSuccessBeep } from '../sounds';
import { BarcodeReader } from '../app/components/barcode-readers';
import { getInfoFromPackageIdentifier } from '../api-rest';
import {
  HTTP_STATUS_CODES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  SWITCHES,
  ERRORS
} from '../constants';
import { useDistributionCenter } from '../app/access-control/distribution-center-provider';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';
import DisambiguatePackageDialog from '../app/components/disambiguate-package-dialog';
import { extractPkgInfo } from './organize/unit-handlers';
import { useFeature } from '../app/hooks/use-feature';
import UnitIsNotPackage from './organize/exceptions';
import handleRestAPIError from '../app/utils/rest-api-request';

export const ITEM_TYPE = {
  PACKAGE: 'package',
  BAG: 'bag'
};

export default function ChangeLabel() {
  const [oldBarcode, setOldBarcode] = React.useState('');
  const [oldPackageId, setOldPackageId] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [infoMessage, setInfoMessage] = React.useState('');
  const [packagesToDisambiguate, setPackagesToDisambiguate] = React.useState(
    []
  );
  const [loading, setLoading] = React.useState(false);

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const enableDisambiguateChangeLabel = useFeature(
    SWITCHES.enableDisambiguateChangeLabel
  );

  const resetOldBarcode = () => {
    setOldBarcode('');
    setInfoMessage('');
    setOldPackageId('');
    setLoading(false);
  };

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const handleReadOld = async barcode => {
    setLoading(true);

    if (!oldBarcode) {
      setOldBarcode(barcode);
      setLoading(false);
      setInfoMessage(`Etiqueta ${barcode} selecionada`);
      return;
    }

    const response = await changeLabel({
      oldBarcode,
      newBarcode: barcode,
      distributionCenter: selectedDistributionCenter.distributionCenterId
    }).catch(err => {
      setErrorMessage(err.message);
      playErrorBeep();
      Sentry.captureException(err);
      resetOldBarcode();
    });

    if (!response) {
      playErrorBeep();
      resetOldBarcode();
      return;
    }

    const responsePayload = await response.json();
    if (!responsePayload) {
      playErrorBeep();
      resetOldBarcode();
      return;
    }
    const { changeLabel: changeLabelResponse } = responsePayload.data;

    if (!changeLabelResponse) {
      const errMessage = (
        responsePayload.errors.map(err => err.message) || []
      ).join('\n');
      setErrorMessage(errMessage);
      playErrorBeep();
      resetOldBarcode();
      return;
    }

    if (!changeLabelResponse.success) {
      const errMessage = (
        changeLabelResponse.errors.map(err => err.message) || []
      ).join('\n');
      setErrorMessage(errMessage);
      playErrorBeep();
      resetOldBarcode();
      return;
    }

    setSuccessMessage(changeLabelResponse.description);
    playSuccessBeep();
    resetOldBarcode();
  };

  const handleError = (errMsg = '') => {
    setErrorMessage(errMsg);
    playErrorBeep();
    resetOldBarcode();
  };

  const hasBeepedOldBarcode = () => !!oldBarcode;

  const handleChange = async () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleLabelChange = async ({ newBarcode, itemType, itemId }) => {
    const response = await changeLabel({
      oldBarcode,
      newBarcode,
      itemId,
      itemType,
      distributionCenter: selectedDistributionCenter.distributionCenterId
    }).catch(err => {
      handleError(err.message);
      Sentry.captureException(err);
    });

    if (!response) {
      playErrorBeep();
      resetOldBarcode();
      return;
    }

    const responsePayload = await response.json();

    if (responsePayload?.errors) {
      const errMessage = (
        responsePayload.errors.map(err => err.message) || []
      ).join('\n');
      handleError(errMessage);
      return;
    }

    if (responsePayload?.data?.changeLabel?.errors) {
      const errMessage = (
        responsePayload.data.changeLabel.errors.map(err => err.message) || []
      ).join('\n');
      handleError(errMessage);
      return;
    }

    if (!responsePayload?.data?.changeLabel) {
      handleError(ERRORS.SOMETHING_WRONG_TRY_AGAIN);
      playErrorBeep();
      resetOldBarcode();
      return;
    }

    const { changeLabel: changeLabelResponse } = responsePayload.data;

    setSuccessMessage(changeLabelResponse.description);
    playSuccessBeep();
    resetOldBarcode();
  };

  const handleBag = identifier => {
    setOldBarcode(identifier);
    setInfoMessage(`Etiqueta ${identifier} selecionada`);
  };

  const handleMultiplePackages = pkgIdentifierInfo => {
    const packages = pkgIdentifierInfo.map(pkg => extractPkgInfo(pkg));
    setPackagesToDisambiguate(packages);
  };

  const handleSinglePackage = pkgIdentifierInfo => {
    setOldBarcode(pkgIdentifierInfo.identifier);
    setOldPackageId(pkgIdentifierInfo.id);
    setInfoMessage(`Etiqueta ${pkgIdentifierInfo.identifier} selecionada`);
  };

  const getPackageIdentifierInfo = async identifier => {
    try {
      const response = await getInfoFromPackageIdentifier({
        packageIdentifier: identifier
      });
      return response.data;
    } catch (err) {
      if (err?.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        throw new UnitIsNotPackage(err.message, identifier);
      }

      throw err;
    }
  };

  const handlePackageInfo = pkgIdentifierInfo => {
    if (pkgIdentifierInfo.info.length > 1) {
      handleMultiplePackages(pkgIdentifierInfo.info);
    } else {
      const pkg = extractPkgInfo(pkgIdentifierInfo.info[0]);
      handleSinglePackage(pkg);
    }
  };

  const handleOldBarcode = async barcode => {
    setLoading(true);

    try {
      const pkgIdentifierInfo = await getPackageIdentifierInfo(barcode);

      handlePackageInfo(pkgIdentifierInfo);
    } catch (err) {
      if (err instanceof UnitIsNotPackage) {
        handleBag(err.identifier);

        setLoading(false);
        return;
      }

      handleRestAPIError(err, errorMsg => handleError(errorMsg));
    }

    setLoading(false);
  };

  const handleNewBarcode = async newBarcode => {
    setLoading(true);

    const itemData = {
      newBarcode
    };

    if (oldPackageId) {
      itemData.itemType = ITEM_TYPE.PACKAGE;
      itemData.itemId = oldPackageId;
    }

    await handleLabelChange(itemData);

    setLoading(false);
  };

  const handleRead = async barcode => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.CHANGE_LABEL_OLD_BEEP
    );
    if (!enableDisambiguateChangeLabel) {
      await handleReadOld(barcode);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.CHANGE_LABEL_OLD_BEEP
      );
      return;
    }

    if (!hasBeepedOldBarcode()) {
      await handleOldBarcode(barcode);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.CHANGE_LABEL_OLD_BEEP
      );
      return;
    }

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.CHANGE_LABEL_NEW_BEEP
    );
    await handleNewBarcode(barcode);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.CHANGE_LABEL_NEW_BEEP);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
  };

  const handleSelectPackage = pkgIdentifierInfo => {
    setLoading(true);
    setPackagesToDisambiguate([]);

    handleSinglePackage(pkgIdentifierInfo);

    setLoading(false);
  };

  const handleDialogCancel = () => {
    setPackagesToDisambiguate([]);
  };

  return (
    <>
      <Container maxWidth="xs">
        <Typography component="div" variant="body1" gutterBottom>
          <Box fontWeight="fontWeightBold">Trocar etiqueta</Box>
        </Typography>
        <Box my={1.5} mt={2.5}>
          <BarcodeReader
            placeholder={
              oldBarcode
                ? 'Leia a nova etiqueta'
                : 'Leia a etiqueta a ser trocada'
            }
            onRead={handleRead}
            onChange={handleChange}
            loading={loading}
          />
          <Container maxWidth="xs">
            {errorMessage && (
              <Box my={1.5}>
                <Alert data-testid="error" severity="error">
                  {errorMessage}
                </Alert>
              </Box>
            )}
            {infoMessage && (
              <Box my={1.5}>
                <Alert data-testid="info" severity="info">
                  {infoMessage}
                </Alert>
              </Box>
            )}
            {successMessage && (
              <Box my={1.5}>
                <Alert data-testid="success" severity="success">
                  {successMessage}
                </Alert>
              </Box>
            )}
          </Container>
        </Box>
      </Container>
      {packagesToDisambiguate.length > 0 && (
        <DisambiguatePackageDialog
          open
          onCancel={handleDialogCancel}
          onSelectPackage={handleSelectPackage}
          packages={packagesToDisambiguate}
        />
      )}
    </>
  );
}
