import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import parseDateWithTimezone from '../../app/utils/parse-date-with-timezone';
import SharedPropTypes from '../../app/shared-prop-types';
import { useFeature } from '../../app/hooks/use-feature';
import {
  SWITCHES,
  PACKAGE_DIRECTION_OPTIONS,
  CONSOLIDATION_TYPE_OPTIONS,
  ROUTES,
  COGNITO_DISTRIBUTION_CENTER
} from '../../constants';
import pluralize from '../../app/utils/pluralize';
import { IdentifyEditButton } from '../../app/components/identify-edit-select';
import validDamagedPackage from './identify-package-service';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import {
  DAMAGED_PACKAGE_REASON_JIRA,
  DAMAGED_PACKAGE_ERROR
} from './constants';
import { playErrorBeep } from '../../sounds';
import IdentifyDamagedPackageJiraMenu from './identify-damaged-package-jira-menu';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import getUserType from '../../app/access-control/access-control-service';

moment.updateLocale('pt-BR');

const DamagePackageDeclararion = ({
  pack,
  handleRedirectToDeclareDamageV2
}) => {
  const enableDeclarePackageDamage = useFeature(
    SWITCHES.enableDeclarePackageDamage
  );

  const enableDeclarePackageDamageV2ByDc = useFeature(
    SWITCHES.enableDeclarePackageDamageV2ByDc
  );
  const enableDeclarePackageDamageV2AllDcsNoLeve = useFeature(
    SWITCHES.enableDeclarePackageDamageV2AllDcsNoLeve
  );

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const isDeclareDamageFeatureEnabled =
    enableDeclarePackageDamage &&
    (enableDeclarePackageDamageV2ByDc ||
      (enableDeclarePackageDamageV2AllDcsNoLeve && !isUserLeve));

  return (
    pack &&
    isDeclareDamageFeatureEnabled && (
      <Box position="sticky" bottom={0} my={2}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={handleRedirectToDeclareDamageV2}
        >
          Está danificado
        </Button>
      </Box>
    )
  );
};

DamagePackageDeclararion.propTypes = {
  pack: SharedPropTypes.package.isRequired,
  handleRedirectToDeclareDamageV2: PropTypes.func.isRequired
};

export default function IdentifyPackage({ pack, validDamagedPackageService }) {
  const showProcessDeadlineDate = useFeature(SWITCHES.showProcessDeadlineDate);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const enableConsolidationTypeOnIdentify = useFeature(
    SWITCHES.enableConsolidationTypeOnIdentify
  );
  const [openDamagedPackageJiraMenu, setOpenDamagedPackageJiraMenu] = useState(
    false
  );

  const currectDirection = pack?.direction
    ? PACKAGE_DIRECTION_OPTIONS.find(op => {
        /* eslint-disable-next-line eqeqeq */
        return op.value === pack.direction;
      })
    : null;

  const isReturnDirection =
    pack?.direction === PACKAGE_DIRECTION_OPTIONS[2].value;

  const consolidationType = pack?.returnInfo?.consolidationType
    ? CONSOLIDATION_TYPE_OPTIONS.find(type => {
        return type.value === pack.returnInfo.consolidationType;
      })
    : null;

  const showConsolidationType =
    enableConsolidationTypeOnIdentify && isReturnDirection && consolidationType;

  const errorHandler = React.useCallback(
    errMessage => {
      showSnackbar({
        message: errMessage,
        variant: 'error',
        showCloseButton: true,
        enqueueSnackbar
      });
      playErrorBeep();
    },
    [enqueueSnackbar]
  );

  const redirectToDeclareDamageV2 = () =>
    history.push(ROUTES.DAMAGED_PACKAGE.MATCH_IMAGE, { pack });

  const handleRedirectToDeclareDamageV2 = async () => {
    const response = await validDamagedPackageService({
      packageId: pack.pk,
      errorHandler
    });
    if (response.valid) {
      redirectToDeclareDamageV2();
    } else if (response.reason === DAMAGED_PACKAGE_REASON_JIRA) {
      setOpenDamagedPackageJiraMenu(true);
    } else {
      errorHandler(`${DAMAGED_PACKAGE_ERROR[response.reason]}`);
    }
  };

  return (
    <Box>
      <Box p={1}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="row" mb={2}>
              <Box>
                <Typography variant="h6">
                  <Box fontWeight="fontWeightBold">
                    {pack.statusCodeDisplay}
                  </Box>
                </Typography>
              </Box>
              <Box ml={3} mt={0.5}>
                <Box mr={2}>
                  <IdentifyEditButton identifiedPackage={pack} />
                </Box>
              </Box>
            </Box>
            {showProcessDeadlineDate ? (
              <Box>
                <Typography variant="h6">
                  Entregar até{' '}
                  {moment(
                    parseDateWithTimezone({ date: pack.currentPromisedDate })
                  ).format('DD [de] MMM')}
                </Typography>
                <Typography variant="caption">
                  Expedir do XD até{' '}
                  {moment(
                    parseDateWithTimezone({
                      date: pack.startProcessingDeadlineDate
                    })
                  ).format('DD [de] MMM')}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="h6">
                  Entregar até{' '}
                  {moment(
                    parseDateWithTimezone({
                      date: pack.currentPromisedDate
                    })
                  ).format('DD MMM')}
                </Typography>
              </Box>
            )}
            {pack?.annotations?.slo?.confirmationHubRoutingCode && (
              <Typography display="block" variant="caption">
                Entrada na malha:{' '}
                {pack.annotations.slo.confirmationHubRoutingCode}
              </Typography>
            )}
            <Box>
              {currectDirection && (
                <Typography variant="caption">
                  Direção do pacote: {currectDirection.shortName}
                </Typography>
              )}
            </Box>
            <Box>
              {showConsolidationType && (
                <Typography variant="caption">
                  Tipo de devolução: {consolidationType.shortName}
                </Typography>
              )}
            </Box>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="body1">
              {pack.company.sharedName} #{pack.identifier}
            </Typography>
            {pack.intDeliveriesAttempts > 0 && (
              <Box my={1}>
                {`${pack.intDeliveriesAttempts} ${pluralize({
                  singular: 'Tentativa',
                  count: pack.intDeliveriesAttempts
                })} de entrega`}
              </Box>
            )}
            {pack.deliveryStatusHistory?.length > 0 && (
              <Box position="relative" my={1}>
                {pack.deliveryStatusHistory
                  .sort((a, b) => b.attempt - a.attempt)
                  .map(item => (
                    <Box
                      key={item.attempt}
                      display="flex"
                      alignItems="center"
                      mb={2}
                      position="relative"
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: '10px',
                          top: '0',
                          bottom: '0',
                          borderLeft: '2px dashed gray',
                          zIndex: 1
                        }}
                      />
                      <Box
                        style={{
                          width: 23,
                          height: 23,
                          borderRadius: '50%',
                          backgroundColor: 'gray',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '8px',
                          zIndex: 2,
                          position: 'relative'
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            color: 'white',
                            lineHeight: '1',
                            fontSize: '13px'
                          }}
                        >
                          {item.attempt}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          Tentativa {item.attempt}:
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: 'bold' }}
                        >
                          {item.codeDisplay}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
            <Typography variant="caption">
              {pack.recipient?.name || ''} - {pack.recipient?.phone || ''}
            </Typography>
            <br />
            <Typography variant="caption">
              {pack.destination.addressFormatted}
            </Typography>
            <br />
            <Typography variant="caption">
              {pack.width}x{pack.height}x{pack.length}cm
              {pack.weight && ` | ${pack.weight}g`}
            </Typography>
            <br />
            <Typography variant="caption">
              Loggi Key: {pack.loggiKey}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      {pack?.issues != null && pack?.issues.length > 0 && (
        <Box p={1}>
          <Card>
            <CardContent>
              <Typography variant="h5">Problemas</Typography>
              <br />
              <ul>
                {pack.issues.map(issue => {
                  return (
                    <li key={issue}>
                      <Typography variant="caption">{issue}</Typography>
                    </li>
                  );
                })}
              </ul>
              <br />
            </CardContent>
          </Card>
        </Box>
      )}
      <DamagePackageDeclararion
        pack={pack}
        handleRedirectToDeclareDamageV2={handleRedirectToDeclareDamageV2}
      />

      <IdentifyDamagedPackageJiraMenu
        packageId={pack.pk}
        handleContinue={redirectToDeclareDamageV2}
        setOpen={setOpenDamagedPackageJiraMenu}
        open={openDamagedPackageJiraMenu}
      />
    </Box>
  );
}

IdentifyPackage.propTypes = {
  pack: SharedPropTypes.package.isRequired,
  validDamagedPackageService: PropTypes.func
};

IdentifyPackage.defaultProps = {
  // It becomes easier to test/mock by injecting the service.
  validDamagedPackageService: validDamagedPackage
};
