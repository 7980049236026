import { captureException } from '@sentry/browser';
import {
  matchImageWithPackage,
  declareDamagedPackageV2,
  declareDamagedPackageV3,
  generateDamagedPackagePresignedUrlsForUpload,
  uploadFileToS3
} from '../../../api-rest';
import handleRestAPIError from '../../../app/utils/rest-api-request';
import { NOT_DAMAGED, PHOTO_TYPE } from './constants';
import { HTTP_STATUS_CODES } from '../../../constants';
import convertBase64ToFile from '../../../app/utils/convert-image';

export const matchImage = async ({ packageId, image, errorHandler }) => {
  const response = await matchImageWithPackage(packageId, image).catch(err =>
    handleRestAPIError(err, errorHandler)
  );

  if (!response) return null;

  return {
    success: Boolean(response.data.success),
    match: response.data.match
  };
};

export async function generatePresignedUrls({
  packageId,
  distributionCenterId,
  packageQuantity,
  labelQuantity,
  errorHandler
}) {
  const response = await generateDamagedPackagePresignedUrlsForUpload(
    packageId,
    distributionCenterId,
    packageQuantity,
    labelQuantity
  ).catch(err => handleRestAPIError(err, errorHandler));

  if (!response?.data?.presignedUrls) {
    return null;
  }

  const {
    data: { presignedUrls }
  } = response;

  const labelType = presignedUrls.filter(
    presignedUrl => presignedUrl.photoType === PHOTO_TYPE.label
  );
  const packageType = presignedUrls.filter(
    presignedUrl => presignedUrl.photoType === PHOTO_TYPE.package
  );

  // API returned the wrong quantity of one of the presignedUrls types
  if (
    labelType.length !== labelQuantity ||
    packageType.length !== packageQuantity
  ) {
    return null;
  }

  return presignedUrls;
}

async function uploadImageToS3({ url, image, method }) {
  const response = await uploadFileToS3({
    url,
    file: image,
    method,
    contentType: 'image/jpeg'
  }).catch(error => {
    captureException(error);
  });

  if (
    response &&
    (response.status === HTTP_STATUS_CODES.OK ||
      response.status === HTTP_STATUS_CODES.NO_CONTENT)
  ) {
    return { success: true };
  }

  return { success: false };
}

export async function uploadImagesToS3({
  presignedUrls,
  imagesPackage,
  imageLabel
}) {
  const packagePresignedUrlsWithImages = presignedUrls
    .filter(presignedUrl => presignedUrl.photoType === PHOTO_TYPE.package)
    .map((presignedUrl, i) => ({
      ...presignedUrl,
      image: imagesPackage[i].src
    }));

  const labelPresignedUrlsWithImages = presignedUrls
    .filter(presignedUrl => presignedUrl.photoType === PHOTO_TYPE.label)
    .map(presignedUrl => ({
      ...presignedUrl,
      image: imageLabel
    }));

  const presignedUrlsWithImages = [
    ...packagePresignedUrlsWithImages,
    ...labelPresignedUrlsWithImages
  ];

  const requestPromises = presignedUrlsWithImages.map(
    (presignedUrlWithImage, index) => {
      return uploadImageToS3({
        url: presignedUrlWithImage.url,
        image: convertBase64ToFile(
          presignedUrlWithImage.image,
          `image-${index}.jpeg`
        ),
        method: presignedUrlWithImage.method
      }).then(
        data =>
          data?.success && {
            key: presignedUrlWithImage.key,
            photo_type: presignedUrlWithImage.photoType
          }
      );
    }
  );

  const listOfKeyAndPhotoType = await Promise.all(requestPromises);
  if (listOfKeyAndPhotoType.some(response => !response)) {
    return null;
  }

  return listOfKeyAndPhotoType;
}

export async function declareDamagedPackage({
  packageId,
  distributionCenterId,
  imageLabel,
  imagesPackage,
  physicalStatePackage,
  physicalStateProduct,
  isImageLabelIdentified,
  productLostStatusType,
  packagingQualityStatusType,
  damageLocation,
  errorHandler,
  enableDeclarePackageDamageWithNewQuestion
}) {
  const presignedUrls = await generatePresignedUrls({
    packageId,
    distributionCenterId,
    packageQuantity: imagesPackage.length,
    labelQuantity: imageLabel ? 1 : 0,
    errorHandler
  });

  if (!presignedUrls) {
    return { success: false };
  }

  const images = await uploadImagesToS3({
    presignedUrls,
    imagesPackage,
    imageLabel
  });

  if (!images) {
    return { success: false };
  }

  const physicalPackageNotDamaged = physicalStatePackage.filter(
    value => value !== NOT_DAMAGED
  );

  const physicalState = {
    package: physicalPackageNotDamaged,
    product: physicalStateProduct
  };

  let response;
  if (!enableDeclarePackageDamageWithNewQuestion) {
    response = await declareDamagedPackageV2(
      packageId,
      distributionCenterId,
      images,
      physicalState,
      isImageLabelIdentified,
      productLostStatusType,
      damageLocation
    ).catch(err => handleRestAPIError(err, errorHandler));
  } else {
    response = await declareDamagedPackageV3({
      packageId,
      distributionCenterId,
      images,
      physicalState,
      isImageLabelIdentified,
      productLostStatusType,
      packagingQualityStatusType,
      damageLocation
    }).catch(err => handleRestAPIError(err, errorHandler));
  }

  if (!response || !response.data || response.status !== HTTP_STATUS_CODES.OK) {
    return { success: false };
  }

  return {
    success: true,
    damagedPackageId: response.data.damagedPackageId,
    damagedPackageDeclarationResult:
      response.data.damagedPackageDeclarationResult
  };
}
