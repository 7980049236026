export default class IdentifierIsNotUnit extends Error {
  constructor(message, identifier) {
    super(message);
    this._identifier = identifier;
  }

  get identifier() {
    return this._identifier;
  }
}
