const numberFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const moneyFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'BRL'
});

const moneyFormatterWithoutStyle = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: 'BRL'
});

export const brazilianNumberTextFormatter = number => {
  return numberFormatter.format(number);
};

/**
 * Formats the weight to a brazilian format text in the proper unit
 *
 * @param {Number} weight the weight in Kg
 * @returns a text formatted to kg in brazilian format
 */
export const fromKgToBrazilianText = weight => {
  return `${brazilianNumberTextFormatter(weight)} kg`;
};

/**
 * Value returned example R$ 12.000,50
 */
export const formatBRL = moneyObject => {
  if ((moneyObject?.units || moneyObject?.units === 0) && moneyObject.nanos) {
    const stringWithDecimals = `${moneyObject.units}.${moneyObject.nanos}`;

    return moneyFormatter.format(stringWithDecimals);
  }
  if (moneyObject?.units && !moneyObject.nanos) {
    return moneyFormatter.format(moneyObject.units);
  }

  return moneyFormatter.format(0);
};

/**
 * Value returned example 12000.50
 */
export const formatMoneyToNumber = moneyObject => {
  if ((moneyObject?.units || moneyObject?.units === 0) && moneyObject.nanos) {
    const stringWithDecimals = `${moneyObject.units}.${moneyObject.nanos}`;

    return Number(
      moneyFormatterWithoutStyle
        .format(stringWithDecimals)
        .replace('.', '')
        .replace(',', '.')
    );
  }
  if (moneyObject?.units && !moneyObject.nanos) {
    return Number(
      moneyFormatterWithoutStyle
        .format(moneyObject.units)
        .replace('.', '')
        .replace(',', '.')
    );
  }

  return 0;
};

export default {
  brazilianNumberTextFormatter,
  fromKgToBrazilianText,
  formatBRL,
  formatMoneyToNumber
};
