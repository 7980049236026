import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  FormControl,
  InputLabel,
  CircularProgress,
  Select,
  TextField,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import { colors } from '@loggi/mar';
import { withStyles } from '@material-ui/core/styles';
import SortingContextReaderRest from '../app/components/sorting-context-reader';
import { playErrorBeep, playSuccessBeep } from '../sounds';
import { editDirection } from './edit-package-status-service';
import {
  PACKAGE_DIRECTION_OPTIONS,
  PACKAGE_DIRECTION_REASONS,
  OPERATIONAL_PROCESS,
  ACTIVITY
} from '../constants';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';

moment.updateLocale('pt-BR');

const ListItemTextStyled = withStyles(() => ({
  primary: {
    fontWeight: 400
  }
}))(ListItemText);

const ListItemStyled = withStyles(() => ({
  root: {
    '&$selected': {
      backgroundColor: 'rgba(204, 241, 255, 0.4)',
      boxShadow: 'inset 3px 0px 0px #00BAFF',
      '&:hover': {
        backgroundColor: 'rgba(204, 241, 255, 0.4)',
        boxShadow: 'inset 3px 0px 0px #00BAFF'
      }
    }
  },
  selected: {}
}))(ListItem);

export default function ChangeDirection({ location }) {
  const [sortingContext, setSortingContext] = React.useState(
    (location.state && location.state.sortingContext) || {}
  );
  const [loading, setLoading] = React.useState(false);
  const [redirectIdentify, setRedirectIdentify] = React.useState(false);
  const [pack, setPack] = React.useState(null);
  const [selectedDirectionOption, setDirectionOption] = React.useState(null);
  const [selectedDirectionReason, setDirectionReason] = React.useState(0);
  const [otherReasonText, setOtherReasonText] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const isContextDefined = sortingContext && sortingContext.licensePlate;

  const isOptionSelected = selectedDirectionOption != null;
  const isReasonSelected = selectedDirectionReason > 0;

  React.useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    const packState = location?.state?.pack;
    if (packState) setPack(packState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, pack]);

  if (
    isContextDefined &&
    !('allowDirectionChange' in sortingContext.metadata)
  ) {
    setErrorMessage('Contexto não permitido para troca de direção');
    setSortingContext(null);
    playErrorBeep();
  }
  const errorHandler = React.useCallback(errMessage => {
    setErrorMessage(errMessage);
    playErrorBeep();
    setLoading(false);
  }, []);

  const currectDirection =
    pack && pack.direction
      ? PACKAGE_DIRECTION_OPTIONS.find(op => op.value === pack.direction)
      : null;

  const handleReasonChange = event => {
    setDirectionReason(event.target.value);
  };

  const handleOtherReasonChange = event => {
    setOtherReasonText(event.target.value);
  };

  const otherReason = PACKAGE_DIRECTION_REASONS.find(
    re => re.value === 'PACKAGE_DIRECTION_REASON_INVALID'
  );

  const selectedReason = PACKAGE_DIRECTION_REASONS.find(
    re => parseInt(re.id, 10) === parseInt(selectedDirectionReason, 10)
  );

  const selectedOption = PACKAGE_DIRECTION_OPTIONS.find(
    re => parseInt(re.id, 10) === parseInt(selectedDirectionOption, 10)
  );

  const filteredOptions = PACKAGE_DIRECTION_OPTIONS.filter(op => {
    return op.changeAvailability === true;
  });

  const handleSave = async () => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.CHANGE_DIRECTION_BEEP
    );
    setLoading(true);
    setErrorMessage('');

    const editPackageDirectionPayload = await editDirection({
      packageId: pack.pk,
      directionValue: selectedOption.value,
      reasonValue: selectedReason.value,
      notes: otherReasonText,
      errorHandler
    });

    if (!editPackageDirectionPayload) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.CHANGE_DIRECTION_BEEP
      );
      return;
    }

    if (!editPackageDirectionPayload.success) {
      const errMessage = (
        editPackageDirectionPayload.errors.map(err => err.message) || []
      ).join('\n');
      errorHandler(errMessage);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.CHANGE_DIRECTION_BEEP
      );
      return;
    }

    playSuccessBeep();
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.CHANGE_DIRECTION_BEEP);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    setRedirectIdentify(true);
    setErrorMessage('');
    setLoading(false);
  };

  if (redirectIdentify) {
    return (
      <Redirect
        to={{
          pathname: '/identify',
          state: {
            success: true
          }
        }}
      />
    );
  }

  return (
    <Box>
      {isContextDefined && pack ? (
        <Box>
          <Box>
            <Box p={2} fontSize="22px">
              Alterar direção do pacote
            </Box>
            <Box p={2} fontSize="16px">
              Pacote {pack.identifier}
              <Box fontSize="14px" color="#5A5A5A">
                Direção atual: {currectDirection?.shortName}
              </Box>
            </Box>

            <List>
              {filteredOptions.map(op => (
                <ListItemStyled
                  data-testid="identify-list-item"
                  button
                  selected={op.id === selectedDirectionOption}
                  onClick={() => setDirectionOption(op.id)}
                  key={op.id}
                >
                  <Box display="flex" p={1} lineHeight="150%">
                    <ListItemTextStyled primary={op.name} />
                    {op.id === selectedDirectionOption && (
                      <ListItemSecondaryAction>
                        <CheckIcon style={{ color: colors.blue[500] }} />
                      </ListItemSecondaryAction>
                    )}
                  </Box>
                </ListItemStyled>
              ))}
            </List>
          </Box>
          {selectedDirectionOption && (
            <Box>
              <Box p={4}>
                <FormControl>
                  <InputLabel>Motivo da alteração</InputLabel>
                  <Select
                    native
                    value={selectedReason?.id}
                    onChange={handleReasonChange}
                    data-testid="select"
                    inputProps={{
                      'data-testid': 'direction-alter'
                    }}
                  >
                    {PACKAGE_DIRECTION_REASONS.map(re => (
                      <option
                        data-testid={`select-option-${re.id}`}
                        value={re.id}
                        key={re.id}
                      >
                        {re.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {selectedReason.id === otherReason?.id && (
                <Box p={3.5}>
                  <TextField
                    fullWidth
                    inputProps={{ 'aria-label': 'edit-status-notes' }}
                    label="Por que a direção está sendo alterada?"
                    multiline
                    rows="3"
                    variant="outlined"
                    onChange={handleOtherReasonChange}
                    value={otherReasonText}
                  />
                </Box>
              )}
            </Box>
          )}
          {isOptionSelected && isReasonSelected && (
            <Box p={4}>
              <Button
                data-testid="update-app-button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleSave()}
              >
                Salvar
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Container maxWidth="xs">
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Alterar direção do pacote</Box>
              <Box fontWeight="fontWeightBold">Bipe o contexto</Box>
            </Typography>
            <Box my={2.5} mt={2.5} className="centered">
              <SortingContextReaderRest
                onRead={async sorting => {
                  setErrorMessage(null);
                  setSortingContext(sorting);
                }}
              />
            </Box>
          </Container>
        </Box>
      )}
      {errorMessage && (
        <Box mx={5} mt={1.5}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      {loading && (
        <Box mt={1.5} display="flex" justifyContent="center">
          <Box>
            <CircularProgress justify="center" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

ChangeDirection.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      sortingContext: PropTypes.shape({
        licensePlate: PropTypes.string
      }),
      pack: PropTypes.shape({
        pk: PropTypes.number,
        identifier: PropTypes.string,
        direction: PropTypes.string
      })
    })
  })
};

ChangeDirection.defaultProps = {
  location: {
    state: {
      sortingContext: {
        licensePlate: ''
      }
    }
  }
};
