import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import {
  FAILURE_FORGOT_PASSWORD,
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { PasswordInput, ScreenHeader } from '../../components';
import { LOGIN_ROUTE } from '../../routes/constants';
import { errorHandler, showNotificationMessage } from '../../service';
import {
  CONTINUE_BUTTON_ID,
  LOADING_ID,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX_FOR_LOWERCASE_LETTER,
  PASSWORD_REGEX_FOR_UPPERCASE_LETTER,
  PASSWORD_REGEX_FOR_NUMBER,
  PASSWORD_REGEX_FOR_SPECIAL_CHARACTER,
  PASSWORD_RECOVERY_ERRORS,
  RESEND_CODE_BUTTON_ID,
  VERIFICATION_CODE_INPUT_ID
} from './constants';
import useValidatePasswordRecoveryStyle from './styles';

const VerificationCodeInput = ({
  handleChange,
  handleBlur,
  verificationCode,
  verificationCodeLabel,
  error
}) => {
  const styles = useValidatePasswordRecoveryStyle();

  return (
    <TextField
      className={styles.input}
      fullWidth
      name="verificationCode"
      value={verificationCode}
      onBlur={handleBlur}
      helperText={error}
      error={Boolean(error)}
      onChange={handleChange}
      label={verificationCodeLabel}
      inputProps={{ 'data-testid': VERIFICATION_CODE_INPUT_ID }}
      margin="normal"
      variant="outlined"
    />
  );
};

VerificationCodeInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  verificationCode: PropTypes.string.isRequired,
  verificationCodeLabel: PropTypes.string.isRequired,
  error: PropTypes.string
};

VerificationCodeInput.defaultProps = {
  error: ''
};

const ValidatePasswordRecovery = () => {
  const styles = useValidatePasswordRecoveryStyle();
  const [loading, setLoading] = useState(false);
  const [submitsResendCode, setSubmitsResendCode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { state: email } = useLocation();
  const { push } = useHistory();
  const { t } = useTranslation('validatePasswordRecovery');
  const {
    forgotPassword,
    sendPasswordRecovery,
    state: {
      error: stateError,
      passwordRecovery: { hasConfirmedNewPassword, hasSentForgotPassword }
    }
  } = useAmplifyAuth();
  const validatePasswordSchema = Yup.object().shape({
    verificationCode: Yup.string().required(
      t('validations.verificationCodeRequired')
    ),
    // password must be 8 characters or longer and must contain at least
    // 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character.
    password: Yup.string()
      .required(t('validations.passwordRequired'))
      .min(PASSWORD_MIN_LENGTH, t('validations.passwordMinLength'))
      .matches(
        RegExp(PASSWORD_REGEX_FOR_LOWERCASE_LETTER),
        t('validations.passwordLowercaseLetterRequired')
      )
      .matches(
        RegExp(PASSWORD_REGEX_FOR_UPPERCASE_LETTER),
        t('validations.passwordUppercaseLetterRequired')
      )
      .matches(
        RegExp(PASSWORD_REGEX_FOR_NUMBER),
        t('validations.passwordNumberRequired')
      )
      .matches(
        RegExp(PASSWORD_REGEX_FOR_SPECIAL_CHARACTER),
        t('validations.passwordSpecialCharacterRequired')
      )
  });

  const passwordRecoveryErrorMessage = useCallback(
    passwordRecoveryError => {
      const { message, event } = passwordRecoveryError;
      let errorMessage = PASSWORD_RECOVERY_ERRORS[message.name]
        ? t(`errorsMessages.${PASSWORD_RECOVERY_ERRORS[message.name].code}`)
        : t('errorsMessages.DefaultException');
      if (event === FAILURE_FORGOT_PASSWORD) {
        errorMessage = t('errorsMessages.resendCodeException');
      }
      return errorMessage;
    },
    [t]
  );

  const validateConfirmationCode = formValues => {
    const { verificationCode, password } = formValues;

    setLoading(true);

    if (verificationCode && password) {
      sendPasswordRecovery(email, verificationCode, password);
    }
  };

  const resendConfirmationCode = () => {
    setSubmitsResendCode(true);
    forgotPassword(email);
  };

  useEffect(() => {
    if (stateError && stateError !== USER_NOT_AUTHENTICATED_ERROR) {
      showNotificationMessage(
        passwordRecoveryErrorMessage(stateError),
        'error',
        enqueueSnackbar
      );
      setLoading(false);
      errorHandler(stateError);
    }
  }, [stateError, enqueueSnackbar, passwordRecoveryErrorMessage, t]);

  useEffect(() => {
    if (hasSentForgotPassword && submitsResendCode) {
      showNotificationMessage(
        t('successMessages.resendCode'),
        'success',
        enqueueSnackbar
      );
    }
  }, [enqueueSnackbar, hasSentForgotPassword, submitsResendCode, t]);

  useEffect(() => {
    if (hasConfirmedNewPassword) {
      showNotificationMessage(
        t('successMessages.passwordUpdated'),
        'success',
        enqueueSnackbar
      );
      push(LOGIN_ROUTE);
    }
  }, [enqueueSnackbar, push, hasConfirmedNewPassword, t]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <ScreenHeader
          title={t('headerContent.title')}
          subTitle={t('headerContent.subTitle')}
        />
      </OneTemplateSummary>
      <OneTemplateContent>
        <Formik
          initialValues={{
            verificationCode: '',
            password: ''
          }}
          onSubmit={validateConfirmationCode}
          validationSchema={validatePasswordSchema}
        >
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            errors
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container justify="center" alignContent="space-between">
                <VerificationCodeInput
                  handleChange={handleChange}
                  verificationCode={values.verificationCode}
                  verificationCodeLabel={t('inputsLabel.verificationCode')}
                  handleBlur={handleBlur}
                  error={touched.verificationCode && errors.verificationCode}
                />
                <PasswordInput
                  handleChange={handleChange}
                  password={values.password}
                  handleBlur={handleBlur}
                  error={touched.password && errors.password}
                  label={t('inputsLabel.password')}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={styles.continueBtn}
                  fullWidth
                  disabled={loading}
                  data-testid={CONTINUE_BUTTON_ID}
                >
                  {!loading && t('buttons.continue')}
                  {loading && (
                    <CircularProgress
                      data-testid={LOADING_ID}
                      className={styles.loading}
                      size={26}
                    />
                  )}
                </Button>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    size="small"
                    className={styles.resendCodeBtn}
                    fullWidth
                    onClick={resendConfirmationCode}
                    data-testid={RESEND_CODE_BUTTON_ID}
                  >
                    {t('buttons.resendCode')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default ValidatePasswordRecovery;
