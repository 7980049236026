import React from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Typography, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@loggi/mar/src/utils';

import SharedPropTypes from '../shared-prop-types';

const useStyles = makeStyles({
  listItem: {
    paddingLeft: '0'
  }
});

export default function PackageItem({ packageItem, bottomDivider }) {
  const classes = useStyles();
  const companyName =
    packageItem?.company?.name || packageItem?.company?.sharedName;

  return (
    <Box
      mb={bottomDivider ? 0 : 1}
      data-testid={`package-item-${packageItem.identifier}`}
    >
      <Divider />
      <Box mt={1} mb={bottomDivider ? 1 : 0}>
        <ListItem className={classes.listItem}>
          <Box maxWidth={pxToRem(300)}>
            <Typography noWrap component="div" variant="body2" gutterBottom>
              Pacote de {companyName}
            </Typography>
            <Typography display="block" variant="body2" gutterBottom>
              #{packageItem.identifier}
            </Typography>
          </Box>
        </ListItem>
      </Box>
      {bottomDivider && <Divider />}
    </Box>
  );
}

PackageItem.defaultProps = {
  bottomDivider: false
};

PackageItem.propTypes = {
  packageItem: SharedPropTypes.package.isRequired,
  bottomDivider: PropTypes.bool
};
