/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from '../../routes/constants';
import { FORGOT_PASSWORD_LINK_ID } from '../constants';

const useForgotPasswordInStyle = makeStyles(({ palette }) => ({
  buttonLoading: {
    color: palette.common.white
  }
}));

const ForgotPasswordLink = ({ email }) => {
  const { push } = useHistory();
  const styles = useForgotPasswordInStyle();
  const { t } = useTranslation('recoveryPasswordForm');

  return (
    <Grid item container direction="column" alignItems="flex-end">
      <Link
        className={styles.forgotPassword}
        variant="body2"
        data-testid={FORGOT_PASSWORD_LINK_ID}
        onClick={() =>
          push({ pathname: FORGOT_PASSWORD_ROUTE, state: { email } })
        }
      >
        {t('linkButton.label')}
      </Link>
    </Grid>
  );
};

ForgotPasswordLink.propTypes = {
  email: PropTypes.string
};

ForgotPasswordLink.defaultProps = {
  email: ''
};

export default ForgotPasswordLink;
