import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import ErrorContent from './error-content.component';

const useAccessDeniedStyle = makeStyles(({ palette }) => ({
  image: {
    fill: palette.primary.contrastText
  },
  root: {
    backgroundColor: palette.grey['900']
  }
}));

const FullscreenFallback = () => {
  const styles = useAccessDeniedStyle();

  return (
    <Box className={styles.root} color="primary.contrastText">
      <Box
        height="100vh"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={{ xs: 3, sm: 5 }}
      >
        <ErrorContent darkMode />
      </Box>
    </Box>
  );
};

export default FullscreenFallback;
