import {
  Box,
  Hidden,
  SwipeableDrawer,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { colors } from '@loggi/mar';
import { ToggleButton } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import HeaderWithClose from './header-with-close';
import { IDENTIFY_EDIT_OPTIONS } from '../../constants';

const ListItemTextStyled = withStyles(() => ({
  primary: {
    fontWeight: 400
  }
}))(ListItemText);

const ListItemStyled = withStyles(() => ({
  root: {
    '&$selected': {
      backgroundColor: 'rgba(204, 241, 255, 0.4)',
      boxShadow: 'inset 3px 0px 0px #00BAFF',
      '&:hover': {
        backgroundColor: 'rgba(204, 241, 255, 0.4)',
        boxShadow: 'inset 3px 0px 0px #00BAFF'
      }
    }
  },
  selected: {}
}))(ListItem);

const SwipeableDrawerMobile = withStyles(({ spacing }) => ({
  root: {
    '& .MuiDrawer-paper.MuiPaper-root': {
      borderRadius: spacing(2, 2, 0, 0)
    }
  }
}))(SwipeableDrawer);

const SwipeableDrawerDesktop = withStyles(() => ({
  root: {
    '& .MuiDrawer-paper.MuiPaper-root': {
      minWidth: '480px'
    }
  }
}))(SwipeableDrawer);

export const DrawerMobile = ({
  open,
  close,
  identifiedPackage,
  selectedIdentifyEditOption,
  setIdentifyEditOption
}) => {
  const selectedOption = IDENTIFY_EDIT_OPTIONS.find(
    op => op.id === selectedIdentifyEditOption
  );

  return (
    <SwipeableDrawerMobile
      data-testid="identify-edit-menu"
      anchor="bottom"
      open={open}
      onOpen={() => {}}
      onClose={close}
    >
      <Box lineHeight="150%">
        <Box
          p={4}
          display="flex"
          fontSize="16px"
          lineHeight="150%"
          fontWeight={700}
          color={colors.smoke[900]}
        >
          Selecione o que deseja editar
        </Box>
        <ListIdentifyEditOptions
          selectedIdentifyEditOption={selectedIdentifyEditOption}
          setIdentifyEditOption={setIdentifyEditOption}
        />
        <Box p={3} display="flex">
          {identifiedPackage && (
            <Link
              to={{
                pathname: selectedOption.component,
                state: {
                  pack: identifiedPackage
                }
              }}
              style={{ width: '100%', textDecoration: 'none' }}
            >
              <Button
                data-testid="update-app-button"
                variant="contained"
                color="primary"
                fullWidth
              >
                Continuar
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </SwipeableDrawerMobile>
  );
};

DrawerMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  identifiedPackage: PropTypes.shape({
    pk: PropTypes.number
  }).isRequired,
  selectedIdentifyEditOption: PropTypes.number.isRequired,
  setIdentifyEditOption: PropTypes.func.isRequired
};

export const DrawerDesktop = ({
  open,
  close,
  identifiedPackage,
  selectedIdentifyEditOption,
  setIdentifyEditOption
}) => {
  const selectedOption = IDENTIFY_EDIT_OPTIONS.find(
    op => op.id === selectedIdentifyEditOption
  );

  return (
    <SwipeableDrawerDesktop
      data-testid="identify-edit-menu"
      anchor="right"
      open={open}
      onOpen={() => {}}
      onClose={close}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box flexGrow={1}>
          <Box p={4}>
            <HeaderWithClose
              testId="identify-close-button"
              handleClose={close}
            />
          </Box>
          <Box p={4} fontSize="16px" fontWeight={700} color={colors.smoke[900]}>
            Selecione o que deseja editar
          </Box>
          <ListIdentifyEditOptions
            selectedIdentifyEditOption={selectedIdentifyEditOption}
            setIdentifyEditOption={setIdentifyEditOption}
          />
        </Box>
        <Box p={3}>
          {identifiedPackage && (
            <Link
              to={{
                pathname: selectedOption.component,
                state: {
                  pack: identifiedPackage
                }
              }}
              style={{ width: '100%', textDecoration: 'none' }}
            >
              <Button
                data-testid="update-app-button"
                variant="contained"
                color="primary"
                fullWidth
              >
                Continuar
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </SwipeableDrawerDesktop>
  );
};

DrawerDesktop.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  identifiedPackage: PropTypes.shape({
    pk: PropTypes.number
  }).isRequired,
  selectedIdentifyEditOption: PropTypes.number.isRequired,
  setIdentifyEditOption: PropTypes.func.isRequired
};

export const ListIdentifyEditOptions = ({
  selectedIdentifyEditOption,
  setIdentifyEditOption
}) => {
  return (
    <List>
      {IDENTIFY_EDIT_OPTIONS.map(op => (
        <ListItemStyled
          data-testid="identify-list-item"
          button
          selected={op.id === selectedIdentifyEditOption}
          onClick={() => setIdentifyEditOption(op.id)}
          key={op.id}
        >
          <Box p={1} lineHeight="150%">
            <ListItemTextStyled primary={op.name} />
            {op.id === selectedIdentifyEditOption ? (
              <ListItemSecondaryAction>
                <CheckIcon style={{ color: colors.blue[500] }} />
              </ListItemSecondaryAction>
            ) : null}
          </Box>
        </ListItemStyled>
      ))}
    </List>
  );
};

ListIdentifyEditOptions.propTypes = {
  selectedIdentifyEditOption: PropTypes.number.isRequired,
  setIdentifyEditOption: PropTypes.func.isRequired
};

export const IdentifyEditMenu = ({ open, handleClose, identifiedPackage }) => {
  const [selectedIdentifyEditOption, setIdentifyEditOption] = useState(1);

  return (
    <>
      <Hidden smUp>
        <DrawerMobile
          open={open}
          close={handleClose}
          identifiedPackage={identifiedPackage}
          selectedIdentifyEditOption={selectedIdentifyEditOption}
          setIdentifyEditOption={setIdentifyEditOption}
        />
      </Hidden>
      <Hidden xsDown>
        <DrawerDesktop
          open={open}
          close={handleClose}
          identifiedPackage={identifiedPackage}
          selectedIdentifyEditOption={selectedIdentifyEditOption}
          setIdentifyEditOption={setIdentifyEditOption}
        />
      </Hidden>
    </>
  );
};

IdentifyEditMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  identifiedPackage: PropTypes.shape({
    pk: PropTypes.number
  }).isRequired
};

export const IdentifyEditButton = ({ identifiedPackage }) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const menuRef = useRef(null);

  return (
    <>
      <ToggleButton
        data-testid="identify-edit-menu-btn-id"
        onChange={() => setIsSelectorOpen(!isSelectorOpen)}
        ref={menuRef}
        selected={isSelectorOpen}
        size="small"
        variant="outlined"
        value="selected"
      >
        <Typography component="span" variant="body2">
          <Box fontWeight={700} lineHeight={1} color={colors.smoke[700]}>
            Editar
          </Box>
        </Typography>
      </ToggleButton>

      <IdentifyEditMenu
        handleClose={() => setIsSelectorOpen(false)}
        open={isSelectorOpen}
        identifiedPackage={identifiedPackage}
      />
    </>
  );
};

IdentifyEditButton.propTypes = {
  identifiedPackage: PropTypes.shape({
    pk: PropTypes.number
  }).isRequired
};
