import { validDamagedPackageDeclaration } from '../../api-rest';
import handleRestAPIError from '../../app/utils/rest-api-request';

const validDamagedPackage = async ({ packageId, errorHandler }) => {
  const response = await validDamagedPackageDeclaration(packageId).catch(err =>
    handleRestAPIError(err, errorHandler)
  );

  if (!response) return null;
  return response.data;
};

export default validDamagedPackage;
