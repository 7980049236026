import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import BagReader from './bag-reader';
import { HISTORY_ACTIONS } from '../../constants';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { FeatureSwitchContext } from '../../firebase/feature-switch-provider';

function BagReaderPage({ history }) {
  const location = useLocation();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const { enableInferDistributeSortingContext } = React.useContext(
    FeatureSwitchContext
  );

  // prevent user for opening this page when editing the URL
  if (
    history.action !== HISTORY_ACTIONS.PUSH ||
    !location.state?.contextLicensePlate
  ) {
    history.replace('/distribute');
    return null;
  }

  const handleGoBack = () => {
    if (
      enableInferDistributeSortingContext === 'true' &&
      selectedDistributionCenter
    ) {
      history.push('/');
    } else {
      history.goBack();
    }
  };

  const { contextLicensePlate } = location.state;

  return (
    <BagReader
      contextLicensePlate={contextLicensePlate}
      goBack={handleGoBack}
      goForward={history.push}
    />
  );
}

BagReaderPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired
  }).isRequired
};

export default BagReaderPage;
