import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Typography,
  Box,
  Container,
  Card,
  Grid,
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import { SWITCHES } from '../constants';
import { useFeature } from '../app/hooks/use-feature';

const useStyles = makeStyles(() => ({
  bodyCard: {
    height: 'max-content',
    flexWrap: 'wrap',
    textAlign: 'center'
  },
  buttonNav: {
    padding: '20px 10px 20px'
  }
}));

export const Transport = ({ location }) => {
  const classes = useStyles();

  const disableConferenciaMeliMenu = useFeature(
    SWITCHES.disableConferenciaMeliMenu
  );

  const disableTransportTransferMenu = useFeature(
    SWITCHES.disableTransportTransferMenu
  );

  const { showTransferOption } = location.state || false;

  const showAddBagStartTransferOptions =
    showTransferOption || !disableTransportTransferMenu;

  return (
    <Container maxWidth="xs">
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography component="div" variant="body1" gutterBottom>
            <Box fontWeight="fontWeightBold">Transportes</Box>
          </Typography>
          <Typography variant="h5" gutterBottom>
            Selecione uma operação:
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <Grid container p={20}>
          <Grid item md={12}>
            <BottomNavigation showLabels className={classes.bodyCard}>
              {!disableConferenciaMeliMenu && (
                <BottomNavigationAction
                  classes={{ root: classes.buttonNav }}
                  component={Link}
                  to="/meli-checking"
                  label="Conferência Meli"
                  icon={<AssignmentTurnedInIcon />}
                  data-testid="meli-checking-nav"
                />
              )}
              {showAddBagStartTransferOptions && (
                <BottomNavigationAction
                  classes={{ root: classes.buttonNav }}
                  component={Link}
                  to={{
                    pathname: '/add-bag-to-transfer',
                    state: {
                      showButtonStartTransfer: showTransferOption
                    }
                  }}
                  label="Adicionar Unidade à transferência"
                  icon={<AddBoxIcon />}
                  data-testid="add-bag-to-transfer-nav"
                />
              )}
              {showAddBagStartTransferOptions && (
                <BottomNavigationAction
                  classes={{ root: classes.buttonNav }}
                  component={Link}
                  to="/start-transfer"
                  label="Iniciar Transferência"
                  icon={<PlayArrowIcon />}
                  data-testid="start-transfer-nav"
                />
              )}
            </BottomNavigation>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

Transport.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      showTransferOption: PropTypes.bool
    })
  })
};

Transport.defaultProps = {
  location: {
    state: {
      showTransferOption: false
    }
  }
};

export default { Transport };
