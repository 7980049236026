import { colors } from '@loggi/mar';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { COMPANIES_ROUTE } from '../../routes/constants';
import { ReactComponent as DeniedImage } from '../../assets/illustra.svg';
import { ScreenHeader } from '../../components';

const useAccessDeniedStyle = makeStyles(({ palette }) => ({
  image: {
    fill: palette.primary.contrastText
  },
  root: {
    backgroundImage: colors.gradients.sanches
  }
}));

const AccessDenied = () => {
  const styles = useAccessDeniedStyle();
  const { t } = useTranslation('accessDenied');
  const { push } = useHistory();

  return (
    <Box
      display="flex"
      height="100vh"
      color="common.white"
      className={styles.root}
    >
      <Container>
        <Box display="flex" height={1} pt={9.5}>
          <Grid container justify="center" direction="column">
            <Grid item>
              <DeniedImage className={styles.image} />
            </Grid>
            <Grid item>
              <Box mt={2}>
                <ScreenHeader
                  title={t('headerContent.title')}
                  subTitle={t('headerContent.subTitleFirst')}
                />
                <Typography variant="h6" component="h2">
                  {t('headerContent.subTitleSecond')}
                </Typography>
              </Box>
              <Box mt={3}>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => push(COMPANIES_ROUTE)}
                >
                  {t('button.label')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AccessDenied;
