import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Avatar, Box, Container, Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '@loggi/mar/src/utils';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import SharedPropTypes from '../../app/shared-prop-types';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import StrictPackageIdentification from './strict-package-identification';
import RemainingWarningDialog from './remaining-warning-dialog';
import pluralize from '../../app/utils/pluralize';
import logLogicalMismatchesService from './log-logical-mismatches-service';
import { GeolocationContext } from '../../geo';
import { LOGICAL_MISMATCH_OPERATIONAL_PROCESSES } from '../../constants';
import LogoCorreios from '../../assets/images/correios-logo.svg';
import RedispatchReceiverConfirmation from './redispatch-receiver-confirmation';
import PackageList from '../../app/components/package-list';
import { BAG_TYPE } from '../../app/enums';

const useStyles = makeStyles(thisTheme => ({
  root: {
    '& > *': {
      margin: thisTheme.typography.pxToRem(1)
    },
    display: 'flex',
    flexGrow: 1,
    flexFlow: 'column',
    overflow: 'hidden',
    height: '97vh'
  },
  avatar: {
    width: pxToRem(60),
    height: pxToRem(60)
  }
}));
export default function RedispatchPackageReader({
  goBack,
  bag,
  redispatchUnitLoad,
  contextLicensePlate,
  userPk
}) {
  const classes = useStyles();

  const [geo] = React.useContext(GeolocationContext);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [checkedPackages, setCheckedPackages] = React.useState([]);
  const [remainingPackages, setRemainingPackages] = React.useState(
    bag.packages
  );
  const [checkedInvalidPackages, setCheckedInvalidPackages] = React.useState(
    []
  );
  const [
    remainingInvalidPackages,
    setRemainingInvalidPackages
  ] = React.useState(bag.invalidPackages);
  const [
    receiverConfirmationDialogOpen,
    setReceiverConfirmationDialogOpen
  ] = React.useState(false);
  const [remainingDialogOpen, setRemainingDialogOpen] = React.useState(false);
  const [hasBeepedAllPackages, setHasBeepedAllPackages] = React.useState(false);
  const [
    isProceedingWithRemaining,
    setIsProceedingWithRemaining
  ] = React.useState(false);

  const hasCheckedSomePackage =
    checkedPackages.length > 0 || checkedInvalidPackages.length > 0;

  const bagRemainingPackagesCount =
    bag.numPackages - checkedInvalidPackages.length;

  const totalRemainingPackagesCount =
    remainingPackages.length + remainingInvalidPackages.length;

  useEffect(() => {
    const hasPackagesToBeep =
      remainingPackages.length > 0 || remainingInvalidPackages.length > 0;

    if (!hasPackagesToBeep) {
      setHasBeepedAllPackages(true);
    }
  }, [remainingPackages, remainingInvalidPackages]);

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
  };

  const clearForNewReading = () => setErrorMessage('');

  const updatePackagesStates = barcode => {
    setCheckedPackages([
      ...checkedPackages,
      ...bag.packages.filter(p => p.identifier === barcode)
    ]);
    setRemainingPackages(
      remainingPackages.filter(p => p.identifier !== barcode)
    );
  };

  const updateInvalidPackagesStates = barcode => {
    setCheckedInvalidPackages([
      ...checkedInvalidPackages,
      ...bag.invalidPackages.filter(p => p.identifier === barcode)
    ]);
    setRemainingInvalidPackages(
      remainingInvalidPackages.filter(p => p.identifier !== barcode)
    );
  };

  const checkPackageAlreadyBeeped = barcode =>
    checkedPackages.some(p => p.identifier === barcode);

  const handlePackageReadSuccess = async currentPackage => {
    clearForNewReading();
    const barcode = currentPackage?.identifier;

    if (!barcode) {
      baseErrorHandler('Código de barras não encontrado');
      return;
    }

    if (checkPackageAlreadyBeeped(barcode)) {
      baseErrorHandler('Opa, pacote já bipado!');
      return;
    }

    updatePackagesStates(barcode);
    playSuccessBeep();
  };

  const handleLogicalMismatches = async () => {
    const lng = geo.coords.longitude;
    const lat = geo.coords.latitude;
    await logLogicalMismatchesService({
      lat,
      lng,
      sortingContextLicensePlate: contextLicensePlate,
      userId: userPk,
      unitLoadLicensePlate: bag.seal,
      operationalProcess:
        LOGICAL_MISMATCH_OPERATIONAL_PROCESSES.OPERATIONAL_PROCESS_DISTRIBUTE,
      packages: [...remainingPackages, ...remainingInvalidPackages]
    });
  };

  const proceedWithRemainingPackages = async () => {
    // If this request fails we still proceed with the rest of the process
    await handleLogicalMismatches().catch(Sentry.captureException);

    setIsProceedingWithRemaining(true);
    setReceiverConfirmationDialogOpen(true);
  };

  const openRemainingPackages = () => setRemainingDialogOpen(true);

  const openReceiverConfirmation = () =>
    setReceiverConfirmationDialogOpen(true);

  const handleClose = () => goBack();

  return (
    <Box data-testid="distribute-redispatch-package-reader">
      <Container maxWidth="xs" className={classes.root}>
        {!isProceedingWithRemaining && (
          <Box
            paddingTop={2.5}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <Box flex={1}>
              <HeaderWithCancel
                testId="distribute-package-reader-cancel-button"
                handleClose={handleClose}
              />
              <Box paddingTop={1.5}>
                <Typography component="div" variant="body1" gutterBottom>
                  <Box fontWeight="fontWeightBold">Distribuir</Box>
                </Typography>
              </Box>
              {hasCheckedSomePackage ? (
                <Box display="flex">
                  <Typography
                    color="secondary"
                    variant="h3"
                    data-testid="checked-packages-count"
                  >
                    {checkedPackages.length}
                  </Typography>
                  <Typography
                    variant="h3"
                    data-testid="bag-remaining-packages-count"
                  >
                    &nbsp;de {bagRemainingPackagesCount}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="h3">
                  <Box>{bag.numPackages}</Box>
                </Typography>
              )}
              <Typography variant="body1">
                {`${pluralize({
                  singular: 'pacote',
                  count: bag.numPackages.length
                })}`}
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box mt={3}>
                  <Typography variant="body1">
                    <Box fontWeight="fontWeightBold">
                      {redispatchUnitLoad.name}
                    </Box>
                    <Box fontWeight="fontWeightLight">Correios</Box>
                  </Typography>
                </Box>
                <Box marginLeft="auto" data-testid="unit-load-avatar">
                  <Avatar src={LogoCorreios} className={classes.avatar} />
                </Box>
              </Box>
              {!hasBeepedAllPackages && (
                <Box>
                  <Box mt={2}>
                    <Typography variant="body1">LACRE {bag.seal}</Typography>
                  </Box>
                  <Box className="centered" mt={2.5}>
                    <StrictPackageIdentification
                      onMovePackage={updateInvalidPackagesStates}
                      expectedPackages={bag.packages}
                      invalidPackages={bag.invalidPackages}
                      checkedInvalidPackages={checkedInvalidPackages}
                      before={clearForNewReading}
                      success={handlePackageReadSuccess}
                      fail={baseErrorHandler}
                      sortingContext={contextLicensePlate}
                      source="xd_app_distribute_package"
                      notes={`Bipado na checagem de pacotes ao distribuir a saca ${
                        bag.seal
                      }`}
                    />
                  </Box>
                </Box>
              )}

              {errorMessage && (
                <Box mt={1.5}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Box>
              )}
            </Box>

            {hasBeepedAllPackages && (
              <Box my={2} flex="1 1 auto" overflow="auto">
                <PackageList
                  packages={checkedPackages}
                  bagType={BAG_TYPE.BAG_TYPE_REDISPATCH}
                />
              </Box>
            )}

            {checkedPackages.length > 0 && (
              <Box flex="0 1 auto">
                <Button
                  fullWidth
                  data-testid="distribute-finish-reading-btn"
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={
                    hasBeepedAllPackages
                      ? openReceiverConfirmation
                      : openRemainingPackages
                  }
                >
                  Pronto
                </Button>
              </Box>
            )}

            {remainingDialogOpen && (
              <RemainingWarningDialog
                packageCount={totalRemainingPackagesCount}
                handleClose={() => setRemainingDialogOpen(false)}
                proceed={proceedWithRemainingPackages}
              />
            )}
          </Box>
        )}

        {receiverConfirmationDialogOpen && (
          <RedispatchReceiverConfirmation
            packages={checkedPackages}
            redispatchUnitLoad={redispatchUnitLoad}
            contextLicensePlate={contextLicensePlate}
          />
        )}
      </Container>
    </Box>
  );
}

RedispatchPackageReader.propTypes = {
  bag: PropTypes.shape({
    seal: PropTypes.string,
    numPackages: PropTypes.number.isRequired,
    packages: SharedPropTypes.packages.isRequired,
    invalidPackages: SharedPropTypes.packages.isRequired
  }).isRequired,
  redispatchUnitLoad: SharedPropTypes.unitLoad.isRequired,
  goBack: PropTypes.func.isRequired,
  contextLicensePlate: PropTypes.string.isRequired,
  userPk: PropTypes.number.isRequired
};
