const filterOutliersByLicensePlate = unitLoad => {
  if (unitLoad?.childrenUnitLoad) {
    const licensePlatesOutliers = unitLoad?.unitLoadsOutlier?.map(
      ul => ul.licensePlate
    );

    if (licensePlatesOutliers) {
      return unitLoad?.childrenUnitLoad?.filter(
        ({ licensePlate }) => !licensePlatesOutliers.includes(licensePlate)
      );
    }
    return unitLoad?.childrenUnitLoad;
  }
  return null;
};

const filterOutliersByIdentifier = unitInfo => {
  if (unitInfo?.childrenUnitLoad) {
    const licensePlatesOutliers = unitInfo?.unitLoadsOutlier?.map(
      ul => ul.childUnitLoad.identifier
    );
    if (licensePlatesOutliers) {
      return unitInfo?.childrenUnitLoad?.filter(
        obj => !licensePlatesOutliers.includes(obj.childUnitLoad.identifier)
      );
    }
    return unitInfo?.childrenUnitLoad;
  }
  return null;
};

const childrenWithoutOutliers = ({ unitLoad, unitInfo }) => {
  return (
    filterOutliersByLicensePlate(unitLoad) ||
    filterOutliersByIdentifier(unitInfo) ||
    []
  );
};

export default childrenWithoutOutliers;
