import firebase from 'firebase/app';
import 'firebase/remote-config';
import { REMOTE_CONFIG_DEFAULT } from '../constants';

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const firebaseConfig = {
  apiKey,
  appId,
  authDomain,
  databaseURL,
  messagingSenderId,
  projectId,
  storageBucket
};

firebase.initializeApp(firebaseConfig);

const remoteConfig = firebase.remoteConfig();

// We are setting a lower fetch interval to react faster on support situations
// https://firebase.google.com/docs/remote-config/use-config-android#throttling
// As long as we don't introduce less than one hour intervals, we should be safe
// eslint-disable-next-line radix
remoteConfig.settings.minimumFetchIntervalMillis = parseInt(
  process.env.REACT_APP_FIREBASE_CONFIG_CACHE_INTERVAL_MS
);

// we MUST set in-app default parameter values in the Remote Config object,
// so that the app behaves as intended before it connects to the
// Remote Config backend, and so that default values are available
// if none are set on the backend.
//
// Example:
//
// remoteConfig.defaultConfig = {
//  enable_packages_app: 'false'
// };
//
remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULT;

export default remoteConfig;
