import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { GOOGLE_PROVIDER } from '@loggi/authentication/src/screens/signin/constants';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { useSnackbar } from 'notistack';
import { showNotificationMessage } from '@loggi/authentication/src/service';
import { ScannerProvider } from './components/scanner-provider';
import { GeolocationProvider } from '../geo';

import {
  Header,
  AuthenticationRoutes,
  AllowedRoutes
} from './cognito-authentication';
import {
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT,
  GSUITE_SIGN_UP_EMAIL_MUST_BE_FROM_LOGGI,
  GSUITE_SIGN_UP_CORPORATE_USER_MUST_EXIST
} from '../constants';
import { FeatureSwitchContext } from '../firebase/feature-switch-provider';
import { DistributionCenterProvider } from './access-control/distribution-center-provider';
import usePWAUpdate from './hooks/use-pwa-update';
import { ActivityTrackingProvider } from './activity-tracking/activity-tracking-provider';

moment.updateLocale('pt-BR');

const useAppStyles = makeStyles(() => ({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

export function DefaultProvider({ children }) {
  // The main intent of this provider it is to avoid multiple line changes on PRs that adds a new Provider
  // If there is a need to add a new provider to wrap the application, it should be added in DefaultProvider
  return (
    <GeolocationProvider>
      <ScannerProvider>
        <DistributionCenterProvider>
          <ActivityTrackingProvider>{children}</ActivityTrackingProvider>
        </DistributionCenterProvider>
      </ScannerProvider>
    </GeolocationProvider>
  );
}

DefaultProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function SetupPWAUpdate() {
  usePWAUpdate({
    checkForUpdatesPeriodically: true,
    watchForControllerChange: true
  });

  return null;
}

export function App() {
  const styles = useAppStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [redirecting, setRedirecting] = useState(false);
  const location = useLocation();

  const {
    state: { authenticatedUser, loading },
    federatedSignIn
  } = useAmplifyAuth();

  const { isLoading: isLoadingFeatureSwitches } = useContext(
    FeatureSwitchContext
  );

  const search = location?.search;

  useEffect(() => {
    if (!search) {
      return;
    }
    if (search.includes(SIGN_UP_GOOGLE_USER_LINK_REDIRECT)) {
      setRedirecting(true);
      federatedSignIn(GOOGLE_PROVIDER);
    } else if (search.includes(GSUITE_SIGN_UP_EMAIL_MUST_BE_FROM_LOGGI.URL)) {
      showNotificationMessage(
        GSUITE_SIGN_UP_EMAIL_MUST_BE_FROM_LOGGI.ERROR_MESSAGE,
        'error',
        enqueueSnackbar
      );
    } else if (search.includes(GSUITE_SIGN_UP_CORPORATE_USER_MUST_EXIST.URL)) {
      showNotificationMessage(
        GSUITE_SIGN_UP_CORPORATE_USER_MUST_EXIST.ERROR_MESSAGE,
        'error',
        enqueueSnackbar
      );
    }
  }, [search, federatedSignIn, enqueueSnackbar]);

  if (loading || redirecting || isLoadingFeatureSwitches) {
    return (
      <Box className={styles.loading}>
        <CircularProgress data-testid="signin-loading-id" />
      </Box>
    );
  }

  return (
    <>
      <SetupPWAUpdate />
      <CssBaseline />
      {!authenticatedUser && <Header />}
      <DefaultProvider>
        <Switch>
          {!authenticatedUser ? <AuthenticationRoutes /> : <AllowedRoutes />}
        </Switch>
      </DefaultProvider>
    </>
  );
}
