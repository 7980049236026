import { colors } from '../principles';

const MuiTableRow = {
  root: {
    '&:last-child .MuiTableCell-root': {
      borderBottom: 0
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: colors.blue['50'],
      cursor: 'pointer'
    }
  }
};

export default MuiTableRow;
