import { colors } from '@loggi/mar';
import { Avatar, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import sharedPropTypes from '../shared-prop-types';
import pluralize from '../utils/pluralize';

function PackagePhrase({ skipPackagesChecks, totalPackages }) {
  return (
    <Typography variant="h3">
      {skipPackagesChecks ? (
        <>
          <Box> 1 </Box>
          <Typography variant="body1">Unidade</Typography>
        </>
      ) : (
        <>
          <Box>{totalPackages}</Box>
          <Typography variant="body1">
            {`${pluralize({
              singular: 'pacote',
              count: totalPackages
            })}`}
          </Typography>
        </>
      )}
    </Typography>
  );
}

PackagePhrase.propTypes = {
  skipPackagesChecks: PropTypes.bool.isRequired,
  totalPackages: PropTypes.number.isRequired
};

export default function PackagePosition({
  checkedPackages,
  skipPackagesChecks,
  identifier,
  lastCheckedPackagePosition,
  totalPackages,
  showPackagePositionOnDistribute
}) {
  const testId = `${identifier}-package-position`;

  return checkedPackages.length > 0 && !skipPackagesChecks ? (
    <Box display="flex" alignItems="center">
      <Typography
        color="secondary"
        variant="h3"
        data-testid="checked-packages-count"
      >
        {checkedPackages.length}
      </Typography>
      <Typography data-testid="total-packages-count" variant="h3">
        &nbsp;de {totalPackages}
      </Typography>
      {showPackagePositionOnDistribute === 'true' &&
        (lastCheckedPackagePosition?.position >= 0 ||
          lastCheckedPackagePosition?.displayId) && (
          <Box
            display="flex"
            flex={1}
            justifyContent="flex-end"
            data-testid={testId}
          >
            <Avatar
              style={{
                width: 'auto',
                minWidth: '24px',
                height: '40px',
                borderStyle: 'solid',
                borderWidth: 'thin',
                backgroundColor: 'transparent',
                borderColor: colors.blue[500],
                borderRadius: 10,
                paddingInline: '8px'
              }}
            >
              <Typography
                data-testid={`${lastCheckedPackagePosition.displayId}-position`}
                variant="h5"
                style={{ fontWeight: 600, color: colors.root[900] }}
              >
                {lastCheckedPackagePosition.displayId}
              </Typography>
            </Avatar>
          </Box>
        )}
    </Box>
  ) : (
    <PackagePhrase
      skipPackagesChecks={skipPackagesChecks}
      totalPackages={totalPackages}
    />
  );
}

PackagePosition.propTypes = {
  checkedPackages: sharedPropTypes.packages.isRequired,
  skipPackagesChecks: PropTypes.bool.isRequired,
  identifier: PropTypes.string.isRequired,
  lastCheckedPackagePosition: sharedPropTypes.packages.isRequired,
  totalPackages: PropTypes.number.isRequired,
  showPackagePositionOnDistribute: PropTypes.bool.isRequired
};
