/**
 * Set a given value in the storage.
 * @param {string} key new key to be added in the storage
 * @param {string} value new value to be added in the storage
 * @param {number} ttl the expiry time in Ms
 */
export const setStorageValueWithTtl = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value,
    ttl: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Get a value in the storage, if the ttl did not expired.
 * If the ttl time expired or this key has no value, it returns null.
 * @param {string} key key related to desired value
 * @returns {string}
 */
export const getStorageValueWithTtl = key => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.ttl) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
