import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import DistributePackageReader from './distribute-package-reader';
import { HISTORY_ACTIONS } from '../../constants';

function DistributePackageReaderPage({ history }) {
  const location = useLocation();

  // prevent user for opening this page when editing the URL
  if (history.action !== HISTORY_ACTIONS.PUSH || !location.state?.bag) {
    history.replace('/distribute');
    return null;
  }

  const goBack = () => {
    history.replace('/distribute');
  };

  return (
    <DistributePackageReader
      bag={location.state.bag}
      itineraryId={location.state.itinerary.pk}
      driver={location.state.itinerary.driver}
      offerAcceptedAt={location.state.itinerary.accepted}
      checkedPackagesIds={location.state.checkedPackagesIds}
      destinationUnitLoadLpn={location.state.destinationUnitLoadLpn}
      userPk={location.state.userPk}
      contextLicensePlate={location.state.contextLicensePlate}
      goBack={goBack}
      caraCrachaRecord={location.state.caraCrachaRecord}
    />
  );
}

DistributePackageReaderPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    action: PropTypes.string
  }).isRequired
};

export default DistributePackageReaderPage;
