import React from 'react';

import { Box, Typography, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BarcodeReader } from '../../app/components/barcode-readers';
import { printRawMessage, printerNameMessage } from './constants';
import { getLabel } from '../../api-rest';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';

const ipp = require('ipp');

const ERROR_MESSAGE_DEFAULT = 'Eita, erro ao processar a solicitação';

export const LABEL_TYPE = {
  PDF: 1,
  ZPL: 2
};

export default function Printer() {
  const [loading, setLoading] = React.useState(false);
  const [printerName, setPrinterName] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [printerURL, setPrinterURL] = React.useState('');

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const distributionCenterRoutingCode = selectedDistributionCenter?.routingCode;

  const handleConnectPrinter = url => {
    try {
      setLoading(true);
      const printer = ipp.Printer(url);

      printer.execute(
        'Get-Printer-Attributes',
        printerNameMessage,
        (err, res) => {
          if (!err) {
            setPrinterName(res['printer-attributes-tag']['printer-name']);
            setPrinterURL(url);
            setErrorMessage('');
          } else {
            setErrorMessage('Impressora não encontrada!');
          }
        }
      );
    } catch (error) {
      setErrorMessage(ERROR_MESSAGE_DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  const getLabelApi = async identifier => {
    try {
      const response = await getLabel({
        baseRoutingCode: distributionCenterRoutingCode,
        itemBarcode: identifier,
        labelType: LABEL_TYPE.ZPL
      });

      if (response?.data) {
        setErrorMessage('');
        return response.data;
      }

      setErrorMessage('Eita, resposta inválida.');
    } catch (error) {
      const message = error.response?.data?.status?.message || error.message;
      setErrorMessage(message);
    }
    return null;
  };

  const handleLabel = label => {
    try {
      const buffer = Buffer.from(label, 'base64');
      const printer = ipp.Printer(printerURL);
      printer.execute('Print-Job', printRawMessage(buffer));
    } catch (error) {
      setErrorMessage(ERROR_MESSAGE_DEFAULT);
    }
  };

  const handleReadItem = async barcode => {
    setLoading(true);
    setErrorMessage('');
    const response = await getLabelApi(barcode);
    if (response) {
      handleLabel(response.labelBase64);
    }

    setLoading(false);
  };

  const renderBarcodeReader = () => {
    return (
      <Box my={1.5} mt={2.5}>
        <Box mb={2} display="flex" justifyContent="center">
          <Typography>
            Impressora: <b>{printerName}</b>
          </Typography>
        </Box>
        <BarcodeReader
          placeholder="Bipe o código de barras"
          onRead={handleReadItem}
          loading={loading}
          disable={!printerName}
        />
      </Box>
    );
  };

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box data-testid="printer" fontWeight="fontWeightBold">
          Imprimir
        </Box>
      </Typography>

      <Box my={1.5} mt={2.5}>
        {!printerURL && (
          <BarcodeReader
            placeholder="Leia a identificação da impressora"
            onRead={handleConnectPrinter}
            loading={loading}
          />
        )}
      </Box>

      {printerName && renderBarcodeReader()}

      {errorMessage && (
        <Box my={1.5}>
          <Alert data-testid="error" severity="error">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Container>
  );
}
