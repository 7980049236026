const printRawMessage = data => ({
  'operation-attributes-tag': {
    'requesting-user-name': 'xd-app-user',
    'job-name': 'barcode-fool',
    'document-format': 'application/vnd.cups-raw'
  },
  data
});

const printerNameMessage = {
  'operation-attributes-tag': {
    'requesting-user-name': 'xd-app-user',
    'document-format': 'image/pwg-raster',
    'requested-attributes': ['printer-name']
  }
};

const zplData = msg => {
  const zplTemplate = `^XA^FO50,60^A0,40^FDLoggi^FS^FO60,120^BY3^BCN,60,,,,A^FD${msg}^FS^FO25,25^GB380,200,2^FS^XZ`;

  return Buffer.from(zplTemplate, 'utf8');
};

const printerURL = 'http://localhost:631/printers/xd-app';

export { printRawMessage, printerNameMessage, zplData, printerURL };
