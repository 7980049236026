import React from 'react';
import PropTypes from 'prop-types';
import { GeolocationContext } from '../../geo';
import { packageCheck } from '../../api';
import {
  KeyboardScannerTemplate,
  CameraScannerTemplate
} from './scanner-template';
import { ScannerContext } from './scanner-provider';
import { useDistributionCenter } from '../access-control/distribution-center-provider';

/**
 * Barcode reader component.
 * Takes an asynchronous onRead callback.
 */
export function BarcodeReader({
  onRead,
  onChange,
  loading,
  disable,
  placeholder
}) {
  const [screenScanner, setScreenScanner] = React.useContext(ScannerContext);

  const handleCameraClick = () => {
    setScreenScanner(!screenScanner);
  };

  if (!screenScanner) {
    return (
      <KeyboardScannerTemplate
        onRead={onRead}
        onChange={onChange}
        loading={loading}
        disable={disable}
        handleCameraClick={handleCameraClick}
        placeholder={placeholder}
      />
    );
  }

  return (
    <CameraScannerTemplate
      onRead={onRead}
      handleCameraClick={handleCameraClick}
    />
  );
}

BarcodeReader.propTypes = {
  onRead: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  placeholder: PropTypes.string
};

BarcodeReader.defaultProps = {
  onRead: () => {},
  onChange: () => {},
  loading: false,
  disable: false,
  placeholder: ''
};

/**
 * Specialization of the BarcodeReader for packages.
 * Publishes a physical evidence of the identified package.
 */
export function PackageReader({
  loading,
  disable,
  onRead,
  onChange,
  notes,
  source,
  placeholder
}) {
  const [geo] = React.useContext(GeolocationContext);

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const handleRead = async barcode => {
    if (loading) {
      return;
    }
    const packCheckPromise = packageCheck(
      geo,
      barcode,
      notes,
      source,
      selectedDistributionCenter
    );
    await onRead(barcode, packCheckPromise);
  };

  return (
    <BarcodeReader
      onRead={handleRead}
      onChange={onChange}
      loading={loading}
      disable={disable}
      placeholder={placeholder}
    />
  );
}

PackageReader.propTypes = {
  onRead: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  notes: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.string
};

PackageReader.defaultProps = {
  onRead: () => {},
  onChange: () => {},
  loading: false,
  disable: false,
  notes: 'Bipado com XD App',
  source: 'xd_app',
  placeholder: ''
};
