import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SharedPropTypes from '../../app/shared-prop-types';
import BasicChip from '../../app/components/basic-chip';
import pluralize from '../../app/utils/pluralize';

const usePackagesWithoutLabelDialogStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      height: '96%',
      maxHeight: '100%'
    }
  }
}));

const PackagesWithoutLabelDialog = ({
  open,
  pkgsToPrepareInfoWithoutLabel,
  onCancel
}) => {
  const { paper } = usePackagesWithoutLabelDialogStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper }}
      data-testid="packages-without-label"
    >
      <DialogTitle>
        <Box pt={4}>
          <Typography variant="h6">
            <Box component="span">Falha no preparo de </Box>
            <Box component="span" fontWeight="fontWeightBold">
              {`${pkgsToPrepareInfoWithoutLabel.length} ${pluralize({
                singular: 'pacote',
                count: pkgsToPrepareInfoWithoutLabel.length
              })}.`}
            </Box>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" width="100%">
          <List>
            {pkgsToPrepareInfoWithoutLabel.map(info => (
              <PackageItem
                pkgToPrepareInfoWithoutLabel={info}
                key={info.pkg.identifier}
              />
            ))}
          </List>
        </Box>
      </DialogContent>
      <Box mb={1.5} clone>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onCancel}
            data-testid="close"
          >
            Fechar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

PackagesWithoutLabelDialog.defaultProps = {
  open: true
};

PackagesWithoutLabelDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  pkgsToPrepareInfoWithoutLabel: SharedPropTypes.pkgsToPrepareInfo.isRequired
};

const PackageItem = ({ pkgToPrepareInfoWithoutLabel }) => {
  return (
    <ListItem
      divider
      disableGutters
      data-testid={`pkg-without-label-${
        pkgToPrepareInfoWithoutLabel.pkg.identifier
      }`}
    >
      <ListItemText
        primary={
          <Box pb={1} style={{ overflowWrap: 'break-word' }}>
            <Typography variant="body1">
              <Box component="span" fontWeight="fontWeightMedium">
                {pkgToPrepareInfoWithoutLabel.pkg.identifier}
              </Box>
            </Typography>
          </Box>
        }
        secondary={
          <Box style={{ overflowWrap: 'break-word' }}>
            <Typography gutterBottom variant="subtitle2">
              {`Pacote de ${pkgToPrepareInfoWithoutLabel.pkg.company
                .sharedName || pkgToPrepareInfoWithoutLabel.pkg.company.name}`}
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {`para ${pkgToPrepareInfoWithoutLabel.pkg.recipient.name}`}
            </Typography>
            <Box mb={1.5} mt={1.5}>
              <BasicChip
                message={pkgToPrepareInfoWithoutLabel.mainLabel.missingText}
              />
            </Box>
          </Box>
        }
      />
    </ListItem>
  );
};

PackageItem.propTypes = {
  pkgToPrepareInfoWithoutLabel: SharedPropTypes.pkgToPrepareInfo.isRequired
};

export default PackagesWithoutLabelDialog;
