import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { PASSWORD_BUTTON_ADORNIMENT_ID, PASSWORD_INPUT_ID } from '../constants';

const PasswordInput = ({
  handleChange,
  handleBlur,
  password,
  error,
  handleFillPassword,
  label,
  className
}) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={className}
      margin="normal"
    >
      <InputLabel error={Boolean(error)} htmlFor={PASSWORD_INPUT_ID}>
        {label}
      </InputLabel>
      <OutlinedInput
        name="password"
        autoComplete="on"
        error={Boolean(error)}
        value={password}
        type={shouldShowPassword ? 'text' : 'password'}
        label={label}
        inputProps={{ 'data-testid': PASSWORD_INPUT_ID }}
        onChange={e => {
          handleChange(e);
          if (handleFillPassword) {
            handleFillPassword(e);
          }
        }}
        onBlur={handleBlur}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              data-testid={PASSWORD_BUTTON_ADORNIMENT_ID}
              onClick={() => setShouldShowPassword(!shouldShowPassword)}
            >
              {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </FormControl>
  );
};

PasswordInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  handleFillPassword: PropTypes.func,
  className: PropTypes.string
};

PasswordInput.defaultProps = {
  error: '',
  handleFillPassword: undefined,
  className: ''
};

export default PasswordInput;
