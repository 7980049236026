import React, { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  SwipeableDrawer,
  Typography,
  ButtonBase,
  Hidden,
  Popover,
  ListSubheader,
  List,
  Container,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { colors } from '@loggi/mar';
import { ToggleButton } from '@material-ui/lab';
import { pxToRem } from '@loggi/mar/src/utils';
import { useSnackbar } from 'notistack';
import { OrganizeProcessContext } from './organize-process-context';
import { playErrorBeep } from '../../sounds';
import SortingContextReader from '../../app/components/sorting-context-reader';
import { ROUTES, COGNITO_DISTRIBUTION_CENTER } from '../../constants';
import { getSortingContext } from '../../api-rest';
import handleRestAPIError from '../../app/utils/rest-api-request';
import getUserType from '../../app/access-control/access-control-service';
import { ReactComponent as EmptyList } from '../../assets/images/empty-dc-list.svg';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import {
  getContextsOnStorageWithTtl,
  setContextsOnStorageWithTtl
} from './store-contexts-with-ttl';
import SharedPropTypes from '../../app/shared-prop-types';

export const RecentSortingContexts = ({
  recentSortingContexts,
  handleSelectSortingContext,
  sortingContext
}) => {
  return (
    <>
      <Box flex="1" overflowY="auto">
        <Box align="center" pb={2} pt={2}>
          {!recentSortingContexts.length ? (
            <EmptyList style={{ height: pxToRem(100) }} />
          ) : (
            <List>
              {recentSortingContexts.map(context => (
                <ListItem
                  key={context.licensePlate}
                  divider
                  button
                  onClick={() => handleSelectSortingContext(context)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" noWrap>
                        <Box
                          data-testid={`${context.licensePlate}-list-item`}
                          fontWeight="fontWeightMedium"
                        >
                          {context.licensePlate}
                        </Box>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {context.name}
                      </Typography>
                    }
                  />
                  {sortingContext?.licensePlate === context.licensePlate && (
                    <ListItemSecondaryAction>
                      <CheckCircle style={{ color: colors.green[500] }} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>

      {!recentSortingContexts.length && (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          variant="body2"
          fontWeight="fontWeightRegular"
          color={colors.smoke[700]}
          pb={9}
        >
          Nenhuma estação recente.
        </Box>
      )}
    </>
  );
};

RecentSortingContexts.defaultProps = {
  sortingContext: null
};

RecentSortingContexts.propTypes = {
  handleSelectSortingContext: PropTypes.func.isRequired,
  recentSortingContexts: PropTypes.arrayOf(SharedPropTypes.sortingContext)
    .isRequired,
  sortingContext: SharedPropTypes.sortingContext
};

const SortingContextSelectMenuContent = ({
  handleClose,
  setOpenSelectContext,
  setSortingContext,
  onSortingContextRead,
  recentSortingContexts,
  sortingContext
}) => {
  const handleReadSortingContext = async sortingContextResponse => {
    const newSortingContext = {
      name: sortingContextResponse.name,
      licensePlate: sortingContextResponse.licensePlate,
      recommendationLicensePlate:
        sortingContextResponse.metadata
          ?.sortingContextRecommendationLicensePlate
    };
    setSortingContext(newSortingContext);
    onSortingContextRead(newSortingContext);

    setOpenSelectContext(false);
  };

  const handleSelectSortingContext = selectedSortingContext => {
    setSortingContext(selectedSortingContext);
    setOpenSelectContext(false);
  };

  return (
    <Box
      pb={2}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      maxHeight="85vh"
      width="100%"
    >
      <Box height="25px" mb={1}>
        <List>
          <ListSubheader disableSticky>
            <Box
              variant="body2"
              fontWeight="fontWeightMedium"
              color={colors.smoke[900]}
            >
              Escolha a estação que você vai organizar
            </Box>
          </ListSubheader>
        </List>
      </Box>

      <Container maxWidth="xs" disableGutters>
        <Box px={2} pb={2} pt={1.5} data-testid="sorting-context-reader">
          <SortingContextReader
            onRead={handleReadSortingContext}
            placeholder="Bipe o código de operação"
          />
        </Box>
      </Container>

      <RecentSortingContexts
        handleSelectSortingContext={handleSelectSortingContext}
        recentSortingContexts={recentSortingContexts}
        sortingContext={sortingContext}
      />

      <Box
        color={colors.blue[500]}
        display="flex"
        justifyContent="center"
        width="100%"
        clone
        variant="body2"
        fontWeight="fontWeightMedium"
      >
        <ButtonBase
          alignitems="center"
          data-testid="cancel-button"
          variant="button"
          onClick={handleClose}
        >
          Cancelar
        </ButtonBase>
      </Box>
    </Box>
  );
};

SortingContextSelectMenuContent.defaultProps = {
  sortingContext: null
};

SortingContextSelectMenuContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setOpenSelectContext: PropTypes.func.isRequired,
  setSortingContext: PropTypes.func.isRequired,
  onSortingContextRead: PropTypes.func.isRequired,
  recentSortingContexts: PropTypes.arrayOf(SharedPropTypes.sortingContext)
    .isRequired,
  sortingContext: SharedPropTypes.sortingContext
};

export const SortingContextSelectMenu = ({
  open,
  handleClose,
  setOpenSelectContext,
  onSortingContextRead,
  setSortingContext,
  recentSortingContexts,
  sortingContext
}) => {
  const menuRef = useRef(null);

  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          data-testid="context-select"
          anchor="bottom"
          open={open}
          onOpen={() => null}
          onClose={handleClose}
          PaperProps={{
            style: { borderRadius: '16px 16px 0px 0px' }
          }}
        >
          <Box display="flex" p={2}>
            <SortingContextSelectMenuContent
              onSortingContextRead={onSortingContextRead}
              setOpenSelectContext={setOpenSelectContext}
              setSortingContext={setSortingContext}
              handleClose={handleClose}
              recentSortingContexts={recentSortingContexts}
              sortingContext={sortingContext}
            />
          </Box>
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Popover
          data-testid="context-select"
          anchorEl={menuRef.current}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          elevation={24}
          marginThreshold={65}
          onClose={handleClose}
          open={open}
          transformOrigin={{ horizontal: 155 }}
          PaperProps={{ component: Box, mt: 1 }}
        >
          <Box display="flex" p={2}>
            <SortingContextSelectMenuContent
              onSortingContextRead={onSortingContextRead}
              setOpenSelectContext={setOpenSelectContext}
              setSortingContext={setSortingContext}
              handleClose={handleClose}
              recentSortingContexts={recentSortingContexts}
              sortingContext={sortingContext}
            />
          </Box>
        </Popover>
      </Hidden>
    </>
  );
};

SortingContextSelectMenu.defaultProps = {
  sortingContext: null
};

SortingContextSelectMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setOpenSelectContext: PropTypes.func.isRequired,
  onSortingContextRead: PropTypes.func.isRequired,
  setSortingContext: PropTypes.func.isRequired,
  recentSortingContexts: PropTypes.arrayOf(SharedPropTypes.sortingContext)
    .isRequired,
  sortingContext: SharedPropTypes.sortingContext
};

const loadLeveSortingContext = async (
  selectedDistributionCenter,
  setSortingContext,
  handleError,
  redirectToHome
) => {
  const baseErrorHandler = message => {
    handleError(`Erro ao identificar contexto: ${message}`);
    redirectToHome();
  };

  const loadSortingContext = async sortingContextLpn => {
    const response = await getSortingContext(sortingContextLpn);

    if (!response?.data?.sortingContext) {
      baseErrorHandler(`Contexto "${sortingContextLpn}" não encontrado`);
      return;
    }

    const sortingCtx = response.data.sortingContext;
    setSortingContext({
      name: sortingCtx.name,
      licensePlate: sortingCtx.licensePlate,
      recommendationLicensePlate:
        sortingCtx.metadata?.sortingContextRecommendationLicensePlate
    });
  };

  try {
    await loadSortingContext(`${selectedDistributionCenter.routingCode} SEP`);
  } catch (err) {
    handleRestAPIError(err, errorMsg => baseErrorHandler(errorMsg));
  }
};

export const SortingContextSelectButton = ({
  sortingContext,
  openSelectContext,
  setOpenSelectContext,
  isDisabled
}) => {
  const menuRef = useRef(null);

  const handleButtonChange = () => {
    setOpenSelectContext(!openSelectContext);
  };

  return (
    <Box display="flex">
      <ToggleButton
        disabled={isDisabled}
        data-testid="select-context-btn-id"
        onChange={handleButtonChange}
        ref={menuRef}
        selected={openSelectContext}
        variant="outlined"
        value="selected"
        style={{
          borderRadius: '50px',
          borderColor: colors.smoke[700],
          backgroundColor: 'white',
          padding: 12
        }}
      >
        <Typography component="span" variant="body2">
          <Box
            fontWeight="fontWeightBold"
            lineHeight={1}
            color={colors.smoke[900]}
            data-testid="selected-context-lpn"
          >
            {sortingContext?.licensePlate
              ? sortingContext?.licensePlate
              : 'ESTAÇÃO'}
          </Box>
        </Typography>
      </ToggleButton>
    </Box>
  );
};

SortingContextSelectButton.defaultProps = {
  sortingContext: null
};

SortingContextSelectButton.propTypes = {
  sortingContext: SharedPropTypes.sortingContext,
  openSelectContext: PropTypes.bool.isRequired,
  setOpenSelectContext: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

export const SortingContextSelectContainer = ({
  openSelectContext,
  setOpenSelectContext,
  redirectToHome,
  isDisabled
}) => {
  const { sortingContext, setSortingContext } = useContext(
    OrganizeProcessContext
  );
  const [recentSortingContexts, setRecentSortingContexts] = useState([]);

  useEffect(() => {
    const recentContextsFromStorage = getContextsOnStorageWithTtl();

    setRecentSortingContexts([...recentContextsFromStorage]);
  }, []);

  const handleClose = () => {
    if (!sortingContext) {
      redirectToHome();
      return;
    }

    setOpenSelectContext(false);
  };

  const handleSortingContextRead = newSortingContext => {
    const ttl = 12 * 60 * 60 * 1000; // 12 hours
    setContextsOnStorageWithTtl([newSortingContext], ttl);

    const recentContextsFromStorage = getContextsOnStorageWithTtl();
    setRecentSortingContexts(recentContextsFromStorage);
  };

  return (
    <>
      <SortingContextSelectButton
        sortingContext={sortingContext}
        openSelectContext={openSelectContext}
        setOpenSelectContext={setOpenSelectContext}
        isDisabled={isDisabled}
      />

      <SortingContextSelectMenu
        open={openSelectContext || !sortingContext}
        handleClose={handleClose}
        setOpenSelectContext={setOpenSelectContext}
        setSortingContext={setSortingContext}
        onSortingContextRead={handleSortingContextRead}
        recentSortingContexts={recentSortingContexts}
        sortingContext={sortingContext}
      />
    </>
  );
};

SortingContextSelectContainer.propTypes = {
  openSelectContext: PropTypes.bool.isRequired,
  setOpenSelectContext: PropTypes.func.isRequired,
  redirectToHome: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

export const SortingContextSelect = ({
  openSelectContext,
  setOpenSelectContext,
  isDisabled
}) => {
  const { setSortingContext } = useContext(OrganizeProcessContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleError = message => {
    showSnackbarAlert(message, 'error');
    playErrorBeep();
  };

  const redirectToHome = () => history.replace(ROUTES.HOME);

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  useEffect(() => {
    (async function initialLoad() {
      if (isUserLeve) {
        await loadLeveSortingContext(
          selectedDistributionCenter,
          setSortingContext,
          handleError,
          redirectToHome
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLeve]);

  return (
    <>
      {!isUserLeve && (
        <SortingContextSelectContainer
          openSelectContext={openSelectContext}
          setOpenSelectContext={setOpenSelectContext}
          redirectToHome={redirectToHome}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

SortingContextSelect.defaultProps = {
  openSelectContext: false,
  setOpenSelectContext: null,
  isDisabled: false
};

SortingContextSelect.propTypes = {
  openSelectContext: PropTypes.bool,
  setOpenSelectContext: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default SortingContextSelect;
