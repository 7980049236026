import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Box, Container, Dialog, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import { OrganizeProcessContext } from './organize-process-context';
import { BarcodeReader } from '../../app/components/barcode-readers';
import HeaderWithReturn from '../../app/components/header-with-return';
import pluralize from '../../app/utils/pluralize';
import SelectableButton from '../../app/components/selectable-button';
import { useFeature } from '../../app/hooks/use-feature';
import { SWITCHES } from '../../constants';

export const DESTINATION_UNIT_LOAD_TYPE = {
  POSITION: 'POSITION',
  SEAL: 'SEAL'
};

export default function DestinationUnitLoadDialog({
  open,
  onRead,
  onCancel,
  loading
}) {
  const [selectedOption, setSelectedOption] = useState(
    DESTINATION_UNIT_LOAD_TYPE.SEAL
  );
  const { unitInfo } = useContext(OrganizeProcessContext);

  const enableSelectSealOrPosition = useFeature(
    SWITCHES.enableSelectSealOrPosition
  );

  const handleRead = destinationLicensePlate => {
    onRead(destinationLicensePlate, selectedOption);
  };

  return (
    <Box padding={0} clone>
      <Dialog
        open={open}
        fullScreen
        data-testid="destination-unit-load"
        PaperProps={{
          style: {
            backgroundColor: colors.root[0],
            boxShadow: 'none'
          }
        }}
      >
        <HeaderWithReturn
          title="Organizar"
          onReturn={onCancel}
          bgcolor={colors.root[0]}
        />
        <Box overflow="hidden" clone>
          <Container maxWidth="xs">
            <Box
              pt={8}
              display="flex"
              flexDirection="column"
              overflow="hidden"
              height="100vh"
            >
              {enableSelectSealOrPosition && (
                <Box mt={1} mb={1.5}>
                  <Box>
                    <Typography variant="subtitle2">
                      Para onde você vai mover?
                    </Typography>
                  </Box>
                  <Box pt={2} display="flex" flexDirection="row">
                    <Box mr={2}>
                      <SelectableButton
                        dataTestId="seal-action-unit-load"
                        label="Lacre"
                        isSelected={
                          selectedOption === DESTINATION_UNIT_LOAD_TYPE.SEAL
                        }
                        onClick={() =>
                          setSelectedOption(DESTINATION_UNIT_LOAD_TYPE.SEAL)
                        }
                      />
                    </Box>
                    <Box mr={0}>
                      <SelectableButton
                        dataTestId="position-action-unit-load"
                        label="Posição"
                        isSelected={
                          selectedOption === DESTINATION_UNIT_LOAD_TYPE.POSITION
                        }
                        onClick={() =>
                          setSelectedOption(DESTINATION_UNIT_LOAD_TYPE.POSITION)
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box mt={1.5} flex={1}>
                <BarcodeReader
                  onRead={handleRead}
                  loading={loading}
                  placeholder="Bipe a nova unidade"
                />
              </Box>
              <Box>
                <Typography
                  data-testid="destination-unit-load-packages-total"
                  style={{ fontSize: pxToRem(86) }}
                >
                  {unitInfo?.childrenUnitLoad?.length}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant="subtitle1"
                  data-testid="destination-unit-load-source-ul"
                >
                  {`${pluralize({
                    singular: 'unidade',
                    count: unitInfo?.childrenUnitLoad?.length
                  })} de ${unitInfo?.unitLoad?.licensePlate}`}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Dialog>
    </Box>
  );
}

DestinationUnitLoadDialog.defaultProps = {
  open: true,
  loading: false
};

DestinationUnitLoadDialog.propTypes = {
  open: PropTypes.bool,
  onRead: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
