import { makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorBoundaryContext from './error-boundary-context';
import FullscreenFallback from './fullscreen-fallback.component';

const useAppStyles = makeStyles({ '@global': { body: { margin: 0 } } });

const AppErrorBoundary = ({ beforeCapture, children }) => {
  useAppStyles();

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={props => (
        <ErrorBoundaryContext.Provider value={props}>
          <FullscreenFallback />
        </ErrorBoundaryContext.Provider>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

AppErrorBoundary.propTypes = {
  beforeCapture: PropTypes.func,
  children: PropTypes.node.isRequired
};

AppErrorBoundary.defaultProps = {
  beforeCapture: () => {}
};

export default AppErrorBoundary;
