import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SharedPropTypes from '../shared-prop-types';
import StatusColorDescription from './status-color-description';

const useDisambiguatePackageDialogStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      height: '96%',
      maxHeight: '100%'
    }
  }
}));

const DisambiguatePackageDialog = ({
  open,
  packages,
  onSelectPackage,
  onCancel
}) => {
  const { paper } = useDisambiguatePackageDialogStyles();

  return (
    <Dialog open={open} classes={{ paper }} data-testid="disambiguate-package">
      <DialogTitle>
        <Box pt={4} style={{ overflowWrap: 'break-word' }}>
          <Typography variant="h5">
            {`Opa, existe mais de um pacote com esse código  ${
              packages[0].identifier
            }`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={1} display="flex" flexDirection="column" height="100%">
          <Typography variant="body2">
            <Box component="span" fontWeight="fontWeightBold">
              Selecione o pacote que está com você
            </Box>
          </Typography>
          <Box>
            <PackageList
              packages={packages}
              onSelectPackage={onSelectPackage}
            />
          </Box>
        </Box>
      </DialogContent>
      <Box mb={1.5} clone>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={onCancel}
            data-testid="cancel-disambiguate"
          >
            Não encontrei o pacote
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DisambiguatePackageDialog.defaultProps = {
  open: true
};

DisambiguatePackageDialog.propTypes = {
  open: PropTypes.bool,
  onSelectPackage: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  packages: SharedPropTypes.packages.isRequired
};

const PackageList = ({ packages, onSelectPackage }) => {
  return (
    <Box>
      <List disablePadding>
        {packages.map(pkg => (
          <PackageItem
            pkg={pkg}
            onSelect={() => onSelectPackage(pkg)}
            key={`${pkg.identifier}-${pkg.company.id}-${pkg.recipient.name}`}
          />
        ))}
      </List>
    </Box>
  );
};

PackageList.propTypes = {
  packages: SharedPropTypes.packages.isRequired,
  onSelectPackage: PropTypes.func.isRequired
};

const PackageItem = ({ pkg, onSelect }) => {
  return (
    <Box>
      <ListItem
        button
        data-testid={`disambiguate-package-${pkg.identifier}`}
        disableGutters
        onClick={onSelect}
        divider
      >
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box flex={1} minWidth={0}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box>
                <StatusColorDescription pkgStatus={pkg?.status} />
                <Box
                  pb={0.5}
                  style={{ overflowWrap: 'break-word' }}
                  data-testid="package-company"
                >
                  <Typography variant="body1">
                    {`Pacote de ${pkg.company?.sharedName ||
                      pkg.company?.name}`}
                  </Typography>
                </Box>
                <Box
                  pb={1}
                  color={colors.smoke[700]}
                  data-testid="package-detail"
                >
                  <Typography variant="subtitle2">
                    {`para ${pkg.recipient.name}`}
                  </Typography>
                  {pkg.item && pkg.totalItems && (
                    <Typography variant="subtitle2">
                      {`volume ${pkg.item}/${pkg.totalItems}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" edge="end">
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </Box>
  );
};

PackageItem.propTypes = {
  pkg: SharedPropTypes.package.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default DisambiguatePackageDialog;
