import React, { useContext } from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import HeaderWithReturn from '../../app/components/header-with-return';
import IdentifyPerson from '../../app/components/identify-person';
import { DistributeProcessContext } from './distribute-process-context';

export default function IdentifyReceiverScreen() {
  const { setToInitialState, name, setName, cpf, setCpf } = useContext(
    DistributeProcessContext
  );

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleCpfChange = event => {
    setCpf(event.target.value);
  };

  return (
    <Box>
      <HeaderWithReturn title="Distribuir" onReturn={setToInitialState} />
      <Box
        flexFlow="column"
        overflow="hidden"
        data-testid="receiver-identification"
      >
        <Container maxWidth="xs" disableGutters>
          <Box
            pt={8}
            display="flex"
            flexDirection="column"
            height="100vh"
            overflow="hidden"
          >
            <Box display="flex" paddingLeft={3} mt={3}>
              <Typography variant="h5">Identifique a pessoa</Typography>
            </Box>
            <Box flex="1" width="100%" height={1} mt={3} pb={1} pl={3} pr={3}>
              <IdentifyPerson
                name={name}
                cpf={cpf}
                onNameChange={handleNameChange}
                onCpfChange={handleCpfChange}
              />
            </Box>
            <Box alignItems="center" p={3} mt={1}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                Continuar
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
