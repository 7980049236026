import { createContext } from 'react';

export const defaultValue = {
  coverageParams: {},
  /* eslint-disable no-empty-function */
  setCoverageParams: () => undefined
};

const CoverageParamsContext = createContext(defaultValue);

export default CoverageParamsContext;
