import { colors } from '../principles';

const MuiOutlinedInput = {
  root: {
    backgroundColor: colors.root[0],
    '&$error': {
      backgroundColor: colors.root[0],
      color: colors.smoke[900],
      '& $notchedOutline': {
        borderColor: colors.red[500]
      }
    },
    '&:hover $notchedOutline': {
      borderColor: colors.smoke[800],
      color: colors.red[900]
    },
    '&$disabled $notchedOutline': {
      borderColor: colors.smoke[300]
    },
    '&$focused $notchedOutline': {
      borderColor: colors.blue[500]
    }
  },
  notchedOutline: {
    borderColor: colors.smoke[500]
  }
};

export default MuiOutlinedInput;
