import { COGNITO_IU } from './constants';

/**
 * Retrieve Cognito impersonator user session to be able to add
 * impersonation headers and make grant requests with access tokens
 * @return {string}
 */
const getImpersonatorToken = (localStorageKey = COGNITO_IU) => {
  const response = localStorage.getItem(localStorageKey);
  const idToken = response ? JSON.parse(response).idToken : null;
  return idToken;
};

export default getImpersonatorToken;
