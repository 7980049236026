import { Avatar, Box, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { ToggleButton } from '@material-ui/lab';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { colors } from '@loggi/mar';
import UserInfoMenu from './user-info-menu';
import initials from '../utils/name-initials';

const useStyles = makeStyles(({ typography }) => ({
  profileButton: {
    borderRadius: '50px',
    paddingLeft: typography.pxToRem(0),
    paddingRight: typography.pxToRem(0),
    paddingBottom: typography.pxToRem(0),
    paddingTop: typography.pxToRem(0),
    borderColor: colors.smoke[700],
    backgroundColor: 'transparent',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    }
  }
}));

const useStylesAvatar = makeStyles(() => ({
  initialsClosed: {
    font: 'Open Sans',
    color: colors.smoke[700],
    fontSize: 14
  },
  initialsOpen: {
    font: 'Open Sans',
    color: colors.root[0],
    fontSize: 14
  },
  pictureClosed: {
    backgroundColor: colors.root[0]
  },
  pictureOpen: {
    backgroundColor: colors.smoke[800]
  }
}));

const UserInfoButton = () => {
  const styles = useStyles();
  const {
    initialsClosed,
    initialsOpen,
    pictureClosed,
    pictureOpen
  } = useStylesAvatar();

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const menuRef = useRef(null);
  const avatarLetters = authenticatedUser?.name
    ? initials(authenticatedUser?.name)
    : undefined;

  return (
    <>
      {avatarLetters && (
        <ToggleButton
          className={styles.profileButton}
          data-testid="profile-menu-btn-id"
          onChange={() => setIsPopoverOpen(!isPopoverOpen)}
          ref={menuRef}
          selected={isPopoverOpen}
          size="small"
          variant="outlined"
          value=""
        >
          <Avatar
            className={isPopoverOpen ? pictureOpen : pictureClosed}
            data-testid="profile-avatar-id"
          >
            <Box
              fontWeight="fontWeightBold"
              component="strong"
              className={isPopoverOpen ? initialsOpen : initialsClosed}
            >
              {avatarLetters}
            </Box>
          </Avatar>
        </ToggleButton>
      )}
      <UserInfoMenu
        anchorElement={menuRef.current}
        handleClose={() => setIsPopoverOpen(false)}
        open={isPopoverOpen}
      />
    </>
  );
};

export default UserInfoButton;
