import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Dialog, Container, Button } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import SharedPropTypes from '../../app/shared-prop-types';
import useWindowDimensions from '../../app/hooks/use-window-dimensions';
import Confirmation from '../../app/components/confirmation';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import HeaderWithClose from '../../app/components/header-with-close';
import BasicChip from '../../app/components/basic-chip';

const useStyles = makeStyles({
  dialog: {
    padding: 0
  }
});

const PreviewLabelDialog = ({
  open,
  pkgToPrepareInfo,
  onReturn,
  onRemovePackages
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { innerWidth } = useWindowDimensions();

  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const getTitleText = () => {
    return `Você quer remover o pacote de <b>${
      pkgToPrepareInfo.pkg?.recipient?.name
    } - ${pkgToPrepareInfo.pkg?.identifier}</b> da sua lista?`;
  };

  const handleConfirmRemove = () => {
    onRemovePackages([pkgToPrepareInfo.pkg.identifier]);
    setOpenConfirmation(false);
    showSnackbarAlert('Boa! Pacotes removidos da lista.');
    onReturn();
  };

  const handleRemove = () => {
    setOpenConfirmation(true);
  };

  const handleCancelRemove = () => {
    setOpenConfirmation(false);
  };

  const getLabelBase64 = () => {
    return `data:application/pdf;base64,${
      pkgToPrepareInfo.mainLabel.base64Pdf
    }`;
  };

  return (
    <Dialog
      open={open}
      fullScreen
      data-testid="preview-label-dialog"
      PaperProps={{
        style: {
          backgroundColor: colors.smoke[50],
          boxShadow: 'none'
        }
      }}
      className={classes.dialog}
    >
      <Box overflow="hidden" clone>
        <Container maxWidth="xs">
          <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            height="100vh"
          >
            <Box>
              <HeaderWithClose testId="close-button" handleClose={onReturn} />
            </Box>
            <Box
              flex={1}
              overflow="auto"
              style={{ overflowWrap: 'break-word' }}
            >
              <Typography variant="subtitle1">
                <Box component="span" fontWeight="fontWeightMedium">
                  {pkgToPrepareInfo.pkg.identifier}
                </Box>
              </Typography>
              <Box
                pt={1.5}
                pb={pkgToPrepareInfo.mainLabel.base64Pdf ? 4 : 1.5}
                color={colors.smoke[700]}
              >
                <Typography variant="subtitle2">
                  {`Pacote de ${pkgToPrepareInfo.pkg.company?.sharedName ||
                    pkgToPrepareInfo.pkg.company?.name}`}
                </Typography>
                <Typography variant="subtitle2">
                  {` para ${pkgToPrepareInfo.pkg.recipient.name}`}
                </Typography>
              </Box>
              {!pkgToPrepareInfo.mainLabel.base64Pdf && (
                <Box data-testid="invalid-chip">
                  <BasicChip message={pkgToPrepareInfo.mainLabel.missingText} />
                </Box>
              )}
              {pkgToPrepareInfo.mainLabel.base64Pdf && (
                <Box>
                  <Box>
                    <Document
                      file={getLabelBase64()}
                      data-testid="label-pdf"
                      loading={
                        <>
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height="50vh"
                          />
                        </>
                      }
                    >
                      <Page
                        pageNumber={1}
                        width={Math.min(innerWidth * 0.85, 355)}
                        loading={
                          <>
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              height="50vh"
                            />
                          </>
                        }
                      />
                    </Document>
                  </Box>
                </Box>
              )}
            </Box>
            <Box mt={1.5} mb={{ xs: 3, sm: 2.5 }}>
              <Button
                data-testid="remove-packages"
                onClick={handleRemove}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
              >
                Remover da lista
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      {openConfirmation && (
        <Confirmation
          open
          subtitleText={getTitleText()}
          onConfirm={handleConfirmRemove}
          confirmText="Confirmar"
          onCancel={handleCancelRemove}
          cancelText="Manter na lista"
          zIndex={2}
        />
      )}
    </Dialog>
  );
};

PreviewLabelDialog.defaultProps = {
  open: true
};

PreviewLabelDialog.propTypes = {
  open: PropTypes.bool,
  pkgToPrepareInfo: SharedPropTypes.pkgToPrepareInfo.isRequired,
  onReturn: PropTypes.func.isRequired,
  onRemovePackages: PropTypes.func.isRequired
};

export default PreviewLabelDialog;
