import { useCallback, useState, useEffect } from 'react';

const getDimensions = () => {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth
  };
};

/**
 * Returns the dimensions of the window in pixels.
 *
 * @returns {Object} Dimensions of the window in pixels
 */
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getDimensions());

  const getWindowDimensions = useCallback(
    () => setWindowDimensions(getDimensions()),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', getWindowDimensions);
    return () => {
      window.removeEventListener('resize', getWindowDimensions);
    };
  }, [getWindowDimensions]);

  return windowDimensions;
}
