import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import { Box, Typography, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  HISTORY_ACTIONS,
  ROUTES,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  REJECT
} from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import HeaderWithReturn from '../../app/components/header-with-return';
import PaperBox from '../../app/components/paper-box';
import { OrganizeProcessContext } from './organize-process-context';
import UnitReader from './unit-reader';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import { CONFIRMATION_TYPE, UNIT_TYPE } from '../../app/enums';
import StorageReader from './storage-reader';
import { SortingContextSelect } from './sorting-context-select';

export default function OrganizePackage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { unitInfo, setUnitInfo, setUnitType } = useContext(
    OrganizeProcessContext
  );
  const { trackEnd } = useContext(ActivityTrackingContext);
  const [loading, setLoading] = useState(false);

  const shouldStorePackage =
    unitInfo?.decision?.confirmationType ===
    CONFIRMATION_TYPE.UNIT_LOAD_LICENSE_PLATE;

  const goBack = () =>
    history.push({ pathname: ROUTES.ORGANIZE.INITIAL_IDENTIFICATION });

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleUnitRead = ({ type, info }) => {
    setUnitInfo(info);
    setUnitType(type);
    playSuccessBeep();
    if (type === UNIT_TYPE.UNIT_TYPE_UNIT_LOAD) {
      history.push({ pathname: ROUTES.ORGANIZE.ORGANIZE_UNIT_LOAD });
    }
  };

  const handleStorageRead = message => {
    showSnackbarAlert(message);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    history.push({ pathname: ROUTES.ORGANIZE.INITIAL_IDENTIFICATION });
  };

  const handleError = message => {
    showSnackbarAlert(message, 'error');
    playErrorBeep();
  };

  const hasDecision = !!unitInfo?.decision?.name;

  if (!hasDecision) {
    playErrorBeep();
  }

  useEffect(() => {
    // prevent page from being accessed by editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      history.replace(ROUTES.HOME);
    }
  }, [history]);

  return (
    <Box
      bgcolor={colors.root[0]}
      height="100vh"
      flexDirection="column"
      overflow="hidden"
      display="flex"
      data-testid="organize-package"
    >
      <HeaderWithReturn
        onReturn={goBack}
        title="Organizar"
        dataTestId="cancel-button-header"
        bgcolor={colors.root[0]}
      >
        <SortingContextSelect isDisabled />
      </HeaderWithReturn>
      <Container maxWidth="xs">
        <Box pt={8} display="flex" flexDirection="column" overflow="hidden">
          {shouldStorePackage ? (
            <StorageReader
              onSuccessCallback={handleStorageRead}
              onErrorCallback={handleError}
              loading={loading}
              setLoading={setLoading}
            />
          ) : (
            <UnitReader
              onSuccessCallback={handleUnitRead}
              onErrorCallback={handleError}
              loading={loading}
              setLoading={setLoading}
              organizeUnitInfo={unitInfo}
              organizeUnitType={UNIT_TYPE.UNIT_TYPE_PACKAGE}
            />
          )}
        </Box>
      </Container>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Container maxWidth="xs" disableGutters>
          {loading && (
            <Box px={{ xs: pxToRem(28), sm: pxToRem(44) }} mb={3}>
              <Box mb={0.5}>
                <Skeleton variant="text" height={120} />
              </Box>
              <Box mb={1}>
                <Skeleton variant="text" height={40} />
              </Box>
              <Box>
                <Skeleton variant="text" height={40} />
              </Box>
              <Box mt={2}>
                <Skeleton variant="rect" height={130} />
              </Box>
            </Box>
          )}
          {!loading && (
            <>
              <Box px={{ xs: pxToRem(28), sm: pxToRem(44) }} mb={3}>
                {!hasDecision ? (
                  <Typography style={{ fontSize: pxToRem(86) }}>
                    <Box
                      dataTestId="rej-decision"
                      fontWeight="fontWeightRegular"
                      component="span"
                    >
                      {REJECT}
                    </Box>
                  </Typography>
                ) : (
                  <>
                    <Typography style={{ fontSize: pxToRem(86) }}>
                      <Box fontWeight="fontWeightRegular" component="span">
                        {unitInfo?.decision?.name}
                      </Box>
                    </Typography>
                  </>
                )}
                {unitInfo?.recommendationDecision && (
                  <>
                    <Typography variant="h4">
                      <Box fontWeight="fontWeightRegular" mb={2}>
                        {unitInfo?.recommendationDecision?.name}
                      </Box>
                    </Typography>
                    <Typography variant="subtitle1">
                      <Box>{unitInfo?.decision?.demandRegionLabel}</Box>
                    </Typography>
                    <Typography variant="subtitle1">
                      <Box>
                        {
                          unitInfo?.decision?.destination?.expeditionCenter
                            ?.routingCode
                        }
                      </Box>
                    </Typography>
                  </>
                )}
              </Box>
              <PaperBox>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ overflowWrap: 'break-word' }}
                  px={{ xs: pxToRem(28), sm: pxToRem(44) }}
                  py={4}
                >
                  <Box pb={1.5}>
                    <Typography variant="subtitle1">
                      <Box fontWeight="fontWeightMedium">
                        {unitInfo?.pkg?.identifier}
                      </Box>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      component="div"
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      {`Pacote de ${unitInfo?.pkg?.company?.sharedName ||
                        unitInfo?.pkg?.company?.name}`}
                    </Typography>
                  </Box>
                  <Box pt={0.5}>
                    <Typography
                      component="div"
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      {`para ${unitInfo?.pkg?.recipient?.name}`}
                    </Typography>
                  </Box>
                </Box>
              </PaperBox>
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
}
