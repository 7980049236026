import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Typography } from '@material-ui/core';

import showSnackbar from '../../app/components/snackbar/snackbar-container';
import HeaderWithClose from '../../app/components/header-with-close';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { DistributeProcessContext } from './distribute-process-context';
import { cancelDistribution } from '../../api-rest';
import {
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  CANCEL_DISTRIBUTION_SUBTITLE,
  DistributionUnitLoadAlreadyStarted,
  ROUTES,
  HTTP_STATUS_CODES
} from '../../constants';

const CancelDistributionScreen = () => {
  const { totalCargosBeeped, distributionUnitLoadId, clearData } = useContext(
    DistributeProcessContext
  );

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const closeCancelScreen = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE.SELECT_POSSIBLE_DESTINATIONS
    });
  };

  const goToInitialIdentificationScreen = () => {
    clearData();
    history.push({
      pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
    });
  };

  const cancelTrip = async () => {
    const response = await cancelDistribution({
      distributionUnitLoadId,
      routingCode: selectedDistributionCenter.routingCode
    }).catch(error => {
      if (error.response?.data?.type === DistributionUnitLoadAlreadyStarted) {
        goToInitialIdentificationScreen();
      }
      showSnackbar({
        variant: 'warning',
        message: BASE_TRIP_TRANSFER_ERROR_MSGS[error.response.data.type],
        showCloseButton: true,
        enqueueSnackbar
      });
    });

    if (response && response.status === HTTP_STATUS_CODES.OK) {
      showSnackbar({
        variant: 'success',
        message: 'Pronto, tudo certo.\n O processo foi cancelado.',
        showCloseButton: true,
        enqueueSnackbar
      });
      goToInitialIdentificationScreen();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100vh"
      padding={2}
      style={{ background: 'white' }}
      data-testid="cancel-distribution-screen"
    >
      <HeaderWithClose
        testId="close-cancel-screen"
        handleClose={closeCancelScreen}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        mt={2}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box mb={2}>
            <Typography variant="h5">
              Deseja salvar na transferência as{' '}
              <b>{totalCargosBeeped} unidades bipadas</b>?
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">
              {CANCEL_DISTRIBUTION_SUBTITLE}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box m={1}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={goToInitialIdentificationScreen}
              data-testid="save-distribution-button"
            >
              Salvar
            </Button>
          </Box>
          <Box m={1}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              onClick={cancelTrip}
              data-testid="discard-distribution-button"
            >
              Descartar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CancelDistributionScreen;
