import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from '@material-ui/icons';
import { colors } from '@loggi/mar';

const DestinationList = ({ destinationList, selectDestinations }) => {
  return (
    <List>
      {destinationList.map(destination => (
        <ListItem
          button
          divider
          dense
          disableGutters
          data-testid="list-destinations"
          key={destination.destinationRoutingCode}
          onClick={() => selectDestinations(destination)}
        >
          <Box mb={1} my={1.5} pl={3}>
            <ListItemText
              key={`${destination.destinationRoutingCode} text`}
              primary={
                <Box fontWeight="fontWeightBold">
                  {destination.destinationRoutingCode}
                </Box>
              }
              secondary={
                <Typography variant="body2" color="textSecondary" noWrap>
                  {destination.destinationName}
                </Typography>
              }
              selected={destination.isSelected}
            />
            {destination.isSelected && (
              <ListItemSecondaryAction>
                <CheckCircle style={{ color: colors.green[500] }} />
              </ListItemSecondaryAction>
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default DestinationList;

DestinationList.propTypes = {
  destinationList: PropTypes.arrayOf(
    PropTypes.shape({
      destinationRoutingCode: PropTypes.string.isRequired,
      destinationName: PropTypes.string.isRequired
    })
  ).isRequired,
  selectDestinations: PropTypes.func.isRequired
};
