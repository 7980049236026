import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MeasurementsField = ({ fieldName, minValue, maxValue, disabled }) => {
  const { t } = useTranslation('one');

  const [field, meta, { setValue }] = useField({
    name: fieldName,
    validate: newValue => {
      if (!newValue)
        return t(`measurementsField.${fieldName}.errorMessages.requiredField`);
      if (newValue && newValue > maxValue)
        return t(`measurementsField.${fieldName}.errorMessages.maxValue`, {
          maxValue
        });
      if (newValue && newValue < minValue)
        return t(`measurementsField.${fieldName}.errorMessages.minValue`, {
          minValue
        });
      return undefined;
    }
  });
  const { name, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  const onChange = ({ target }) => {
    setValue(String(target.value).replace(/\D/g, ''));
  };

  return (
    <TextField
      error={hasError}
      fullWidth
      type="number"
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field`, inputMode: 'numeric' }}
      label={t(`measurementsField.${name}.label`)}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      variant="outlined"
      disabled={disabled}
    />
  );
};

MeasurementsField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

MeasurementsField.defaultProps = {
  disabled: false
};

export default MeasurementsField;
