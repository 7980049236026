import { colors } from '../principles';

const MuiFormLabel = {
  root: {
    '&$error': {
      color: colors.red[500]
    },
    '&$disabled': {
      color: colors.smoke[300]
    },
    '&$focused': {
      color: colors.blue[500]
    }
  }
};

export default MuiFormLabel;
