import moment from 'moment';
import { useContext } from 'react';
import { COVERAGE_PARAMS_LOCALSTORAGE_KEY } from '../constants';
import {
  SHIFT_END,
  SHIFT_START,
  TIME_GAP_BEFORE_SHIFT_END_IN_MINUTES,
  weekDays
} from '../formik-fields/constants';
import CoverageParamsContext from './coverage-params-context';

const DEFAULT_SLOT_TIME = 1;
const DEFAULT_MIN_COLECT_TIME_WINDOW_HOUR = 1;
const DEFAULT_MIN_COLECT_TIME_WINDOW_MINUTES = 30;

const getCoverageParams = contextParams => {
  let coverageParams = contextParams?.coverageParams;
  const hasContextCoverageParams =
    Boolean(coverageParams) && Boolean(Object.keys(coverageParams).length);
  const shippingApp = window.location.pathname.includes('envios');

  if (!hasContextCoverageParams && shippingApp) {
    const storeCoverageParams = localStorage.getItem(
      COVERAGE_PARAMS_LOCALSTORAGE_KEY
    );
    coverageParams = JSON.parse(storeCoverageParams);
  }

  return coverageParams;
};

const isMinCollectTimeWindowZero = param => {
  return (
    param?.minCollectTimeWindow?.hours === 0 &&
    param?.minCollectTimeWindow?.minutes === 0
  );
};

const getSafeInitialSlotHours = params => {
  const isInitialSlotZero =
    params?.initialSlot?.hours === 0 && params?.initialSlot?.minutes === 0;

  return params?.initialSlot?.hours === undefined || isInitialSlotZero
    ? DEFAULT_SLOT_TIME
    : params?.initialSlot?.hours;
};

const useCoverageParamsContext = () => {
  const contextParams = useContext(CoverageParamsContext);
  const coverageParams = getCoverageParams(contextParams);
  const hasPickup = coverageParams?.hasPickup;

  const hasCoverageParamsStartTime =
    coverageParams?.pickupWorkingHours?.openAt?.hours !== undefined &&
    coverageParams?.pickupWorkingHours?.openAt?.minutes !== undefined;
  const hasCoverageParamsEndTime =
    coverageParams?.pickupWorkingHours?.closeAt?.hours !== undefined &&
    coverageParams?.pickupWorkingHours?.closeAt?.minutes !== undefined;

  const safeShiftStartMinute = hasCoverageParamsStartTime
    ? coverageParams?.pickupWorkingHours?.openAt.minutes
    : 0;

  const shiftStartMinute = safeShiftStartMinute.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  const safeShiftStartHour = hasCoverageParamsStartTime
    ? coverageParams?.pickupWorkingHours?.openAt.hours
    : 8;

  const shiftStart = hasCoverageParamsStartTime
    ? `${safeShiftStartHour}:${shiftStartMinute}`
    : SHIFT_START;

  const momentShiftStart = moment(shiftStart, 'HH:mm');

  const safeShiftEndHour = hasCoverageParamsEndTime
    ? coverageParams?.pickupWorkingHours?.closeAt.hours
    : 19;

  const safeShiftEndMinute = hasCoverageParamsEndTime
    ? coverageParams?.pickupWorkingHours?.closeAt.minutes
    : 0;

  const shiftEndMinute = safeShiftEndMinute.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  const shiftEnd = hasCoverageParamsEndTime
    ? `${safeShiftEndHour}:${shiftEndMinute}`
    : SHIFT_END;

  const momentShiftEndWithGap = moment(shiftEnd, 'HH:mm').subtract(
    TIME_GAP_BEFORE_SHIFT_END_IN_MINUTES,
    'minutes'
  );

  const minCollectTimeWindowHours =
    coverageParams?.minCollectTimeWindow?.hours === undefined ||
    isMinCollectTimeWindowZero(coverageParams)
      ? DEFAULT_MIN_COLECT_TIME_WINDOW_HOUR
      : coverageParams?.minCollectTimeWindow?.hours;

  const minCollectTimeWindowMinutes =
    coverageParams?.minCollectTimeWindow?.minutes === undefined
      ? DEFAULT_MIN_COLECT_TIME_WINDOW_MINUTES
      : coverageParams?.minCollectTimeWindow?.minutes;

  const safeInitialSlotHours = getSafeInitialSlotHours(coverageParams);

  const safeInitialSlotMinutes =
    coverageParams?.initialSlot?.minutes === undefined
      ? 0
      : coverageParams?.initialSlot?.minutes;

  const initialSlotTimeMinutes =
    safeInitialSlotHours * 60 + safeInitialSlotMinutes;

  const hasInitialSlotParams =
    coverageParams?.initialSlot?.hours !== undefined &&
    coverageParams?.initialSlot?.minutes !== undefined;

  const shiftInitialSlot = hasInitialSlotParams
    ? `${safeInitialSlotHours}:${safeInitialSlotMinutes.toLocaleString(
        'pt-br',
        {
          minimumIntegerDigits: 2,
          useGrouping: false
        }
      )}`
    : SHIFT_END;

  const shiftEndWithGap = moment(shiftEnd, 'HH:mm')
    .subtract(TIME_GAP_BEFORE_SHIFT_END_IN_MINUTES, 'minutes')
    .format('HH:mm');

  const pickupWorkingWeekdays =
    coverageParams?.pickupWorkingWeekdays !== undefined
      ? coverageParams?.pickupWorkingWeekdays
      : weekDays.slice(1, 6);

  return {
    initialSlotTimeMinutes,
    momentShiftEndWithGap,
    momentShiftStart,
    minCollectTimeWindowHours,
    minCollectTimeWindowMinutes,
    safeInitialSlotHours,
    safeInitialSlotMinutes,
    hasPickup,
    shiftStart,
    shiftEndWithGap,
    shiftInitialSlot,
    pickupWorkingWeekdays
  };
};

export default useCoverageParamsContext;
