import wretch from 'wretch';
import oneAuthMiddleware from './one-auth-middleware';

// Wretch object is immutable which means that you can configure, store and reuse instances.
// This seems like a good place to put things that are common to all requests.
//
// More: https://github.com/elbywan/wretch#because-configuration-should-not-rhyme-with-repetition

/**
 * annotatorApi is a wretch instance for requests to Annotator RestAPI.
 *
 * @return {Object}
 */

const annotatorApi = wretch()
  .url(process.env.REACT_APP_ANNOTATOR_URL)
  .middlewares([oneAuthMiddleware]);

export default annotatorApi;
