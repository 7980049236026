import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  makeStyles
} from '@material-ui/core';

const useInvalidDestinationStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.only('xs')]: {
      height: '96%',
      maxHeight: '100%'
    }
  }
}));

const InvalidDestinationDialog = ({
  onCancel,
  onConfirm,
  title,
  confirmButton,
  cancelButton,
  children,
  hideConfirmButton
}) => {
  const { paper } = useInvalidDestinationStyles();

  return (
    <Dialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      classes={{ paper }}
      data-testid="invalid-destination-dialog"
    >
      <DialogTitle>
        <Box pt={4} mb={1}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Box style={{ overflowWrap: 'break-word' }}>{children}</Box>
        </Box>
      </DialogContent>
      <Box mb={1.5} clone>
        <DialogActions>
          {!hideConfirmButton && (
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={onConfirm}
              data-testid="add-invalid-cargo"
            >
              {confirmButton}
            </Button>
          )}
        </DialogActions>
      </Box>
      <Box mb={1.5} clone>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={onCancel}
            data-testid="cancel-invalid-cargo"
          >
            {cancelButton}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

InvalidDestinationDialog.defaultProps = {
  confirmButton: 'Adicionar',
  cancelButton: 'Cancelar',
  hideConfirmButton: false
};

InvalidDestinationDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  confirmButton: PropTypes.string,
  cancelButton: PropTypes.string,
  hideConfirmButton: PropTypes.bool
};

export default InvalidDestinationDialog;
