import { Box, TextField } from '@material-ui/core';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CpfFormatInput from './cpf-format-input';

const ENTER_EVENT = 'Enter';

export default function IdentifyPerson({
  name,
  cpf,
  onNameChange,
  onCpfChange
}) {
  const cpfFieldRef = useRef(null);

  const handleNameKeyDown = event => {
    if (event.key === ENTER_EVENT && cpfFieldRef?.current) {
      cpfFieldRef.current.focus();
    }
  };

  return (
    <Box>
      <Box justifyContent="center">
        <TextField
          type="text"
          label="Nome"
          variant="outlined"
          autoFocus
          fullWidth
          onKeyDown={handleNameKeyDown}
          value={name}
          onChange={onNameChange}
          inputProps={{
            'data-testid': 'name-text-field'
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Box>
      <Box justifyContent="center" pt={3}>
        <TextField
          label="CPF"
          variant="outlined"
          fullWidth
          value={cpf}
          onChange={onCpfChange}
          inputRef={cpfFieldRef}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            inputComponent: CpfFormatInput
          }}
        />
      </Box>
    </Box>
  );
}

IdentifyPerson.propTypes = {
  name: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onCpfChange: PropTypes.func.isRequired
};
