import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, IconButton, Container } from '@material-ui/core';
import { Adjust } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { playActionCameraClick } from '../../sounds';
import CameraPhoto from './camera-photo';

const useStylesPhotoTemplate = makeStyles(thisTheme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: thisTheme.typography.pxToRem(1)
    }
  },
  iconExtraLarge: {
    fontSize: 60
  },
  height: '25rem',

  imgContainer: {
    objectFit: 'cover'
  }
}));

export default function PhotoTemplate({
  imageData,
  onImageCapture,
  imgAlt,
  cameraHeight,
  withBorderRadius
}) {
  const classes = useStylesPhotoTemplate();
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  let videoSize = { width: 0, height: 0 };

  const handleCameraClick = () => {
    videoSize = {
      width: webcamRef.current.video.clientWidth,
      height: webcamRef.current.video.clientHeight
    };

    imgRef.current.width = videoSize.width;
    imgRef.current.height = videoSize.height;

    const data = webcamRef.current.getScreenshot();
    onImageCapture(data);
    playActionCameraClick();
  };

  return (
    <Container maxWidth="xs" data-testid="camera-photo" disableGutters>
      <Box>
        <Card style={!withBorderRadius ? { borderRadius: 0 } : {}}>
          <Box
            data-testid="container-photo"
            position="relative"
            display="flex"
            alignItems="center"
            height={cameraHeight}
            justifyContent="space-around"
            p={0.05}
          >
            {imageData == null && (
              <Box position="absolute" zIndex="1" center="0" bottom="10px">
                <IconButton
                  data-testid="take-picture"
                  onClick={handleCameraClick}
                >
                  <Adjust
                    color="primary"
                    fontSize="large"
                    className={classes.iconExtraLarge}
                  />
                </IconButton>
              </Box>
            )}

            <CameraPhoto
              data-testid="camera-photo-testid"
              visible={imageData == null}
              videoReference={webcamRef}
            />

            <img
              data-testid="img-photo-testid"
              src={imageData}
              style={{ display: imageData == null ? 'none' : 'block' }}
              width="100%"
              height="100%"
              ref={imgRef}
              className={classes.imgContainer}
              alt={imgAlt}
            />
          </Box>
        </Card>
      </Box>
    </Container>
  );
}

PhotoTemplate.propTypes = {
  onImageCapture: PropTypes.func.isRequired,
  imageData: PropTypes.string,
  imgAlt: PropTypes.string,
  cameraHeight: PropTypes.string,
  withBorderRadius: PropTypes.bool
};

PhotoTemplate.defaultProps = {
  imageData: null,
  imgAlt: '',
  cameraHeight: '25rem',
  withBorderRadius: true
};
