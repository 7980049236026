import { captureException } from '@sentry/browser';
import { Auth } from 'aws-amplify';
import {
  EXPIRED_REFRESH_TOKEN_ERROR_CODE,
  USER_NOT_AUTHENTICATED_ERROR
} from './constants';

/**
 * Retrieve Cognito current user session to be able to add authorization
 * headers and make authenticated requests with access tokens
 * @return {Promise<{accessToken: string | null, idToken: string | null}>}
 *
 * @example
 * {
 *  accessToken: jwtAccessToken,
 *  idToken: jwtIdToken
 * }
 */
const getAuthorizationTokens = async () => {
  let accessToken;
  let idToken;

  try {
    const userSession = await Auth.currentSession();
    accessToken = userSession.accessToken.jwtToken;
    idToken = userSession.idToken.jwtToken;
  } catch (e) {
    if (
      e !== USER_NOT_AUTHENTICATED_ERROR &&
      e?.code !== EXPIRED_REFRESH_TOKEN_ERROR_CODE &&
      !(e instanceof TypeError)
    ) {
      captureException(e);
    }
    accessToken = null;
    idToken = null;
  }

  return { accessToken, idToken };
};

export default getAuthorizationTokens;
