import React, {
  useContext,
  useReducer,
  useMemo,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { useAmplifyAuth } from '@loggi/authentication-lib';
import { cognitoDistributionCenters } from '../../api-rest';

import { DC_REDUCER_TYPE, RECENT_DISTRIBUTION_CENTERS } from '../../constants';
import { dcShape } from '../components/distribution-center-list';

/**
 * loadingDistributionCenter -> this state is used to inform us when the
 * fetching is being performed.
 *
 * selectedDistributionCenter -> this state stores the user's selected DC.
 * Very useful for removing the context beep in all operations.
 *
 * allDistributionCenters -> this state stores all the user's DCs returned from the API.
 * Very useful for the "select DCs" component and to avoid new fetchs.
 */
export const initialState = {
  loadingDistributionCenter: true,
  selectedDistributionCenter: null,
  allDistributionCenters: [],
  recentDistributionCenters: [],
  hasError: false
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case DC_REDUCER_TYPE.START_FETCH_OR_SET_DC: {
      return {
        ...state,
        loadingDistributionCenter: true,
        hasError: false
      };
    }
    case DC_REDUCER_TYPE.SUCCESS_FETCH_DC: {
      return {
        ...state,
        loadingDistributionCenter: false,
        hasError: false,
        allDistributionCenters: payload.allDistributionCenters,
        recentDistributionCenters: payload.recentDistributionCenters,
        selectedDistributionCenter: payload.selectedDistributionCenter
      };
    }
    case DC_REDUCER_TYPE.SUCCESS_SET_DC: {
      return {
        ...state,
        selectedDistributionCenter: payload.selectedDistributionCenter,
        recentDistributionCenters: payload.recentDistributionCenters
      };
    }
    case DC_REDUCER_TYPE.FAILURE_FETCH_DC: {
      return {
        ...initialState,
        loadingDistributionCenter: false,
        hasError: true
      };
    }
    default: {
      return {
        ...initialState,
        loadingDistributionCenter: false,
        hasError: true
      };
    }
  }
};

export const DistributionCenterContext = React.createContext();

export const DistributionCenterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  /**
   * Function used to fetch the user's DC right after the user login.
   *
   * When the user logs in, we immediately call this function to fetch the
   * user's DCs so that he/she can select one.
   *
   * @param {Object} selectedDistributionCenterId You should inform the selected DC id
   * so it's presence can be checked on the DC list from the server
   */
  const fetchDC = ({ selectedDistributionCenterId = null, userEmail = '' }) => {
    dispatch({ type: DC_REDUCER_TYPE.START_FETCH_OR_SET_DC });
    cognitoDistributionCenters()
      .then(response => {
        const cognitoDistributionCentersResponse =
          response?.data?.cognitoDistributionCenters;
        if (cognitoDistributionCentersResponse) {
          /**
           * To assure selectedDC is present in the list, we always reset
           * selectedDistributionCenter based on the received list.
           * When not present on the list, will return undefined.
           */
          const dcFromList =
            cognitoDistributionCentersResponse.filter(
              dc =>
                dc.distributionCenterId &&
                dc.distributionCenterId === selectedDistributionCenterId
            )[0] || null;

          // We must guarantee that the saved DCs exists in response too
          const lastSelectedDCs =
            JSON.parse(localStorage.getItem(RECENT_DISTRIBUTION_CENTERS)) || [];

          const existentRecentDCs = cognitoDistributionCentersResponse.filter(
            dc =>
              lastSelectedDCs
                .map(rdc => rdc.distributionCenterId)
                .includes(dc.distributionCenterId)
          );

          dispatch({
            type: DC_REDUCER_TYPE.SUCCESS_FETCH_DC,
            payload: {
              selectedDistributionCenter: dcFromList,
              allDistributionCenters: cognitoDistributionCentersResponse,
              recentDistributionCenters: existentRecentDCs
            }
          });

          localStorage.setItem(
            RECENT_DISTRIBUTION_CENTERS,
            JSON.stringify(existentRecentDCs)
          );
          localStorage.setItem(userEmail, JSON.stringify(dcFromList));
        } else {
          /**
           * When no DC is returned, we know that this user
           * is authorized but has no DC
           */
          dispatch({
            type: DC_REDUCER_TYPE.SUCCESS_FETCH_DC,
            payload: {
              allDistributionCenters: [],
              recentDistributionCenters: [],
              selectedDistributionCenter: null
            }
          });

          localStorage.setItem(RECENT_DISTRIBUTION_CENTERS, JSON.stringify([]));
          localStorage.setItem(userEmail, null);
        }
      })
      .catch(err => {
        dispatch({ type: DC_REDUCER_TYPE.FAILURE_FETCH_DC });
        Sentry.captureException(err);
      });
  };

  /**
   * Function to set and store the selected DC when the user selects it.
   *
   * After this function is called, all the components that rely
   * on this context will be automatically updated with the new DC.
   *
   * @param selectedDistributionCenter
   */
  const setSelectedDC = (selectedDistributionCenter, userEmail) => {
    // Set the DC on localStorage so that the user doesn't have to select it
    // after each login session.
    localStorage.setItem(userEmail, JSON.stringify(selectedDistributionCenter));
    const lastSelectedDCs =
      JSON.parse(localStorage.getItem(RECENT_DISTRIBUTION_CENTERS)) || [];
    if (
      selectedDistributionCenter &&
      !lastSelectedDCs.filter(
        dc =>
          dc.distributionCenterId ===
          selectedDistributionCenter.distributionCenterId
      )[0]
    ) {
      lastSelectedDCs.push(selectedDistributionCenter);
      localStorage.setItem(
        RECENT_DISTRIBUTION_CENTERS,
        JSON.stringify(lastSelectedDCs)
      );
    }

    return dispatch({
      type: DC_REDUCER_TYPE.SUCCESS_SET_DC,
      payload: {
        selectedDistributionCenter,
        recentDistributionCenters: lastSelectedDCs
      }
    });
  };

  useEffect(() => {
    if (authenticatedUser) {
      const { email } = authenticatedUser;
      const selectedDC = JSON.parse(localStorage.getItem(email));
      setSelectedDC(selectedDC, email);
      fetchDC({
        selectedDistributionCenterId: selectedDC?.distributionCenterId,
        userEmail: email
      });
    }
  }, [authenticatedUser]);

  const value = useMemo(() => ({ state, fetchDC, setSelectedDC }), [state]);

  return (
    <DistributionCenterContext.Provider value={value}>
      {children}
    </DistributionCenterContext.Provider>
  );
};

DistributionCenterProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const errorMessage =
  '`useDistributionCenter` hook must be used within a `DistributionCenterProvider` component';

/**
 * This Provider can be used to mock any possible state
 * or provider function call
 */
export function TestDistributionCenterProvider({
  children,
  selectedDistributionCenter,
  allDistributionCenters,
  recentDistributionCenters,
  loadingDistributionCenter,
  hasError,
  fetchDC,
  setSelectedDC
}) {
  const [selectedDC, setSelectedDCFromState] = useState(
    selectedDistributionCenter
  );

  const value = useMemo(
    () => ({
      state: {
        selectedDistributionCenter: selectedDC,
        allDistributionCenters,
        recentDistributionCenters,
        loadingDistributionCenter,
        hasError
      },
      fetchDC,
      setSelectedDC: setSelectedDC || setSelectedDCFromState
    }),
    [
      allDistributionCenters,
      recentDistributionCenters,
      loadingDistributionCenter,
      hasError,
      fetchDC,
      setSelectedDC,
      selectedDC
    ]
  );
  return (
    <DistributionCenterContext.Provider value={value}>
      {children}
    </DistributionCenterContext.Provider>
  );
}

TestDistributionCenterProvider.defaultProps = {
  fetchDC: () => {},
  setSelectedDC: null,
  selectedDistributionCenter: null,
  allDistributionCenters: [],
  recentDistributionCenters: [],
  loadingDistributionCenter: false,
  hasError: false
};

TestDistributionCenterProvider.propTypes = {
  children: PropTypes.element.isRequired,
  selectedDistributionCenter: dcShape,
  allDistributionCenters: PropTypes.arrayOf(dcShape),
  recentDistributionCenters: PropTypes.arrayOf(dcShape),
  loadingDistributionCenter: PropTypes.bool,
  hasError: PropTypes.bool,
  fetchDC: PropTypes.func,
  setSelectedDC: PropTypes.func
};

/**
 * This function can be used to fetch, set and/or get the user's distribution center.
 *
 * Example of usage:
 *
 * const {
 *  state: {
 *    loadingDistributionCenter,
 *    selectedDistributionCenter,
 *    allDistributionCenters,
 *    recentDistributionCenters
 *  },
 *  fetchDC, setSelectedDC
 * } = useDistributionCenter();
 */
export const useDistributionCenter = () => {
  const context = useContext(DistributionCenterContext);

  if (!context) {
    throw new Error(errorMessage);
  }

  return context;
};
