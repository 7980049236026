import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const round = (value, step) => {
  // eslint-disable-next-line no-param-reassign
  step = step || 1.0;
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
};

export function PGRProgress({ percentage }) {
  const calculatePgrProgressBarPercentage = value => {
    return value <= 100 ? value : 100 - (value - 100);
  };

  const linearProgressValue = calculatePgrProgressBarPercentage(percentage);

  const BorderLinearProgress = withStyles({
    root: {
      height: 8,
      borderRadius: 8
    },
    colorPrimary: {
      backgroundColor:
        percentage <= 100 ? colors.smoke[100] : colors.yellow[700]
    },
    bar: {
      borderRadius: 8,
      backgroundColor: colors.gradients.sanches
    }
  })(LinearProgress);

  return (
    <Box>
      <BorderLinearProgress variant="determinate" value={linearProgressValue} />
      <Box pt={1}>
        <Typography
          style={{ width: '50%', float: 'left' }}
          variant="caption"
          color="textSecondary"
        >
          <Box style={{ textAlign: 'left' }}>Valor de mercadoria (PGR)</Box>
        </Typography>
        <Typography
          style={{ width: '50%', float: 'left' }}
          variant="caption"
          color="textSecondary"
        >
          <Box style={{ textAlign: 'right' }} data-testid="pgr-percentage">
            {percentage}%
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

PGRProgress.propTypes = {
  percentage: PropTypes.number
};

PGRProgress.defaultProps = {
  percentage: 0
};
