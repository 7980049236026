import { useCallback, useEffect, useState } from 'react';
import { Loader } from 'google-maps';

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
let mapsLoaderPromise = false;

/**
 * Instantiates Google Places API and creates a session token
 *
 * @returns {Object}
 */
const useGooglePlacesApi = () => {
  // default to window.google if is already loaded
  const [googleMaps, setGoogleMaps] = useState(window.google);
  const [autocompleteSessionToken, setAutocompleteSessionToken] = useState(
    null
  );

  const generateAutocompleteSessionToken = useCallback(google => {
    const newToken = new google.maps.places.AutocompleteSessionToken();
    setAutocompleteSessionToken(newToken);
  }, []);

  // check if google maps places API is present and fetch a new session token
  useEffect(() => {
    if (!autocompleteSessionToken && googleMaps?.maps?.places) {
      generateAutocompleteSessionToken(googleMaps);
    }
  }, [autocompleteSessionToken, generateAutocompleteSessionToken, googleMaps]);

  // load google maps API if it is not present
  useEffect(() => {
    if (!googleMaps) {
      if (!mapsLoaderPromise) {
        const googleLoader = new Loader(REACT_APP_GOOGLE_MAPS_API_KEY, {
          language: 'pt-BR',
          libraries: ['places'],
          region: 'BR'
        });
        mapsLoaderPromise = googleLoader.load();
      }
      mapsLoaderPromise.then(google => {
        // we generate a new session token on API load so we don't
        // need an extra render just because of this
        generateAutocompleteSessionToken(google);
        setGoogleMaps(google);
      });
    }
  }, [generateAutocompleteSessionToken, googleMaps]);

  return { autocompleteSessionToken, googleMaps };
};

export default useGooglePlacesApi;
