import React, { useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  styled
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { playErrorBeep, playSuccessBeep } from '../../../sounds';
import showSnackbar from '../../../app/components/snackbar/snackbar-container';
import {
  ACTIVITY,
  OPERATIONAL_PROCESS,
  SWITCHES,
  ROUTES,
  COGNITO_DISTRIBUTION_CENTER
} from '../../../constants';
import HeaderWithReturn from '../../../app/components/header-with-return';
import CheckboxListItem from '../../../app/components/checkbox-list-item';
import { useFeature } from '../../../app/hooks/use-feature';
import {
  TESTID,
  TEXT,
  IS_DAMAGED_HERE_OPTIONS,
  PHYSICAL_STATE_PACKAGE_OPTIONS,
  PHYSICAL_STATE_PRODUCT_OPTIONS,
  IS_PRODUCT_DAMAGED_OPTIONS,
  NOT_DAMAGED,
  CREATE_API_RESPONSE,
  DECLARATION_RESULT,
  IS_LOST_PRODUCT_OPTIONS,
  IS_QUALITY_PACKAGING_APPROPRIATE_OPTIONS,
  YES
} from './constants';
import { DamagedPackageContext } from './damaged-package-context';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import { declareDamagedPackage } from './damaged-package-service';
import SelectableButton from '../../../app/components/selectable-button';
import ActionContainerBottom from '../../../app/components/actions-container-bottom';
import DeclarationPendingDialog from './damaged-package-declaration-dialog';
import TextWithBoldEmphasis from '../../../app/components/text-with-bold-emphasis';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import getUserType from '../../../app/access-control/access-control-service';

/**
 * Add valueToBeToggled if no present on originalList
 * Remove valueToBeToggled if present on originalList
 * @param {string[]} originalList
 * @param {string} valueToBeToggled
 * @returns {string[]}
 */
const toggleValueFromList = (originalList, valueToBeToggled) => {
  const newList = originalList.filter(v => v !== valueToBeToggled);
  if (newList.length === originalList.length) {
    return [...originalList, valueToBeToggled];
  }
  return newList;
};

export default function FormDamagedPackage({ declareDamagedPackageService }) {
  const {
    damageLocation,
    setDamageLocation,
    physicalStatePackage,
    setPhysicalStatePackage,
    isProductDamaged,
    setIsProductDamaged,
    productLostStatusType,
    setProductLostStatusType,
    packagingQualityStatusType,
    setPackagingQualityStatusType,
    physicalStateProduct,
    setPhysicalStateProduct,
    packageId,
    distributionCenterId,
    imageLabel,
    imagesPackage,
    isImageLabelIdentified
  } = useContext(DamagedPackageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [
    showFinishedAsPendingDrawer,
    setShowFinishedAsPendingDrawer
  ] = useState(false);
  const [declarationResult, setDeclarationResult] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const enableDeclarePackageDamageV2ByDc = useFeature(
    SWITCHES.enableDeclarePackageDamageV2ByDc
  );
  const enableDeclarePackageDamageV2AllDcsNoLeve = useFeature(
    SWITCHES.enableDeclarePackageDamageV2AllDcsNoLeve
  );

  const enableDeclarePackageDamageWithNewQuestion = useFeature(
    SWITCHES.enableDeclarePackageDamageWithNewQuestion
  );

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const isDeclareDamagedPackageEnabled =
    enableDeclarePackageDamageV2ByDc ||
    (enableDeclarePackageDamageV2AllDcsNoLeve && !isUserLeve);

  const history = useHistory();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const handleGoBack = useCallback(() => history.push('/identify'), [history]);
  const handleGoToPreviousPage = () =>
    history.push(ROUTES.DAMAGED_PACKAGE.PREVISUALIZE_IMAGE);

  const validateForm = () => {
    // not a valid form if these values still have their default values
    const validNewQuestion = enableDeclarePackageDamageWithNewQuestion
      ? packagingQualityStatusType
      : true;

    const validDeclaration =
      !productLostStatusType ||
      !damageLocation ||
      !isProductDamaged ||
      !validNewQuestion;

    if (validDeclaration) {
      return false;
    }

    if (!physicalStatePackage.length) {
      return false;
    }

    // selected that product is damaged, but didn't select any kind of product damage
    if (isProductDamaged === YES && !physicalStateProduct.length) {
      return false;
    }

    // selected that the package wasn't damaged, product should be damaged
    return !(
      physicalStatePackage.includes(NOT_DAMAGED) && isProductDamaged !== YES
    );
  };

  const isAbleToSubmit = validateForm();

  /**
   * If the checked option is equal NOT_DAMAGED, should be the only one selected,
   * If the check option is anything else, the NOT_DAMAGED should be removed if present
   * @param {string} newValue
   */
  const handlePhysicalStatePackageChange = newValue => {
    if (physicalStatePackage.includes(newValue)) {
      setPhysicalStatePackage(
        toggleValueFromList(physicalStatePackage, newValue)
      );
      return;
    }

    if (newValue === NOT_DAMAGED) {
      setPhysicalStatePackage([newValue]);
      setIsProductDamaged(YES);
      return;
    }

    const filterOutNotDamagedIfExist = physicalStatePackage.filter(
      value => value !== NOT_DAMAGED
    );
    setPhysicalStatePackage([...filterOutNotDamagedIfExist, newValue]);
  };

  const handlePhysicalStateProductChange = v =>
    setPhysicalStateProduct(toggleValueFromList(physicalStateProduct, v));

  const handleLocationOptionChange = value => setDamageLocation(value);
  const handleLostProductOptionChange = value =>
    setProductLostStatusType(value);
  const handlePackagingQualityStatusOptionChange = value =>
    setPackagingQualityStatusType(value);
  const handleDamagedProductOptionChange = value => setIsProductDamaged(value);

  const errorHandler = React.useCallback(
    errMessage => {
      showSnackbar({
        message: errMessage,
        variant: 'error',
        showCloseButton: true,
        enqueueSnackbar
      });
      playErrorBeep();
      setIsLoading(false);
    },
    [enqueueSnackbar]
  );

  function finishAsPendingStatusChange(result) {
    setDeclarationResult(result);
    setShowFinishedAsPendingDrawer(true);
  }

  function finishAsAcceptedAsDamage() {
    const snackbar =
      CREATE_API_RESPONSE[
        DECLARATION_RESULT
          .DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_ACCEPTED_AS_DAMAGED
      ];

    showSnackbar({
      message: snackbar.MESSAGE,
      variant: snackbar.VARIANT,
      showCloseButton: true,
      enqueueSnackbar
    });
    history.push('/identify');
  }

  const createDamagedPackage = async () => {
    if (isLoading) return;

    setIsLoading(true);

    const damagedPackageBody = {
      packageId,
      distributionCenterId,
      physicalStatePackage,
      physicalStateProduct:
        isProductDamaged === YES ? physicalStateProduct : [],
      imageLabel,
      imagesPackage,
      isImageLabelIdentified,
      productLostStatusType,
      packagingQualityStatusType,
      damageLocation,
      errorHandler,
      enableDeclarePackageDamageWithNewQuestion
    };

    const response = await declareDamagedPackageService(damagedPackageBody);

    if (!response || !response.success) {
      setIsLoading(false);
      return;
    }

    playSuccessBeep();
    trackEnd(OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2, ACTIVITY.FORM_STEP);
    setIsLoading(false);

    if (
      response.damagedPackageDeclarationResult ===
      DECLARATION_RESULT.DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_ACCEPTED_AS_DAMAGED
    ) {
      finishAsAcceptedAsDamage();
    } else {
      finishAsPendingStatusChange(response.damagedPackageDeclarationResult);
    }
  };

  const Loading = () => (
    <Box mt={1.5} display="flex" justifyContent="center">
      <CircularProgress justify="center" data-testid={TESTID.FORM_LOADING} />
    </Box>
  );

  useEffect(() => {
    if (!isDeclareDamagedPackageEnabled) {
      handleGoBack();
    }
  }, [isDeclareDamagedPackageEnabled, handleGoBack]);

  useEffect(() => {
    const isContextMissingState =
      !imageLabel || !imagesPackage || imagesPackage.length === 0 || !packageId;
    if (isContextMissingState) {
      handleGoBack();
    }
  }, [imageLabel, imagesPackage, packageId, handleGoBack]);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.DECLARE_DAMAGE_V2, ACTIVITY.FORM_STEP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderWithReturn
        title={TEXT.SCREEN_TITLE}
        onReturn={handleGoToPreviousPage}
      />
      <Container maxWidth="xs" disableGutters>
        <Box mb={6}>
          <Box mt={11} mb={4} mx={3}>
            <Typography variant="h6">
              {isLoading ? TEXT.FORM_SUBTITLE_LOADING : TEXT.FORM_SUBTITLE}
            </Typography>
          </Box>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {enableDeclarePackageDamageWithNewQuestion && (
                <ToggleOption
                  question={
                    <TextWithBoldEmphasis
                      text={TEXT.QUESTION_PACKAGING_QUALITY.TEXT}
                      emphasis={TEXT.QUESTION_PACKAGING_QUALITY.EMPHASIS}
                    />
                  }
                  options={IS_QUALITY_PACKAGING_APPROPRIATE_OPTIONS}
                  setActiveOption={handlePackagingQualityStatusOptionChange}
                  active={packagingQualityStatusType}
                  dataTestId={TESTID.DAMAGE_PACKAGE_PACKAGING_QUALITY_OPTIONS}
                />
              )}

              <ToggleOption
                question={
                  <TextWithBoldEmphasis
                    text={TEXT.QUESTION_LOCATION.TEXT}
                    emphasis={TEXT.QUESTION_LOCATION.EMPHASIS}
                  />
                }
                options={IS_DAMAGED_HERE_OPTIONS}
                setActiveOption={handleLocationOptionChange}
                active={damageLocation}
              />

              <FormCheckboxList
                question={
                  <TextWithBoldEmphasis
                    text={TEXT.QUESTION_PHYSICAL_STATE_PACKAGE.TEXT}
                    emphasis={TEXT.QUESTION_PHYSICAL_STATE_PACKAGE.EMPHASIS}
                  />
                }
                options={PHYSICAL_STATE_PACKAGE_OPTIONS}
                setSelectedOptions={handlePhysicalStatePackageChange}
                selectedOptions={physicalStatePackage}
              />

              <ToggleOption
                question={
                  <TextWithBoldEmphasis
                    text={TEXT.QUESTION_LOST_PRODUCT.TEXT}
                    emphasis={TEXT.QUESTION_LOST_PRODUCT.EMPHASIS}
                  />
                }
                options={IS_LOST_PRODUCT_OPTIONS}
                setActiveOption={handleLostProductOptionChange}
                active={productLostStatusType}
                dataTestId={TESTID.DAMAGE_PACKAGE_MISSING_PRODUCT_OPTIONS}
              />

              <ToggleOption
                question={
                  <TextWithBoldEmphasis
                    text={TEXT.QUESTION_DAMAGED_PRODUCT.TEXT}
                    emphasis={TEXT.QUESTION_DAMAGED_PRODUCT.EMPHASIS}
                  />
                }
                options={IS_PRODUCT_DAMAGED_OPTIONS}
                setActiveOption={handleDamagedProductOptionChange}
                active={isProductDamaged}
                dataTestId={TESTID.IS_PRODUCT_DAMAGED_TOGGLE}
              />

              {isProductDamaged === YES && (
                <FormCheckboxList
                  question={
                    <TextWithBoldEmphasis
                      text={TEXT.QUESTION_PHYSICAL_STATE_PRODUCT.TEXT}
                      emphasis={TEXT.QUESTION_PHYSICAL_STATE_PRODUCT.EMPHASIS}
                    />
                  }
                  options={PHYSICAL_STATE_PRODUCT_OPTIONS}
                  setSelectedOptions={handlePhysicalStateProductChange}
                  selectedOptions={physicalStateProduct}
                  dataTestId={TESTID.DAMAGED_PACKAGE_PHYSICAL_STATE_PRODUCT}
                />
              )}
            </>
          )}
        </Box>
      </Container>

      {!isLoading && (
        <ActionContainerBottom>
          <Container maxWidth="xs">
            <Box pb={1.5}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={!isAbleToSubmit}
                onClick={() => {
                  createDamagedPackage()
                    .then(() => {})
                    .catch(error => error);
                }}
              >
                {TEXT.SUBMIT}
              </Button>
            </Box>
          </Container>
        </ActionContainerBottom>
      )}

      {showFinishedAsPendingDrawer && (
        <DeclarationPendingDialog declarationResult={declarationResult} />
      )}
    </Box>
  );
}

FormDamagedPackage.propTypes = {
  declareDamagedPackageService: PropTypes.func
};

FormDamagedPackage.defaultProps = {
  declareDamagedPackageService: declareDamagedPackage
};

const FormCheckboxList = ({
  question,
  options,
  setSelectedOptions,
  selectedOptions,
  dataTestId
}) => {
  return (
    <Box pb={4} data-testid={dataTestId}>
      <Box pb={1} mx={3}>
        {question}
      </Box>
      <Box>
        {options.map(option => (
          <CheckboxListItem
            onClick={() => setSelectedOptions(option.value)}
            selected={selectedOptions.includes(option.value)}
            key={option.value}
            testIdListItem={`test-${option.value}`}
            horizontalPadding={4}
            divider
          >
            {option.label}
          </CheckboxListItem>
        ))}
      </Box>
    </Box>
  );
};

FormCheckboxList.propTypes = {
  question: PropTypes.element.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataTestId: PropTypes.string
};

FormCheckboxList.defaultProps = {
  dataTestId: ''
};

const ToggleOption = ({
  question,
  options,
  setActiveOption,
  active,
  dataTestId
}) => {
  return (
    <Box pb={2} data-testid={dataTestId}>
      <Box pb={2} pl={3}>
        {question}
      </Box>
      <HiddenScrollbarBox display="flex" overflow="auto" px={3} pb={2}>
        {options.map(option => (
          <Box mr={1} key={option.label}>
            <SelectableButton
              noWrapLabel
              dataTestId={option.value}
              label={option.label}
              isSelected={active === option.value}
              onClick={() => setActiveOption(option.value)}
            />
          </Box>
        ))}
      </HiddenScrollbarBox>
    </Box>
  );
};

ToggleOption.propTypes = {
  question: PropTypes.element.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  setActiveOption: PropTypes.func.isRequired,
  active: PropTypes.bool,
  dataTestId: PropTypes.string
};

ToggleOption.defaultProps = {
  active: null,
  dataTestId: ''
};

const HiddenScrollbarBox = styled(Box)({
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': { display: 'none' }
});
