import getHeaders from './get-headers';

/**
 * oneAuthMiddleware is the wretch middleware to include the Authorization header in the LoggiOne requests.
 *
 * @return {Function}
 */

const oneAuthMiddleware = next => async (url, opts) => {
  const headers = opts?.headers;

  const optionsAuthHeader = {
    ...opts,
    headers: await getHeaders(headers)
  };

  return next(url, optionsAuthHeader);
};

export default oneAuthMiddleware;
