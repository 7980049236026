import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { colors, typography } from '../principles';
import { hexToRgb } from '../utils';

const breakpoints = createBreakpoints({});

export const MuiDialog = {
  paper: {
    padding: '8px 0',
    width: '612px',
    borderRadius: '16px',
    [breakpoints.only('xs')]: {
      position: 'absolute',
      bottom: 0,
      marginBottom: '-16px',
      paddingBottom: '16px',

      width: '100%',
      maxWidth: '100%'
    }
  },
  paperWidthXs: {
    maxWidth: '100%'
  },
  paperWidthSm: {
    [breakpoints.only('xs')]: {
      maxWidth: '100%'
    }
  }
};

export const MuiDialogActions = {
  root: {
    padding: '8px 24px',
    [breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'stretch'
    }
  }
};

export const MuiDialogTitle = {
  root: { ...typography.h6, fontWeight: 600, padding: '8px 24px' }
};

export const MuiBackdrop = {
  root: { backgroundColor: `${hexToRgb(colors.smoke[900], 0.3).rgba}` }
};

export const MuiDialogProps = {
  PaperProps: {
    elevation: 11
  }
};

export const MuiDialogTitleProps = {
  disableTypography: true
};
