/*
 * Count the number of full or almost unit loads on a list of decisions.
 */
export function countFullUnits(decisions) {
  return decisions.reduce((acc, decision) => {
    const isAlmostFull =
      decision.destination.occupationStatus === 'ALMOST_FULL';
    return isAlmostFull ? acc + 1 : acc;
  }, 0);
}

/*
 * Aggregate a list of sorting decisions by their destination unit load license plate.
 */
export function aggregateSortingDecisions(decisions) {
  // Aggregate sorting decisions by destination license plate.
  const aggregated = decisions.reduce((acc, decision) => {
    acc[decision.destination.licensePlate] = decision;
    return acc;
  }, {});

  // Sort decisions by name.
  return Object.values(aggregated).sort((left, right) => {
    return left.name > right.name ? 1 : -1;
  });
}
