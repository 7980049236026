import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(() => ({
  root: {
    '&$divider': {
      borderBottom: 0,
      boxShadow: `inset 0px -1px 0px ${colors.smoke[100]};`
    },
    '&$selected': {
      boxShadow: `inset 0px -1px 0px ${colors.blue[500]}33, inset 3px 0px 0px ${
        colors.blue[500]
      };`,
      backgroundColor: `${colors.blue[100]}`,
      '&:hover': {
        backgroundColor: `${colors.blue[100]}`
      }
    }
  },
  divider: {},
  selected: {}
}));

const CheckboxListItem = ({
  children,
  selected,
  onClick,
  key,
  horizontalPadding,
  testIdListItem,
  divider
}) => {
  const classes = useStyles();

  return (
    <Box px={horizontalPadding} clone>
      <ListItem
        data-testid={testIdListItem}
        disableGutters
        key={key}
        divider={divider}
        onClick={onClick}
        selected={selected}
        classes={{
          root: classes.root,
          divider: classes.divider,
          selected: classes.selected
        }}
      >
        <Box paddingRight={1}>
          <Checkbox edge="start" checked={selected} color="primary" />
        </Box>
        {children}
      </ListItem>
    </Box>
  );
};

CheckboxListItem.defaultProps = {
  horizontalPadding: 3,
  testIdListItem: '',
  divider: false
};

CheckboxListItem.propTypes = {
  children: PropTypes.element.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
  horizontalPadding: PropTypes.number,
  testIdListItem: PropTypes.string,
  divider: PropTypes.bool
};

export default CheckboxListItem;
