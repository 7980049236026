import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';

import Logo from '../../assets/images/icon.png';

export default function HeaderWithButton({
  testId,
  handleButtonClick,
  isDisabled,
  buttonLabel
}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
      <Box>
        <img src={Logo} alt="Loggi" height="25" />
      </Box>
      <Box marginLeft="auto">
        <Button
          data-testid={testId}
          size="medium"
          variant="outlined"
          color="primary"
          onClick={handleButtonClick}
          disabled={isDisabled}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
}

HeaderWithButton.propTypes = {
  testId: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  isDisabled: PropTypes.bool
};

HeaderWithButton.defaultProps = {
  isDisabled: false,
  buttonLabel: 'OK'
};
