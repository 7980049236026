import { getAuthorizationTokens } from '@loggi/authentication-lib';
import {
  NETWORK_ERROR_MESSAGE,
  UNKNOWN_ERROR,
  BROWSER_NETWORK_ERROR_MESSAGE
} from './constants';

const LOGGI_WEB_URL = process.env.REACT_APP_LOGGI_WEB_URL;

export function handleNetworkError(err, callback) {
  const errorMsg = Object.values(BROWSER_NETWORK_ERROR_MESSAGE).includes(
    err.message
  )
    ? NETWORK_ERROR_MESSAGE
    : err.message;
  if (typeof callback === 'function') callback(errorMsg);
  if (err.message === UNKNOWN_ERROR) throw err;
}

export async function graphqlRequest(query, selectedDistributionCenter) {
  const { idToken } = await getAuthorizationTokens();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    AppVersion: process.env.REACT_APP_VERSION,
    Authorization: `Bearer ${idToken}`
  };

  if (selectedDistributionCenter) {
    headers.SelectedDistributionCenterId =
      selectedDistributionCenter?.distributionCenterId;
  }

  return fetch(`${LOGGI_WEB_URL}/graphql`, {
    method: 'POST',
    body: JSON.stringify({ query }),
    headers,
    // 'same-origin' option here is the default and safer. But, for local
    // development, just when accessing through a smartphone your machine IP,
    // (for doing some QA with a real phone), it does cross-origin
    // calls which does not send the credentials to loggi-web correctly.
    // The option 'include' fixes this.
    credentials: process.env.development ? 'include' : 'same-origin'
  });
}

export async function getUser(errorHandler) {
  const queryPayload = `
    query {
      user(id: "") {
        pk,
        fullName
      }
    }
  `;
  return graphqlRequest(queryPayload).catch(err =>
    handleNetworkError(err, errorHandler)
  );
}

export async function packageCheck(
  geo,
  barcode,
  notes,
  source,
  selectedDistributionCenter
) {
  const lng = geo.coords.longitude;
  const lat = geo.coords.latitude;

  return graphqlRequest(
    `
    mutation {
      checkPackage(input: {
        barcode: "${barcode}",
        lat: ${lat},
        lng: ${lng},
        scanned: true,
        origin: xd_app,
        notes: "${notes}",
        source: "${source}"
      }) {
        packageCheck {
          pk
        }
        errors {
          title
        }
      }
    }
  `,
    selectedDistributionCenter
  );
}

export async function identifyBarcode(barcode, selectedDistributionCenter) {
  return graphqlRequest(
    `
    query {
      getLabelInfo(label: "${barcode}") {
        packages {
          pk
          direction
          returnInfo {
            consolidationType
          }
          routingCode
          identifier
          loggiKey
          currentPromisedDate
          startProcessingDeadlineDate
          statusCodeDisplay
          unitLoad {
            licensePlate
          }
          destination {
            zipCode
            addressFormatted
          }
          recipient {
            name,
            phone
          }
          company {
            name
            sharedName
          }
          issues
          width
          height
          length
          weight
          deliveryStatusHistory {
            codeDisplay
            attempt
          }
          annotations {
            slo {
              confirmationHubRoutingCode
            }
          }
        }
        unitLoad {
          licensePlate
        }
        bag {
          seal
          promisedDate
          numPackages
          statusDisplay
          packages {
            pk
            identifier
            trackingKey
            currentPromisedDate
            startProcessingDeadlineDate
            recipient {
              name
            }
            destination {
              addressFormatted
            }
            company {
              sharedName
            }
          }
          distributionCenter {
            name
            routingCode
          }
          route {
            index
            cutoff {
              pk
            }
            inquiry {
              finalized(first: 1) {
                edges {
                  node {
                    itinerary {
                      pk
                      url
                      status
                      created
                      accepted
                      started
                      dropped
                      finished
                      driver {
                        pk
                        cnh{
                          number
                        }
                        photoUrl {
                          default
                          profileHighRes
                        }
                        fullName
                        docRg {
                          number
                        }
                        docCpf {
                          number
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        nonIntegratedPackage{
          barcode
          recipientName
          companyName
        }
      }
    }
  `,
    selectedDistributionCenter
  );
}

export async function meliHuConfirmation(barcode) {
  const payload = {
    hu_id: barcode
  };

  const response = await fetch(
    `${LOGGI_WEB_URL}/api/v1/integration/mercado_livre/hu`,
    {
      method: 'POST',
      body: JSON.stringify(payload)
    }
  );

  const data = await response.json();

  return {
    status: response.status,
    ok: response.ok,
    data
  };
}

/* *
 * Queries and Mutation of Transferences
 * */

export async function getLabelInfoByBagOrUnitLoad(barcode, errorHandler) {
  const graphql = `{
    getLabelInfo(label: "${barcode}") {
      modelType
      bag {
        seal
        identifier
        transfers {
          pk
          name
          id
          status
          statusDisplay
          expeditionCenter {
            name
            shortDisplay
          }
          numBags
        }
      }
      unitLoad {
        licensePlate
        name
        transfer {
          pk
          name
          id
          status
          statusDisplay
          expeditionCenter {
            name
            shortDisplay
          }
        }
      }
    }
  }`;
  return graphqlRequest(graphql).catch(err =>
    handleNetworkError(err, errorHandler)
  );
}

export async function changeLabel({
  oldBarcode,
  newBarcode,
  itemId,
  itemType,
  distributionCenter
}) {
  let input = `itemLabel: "${oldBarcode}",
               newLabel: "${newBarcode}",
               distributionCenterId: ${distributionCenter}`;

  if (itemType && itemId) {
    input = `${input},
            itemType: "${itemType}",
            itemId: ${itemId}`;
  }

  return graphqlRequest(
    `
    mutation {
      changeLabel(input: {
        ${input}
      }) {
        success
        errors {
          message
          field
        }
        description
      }
    }
      `
  );
}
