import React, { useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  TextField,
  Grid
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { pxToRem } from '@loggi/mar/src/utils';

const useStyles = makeStyles(({ spacing }) => ({
  item: {
    paddingLeft: spacing(2.0),
    paddingRight: 0,
    paddingTop: pxToRem(20),
    paddingBottom: pxToRem(20),
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  itemSelected: {
    borderLeft: `${pxToRem(3)} solid ${colors.blue[500]}`
  },
  itemNotSelected: {
    borderLeft: `${pxToRem(3)} solid transparent`,
    '&:hover': {
      backgroundColor: colors.blue[100]
    }
  }
}));

export default function RadioButtonList({
  itemList,
  selectedItemKey,
  expectNotes,
  notes,
  onClickItem,
  onChangeNotes
}) {
  const textFieldRef = useRef(null);
  const bottomRef = useRef(null);

  const classes = useStyles();

  const shouldFocusNotes = (textRef, positionRef, expectingNotes) => {
    const hasTextRef = textRef && textRef.current;
    const hasPositionRef = positionRef && positionRef.current;
    return hasTextRef && hasPositionRef && !!expectingNotes;
  };

  useEffect(() => {
    if (shouldFocusNotes(textFieldRef, bottomRef, expectNotes)) {
      textFieldRef.current.focus();
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [itemList, selectedItemKey, expectNotes]);

  // Render list of selectable items to be displayed
  const renderedItems = itemList.map(item => {
    const isSelected = !!selectedItemKey && selectedItemKey === item.key;
    const itemClass = `${classes.item} ${
      isSelected ? classes.itemSelected : classes.itemNotSelected
    }`;

    return (
      <ListItem
        button
        divider
        selected={isSelected}
        key={item.key}
        onClick={() => onClickItem(item)}
        className={itemClass}
        data-testid={`radio-button-list-item-${item.key}`}
      >
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="body2" fontWeight="fontWeightRegular">
            {item.text}
          </Typography>
          <Box
            p={0}
            mr={2.0}
            hidden={!isSelected}
            data-testid={`check-icon-${item.key}`}
          >
            <CheckIcon id={item.text} color="primary" />
          </Box>
        </Grid>
      </ListItem>
    );
  });

  return (
    <Box>
      <List disablePadding data-testid="radio-button-list">
        {renderedItems}
      </List>
      <Box mt={3.5} hidden={!expectNotes}>
        <TextField
          required
          id="text-field-notes"
          type="text"
          placeholder="Conte o que aconteceu."
          multiline
          rows={5}
          fullWidth
          variant="outlined"
          inputProps={{ 'data-testid': 'text-field-notes' }}
          value={notes}
          onChange={onChangeNotes}
          inputRef={textFieldRef}
        />
        <div ref={bottomRef} />
      </Box>
    </Box>
  );
}

const itemPropType = PropTypes.shape({
  key: PropTypes.string,
  text: PropTypes.string,
  expectNotes: PropTypes.bool
});

RadioButtonList.defaultProps = {
  selectedItemKey: '',
  expectNotes: false,
  notes: '',
  onClickItem: () => {},
  onChangeNotes: () => {}
};
RadioButtonList.propTypes = {
  itemList: PropTypes.arrayOf(itemPropType).isRequired,
  selectedItemKey: PropTypes.string,
  expectNotes: PropTypes.bool,
  notes: PropTypes.string,
  onClickItem: PropTypes.func,
  onChangeNotes: PropTypes.func
};
