import { colors } from '@loggi/mar';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';

import ADDRESS_AUTOCOMPLETE from './constants';
import useGooglePlacesApi from './hooks/useGooglePlacesApi';

const DEBOUNCE_TIME_MILLISECONDS = 500;
const {
  ADD_NUMBER,
  NO_OPTIONS_TEXT,
  NUMBER_HELPER_TEXT
} = ADDRESS_AUTOCOMPLETE;

const useStyles = makeStyles({
  addNumberButton: {
    backgroundColor: 'transparent'
  },
  autocomplete: {
    '& input[type=text]::selection': {
      backgroundColor: colors.smoke[100]
    }
  }
});

/**
 * Uses @material-ui/lab/Autocomplete with Google Places API
 * to resolve an input string into an actual address
 */
const AddressAutocomplete = props => {
  const {
    error,
    label,
    loading,
    name,
    onBlur,
    setValue,
    required,
    onChange,
    shouldRenderRequiredAsterisk,
    value: address
  } = props;
  const { autocompleteSessionToken, googleMaps } = useGooglePlacesApi();
  const [isOpen, setOpen] = useState(false);
  const [focusNumberOnNextUpdate, setFocusNumberOnNextUpdate] = useState(false);
  const inputRef = useRef();
  const classes = useStyles();

  // we need to memoize the requestOptions object because it is a
  // dependency of usePlacesAutocomplete's fetchPredictions hook.
  // Otherwise, every keystroke fires a fetch.
  const requestOptions = useMemo(
    () => ({ sessionToken: autocompleteSessionToken }),
    [autocompleteSessionToken]
  );
  const {
    ready,
    setValue: setPlacesAutoCompleteValue,
    suggestions: { data },
    value
  } = usePlacesAutocomplete({
    callbackName: 'initGoogleMaps', // workaround for usePlacesAutocomplete loadApiErr
    debounce: DEBOUNCE_TIME_MILLISECONDS,
    googleMaps,
    requestOptions
  });

  const shouldDisableInput = !ready || loading;

  const focusNumberHelper = useCallback(() => {
    // Focusing/highlighting the NUMBER_HELPER_TEXT inside the input
    const field = inputRef.current;
    const startSelectionRange = field.value.indexOf(NUMBER_HELPER_TEXT);
    const endSelectionRange = startSelectionRange + NUMBER_HELPER_TEXT.length;

    if (startSelectionRange > 0)
      field.setSelectionRange(startSelectionRange, endSelectionRange);
  }, []);

  useEffect(() => {
    if (focusNumberOnNextUpdate && address?.description) {
      setFocusNumberOnNextUpdate(false);

      // This is here to defer the execution to the next JS cycle
      setTimeout(focusNumberHelper, 0);
    }
  }, [address, focusNumberHelper, focusNumberOnNextUpdate]);

  const getOptionLabel = useCallback(option => {
    const isFreeSolo = typeof option === 'string';
    if (isFreeSolo) {
      return option;
    }

    return option.description;
  }, []);

  const hasAddressNumber = useCallback(fullAddress => {
    /*
    The following regex matches against Google's returned
    suggestion, not against what the user has typed,
    which is safer for us.
  */
    const addressNumberRegex = /,\s+\d+/g;
    return Boolean(fullAddress && fullAddress.match(addressNumberRegex));
  }, []);

  /* eslint-disable camelcase */
  const handleSelect = useCallback(
    (_, option, reason) => {
      if (reason === 'select-option') {
        // Description is the full address resolved by Places. The place_id identifies
        // not only the option, but also the search from Google Maps' perspective.
        const { description, place_id } = option;

        // As we're selecting an option, we can replace the keyword without
        // requesting data from the API by setting the second parameter as "false".
        setPlacesAutoCompleteValue(description, false);
        setValue({ description, place_id });
      } else if (reason === 'blur') {
        // We can't assume that values are strings on blur, because an option might be selected.
        // (1) It's a simple freeSolo string (not a selected option)
        // (2) It's a structured address object from the highlighted option (with place_id)
        if (typeof option === 'string') {
          // cleanup urls
          let regex = new RegExp(/https:[\s\S]+|http:[\s\S]+/, 'gm');
          let aux = option.replace(regex, '');
          // cleanup special characters
          regex = new RegExp(/[|/\\]+/, 'gm');
          aux = aux.replace(regex, '').trim();
          // check if address is ok
          setPlacesAutoCompleteValue(aux, true);
        } else {
          const description = option?.description;
          const place_id = option?.place_id || null;

          if (description !== address?.description)
            setValue({ description, place_id });
        }
      } else if (reason === 'clear') {
        // 'clear' covers both clicking on the right-side "X" and making the input empty.
        setValue(null);
      }
    },
    [address, setValue, setPlacesAutoCompleteValue]
  );

  const onClickAddNumber = useCallback(
    (e, option) => {
      e.stopPropagation();

      const { place_id } = option;
      const {
        main_text: mainText,
        secondary_text: secondaryText
      } = option.structured_formatting;
      const description = `${mainText}, ${NUMBER_HELPER_TEXT} - ${secondaryText}`;

      /**
       * So, this is important:
       * 1. The setFocusNumberOnNextUpdate sets a state signaling to focus the
       *  number placeholder on the next render, when the address prop changes
       *  due to the below setValue call.
       * 2. We call here the setValue with false as second param, which is
       *  the shouldValidate option, making formik update the field value
       *  without validating it, and this is done to make it possible to focus
       *  the number helper text on the next render.
       */
      setFocusNumberOnNextUpdate(true);
      setValue({ description, place_id }, false);
    },
    [setValue]
  );

  const renderInput = params => {
    const {
      disabled,
      id,
      InputLabelProps,
      inputProps,
      InputProps,
      size
    } = params;

    const extendedInputLabelProps = {
      ...InputLabelProps,
      required: shouldRenderRequiredAsterisk
    };

    const extendedinputProps = {
      ...inputProps,
      'data-testid': 'address-autocomplete-input',
      'data-hj-allow': true,
      autoComplete: 'no'
    };

    const extendedInputProps = {
      ...InputProps,
      endAdornment: (
        <>
          {loading ? (
            <CircularProgress data-testid="loading" color="inherit" size={20} />
          ) : null}
          {InputProps.endAdornment}
        </>
      )
    };

    /* eslint-disable react/jsx-no-duplicate-props */
    return (
      <TextField
        disabled={disabled}
        error={error}
        fullWidth
        id={id}
        InputLabelProps={extendedInputLabelProps}
        inputProps={extendedinputProps}
        InputProps={extendedInputProps}
        inputRef={inputRef}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        required={required}
        size={size}
        variant="outlined"
      />
    );
    /* eslint-enable react/jsx-no-duplicate-props */
  };

  const renderOption = useCallback(
    (option, { inputValue }) => {
      const matches = match(option.structured_formatting.main_text, inputValue);
      const textParts = parse(option.structured_formatting.main_text, matches);
      const {
        structured_formatting: { main_text }
      } = option;

      const shouldRenderAddNumberBtn =
        !hasAddressNumber(value) && !hasAddressNumber(main_text);

      return (
        <Grid>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item>
              <LocationOnIcon color="disabled" />
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="body1">
                  {textParts.map((part, index) => (
                    <Box
                      component="span"
                      fontWeight={
                        part.highlight ? 'fontWeightBold' : 'fontWeightRegular'
                      }
                      // https://material-ui.com/components/autocomplete/#highlights
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Box>
              {shouldRenderAddNumberBtn && (
                <Box py={1}>
                  <Button
                    color="primary"
                    className={classes.addNumberButton}
                    data-testid="add-number-btn"
                    onClick={e => onClickAddNumber(e, option)}
                    size="small"
                    variant="outlined"
                  >
                    {ADD_NUMBER}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    },
    [classes.addNumberButton, onClickAddNumber, hasAddressNumber, value]
  );

  return (
    <Autocomplete
      autoComplete
      autoSelect
      className={classes.autocomplete}
      clearOnBlur
      data-testid="address-autocomplete"
      disabled={shouldDisableInput}
      filterOptions={x => x}
      freeSolo
      getOptionLabel={getOptionLabel}
      includeInputInList
      noOptionsText={NO_OPTIONS_TEXT}
      onChange={handleSelect}
      onClose={() => setOpen(false)}
      onInputChange={event => setPlacesAutoCompleteValue(event?.target.value)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      options={data}
      renderInput={renderInput}
      renderOption={renderOption}
      value={address?.description || ''}
    />
  );
};

AddressAutocomplete.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setValue: PropTypes.func,
  shouldRenderRequiredAsterisk: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.shape({
    description: PropTypes.string,
    place_id: PropTypes.string
  })
};

AddressAutocomplete.defaultProps = {
  error: false,
  loading: false,
  name: '',
  onBlur: () => {},
  onChange: () => {},
  setValue: () => {},
  shouldRenderRequiredAsterisk: false,
  required: false,
  value: undefined
};

export default AddressAutocomplete;
