import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InstructionsField = ({ fieldName, isRequired }) => {
  const { t } = useTranslation('one');
  const [field, meta] = useField({
    name: fieldName,
    validate: newValue => {
      if (isRequired) {
        if (!newValue) {
          return t('instructionsField.errorMessages.requiredField');
        }
      }
      return null;
    }
  });
  const { name, onBlur, onChange, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field` }}
      label={t('instructionsField.label')}
      multiline
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required={isRequired}
      rows={3}
      value={value}
      variant="outlined"
    />
  );
};

InstructionsField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool
};

InstructionsField.defaultProps = {
  isRequired: true
};

export default InstructionsField;
