import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Typography,
  Container,
  Button,
  makeStyles
} from '@material-ui/core';
import { ReactComponent as PackagesImage } from '../assets/images/empty-packages.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
    justifyContent: 'center'
  },
  centered: {
    alignSelf: 'center'
  }
});

export default function PageNotFound() {
  const classes = useStyles();
  return (
    <Container
      data-testid="page-not-found"
      maxWidth="xs"
      className={classes.root}
    >
      <Box mb={2} className={classes.centered}>
        <PackagesImage />
      </Box>
      <Box>
        <Typography align="center" component="div" variant="body1" gutterBottom>
          <Box fontWeight="fontWeightBold">Página não encontrada</Box>
        </Typography>
        <Typography align="center" component="div" variant="body1" gutterBottom>
          A página que você está tentando acessar não existe.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          component={Link}
          to="/"
        >
          Voltar para o aplicativo
        </Button>
      </Box>
    </Container>
  );
}
