import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

/**
 * This component is responsible for showing a header with a close button.
 *
 *
 * @param testId {string} Test id of the button
 * @param handleClose {func} Callback function for when the button is clicked
 * @returns {JSX.Element}
 */
export default function HeaderWithClose({ testId, handleClose }) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
      <IconButton data-testid={testId} edge="start" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

HeaderWithClose.propTypes = {
  testId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};
