import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TRIP_PAGE_STATES } from '../../../constants';

export const TripProcessContext = React.createContext();

export const TripProcessProvider = ({ children, overrides }) => {
  // A string to help redirect to the correct page in the workflow
  const [pageState, setPageState] = useState(
    TRIP_PAGE_STATES.READ_LICENSE_PLATE
  );

  // Active trip
  const [trip, setTrip] = useState('');

  // DC to use in the whole trip process
  const [selectedDC, setSelectedDC] = useState('');

  // Cargos added at trip
  const [cargos, setCargos] = useState([]);

  // Trip wide stats
  const [tripStats, setTripStats] = useState('');

  const clearData = () => {
    setTrip({});
    setCargos([]);
    setTripStats({});
  };

  const defaultValues = {
    pageState,
    setPageState,
    trip,
    setTrip,
    tripStats,
    setTripStats,
    selectedDC,
    setSelectedDC,
    cargos,
    setCargos,
    clearData
  };

  return (
    <TripProcessContext.Provider value={{ ...defaultValues, ...overrides }}>
      {children}
    </TripProcessContext.Provider>
  );
};

TripProcessProvider.defaultProps = {
  overrides: null
};

TripProcessProvider.propTypes = {
  children: PropTypes.element.isRequired,
  overrides: PropTypes.shape({})
};
