/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Button,
  Typography,
  Divider,
  Container
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Warning } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import useInterval from '../app/hooks/use-interval';
import { unitize as unitizeRest, getUnitLoadMetrics } from '../api-rest';
import { BarcodeReader } from '../app/components/barcode-readers';
import { playErrorBeep, playSuccessBeep } from '../sounds';
import { aggregateSortingDecisions, countFullUnits } from '../sorting-utils';
import SortingContextReader, {
  requestAggregatedSortingContext
} from '../app/components/sorting-context-reader';
import { StyledTableCell, StyledTableRow } from '../app/components/table';
import { useDistributionCenter } from '../app/access-control/distribution-center-provider';
import handleRestAPIError from '../app/utils/rest-api-request';
import {
  setStorageValueWithTtl,
  getStorageValueWithTtl
} from '../app/utils/storage-value-with-ttl';
import { ActivityTrackingContext } from '../app/activity-tracking/activity-tracking-provider';
import { ACTIVITY, OPERATIONAL_PROCESS, SWITCHES, SURVEY } from '../constants';
import { useFeature, useFeatureValue } from '../app/hooks/use-feature';
import Confirmation from '../app/components/confirmation';
import SortSurvey from '../app/components/sort-survey';

/**
 * This screen is responsible for the packing process. You first select a context,
 * you are then presented with all destinations of the context and their current
 * capacity state. You should then beep the unit load barcode and beep the new seal
 * to pack the contents of the source into
 * Also, you can click on a destination to check its packages and remove them if necessary
 */

function checkSurveyInterval() {
  return (
    localStorage.getItem(SURVEY.STORAGE_STATES.SUBMITTED) ||
    getStorageValueWithTtl(SURVEY.STORAGE_STATES.CLOSED)
  );
}

export function Packing() {
  const location = useLocation();
  const [sortingContext, setSortingContext] = React.useState(
    location?.state?.sortingContext || {}
  );
  const [successMessage, setSuccessMessage] = React.useState('');
  const [unitizeSource, setUnitizeSource] = React.useState(null);
  const [unitLoadLpn, setUnitLoadLpn] = React.useState('');
  const isContextDefined = sortingContext?.licensePlate;
  const hasDecisions = sortingContext?.decisions?.length;
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const enablePackingSurvey = useFeature(SWITCHES.enableSortSurvey);
  const [surveyPackingState, setSurveyPackingState] = useState(
    SURVEY.STATES.CONFIRMATION
  );

  const alreadySawSurvey = checkSurveyInterval();

  const isPackageReaderDisabled =
    surveyPackingState !== SURVEY.STATES.CLOSE &&
    !alreadySawSurvey &&
    enablePackingSurvey;

  // List decisions aggregated by unit load and sorted by name.
  const aggregatedDecisions = hasDecisions
    ? aggregateSortingDecisions(sortingContext.decisions)
    : [];

  // Count the number of unit loads that are almost full.
  const fullUnits = hasDecisions ? countFullUnits(aggregatedDecisions) : 0;

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.FULL_PROCESS);
    trackStart(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.BEEP_UNIT_LOAD);
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.FULL_PROCESS);
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    if (!sortingContext.licensePlate) {
      return;
    }
    const [response] = await requestAggregatedSortingContext(
      sortingContext.licensePlate
    ).catch(() => {
      return [undefined, undefined];
    });
    if (!response) {
      return;
    }

    const { sortingContext: updatedSortingContext } = response;
    if (updatedSortingContext) {
      setSortingContext(updatedSortingContext);
    }
  }, 15000);

  const onRowClick = decision => {
    setUnitLoadLpn(decision.destination.licensePlate);
  };

  if (unitLoadLpn) {
    return (
      <Redirect
        to={{
          pathname: '/unit-load',
          state: {
            unitLoadLpn,
            sortingContext
          }
        }}
      />
    );
  }

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">Empacotar</Box>
      </Typography>
      <Box mt={2.5}>
        {isContextDefined ? (
          <Box>
            <Box my={1.5}>
              <Typography variant="h5" align="center">
                {sortingContext.name}
              </Typography>
              <Box display="flex" justifyContent="center">
                <p>
                  Unidades cheias: <b data-testid="full-units">{fullUnits}</b>
                </p>
              </Box>
              <Box display="flex" justifyContent="center">
                <Link
                  to={{
                    pathname: '/sorting',
                    state: {
                      sortingContext
                    }
                  }}
                >
                  <Button variant="contained" color="primary">
                    Separar
                  </Button>
                </Link>
              </Box>
            </Box>

            <Divider />
            {!unitizeSource ? (
              <Box mt={1.5}>
                <BarcodeReader
                  placeholder="Leia a unidade"
                  onRead={unit => {
                    playSuccessBeep();
                    setUnitizeSource(unit);
                  }}
                />
                {successMessage && (
                  <Box mt={1.5}>
                    <Alert severity="success">{successMessage}</Alert>
                  </Box>
                )}
              </Box>
            ) : (
              <ConfirmPacking
                unitizeSource={unitizeSource}
                confirmationCallback={async () => {
                  setUnitizeSource(null);
                  setSuccessMessage('Unidade criada!');
                }}
                sortingContext={sortingContext}
              />
            )}
          </Box>
        ) : (
          <SortingContextReader
            onRead={async sorting => {
              setSortingContext(sorting);
            }}
            disable={isPackageReaderDisabled}
            aggregated
          />
        )}
      </Box>
      {Boolean(!unitizeSource && hasDecisions) && (
        <PackingState sortingContext={sortingContext} onRowClick={onRowClick} />
      )}
      {Boolean(isContextDefined && !hasDecisions) && (
        <Alert severity="warning">
          Não foi possível carregar a lista de unidades
        </Alert>
      )}
      <Survey
        surveyPackingState={surveyPackingState}
        setSurveyPackingState={setSurveyPackingState}
        enablePackingSurvey={enablePackingSurvey}
      />
    </Container>
  );
}

function Survey({
  surveyPackingState,
  setSurveyPackingState,
  enablePackingSurvey
}) {
  const alreadySawSurvey = checkSurveyInterval();

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const handleSubmitSurvey = () => {
    localStorage.setItem(SURVEY.STORAGE_STATES.SUBMITTED, 'true');
    setSurveyPackingState(SURVEY.STATES.CLOSE);
  };

  const handleCloseSurvey = () => {
    setStorageValueWithTtl(
      SURVEY.STORAGE_STATES.CLOSED,
      'true',
      SURVEY.CASH_TIME
    );
    setSurveyPackingState(SURVEY.STATES.CLOSE);
  };
  return (
    <>
      {enablePackingSurvey && !alreadySawSurvey && (
        <Confirmation
          open={surveyPackingState === SURVEY.STATES.CONFIRMATION}
          titleText={SURVEY.LABELS.TITLE}
          subtitleText={SURVEY.LABELS.SUBTITLE}
          onConfirm={() => {
            setSurveyPackingState(SURVEY.STATES.SURVEY);
          }}
          confirmText={SURVEY.LABELS.CONFIRM}
          onCancel={handleCloseSurvey}
          cancelText={SURVEY.LABELS.CANCEL}
        />
      )}
      {surveyPackingState === SURVEY.STATES.SURVEY && (
        <SortSurvey
          routingCode={selectedDistributionCenter?.routingCode}
          email={authenticatedUser.email}
          closeSurvey={handleCloseSurvey}
          submitSurvey={handleSubmitSurvey}
        />
      )}
    </>
  );
}

Survey.propTypes = {
  surveyPackingState: PropTypes.bool.isRequired,
  setSurveyPackingState: PropTypes.func.isRequired,
  enablePackingSurvey: PropTypes.bool.isRequired
};

export function ConfirmPacking({
  unitizeSource,
  confirmationCallback,
  sortingContext
}) {
  const [error, setError] = React.useState('');
  const [unitizeSourceMessage] = React.useState(
    `Unidade selecionada: ${unitizeSource}`
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const densityValue = JSON.parse(useFeatureValue(SWITCHES.densityValue));
  const enableDensityAnalysis = sortingContext?.licensePlate?.includes('SEP');
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  useEffect(() => {
    trackEnd(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.BEEP_UNIT_LOAD);
    trackStart(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.BEEP_NEW_UNIT_LOAD);

    return () => {
      trackStart(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.BEEP_UNIT_LOAD);
      trackEnd(OPERATIONAL_PROCESS.UNITIZE, ACTIVITY.BEEP_NEW_UNIT_LOAD);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseErrorHandler = err => {
    setError(err);
    playErrorBeep();
    setIsLoading(false);
  };

  const fetchUnitLoadMetrics = async unitLoadLpn => {
    const response = await getUnitLoadMetrics(unitLoadLpn).catch(err => {
      handleRestAPIError(err, baseErrorHandler);
    });

    return response?.data;
  };

  const fetchUnitize = async (unitizeDestination, sortingContextLpn) => {
    if (
      enableDensityAnalysis &&
      densityValue[(selectedDistributionCenter?.routingCode)]
    ) {
      const unitInfo = await fetchUnitLoadMetrics(unitizeSource);
      if (
        unitInfo?.density >=
        densityValue[(selectedDistributionCenter?.routingCode)].block
      ) {
        setIsLoading(false);
        setError(
          'A distância entre paradas está muito alta, retire pacotes que estão longe do grupo.'
        );
        return;
      }
    }

    const response = await unitizeRest(
      unitizeSource,
      unitizeDestination,
      sortingContextLpn
    ).catch(err => {
      handleRestAPIError(err, baseErrorHandler);
    });

    setIsLoading(false);
    return response;
  };

  const handleRead = async unitizeDestination => {
    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.UNITIZE_BEEP);

    if (isLoading) {
      return;
    }
    const sortingContextLpn = sortingContext?.licensePlate || '';
    setIsLoading(true);

    const response = await fetchUnitize(unitizeDestination, sortingContextLpn);

    if (!response) {
      playErrorBeep();
      trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.UNITIZE_BEEP);
      return;
    }

    playSuccessBeep();
    await confirmationCallback(response);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.UNITIZE_BEEP);
  };

  return (
    <Box>
      <BarcodeReader
        placeholder="Leia a nova unidade"
        onRead={handleRead}
        loading={isLoading}
      />
      <Divider />
      <Box mt={1.5}>
        {error && <Alert severity="error">{error}</Alert>}
        {unitizeSourceMessage && (
          <Alert severity="info">{unitizeSourceMessage}</Alert>
        )}
      </Box>
    </Box>
  );
}

ConfirmPacking.propTypes = {
  unitizeSource: PropTypes.string.isRequired,
  confirmationCallback: PropTypes.func.isRequired,
  sortingContext: PropTypes.shape({
    licensePlate: PropTypes.string
  })
};

ConfirmPacking.defaultProps = {
  sortingContext: {
    licensePlate: ''
  }
};

export function PackingState({ sortingContext, onRowClick }) {
  // List decisions aggregated by unit load and sorted by name.
  const aggregatedDecisions = aggregateSortingDecisions(
    sortingContext.decisions
  );

  const enablePackingCreateBagEcRoutingCode = useFeature(
    SWITCHES.enablePackingCreateBagEcRoutingCode
  );

  return (
    <Box data-testid="pack">
      <Box maxWidth="md">
        <Box mx={0.5} mt={1.5}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Unidade</StyledTableCell>

                  {enablePackingCreateBagEcRoutingCode && (
                    <StyledTableCell align="center">CE</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Unidades</StyledTableCell>
                  <StyledTableCell align="center">Uso</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {aggregatedDecisions.map(decision => {
                  return (
                    <StyledTableRow
                      key={decision.name}
                      onClick={() => onRowClick(decision)}
                    >
                      <StyledTableCell align="center">
                        {decision.destination.occupationStatus ===
                        'UNCONSTRAINED' ? (
                          ''
                        ) : (
                          <Box position="absolute">
                            {decision.destination.occupationStatus ===
                              'ALMOST_FULL' && <Warning color="secondary" />}
                          </Box>
                        )}
                        <b>{decision.name}</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {enablePackingCreateBagEcRoutingCode ? (
                          <>
                            {decision?.destination
                              ?.createBagExpeditionCenterRoutingCode || ''}
                          </>
                        ) : (
                          <>
                            {decision.expeditionCenter
                              ? decision.expeditionCenter.routing_code
                              : ''}
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {(decision.destination.packageCount || 0) +
                          (decision.destination.unitLoadCount || 0)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="body1">
                          {decision.destination.unitLoadCount === 0 &&
                            `${(
                              (decision.destination.occupationFraction || 0) *
                              100
                            ).toFixed(0)}%`}
                          {decision.destination.unitLoadCount !== 0 && '-'}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

PackingState.propTypes = {
  sortingContext: PropTypes.shape({
    licensePlate: PropTypes.string.isRequired,
    decisions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  onRowClick: PropTypes.func.isRequired
};
