import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import React from 'react';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyList } from '../../assets/images/empty-dc-list.svg';
import { DriverList, driverShape } from './driver-list';

const DriverSearch = ({
  driverList,
  selectDriver,
  selectedDriver,
  onClickAvatar,
  selectedAvatar
}) => {
  const [shownList, setShownList] = React.useState([]);

  const searchDrivers = value => {
    const foundDrivers = value
      ? driverList.filter(driver => {
          return (
            driver.fullName?.toLowerCase().includes(value.toLowerCase()) ||
            driver.docCpf?.number.includes(value)
          );
        })
      : [];
    setShownList(foundDrivers);
  };

  return (
    <Box display="flex" flexFlow="column" flexDirection="column" height={1}>
      <Box borderRadius={8} justifyContent="center" pb={1} pl={3} pr={3}>
        <TextField
          fullWidth
          variant="outlined"
          autoFocus
          placeholder="Busque pelo nome ou CPF"
          InputProps={{
            inputProps: {
              'data-testid': 'input-search'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon size="small" color="disabled" />
              </InputAdornment>
            )
          }}
          onChange={event => searchDrivers(event.target.value)}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" mt={1} height={1}>
        {shownList.length ? (
          <Box
            display="flex"
            flexDirection="column"
            height={1}
            maxHeight={1}
            style={{
              overflowY: 'scroll'
            }}
            flex="1 1 0"
          >
            <DriverList
              driverList={shownList}
              selectDriver={selectDriver}
              selectedDriver={selectedDriver}
              onClickAvatar={onClickAvatar}
              selectedAvatar={selectedAvatar}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" height={1}>
            <Box
              data-testid="driver-not-found"
              maxWidth="xs"
              justifyContent="center"
              display="flex"
              flexDirection="column"
              alignSelf="center"
            >
              <Box mb={2} alignSelf="center">
                <EmptyList />
              </Box>
              <Box>
                <Typography
                  align="center"
                  component="div"
                  variant="body1"
                  gutterBottom
                  color="textSecondary"
                >
                  Nenhum entregador recente.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DriverSearch;

DriverSearch.propTypes = {
  driverList: PropTypes.arrayOf(driverShape),
  selectDriver: PropTypes.func.isRequired,
  selectedDriver: PropTypes.arrayOf(driverShape),
  onClickAvatar: PropTypes.func.isRequired,
  selectedAvatar: PropTypes.arrayOf(driverShape)
};

DriverSearch.defaultProps = {
  driverList: [],
  selectedDriver: null,
  selectedAvatar: null
};
