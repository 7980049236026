import { GridList, GridListTile, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const MainInfoBag = ({
  seal,
  numPackages,
  cutoffRoute,
  routingCode,
  dcName
}) => {
  return (
    <GridList cellHeight="auto" cols={3}>
      {seal && (
        <GridListTile cols={2}>
          <Typography variant="caption">Lacre</Typography>
          <Typography variant="body1">{seal}</Typography>
        </GridListTile>
      )}
      {String(numPackages) && (
        <GridListTile cols={1}>
          <Typography variant="caption">Num pacotes</Typography>
          <Typography variant="body1">{numPackages}</Typography>
        </GridListTile>
      )}
      {cutoffRoute && (
        <GridListTile cols={2}>
          <Typography variant="caption">Corte - Rota</Typography>
          <Typography variant="body1">{cutoffRoute}</Typography>
        </GridListTile>
      )}
      {routingCode && (
        <GridListTile cols={1}>
          <Typography variant="caption">Roteamento</Typography>
          <Typography variant="body1">{routingCode}</Typography>
        </GridListTile>
      )}
      {dcName && (
        <GridListTile cols={2}>
          <Typography variant="caption">Centro de Expedição</Typography>
          <Typography variant="body1">{dcName}</Typography>
        </GridListTile>
      )}
    </GridList>
  );
};

MainInfoBag.propTypes = {
  seal: PropTypes.string,
  numPackages: PropTypes.number,
  cutoffRoute: PropTypes.string,
  routingCode: PropTypes.string,
  dcName: PropTypes.string
};

MainInfoBag.defaultProps = {
  seal: null,
  numPackages: null,
  cutoffRoute: null,
  routingCode: null,
  dcName: null
};

export default MainInfoBag;
