import React from 'react';
import PropTypes from 'prop-types';
import './i18nInitForTests';
import AuthenticationRoutes from './routes';

const App = ({ target, signUpRedirect }) => {
  return (
    <AuthenticationRoutes target={target} signUpRedirect={signUpRedirect} />
  );
};

App.propTypes = {
  target: PropTypes.string,
  signUpRedirect: PropTypes.string
};

App.defaultProps = {
  target: '',
  signUpRedirect: undefined
};

export default App;
