export const DECLARATION_RESULT = {
  DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_DAMAGED:
    'DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_DAMAGED',
  DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_ACCEPTED_AS_DAMAGED:
    'DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_ACCEPTED_AS_DAMAGED',
  DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_LOST:
    'DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_LOST'
};

export const TEXT = {
  TITLE: 'Pacote danificado',
  ERROR_MATCH_IMAGE:
    'Opa! Imagem com baixa qualidade. Limpe a lente da câmera ou ajuste seu foco.',
  ERROR_MATCH_IMAGE_JIRA:
    'Opa! Etiqueta não validada. A análise vai ser feita via Jira após finalizado o processo.',
  TITLE_MATCH_IMAGE: {
    TEXT: 'Tire uma foto da etiqueta',
    EMPHASIS: 'etiqueta'
  },
  TITLE_CONFIRM_MATCH_IMAGE: 'Verifique se a imagem da etiqueta está boa.',
  ALT_MATCH_IMAGE: 'Imagem da etiqueta.',
  FORM_SUBTITLE: 'Conte o que aconteceu com o pacote.',
  QUESTION_LOCATION: {
    TEXT: 'Onde o pacote foi danificado?',
    EMPHASIS: 'pacote'
  },
  QUESTION_PHYSICAL_STATE_PACKAGE: {
    TEXT: 'Qual o estado atual do pacote?',
    EMPHASIS: 'pacote'
  },
  QUESTION_PHYSICAL_STATE_PRODUCT: {
    TEXT: 'Qual o estado atual do produto?',
    EMPHASIS: 'produto'
  },
  QUESTION_PACKAGING_QUALITY: {
    TEXT: 'A embalagem estava dentro dos padrões recomendados pela Loggi?',
    EMPHASIS: 'embalagem'
  },
  QUESTION_DAMAGED_PRODUCTS:
    'O item dentro da embalagem foi danificado ou está incompleto?',
  SUCCESSFUL_DECLARATION:
    'Pacote danificado! O Status mudará para “Mercadoria Avariada” em breve.',
  ERROR_DECLARATION: 'Deu algo de errado, tente novamente',
  ERROR_GENERATE_PRESIGNEDURLS:
    'Não foi possível preparar tudo para salvar suas fotos, tente novamente.',
  ERROR_UPLOAD_IMAGES:
    'Não foi possível salvar todas as fotos, tente novamente.',
  DAMAGED_PACKAGE_REQUEST_CREATED_MESSAGE: {
    [DECLARATION_RESULT.DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_DAMAGED]: {
      TEXT: 'Análise de avaria aberta!',
      EMPHASIS: 'avaria'
    },
    [DECLARATION_RESULT.DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_PENDING_AS_LOST]: {
      TEXT: 'Análise de extravio aberta!',
      EMPHASIS: 'extravio'
    }
  },
  DAMAGED_PACKAGE_REQUEST_CREATED_MESSAGE_2:
    'Armazene o pacote enquanto a solicitação é analisada.',
  DAMAGED_PACKAGE_REQUEST_CREATED_MESSAGE_3:
    'Para acompanhar a evolução, vá em Pacote danificado, no Identificar.',
  BACK: 'Voltar',
  SUBMIT: 'Enviar',
  CLOSE: 'Fechar',
  SCREEN_TITLE: 'Pacote danificado',
  FORM_SUBTITLE_LOADING:
    'Estamos preparando o envio das informações, aguarde um momento.',
  SEND_DAMAGE_IMAGE: 'Continuar',
  TITLE_UPLOAD_DAMAGE_IMAGE: {
    TEXT: 'Tire fotos do pacote danificado',
    EMPHASIS: 'pacote danificado'
  },
  ALT_UPLOAD_DAMAGE_IMAGE: 'Upload da imagem do pacote danificado.',
  TITLE_PREVISUALIZE_DAMAGE_IMAGE:
    'Agora, tire algumas fotos que mostram o estado do pacote',
  ALT_PREVISUALIZE_DAMAGE_IMAGE:
    'Visualização da imagem do pacote danificado. ',
  PRODUCT: 'produto',
  PACKAGE: 'pacote',
  QUESTION_LOST_PRODUCT: {
    TEXT: 'Está faltando algum produto?',
    EMPHASIS: 'produto'
  },
  QUESTION_DAMAGED_PRODUCT: {
    TEXT: 'O produto dentro do pacote foi danificado?',
    EMPHASIS: 'produto'
  }
};

export const YES = 'yes';

export const IS_QUALITY_PACKAGING_APPROPRIATE_OPTIONS = [
  { label: 'Sim', value: 2 },
  { label: 'Não', value: 1 },
  { label: 'Não sei', value: 3 }
];

export const IS_DAMAGED_HERE_OPTIONS = [
  { label: 'Aqui na base', value: 2 },
  { label: 'Outro lugar', value: 1 },
  { label: 'Não sei', value: 3 }
];

export const IS_LOST_PRODUCT_OPTIONS = [
  { label: 'Sim', value: 2 },
  { label: 'Não', value: 1 },
  { label: 'Não sei', value: 3 }
];

export const IS_PRODUCT_DAMAGED_OPTIONS = [
  { label: 'Sim', value: YES },
  { label: 'Não', value: 'no' }
];

export const NOT_DAMAGED = 'not-damaged';

export const PHYSICAL_STATE_PACKAGE_OPTIONS = [
  { label: 'Aberto', value: 'open' },
  { label: 'Amassado', value: 'dented' },
  { label: 'Molhado', value: 'wet' },
  { label: 'Rasgado', value: 'torn' },
  { label: 'Não está danificado', value: NOT_DAMAGED }
];

export const PHYSICAL_STATE_PRODUCT_OPTIONS = [
  { label: 'Quebrado', value: 'broken' },
  { label: 'Sujo ou molhado', value: 'wet' },
  { label: 'Vazando', value: 'leaking' },
  { label: 'Rasgado', value: 'torn' },
  { label: 'Violado', value: 'violated' },
  { label: 'Outro', value: 'other' }
];

export const TESTID = {
  DAMAGE_PHOTO_TITLE: 'damage-photo-title',
  DAMAGE_PHOTO_CONTAINER: 'damage-photo-camera-container',
  MATCH_IMAGE: 'img-photo-testid',
  CONTAINER_IMAGE_VIEW: 'container-image-view',
  IMAGE_VIEW_SEND_BUTTON: 'send-button',
  IMAGE_VIEW_REMOVE_BUTTON: 'remove-button',
  IS_PRODUCT_DAMAGED_TOGGLE: 'IS_PRODUCT_DAMAGED_TOGGLE',
  FORM_LOADING: 'FORM_LOADING',
  DAMAGED_PACKAGE_DECLARATION_DIALOG: 'DAMAGED_PACKAGE_DECLARATION_DIALOG',
  DAMAGED_PACKAGE_DECLARATION_DIALOG_CLOSE_BUTTON:
    'DAMAGED_PACKAGE_DECLARATION_DIALOG_CLOSE_BUTTON',
  DAMAGE_PACKAGE_MINIPREVISUALIZATION: 'image-mini-previsualization',
  DAMAGE_PACKAGE_SEND_BUTTON: 'damage-package-send-button',
  DAMAGE_PACKAGE_VISUALIZATION_TITLE: 'damage-package-visualization-title',
  DAMAGE_PACKAGE_VISUALIZATION_CONTAINER:
    'damage-package-visualization-container',
  DAMAGE_PACKAGE_VISUALIZATION_REMOVE_BUTTON: 'remove-previsualization-image',
  DAMAGE_PACKAGE_IMAGE: 'damage-package-image',
  DAMAGED_PACKAGE_PHYSICAL_STATE_PRODUCT:
    'damaged-package-physical-state-product',
  DAMAGE_PACKAGE_MISSING_PRODUCT_OPTIONS:
    'DAMAGE_PACKAGE_MISSING_PRODUCT_OPTIONS',
  DAMAGE_PACKAGE_PACKAGING_QUALITY_OPTIONS:
    'DAMAGE_PACKAGE_PACKAGING_QUALITY_OPTIONS'
};

export const PHOTO_TYPE = {
  label: 'DAMAGED_PACKAGE_PHOTO_TYPE_LABEL',
  package: 'DAMAGED_PACKAGE_PHOTO_TYPE_PACKAGE'
};

export const CREATE_API_RESPONSE = {
  [DECLARATION_RESULT.DAMAGED_PACKAGE_DECLARATION_RESULT_TYPE_ACCEPTED_AS_DAMAGED]: {
    MESSAGE:
      'Pacote danificado! O Status mudará para “Mercadoria Avariada” em breve.',
    VARIANT: 'success'
  }
};

export const NUMBER = {
  TOTAL_RETRY_MATCH_IMAGE: 3,
  TOTAL_PREVISUALIZE_IMAGE: 3
};
