import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Box,
  Typography,
  Button,
  Container,
  ListItem
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import SharedPropTypes from '../../app/shared-prop-types';
import { ReactComponent as EmptySearch } from '../../assets/images/empty-search.svg';
import EditPackagesDialog from './edit-packages-dialog';
import PreviewLabelDialog from './preview-label-dialog';
import BasicChip from '../../app/components/basic-chip';

const checkPackagesButtonHeight = 78;

const PrintingPackages = ({
  pkgsToPrepareInfo,
  onRemovePackages,
  onConfirm
}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [packageToPreviewInfo, setPackageToPreviewInfo] = useState(null);

  const getContainerPaddingTop = () => {
    let pixels = 590;

    if (pkgsToPrepareInfo.length === 0) {
      pixels = 510;
    } else if (pkgsToPrepareInfo.length === 1) {
      pixels = 495;
    }

    return `calc(100vh - ${pxToRem(pixels)})`;
  };

  const handleEditSelect = () => {
    setOpenEdit(true);
  };

  const handleReturnEdit = () => {
    setOpenEdit(false);
  };

  const handlePreviewSelect = pkgInfo => {
    setPackageToPreviewInfo(pkgInfo);
  };

  const handleReturnPreview = () => {
    setPackageToPreviewInfo(null);
  };

  return (
    <Container disableGutters maxWidth="xs" data-testid="printing-packages">
      <Box paddingTop={getContainerPaddingTop()}>
        <Box
          bottom={pxToRem(checkPackagesButtonHeight)}
          borderRadius="16px"
          px={3}
          pt={1}
        >
          <Typography
            data-testid="packages-counter"
            style={{ fontSize: pxToRem(86) }}
          >
            {pkgsToPrepareInfo.length}
          </Typography>
        </Box>
        <Box
          flex="1 1"
          bgcolor={colors.root[0]}
          style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
        >
          <PackageList
            pkgsToPrepareInfo={pkgsToPrepareInfo}
            onPreviewSelect={handlePreviewSelect}
          />
          <CheckedPackagesButtons
            onConfirm={onConfirm}
            onEditSelect={handleEditSelect}
            packagesLength={pkgsToPrepareInfo.length}
          />
        </Box>
        {openEdit && (
          <EditPackagesDialog
            open
            pkgsToPrepareInfo={pkgsToPrepareInfo}
            onRemovePackages={onRemovePackages}
            onReturn={handleReturnEdit}
          />
        )}
        {packageToPreviewInfo && (
          <PreviewLabelDialog
            open
            pkgToPrepareInfo={packageToPreviewInfo}
            onReturn={handleReturnPreview}
            onRemovePackages={onRemovePackages}
          />
        )}
      </Box>
    </Container>
  );
};

PrintingPackages.propTypes = {
  pkgsToPrepareInfo: SharedPropTypes.pkgsToPrepareInfo.isRequired,
  onRemovePackages: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const usePackageListStyles = makeStyles(({ breakpoints }) => ({
  container: {
    [breakpoints.only('xs')]: {
      paddingBottom: '35vh'
    },
    [breakpoints.up('sm')]: {
      paddingBottom: '60vh'
    }
  }
}));

const PackageList = ({ pkgsToPrepareInfo, onPreviewSelect }) => {
  const styles = usePackageListStyles();

  return (
    <Box
      width="100%"
      overflow="auto"
      maxHeight="60%"
      borderRadius="16px"
      bgcolor={colors.root[0]}
      className={styles.container}
    >
      <Box position="relative">
        <Box position="sticky" bgcolor="white" zIndex="2">
          {!pkgsToPrepareInfo.length && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              mt={5}
              px={2.5}
            >
              <EmptySearch style={{ width: 100 }} />
              <Typography
                variant="subtitle1"
                align="center"
                style={{ marginTop: 20 }}
              >
                Bipe ou digite o código do pacote para adicionar na lista.
              </Typography>
            </Box>
          )}
          <Box>
            <List disablePadding>
              {pkgsToPrepareInfo.map(pkgInfo => (
                <PackageItem
                  pkgToPrepareInfo={pkgInfo}
                  key={`${pkgInfo.pkg.trackingKey}-${pkgInfo.pkg.identifier}`}
                  onPreviewSelect={() => onPreviewSelect(pkgInfo)}
                />
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PackageList.propTypes = {
  pkgsToPrepareInfo: SharedPropTypes.pkgsToPrepareInfo.isRequired,
  onPreviewSelect: PropTypes.func.isRequired
};

const CheckedPackagesButtons = ({
  onConfirm,
  packagesLength,
  onEditSelect
}) => {
  return (
    <Box
      position="fixed"
      bottom="0"
      width="100%"
      maxWidth={pxToRem(444)}
      zIndex={2}
      bgcolor={colors.root[0]}
    >
      <Box
        height={24}
        mt={-1.5}
        style={{
          background:
            'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,0))'
        }}
      />
      <Box px={3} pb={2}>
        {packagesLength === 1 && <ConfirmButton onConfirm={onConfirm} />}
        {packagesLength > 1 && (
          <Box display="flex">
            <Box flex={1} mr={1}>
              <Button
                fullWidth
                data-testid="edit-packages"
                color="primary"
                variant="outlined"
                size="large"
                onClick={onEditSelect}
              >
                Editar
              </Button>
            </Box>
            <Box flex={1} ml={1}>
              <ConfirmButton onConfirm={onConfirm} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CheckedPackagesButtons.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  packagesLength: PropTypes.number.isRequired,
  onEditSelect: PropTypes.func.isRequired
};

const ConfirmButton = ({ onConfirm }) => {
  return (
    <Button
      fullWidth
      data-testid="confirm-printing"
      color="primary"
      variant="contained"
      size="large"
      onClick={onConfirm}
    >
      Imprimir
    </Button>
  );
};

ConfirmButton.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

const PackageItem = ({ pkgToPrepareInfo, onPreviewSelect }) => {
  return (
    <ListItem
      button
      onClick={onPreviewSelect}
      data-testid={`preview-package-${pkgToPrepareInfo.pkg.identifier}`}
      disableGutters
      divider
    >
      <Box
        display="flex"
        justifyContent="space-between"
        px={3}
        py={1}
        width="100%"
      >
        <Box py={1.5} flex={1} minWidth={0}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box pb={1.5}>
              <Typography component="span">
                <Box
                  fontWeight="fontWeightMedium"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {pkgToPrepareInfo.pkg.identifier}
                </Box>
              </Typography>
            </Box>
            <Box color={colors.smoke[700]}>
              <Typography variant="subtitle2" noWrap>
                {`Pacote de ${pkgToPrepareInfo.pkg.company?.sharedName ||
                  pkgToPrepareInfo.pkg.company?.name}`}
              </Typography>
              <Typography variant="subtitle2" noWrap>
                {` para ${pkgToPrepareInfo.pkg.recipient.name}`}
              </Typography>
              {!pkgToPrepareInfo.mainLabel.base64Pdf && (
                <Box
                  mt={1.5}
                  data-testid={`invalid-chip-${
                    pkgToPrepareInfo.pkg.identifier
                  }`}
                >
                  <BasicChip message={pkgToPrepareInfo.mainLabel.missingText} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <IconButton color="primary" edge="end">
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );
};

PackageItem.propTypes = {
  pkgToPrepareInfo: SharedPropTypes.pkgToPrepareInfo.isRequired,
  onPreviewSelect: PropTypes.func.isRequired
};

export default PrintingPackages;
