import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import 'moment/locale/pt-br';
import { OrganizeProcessContext } from './organize-process-context';
import { HISTORY_ACTIONS, ROUTES } from '../../constants';
import ActionContainerTop from '../../app/components/actions-container-top';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import PaperBox from '../../app/components/paper-box';
import { ReactComponent as BarcodeIcon } from '../../assets/images/barcode.svg';
import HeaderWithReturn from '../../app/components/header-with-return';
import pluralize from '../../app/utils/pluralize';
import PreviewBarcodeDialog from './preview-barcode-dialog';

export default function Summary() {
  const history = useHistory();
  const [openPreviewBarcode, setOpenPreviewBarcode] = useState(false);
  const { unitInfo } = useContext(OrganizeProcessContext);

  const goBack = () => {
    history.push({ pathname: ROUTES.ORGANIZE.INITIAL_IDENTIFICATION });
  };

  useEffect(() => {
    // prevent page from being accessed by editing the URL
    if (history.action !== HISTORY_ACTIONS.PUSH) {
      history.replace(ROUTES.ORGANIZE.INITIAL_IDENTIFICATION);
    }
  }, [history]);

  return (
    <Box bgcolor={colors.root[0]}>
      <Box display="flex" flexDirection="column" overflow="hidden">
        <Container disableGutters maxWidth="xs">
          <PaperBox>
            <>
              <Container maxWidth="xs">
                <Box pb={0.5} mt={10}>
                  <BarcodeIcon />
                  <Box my={2}>
                    <Typography component="div">
                      <Box fontWeight="fontWeightMedium">
                        {unitInfo?.unitLoad?.licensePlate}
                      </Box>
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      <Box>{`com ${
                        unitInfo?.childrenUnitLoad?.length
                      } ${pluralize({
                        singular: 'pacote',
                        count: unitInfo?.childrenUnitLoad?.length
                      })}`}</Box>
                      <Box>
                        {`criado em ${moment(
                          unitInfo?.unitLoad?.created
                        ).format('DD MMM')}`}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Container>
              <Divider />
              <Container maxWidth="xs">
                <Box pt={0.5} pb={10} minHeight="70vh">
                  <List>
                    {unitInfo?.childrenUnitLoad?.map(child => (
                      <ListItem
                        disableGutters
                        key={child.childUnitLoad.identifier}
                      >
                        <ListItemText
                          primary={
                            <Typography noWrap>
                              {child.childUnitLoad.identifier}
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary" noWrap>
                              {`Pacote de ${child.childUnitLoad.company
                                ?.sharedName ||
                                child.childUnitLoad.company?.name}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Container>
            </>
          </PaperBox>
        </Container>
      </Box>
      <ActionContainerTop>
        <Container maxWidth="xs">
          <Box mt={3}>
            <HeaderWithReturn
              title="Organizar"
              onReturn={goBack}
              fixed={false}
            />
          </Box>
        </Container>
      </ActionContainerTop>
      <ActionContainerBottom>
        <Container maxWidth="xs">
          <Box mb={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              data-testid="organize-barcode-button"
              onClick={() => setOpenPreviewBarcode(true)}
            >
              Código de barras
            </Button>
          </Box>
        </Container>
      </ActionContainerBottom>
      {openPreviewBarcode && (
        <PreviewBarcodeDialog
          open
          unitLoadLpn={unitInfo?.unitLoad?.licensePlate}
          onClose={() => setOpenPreviewBarcode(false)}
        />
      )}
    </Box>
  );
}
