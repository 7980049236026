import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import * as Sentry from '@sentry/browser';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AddressAutocomplete from '../address-autocomplete';
import { CoverageParamsContext } from '../coverage-params';
import { useFeature } from '../remote-config';
import { ASYNC_PENDING } from './constants';

const AddressField = ({ fieldName, label, validate, cleanFieldsOnError }) => {
  const { t } = useTranslation('one');
  const { companyId } = useParams();
  const extraAddressValidationFeature = useFeature(
    'enable_extra_address_validation'
  );
  const [loading, setLoading] = useState(false);
  const [asyncError, setAsyncError] = useState(undefined);
  const prevStateRef = useRef({});
  const { setFieldValue } = useFormikContext();
  const prevState = prevStateRef.current;
  const { setCoverageParams } = useContext(CoverageParamsContext);

  // The return of this should be either:
  // 1. string: error message, Formik makes the field invalid
  // 2. undefined: Formik makes the field valid
  const runAsyncValidation = useCallback(
    async address => {
      let result;
      let message;

      // Formik fire validations for all fields when change/blur occurs to any field,
      // so this function will always run. To avoid external request on every validation,
      // if the address value didn't change we just return the last stored error message.
      if (address.description === prevState.value?.description)
        return asyncError;

      setLoading(true);

      try {
        result = await validate(companyId, address);
        message = result;
      } catch (e) {
        Sentry.captureException(e, {
          contexts: {
            runAsyncValidation: { address }
          }
        });

        message = t('addressField.errorMessages.asyncValidation');
      }

      if (typeof result === 'string') {
        message = t('addressField.errorMessages.invalidAddress');
      } else if (result instanceof Object) {
        cleanFieldsOnError.forEach(fieldNameToClean => {
          setFieldValue(fieldNameToClean, null);
        });
        setCoverageParams(result);
        message = undefined;
        if (!result?.hasPickup)
          message = t('addressField.errorMessages.originOutOfCoverageArea');
      }

      setLoading(false);
      setAsyncError(message);

      return message;
    },
    [
      prevState.value,
      asyncError,
      t,
      validate,
      cleanFieldsOnError,
      companyId,
      setCoverageParams,
      setFieldValue
    ]
  );

  const [field, meta, helpers] = useField({
    name: fieldName,
    validate: async newValue => {
      if (!newValue) return t('addressField.errorMessages.requiredField');

      // If we don't have an extra validation to run, field is valid
      if (typeof validate !== 'function') return undefined;
      // FS: enable_extra_address_validation
      if (!extraAddressValidationFeature) return undefined;

      const asyncMessage = await runAsyncValidation(newValue);

      return asyncMessage;
    }
  });

  const { name, onBlur, value } = field;
  const { error, touched } = meta;
  const { setError, setValue, setTouched } = helpers;

  const isAsyncPending = error === ASYNC_PENDING;
  const hasError = Boolean(error) && touched && !isAsyncPending;

  useEffect(() => {
    if (prevState.loading !== loading) {
      if (loading === true) {
        // We explicitly set an error to Formik, so the address field becomes invalid
        // while the async validation is pending. This prevents the user from submitting.
        setError(ASYNC_PENDING);
      } else {
        // When async validation has finished, we apply the result to Formik.
        setError(asyncError);
      }
    }
  }, [asyncError, loading, prevState.loading, setError]);

  useEffect(() => {
    // We set the field touched when there's an async error,
    // so the error message can be displayed. Errors are only displayed
    // for touched fields (just how Formik works)
    if (!touched && asyncError) setTouched(true);
  }, [asyncError, setTouched, touched]);

  useEffect(() => {
    prevStateRef.current = { loading, value };
  }, [loading, value]);

  return (
    <FormControl error={hasError} fullWidth variant="outlined">
      <AddressAutocomplete
        error={hasError}
        label={label || t('addressField.label')}
        loading={loading}
        name={name}
        onBlur={onBlur}
        required
        setValue={setValue}
        value={value}
      />
      {!isAsyncPending && hasError && (
        <FormHelperText>
          <ErrorMessage name={name} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

AddressField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.func,
  cleanFieldsOnError: PropTypes.arrayOf(PropTypes.string)
};

AddressField.defaultProps = {
  label: '',
  validate: null,
  cleanFieldsOnError: [
    'pickupDate',
    'pickupStartTime',
    'pickupEndTime',
    'date',
    'startTime',
    'endTime'
  ]
};

export default AddressField;
