import React from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';

const styles = {
  chip: {
    color: 'white',
    background: colors.smoke[500]
  }
};

const BasicChip = ({ message }) => {
  return (
    <Chip
      style={styles.chip}
      data-testid="basic-chip"
      size="small"
      label={message}
    />
  );
};

BasicChip.propTypes = {
  message: PropTypes.string.isRequired
};

export default BasicChip;
