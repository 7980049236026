import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { ReactComponent as EmptyList } from '../../assets/images/empty-dc-list.svg';
import DestinationList from './destination-list';

const DestinationSearch = ({ selectDestinations, destinationList }) => {
  const [shownList, setShownList] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState();

  useEffect(() => {
    if (!searchTerm) {
      setShownList(destinationList);
      return;
    }
    const foundDestinations = searchTerm
      ? destinationList.filter(destination => {
          return (
            destination.destinationRoutingCode
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            destination.destinationName
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        })
      : [];

    setShownList(foundDestinations);
  }, [destinationList, searchTerm]);

  return (
    <Box display="flex" flexflow="column" flexDirection="column" height={1}>
      <Box borderRadius={8} justifyContent="center" pb={1} pl={3} pr={3}>
        <TextField
          fullWidth
          variant="outlined"
          autoFocus
          placeholder="Busque pelo nome ou sigla"
          InputProps={{
            inputProps: {
              'data-testid': 'input-search'
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon size="small" color="disabled" />
              </InputAdornment>
            )
          }}
          onChange={event => setSearchTerm(event.target.value)}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" mt={1} height={1}>
        {shownList.length ? (
          <Box
            display="flex"
            flexDirection="column"
            height={1}
            maxHeight={1}
            style={{
              overflowY: 'scroll'
            }}
            flex="1 1 0"
          >
            <DestinationList
              destinationList={shownList}
              selectDestinations={selectDestinations}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" height={1}>
            <Box
              data-testid="destination-not-found"
              maxWidth="xs"
              justifyContent="center"
              display="flex"
              flexDirection="column"
              alignSelf="center"
            >
              <Box mb={2} alignSelf="center">
                <EmptyList />
              </Box>
              <Box>
                <Typography
                  align="center"
                  component="div"
                  variant="body1"
                  gutterBottom
                  color="textSecondary"
                >
                  Nenhum destino encontrado.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DestinationSearch;

DestinationSearch.propTypes = {
  selectDestinations: PropTypes.func.isRequired,
  destinationList: PropTypes.arrayOf(
    PropTypes.shape({
      destinationRoutingCode: PropTypes.string,
      destinationName: PropTypes.string
    })
  ).isRequired
};
