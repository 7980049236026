import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import { colors } from '@loggi/mar';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { BarcodeReader } from '../app/components/barcode-readers';

import { playSuccessBeep, playErrorBeep } from '../sounds';
import { meliHuConfirmation } from '../api';

moment.updateLocale('pt-BR');

export const MELI_CHECKING = {
  HEADER: 'Identificar HU',
  NOT_FOUND: 'HU não encontrado, tente novamente.',
  ERROR: 'Erro desconhecido, aguarde e tente novamente.'
};

/**
 * Checking screen (HU Mercado Livre)
 */
export function MeliChecking() {
  const [handlingUnit, setHandlingUnit] = React.useState({});
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const titleFieldMapping = [
    { title: 'HU:', field: 'NroHu' },
    { title: 'Total de pacotes:', field: 'shipment_count' },
    {
      title: 'Emissão:',
      field: 'FecCreacion',
      format: date => moment(new Date(date)).format('L')
    },
    { title: 'Horário:', field: 'HoraRegistrada' }
  ];

  const handleRead = async barcode => {
    setError('');
    setLoading(true);
    setHandlingUnit({});

    const response = await meliHuConfirmation(barcode);
    setLoading(false);

    if (response.ok) {
      setHandlingUnit(response.data);
      playSuccessBeep();
      return;
    }
    const errorMsg =
      response.status > 203 ? MELI_CHECKING.NOT_FOUND : MELI_CHECKING.ERROR;
    setError(errorMsg);
    playErrorBeep();
  };

  // eslint-disable-next-line react/prop-types
  const summaryItem = ({ title, field, format = txt => txt }) => (
    <Typography key={field} variant="body1" component="div" gutterBottom>
      <Box fontWeight="fontWeightBold" m={1} color={colors.blue[500]}>
        {title}
        <Typography variant="h5" component="div">
          <Box color={colors.smoke[900]} data-testid={field}>
            {format(handlingUnit[field])}
          </Box>
        </Typography>
      </Box>
    </Typography>
  );

  return (
    <Container maxWidth="xs">
      <Typography component="div" variant="body1" gutterBottom>
        <Box fontWeight="fontWeightBold">{MELI_CHECKING.HEADER}</Box>
      </Typography>
      <Box className="centered" my={2.5}>
        <Box>
          <BarcodeReader onRead={handleRead} />
          {error && (
            <Box mt={2.5}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </Box>
        {loading && (
          <Grid container justify="center">
            <Box p={2}>
              <CircularProgress justify="center" />
            </Box>
          </Grid>
        )}
        {!loading && handlingUnit.NroHu && (
          <Box p={2.5}>{titleFieldMapping.map(summaryItem)}</Box>
        )}
      </Box>
    </Container>
  );
}
