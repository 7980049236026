import getAuthorizationTokens from './get-authorization-tokens';
import getImpersonatorToken from './get-impersonator-token';

export const HTTP_X_IMPERSONATE = 'x-impersonate';

const getHeaders = async headers => {
  const { idToken } = await getAuthorizationTokens();

  if (!idToken) {
    return headers;
  }

  const cognitoImpersonatorUserToken = getImpersonatorToken();

  const setupHeaders = {
    ...headers,
    Authorization: `Bearer ${idToken}`
  };

  if (cognitoImpersonatorUserToken) {
    setupHeaders[HTTP_X_IMPERSONATE] = cognitoImpersonatorUserToken;
  }

  return setupHeaders;
};

export default getHeaders;
