import { authenticationLocales } from '@loggi/authentication';

const signIn = {
  ...authenticationLocales.ptBr.signIn,
  header: { message: 'Vamos começar?' },
  accessAccount: {
    message: 'Acesse a sua conta na Loggi e comece a usar o XdApp.'
  }
};

export default signIn;
