import { isValid as isCpfValid } from 'cpf';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { validate as isCnpjValid } from 'cnpj';
import PropTypes from 'prop-types';
import React from 'react';

const masks = {
  cpf(val) {
    return val
      .replace(/(\d{11})\d/, '$1')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2');
  },
  cnpj(val) {
    return val
      .replace(/(\d{14})\d/, '$1')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
};

const CnpjOrCpfField = ({ fieldName, label, shouldRenderRequiredAsterisk }) => {
  const { t } = useTranslation('one');
  const [field, meta, { setTouched, setValue }] = useField({
    name: fieldName,
    validate: newValue => {
      if (!newValue) {
        return String(t('cnpjOrCpfField.errorMessages.requiredField'));
      }
      if (!isCpfValid(newValue) && !isCnpjValid(newValue)) {
        return String(t('cnpjOrCpfField.errorMessages.invalid'));
      }
      return '';
    }
  });
  const { value } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  const handleChange = ({ target }) => {
    let formatted = '';
    const onlyDigits = String(target.value).replace(/\D/g, '');
    if (onlyDigits.length <= 11) formatted = masks.cpf(onlyDigits);
    else formatted = masks.cnpj(onlyDigits);
    setTouched(true);
    setValue(formatted);
  };

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={fieldName}
      inputProps={{ 'data-testid': `${fieldName}-field`, inputMode: 'numeric' }}
      InputLabelProps={{ required: shouldRenderRequiredAsterisk }}
      label={label}
      name={fieldName}
      onChange={handleChange}
      required
      value={value}
      variant="outlined"
    />
  );
};

CnpjOrCpfField.defaultProps = {
  label: 'CPF ou CNPJ',
  shouldRenderRequiredAsterisk: false
};

CnpjOrCpfField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  shouldRenderRequiredAsterisk: PropTypes.bool
};

export default CnpjOrCpfField;
