import { colors } from '@loggi/mar';
import { Box, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useSummaryStyles = makeStyles(({ typography, breakpoints, palette }) => ({
  root: {
    backgroundImage: ({ color }) =>
      color ? 'none' : palette.header?.gradient || colors.gradients.sanches,
    backgroundColor: ({ color }) => color || 'transparent',
    transition: 'background-color 0.5s ease-in-out',
    [breakpoints.up('sm')]: {
      minHeight: typography.pxToRem(292)
    }
  }
}));

/**
 * This is an extra padding used to free some space on the top of the page
 * @type {Float|Object} - Material spacing unit
 */
const HEADER_EXTRA_PADDING = { xs: 8, sm: 10 };

/**
 * The OneTemplateSummary is the component responsible of presenting the elements
 * of the upper region of the template
 * @param children
 * @param color
 * @returns {*}
 * @constructor
 */
const OneTemplateSummary = ({ children, color }) => {
  const { palette } = useTheme();
  const style = useSummaryStyles({ color });

  return (
    <Box
      alignItems="flex-end"
      className={style.root}
      display="flex"
      flexShrink={0}
      pb={8}
      pt={HEADER_EXTRA_PADDING}
    >
      <Container
        color={palette.common.white}
        component={Box}
        pb={{ xs: 3, sm: 5 }}
        pt={{ xs: 5, sm: 7 }}
      >
        {children}
      </Container>
    </Box>
  );
};

OneTemplateSummary.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

OneTemplateSummary.defaultProps = {
  color: ''
};

export default OneTemplateSummary;
